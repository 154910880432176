import { Header1, Header3, Text5 } from 'styles/typography'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
// import Markdown, { compiler } from 'markdown-to-jsx'
import { $userProfile } from 'stores/userStore'
import { useStore } from '@nanostores/react'
import Markdown from 'react-markdown'
import Modal from 'components/Modal'
import newImage2 from 'images/news_image_test2.webp'
import newPrev from 'images/news_prev_test.webp'
import news, { Article } from 'mock/news'
import styled from 'styled-components'

const StyleTitle = styled(Header1)`
  color: #2cff80;
  text-align: center;
  text-shadow: 0px 1px 6px rgba(44, 255, 128, 0.4);
  text-transform: uppercase;
  line-height: 100%;
  padding-bottom: 7px;
`

const StyledNewsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 10;

  gap: 32px;
  /* &::after {
    content: '';
    position: absolute;
    bottom: 52px;
    left: 2px;
    width: 713px;
    height: 42px;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.8) -10.7%,
      rgba(0, 0, 0, 0) 99.62%
    );

    filter: blur(15px);
    transform: rotate(180deg);
    z-index: 10;
  }

  &::before {
    content: '';
    position: absolute;
    width: 713px;
    height: 0px;
    top: 107px;
    left: 2px;
    width: 715px;
    height: 42px;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.8) -43.13%,
      rgba(0, 0, 0, 0) 108.52%
    );

    filter: blur(15px);
    z-index: 10;
  } */
`

const StyledNewsContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 10;

  gap: 32px;
`

const StyledNew = styled.div`
  position: relative;

  width: 713px;
  height: auto;
  padding: 29px 52px;

  outline: 1px solid rgba(255, 255, 255, 0.15);
  border-radius: 0px 6px;

  background: url(${newPrev});
  background-repeat: no-repeat;
  background-color: transparent;
  background-position: center center;

  background-size: cover;

  overflow: hidden;
`

const StyledBlur = styled.div`
  position: absolute;

  top: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
  /* backdrop-filter: blur(3px); */
  border-radius: 0px 6px;
  /* background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.6) 0%,
    rgba(0, 0, 0, 0) 180.75%
  ); */
`

const StyledButton = styled.button`
  position: relative;
  border-radius: 3px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background-color: rgba(255, 255, 255, 0.03);
  /* BlurEffect */
  backdrop-filter: blur(7.5px);
  padding: 7.5px 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
  font-family: Ruberoid;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 114.286% */
  text-transform: uppercase;
  &:hover {
    background-color: rgba(255, 255, 255, 0.15);
  }
  transition: 0.3s;
  outline: none;
`

const StyledNewsTitle = styled(Header3)`
  position: relative;
  text-transform: uppercase;
  padding-bottom: 16px;
  z-index: 3;
  line-height: 115%; /* 29.9px */
  overflow: hidden;
`

const StyledNewsDescription = styled(Text5)`
  position: relative;
  color: rgba(255, 255, 255, 0.7);
  padding-bottom: 24px;
  line-height: 125%;
  overflow: hidden;
  z-index: 3;
`

const StyledScroll = styled.div`
  height: 740px;
  overflow-y: scroll;
  padding: 5px 2px 5px 2px;
  position: relative;
  z-index: 5;

  /* border-radius: 20px;
  background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.5) -9.12%,
      rgba(0, 0, 0, 0) 32.64%
    ),
    linear-gradient(180deg, rgba(0, 0, 0, 0.5) -39.7%, rgba(0, 0, 0, 0) 99.62%);

  filter: blur(24.5px); */
  &::-webkit-scrollbar {
    width: 2px;
  }

  &::-webkit-scrollbar-track {
    margin: 4px 0 4px;
    border-radius: 1px;
    opacity: 0.1;
    background: rgba(217, 217, 217, 0.1);
  }

  &::-webkit-scrollbar-thumb {
    background-color: white;
    border-radius: 1px;
  }
`

const StyledModalContentWrapper = styled.div`
  position: relative;
  width: 755px;
  height: 717px;

  padding: 77px 52px;

  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.8) 0%,
    rgba(0, 0, 0, 0) 180.75%
  );

  background-image: url(${newImage2});
  backdrop-filter: blur(4px);
  overflow-y: auto;

  background-repeat: no-repeat;
  background-position: center center;

  background-size: cover;

  border-radius: 0px 6px;
  outline: 1px solid rgba(255, 255, 255, 0.15);
  &::-webkit-scrollbar {
    width: 2px;
  }

  &::-webkit-scrollbar-track {
    margin: 4px 0 4px;
    border-radius: 1px;
    opacity: 0.1;
    background: rgba(217, 217, 217, 0.1);
  }

  &::-webkit-scrollbar-thumb {
    background-color: white;
    border-radius: 1px;
  }
  z-index: 3;

  & h1 {
    font-family: Ruberoid;
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: 115%; /* 29.9px */
    text-transform: uppercase;

    padding-bottom: 16px;
    position: relative;
    z-index: 1;
  }

  & h2 {
    color: #fff;

    /* Header H5 Uppercase */
    font-family: Ruberoid;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 16px */
    text-transform: uppercase;
  }

  & h3 {
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 125%; /* 20px */
  }
`

const StyledModalContentText = styled.div`
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%; /* 20px */
  position: relative;
  white-space: pre-wrap;
  z-index: 1;
`

const StyledModalBlur = styled.div`
  position: absolute;

  top: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
  border-radius: 0px 6px;

  z-index: 0;
`

const NewsBlock = () => {
  const { t } = useTranslation()
  const user = useStore($userProfile)

  const [showedAticleId, setShowedAticleId] = useState<string | null>(null)

  const showedAticle: Article = useMemo(() => {
    return news().filter((article) => article.id === String(showedAticleId))[0]
  }, [showedAticleId])

  return (
    <>
      <Modal
        isOpen={Boolean(showedAticleId)}
        onClose={() => setShowedAticleId(null)}
      >
        {showedAticle && (
          <StyledModalContentWrapper>
            <StyledModalContentText>
              {/* <Markdown children={showedAticle.text} /> */}
              <Markdown>{showedAticle.text}</Markdown>
            </StyledModalContentText>
            <StyledModalBlur />
          </StyledModalContentWrapper>
        )}
      </Modal>
      <StyledNewsWrapper>
        <StyleTitle>{t('home.news')}</StyleTitle>
        <StyledScroll>
          <StyledNewsContainer>
            {user &&
              news().map((article) => (
                <StyledNew key={article.id}>
                  <StyledNewsTitle>{article.title}</StyledNewsTitle>
                  <StyledNewsDescription>
                    {article.description}
                  </StyledNewsDescription>
                  <StyledButton onClick={() => setShowedAticleId(article.id)}>
                    {t('home.readMore')}
                  </StyledButton>
                  <StyledBlur />
                </StyledNew>
              ))}
          </StyledNewsContainer>
        </StyledScroll>
      </StyledNewsWrapper>
    </>
  )
}

export default NewsBlock
