import { $createdAppeal } from 'stores/chatStores/appealStore'
import { ChangeEvent } from 'react'
import { Header6 } from 'styles/typography'
import { useStore } from '@nanostores/react'
import TextArea from 'components/TextArea'
import styled from 'styled-components'

interface DescriptionBlockProps {
  label?: string
}

const DescriptionBlock: React.FC<DescriptionBlockProps> = ({ label }) => {
  const createdAppeal = useStore($createdAppeal)

  const handleChangeDescription = (e: ChangeEvent<HTMLTextAreaElement>) => {
    $createdAppeal.set({
      ...createdAppeal,
      description: e.target.value,
    })
  }

  return (
    <StyledFilesWrapper>
      {label ? <StyledLabel>{label}</StyledLabel> : null}
      <TextArea
        value={createdAppeal.description || ''}
        onChange={handleChangeDescription}
      />
    </StyledFilesWrapper>
  )
}

export default DescriptionBlock

const StyledLabel = styled(Header6)``

const StyledFilesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`
