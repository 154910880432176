import styled from 'styled-components'

const Wrapper = styled.div`
  position: absolute;
  height: 272px;
  display: flex;
  align-items: center;
`

export default Wrapper
