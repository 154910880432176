/* eslint-disable import/no-unresolved */
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'
import { Controller, Mousewheel, Navigation, Scrollbar } from 'swiper/modules'
import { DtoCountry } from 'api/schemas/lobbyApi'
import { DtoServerResponse } from 'api/schemas/gamecontroller'
import { Header5, Text6 } from 'styles/typography'
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react'
import { useDebouncedCallback } from 'use-debounce'
import { useTranslation } from 'react-i18next'
import React, { useEffect, useMemo, useState } from 'react'
import api from 'api/api'
import getPing from 'utils/getPing'
import styled, { css } from 'styled-components'

interface StyledMenuWrapper {
  $isShow?: boolean
  $disabled?: boolean
}

const StyledWrapper = styled.div<StyledMenuWrapper>`
  position: relative;
  width: 85px;
  height: 308px;

  background: rgba(255, 255, 255, 0.03);

  border: 1px solid rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(5px);

  border-radius: 3px;
  opacity: ${({ $disabled }) => ($disabled ? '0.7' : '1')};
`

const StyledSwiper = styled(Swiper)`
  width: 100%;
  height: 230px;
  margin: 7px 0;
`

const StyledSwiperSlide = styled(SwiperSlide)`
  text-align: center;
  font-size: 18px;
  color: black;

  display: flex;
  justify-content: center;
  align-items: center;
`

interface StyledItemProps {
  $isActive: boolean
  disabled?: boolean
}

const StyledItem = styled.div<StyledItemProps>`
  padding: 8px 0;
  width: 100%;
  display: flex;
  justify-content: center;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  ${({ $isActive }) => css`
    background-color: ${$isActive ? 'rgba(217, 217, 217, 0.1)' : 'transparent'};
  `}
`

interface StyledNavigationProps {
  $isHidden: boolean
}

const StyledNext = styled.button<StyledNavigationProps>`
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 10;
  ${({ $isHidden }) => css`
    opacity: ${$isHidden ? '0' : '1'};
    cursor: ${$isHidden ? 'default' : 'pointer'};
  `};

  transition: 0.3s;
`
const StyledPrev = styled.button<StyledNavigationProps>`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  z-index: 10;
  ${({ $isHidden }) => css`
    opacity: ${$isHidden ? '0' : '1'};
    cursor: ${$isHidden ? 'default' : 'pointer'};
  `};

  transition: 0.3s;
`

const StyledPingWrapper = styled(Text6)`
  color: rgba(255, 255, 255, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 7px;
`

interface StyledPingNumberProps {
  $isRed: boolean
}

const StyledPingNumber = styled(Header5)<StyledPingNumberProps>`
  color: ${({ $isRed }) => ($isRed ? '#ff2c2c' : '#2CFF80')};
  font-weight: normal;
  margin-right: 3px;
`

const StyledServer = styled.img`
  width: 27px;
  height: 17px;
`

const StyledRoot = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  height: 24px;
  width: 100%;
`

const StyledLine = styled.div`
  width: 100%;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  margin-bottom: -6px;
`

interface ServerChoiserProps {
  disabled?: boolean
  servers?: DtoCountry[]
  currentServer?: DtoCountry
  onChangeCurrentServer?: (id: string) => void
}

const ServerChoiser: React.FC<ServerChoiserProps> = ({
  servers,
  disabled,
  currentServer,

  onChangeCurrentServer,
}) => {
  const { t } = useTranslation()

  const [controlledSwiper, setControlledSwiper] = useState<SwiperClass | null>(
    null
  )
  const [currentPing, setCurrentPing] = useState<number>(0)

  const currentIndex = useMemo(() => {
    return (
      servers?.reduce((acc, server, index) => {
        if (server.name === currentServer?.name) {
          acc = index
        }
        return acc
      }, 0) || 0
    )
  }, [currentServer, servers])

  const swiperRef = React.useRef<SwiperClass>()

  const handleChangeServer = useDebouncedCallback((id: string) => {
    if (onChangeCurrentServer && id !== currentServer?.name) {
      onChangeCurrentServer(id)
    }
  }, 400)

  useEffect(() => {
    if (!onChangeCurrentServer || disabled) {
      swiperRef.current?.disable()
    } else {
      swiperRef.current?.enable()
    }
  }, [onChangeCurrentServer, disabled])

  useEffect(() => {
    if (currentServer) {
      swiperRef.current?.enable()
      swiperRef.current?.slideTo(currentIndex)
      !onChangeCurrentServer && swiperRef.current?.disable()
      disabled && swiperRef.current?.disable()
    } else {
      onChangeCurrentServer &&
        servers &&
        onChangeCurrentServer(servers[0]?.name || '')
    }
  }, [currentServer, disabled, currentIndex, onChangeCurrentServer, servers])

  useEffect(() => {
    const fetchData = async () => {
      const { data } = currentServer?.name
        ? await api.server.getList(currentServer.name)
        : { data: [] }

      const dataWithPingPromise = data.map(async (item: DtoServerResponse) => {
        const ping = await getPing(item && `${item.ip}`)
        return { ...item, ping }
      }) as Promise<{ ip: string; ping: string; port: string }>[]

      const dataWithPing = await Promise.all(dataWithPingPromise)

      const bestServer = dataWithPing.reduce((acc, item) => {
        if (acc.ping > item.ping) {
          return item
        }
        return acc
      }, dataWithPing[0])
      setCurrentPing(Number(bestServer?.ping) || 999)
      // ...
    }

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    fetchData()
  }, [currentServer?.name])

  return (
    <StyledRoot>
      <StyledHeader>
        <StyledLine />
      </StyledHeader>
      <StyledWrapper $disabled={disabled}>
        <StyledPrev
          $isHidden={currentIndex === 0}
          onClick={() => swiperRef.current?.slidePrev()}
        >
          <svg
            width="8"
            height="5"
            fill="none"
            viewBox="0 0 8 5"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill="#D9D9D9"
              d="M4 0.65274L7.4641 4.33039L0.535898 4.33039L4 0.65274Z"
            />
          </svg>
        </StyledPrev>
        <StyledSwiper
          scrollbar
          centeredSlides
          slidesPerView={7}
          mousewheel={true}
          direction="vertical"
          className="mySwiper"
          onSwiper={setControlledSwiper}
          controller={{ control: controlledSwiper }}
          modules={[Mousewheel, Scrollbar, Controller, Navigation]}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onBeforeInit={(swiper: any) => {
            swiperRef.current = swiper
            setControlledSwiper(swiper)
          }}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onSlideChange={(el: any) => {
            setControlledSwiper(el)
            onChangeCurrentServer &&
              handleChangeServer(servers?.[el.activeIndex]?.name || '')
          }}
        >
          {servers?.map((item, index) => (
            <StyledSwiperSlide key={`${index}_${item}`}>
              {({ isActive }: { isActive: boolean }) => (
                <StyledItem
                  disabled={disabled}
                  $isActive={isActive}
                  onClick={() =>
                    controlledSwiper?.slideTo(controlledSwiper?.clickedIndex)
                  }
                >
                  {item.imgUrl ? <StyledServer src={item.imgUrl} /> : item.name}
                </StyledItem>
              )}
            </StyledSwiperSlide>
          ))}
        </StyledSwiper>
        <StyledNext
          onClick={() => swiperRef.current?.slideNext()}
          $isHidden={!!servers && currentIndex === servers?.length - 1}
        >
          <svg
            width="8"
            height="4"
            fill="none"
            viewBox="0 0 8 4"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill="#D9D9D9"
              d="M4 3.79102L0.535904 0.113364L7.4641 0.113363L4 3.79102Z"
            />
          </svg>
        </StyledNext>
        <StyledPingWrapper>
          <StyledPingNumber $isRed={currentPing > 100}>
            {currentPing}
          </StyledPingNumber>
          {t('play.ms')}
        </StyledPingWrapper>
      </StyledWrapper>
    </StyledRoot>
  )
}

StyledSwiperSlide.displayName = 'SwiperSlide'

export default ServerChoiser
