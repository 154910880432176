import { Outlet } from 'react-router-dom'
// import csBG1 from 'images/Background CS 1.webp'
import csBG2 from 'images/Background CS 2.webp'
import styled from 'styled-components'

const StyledWrapper = styled.div`
  z-index: 0;

  height: 100%;
  width: 100%;
`

// const StyledBGBase = styled.div`
//   position: absolute;
//   height: 100vh;
//   width: 100vw;
//   left: 0;
//   top: 0;
//   background-image: url('${csBG1}');

//   background-position: center;
//   background-size: cover;
//   /* animation-duration: 10s;
//   animation-name: ${glitchAnim};
//   animation-iteration-count: infinite;
//   animation-timing-function: linear;
//   transform: translateZ(0); */
//   z-index: -1;

//   background-repeat: no-repeat;
// `

const StyledBGLayer = styled.div`
  position: absolute;
  height: 100vh;
  width: 100vw;
  background-image: url('${csBG2}');
  transform: translateZ(0);
  left: 0;
  top: 0;
  background-position: center;
  background-size: cover;
  /* animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-timing-function: linear; */
  z-index: -1;
  background-repeat: no-repeat;
`

const StyledContainer = styled.div`
  /* padding-top: 80px; */
  height: 100%;
  width: 1646px;
  margin: 0 auto;
`

const CSGOLayout: React.FC = () => {
  return (
    <>
      {/* <StyledBGBase /> */}
      <StyledBGLayer />
      <StyledWrapper>
        <StyledContainer>
          <Outlet />
        </StyledContainer>
      </StyledWrapper>
    </>
  )
}

export default CSGOLayout
