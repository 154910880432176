import { $appealMatchesHistory } from 'stores/statisticStore'
import {
  $createdAppeal,
  $getReportedPlayers,
} from 'stores/chatStores/appealStore'
import { Text5 } from 'styles/typography'
import { getMatchText } from 'utils/getMatchText'
import { useState } from 'react'
import { useStore } from '@nanostores/react'
import { useTranslation } from 'react-i18next'
import ImageModal from 'components/ImageModal'
import styled from 'styled-components'

const StyledGeneratedWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: 100%;
`

const StyledGeneratedItem = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
  justify-content: start;
  /* text-transform: capitalize; */
`
const StyledGeneratedItemCaption = styled(Text5)`
  color: #2cff80;
  line-height: 120%;
`
const StyledGeneratedItemDescription = styled(Text5)`
  color: white;
  line-height: 120%;
`
const StyledGeneratedMiddle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`
const StyledInputLabel = styled(Text5)`
  color: #2cff80;
`

const StyledGeneratedBottom = styled(StyledGeneratedMiddle)``
const StyledFilesList = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`

const StyledFile = styled.img`
  width: 75px;
  height: 75px;
  border: 1px solid rgba(255, 255, 255, 0.15);
  object-fit: contain;
  cursor: pointer;
  border-radius: 2px;
  overflow: hidden;
  cursor: pointer;
`

const StyledImgWrapper = styled.div`
  cursor: pointer;
`

const GeneratedAppeal: React.FC = () => {
  const { t } = useTranslation()
  const createdAppeal = useStore($createdAppeal)
  const matchesHistory = useStore($appealMatchesHistory)
  const getReportedPlayers = useStore($getReportedPlayers)

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [currentImageIndex, setCurrentImageIndex] = useState(0)

  const getReasonText = () => {
    const reasonArr = createdAppeal?.reason?.filter((item) => item.isActive)
    if (!reasonArr || reasonArr.length === 0) return null

    return reasonArr.reduce(
      (acc, curr) => {
        acc.name += `${curr.name}; `
        return acc
      },
      { name: '', descr: '' }
    )
  }

  const downloadedFiles = createdAppeal.downloadFiles ?? []

  const files = downloadedFiles.map((file, index) => {
    return (
      <StyledImgWrapper
        key={file.id}
        onClick={() => {
          setCurrentImageIndex(index)
          setIsModalOpen(true)
        }}
      >
        <StyledFile src={file.url} />
      </StyledImgWrapper>
    )
  })

  const getText = () => {
    if (!matchesHistory) return ''
    const match = matchesHistory.find(
      (match) => match.id === $createdAppeal.get().reportedMatchId
    )
    return match ? getMatchText(match) : ''
  }

  return (
    <StyledGeneratedWrapper>
      <StyledGeneratedItem>
        <StyledGeneratedItemCaption>
          {t('chat.appeal.category')}:
        </StyledGeneratedItemCaption>
        <StyledGeneratedItemDescription>
          {createdAppeal.category
            ? t(`chat.appeal.${createdAppeal.category}`)
            : ''}
        </StyledGeneratedItemDescription>
      </StyledGeneratedItem>
      {createdAppeal.game && (
        <StyledGeneratedItem>
          <StyledGeneratedItemCaption>
            {t('chat.appeal.game')}:
          </StyledGeneratedItemCaption>
          <StyledGeneratedItemDescription>
            {createdAppeal.game}
          </StyledGeneratedItemDescription>
        </StyledGeneratedItem>
      )}
      {createdAppeal.reportedMatchId && (
        <StyledGeneratedItem>
          <StyledGeneratedItemCaption>
            {t('chat.appeal.matchID')}:
          </StyledGeneratedItemCaption>
          <StyledGeneratedItemDescription>
            {getText()}
          </StyledGeneratedItemDescription>
        </StyledGeneratedItem>
      )}
      {getReportedPlayers?.entirely && (
        <StyledGeneratedItem>
          <StyledGeneratedItemCaption>
            {t('chat.appeal.player')}:
          </StyledGeneratedItemCaption>
          <StyledGeneratedItemDescription>
            {getReportedPlayers.entirely}
          </StyledGeneratedItemDescription>
        </StyledGeneratedItem>
      )}
      {createdAppeal?.timeCodes && (
        <StyledGeneratedItem>
          <StyledGeneratedItemCaption>
            {t('chat.appeal.timeCodes')}:
          </StyledGeneratedItemCaption>
          <StyledGeneratedItemDescription>
            {createdAppeal?.timeCodes?.[0]?.[0]} -{' '}
            {createdAppeal?.timeCodes?.[0]?.[1]}
          </StyledGeneratedItemDescription>
        </StyledGeneratedItem>
      )}
      {getReasonText() && (
        <StyledGeneratedItem>
          <StyledGeneratedItemCaption>
            {t('chat.appeal.reason')}:
          </StyledGeneratedItemCaption>
          <StyledGeneratedItemDescription>
            {getReasonText()?.name?.slice(0, -2) || ''}
          </StyledGeneratedItemDescription>
        </StyledGeneratedItem>
      )}
      <StyledGeneratedItem>
        <StyledGeneratedItemCaption>
          {t('chat.appeal.yourDescr')}:
        </StyledGeneratedItemCaption>
        <StyledGeneratedItemDescription>
          {createdAppeal.description || ''}
        </StyledGeneratedItemDescription>
      </StyledGeneratedItem>

      {createdAppeal.downloadFiles?.length ? (
        <StyledGeneratedBottom>
          <StyledInputLabel>{t('chat.appeal.additionFiles')}:</StyledInputLabel>
          <StyledFilesList>
            {files}

            {isModalOpen && (
              <ImageModal
                images={downloadedFiles}
                initialIndex={currentImageIndex}
                onClose={() => setIsModalOpen(false)}
              />
            )}
          </StyledFilesList>
        </StyledGeneratedBottom>
      ) : (
        ''
      )}
    </StyledGeneratedWrapper>
  )
}

export default GeneratedAppeal
