import { Header9 } from 'styles/typography'
import styled from 'styled-components'

interface Counter {
  position?: Position
  withoutCount?: boolean
  color?: 'green' | 'gold'
  value: number | string | boolean
}

type Color = 'green' | 'gold'

interface StyledCounterProps {
  $color: Color
  $position: Position
}

type Position =
  | 'top-left'
  | 'top-right'
  | 'bottom-left'
  | 'bottom-right'
  | undefined

const getPositionType = (position: Position) => {
  switch (position) {
    case 'top-right':
      return 'position: absolute; top: -7px;  right: -8px;'
    case 'top-left':
      return 'position: absolute; top: -7px;  left: -8px;'
    case 'bottom-right':
      return 'position: absolute; bottom: -7px;  right: -8px;'
    case 'bottom-left':
      return 'position: absolute; bottom: -7px;  left: -8px;'
    default:
      return 'position: unset;'
  }
}

const getColor = (color: Color) => {
  switch (color) {
    case 'gold':
      return '#FFCD29'
    case 'green':
    default:
      return '#2cff80'
  }
}

const StyledCounter = styled(Header9)<StyledCounterProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  color: #000000;
  font-weight: 700;
  min-width: 15px;
  height: 15px;
  padding: 0 4px;
  font-family: 'Ruberoid';
  border-radius: 16px;

  background: ${({ $color }) => getColor($color)};
  ${({ $position }) => getPositionType($position)};
`

const Counter: React.FC<Counter> = ({
  color,
  value,
  position,
  withoutCount,
}) => {
  return (
    <>
      {withoutCount ? (
        value ? (
          <StyledCounter $position={position} $color={color || 'green'}>
            {value}
          </StyledCounter>
        ) : null
      ) : (
        <StyledCounter $position={position} $color={color || 'green'}>
          {value}
        </StyledCounter>
      )}
    </>
  )
}

export default Counter
