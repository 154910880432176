import { Controller, FieldError, SubmitHandler, useForm } from 'react-hook-form'
import { Header3, Header5, Link, Text4, TextLink } from 'styles/typography'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import BigLogo from 'images/BigLogo'
import CloseEyeIcon from 'images/CloseEyeIcon'
import CodeInput from 'components/CodeInput/CodeInput'
import FormButton from 'components/FormButton'
import Input from 'components/Input'
import PhoneNumberInput from 'components/PhoneNumberInput'
import TellUsYourProblem from 'components/TellUsYourProblem'
import api, { apiAuth } from 'api/api'
import formatNumber from 'utils/formatNumber'
import routes from 'router/routes'
import styled from 'styled-components'

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 61px 0;
  width: 528px;
  align-items: center;
`

const StyledTitle = styled(Header3)`
  color: #f2f2f2;

  margin: 32px 0 38px 0;
  opacity: 0.32;
  letter-spacing: 2.4px;
  text-transform: uppercase;
  text-align: center;
`

const StyledFooter = styled(Text4)`
  color: rgba(255, 255, 255, 0.25);
  text-align: center;

  font-weight: 500;
`

const StyledContainer = styled.form`
  width: 100%;
  height: 100%;

  display: flex;

  flex-direction: column;

  align-items: center;
  justify-content: end;
`

const StyledForm = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 550px;
  width: 100%;
`

const StyledButton = styled(FormButton)`
  margin-top: 23px;
`

const StyledButtonContent = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

const StyledButtonText = styled.div`
  font-size: 500;
  text-transform: uppercase;
`

const StyledButtonStepCounter = styled.div`
  color: #ffcd29;
`

const StyledCodeText = styled(Text4)`
  text-align: center;
`

interface StyledResend {
  $disabled?: boolean
}

const StyledResend = styled.span<StyledResend>`
  color: ${({ $disabled }) =>
    $disabled ? ' rgba(255, 255, 255, 0.45);' : '#2cff80'};
  transition: 0.2s;

  cursor: pointer;

  &:hover {
    ${({ $disabled }) =>
      $disabled ? ' rgba(255, 255, 255, 0.45);' : 'rgba(44, 255, 128, 0.3)'};
  }
`

const StyledTooltip = styled(Text4)`
  width: 100%;
  text-align: center;
`

const StyledLabel = styled(Header5)`
  color: #ffcd29;
  width: 100%;
  text-align: center;
  text-transform: uppercase;
`

const StyledCodeInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: center;
`

const StyledCodeInfoExampleWrapper = styled(Text4)`
  display: flex;
  align-items: center;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.45);
  gap: 4px;
`

const StyledCodeInfoExampleBordered = styled(Text4)`
  font-weight: 500;
  color: var(--Desktop-Yellow, #ffcd29);
  display: flex;
  padding: 2px 5px;
  border-radius: 3px;
  border: 1px solid rgba(255, 205, 41, 0.5);
`

interface RegistrationFormDataProps {
  code: string
  number: string
  password: string
  passwordSecond: string
}

const ResetPasswordPage = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [step, setStep] = useState<1 | 2 | 3>(1)
  const [timer, setTimer] = useState<number>(59)
  const [error, setError] = useState<string>('')
  const [code, setCode] = useState<string>('')

  const [isShowPassword, setIsShowPassword] = useState<boolean>(false)
  const [isShowPasswordSecond, setIsShowPasswordSecond] =
    useState<boolean>(false)

  const { t } = useTranslation()

  const navigate = useNavigate()

  const {
    watch,
    control,
    setValue,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<RegistrationFormDataProps>()

  const password = watch('password')
  const number = watch('number')

  const onSubmit: SubmitHandler<RegistrationFormDataProps> = async ({
    number,
    password,
  }) => {
    if (!isLoading) {
      setIsLoading(true)
      if (step === 1) {
        try {
          await api.code.getSendPhoneCode({
            phoneNumber: formatNumber(number),
          })

          setStep(2)
        } catch (e) {
          console.log(e)
          // showNotification(
          //   'Неверный токен или пользователь уже существует',
          //   'error'
          // )
        }

        setIsLoading(false)

        return
      }

      if (step === 2) {
        try {
          await api.code.postVerifyCode({
            login: formatNumber(number),
            verificationCode: Number(code),
          })

          setStep(3)
        } catch (e) {
          setError(t('registation.codeField.wrong') || '')
          setValue('code', '')
        }
      }
      if (step === 3) {
        try {
          await apiAuth.user.postPasswordForget({
            password,
            login: formatNumber(number),
            verificationCode: Number(code),
          })

          navigate(routes.login())
          //   showNotification('Регистрация прошла успешно')
        } catch (e) {
          //   showNotification('Неверный токен', 'error')
        }
      }

      setIsLoading(false)
    }
  }

  const tick = useCallback((count: number) => {
    if (count !== 0) {
      setTimer(count - 1)
      setTimeout(() => tick(count - 1), 1000)
    }
  }, [])

  const handleResendCode = async () => {
    if (!timer) {
      await api.code.getSendPhoneCode({
        phoneNumber: formatNumber(number),
      })

      //   showNotification('Код отправлен')
      setTimer(59)
      tick(60)
    }
  }

  const handleChangeCode = (value: string) => {
    if (!isLoading && value.length === 4) {
      //   setIsLoading(true)

      //   try {
      //     // await checkCodeApi({ login: email, code: value })
      //     if (value !== '1111') {
      //       throw new Error()
      //     }
      // setStep(3)
      setCode(value)
      //   } catch (e) {
      //     setError('Wrong code')
      //     setValue('code', '')
      //   }
      //   setIsLoading(false)
    }
  }

  useEffect(() => {
    if (step === 2) {
      tick(timer)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step])

  const getPasswordError = (error?: FieldError) => {
    if (!error) {
      return
    }
    switch (error.type) {
      case 'lat':
        return t('registation.passwordField.errors.lat')
      case 'num':
        return t('registation.passwordField.errors.num')
      case 'minLength':
        return t('registation.passwordField.errors.minLength')
      case 'down':
        return t('registation.passwordField.errors.down')
      case 'up':
        return t('registation.passwordField.errors.up')
      case 'symbol':
        return t('registation.passwordField.errors.symbol')
      default:
        return t('registation.passwordField.errors.required')
    }
  }

  const handleShowPassword = () => {
    setIsShowPassword(!isShowPassword)
  }

  const handleShowPasswordSecond = () => {
    setIsShowPasswordSecond(!isShowPasswordSecond)
  }

  //   const handleGoRestore = () => {
  //     navigate(routes.resetPassword())
  //   }

  //   useEffect(() => {
  //     registationToken.set('')
  //   }, [])
  console.log(error, errors)
  return (
    <StyledWrapper>
      <TellUsYourProblem />
      <StyledContainer onSubmit={handleSubmit(onSubmit)}>
        <BigLogo />
        <StyledTitle>{t('restorePassword.title')}</StyledTitle>

        <StyledForm>
          {step === 1 && (
            <Controller
              name="number"
              defaultValue=""
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <PhoneNumberInput
                  id={'number'}
                  label={t('auth.numberField.label')}
                  error={errors.number && t('auth.numberField.errors.required')}
                  {...field}
                />
              )}
            />
          )}
          {step === 2 && (
            <>
              <StyledLabel>{t('registation.codeField.getCode')}</StyledLabel>
              <StyledCodeInfoWrapper>
                <StyledTooltip>
                  {t('registation.codeField.lastNumberTooltip')}
                </StyledTooltip>
                <StyledCodeInfoExampleWrapper>
                  {t('registation.codeField.forExample')} + 7 (***) ***{' '}
                  <StyledCodeInfoExampleBordered>
                    20 04
                  </StyledCodeInfoExampleBordered>
                </StyledCodeInfoExampleWrapper>
                <StyledTooltip>
                  {t('registation.codeField.dontAnswer')}
                </StyledTooltip>
              </StyledCodeInfoWrapper>

              <StyledTooltip>
                {t('registation.codeField.codeSended', { number })}{' '}
                <TextLink onClick={() => setStep(1)}>
                  {t('registation.codeField.changeNumber')}
                </TextLink>
              </StyledTooltip>
              <Controller
                name="code"
                defaultValue=""
                control={control}
                render={({ field }) => (
                  <CodeInput
                    error={error}
                    {...field}
                    onChange={handleChangeCode}
                  />
                )}
              />
              <StyledCodeText>
                {timer ? (
                  <StyledResend $disabled={true}>
                    {t('registation.codeField.resendCodeTimer', { timer })}
                  </StyledResend>
                ) : (
                  <>
                    {t('registation.codeField.dontGetCode')}{' '}
                    <StyledResend onClick={handleResendCode}>
                      {' '}
                      {t('registation.codeField.resendCode')}
                    </StyledResend>
                  </>
                )}
              </StyledCodeText>
            </>
          )}
          {step === 3 && (
            <>
              <StyledLabel>{t('registation.passwordField.label')}</StyledLabel>
              <StyledTooltip>
                {t('registation.passwordField.tooltip')}
              </StyledTooltip>
              <Controller
                defaultValue=""
                name="password"
                control={control}
                rules={{
                  required: true,
                  validate: {
                    lat: (v) => /[A-Za-z]+/.test(v),
                    // eslint-disable-next-line perfectionist/sort-objects
                    up: (v) => /[A-Z]+/.test(v),
                    down: (v) => /[a-z]+/.test(v),
                    // eslint-disable-next-line perfectionist/sort-objects
                    num: (v) => /[1-9]+/.test(v),
                    minLength: (v) => v.trim().length >= 8,
                    symbol: (v) => /[!@#$%^&*`/\\[\]().,?]+/.test(v),
                  },
                }}
                render={({ field }) => (
                  <Input
                    id={'password'}
                    error={getPasswordError(errors.password)}
                    onClickRightDeacorator={handleShowPassword}
                    type={isShowPassword ? 'text' : 'password'}
                    label={t('registation.passwordField.label')}
                    placeholder={
                      t('registation.passwordField.placeholder') || ''
                    }
                    rightDecorator={
                      isShowPassword ? <CloseEyeIcon /> : <CloseEyeIcon />
                    }
                    {...field}
                  />
                )}
              />

              <Controller
                defaultValue=""
                control={control}
                name="passwordSecond"
                rules={{
                  required: true,
                  validate: { correct: (v) => v === password },
                }}
                render={({ field }) => (
                  <Input
                    id={'passwordSecond'}
                    onClickRightDeacorator={handleShowPasswordSecond}
                    type={isShowPasswordSecond ? 'text' : 'password'}
                    placeholder={
                      t('registation.secondPasswordField.placeholder') || ''
                    }
                    rightDecorator={
                      isShowPasswordSecond ? <CloseEyeIcon /> : <CloseEyeIcon />
                    }
                    error={
                      errors.passwordSecond &&
                      t('registation.secondPasswordField.errors.match')
                    }
                    {...field}
                  />
                )}
              />
            </>
          )}

          <StyledButton
            type="submit"
            isLoading={isLoading}
            disabled={!isValid && step !== 2}
          >
            <StyledButtonContent>
              <StyledButtonText>{t('registation.next')}</StyledButtonText>
              <StyledButtonStepCounter>
                {t('registation.steps', { end: 3, start: step })}
              </StyledButtonStepCounter>
            </StyledButtonContent>
          </StyledButton>
        </StyledForm>
      </StyledContainer>

      <StyledFooter>
        {t('registation.back')}
        {` `}
        <Link to={routes.login()}>{t('auth.login')}</Link>
      </StyledFooter>
    </StyledWrapper>
  )
}

export default ResetPasswordPage
