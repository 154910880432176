import 'index.css'

import {
  Navigate,
  Outlet,
  Route,
  RouterProvider,
  createHashRouter,
  createRoutesFromElements,
} from 'react-router-dom'
import CSGOLayout from 'layouts/CSGOLayout'

import routes from 'router/routes'

import 'i18n/config'

import AuthLayout from 'layouts/AuthLayout'
import CSGOPersonalStatisticPage from 'pages/statistic/CSGOPersonalStatisticPage'
import ChooseGamePage from 'pages/ChooseGamePage'
import CustomGameCSPage from 'pages/lobby/CustomGameCSPage'
import CustomGameDotaPage from 'pages/lobby/CustomGameDotaPage'
import DotaLayout from 'layouts/DotaLayout'
import ErrorPage from 'pages/ErrorPage'
import GameResolver from 'utils/GameResolver'
import HomeCSPage from 'pages/HomeCSPage'
import JudgeMyTicketsPage from 'pages/judge/JudgeMyTicketsPage'
import JudgeWorkPage from 'pages/judge/JudgeWorkPage'
import Loading from 'components/Loading'
import LobbyChoiser from 'pages/lobby/LobbyChoiser'
import LoginPage from 'pages/LoginPage'
import MainLayout from 'layouts/MainLayout'
import PlayCSLayout from 'layouts/PlayCSLayout'
import PlayDotaLayout from 'layouts/PlayDotaLayout'
import Raiting1x1GameCSPage from 'pages/lobby/Raiting1x1GameCSPage'
import Raiting5GameCSPage from 'pages/lobby/Raiting5GameCSPage'
import Raiting5GameDotaPage from 'pages/lobby/Raiting5GameDotaPage'
import RegistrationPage from 'pages/RegistrationPage'
import RequireAuth from 'utils/RequireAuth'
import ResetPasswordPage from 'pages/ResetPasswordPage'
import SpectatingLayout from 'layouts/SpectatingLayout'
import StatisticLayout from 'layouts/StatisticLayout'
import mainLoader from 'loaders/mainLoader'
import statisticLoader from 'loaders/statisticLoader'

const routesElements = createRoutesFromElements(
  <Route>
    <Route
      path={routes.app()}
      errorElement={<ErrorPage />}
      element={
        <RequireAuth>
          <MainLayout />
        </RequireAuth>
      }
    >
      <Route loader={mainLoader}>
        <Route
          element={<GameResolver cs={<CSGOLayout />} dota={<DotaLayout />} />}
        >
          <Route element={<Outlet />}>
            <Route index element={<HomeCSPage />}></Route>
          </Route>
          <Route path={routes.play()}>
            <Route index element={<LobbyChoiser />}></Route>
            <Route
              element={
                <GameResolver cs={<PlayCSLayout />} dota={<PlayDotaLayout />} />
              }
            >
              <Route
                path={routes.playCustom()}
                element={
                  <GameResolver
                    cs={<CustomGameCSPage />}
                    dota={<CustomGameDotaPage />}
                  />
                }
              ></Route>
              <Route
                path={routes.playRating()}
                element={
                  <GameResolver
                    cs={<Raiting5GameCSPage />}
                    dota={<Raiting5GameDotaPage />}
                  />
                }
              ></Route>
              <Route
                path={routes.play1x1()}
                element={
                  <GameResolver
                    cs={<Raiting1x1GameCSPage />}
                    dota={<Raiting5GameDotaPage />}
                  />
                }
              ></Route>
            </Route>
          </Route>

          <Route loader={statisticLoader} path={routes.statistic()}>
            <Route element={<StatisticLayout />}>
              <Route
                index
                element={<Navigate to={routes.statisticCustom()} />}
              ></Route>

              <Route
                path={routes.statisticRating()}
                element={<CSGOPersonalStatisticPage />}
              ></Route>
              <Route
                path={routes.statistic1x1()}
                element={<CSGOPersonalStatisticPage />}
              ></Route>
              <Route
                path={routes.statisticCustom()}
                element={<CSGOPersonalStatisticPage />}
              ></Route>
            </Route>
          </Route>
          <Route path={routes.spectating()}>
            <Route element={<SpectatingLayout />}>
              <Route
                index
                element={<Navigate to={routes.spectatingJudge()} />}
              ></Route>
              <Route
                element={<JudgeWorkPage />}
                path={routes.spectatingJudge()}
              ></Route>
              <Route
                element={<JudgeMyTicketsPage />}
                path={routes.spectatingMyTickets()}
              ></Route>
              <Route></Route>
            </Route>
          </Route>
        </Route>
      </Route>
    </Route>
    <Route element={<AuthLayout />}>
      <Route path={routes.login()} element={<LoginPage />} />
      <Route path={routes.registration()} element={<RegistrationPage />} />
      <Route path={routes.resetPassword()} element={<ResetPasswordPage />} />
      <Route
        path={routes.chooseGame()}
        element={
          <RequireAuth>
            <ChooseGamePage />
          </RequireAuth>
        }
      />
    </Route>

    <Route path="*" element={<div>404</div>} />
  </Route>
)

const router = createHashRouter(routesElements)

export default function () {
  return <RouterProvider router={router} fallbackElement={<Loading />} />
}
