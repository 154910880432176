import { ApiError } from 'api/api'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import {
  FormError,
  Header3,
  Header5,
  Header6,
  Link,
  Text4,
  Text5,
} from 'styles/typography'
import { clearUserAuthenticatorCode } from 'stores/userStore'
import { login, logout } from 'stores/authStore'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import BigLogo from 'images/BigLogo'
import Checkbox from 'components/Checkbox'
import CloseEyeIcon from 'images/CloseEyeIcon'
import CodeInput from 'components/CodeInput/CodeInput'
import FormButton from 'components/FormButton'
import Input from 'components/Input'
import PhoneNumberInput from 'components/PhoneNumberInput'
import TellUsYourProblem from 'components/TellUsYourProblem'
import routes from 'router/routes'
import styled from 'styled-components'

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 61px 0;
  width: 528px;
  align-items: center;
`

const StyledTitle = styled(Header3)`
  color: #f2f2f2;

  margin: 32px 0 38px 0;
  opacity: 0.32;
  letter-spacing: 2.4px;
  text-transform: uppercase;
  text-align: center;
`

const StyledFooter = styled(Text4)`
  color: rgba(255, 255, 255, 0.25);
  text-align: center;

  font-weight: 500;
`

const StyledContainer = styled.form`
  width: 100%;
  height: 100%;

  display: flex;

  flex-direction: column;

  align-items: center;
  justify-content: end;
`

const StyledForm = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 550px;
  width: 100%;
`

const StyledButton = styled(FormButton)``

const StyledControls = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const StyledButtonText = styled(Header6)`
  text-transform: uppercase;
`

const StyledTooltip = styled(Text4)`
  width: 100%;
  text-align: center;
`

const StyledLabel = styled(Header5)`
  color: #ffcd29;
  width: 100%;
  text-align: center;
  text-transform: uppercase;
`

interface LoginFormDataProps {
  code?: string
  number: string
  password: string
}

enum totpCodeNeedError {
  CODE_REQUIRED = 'totp_code_required',
}

const LoginPage = () => {
  const [isShowPassword, setIsShowPassword] = useState<boolean>(false)
  const [isAnotherPC, setIsAnotherPC] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)
  const [step, setStep] = useState<'base' | 'google'>('base')
  const [numberValue, setNumber] = useState<string | null>(null)
  const [passwordValue, setPassword] = useState<string | null>(null)

  const { t } = useTranslation()

  const navigate = useNavigate()

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<LoginFormDataProps>()

  const onSubmit: SubmitHandler<LoginFormDataProps> = async ({
    number,
    password,
  }) => {
    if (!isLoading) {
      setIsLoading(true)

      try {
        // const data = await loginApi({ password, login: email })
        await login(number, password)

        navigate(routes.app())
      } catch (e) {
        const error = e as ApiError
        console.log(error)
        if (
          `${error.response.data.meta.code}` ===
            totpCodeNeedError.CODE_REQUIRED &&
          number &&
          password
        ) {
          setStep('google')
          setNumber(number)
          setPassword(password)
        } else if (error.response.data.meta.code) {
          setError(t('auth.serverError'))
        } else {
          setError(t('auth.wrongLoginOrPassword'))
        }
        // showNotification('Неверный логин или пароль', 'error')
      }

      setIsLoading(false)
    }
  }

  const handleShowPassword = () => {
    setIsShowPassword(!isShowPassword)
  }

  const handleChangeAnothrePC = (value: boolean) => {
    setIsAnotherPC(value)
    window.ipcRenderer.invoke('isAnotherPC', value)
  }

  const handleChangeCode = async (value: string) => {
    if (!isLoading && value.length === 6) {
      if (!numberValue || !passwordValue) {
        setStep('base')
        return
      }
      try {
        setIsLoading(true)
        await login(numberValue, passwordValue, value)
        clearUserAuthenticatorCode()
        navigate(routes.app())
      } catch (e) {
        setError(t('registation.codeField.wrong'))
      } finally {
        setIsLoading(false)
      }
    }
  }

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    logout()
  }, [])

  return (
    <StyledWrapper>
      <TellUsYourProblem />
      <StyledContainer onSubmit={handleSubmit(onSubmit)}>
        <BigLogo />
        <StyledTitle>{t('auth.login')}</StyledTitle>
        <StyledForm>
          {step === 'base' && (
            <>
              <Controller
                name="number"
                defaultValue=""
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <PhoneNumberInput
                    id={'number'}
                    label={t('auth.numberField.label')}
                    error={
                      errors.number && t('auth.numberField.errors.required')
                    }
                    {...field}
                  />
                )}
              />
              <Controller
                defaultValue=""
                name="password"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Input
                    id={'password'}
                    label={t('auth.passwordField.label')}
                    onClickRightDeacorator={handleShowPassword}
                    type={isShowPassword ? 'text' : 'password'}
                    placeholder={t('auth.passwordField.placeholder') || ''}
                    error={
                      errors.password && t('auth.passwordField.errors.required')
                    }
                    rightDecorator={
                      isShowPassword ? <CloseEyeIcon /> : <CloseEyeIcon />
                    }
                    secondLabel={
                      <Link to={routes.resetPassword()}>
                        {t('auth.forgetPasswordLink')}
                      </Link>
                    }
                    {...field}
                  />
                )}
              />
            </>
          )}
          {step === 'google' && (
            <>
              <StyledLabel>{t('auth.googleStepLabel')}</StyledLabel>
              <StyledTooltip>{t('auth.googleTooltip')}</StyledTooltip>
              <Controller
                name="code"
                defaultValue=""
                control={control}
                render={({ field }) => (
                  <CodeInput
                    length={6}
                    {...field}
                    onChange={handleChangeCode}
                  />
                )}
              />
            </>
          )}

          <FormError>{error || ` `}</FormError>
          <StyledControls>
            {step === 'base' && (
              <Checkbox isActive={isAnotherPC} onChange={handleChangeAnothrePC}>
                <Text5>{t('auth.anotherPC')}</Text5>
              </Checkbox>
            )}
            <StyledButton
              type="submit"
              isLoading={isLoading}
              disabled={!isValid || isLoading}
            >
              <StyledButtonText>{t('auth.submitLoginButton')}</StyledButtonText>
            </StyledButton>
          </StyledControls>
        </StyledForm>
      </StyledContainer>

      <StyledFooter>
        {t('auth.havntAccount')}
        {` `}
        <Link to={routes.registration()}>{t('auth.signUp')}</Link>
      </StyledFooter>
    </StyledWrapper>
  )
}

export default LoginPage
