import {
  $appealMessages,
  $isCheatAppeal,
  $isCreatingAppeal,
  getMoreMessages,
} from 'stores/chatStores/appealStore'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useStore } from '@nanostores/react'
import ArrowUpIcon from 'images/chat/ArrowUpIcon.svg'
import SupportChatInput from 'components/Chat/Support/supportComponents/SupportChatInput'
import SupportMessage from 'components/Chat/Support/supportComponents/SupportMessage'
import SupportStage from 'components/Chat/Support/SupportStage'
import debounce from 'utils/debounce'
import scrollChatToBottom from 'utils/scrollChatToBottom'
import styled from 'styled-components'

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7px;
  width: 100%;
  height: -webkit-fill-available;
  padding-left: 10px;
  padding-right: 20px;
`
const StyledChatField = styled.div`
  display: flex;
  justify-content: center;
  border: 1px solid rgba(255, 255, 255, 0.15);
  width: 100%;
  height: 100%;
  min-height: 100px;
  padding-right: 5px;
  position: relative;
`
const StyledScroll = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7px;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  padding: 12px;

  &::-webkit-scrollbar {
    width: 2px;
  }

  &::-webkit-scrollbar-track {
    margin: 4px 0 4px;
    border-radius: 1px;
    opacity: 0.1;
    background: rgba(217, 217, 217, 0.1);
  }

  &::-webkit-scrollbar-thumb {
    background-color: white;
    border-radius: 1px;
  }
`
const StyledArrowIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 5px;
  background-image: url(${ArrowUpIcon});
  width: 24px;
  height: 24px;
`

const SupportChat: React.FC = () => {
  const isCreatingAppeal = useStore($isCreatingAppeal)
  const isCheatAppeal = useStore($isCheatAppeal)
  const appealMessages = useStore($appealMessages)
  const [hasMessages, setHasMessages] = useState(true)
  const [isFetching, setIsFetching] = useState(false)
  const scrollbarRef = useRef<HTMLDivElement | null>(null)

  const debouncedFetch = debounce(async () => {
    if (appealMessages?.messages?.length && hasMessages) {
      const hasMoreMessages = await getMoreMessages(
        appealMessages.messages.reverse()[0].createdAt
      )

      setHasMessages(!!hasMoreMessages)
    }
    setIsFetching(false)
  }, 1000)

  const handleScroll = useCallback(async () => {
    const element = scrollbarRef.current
    if (!element || element.scrollTop > 20 || isFetching) return
    setIsFetching(true)
    await debouncedFetch()
  }, [debouncedFetch, isFetching])

  useEffect(() => {
    const element = scrollbarRef.current
    if (element) {
      element.addEventListener('wheel', handleScroll)
    }

    return () => {
      if (element) {
        element.removeEventListener('wheel', handleScroll)
      }
    }
  }, [handleScroll])

  useEffect(() => {
    scrollChatToBottom()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appealMessages?.messages?.[0]?.createdAt])

  return (
    <>
      {isCreatingAppeal ? (
        <SupportStage />
      ) : (
        <StyledWrapper>
          <StyledChatField>
            {hasMessages && Boolean(appealMessages?.messages?.length) && (
              <StyledArrowIcon />
            )}
            <StyledScroll id="chatScroll" ref={scrollbarRef}>
              {appealMessages?.messages
                ?.map((message) => {
                  return <SupportMessage key={message.id} message={message} />
                })
                ?.reverse()}
            </StyledScroll>
          </StyledChatField>
          {!isCheatAppeal ? <SupportChatInput /> : null}
        </StyledWrapper>
      )}
    </>
  )
}

export default SupportChat
