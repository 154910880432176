// import { Tooltip } from 'react-tooltip'

import { IMaskInput } from 'react-imask'
import { Text6 } from 'styles/typography'
import styled, { css } from 'styled-components'

const StyledLabel = styled.label`
  display: flex;
  justify-content: space-between;
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 16px;

  color: #ffcd29;

  margin-bottom: 12px;
`

interface StyledInputWrapperProps {
  $isError?: boolean
  $hideBorder?: boolean
}

const StyledInputWrapper = styled.div<StyledInputWrapperProps>`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  background-color: transparent;
  outline: none;

  border: ${({ $hideBorder }) =>
    `${$hideBorder ? 'unset' : '1px solid rgba(255, 255, 255, 0.15)'}`};
  border-radius: 3px;

  font-family: 'Montserrat';
  font-size: 16px;

  transition: 0.2s;

  ${({ $isError }) =>
    $isError &&
    css`
      border: 1px solid #ff4444;
    `}
`

const StyledInput = styled(IMaskInput)`
  width: 100%;
  height: auto;
  background-color: transparent;
  outline: none;
  padding: 5px 10px;
  text-align: center;

  border-radius: 3px 0px 0px 3px;
  border: 1px solid var(--Button-Disabled, rgba(255, 255, 255, 0.15));
  background: var(--Button-BaseButtonColor, rgba(255, 255, 255, 0.03));
  &:focus {
    border: 1px solid #ffcd29;
  }
  &:focus-within {
    border: 1px solid #ffcd29;
  }
  &::-webkit-input-placeholder {
    color: rgba(255, 255, 255, 0.25);
  }
`

const StyledWrapper = styled.div`
  position: relative;
`

const StyledError = styled(Text6)`
  color: #ff4444;
  position: absolute;
  bottom: 15px;
  left: calc(100% + 11px);
  width: max-content;
  font-weight: 500;
`

interface TimeCodeProps {
  values?: string[]
  className?: string
  label?: string | null
  error?: string | null
  onChange: (value: string[]) => void
}

const TimeCode = ({
  label,
  error,
  values,
  onChange,
  // tooltip,
  className,
}: TimeCodeProps) => {
  return (
    <StyledWrapper className={className}>
      {label && (
        <StyledLabel>
          <div>{label}</div>
        </StyledLabel>
      )}
      <StyledInputWrapper $isError={Boolean(error)}>
        <StyledInput
          mask="00:00"
          lazy={false} // make placeholder always visible
          placeholderChar="0"
          radix="0"
          max={9999}
          value={values?.[0]}
          unmask={false}
          onAccept={(value) => {
            onChange([value, values?.[1] || '00:00'])
            console.log(value)
          }}
          placeholder="00:00"
        />
        <StyledInput
          mask="00:00"
          lazy={false} // make placeholder always visible
          placeholderChar="0"
          radix="0"
          max={9999}
          value={values?.[1]}
          unmask={false}
          onAccept={(value) => {
            onChange([values?.[0] || '00:00', value])
            console.log(value)
          }}
          placeholder="00:00"
        />
      </StyledInputWrapper>

      {error && <StyledError>{error}</StyledError>}
    </StyledWrapper>
  )
}

export default TimeCode
