import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Header3 } from 'styles/typography'
import TabsSwitcher from 'components/TabsSwitcher'
import routes from 'router/routes'

const SpectatingLayout: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { pathname } = useLocation()

  return (
    <StyledWrapper>
      <StyledPlayHeader>
        <StyledTabsWrapper>
          <TabsSwitcher
            defaultActiveKey={pathname}
            onChange={navigate}
            items={[
              {
                key: routes.spectatingJudge(),
                label: t('judge.welcomePage.judge'),
              },
              {
                key: routes.spectatingMyTickets(),
                label: t('judge.welcomePage.myTickets'),
              },
            ]}
          />
        </StyledTabsWrapper>
      </StyledPlayHeader>
      <Outlet />
    </StyledWrapper>
  )
}

export default SpectatingLayout

const StyledTabsWrapper = styled(Header3)`
  display: flex;
  align-items: center;
  gap: 22px;
  z-index: 10;
`

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  height: 100%;
`

const StyledPlayHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 48px;
`
