import { useTranslation } from 'react-i18next'
import Background from 'images/judge/WelcomeBackgroundSlide1.svg'
import InfoCardContent from 'components/Judge/Welcome/InfoCard/Content'
import InfoCardWrapper from 'components/Judge/Welcome/InfoCard/Wrapper'
import JudgeWelcomeSlide1InfoCardBG from 'images/judge/JudgeWelcomeSlide1InfoCardBG.svg'
import JudgeWelcomeSlide1Man1Background from 'images/judge/JudgeWelcomeSlide1Man1.png'
import JudgeWelcomeSlide1Man2Background from 'images/judge/JudgeWelcomeSlide1Man2.png'
import styled, { keyframes } from 'styled-components'

const JudgeWelcomeSlide1 = () => {
  const { t } = useTranslation()

  return (
    <StyledWrapper>
      <StyledAnimatedBackground />
      <StyledWelcomeTitle>
        {t('judge.welcomePage.welcomeTitle')}
      </StyledWelcomeTitle>
      <StyledInfoCardWrapper>
        <InfoCardContent
          slideNumber="01"
          title={t('judge.welcomePage.infoTitleSlide1')}
          description={t('judge.welcomePage.infoDescriptionSlide1')}
        />
      </StyledInfoCardWrapper>
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  background-image: url(${Background});
`

const StyledWelcomeTitle = styled.div`
  position: absolute;
  width: 605px;
  height: 219px;
  top: 25px;
  left: 32px;

  color: #2cff80;
  font-family: Ruberoid;
  font-size: 73px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  text-transform: uppercase;
`

const StyledInfoCardWrapper = styled(InfoCardWrapper)`
  width: 672px;
  height: 260px;
  top: 336px;
  left: 32px;
  margin-top: 6px;
  padding: 64px 24px 34px;
  gap: 36px;
  background-image: url(${JudgeWelcomeSlide1InfoCardBG});
`

const fadeInOut = keyframes`
  0%, 16.66% {
    opacity: 0;
  }
  33.33%, 66.66% {
    opacity: 1;
  }
  83.33%, 100% {
    opacity: 0;
  }
`

const StyledAnimatedBackground = styled.div`
  position: absolute;
  top: 0;
  left: 601px;
  width: 933px;
  height: 639px;

  background-image: url(${JudgeWelcomeSlide1Man1Background});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    background-image: url(${JudgeWelcomeSlide1Man2Background});
    background-size: cover;
    background-repeat: no-repeat;

    background-position: calc(50% + 6px) calc(50%);

    opacity: 0;

    animation: ${fadeInOut} 4s infinite;
  }
`

export default JudgeWelcomeSlide1
