const CheckboxEmpty = ({
  onClick,
  className,
}: {
  className?: string
  onClick?: () => void
}) => (
  <svg
    width="16"
    height="16"
    fill="none"
    onClick={onClick}
    viewBox="0 0 16 16"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      rx="3"
      width="16"
      height="16"
      fill="white"
      y="0.00012207"
      fillOpacity="0.03"
    />
    <rect
      x="0.5"
      rx="2.5"
      width="15"
      height="15"
      y="0.500122"
      stroke="white"
      strokeOpacity="0.25"
    />
  </svg>
)

export default CheckboxEmpty
