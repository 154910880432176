const MatchesLine = ({ className }: { className?: string }) => (
  <svg
    width="806"
    height="53"
    fill="none"
    viewBox="0 0 806 53"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.1"
      stroke="white"
      d="M0 52H674.822C681.403 52 687.739 49.5044 692.552 45.0165L732.271 7.98351C737.084 3.49562 743.42 1 750.001 1H855"
    />
  </svg>
)

export default MatchesLine
