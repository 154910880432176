import { Header7 } from 'styles/typography'
import { styled } from 'styled-components'
import React from 'react'

const StyledRoot = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 60px;
  margin-top: -4px;
`

const StyledMatchIcon = styled.img`
  width: 15px;
`

interface StyledMatchResultProps {
  type: 'lose' | 'win' | 'drow'
}

const StyledMatchResult = styled.div<StyledMatchResultProps>`
  margin: 5px 0;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: ${({ type }) => {
    switch (type) {
      case 'lose':
        return '#F95252'
      case 'win':
        return '#04D156'
      case 'drow':
        return '#F2B927'
    }
  }};
`

const StyledMatchTime = styled(Header7)``

interface MatchItemProps {
  time: string
  mapIcon: string
  result: 'lose' | 'win' | 'drow'
}

const MatchItem: React.FC<MatchItemProps> = ({ time, result, mapIcon }) => {
  return (
    <StyledRoot>
      <StyledMatchIcon src={mapIcon}></StyledMatchIcon>
      <StyledMatchResult type={result} />
      <StyledMatchTime>{time}</StyledMatchTime>
    </StyledRoot>
  )
}

export default MatchItem
