import {
  $activeChatData,
  $activeChatID,
  $chats,
  getPartner,
} from 'stores/chatStores/singleChat'
import {
  $activeNotificationAuthorID,
  $chatNotifications,
} from 'stores/chatStores/notificationsStore'
import {
  $friendsListSorted,
  $searchChatFriendsInput,
  $searchedChatFriendsList,
  setSearchChatFriendsInput,
} from 'stores/friendsStore'
import { $tabActive } from 'stores/chatStores/chatStore'
import { DtoChatParticipant } from 'api/schemas/chatApi'
import { styled } from 'styled-components'
import { useCallback, useEffect, useState } from 'react'
import { useStore } from '@nanostores/react'
import { useTranslation } from 'react-i18next'
import ChatPersonCard from 'components/Chat/ChatPersonCard'
import Input from 'components/Input'
import Search from 'images/Search'
import debounce from 'utils/debounce'

const StyledChatLeft = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 162px;
`
const StyledLine = styled.div`
  width: 100%;
  height: 1px;
  background-color: white;
  opacity: 0.1;
  margin: 8px 0;
`
const StyledPersonCards = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`

const StyledScrollbar = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% + 6px);
  height: 100%;
  overflow-y: scroll;
  gap: 7px;
  padding-right: 4px;

  position: relative;
  right: -4px;
  margin-left: -4px;

  &::-webkit-scrollbar {
    width: 2px;
  }

  &::-webkit-scrollbar-track {
    margin: 4px 0 4px;
    border-radius: 1px;
    opacity: 0.1;
    background: rgba(217, 217, 217, 0.1);
  }

  &::-webkit-scrollbar-thumb {
    background-color: white;
    border-radius: 1px;
  }
`

const ChatLeftUsersPanel: React.FC = () => {
  const { t } = useTranslation()
  const tabActive = useStore($tabActive)
  const chats = useStore($chats)
  const chatNotifications = useStore($chatNotifications)
  const activeChatID = useStore($activeChatID)
  const activeNotificationAuthorID = useStore($activeNotificationAuthorID)
  const activeChatData = useStore($activeChatData)
  const searchedChatFriendsList = useStore($searchedChatFriendsList)
  const friendsList = useStore($friendsListSorted)
  const searchChatFriendsInput = useStore($searchChatFriendsInput)
  const [inputValue, setInputValue] = useState(searchChatFriendsInput)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    debounce((value) => setSearchChatFriendsInput(value), 500),
    []
  )

  useEffect(() => {
    setInputValue('')
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    setSearchChatFriendsInput('')
  }, [tabActive])

  const handleSearchChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    setInputValue(value)
    await debouncedSearch(value)
  }

  const isSearchedChatActive = (id: string) => {
    if (tabActive !== 'invite') {
      return !!activeChatData.participants?.find((user) => user.id === id)
    } else {
      return id === activeNotificationAuthorID
    }
  }

  const searchedFriends = searchedChatFriendsList.map((friend) => {
    const isActive = isSearchedChatActive(friend.id || '')
    return (
      friendsList.some(
        (friendsListItem) => friendsListItem.id === friend.id
      ) && (
        <ChatPersonCard
          key={friend.id}
          isActive={isActive}
          nick={friend.name || ''}
          personId={friend.id || ''}
          avatar={friend.imgUrl || ''}
        />
      )
    )
  })

  const persons = chats.map((chat) => {
    const partner = getPartner(chat.participants as DtoChatParticipant[])
    return (
      <ChatPersonCard
        key={chat.id}
        nick={partner?.name || ''}
        personId={partner?.id || ''}
        avatar={partner?.imgUrl || ''}
        isActive={chat.id === activeChatID}
        haveUnreadMessage={chat.haveUnreadMessage || false}
      />
    )
  })
  const notificationsPersons = Object.values(chatNotifications)
    .filter((notification) => notification.length)
    .map((notification) => {
      const author =
        notification[0].usersById[notification[0].payload.authorId || '']

      return (
        <ChatPersonCard
          key={author.id}
          nick={author.name || ''}
          personId={author.id || ''}
          avatar={author.imgUrl || ''}
          haveUnreadMessage={!!notification.length}
          isActive={author.id === activeNotificationAuthorID}
        />
      )
    })
  return (
    <StyledChatLeft>
      <Input
        value={inputValue}
        rightDecorator={<Search />}
        onChange={handleSearchChange}
        placeholder={t('friends.nickname')}
      />
      <StyledLine />
      <StyledScrollbar>
        <StyledPersonCards>
          {!searchedChatFriendsList.length
            ? tabActive !== 'invite'
              ? chats.length > 0 && persons
              : notificationsPersons
            : searchedFriends}
        </StyledPersonCards>
      </StyledScrollbar>
    </StyledChatLeft>
  )
}

export default ChatLeftUsersPanel
