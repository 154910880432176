import { useEffect, useState } from 'react'
import { useStore } from '@nanostores/react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { $myFinishedClaims, getMyFinishedClaims } from 'stores/judgeStore'
import { Header3 } from 'styles/typography'
import ClaimsTable from 'components/ClaimsTable/ClaimsTable'
import InfoTooltipIcon from 'components/Tooltip/Info/InfoTooltipIcon'

const MyFinishedClaims = () => {
  const { t } = useTranslation()

  const [isLoading, setIsLoading] = useState(true)
  const myFinishedClaims = useStore($myFinishedClaims)

  useEffect(() => {
    const fetchClaims = async () => {
      await getMyFinishedClaims()
      setIsLoading(false)
    }

    void fetchClaims()
  }, [])

  return (
    <StyledTableWrapper>
      <StyledCaptionWrapper>
        <StyledCaption>
          <StyledText>{t('judge.judgePage.applicationTable')}</StyledText>
          <InfoTooltipIcon text={t('judge.judgePage.reportTooltip')} />
        </StyledCaption>
      </StyledCaptionWrapper>

      <ClaimsTable claims={myFinishedClaims} isLoading={isLoading} />
    </StyledTableWrapper>
  )
}

const StyledTableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

const StyledCaptionWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
`

const StyledCaption = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 14px;
`

const StyledText = styled(Header3)`
  text-transform: uppercase;
  line-height: 140%;
`

export default MyFinishedClaims
