import {
  $CSActiveTable,
  $DotaActiveTable,
  $mainPageStatisticGameFormat,
  $userPosition,
  GameChoosen,
  RankTypes,
} from 'stores/statisticStore'
import { $showStandingsPopup, $userProfile } from 'stores/userStore'
import { Header5 } from 'styles/typography'
import { useStore } from '@nanostores/react'
import MorePlayers from 'images/MorePlayers.svg'
import StandingPerson from 'components/Home/StandingPerson'
import styled from 'styled-components'

interface position {
  $position: 'top' | 'other'
}

const TOP_GRADIENT =
  'radial-gradient( 135.43% 133.72% at 105.64% 107.27%, rgba(44, 255, 128, 0.2) 0%, rgba(0, 0, 0, 0) 100%);'
const BOTTOM_GRADIENT =
  'radial-gradient(121.38% 134.39% at 90.55% 100%, rgba(41, 101, 255, 0.2) 0%, rgba(0, 0, 0, 0) 100%);'

const StyledWrapper = styled.div<position>`
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-radius: 6px;
  border: 1px solid rgba(255, 255, 255, 0.15);
  background: ${({ $position }) =>
    $position === 'top' ? TOP_GRADIENT : BOTTOM_GRADIENT}
  background-color: rgba(255, 255, 255, 0.03);
  backdrop-filter: blur(7.5px);
  padding: 16px 12px;
`

const StyledCaption = styled(Header5)`
  color: white;
  opacity: 0.5;
  font-weight: 400;
  line-height: 100%;
`
const StyledPersons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`
const StyledMore = styled.div`
  width: 43px;
  height: 16px;
  cursor: pointer;
  background-image: url('${MorePlayers}');
  margin: -4px auto;
`

interface StandingItemProps {
  game: GameChoosen
  position: 'top' | 'other'
}

const StandingItem: React.FC<StandingItemProps> = ({ game, position }) => {
  const CSActiveTable = useStore($CSActiveTable)
  const DotaActiveTable = useStore($DotaActiveTable)
  const personal = useStore($userProfile)
  const userPosition = useStore($userPosition)
  const mainPageStatisticGameFormat = useStore($mainPageStatisticGameFormat)
  const showStandingsPopup = useStore($showStandingsPopup)

  const toggglePopup = () => {
    $showStandingsPopup.set(!showStandingsPopup)
  }

  const activeTable = () => {
    const users =
      game === 'CS' ? CSActiveTable?.standings : DotaActiveTable?.standings
    if (!users?.length) return []
    return users
  }

  const getGameStanding = () => {
    return position === 'top'
      ? activeTable().slice(0, 3)
      : activeTable().slice().slice(3, 10)
  }

  const listHasPerson = !!activeTable()
    .slice(0, 9)
    .find((user) => user.userId === personal?.id)

  const getItems = () => {
    const standing = getGameStanding().map((user) => {
      return (
        <StandingPerson
          key={user.userId}
          imgUrl={user.userImg}
          id={user.userId || ''}
          score={user.rating || ''}
          isTop={position === 'top'}
          nick={user.userName || ''}
          position={user.position || ''}
          isYou={personal?.id === user.userId}
          rank={(user.rank || 'D') as RankTypes}
        />
      )
    })
    if (!listHasPerson && position === 'other' && userPosition) {
      standing.pop()
      const myPosition = userPosition.find(
        (userGame) =>
          userGame.gameFormat === mainPageStatisticGameFormat &&
          userGame.gameName === game
      )
      standing.push(<StyledMore key="homePlayers" onClick={toggglePopup} />)
      standing.push(
        <StandingPerson
          isYou={true}
          isTop={false}
          id={myPosition?.userId || ''}
          key={myPosition?.userId || ''}
          score={myPosition?.rating || ''}
          nick={myPosition?.userName || ''}
          imgUrl={myPosition?.userImg || ''}
          position={myPosition?.position || ''}
          rank={(myPosition?.rank || 'D') as RankTypes}
        />
      )
    }
    return standing
  }

  return (
    <StyledWrapper $position={position}>
      {position === 'top' ? (
        <StyledCaption>{game === 'CS' ? 'CS 2' : 'DOTA 2'}</StyledCaption>
      ) : (
        ''
      )}
      <StyledPersons>{getItems()}</StyledPersons>
    </StyledWrapper>
  )
}

export default StandingItem
