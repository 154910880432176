const ChooseGameShevron = ({ className }: { className?: string }) => (
  <svg
    width="46"
    fill="none"
    height="24"
    viewBox="0 0 46 24"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="#FFCD29"
      d="M22.8287 23.8808L0.0428956 8.02983C0.0160236 8.01114 2.10071e-06 7.98048 2.105e-06 7.94774L3.12212e-06 0.191376C3.13272e-06 0.110607 0.0908053 0.0631619 0.157109 0.109285L22.8287 15.8808C22.9317 15.9525 23.0683 15.9525 23.1713 15.8808L45.8429 0.109291C45.9092 0.0631679 46 0.110613 46 0.191382L46 7.94775C46 7.98049 45.984 8.01115 45.9571 8.02984L23.1713 23.8808C23.0683 23.9525 22.9317 23.9525 22.8287 23.8808Z"
    />
  </svg>
)

export default ChooseGameShevron
