const PowerCSIcon = () => (
  <svg
    height="9"
    width="10"
    fill="none"
    viewBox="0 0 10 9"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="#2CFF80"
      clipRule="evenodd"
      fillRule="evenodd"
      d="M4.75356 4.00702C5.62866 4.00702 6.33807 3.29761 6.33807 2.42251C6.33807 1.54741 5.62866 0.838006 4.75356 0.838006C3.87846 0.838006 3.16905 1.54741 3.16905 2.42251C3.16905 3.29761 3.87846 4.00702 4.75356 4.00702ZM3.16902 6.8591C3.16902 7.7342 2.45961 8.4436 1.58451 8.4436C0.709412 8.4436 4.29825e-06 7.7342 4.29825e-06 6.8591C4.29825e-06 5.984 0.709412 5.27459 1.58451 5.27459C2.45961 5.27459 3.16902 5.984 3.16902 6.8591ZM9.50702 6.8591C9.50702 7.7342 8.79761 8.4436 7.92251 8.4436C7.04741 8.4436 6.338 7.7342 6.338 6.8591C6.338 5.984 7.04741 5.27459 7.92251 5.27459C8.79761 5.27459 9.50702 5.984 9.50702 6.8591Z"
    />
  </svg>
)

export default PowerCSIcon
