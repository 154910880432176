import {
  $activeGroupChatMessages,
  $activeGroupChatType,
  $activeGroupsChatID,
  $isGroupUserListOpen,
  getGroupsChatMessages,
} from 'stores/chatStores/groupChats'
import { $lobby } from 'stores/lobbyStore'
import { $party } from 'stores/partyStore'
import { sendMessage } from 'stores/chatStores/chatStore'
import { styled } from 'styled-components'
import { useEffect, useState } from 'react'
import { useStore } from '@nanostores/react'
import { useTranslation } from 'react-i18next'
import ChatField from 'components/Chat/ChatField'
import ChatGroupUserList from 'components/Chat/ChatGroupUserList'
import Input from 'components/Input'
import PartyBar from 'components/Chat/PartyBar'

const StyledPartyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  padding-right: 20px;
  gap: 7px;
  height: calc(100% - 140px);
`

const StyledChatFieldWrapper = styled.div`
  min-height: 100%;
  position: relative;
`
const PartyWrapper: React.FC = () => {
  const { t } = useTranslation()
  const isGroupUserListOpen = useStore($isGroupUserListOpen)
  const activeGroupChatMessages = useStore($activeGroupChatMessages)
  const activeGroupsChatID = useStore($activeGroupsChatID)
  const activeGroupChatType = useStore($activeGroupChatType)
  const party = useStore($party)
  const lobby = useStore($lobby)
  const [inputValue, setInputValue] = useState('')
  const handleKeyDown: React.KeyboardEventHandler<HTMLInputElement> = async (
    e
  ) => {
    if (e.key === 'Enter') {
      const inputElement = e.currentTarget as HTMLInputElement
      setInputValue('') // Clear the input after sending the message
      if (inputElement.value.trim().length)
        await sendMessage(activeGroupsChatID, inputElement.value)
    }
  }

  useEffect(() => {
    async function fetchData() {
      await getGroupsChatMessages()
    }
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    fetchData()
  }, [])

  return (
    <StyledPartyWrapper>
      <PartyBar />
      <StyledChatFieldWrapper>
        {isGroupUserListOpen && (
          <ChatGroupUserList
            users={
              activeGroupChatType === 'party'
                ? party?.participants
                : lobby?.participants
            }
          />
        )}
        <ChatField messages={activeGroupChatMessages} />
      </StyledChatFieldWrapper>
      <Input
        value={inputValue}
        onKeyDown={handleKeyDown}
        placeholder={t('chat.message')}
        onChange={(e) => setInputValue(e.target.value)}
      />
    </StyledPartyWrapper>
  )
}

export default PartyWrapper
