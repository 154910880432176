/* eslint-disable @typescript-eslint/no-explicit-any */
import { $lobby, declineLobby, joinToLobby } from 'stores/lobbyStore'
import { $party, declinePartyInvite, joinToParty } from 'stores/partyStore'
import { $popups, setModalType } from 'stores/appStore'
import { $userPopupId } from 'stores/statisticStore'
import { $userProfile } from 'stores/userStore'
import {
  addNotification,
  removeNotification,
} from 'stores/chatStores/notificationsStore'
import { firendInviteAccept, firendInviteDecline } from 'stores/friendsStore'
import { t } from 'i18next'
import { toast } from 'react-toastify'
import api from 'api/api'
import routes from 'router/routes'
import showNotification from 'utils/showNotification'

const notificationListiner = (data: any) => {
  if (data.event === 'CREATED' && data.data.type === 'FRIEND_REQUEST') {
    addNotification(data.data)
    showNotification({
      text: '',
      type: 'friend',
      id: data.data.id,
      info: {
        author: data.data.usersById[data.data.payload.authorId],
      },
      onReject: async () => {
        await firendInviteDecline(data.data.payload.friendRequestId)
        removeNotification(data)
      },
      onAccept: async () => {
        await firendInviteAccept(data.data.payload.friendRequestId)
        removeNotification(data.data.id)
      },
    })
  }
  if (data.event === 'CREATED' && data.data.type === 'PARTY_INVITE') {
    addNotification(data.data)
    showNotification({
      text: '',
      type: 'party',
      id: data.data.id,
      info: {
        author: data.data.usersById[data.data.payload.authorId],
      },
      onAccept: async () => {
        await joinToParty(data.data.payload.partyId)
        removeNotification(data.data.id)
      },
      onReject: async () => {
        await declinePartyInvite(data.data.payload.partyId)
        removeNotification(data.data.id)
      },
    })
  }
  if (data.event === 'CREATED' && data.data.type === 'LOBBY_INVITE') {
    addNotification(data.data)
    showNotification({
      text: '',
      type: 'lobby',
      id: data.data.id,
      redirect: routes.play(),
      info: {
        author: data.data.usersById[data.data.payload.authorId],
      },
      onAccept: async () => {
        await joinToLobby(data.data.payload.lobbyId)
        removeNotification(data.data.id)
      },
      onReject: async () => {
        await declineLobby(data.data.payload.lobbyId)
        removeNotification(data.data.id)
      },
    })
  }
  if (data.event === 'CREATED' && data.data.type === 'PARTY_KICK') {
    const profile = $userProfile.get()
    if (profile) {
      $party.set(null)
    }
  }

  if (data.event === 'CREATED' && data.data.type === 'LOBBY_KICK') {
    const profile = $userProfile.get()
    if (profile) {
      $lobby.set(null)
    }

    showNotification({
      type: 'info',
      id: data.data.id,
      title: t('notifications.title'),
      text: t('notifications.lobbyKickedText'),
    })
  }

  if (
    data.event === 'CREATED' &&
    data.data.type === 'FRIEND_REQUEST_ACCEPTED'
  ) {
    showNotification({
      text: '',
      id: data.data.id,
      type: 'friendAccepted',
      info: {
        author: data.data.usersById[data.data.payload.authorId],
      },
      onReject: () => {
        removeNotification(data.data.id)
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        api.notifications.postClose({ notificationId: data.data.id })
      },
      onAccept: () => {
        removeNotification(data.data.id)
        $userPopupId.set(data.data.payload.authorId)
        setModalType('personal')
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        api.notifications.postClose({ notificationId: data.data.id })
      },
    })
  }
  if (data.event === 'CLOSED') {
    if (data.data.id && $popups.get()[data.data.id]) {
      toast.dismiss($popups.get()[data.data.id])
    }
    removeNotification(data.data.id)
  }
}

export default notificationListiner
