import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { DtoJudgeClaim } from 'api/schemas/supportApi'
import { Header6 } from 'styles/typography'
import { createJudgeClaim } from 'stores/judgeStore'
import JoinButtonDots from 'images/judge/JoinButtonDots'
import JoinJudgePopup from 'components/Judge/JoinPopup/JoinJudgePopup'
import Modal from 'components/Modal'

interface JudgeJoinButtonProps {
  claim: DtoJudgeClaim | null
}

const JudgeJoinButton = ({ claim }: JudgeJoinButtonProps) => {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleJoin = async () => {
    if (isLoading) return

    if (claim) {
      return setIsModalOpen(true)
    }

    try {
      setIsLoading(true)
      await createJudgeClaim()
      setIsModalOpen(true)
    } finally {
      setIsLoading(false)
    }
  }

  const handleCloseModal = () => setIsModalOpen(false)

  return (
    <>
      <StyledButton onClick={handleJoin}>
        <JoinButtonDots />
        <StyledButtonText>
          {isLoading
            ? t('common.pleaseWait')
            : t('judge.welcomePage.toJoinPopup')}
        </StyledButtonText>
        <JoinButtonDots />
      </StyledButton>

      {claim && (
        <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
          <JoinJudgePopup claim={claim} onClose={handleCloseModal} />
        </Modal>
      )}
    </>
  )
}

const StyledButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50px;
  margin-top: 24px;
  border: 1px solid rgba(255, 255, 255, 0.15);
  background: rgba(255, 255, 255, 0.03);
  backdrop-filter: blur(7.5px);
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    border-color: #ffcd29;
  }
`
const StyledButtonText = styled(Header6)`
  color: white;
  text-transform: uppercase;
  margin: 0 40px;
  border-radius: 3px;
`

export default JudgeJoinButton
