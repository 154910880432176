import styled from 'styled-components'

import Carousel from 'components/Carousel/Carousel'
import ChangeSlideButton from 'components/Judge/Welcome/ChangeSlideButton'
import JudgeWelcomeSlide1 from 'components/Judge/Welcome/Slide1'
import JudgeWelcomeSlide2 from 'components/Judge/Welcome/Slide2'
import JudgeWelcomeSlide3 from 'components/Judge/Welcome/Slide3'

const JudgeWelcomeSlider = () => (
  <Wrapper>
    <Carousel
      autoPlay
      infiniteLoop
      interval={10000}
      transitionTime={1000}
      showStatus={false}
      showIndicators={false}
      showThumbs={false}
      stopOnHover
      renderArrowPrev={(clickHandler, hasPrev) =>
        hasPrev && (
          <StyledPrevSlideButton>
            <ChangeSlideButton direction="prev" onClick={clickHandler} />
          </StyledPrevSlideButton>
        )
      }
      renderArrowNext={(clickHandler) => (
        <StyledNextSlideButton>
          <ChangeSlideButton direction="next" onClick={clickHandler} />
        </StyledNextSlideButton>
      )}
    >
      <JudgeWelcomeSlide1 />
      <JudgeWelcomeSlide2 />
      <JudgeWelcomeSlide3 />
    </Carousel>
  </Wrapper>
)

const Wrapper = styled.div`
  position: relative;
  width: 1630px;
  height: 639px;

  border-radius: 3px;
  border: 1px solid rgba(255, 255, 255, 0.15);
  background: rgba(255, 255, 255, 0.03);
  backdrop-filter: blur(20px);

  overflow: hidden;
`

const StyledPrevSlideButton = styled.div`
  position: absolute;
  top: 282px;
  left: 32px;
  z-index: 1;
`

const StyledNextSlideButton = styled.div`
  position: absolute;
  top: 292px;
  right: 32px;
`

export default JudgeWelcomeSlider
