import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { EnumClaimStatus } from 'api/schemas/judgeApi'
import { Header5 } from 'styles/typography'
import { defaultTableGridStyles } from 'components/ClaimsTable/styles'
import {
  useClaimStatusTranslations,
  useGuiltyTranslations,
} from 'components/ClaimsTable/translations'
import Select from 'components/Select'

interface ClaimsTableHeaderProps {
  status: EnumClaimStatus | undefined
  decision: boolean | null | undefined
  onChangeStatus: (value: EnumClaimStatus | undefined) => void
  onChangeDecision: (value: boolean | null | undefined) => void
}

const ClaimsTableHeader = ({
  status,
  decision,
  onChangeStatus,
  onChangeDecision,
}: ClaimsTableHeaderProps) => {
  const { t } = useTranslation()

  const { statusOptions, toStatusSelectValue, fromStatusSelectValue } =
    useClaimStatusTranslations()
  const { guiltyOptions, toGuiltySelectValue, fromGuiltySelectValue } =
    useGuiltyTranslations()

  return (
    <StyledHeader>
      <StyledHeaderCell>ID</StyledHeaderCell>
      <StyledHeaderCell>{t('judge.judgePage.game')}</StyledHeaderCell>
      <StyledHeaderCell>{t('judge.judgePage.player')}</StyledHeaderCell>
      <StyledHeaderCell>{t('judge.judgePage.reason')}</StyledHeaderCell>
      <StyledStatus
        onChange={(value) => onChangeStatus(fromStatusSelectValue(value))}
        placeholder={t('judge.judgePage.status')}
        values={toStatusSelectValue(status)}
        options={statusOptions}
        clearable
      />
      <StyledDecisionWrapper>
        <StyledDecision
          onChange={(value) => onChangeDecision(fromGuiltySelectValue(value))}
          placeholder={t('judge.judgePage.decision')}
          values={toGuiltySelectValue(decision)}
          options={guiltyOptions}
          clearable
        />
      </StyledDecisionWrapper>
      <div />
      <StyledHeaderCell>{t('judge.judgePage.date')}</StyledHeaderCell>
    </StyledHeader>
  )
}

const StyledHeader = styled.div`
  ${defaultTableGridStyles}
  margin-top: 48px;
  padding: 0 32px;
`

const StyledHeaderCell = styled(Header5)`
  font-weight: 600;
  color: rgba(255, 255, 255, 0.3);
  text-transform: uppercase;
  text-align: center;
`

const StyledDecisionWrapper = styled.div`
  display: flex;
  justify-content: center;
`

const StyledStatus = styled(Select)`
  position: relative;
  z-index: 1;
`

const StyledDecision = styled(StyledStatus)`
  min-width: 160px;
`

export default ClaimsTableHeader
