// import 'react-datepicker/dist/react-datepicker.css'
import { Header6, Text5 } from 'styles/typography'
import { forwardRef, useState } from 'react'
import { styled } from 'styled-components'
import ArrowLeft from 'images/calendar/ArrowLeft.svg'
import ArrowLeftDouble from 'images/calendar/ArrowLeftDouble.svg'
import DatePicker from 'react-datepicker'

const StyledCalendarWrapper = styled.div`
  width: 50%;
  height: 38px;
  cursor: pointer;
  position: relative;
`
const StyledCalendarHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 9px 8px;
`
const StyledArrows = styled.div`
  display: flex;
  gap: 4px;
`
const StyledArrowLeft = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  background-image: url('${ArrowLeft}');
  cursor: pointer;
`
const StyledArrowRight = styled(StyledArrowLeft)`
  transform: rotate(180deg);
`
const StyledDoubleArrowLeft = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  background-image: url('${ArrowLeftDouble}');
  cursor: pointer;
`
const StyledDoubleArrowRight = styled(StyledDoubleArrowLeft)`
  transform: rotate(180deg);
`
const StyledMonthHeader = styled(Header6)`
  color: white;
  text-transform: uppercase;
`
const StyledBottomleft = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
  border-right: 1px solid rgba(255, 255, 255, 0.15);
  width: 100%;
  height: 100%;
`
const StyledBottomText = styled(Text5)`
  color: white;
`
const StyledBottomDate = styled(Text5)`
  color: #2cff80;
`
const StyledTodayButton = styled(Header6)`
  display: flex;
  justify-content: center;
  color: #2cff80;
  text-transform: uppercase;
  padding-top: 20px;
  padding-bottom: 17px;
`

const StatisticCalendar: React.FC<{ text: string }> = ({ text }) => {
  const [calendarDate, setCalendarDate] = useState(new Date())
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const CustomInput = forwardRef(({ value, onClick }: any, ref: any) => (
    <StyledBottomleft ref={ref} onClick={onClick}>
      <StyledBottomText>{text}</StyledBottomText>
      <StyledBottomDate>{value}</StyledBottomDate>
    </StyledBottomleft>
  ))
  return (
    <StyledCalendarWrapper>
      <DatePicker
        dateFormat="dd.MM.yyyy"
        selected={calendarDate}
        customInput={<CustomInput />}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onChange={(date: any) => setCalendarDate(date)}
        renderCustomHeader={({
          monthDate,
          increaseYear,
          decreaseYear,
          increaseMonth,
          decreaseMonth, // eslint-disable-next-line @typescript-eslint/no-explicit-any
        }: any) => (
          <StyledCalendarHeader>
            <StyledArrows>
              <StyledDoubleArrowLeft onClick={decreaseYear} />
              <StyledArrowLeft onClick={decreaseMonth} />
            </StyledArrows>
            <StyledMonthHeader>
              {monthDate.toLocaleString('en-US', {
                month: 'short',
                year: 'numeric',
              })}
            </StyledMonthHeader>
            <StyledArrows>
              <StyledArrowRight onClick={increaseMonth} />
              <StyledDoubleArrowRight onClick={increaseYear} />
            </StyledArrows>
          </StyledCalendarHeader>
        )}
      >
        <StyledTodayButton onClick={() => setCalendarDate(new Date())}>
          Today
        </StyledTodayButton>
      </DatePicker>
    </StyledCalendarWrapper>
  )
}

export default StatisticCalendar
