const FriendAcceptIcon = ({ className }: { className?: string }) => (
  <svg
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="#2CFF80"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.3747 5.93724C13.3747 8.11172 11.612 9.87448 9.43749 9.87448C7.26301 9.87448 5.50024 8.11172 5.50024 5.93724C5.50024 3.76276 7.26301 2 9.43749 2C11.612 2 13.3747 3.76276 13.3747 5.93724ZM12.6637 12.6455C11.0774 13.8196 10.049 15.7045 10.049 17.8296C10.049 18.3519 10.1111 18.8598 10.2284 19.3461H9.43701H2C2 17.3737 2.78354 15.482 4.17825 14.0873C5.57296 12.6926 7.46459 11.9091 9.43701 11.9091C10.5649 11.9091 11.6664 12.1653 12.6637 12.6455Z"
    />
    <path
      fill="#2CFF80"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M22.5932 15.3891L21.1543 14L16.7717 18.5395L13.4877 14.882L12 16.2188L16.6863 21.4342L18.083 20L18.0744 19.9905L22.5932 15.3891Z"
    />
  </svg>
)

export default FriendAcceptIcon
