const TeamIcons = ({ group }: { className?: string; group: number | null }) => {
  switch (group) {
    case 0:
      return (
        <svg
          width="24"
          fill="none"
          height="19"
          viewBox="0 0 24 19"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill="#FF7629"
            clip-rule="evenodd"
            fill-rule="evenodd"
            d="M11.0733 5.99051L9.01754 8.01917L9.2121 8.21032C10.0795 9.06245 11.4821 9.06092 12.3476 8.2069L12.7143 7.84499L14.8041 9.90721C14.8116 9.96504 14.8127 10.024 14.8073 10.0831L14.7927 10.2417C14.7653 10.5395 14.5262 10.7756 14.2244 10.8028L14.1765 10.8071C13.8921 10.8328 13.6703 11.0615 13.6566 11.343C13.6424 11.6333 13.4073 11.8658 13.1131 11.8802C12.8282 11.8941 12.5972 12.1128 12.5713 12.3932L12.5669 12.4412C12.5395 12.7393 12.3002 12.9755 11.9982 13.0028L11.838 13.0173C11.5952 13.0392 11.355 12.9536 11.1824 12.7835L7.67491 9.32709C7.66173 9.3141 7.64891 9.30089 7.63645 9.28747L5.54975 7.26673C5.38917 7.10849 5.38899 6.85175 5.54935 6.69329L7.00214 5.25767C7.1625 5.09921 7.42267 5.09903 7.58325 5.25727L8.44933 6.07517C8.66344 6.28616 9.01033 6.28592 9.22414 6.07464L9.31902 5.98088C9.80009 5.50549 10.5806 5.50496 11.0624 5.97968L11.0733 5.99051ZM12.5904 5.25802L9.80909 8.00266C10.4621 8.46213 11.3745 8.40256 11.9602 7.8246L12.4364 7.35467C12.5899 7.2032 12.8387 7.2032 12.9922 7.35467L15.2209 9.55393C15.2909 9.51883 15.3561 9.47263 15.4139 9.41574L15.7517 9.08274C15.7645 9.07019 15.7769 9.05743 15.789 9.04447L17.5928 7.26676C17.7533 7.10851 17.7535 6.85176 17.5932 6.69329L16.0211 5.1396C15.8607 4.98113 15.6006 4.98095 15.44 5.1392L14.5858 5.98101C14.5221 6.04384 14.4188 6.04376 14.3551 5.98085C14.3526 5.97838 14.3501 5.97593 14.3476 5.97349L13.765 5.39858C13.4464 5.08415 12.9593 5.0373 12.5904 5.25802Z"
          />
        </svg>
      )
    case 1:
      return (
        <svg
          width="24"
          fill="none"
          height="19"
          viewBox="0 0 24 19"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill="#FF2990"
            clip-rule="evenodd"
            fill-rule="evenodd"
            d="M11.0728 5.99051L9.01705 8.01917L9.21162 8.21032C10.079 9.06245 11.4816 9.06092 12.3471 8.2069L12.7138 7.84499L14.8036 9.90721C14.8111 9.96504 14.8123 10.024 14.8068 10.0831L14.7922 10.2417C14.7648 10.5395 14.5257 10.7756 14.2239 10.8028L14.176 10.8071C13.8916 10.8328 13.6698 11.0615 13.6561 11.343C13.6419 11.6333 13.4068 11.8658 13.1126 11.8802C12.8277 11.8941 12.5967 12.1128 12.5709 12.3932L12.5664 12.4412C12.539 12.7393 12.2997 12.9755 11.9977 13.0028L11.8375 13.0173C11.5947 13.0392 11.3545 12.9536 11.1819 12.7835L7.67442 9.32709C7.66124 9.3141 7.64842 9.30089 7.63597 9.28747L5.54926 7.26673C5.38868 7.10849 5.3885 6.85175 5.54886 6.69329L7.00165 5.25767C7.16201 5.09921 7.42218 5.09903 7.58276 5.25727L8.44884 6.07517C8.66295 6.28616 9.00984 6.28592 9.22365 6.07464L9.31854 5.98088C9.79961 5.50549 10.5801 5.50496 11.0619 5.97968L11.0728 5.99051ZM12.5899 5.25802L9.8086 8.00266C10.4616 8.46213 11.374 8.40256 11.9597 7.8246L12.4359 7.35467C12.5894 7.2032 12.8383 7.2032 12.9918 7.35467L15.2204 9.55393C15.2904 9.51883 15.3557 9.47263 15.4134 9.41574L15.7513 9.08274C15.764 9.07019 15.7764 9.05743 15.7885 9.04447L17.5923 7.26676C17.7528 7.10851 17.753 6.85176 17.5927 6.69329L16.0206 5.1396C15.8602 4.98113 15.6001 4.98095 15.4395 5.1392L14.5853 5.98101C14.5216 6.04384 14.4183 6.04376 14.3546 5.98085C14.3521 5.97838 14.3496 5.97593 14.3471 5.97349L13.7645 5.39858C13.4459 5.08415 12.9588 5.0373 12.5899 5.25802Z"
          />
        </svg>
      )
    case 2:
      return (
        <svg
          width="24"
          fill="none"
          height="19"
          viewBox="0 0 24 19"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill="#29BFFF"
            clip-rule="evenodd"
            fill-rule="evenodd"
            d="M11.0728 5.99051L9.01705 8.01917L9.21162 8.21032C10.079 9.06245 11.4816 9.06092 12.3471 8.2069L12.7138 7.84499L14.8036 9.90721C14.8111 9.96504 14.8123 10.024 14.8068 10.0831L14.7922 10.2417C14.7648 10.5395 14.5257 10.7756 14.2239 10.8028L14.176 10.8071C13.8916 10.8328 13.6698 11.0615 13.6561 11.343C13.6419 11.6333 13.4068 11.8658 13.1126 11.8802C12.8277 11.8941 12.5967 12.1128 12.5709 12.3932L12.5664 12.4412C12.539 12.7393 12.2997 12.9755 11.9977 13.0028L11.8375 13.0173C11.5947 13.0392 11.3545 12.9536 11.1819 12.7835L7.67442 9.32709C7.66124 9.3141 7.64842 9.30089 7.63597 9.28747L5.54926 7.26673C5.38868 7.10849 5.3885 6.85175 5.54886 6.69329L7.00165 5.25767C7.16201 5.09921 7.42218 5.09903 7.58276 5.25727L8.44884 6.07517C8.66295 6.28616 9.00984 6.28592 9.22365 6.07464L9.31854 5.98088C9.79961 5.50549 10.5801 5.50496 11.0619 5.97968L11.0728 5.99051ZM12.5899 5.25802L9.8086 8.00266C10.4616 8.46213 11.374 8.40256 11.9597 7.8246L12.4359 7.35467C12.5894 7.2032 12.8383 7.2032 12.9918 7.35467L15.2204 9.55393C15.2904 9.51883 15.3557 9.47263 15.4134 9.41574L15.7513 9.08274C15.764 9.07019 15.7764 9.05743 15.7885 9.04447L17.5923 7.26676C17.7528 7.10851 17.753 6.85176 17.5927 6.69329L16.0206 5.1396C15.8602 4.98113 15.6001 4.98095 15.4395 5.1392L14.5853 5.98101C14.5216 6.04384 14.4183 6.04376 14.3546 5.98085C14.3521 5.97838 14.3496 5.97593 14.3471 5.97349L13.7645 5.39858C13.4459 5.08415 12.9588 5.0373 12.5899 5.25802Z"
          />
        </svg>
      )
    case 3:
      return (
        <svg
          width="24"
          fill="none"
          height="19"
          viewBox="0 0 24 19"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill="#FFCD29"
            clip-rule="evenodd"
            fill-rule="evenodd"
            d="M11.0728 5.99051L9.01705 8.01917L9.21162 8.21032C10.079 9.06245 11.4816 9.06092 12.3471 8.2069L12.7138 7.84499L14.8036 9.90721C14.8111 9.96504 14.8123 10.024 14.8068 10.0831L14.7922 10.2417C14.7648 10.5395 14.5257 10.7756 14.2239 10.8028L14.176 10.8071C13.8916 10.8328 13.6698 11.0615 13.6561 11.343C13.6419 11.6333 13.4068 11.8658 13.1126 11.8802C12.8277 11.8941 12.5967 12.1128 12.5709 12.3932L12.5664 12.4412C12.539 12.7393 12.2997 12.9755 11.9977 13.0028L11.8375 13.0173C11.5947 13.0392 11.3545 12.9536 11.1819 12.7835L7.67442 9.32709C7.66124 9.3141 7.64842 9.30089 7.63597 9.28747L5.54926 7.26673C5.38868 7.10849 5.3885 6.85175 5.54886 6.69329L7.00165 5.25767C7.16201 5.09921 7.42218 5.09903 7.58276 5.25727L8.44884 6.07517C8.66295 6.28616 9.00984 6.28592 9.22365 6.07464L9.31854 5.98088C9.79961 5.50549 10.5801 5.50496 11.0619 5.97968L11.0728 5.99051ZM12.5899 5.25802L9.8086 8.00266C10.4616 8.46213 11.374 8.40256 11.9597 7.8246L12.4359 7.35467C12.5894 7.2032 12.8383 7.2032 12.9918 7.35467L15.2204 9.55393C15.2904 9.51883 15.3557 9.47263 15.4134 9.41574L15.7513 9.08274C15.764 9.07019 15.7764 9.05743 15.7885 9.04447L17.5923 7.26676C17.7528 7.10851 17.753 6.85176 17.5927 6.69329L16.0206 5.1396C15.8602 4.98113 15.6001 4.98095 15.4395 5.1392L14.5853 5.98101C14.5216 6.04384 14.4183 6.04376 14.3546 5.98085C14.3521 5.97838 14.3496 5.97593 14.3471 5.97349L13.7645 5.39858C13.4459 5.08415 12.9588 5.0373 12.5899 5.25802Z"
          />
        </svg>
      )
    case 4:
      return (
        <svg
          width="24"
          fill="none"
          height="19"
          viewBox="0 0 24 19"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill="#2CFF80"
            clip-rule="evenodd"
            fill-rule="evenodd"
            d="M11.0724 5.99051L9.01657 8.01917L9.21113 8.21032C10.0785 9.06245 11.4812 9.06092 12.3466 8.2069L12.7133 7.84499L14.8031 9.90721C14.8106 9.96504 14.8118 10.024 14.8063 10.0831L14.7917 10.2417C14.7643 10.5395 14.5252 10.7756 14.2234 10.8028L14.1756 10.8071C13.8911 10.8328 13.6693 11.0615 13.6556 11.343C13.6414 11.6333 13.4063 11.8658 13.1121 11.8802C12.8272 11.8941 12.5962 12.1128 12.5704 12.3932L12.5659 12.4412C12.5385 12.7393 12.2992 12.9755 11.9972 13.0028L11.837 13.0173C11.5942 13.0392 11.354 12.9536 11.1815 12.7835L7.67393 9.32709C7.66076 9.3141 7.64794 9.30089 7.63548 9.28747L5.54878 7.26673C5.3882 7.10849 5.38801 6.85175 5.54837 6.69329L7.00116 5.25767C7.16152 5.09921 7.42169 5.09903 7.58227 5.25727L8.44835 6.07517C8.66246 6.28616 9.00936 6.28592 9.22317 6.07464L9.31805 5.98088C9.79912 5.50549 10.5796 5.50496 11.0614 5.97968L11.0724 5.99051ZM12.5895 5.25802L9.80811 8.00266C10.4611 8.46213 11.3735 8.40256 11.9592 7.8246L12.4354 7.35467C12.5889 7.2032 12.8378 7.2032 12.9913 7.35467L15.2199 9.55393C15.2899 9.51883 15.3552 9.47263 15.4129 9.41574L15.7508 9.08274C15.7635 9.07019 15.7759 9.05743 15.788 9.04447L17.5918 7.26676C17.7524 7.10851 17.7525 6.85176 17.5922 6.69329L16.0201 5.1396C15.8597 4.98113 15.5996 4.98095 15.439 5.1392L14.5848 5.98101C14.5211 6.04384 14.4178 6.04376 14.3541 5.98085C14.3516 5.97838 14.3491 5.97593 14.3466 5.97349L13.764 5.39858C13.4454 5.08415 12.9583 5.0373 12.5895 5.25802Z"
          />
        </svg>
      )
    default:
      return null
  }
}

export default TeamIcons
