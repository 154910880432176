import { Header7, Text5 } from 'styles/typography'
import { ToastContentProps } from 'react-toastify'
import { deletePopup } from 'stores/appStore'
import { useTranslation } from 'react-i18next'
import ButtonCorner from 'images/ButtonCorner'
import SmallButton from 'components/SmallButton'
import styled from 'styled-components'

interface StyledTitleProps {
  $type: 'error' | 'info'
}

const StyledCorner = styled(ButtonCorner)<StyledTitleProps>`
  position: absolute;
  bottom: 0;
  right: 0;
  fill: #ffcd29;
  color: ${({ $type }) =>
    $type === 'info' ? '#ffcd29' : 'rgba(255, 68, 68, 1)'};
`

const StyledRoot = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 15px;
`

const StyledButtons = styled.div`
  display: flex;
  width: 100%;
  justify-content: end;
`

const StyledTitle = styled(Header7)<StyledTitleProps>`
  font-size: bold;
  color: ${({ $type }) =>
    $type === 'info' ? '#ffcd29' : 'rgba(255, 68, 68, 1)'};
`

const StyledDescription = styled(Text5)``

interface MessageProps {
  id: string
  text: string
  title: string
  onAccept?: () => void
  type: 'error' | 'info'
}

const InfoModal = ({
  id,
  type,
  text,
  title,
  onAccept,
  closeToast,
}: ToastContentProps<unknown> & MessageProps) => {
  const { t } = useTranslation()

  const handleAccept = () => {
    onAccept && onAccept()
    closeToast && closeToast()
    deletePopup(id)
  }

  return (
    <StyledRoot>
      <StyledTitle $type={type}>{title}</StyledTitle>
      <StyledDescription>{text}</StyledDescription>
      <StyledButtons>
        <SmallButton onClick={handleAccept}>{t('buttons.ok')}</SmallButton>
      </StyledButtons>
      <StyledCorner $type={type} />
    </StyledRoot>
  )
}
export default InfoModal
