import {
  $qrImage,
  $userAuthenticatorCode,
  $userProfile,
  clearUserAuthenticatorCode,
  userTotpGenerate,
} from 'stores/userStore'
import { Controller, FieldError, SubmitHandler, useForm } from 'react-hook-form'
import {
  Header3,
  Header5,
  Header6,
  Link,
  Text4,
  TextLink,
} from 'styles/typography'
import { login } from 'stores/authStore'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useStore } from '@nanostores/react'
import { useTranslation } from 'react-i18next'
import BackIcon from 'images/BackIcon'
import BigLogo from 'images/BigLogo'
import Button from 'components/Button'
import CloseEyeIcon from 'images/CloseEyeIcon'
import CodeInput from 'components/CodeInput/CodeInput'
import FormButton from 'components/FormButton'
import GoogleLogo from 'images/google-logo.webp'
import Input from 'components/Input'
import PhoneNumberInput from 'components/PhoneNumberInput'
import RightArrowButton from 'images/RightArrowButton.svg'
import SteamButtonIcon from 'images/SteamButtonIcon'
import TellUsYourProblem from 'components/TellUsYourProblem'
import api, { ApiError, verifyToken } from 'api/api'
import formatNumber from 'utils/formatNumber'
import mainLoader from 'loaders/mainLoader'
import routes from 'router/routes'
import styled from 'styled-components'

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 61px 0;
  width: 528px;
  align-items: center;
`

const StyledTitle = styled(Header3)`
  color: #f2f2f2;

  margin: 32px 0 38px 0;
  opacity: 0.32;
  letter-spacing: 2.4px;
  text-transform: uppercase;
  text-align: center;
`

const StyledFooter = styled(Text4)`
  color: rgba(255, 255, 255, 0.25);
  text-align: center;

  font-weight: 500;
`

const StyledContainer = styled.form`
  width: 100%;
  height: 100%;

  display: flex;

  flex-direction: column;

  align-items: center;
  justify-content: end;
`

const StyledForm = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 550px;
  width: 100%;
`

const StyledButton = styled(FormButton)``

const StyledButtonContent = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

const StyledButtonText = styled.div`
  font-size: 500;
  text-transform: uppercase;
`

const StyledButtonStepCounter = styled(Text4)`
  color: #ffcd29;
  font-weight: 500;
`

const StyledCodeText = styled(Text4)`
  text-align: center;
`

interface StyledResend {
  $disabled?: boolean
}

const StyledResend = styled.span<StyledResend>`
  color: ${({ $disabled }) =>
    $disabled ? ' rgba(255, 255, 255, 0.45);' : '#2cff80'};
  transition: 0.2s;

  cursor: pointer;

  &:hover {
    ${({ $disabled }) =>
      $disabled ? ' rgba(255, 255, 255, 0.45);' : 'rgba(44, 255, 128, 0.3)'};
  }
`

const StyledSteamLink = styled.button`
  position: relative;

  width: 100%;

  padding: 5px 12px;
  height: 42px;

  font-size: 16px;
  font-family: 'Ruberoid';
  font-weight: bold;
  text-transform: uppercase;

  border-radius: 3px;
  border: 1px solid rgba(255, 255, 255, 0.15);
  background: linear-gradient(180deg, #163a8a 0%, #137bac 100%);

  opacity: 1;

  &:hover {
    background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.5) 0%,
        rgba(0, 0, 0, 0.5) 100%
      ),
      linear-gradient(180deg, #163a8a 0%, #137bac 100%);
  }

  transition: 0.2s;
`

const StyledSteamLinkWrapper = styled.div`
  height: 550px;
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 12px;
`

const StyledSteamButtonIcon = styled(SteamButtonIcon)`
  position: absolute;

  right: 12px;
  top: 5px;
`

const StyledTooltip = styled(Text4)`
  width: 100%;
  text-align: center;
`

const StyledLabel = styled(Header5)`
  color: #ffcd29;
  width: 100%;
  text-align: center;
  text-transform: uppercase;
`

const StyledCodeInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: center;
`

const StyledCodeInfoExampleWrapper = styled(Text4)`
  display: flex;
  align-items: center;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.45);
  gap: 4px;
`

const StyledCodeInfoExampleBordered = styled(Text4)`
  font-weight: 500;
  color: var(--Desktop-Yellow, #ffcd29);
  display: flex;
  padding: 2px 5px;
  border-radius: 3px;
  border: 1px solid rgba(255, 205, 41, 0.5);
`

const StyledButtons = styled.div`
  display: flex;
  width: 100%;
  gap: 12px;
  margin-top: 23px;
`

const StyledBackButtonContent = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`

const StyledWantConnectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 12px;
`

const StyledWantConnectButton = styled(Button)`
  margin-top: 12px;
  width: 100%;
  display: flex;
  justify-content: center;
  height: 42px;
  padding: 12px;
  background-color: white;
  border-radius: 3px;
  border: 1px solid rgba(255, 255, 255, 0.15);
  position: relative;
  cursor: pointer;

  &:hover {
    background-color: white;
  }
`

const StyledWantConnectButtonText = styled(Header6)`
  text-transform: uppercase;
  color: black;
`

const StyledGoogleLogo = styled.div`
  width: 24px;
  height: 24px;
  background-image: url(${GoogleLogo});
  background-size: contain;
  position: absolute;
  right: 12px;
  top: 9px;
`

const StyledSkip = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 42px;
  width: 100%;
  padding: 5px 12px;
  border-radius: 3px;
  border: 1px solid rgba(255, 255, 255, 0.15);
  background: rgba(255, 255, 255, 0.03);
  cursor: pointer;

  & > div {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
`

const StyledToWriteQr = styled(StyledSkip)``

const StyledSkipText = styled(Header5)`
  color: white;
  text-transform: uppercase;
`

const StyledSkipArrow = styled.div`
  width: 24px;
  height: 24px;
  background-image: url(${RightArrowButton});
`

const StyledReadQRWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`

const StyledQrWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 163px;
  height: 163px;
`
interface QrProps {
  $src: string
}

const StyledQrImage = styled.div<QrProps>`
  width: 100%;
  height: 100%;
  background-image: ${({ $src }) => `url('${$src || null}')`};
`

const StyledDescription = styled(Text4)`
  line-height: 125%;
  color: white;
  text-align: center;
`

const StyledCode = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 34px;
  border: 1px solid rgb(255 205 41 / 40%);
  width: 100%;
  gap: 12px;
`

const StyledCodeItem = styled(Text4)`
  color: #ffcd29;
  text-transform: uppercase;
  font-size: 15px;
`

const StyledCodeInputWrapper = styled.div`
  margin-top: 13px;
`

interface RegistrationFormDataProps {
  code: string
  number: string
  password: string
  passwordSecond: string
}

type Step = 1 | 2 | 3 | 4 | 5
type QrSteps = 'wantConnect' | 'readCode' | 'writeCode'

const RegistrationPage = () => {
  const user = useStore($userProfile)

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const userAuthenticatorCode = useStore($userAuthenticatorCode)
  const qrImage = useStore($qrImage)
  const [qrStep, setQrStep] = useState<QrSteps>('wantConnect')
  const [qrTotpCode, setQrTotpCode] = useState('')

  const [step, setStep] = useState<Step>(() => {
    return user ? 5 : 1
  })

  const [timer, setTimer] = useState<number>(59)
  const [error, setError] = useState<string>('')
  const [code, setCode] = useState<string>('')

  const [isShowPassword, setIsShowPassword] = useState<boolean>(false)
  const [isShowPasswordSecond, setIsShowPasswordSecond] =
    useState<boolean>(false)

  const { t } = useTranslation()

  const navigate = useNavigate()

  const {
    watch,
    control,
    setValue,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<RegistrationFormDataProps>()

  const password = watch('password')
  const number = watch('number')

  const loadData = async () => {
    const isLogin = await verifyToken()
    if (isLogin) {
      await mainLoader()
    }
  }

  const onSubmit: SubmitHandler<RegistrationFormDataProps> = async ({
    number,
    password,
  }) => {
    if (!isLoading) {
      setIsLoading(true)
      if (step === 1) {
        try {
          await api.code.getSendPhoneCode({ phoneNumber: formatNumber(number) })

          setStep(2)
          setError('')
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (e: any) {
          if (
            e.response.data.meta.error ===
            'cant make verification call: Too many calls to this number'
          ) {
            setError(t('auth.tooManyCalls'))
          } else {
            setError(t('auth.serverError'))
          }
        }

        setIsLoading(false)

        return
      }

      if (step === 2) {
        try {
          await api.code.postVerifyCode({
            login: formatNumber(number),
            verificationCode: Number(code),
          })

          setStep(3)
          setError('')
        } catch (e) {
          setError(t('registation.codeField.wrong') || '')
          setValue('code', '')
        }
      }
      if (step === 3) {
        try {
          await api.signUp.postSignUp({
            password,
            login: formatNumber(number),
            verificationCode: Number(code),
          })

          await login(number, password)
          await loadData()

          setStep(4)
        } catch (e) {
          const error = e as ApiError
          if (error.response.data.meta.error !== 'user already exists') {
            setError(t('auth.serverError'))
          } else {
            setError(t('errors.alreadyExisted'))
          }
        }
      }

      if (step === 4 && qrTotpCode.length === 6) {
        try {
          setIsLoading(true)
          await api.users.postTotpConfirm({ code: qrTotpCode })
          setStep(5)
          clearUserAuthenticatorCode()
        } catch (e) {
          console.log(e)
          setError(t('qrCode.wrongCode'))
        } finally {
          setIsLoading(false)
        }
      }

      if (step === 5) {
        try {
          const { data } = await api.accounts.getSteamGet({})
          await window.shell.openExternal(data)
        } catch (e) {
          // showNotification('Неверный токен', 'error')
        }
      }

      setIsLoading(false)
    }
  }

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    loadData()
  }, [])

  useEffect(() => {
    if (user?.steamId) {
      navigate(routes.chooseGame())
    }
  }, [navigate, user?.steamId])

  const tick = useCallback((count: number) => {
    if (count !== 0) {
      setTimer(count - 1)
      setTimeout(() => tick(count - 1), 1000)
    }
  }, [])

  const handleResendCode = async () => {
    if (!timer) {
      await api.code.getSendPhoneCode({ phoneNumber: formatNumber(number) })

      setTimer(59)
      tick(60)
    }
  }

  const handleChangeCode = (value: string) => {
    if (!isLoading && value.length === 4) {
      setCode(value)
    }
  }

  useEffect(() => {
    if (step === 2) {
      tick(timer)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step])

  const getPasswordError = (error?: FieldError) => {
    if (!error) {
      return
    }
    switch (error.type) {
      case 'num':
        return t('registation.passwordField.errors.num')
      case 'lat':
        return t('registation.passwordField.errors.lat')
      case 'minLength':
        return t('registation.passwordField.errors.minLength')
      case 'down':
        return t('registation.passwordField.errors.down')
      case 'up':
        return t('registation.passwordField.errors.up')
      case 'symbol':
        return t('registation.passwordField.errors.symbol')
      default:
        return t('registation.passwordField.errors.required')
    }
  }

  const handleShowPassword = () => {
    setIsShowPassword(!isShowPassword)
  }

  const handleShowPasswordSecond = () => {
    setIsShowPasswordSecond(!isShowPasswordSecond)
  }

  const handleShowQr = async () => {
    await userTotpGenerate(true)
    setQrStep('readCode')
  }

  const handleBack = () => {
    const nextStep = step === 3 ? 1 : step - 1
    setError('')
    setStep(nextStep as Step)
  }

  const handleChangeQrCode = (value: string) => {
    if (!isLoading) {
      setQrTotpCode(value)
    }
  }

  return (
    <StyledWrapper>
      <TellUsYourProblem />
      <StyledContainer onSubmit={handleSubmit(onSubmit)}>
        <BigLogo />
        <StyledTitle>{t('registation.registration')}</StyledTitle>
        <StyledForm>
          {step === 1 && (
            <>
              <StyledLabel>{t('registation.enterMobile')}</StyledLabel>
              <StyledTooltip>{t('registation.mobileTooltip')}</StyledTooltip>
              <Controller
                name="number"
                defaultValue=""
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <PhoneNumberInput
                    id={'number'}
                    error={
                      (errors.number &&
                        t('auth.numberField.errors.required')) ||
                      error
                    }
                    {...field}
                  />
                )}
              />
            </>
          )}
          {step === 2 && (
            <>
              <StyledLabel>{t('registation.codeField.getCode')}</StyledLabel>
              <StyledCodeInfoWrapper>
                <StyledTooltip>
                  {t('registation.codeField.lastNumberTooltip')}
                </StyledTooltip>
                <StyledCodeInfoExampleWrapper>
                  {t('registation.codeField.forExample')} + 7 (***) ***{' '}
                  <StyledCodeInfoExampleBordered>
                    20 04
                  </StyledCodeInfoExampleBordered>
                </StyledCodeInfoExampleWrapper>
                <StyledTooltip>
                  {t('registation.codeField.dontAnswer')}
                </StyledTooltip>
              </StyledCodeInfoWrapper>

              <StyledTooltip>
                {t('registation.codeField.codeSended', { number })}{' '}
                <TextLink onClick={() => setStep(1)}>
                  {t('registation.codeField.changeNumber')}
                </TextLink>
              </StyledTooltip>

              <Controller
                name="code"
                defaultValue=""
                control={control}
                render={({ field }) => (
                  <CodeInput
                    error={error}
                    {...field}
                    onChange={handleChangeCode}
                  />
                )}
              />
              <StyledCodeText>
                {timer ? (
                  <StyledResend $disabled={true}>
                    {t('registation.codeField.resendCodeTimer', { timer })}
                  </StyledResend>
                ) : (
                  <>
                    {t('registation.codeField.dontGetCode')}{' '}
                    <StyledResend onClick={handleResendCode}>
                      {' '}
                      {t('registation.codeField.resendCode')}
                    </StyledResend>
                  </>
                )}
              </StyledCodeText>
            </>
          )}
          {step === 3 && (
            <>
              <StyledLabel>{t('registation.passwordField.label')}</StyledLabel>
              <StyledTooltip>
                {t('registation.passwordField.tooltip')}
              </StyledTooltip>
              <Controller
                defaultValue=""
                name="password"
                control={control}
                rules={{
                  required: true,
                  validate: {
                    lat: (v) => /[A-Za-z]+/.test(v),
                    // eslint-disable-next-line perfectionist/sort-objects
                    up: (v) => /[A-Z]+/.test(v),
                    down: (v) => /[a-z]+/.test(v),
                    // eslint-disable-next-line perfectionist/sort-objects
                    num: (v) => /[1-9]+/.test(v),
                    minLength: (v) => v.trim().length >= 8,
                    symbol: (v) => /[!@#$%^&*`/\\[\]().,?]+/.test(v),
                  },
                }}
                render={({ field }) => (
                  <Input
                    id={'password'}
                    error={getPasswordError(errors.password)}
                    onClickRightDeacorator={handleShowPassword}
                    type={isShowPassword ? 'text' : 'password'}
                    // label={t('registation.passwordField.label')}
                    placeholder={
                      t('registation.passwordField.placeholder') || ''
                    }
                    rightDecorator={
                      isShowPassword ? <CloseEyeIcon /> : <CloseEyeIcon />
                    }
                    {...field}
                  />
                )}
              />

              <Controller
                defaultValue=""
                control={control}
                name="passwordSecond"
                rules={{
                  required: true,
                  validate: { correct: (v) => v === password },
                }}
                render={({ field }) => (
                  <Input
                    id={'passwordSecond'}
                    type={isShowPasswordSecond ? 'text' : 'password'}
                    onClickRightDeacorator={handleShowPasswordSecond}
                    placeholder={
                      t('registation.secondPasswordField.placeholder') || ''
                    }
                    rightDecorator={
                      isShowPasswordSecond ? <CloseEyeIcon /> : <CloseEyeIcon />
                    }
                    error={
                      (errors.passwordSecond &&
                        t('registation.secondPasswordField.errors.match')) ||
                      error
                    }
                    {...field}
                  />
                )}
              />
            </>
          )}
          {step === 4 && qrStep === 'wantConnect' && (
            <StyledWantConnectWrapper>
              <StyledLabel>{t('qrCode.doubleAutentification')}</StyledLabel>
              <StyledTooltip>{t('qrCode.toProtect')}</StyledTooltip>
              <StyledWantConnectButton>
                <StyledWantConnectButtonText onClick={handleShowQr}>
                  {t('setting.connectGoogle')}
                </StyledWantConnectButtonText>
                <StyledGoogleLogo />
              </StyledWantConnectButton>
            </StyledWantConnectWrapper>
          )}
          {step === 4 && qrStep === 'readCode' && (
            <StyledReadQRWrapper>
              <StyledLabel>{t('qrCode.scan')}</StyledLabel>
              <StyledQrWrapper>
                {qrImage && <StyledQrImage $src={qrImage} />}
              </StyledQrWrapper>
              <StyledDescription>{t('qrCode.orEnter')}</StyledDescription>
              <StyledCode>
                {userAuthenticatorCode?.codes
                  ? userAuthenticatorCode.codes.map((item) => (
                      <StyledCodeItem key={item}>{item}</StyledCodeItem>
                    ))
                  : null}
              </StyledCode>
            </StyledReadQRWrapper>
          )}
          {step === 4 && qrStep === 'writeCode' && (
            <StyledReadQRWrapper>
              <StyledLabel>{t('qrCode.enterConfirmationCode')}</StyledLabel>
              <StyledDescription>{t('qrCode.enterCode')}</StyledDescription>
              <StyledCodeInputWrapper>
                <CodeInput
                  length={6}
                  error={error}
                  onChange={handleChangeQrCode}
                />
              </StyledCodeInputWrapper>
            </StyledReadQRWrapper>
          )}
          {step === 4 && (
            <StyledButtons>
              {qrStep === 'wantConnect' && (
                <StyledSkip onClick={() => setStep(5)}>
                  <StyledSkipText>{t('qrCode.skip')}</StyledSkipText>
                  <StyledSkipArrow />
                </StyledSkip>
              )}
              {qrStep === 'readCode' && (
                <StyledToWriteQr onClick={() => setQrStep('writeCode')}>
                  <StyledButtonContent>
                    <StyledButtonText>{t('registation.next')}</StyledButtonText>
                    <StyledButtonStepCounter>
                      {t('registation.steps', { end: 5, start: step })}
                    </StyledButtonStepCounter>
                  </StyledButtonContent>
                </StyledToWriteQr>
              )}
              {qrStep === 'writeCode' && (
                <>
                  <Button
                    size="s"
                    type="button"
                    onClick={() => setQrStep('readCode')}
                  >
                    <StyledBackButtonContent>
                      {t('registation.backButton')} <BackIcon />
                    </StyledBackButtonContent>
                  </Button>
                  <StyledButton
                    type="submit"
                    isLoading={isLoading}
                    disabled={qrTotpCode.length < 6}
                  >
                    <StyledButtonContent>
                      <StyledButtonText>
                        {t('registation.next')}
                      </StyledButtonText>
                      <StyledButtonStepCounter>
                        {t('registation.steps', { end: 5, start: 5 })}
                      </StyledButtonStepCounter>
                    </StyledButtonContent>
                  </StyledButton>
                </>
              )}
            </StyledButtons>
          )}
          {step < 5 && (
            <StyledButtons>
              {step > 1 && step !== 4 && (
                <Button size="s" type="button" onClick={handleBack}>
                  <StyledBackButtonContent>
                    {t('registation.backButton')} <BackIcon />
                  </StyledBackButtonContent>
                </Button>
              )}
              {step !== 4 && (
                <StyledButton
                  type="submit"
                  isLoading={isLoading}
                  disabled={!isValid && step !== 2}
                >
                  <StyledButtonContent>
                    <StyledButtonText>{t('registation.next')}</StyledButtonText>
                    <StyledButtonStepCounter>
                      {t('registation.steps', { end: 5, start: step })}
                    </StyledButtonStepCounter>
                  </StyledButtonContent>
                </StyledButton>
              )}
            </StyledButtons>
          )}
          {step === 5 && (
            <StyledSteamLinkWrapper>
              <StyledLabel>{t('steamConnect.connect')}</StyledLabel>
              <StyledTooltip>{t('steamConnect.info')}</StyledTooltip>
              <StyledButtons>
                {!user && (
                  <Button size="s" type="button" onClick={handleBack}>
                    <StyledBackButtonContent>
                      {t('registation.backButton')} <BackIcon />
                    </StyledBackButtonContent>
                  </Button>
                )}
                <StyledSteamLink>
                  {t('steamConnect.connect')} <StyledSteamButtonIcon />
                </StyledSteamLink>
              </StyledButtons>
            </StyledSteamLinkWrapper>
          )}
        </StyledForm>
      </StyledContainer>

      <StyledFooter>
        {t('registation.back')}
        {` `}
        <Link to={routes.login()}>{t('auth.login')}</Link>
      </StyledFooter>
    </StyledWrapper>
  )
}

export default RegistrationPage
