const YouIcon = () => (
  <svg
    width="25"
    fill="none"
    height="13"
    viewBox="0 0 25 13"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect y="3.5" height="9" width="24" x="0.900391" fill="#F2B927" />
    <rect
      y="0.39624"
      x="12.9043"
      fill="#F2B927"
      width="4.24777"
      height="4.24777"
      transform="rotate(45 12.9043 0.39624)"
    />
    <path
      fill="black"
      d="M6.28656 11H7.56656V8.88L9.99856 5.4H8.47856L6.92656 7.72L5.36656 5.4H3.84656L6.28656 8.888V11ZM10.5627 6C10.1067 6.456 9.87469 7.192 9.87469 8.2C9.87469 9.208 10.1067 9.936 10.5627 10.392C11.0187 10.848 11.7467 11.08 12.7547 11.08C13.7627 11.08 14.4987 10.848 14.9547 10.392C15.4107 9.936 15.6347 9.208 15.6347 8.2C15.6347 7.192 15.4107 6.456 14.9547 6C14.4987 5.544 13.7627 5.32 12.7547 5.32C11.7467 5.32 11.0187 5.544 10.5627 6ZM11.5387 6.92C11.7867 6.656 12.1947 6.52 12.7547 6.52C13.3147 6.52 13.7227 6.656 13.9787 6.92C14.2267 7.184 14.3547 7.616 14.3547 8.2C14.3547 8.784 14.2267 9.216 13.9787 9.48C13.7227 9.744 13.3147 9.88 12.7547 9.88C12.1947 9.88 11.7867 9.744 11.5387 9.48C11.2827 9.216 11.1547 8.784 11.1547 8.2C11.1547 7.616 11.2827 7.184 11.5387 6.92ZM16.3931 8.552C16.3931 10.32 17.2171 11.08 19.1131 11.08C21.0091 11.08 21.8331 10.32 21.8331 8.552V5.4H20.5531V8.456C20.5531 9.448 20.1131 9.88 19.1131 9.88C18.1131 9.88 17.6731 9.448 17.6731 8.456V5.4H16.3931V8.552Z"
    />
  </svg>
)

export default YouIcon
