import { DtoLobbyParticipant } from 'api/schemas/lobbyApi'
import {
  Header2,
  Header3,
  Header4,
  Header5,
  Header6,
  SideHeader4,
  Text4,
  Text5,
} from 'styles/typography'
import { styled } from 'styled-components'
import { useTranslation } from 'react-i18next'
import AvatarWithFlag from 'components/AvatarWithFlag'
import MatchItem from 'components/PlayerCard/MatchItem'
import MatchesLine from 'images/MatchesLine'
import NickTrimmer from 'components/NickTrimmer'
import PowerCSIcon from 'images/PowerCSIcon'
import WithUserMenu from 'components/ContextMenu/WithUserMenu'
import blue_hightlight from 'images/1x1lobby/blue_hightlight.webp'
import card_bg from 'images/1х1 Card СS.png'
import getInterval from 'utils/getIntervalMinSec'
import red_hightlight from 'images/1x1lobby/red_hightlight.webp'
import round from 'utils/round'

const StyledRoot = styled.div`
  width: 806px;
  height: 233px;
  background-image: url('${card_bg}');
  position: relative;
  padding: 20px;
  row-gap: 18px;
  display: flex;
  backdrop-filter: blur(7.5px);
  flex-direction: column;
`

const StyledEmptyWrapper = styled(Header5)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.3);
`

const StyledProfileInfo = styled.div`
  display: flex;
  gap: 32px;
`

const StyledLastMatches = styled.div`
  display: flex;
  gap: 12px;
`

const StyledMainInfoWrapper = styled.div`
  display: flex;
  justify-content: start;
  gap: 9px;
`

const StyledWinRateWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  bottom: 16px;
  right: 6px;
`

const StyledPower = styled(Header5)`
  display: flex;
  align-items: center;
  gap: 4px;
  font-weight: 600;
`

const StyledMainInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const StyledNickname = styled(Header3)`
  font-weight: 400;
  line-height: 100%;
`

const StyledMatchesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 17px;
  min-width: 125px;
`

const StyledShadowText = styled(SideHeader4)`
  color: rgba(255, 255, 255, 0.2);
  text-transform: uppercase;
  left: 12px;
`

const StyledMatchCount = styled(Header2)`
  font-weight: 400;
  line-height: 75%;
`

const StyledWDLStats = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 20px auto 5px;
  height: 65px;
`

const StyledGreenCircle = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #04d156;
`

const StyledYellowCircle = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #f2b927;
`

const StyledRedCircle = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #f84848;
`

const StyledWDLText = styled(Text5)`
  font-weight: 500;
  margin-right: 10px;
`

const StyledStats = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: auto auto auto auto;
  height: 65px;
  column-gap: 45px;
`

const StyledWDLNumber = styled(Header6)``

const StyledMatchStatTitle = styled(SideHeader4)`
  display: flex;
  align-items: end;
  color: rgba(255, 255, 255, 0.2);
  text-transform: uppercase;
  grid-column-start: 1;
  height: 17px;
  grid-column-end: 5;
`

const StyledStatCount = styled(Header4)`
  font-weight: 500;
`

const StyledStatText = styled(Text5)`
  color: rgba(255, 255, 255, 0.3);
  font-weight: 500;
`

const StyledWinRateCount = styled(Header3)`
  color: #2cff80;
  text-align: center;
`

const StyledWinRateText = styled(Text4)`
  color: rgba(255, 255, 255, 0.3);
`

const StyledLastMatchesTitle = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 13px;
`
const StyledLastMatchesTitleText = styled(Header6)`
  margin-bottom: 8px;
`
const StyledLastMatchesDescription = styled(Text5)`
  color: rgba(255, 255, 255, 0.3);
`

const StyledMatchesLine = styled(MatchesLine)`
  position: absolute;
  bottom: 37px;
  left: 0;
`

interface StyledStatusWrapperProps {
  $team?: string
  $isShow: boolean
}

const StyledStatusWrapper = styled.div<StyledStatusWrapperProps>`
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: ${({ $team }) =>
    `url(${$team === 'RED' ? red_hightlight : blue_hightlight})`};
  opacity: ${({ $isShow }) => ($isShow ? 1 : 0)};
  top: 0;
  left: 0;
  background-size: cover;
  z-index: -1;
`

interface PlayerCardCSProps {
  player?: DtoLobbyParticipant
}

const PlayerCardCS: React.FC<PlayerCardCSProps> = ({ player }) => {
  const { t } = useTranslation()
  // const [lastMatches, setLastMatches] = useState<DtoMatchUserResult[]>([])
  if (!player) {
    return (
      <StyledRoot>
        <StyledEmptyWrapper>
          {t('play.userCard.emptyOpponent')}
        </StyledEmptyWrapper>
      </StyledRoot>
    )
  }

  const { user } = player
  const getWinrate = (wins: number, loses: number, draws: number) => {
    const total = loses + wins + draws
    const fixedTotal = total || 1
    return Math.round((wins / fixedTotal) * 100)
  }

  // useEffect(async () => {
  //   try {
  //     const mathes = await api.match.getLastResults()

  //     setLastMatches(mathes?.data || [])
  //   } catch (e) {
  //     logError('Ошибка получения матчей', e)
  //   }
  // }, [player?.id])
  return (
    <WithUserMenu profile={user}>
      <StyledRoot>
        <StyledMainInfoWrapper>
          <AvatarWithFlag avatarSrc={user?.imgUrl} />
          <StyledMainInfo>
            <StyledPower>
              <PowerCSIcon />
              <div>{round(user?.CSProfileStat?.rating)}</div>
            </StyledPower>
            <StyledNickname>
              <NickTrimmer>{user?.name}</NickTrimmer>
            </StyledNickname>
          </StyledMainInfo>
        </StyledMainInfoWrapper>
        <StyledProfileInfo>
          <StyledMatchesWrapper>
            <StyledShadowText>{t('play.userCard.matches')}</StyledShadowText>
            <StyledMatchCount>
              {(user?.CSProfileStat?.matchWinCount || 0) +
                (user?.CSProfileStat?.matchDrawCount || 0) +
                (user?.CSProfileStat?.matchLoseCount || 0)}
            </StyledMatchCount>
          </StyledMatchesWrapper>
          <StyledWDLStats>
            <StyledGreenCircle />
            <StyledWDLText>{t('play.userCard.win')}</StyledWDLText>
            <StyledWDLNumber>
              {user?.CSProfileStat?.matchWinCount}
            </StyledWDLNumber>
            <StyledYellowCircle />
            <StyledWDLText>{t('play.userCard.drow')}</StyledWDLText>
            <StyledWDLNumber>
              {user?.CSProfileStat?.matchDrawCount}
            </StyledWDLNumber>
            <StyledRedCircle />
            <StyledWDLText>{t('play.userCard.lose')}</StyledWDLText>
            <StyledWDLNumber>
              {user?.CSProfileStat?.matchLoseCount}
            </StyledWDLNumber>
          </StyledWDLStats>
          <StyledStats>
            <StyledMatchStatTitle>
              {t('play.userCard.matchstat')}
            </StyledMatchStatTitle>
            <StyledStatCount>
              {round(user?.CSProfileStat?.KD, 2)}
            </StyledStatCount>
            <StyledStatCount>
              {round(user?.CSProfileStat?.score)}
            </StyledStatCount>
            <StyledStatCount>
              {round(user?.CSProfileStat?.HS)} %
            </StyledStatCount>
            <StyledStatCount>{round(user?.CSProfileStat?.ADR)}</StyledStatCount>
            <StyledStatText>KD</StyledStatText>
            <StyledStatText>Score</StyledStatText>
            <StyledStatText>HS</StyledStatText>
            <StyledStatText>ADR</StyledStatText>
          </StyledStats>
        </StyledProfileInfo>
        <StyledLastMatches>
          <StyledMatchesLine></StyledMatchesLine>
          <StyledLastMatchesTitle>
            <StyledLastMatchesTitleText>
              {t('play.userCard.last')} 10
            </StyledLastMatchesTitleText>
            <StyledLastMatchesDescription>
              {t('play.userCard.matches')}
            </StyledLastMatchesDescription>
          </StyledLastMatchesTitle>
          {user?.CSProfileStat?.lastMatchesResult?.map((item) => {
            return (
              <MatchItem
                key={item.id}
                result={item.isWin ? 'win' : 'lose'}
                mapIcon={item.gameMap?.imgIconUrl || ''}
                time={getInterval(item?.startAt || '', item?.finishAt || '')}
              />
            )
          })}
        </StyledLastMatches>
        <StyledWinRateWrapper>
          <StyledWinRateCount>
            {getWinrate(
              user?.CSProfileStat?.matchWinCount || 0,
              user?.CSProfileStat?.matchDrawCount || 0,
              user?.CSProfileStat?.matchLoseCount || 0
            )}
            %
          </StyledWinRateCount>
          <StyledWinRateText>{t('play.userCard.winrate')}</StyledWinRateText>
        </StyledWinRateWrapper>
        <StyledStatusWrapper
          $team={player.teamColor}
          $isShow={Boolean(player.isReady)}
        />
        {/* <StyledCardMenu /> */}
      </StyledRoot>
    </WithUserMenu>
  )
}

export default PlayerCardCS
