import styled from 'styled-components'

const StyledChooseBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto 0;
  padding: 9px;
  gap: 7px;
  width: 809px;
  justify-content: end;
  border-radius: 3px;
  border: 1px solid rgba(255, 255, 255, 0.1);
`

interface ChooseBlockProps {
  caption?: string
  textMargin?: number
  description?: string
  children: React.ReactNode
}

const ChooseBlock: React.FC<ChooseBlockProps> = ({ children }) => {
  return (
    <StyledChooseBlock>
      {/* <StyledText $textMargin={textMargin}>
        {caption && <StyledCaption>{caption}</StyledCaption>}
        {description && <StyledDescription>{description}</StyledDescription>}
      </StyledText> */}
      {children}
    </StyledChooseBlock>
  )
}

export default ChooseBlock
