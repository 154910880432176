import { atom, computed } from 'nanostores'

import { DtoFriend } from 'api/schemas/friendsApi'
import { DtoUser } from 'api/schemas/matchApi'
import { logger } from '@nanostores/logger'
import api from 'api/api'

export type FriendProfile = DtoFriend

const $friendsList = atom<FriendProfile[]>([])

export const $recnetPlayers = atom<DtoUser[]>([])
export const $friendsStatistic = atom<DtoFriend[] | null>(null)

export const $friendsListSorted = computed([$friendsList], (friendsList) => {
  const chosenFriends = friendsList
    ?.filter((friend) => friend.isChosen)
    .sort((a, b) => {
      if (!a.name || !b.name) {
        return 0
      }
      if (a.name > b.name) {
        return 1
      }
      if (a.name < b.name) {
        return -1
      }
      return 0
    })
  const otherFriends = friendsList
    ?.filter((friend) => !friend.isChosen)
    .sort((a, b) => {
      if (!a.name || !b.name) {
        return 0
      }
      if (a.name > b.name) {
        return 1
      }
      if (a.name < b.name) {
        return -1
      }
      return 0
    })
  return [...chosenFriends, ...otherFriends]
})

export const $searchFriendsInput = atom<string>('')

export const $searchedFriendsList = atom<FriendProfile[]>([])

export const getSearchedFriendsList = async (search: string) => {
  if (search) {
    const { data } = await api.users.getSearch({
      userSearchName: search,
    })
    $searchedFriendsList.set(data || [])
    return
  }
  $searchedFriendsList.set([])
}

export const getFriends = async () => {
  const { data } = (await api.friends.getState()) as { data: FriendProfile[] }

  $friendsList.set(data || [])
}

export const getRecnetPlayers = async () => {
  const { data } = (await api.match.getLastParticipants()) as {
    data: DtoUser[]
  }

  $recnetPlayers.set(data || [])
}

export const setSearchFriendsInput = async (search: string) => {
  if (search === '') {
    return $searchedFriendsList.set([])
  }
  await getSearchedFriendsList(search)
}

export const deleteFriend = async (id: string) => {
  await api.friends.deleteFriends(id)
  await getFriends()
}

export const toggleFriendToFavorite = async (id: string) => {
  await api.friends.postChosen(id)
  await getFriends()
}

export const firendInviteAccept = async (id: string) => {
  await api.friends.postInviteAccept(id)
  // await getFriends()
}

export const firendInviteDecline = async (id: string) => {
  await api.friends.postInviteDecline(id)
  // await getFriends()
}

export const inviteFriend = async (id: string) => {
  await api.friends.postInvite({ friendId: id })
  // await getFriends()
}

export const addFriend = (profile: FriendProfile) => {
  $friendsList.set([...$friendsList.get(), profile])
}

export const updateFriend = (profile: FriendProfile) => {
  $friendsList.set([
    ...$friendsList
      .get()
      .map((friend) =>
        profile.id === friend.id ? { ...friend, ...profile } : friend
      ),
  ])
}

export const getfriendsStatistic = async (id: string | undefined) => {
  if (!id) return $friendsStatistic.set(null)
  try {
    const response = await api.friends.getStatistic({ userId: id })
    return $friendsStatistic.set(response.data)
  } catch (e) {
    console.log(e)
  }
}

// отдельный инпут и результат для чатов, чтобы не дублировалось
export const $searchChatFriendsInput = atom<string>('')
export const $searchedChatFriendsList = atom<FriendProfile[]>([])

export const getSearchedChatFriendsList = async (search: string) => {
  if (search) {
    const { data } = await api.users.getSearch({
      userSearchName: search,
    })
    return $searchedChatFriendsList.set(data || [])
  }
  $searchedChatFriendsList.set([])
}

export const setSearchChatFriendsInput = async (search: string) => {
  try {
    if (search === '') {
      $searchChatFriendsInput.set(search)
      return $searchedChatFriendsList.set([])
    }
    await getSearchedChatFriendsList(search)
  } catch (err) {
    console.log(err)
  }
  console.log(search)
  return $searchChatFriendsInput.set(search)
}

logger(
  {
    friendsList: $friendsList,
    friendsStatistic: $friendsStatistic,
    friendsListSorted: $friendsListSorted,
    searchFriendsInput: $searchFriendsInput,
    searchedFriendsList: $searchedFriendsList,
    searchChatFriendsInput: $searchChatFriendsInput,
    searchedChatFriendsList: $searchedChatFriendsList,
  },
  {
    messages: {
      mount: false,
      unmount: false,
    },
  }
)
