import { Header5 } from 'styles/typography'
import { useTranslation } from 'react-i18next'
import emptySlot from 'images/emptySlot.png'
import emptySlotHover from 'images/emptySlotHover.png'
import styled, { css } from 'styled-components'

interface StyledEmptyCardWrapperProps {
  disabled?: boolean
}

const StyledEmptyCardWrapper = styled.div<StyledEmptyCardWrapperProps>`
  position: relative;
  background-image: url('${emptySlot}');
  width: 158px;
  height: 233px;
  cursor: pointer;
  backdrop-filter: blur(3px);
  &:hover {
    background-image: url('${emptySlotHover}');
  }
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.8;
      cursor: default;
      &:hover {
        background-image: url('${emptySlot}');
      }
    `}
`

const StyledEmptySlotTitle = styled(Header5)`
  position: absolute;
  right: 60px;
  top: 110px;
  text-transform: uppercase;
`

interface EmptyCardCSProps {
  index: number
  team: 'RED' | 'BLUE'
  isLobbyBlocked: boolean
  handlePositionClick: (position: number, team: 'BLUE' | 'RED') => () => void
}

const EmptyCardCS: React.FC<EmptyCardCSProps> = ({
  team,
  index,
  isLobbyBlocked,
  handlePositionClick,
}) => {
  const { t } = useTranslation()

  return (
    <StyledEmptyCardWrapper
      key={index}
      disabled={isLobbyBlocked}
      onClick={
        !isLobbyBlocked ? handlePositionClick(index + 1, team) : undefined
      }
    >
      <StyledEmptySlotTitle>{t('play.customGame.join')}</StyledEmptySlotTitle>
    </StyledEmptyCardWrapper>
  )
}

export default EmptyCardCS
