import { Header5, SideHeader1 } from 'styles/typography'
import { t } from 'i18next'
import CheckboxEmpty from 'images/CheckboxEmpty'
import CheckboxFilled from 'images/CheckboxFilled'
import React from 'react'
import map_test from 'images/map_test.jpg'
import styled, { css } from 'styled-components'

const StyledMapTitle = styled(SideHeader1)`
  position: absolute;
  transform: rotate(-90deg);
  color: rgba(255, 255, 255, 0.2);
  display: inline-block;
  left: -45px;
  top: 50px;
`

const StyledMapWrapper = styled.div`
  position: relative;
  padding-top: 20px;
  padding-left: 34px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  width: 1155px;
`

const StyledMapContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  gap: 5px;
  /* background: rgba(5, 2, 2, 0.03);

  border: 1px solid rgba(255, 255, 255, 0.15);
  border-radius: 6px; */
`

interface StyledMapItem {
  $url?: string
}
const StyledMapItem = styled.div<StyledMapItem>`
  position: relative;

  height: 308px;
  width: 117px;

  border-radius: 6px;

  background-position: center;
  background-size: cover;

  cursor: pointer;

  ${({ $url }) =>
    !$url &&
    css`
      background-image: url('${map_test}');
    `}

  &:hover {
    background-color: rgba(238, 157, 62, 0.15);
  }

  transition: 0.2s;
`

interface StyledMapItemMaskProps {
  $isActive: boolean
  $isInactive: boolean
}

const StyledMapItemMask = styled.div<StyledMapItemMaskProps>`
  position: absolute;
  height: 308px;
  width: 117px;

  border-radius: 6px;

  &:hover {
    background-color: rgba(238, 157, 62, 0.15);
  }

  ${({ $isActive, $isInactive }) => css`
    border: ${$isActive ? '2px solid #FFCD29;' : '2px solid transparent'};
    background-color: ${$isActive
      ? 'transparent'
      : $isInactive
        ? 'rgba(0, 0, 0, 0.5)'
        : 'transparent'};
  `}
  transition: 0.2s;
`

const StlyledCheckboxEmpty = styled(CheckboxEmpty)`
  position: absolute;
  top: 7px;
  left: 7px;
`

const StlyledCheckboxFilled = styled(CheckboxFilled)`
  position: absolute;
  top: 7px;
  left: 7px;
`

const StyledModeTitle = styled(Header5)`
  position: absolute;
  bottom: 15px;
  width: 100%;
  display: flex;
  justify-content: center;
  height: 50px;
  align-items: center;
  padding: 0 10px;

  text-transform: uppercase;
  text-align: center;
`

const MODES = [
  { image: '', title: 'All pick' },
  { image: '', title: 'Single Draft' },
  { image: '', title: 'Random Draft' },
  { image: '', title: 'Turbo' },
  { image: '', title: 'All Random' },
  { image: '', title: 'Captains mode' },
  { image: '', title: 'Ability draft' },
  { image: '', title: 'All Random Deathmatch' },
  { image: '', title: 'Captains Draft' },
]

const ModeList = () => {
  const [currentMap, setCurrentMap] = React.useState<number | null>(null)

  return (
    <>
      <StyledMapWrapper>
        <StyledMapTitle>{t('play.customGame.mode')}</StyledMapTitle>

        <StyledMapContainer>
          {MODES.map((item, index) => (
            <StyledMapItem
              key={index}
              onClick={() => setCurrentMap(currentMap === index ? null : index)}
            >
              <StyledMapItemMask
                $isActive={currentMap === index}
                $isInactive={currentMap !== null}
              />
              {currentMap !== index && <StlyledCheckboxEmpty />}
              {currentMap === index && <StlyledCheckboxFilled />}
              <StyledModeTitle>{item.title}</StyledModeTitle>
            </StyledMapItem>
          ))}
        </StyledMapContainer>
      </StyledMapWrapper>
    </>
  )
}

export default ModeList
