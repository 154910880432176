import { $userProfile } from 'stores/userStore'
import { DtoMatch } from 'api/schemas/matchApi'
import { Header5, Header6 } from 'styles/typography'
import { useStore } from '@nanostores/react'
import { useTranslation } from 'react-i18next'
import Button from 'components/Button'
import Coin from 'images/Coin'
import CopyWrapper from 'components/CopyWrapper'
import NickTrimmer from 'components/NickTrimmer'
import WithUserMenu from 'components/ContextMenu/WithUserMenu'
import dayjs from 'dayjs'
import styled from 'styled-components'

interface StyledButton {
  $selected?: boolean
}
const StyledButton = styled(Header6)<StyledButton>`
  display: flex;
  align-items: center;
  height: 32px;
  width: 100%;
  border: ${({ $selected }) =>
    `${
      $selected
        ? '1px solid rgba(255, 255, 255, 0)'
        : '1px solid rgba(255, 255, 255, 0.1)'
    }`};
  border-radius: 3px;
  background: ${({ $selected }) =>
    `${$selected ? '#2cff80' : 'rgba(255, 255, 255, 0.15)'}`};
  backdrop-filter: ${({ $selected }) =>
    `${$selected ? 'unset' : 'blur(7.5px)'}`};
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    background: #2cff80;
    color: black;
  }
`

const StyledButton_Person = styled(Button)<StyledButton>`
  height: 62px;
  padding: 6px;
  border-radius: 3px;
  border: 1px solid var(--BaseButtonOutline, rgba(255, 255, 255, 0.15));
  background: rgba(255, 255, 255, 0.03);

  border: ${({ $selected }) =>
    `${$selected ? '1px solid #FFCD29' : '1px solid rgba(255, 255, 255, 0)'}`};

  background: ${({ $selected }) =>
    `${$selected ? 'rgba(238, 157, 62, 0.15)' : 'rgba(255, 255, 255, 0.03)'}`};
`
const StyledButtonText = styled(Header6)<StyledButton>`
  color: inherit;
  text-transform: uppercase;
  line-height: 100%;
  margin-left: 10px;

  height: 100%;
  display: flex;
  align-items: center;
`

interface StyledImage {
  $imgUrl: string
}
const StyledImage = styled.div<StyledImage>`
  min-width: 50px;
  height: 50px;
  background-image: ${({ $imgUrl }) =>
    `${$imgUrl ? `url(${$imgUrl})` : 'unset'}`};
  background-size: contain;
`

const StyledButtonOrigin = styled(Button)<StyledButton>`
  width: 100%;
  justify-content: center;

  border: ${({ $selected }) =>
    `${$selected ? '1px solid #FFCD29' : '1px solid rgba(255, 255, 255, 0)'}`};

  background: ${({ $selected }) =>
    `${$selected ? 'rgba(238, 157, 62, 0.15)' : 'rgba(255, 255, 255, 0.03)'}`};
`

const StyledButtonContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;

  stroke: white;
`

interface ChooseButtonProps {
  id: string
  text: string
  time?: string
  imgUrl?: string
  match?: DtoMatch
  selected?: boolean
  onClick?: () => void
  icon?: React.ReactNode
  type: 'button' | 'match' | 'person'
}

const ChooseButton: React.FC<ChooseButtonProps> = ({
  type,
  text,
  icon,
  match,
  imgUrl,
  onClick,
  selected,
}) => {
  const { t } = useTranslation()
  const currentUser = useStore($userProfile)

  const gameWinner = match?.CSMatchResult?.isDraw
    ? ''
    : match?.CSMatchResult?.winTeamColor

  const redScore =
    gameWinner === 'RED'
      ? match?.CSMatchResult?.winTeamScore
      : match?.CSMatchResult?.loseTeamScore
  const blueScore =
    gameWinner === 'BLUE'
      ? match?.CSMatchResult?.winTeamScore
      : match?.CSMatchResult?.loseTeamScore

  return (
    <>
      {type === 'button' && (
        // <StyledButton_Button onClick={onClick} $selected={selected}>
        //   <StyledButtonText>{text}</StyledButtonText>
        // </StyledButton_Button>
        <StyledButtonOrigin size="s" onClick={onClick} $selected={selected}>
          <StyledButtonContent>
            {icon} {text}
          </StyledButtonContent>
        </StyledButtonOrigin>
      )}
      {type === 'match' && (
        <StyledMatchItem onClick={onClick} $selected={selected}>
          <StyledLegendTextCenter>
            {dayjs(match?.createdAt).format('DD.MM.YY')}
          </StyledLegendTextCenter>
          <CopyWrapper textToCopy={match?.externalId || ''}>
            <StyledId># {match?.externalId || ''}</StyledId>
          </CopyWrapper>
          <StyledTeamLeft>
            {gameWinner ? (
              gameWinner === 'RED' ? (
                <StyledResultTextWin>win</StyledResultTextWin>
              ) : (
                <StyledResultTextLose>lose</StyledResultTextLose>
              )
            ) : (
              <StyledResultTextLose>draw</StyledResultTextLose>
            )}
            <StyledAvatars $winner={gameWinner === 'RED'}>
              {match?.participants
                ?.filter((participant) => participant.teamColor === 'RED')
                .map((participant, index) => (
                  <WithUserMenu
                    key={participant.user?.id}
                    profile={participant.user}
                  >
                    <StyledAvatar
                      $avatar={participant.user?.imgUrl}
                      $isCurrentUser={participant.user?.id === currentUser?.id}
                      $index={index}
                      $text={
                        participant.user?.id === currentUser?.id
                          ? t('play.statistic.you')
                          : undefined
                      }
                    />
                  </WithUserMenu>
                ))}
            </StyledAvatars>
            <StyledCountResult $winner={gameWinner === 'RED'}>
              {redScore}
            </StyledCountResult>
          </StyledTeamLeft>
          <StyledTime>
            {getInterval(match?.startAt || '', match?.finishAt || '')}
          </StyledTime>
          <StyledTeamRight>
            <StyledCountResult $winner={gameWinner === 'BLUE'}>
              {blueScore}
            </StyledCountResult>
            <StyledAvatars $winner={gameWinner === 'BLUE'}>
              {match?.participants
                ?.filter((participant) => participant.teamColor === 'BLUE')
                .map((participant, index) => (
                  <WithUserMenu
                    key={participant.user?.id}
                    profile={participant.user}
                  >
                    <StyledAvatar
                      $avatar={participant.user?.imgUrl}
                      $isCurrentUser={participant.user?.id === currentUser?.id}
                      $index={index}
                      $text={
                        participant.user?.id === currentUser?.id
                          ? t('play.statistic.you')
                          : undefined
                      }
                    />
                  </WithUserMenu>
                ))}
            </StyledAvatars>
            {gameWinner ? (
              gameWinner === 'BLUE' ? (
                <StyledResultTextWin>win</StyledResultTextWin>
              ) : (
                <StyledResultTextLose>lose</StyledResultTextLose>
              )
            ) : (
              <StyledResultTextLose>draw</StyledResultTextLose>
            )}
          </StyledTeamRight>
          <StyledReward>
            <StyledCoin></StyledCoin>
            {match?.bet}
          </StyledReward>
        </StyledMatchItem>
      )}
      {type === 'person' && (
        <StyledButton_Person onClick={onClick} $selected={selected}>
          <StyledImage $imgUrl={imgUrl || ''} />
          <NickTrimmer>
            <StyledButtonText>{text}</StyledButtonText>
          </NickTrimmer>
        </StyledButton_Person>
      )}
    </>
  )
}

export default ChooseButton

const StyledLegendText = styled(Header5)`
  font-weight: bold;
  font-size: 14px;
  text-transform: uppercase;
  color: white;
  opacity: 0.3;
`
const StyledLegendTextCenter = styled(StyledLegendText)`
  display: flex;
  justify-content: center;
`

const StyledMatchItem = styled(Button)<StyledButton>`
  height: 62px;
  width: 784px;
  min-width: 784px;
  padding: 6px;
  border-radius: 3px;
  border: 1px solid var(--BaseButtonOutline, rgba(255, 255, 255, 0.15));
  background: rgba(255, 255, 255, 0.03);
  backdrop-filter: none;
  border: ${({ $selected }) =>
    `${$selected ? '1px solid #FFCD29' : '1px solid rgba(255, 255, 255, 0)'}`};

  background: ${({ $selected }) =>
    `${$selected ? 'rgba(238, 157, 62, 0.15)' : 'rgba(255, 255, 255, 0.03)'}`};
  & > div {
    display: grid;
    gap: 10px;
    grid-template-columns: 76px 76px 219px 1fr 219px 76px;
    align-items: center;
    transition: 0.3s ease-in-out;
  }
  cursor: pointer;
  z-index: 0;
`

const StyledId = styled.div`
  display: flex;
  justify-content: center;
  font-family: Ruberoid;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  color: white;
`
const StyledTeam = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  height: 100%;
`
const StyledTeamLeft = styled(StyledTeam)`
  justify-content: flex-end;
`
const StyledTeamRight = styled(StyledTeam)`
  justify-content: flex-start;
`
const StyledResultText = styled.div`
  font-family: Ruberoid;
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
`
const StyledResultTextWin = styled(StyledResultText)`
  color: #2cff80;
`
const StyledResultTextLose = styled(StyledResultText)`
  color: #414141;
`
const StyledTime = styled.div`
  font-family: 'Ruberoid';
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  color: white;
`
interface Winner {
  $winner: boolean
}
const StyledAvatars = styled.div<Winner>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 100%;
  gap: 2px;

  &:after {
    display: ${({ $winner }) => `${$winner ? 'block' : 'none'}`};
    content: '';
    position: absolute;
    bottom: -13px;
    width: 196px;
    height: 100%;
    background: radial-gradient(
      51.1% 73.47% at 50% 119.39%,
      #00ff85 0%,
      #0f6 8.85%,
      rgba(0, 255, 178, 0.34) 49.48%,
      rgba(0, 148, 255, 0) 100%
    );
    z-index: -1;
  }
`

interface ItsYou {
  $text?: string
  $index: number
  $avatar?: string
  $isCurrentUser?: boolean
}
const StyledAvatar = styled.div<ItsYou>`
  display: flex;
  justify-content: center;
  width: 30px;
  height: 30px;
  background-image: url('${({ $avatar }) => $avatar}');
  background-size: contain;
  cursor: pointer;

  margin-left: ${({ $index }) => `-${$index * 15}px`};

  &:after {
    display: ${({ $isCurrentUser }) => `${$isCurrentUser ? 'flex' : 'none'}`};
    align-items: center;
    justify-content: center;
    content: '${(props) => props.$text}';

    color: #000;
    font-family: Ruberoid;
    font-size: 8px;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    width: 24px;
    height: 9px;
    background-color: #f2b927;
    position: absolute;
    bottom: -9px;
  }

  &:before {
    display: ${({ $isCurrentUser }) => `${$isCurrentUser ? 'block' : 'none'}`};
    content: '';
    background-color: #f2b927;
    width: 5px;
    height: 5px;
    transform: rotate(45deg);
    position: absolute;
    bottom: -2.5px;
  }

  transition: 0.2s;

  &:hover {
    transform: scale(1.02);
    z-index: 1;
  }
`
const StyledCountResult = styled.div<Winner>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 30px;
  border-radius: 3px;
  background-color: ${({ $winner }) => `${$winner ? '#2cff80' : '#414141'}`};
  color: ${({ $winner }) => `${$winner ? 'black' : 'white'}`};
  font-family: Ruberoid;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
`
const StyledReward = styled.div`
  display: flex;
  align-items: center;
  gap: 11px;
  font-family: Ruberoid;
  font-size: 26px;
  font-weight: 400;
  line-height: normal;
  color: white;
`
const StyledCoin = styled(Coin)`
  fill: #ffcd29;
  height: 24px;
  width: 24px;
`

const getInterval = (start: string, end: string) => {
  const min = (dayjs(end).diff(dayjs(start)) / 1000 / 60).toFixed(0)
  const sec = ((dayjs(end).diff(dayjs(start)) / 1000) % 60).toFixed(0)
  return `${Number(min) < 10 ? 0 : ''}${min}:${Number(sec) < 10 ? 0 : ''}${sec}`
}
