/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface DtoFriend {
  country?: string
  externalId?: string
  gameName?: string
  gameStatistics?: DtoFriendGameStatistic[]
  id?: string
  imgUrl?: string
  isChosen?: boolean
  lobbyId?: string
  matchId?: string
  name?: string
  online?: boolean
  partyId?: string
  steamId?: string
}

export interface DtoFriendGameStatistic {
  gameFormat?: string
  gameName?: string
  lastMatchTime?: string
  matchDrawCount?: number
  matchLoseCount?: number
  matchWinCount?: number
}

export interface DtoFriendInvite {
  friendId?: string
}

export interface DtoParty {
  createdBy?: string
  hostId?: string
  id?: string
  invitedParticipants?: DtoPartyParticipant[]
  participants?: DtoPartyParticipant[]
  status?: string
  statusTimestamp?: string
}

export interface DtoPartyCloseRequest {
  participantIds?: string[]
}

export interface DtoPartyInviteRequest {
  participantId?: string
}

export interface DtoPartyParticipant {
  createdAt?: string
  invitedBy?: DtoUser
  status?: string
  user?: DtoUser
}

export interface DtoUser {
  country?: string
  externalId?: string
  gameName?: string
  id?: string
  imgUrl?: string
  lobbyId?: string
  matchId?: string
  name?: string
  online?: boolean
  partyId?: string
  steamId?: string
}

export interface ResponseResponse {
  data?: any
  meta?: ResponseMeta
  pagination?: ResponsePagination
}

export interface ResponseMeta {
  code?: string
  error?: string
  message?: string
  requestId?: string
  traceId?: string
}

export interface ResponsePagination {
  page?: number
  pages?: number
  perPage?: number
}

export namespace Friends {
  /**
   * @description Create friend invite request
   * @tags Friends
   * @name PostInvite
   * @summary Create friend invite request
   * @request POST:/friends/invite
   */
  export namespace PostInvite {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = DtoFriendInvite
    export type RequestHeaders = {
      /**
       * Access token
       * @default "Bearer <token>"
       */
      Authorization: string
      /** Request id identity */
      'X-Request-Id': string
    }
    export type ResponseBody = ResponseResponse
  }

  /**
   * @description Accept friend invite request
   * @tags Friends
   * @name PostInviteAccept
   * @summary Accept friend invite request
   * @request POST:/friends/invite/{id}/accept
   */
  export namespace PostInviteAccept {
    export type RequestParams = {
      /** Friend Request ID */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {
      /**
       * Access token
       * @default "Bearer <token>"
       */
      Authorization: string
      /** Request id identity */
      'X-Request-Id': string
    }
    export type ResponseBody = ResponseResponse
  }

  /**
   * @description Decline friend invite request
   * @tags Friends
   * @name PostInviteDecline
   * @summary Decline friend invite request
   * @request POST:/friends/invite/{id}/decline
   */
  export namespace PostInviteDecline {
    export type RequestParams = {
      /** Friend Request ID */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {
      /**
       * Access token
       * @default "Bearer <token>"
       */
      Authorization: string
      /** Request id identity */
      'X-Request-Id': string
    }
    export type ResponseBody = ResponseResponse
  }

  /**
   * @description Get friends with State
   * @tags Friends
   * @name GetState
   * @summary Get friends with State
   * @request GET:/friends/state
   */
  export namespace GetState {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {
      /**
       * Access token
       * @default "Bearer <token>"
       */
      Authorization: string
      /** Request id identity */
      'X-Request-Id': string
    }
    export type ResponseBody = ResponseResponse & {
      data?: DtoFriend[]
    }
  }

  /**
   * @description Get friends with State
   * @tags Friends
   * @name GetStatistic
   * @summary Get friends with State
   * @request GET:/friends/statistic
   */
  export namespace GetStatistic {
    export type RequestParams = {}
    export type RequestQuery = {
      /** userId */
      userId: string
    }
    export type RequestBody = never
    export type RequestHeaders = {
      /**
       * Access token
       * @default "Bearer <token>"
       */
      Authorization: string
      /** Request id identity */
      'X-Request-Id': string
    }
    export type ResponseBody = ResponseResponse & {
      data?: DtoFriend[]
    }
  }

  /**
   * @description Delete friend
   * @tags Friends
   * @name DeleteFriends
   * @summary Delete friend
   * @request DELETE:/friends/{friendId}
   */
  export namespace DeleteFriends {
    export type RequestParams = {
      /** Friend ID */
      friendId: string
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {
      /**
       * Access token
       * @default "Bearer <token>"
       */
      Authorization: string
      /** Request id identity */
      'X-Request-Id': string
    }
    export type ResponseBody = ResponseResponse
  }

  /**
   * @description Mark Friend as chosen
   * @tags Friends
   * @name PostChosen
   * @summary Mark Friend as chosen
   * @request POST:/friends/{friendId}/chosen
   */
  export namespace PostChosen {
    export type RequestParams = {
      /** Friend ID */
      friendId: string
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {
      /**
       * Access token
       * @default "Bearer <token>"
       */
      Authorization: string
      /** Request id identity */
      'X-Request-Id': string
    }
    export type ResponseBody = ResponseResponse
  }
}

export namespace Party {
  /**
   * @description Create party
   * @tags Party
   * @name PostParty
   * @summary Create party
   * @request POST:/party
   */
  export namespace PostParty {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {
      /**
       * Access token
       * @default "Bearer <token>"
       */
      Authorization: string
      /** Request id identity */
      'X-Request-Id': string
    }
    export type ResponseBody = ResponseResponse & {
      data?: DtoParty
    }
  }

  /**
   * @description Get current party by id
   * @tags Party
   * @name GetCurrent
   * @summary Get current party by id
   * @request GET:/party/current
   */
  export namespace GetCurrent {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {
      /**
       * Access token
       * @default "Bearer <token>"
       */
      Authorization: string
      /** Request id identity */
      'X-Request-Id': string
    }
    export type ResponseBody = ResponseResponse & {
      data?: DtoParty
    }
  }

  /**
   * @description Get party by id
   * @tags Party
   * @name GetParty
   * @summary Get party by id
   * @request GET:/party/{id}
   */
  export namespace GetParty {
    export type RequestParams = {
      /** PartyId ID */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {
      /**
       * Access token
       * @default "Bearer <token>"
       */
      Authorization: string
      /** Request id identity */
      'X-Request-Id': string
    }
    export type ResponseBody = ResponseResponse & {
      data?: DtoParty
    }
  }

  /**
   * @description Dismiss party
   * @tags Party
   * @name PostDismiss
   * @summary Dismiss party
   * @request POST:/party/{id}/dismiss
   */
  export namespace PostDismiss {
    export type RequestParams = {
      /** PartyId ID */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {
      /**
       * Access token
       * @default "Bearer <token>"
       */
      Authorization: string
      /** Request id identity */
      'X-Request-Id': string
    }
    export type ResponseBody = ResponseResponse
  }

  /**
   * @description Change party host(owner)
   * @tags Party
   * @name PostHost
   * @summary Change party host(owner)
   * @request POST:/party/{id}/host/{hostId}
   */
  export namespace PostHost {
    export type RequestParams = {
      /** PartyId ID */
      id: string
      /** New User-Host ID */
      hostId: string
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {
      /**
       * Access token
       * @default "Bearer <token>"
       */
      Authorization: string
      /** Request id identity */
      'X-Request-Id': string
    }
    export type ResponseBody = ResponseResponse & {
      data?: DtoParty
    }
  }

  /**
   * @description Invite participant to party
   * @tags Party
   * @name PostInvite
   * @summary Invite participant to party
   * @request POST:/party/{id}/invite
   */
  export namespace PostInvite {
    export type RequestParams = {
      /** PartyId ID */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = DtoPartyInviteRequest
    export type RequestHeaders = {
      /**
       * Access token
       * @default "Bearer <token>"
       */
      Authorization: string
      /** Request id identity */
      'X-Request-Id': string
    }
    export type ResponseBody = ResponseResponse & {
      data?: DtoParty
    }
  }

  /**
   * @description Close party invite
   * @tags Party
   * @name PostInviteClose
   * @summary Close party invite
   * @request POST:/party/{id}/invite/close
   */
  export namespace PostInviteClose {
    export type RequestParams = {
      /** PartyId ID */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = DtoPartyCloseRequest
    export type RequestHeaders = {
      /**
       * Access token
       * @default "Bearer <token>"
       */
      Authorization: string
      /** Request id identity */
      'X-Request-Id': string
    }
    export type ResponseBody = ResponseResponse & {
      data?: DtoParty
    }
  }

  /**
   * @description Decline request to party
   * @tags Party
   * @name PostInviteDecline
   * @summary Decline request to party
   * @request POST:/party/{id}/invite/decline
   */
  export namespace PostInviteDecline {
    export type RequestParams = {
      /** PartyId ID */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {
      /**
       * Access token
       * @default "Bearer <token>"
       */
      Authorization: string
      /** Request id identity */
      'X-Request-Id': string
    }
    export type ResponseBody = ResponseResponse
  }

  /**
   * @description Join to party
   * @tags Party
   * @name PostJoin
   * @summary Join to party
   * @request POST:/party/{id}/join
   */
  export namespace PostJoin {
    export type RequestParams = {
      /** PartyId ID */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {
      /**
       * Access token
       * @default "Bearer <token>"
       */
      Authorization: string
      /** Request id identity */
      'X-Request-Id': string
    }
    export type ResponseBody = ResponseResponse & {
      data?: DtoParty
    }
  }

  /**
   * @description Kick participant
   * @tags Party
   * @name PostKick
   * @summary Kick participant
   * @request POST:/party/{id}/kick/{participantId}
   */
  export namespace PostKick {
    export type RequestParams = {
      /** PartyId ID */
      id: string
      /** Kick participant ID */
      participantId: string
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {
      /**
       * Access token
       * @default "Bearer <token>"
       */
      Authorization: string
      /** Request id identity */
      'X-Request-Id': string
    }
    export type ResponseBody = ResponseResponse & {
      data?: DtoParty
    }
  }

  /**
   * @description Leave from party
   * @tags Party
   * @name PostLeave
   * @summary Leave from party
   * @request POST:/party/{id}/leave
   */
  export namespace PostLeave {
    export type RequestParams = {
      /** PartyId ID */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {
      /**
       * Access token
       * @default "Bearer <token>"
       */
      Authorization: string
      /** Request id identity */
      'X-Request-Id': string
    }
    export type ResponseBody = ResponseResponse
  }
}

import type { AxiosInstance, AxiosRequestConfig, HeadersDefaults, ResponseType } from 'axios'
import axios from 'axios'

export type QueryParamsType = Record<string | number, any>

export interface FullRequestParams extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean
  /** request path */
  path: string
  /** content type of request body */
  type?: ContentType
  /** query params */
  query?: QueryParamsType
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType
  /** request body */
  body?: unknown
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
  securityWorker?: (
    securityData: SecurityDataType | null
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void
  secure?: boolean
  format?: ResponseType
}

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance
  private securityData: SecurityDataType | null = null
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker']
  private secure?: boolean
  private format?: ResponseType

  constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({
      ...axiosConfig,
      baseURL: axiosConfig.baseURL || '//localhost:8080/f2f/friends/api/v1',
    })
    this.secure = secure
    this.format = format
    this.securityWorker = securityWorker
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data
  }

  protected mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method)

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method && this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    }
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === 'object' && formItem !== null) {
      return JSON.stringify(formItem)
    } else {
      return `${formItem}`
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    if (input instanceof FormData) {
      return input
    }
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key]
      const propertyContent: any[] = property instanceof Array ? property : [property]

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem))
      }

      return formData
    }, new FormData())
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<T> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {}
    const requestParams = this.mergeRequestParams(params, secureParams)
    const responseFormat = format || this.format || undefined

    if (type === ContentType.FormData && body && body !== null && typeof body === 'object') {
      body = this.createFormData(body as Record<string, unknown>)
    }

    if (type === ContentType.Text && body && body !== null && typeof body !== 'string') {
      body = JSON.stringify(body)
    }

    return this.instance
      .request({
        ...requestParams,
        headers: {
          ...(requestParams.headers || {}),
          ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
        },
        params: query,
        responseType: responseFormat,
        data: body,
        url: path,
      })
      .then((response) => response.data)
  }
}

/**
 * @title Friends API
 * @version 1.0
 * @baseUrl //localhost:8080/f2f/friends/api/v1
 * @contact Mikhel Alexander
 *
 * This is friends api service.
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  friends = {
    /**
     * @description Create friend invite request
     *
     * @tags Friends
     * @name PostInvite
     * @summary Create friend invite request
     * @request POST:/friends/invite
     */
    postInvite: (request: DtoFriendInvite, params: RequestParams = {}) =>
      this.request<ResponseResponse, any>({
        path: `/friends/invite`,
        method: 'POST',
        body: request,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Accept friend invite request
     *
     * @tags Friends
     * @name PostInviteAccept
     * @summary Accept friend invite request
     * @request POST:/friends/invite/{id}/accept
     */
    postInviteAccept: (id: string, params: RequestParams = {}) =>
      this.request<ResponseResponse, any>({
        path: `/friends/invite/${id}/accept`,
        method: 'POST',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Decline friend invite request
     *
     * @tags Friends
     * @name PostInviteDecline
     * @summary Decline friend invite request
     * @request POST:/friends/invite/{id}/decline
     */
    postInviteDecline: (id: string, params: RequestParams = {}) =>
      this.request<ResponseResponse, any>({
        path: `/friends/invite/${id}/decline`,
        method: 'POST',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Get friends with State
     *
     * @tags Friends
     * @name GetState
     * @summary Get friends with State
     * @request GET:/friends/state
     */
    getState: (params: RequestParams = {}) =>
      this.request<
        ResponseResponse & {
          data?: DtoFriend[]
        },
        any
      >({
        path: `/friends/state`,
        method: 'GET',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Get friends with State
     *
     * @tags Friends
     * @name GetStatistic
     * @summary Get friends with State
     * @request GET:/friends/statistic
     */
    getStatistic: (
      query: {
        /** userId */
        userId: string
      },
      params: RequestParams = {}
    ) =>
      this.request<
        ResponseResponse & {
          data?: DtoFriend[]
        },
        any
      >({
        path: `/friends/statistic`,
        method: 'GET',
        query: query,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Delete friend
     *
     * @tags Friends
     * @name DeleteFriends
     * @summary Delete friend
     * @request DELETE:/friends/{friendId}
     */
    deleteFriends: (friendId: string, params: RequestParams = {}) =>
      this.request<ResponseResponse, any>({
        path: `/friends/${friendId}`,
        method: 'DELETE',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Mark Friend as chosen
     *
     * @tags Friends
     * @name PostChosen
     * @summary Mark Friend as chosen
     * @request POST:/friends/{friendId}/chosen
     */
    postChosen: (friendId: string, params: RequestParams = {}) =>
      this.request<ResponseResponse, any>({
        path: `/friends/${friendId}/chosen`,
        method: 'POST',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  party = {
    /**
     * @description Create party
     *
     * @tags Party
     * @name PostParty
     * @summary Create party
     * @request POST:/party
     */
    postParty: (params: RequestParams = {}) =>
      this.request<
        ResponseResponse & {
          data?: DtoParty
        },
        any
      >({
        path: `/party`,
        method: 'POST',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Get current party by id
     *
     * @tags Party
     * @name GetCurrent
     * @summary Get current party by id
     * @request GET:/party/current
     */
    getCurrent: (params: RequestParams = {}) =>
      this.request<
        ResponseResponse & {
          data?: DtoParty
        },
        any
      >({
        path: `/party/current`,
        method: 'GET',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Get party by id
     *
     * @tags Party
     * @name GetParty
     * @summary Get party by id
     * @request GET:/party/{id}
     */
    getParty: (id: string, params: RequestParams = {}) =>
      this.request<
        ResponseResponse & {
          data?: DtoParty
        },
        any
      >({
        path: `/party/${id}`,
        method: 'GET',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Dismiss party
     *
     * @tags Party
     * @name PostDismiss
     * @summary Dismiss party
     * @request POST:/party/{id}/dismiss
     */
    postDismiss: (id: string, params: RequestParams = {}) =>
      this.request<ResponseResponse, any>({
        path: `/party/${id}/dismiss`,
        method: 'POST',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Change party host(owner)
     *
     * @tags Party
     * @name PostHost
     * @summary Change party host(owner)
     * @request POST:/party/{id}/host/{hostId}
     */
    postHost: (id: string, hostId: string, params: RequestParams = {}) =>
      this.request<
        ResponseResponse & {
          data?: DtoParty
        },
        any
      >({
        path: `/party/${id}/host/${hostId}`,
        method: 'POST',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Invite participant to party
     *
     * @tags Party
     * @name PostInvite
     * @summary Invite participant to party
     * @request POST:/party/{id}/invite
     */
    postInvite: (id: string, request: DtoPartyInviteRequest, params: RequestParams = {}) =>
      this.request<
        ResponseResponse & {
          data?: DtoParty
        },
        any
      >({
        path: `/party/${id}/invite`,
        method: 'POST',
        body: request,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Close party invite
     *
     * @tags Party
     * @name PostInviteClose
     * @summary Close party invite
     * @request POST:/party/{id}/invite/close
     */
    postInviteClose: (id: string, request: DtoPartyCloseRequest, params: RequestParams = {}) =>
      this.request<
        ResponseResponse & {
          data?: DtoParty
        },
        any
      >({
        path: `/party/${id}/invite/close`,
        method: 'POST',
        body: request,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Decline request to party
     *
     * @tags Party
     * @name PostInviteDecline
     * @summary Decline request to party
     * @request POST:/party/{id}/invite/decline
     */
    postInviteDecline: (id: string, params: RequestParams = {}) =>
      this.request<ResponseResponse, any>({
        path: `/party/${id}/invite/decline`,
        method: 'POST',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Join to party
     *
     * @tags Party
     * @name PostJoin
     * @summary Join to party
     * @request POST:/party/{id}/join
     */
    postJoin: (id: string, params: RequestParams = {}) =>
      this.request<
        ResponseResponse & {
          data?: DtoParty
        },
        any
      >({
        path: `/party/${id}/join`,
        method: 'POST',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Kick participant
     *
     * @tags Party
     * @name PostKick
     * @summary Kick participant
     * @request POST:/party/{id}/kick/{participantId}
     */
    postKick: (id: string, participantId: string, params: RequestParams = {}) =>
      this.request<
        ResponseResponse & {
          data?: DtoParty
        },
        any
      >({
        path: `/party/${id}/kick/${participantId}`,
        method: 'POST',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Leave from party
     *
     * @tags Party
     * @name PostLeave
     * @summary Leave from party
     * @request POST:/party/{id}/leave
     */
    postLeave: (id: string, params: RequestParams = {}) =>
      this.request<ResponseResponse, any>({
        path: `/party/${id}/leave`,
        method: 'POST',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
}
