const PlayerReportIcon: React.FC = () => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_10503_125473)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M23.3587 10.6776C23.3587 13.165 22.4337 15.4364 20.9088 17.1665L22.5214 23.6168C22.6792 24.248 22.2019 24.8594 21.5513 24.8594H13.2304C12.7715 24.8594 12.3715 24.5471 12.2602 24.1019L11.5922 21.4298L9.98365 22.0465C8.43661 22.6397 6.70163 21.8664 6.10848 20.3194L4.87453 17.101L2.41324 15.7238C1.93128 15.4541 1.75919 14.8448 2.02887 14.3628L3.74185 11.3015C3.72891 11.0952 3.72234 10.8871 3.72234 10.6776C3.72234 5.25512 8.11809 0.859375 13.5405 0.859375C18.963 0.859375 23.3587 5.25512 23.3587 10.6776ZM14.8541 4.85935H11.9479L12.4323 12.6051H14.3698L14.8541 4.85935ZM12.3229 16.4187C12.6146 16.7125 12.9739 16.8594 13.401 16.8594C13.8281 16.8594 14.1823 16.7125 14.4635 16.4187C14.7552 16.1136 14.901 15.7464 14.901 15.317C14.901 14.8876 14.7604 14.5317 14.4791 14.2492C14.1979 13.9554 13.8385 13.8085 13.401 13.8085C12.9635 13.8085 12.6041 13.9554 12.3229 14.2492C12.0416 14.5317 11.901 14.8876 11.901 15.317C11.901 15.7464 12.0416 16.1136 12.3229 16.4187Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_10503_125473">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0.214844 0.859375)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default PlayerReportIcon
