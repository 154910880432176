import { Text5 } from 'styles/typography'
import Close from 'images/Close'
import React, { ReactElement } from 'react'
import SelectBase, { SelectProps } from 'react-dropdown-select'
import ShevronLeftIcor from 'images/ShevronLeftIcor'
import styled from 'styled-components'

export interface Option {
  id?: string
  label: string
  value: string | boolean
}

interface InputProps {
  error?: string
  options: Option[]
  disableSelect?: boolean
  rightDecorator?: React.ReactNode
  onClickRightDeacorator?: () => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  label?: string | ReactElement<any, any>
}

const StyledMain = styled.div`
  & .react-dropdown-select-dropdown-handle svg {
    width: 14px !important;
    height: 14px !important;
  }
`

const StyledDropdown = styled.div`
  border-radius: 3px;
  border: 1px solid rgba(255, 255, 255, 0.15);
  background: #34353b;
  box-shadow: 0px 13px 11px 0px rgba(0, 0, 0, 0.25);
  padding: 4px;
`

interface StyledDropdownOptionProps {
  $isActive: boolean
}

const StyledDropdownOption = styled(Text5)<StyledDropdownOptionProps>`
  padding: 9px 16px;
  border-radius: 4px;
  background: ${({ $isActive }) => ($isActive ? '#FFCD29' : 'none')};
  &:hover {
    background: ${({ $isActive }) =>
      $isActive ? '#FFCD29' : 'rgba(238, 157, 62, 0.15)'};
  }

  transition: 0.3s;
`

const StyledLabel = styled(Text5)`
  padding-bottom: 7px;
`

interface StyledShevron {
  $isUp: boolean
}

const StyledShevron = styled(ShevronLeftIcor)<StyledShevron>`
  fill: rgba(44, 255, 128, 1);
  transform: ${({ $isUp }) => ($isUp ? 'rotate(-90deg)' : 'rotate(90deg)')};

  transition: 0.2s;
`

const Select = React.forwardRef(
  (
    {
      error,
      label,
      values,
      options,
      onChange,
      clearable,
      placeholder,
      disableSelect,
      rightDecorator,
      onClickRightDeacorator,
      ...rest
    }: InputProps & SelectProps<Option>,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _ref
  ) => {
    return (
      <StyledMain className={''}>
        {label && <StyledLabel>{label}</StyledLabel>}
        <Text5 className="">
          <SelectBase
            values={values}
            onChange={onChange}
            color="#A4A9B8"
            options={options}
            searchable={false}
            placeholder={placeholder}
            dropdownHandleRenderer={({ state }) => {
              return <StyledShevron $isUp={state.dropdown} />
            }}
            style={{
              height: '30px',
              minHeight: '15px',
              borderRadius: '3px',
              padding: ' 7px 12px',
              backdropFilter: 'blur(7.5px)',
              background: 'rgba(255, 255, 255, 0.03) ',
              border: '1px solid rgba(255, 255, 255, 0.15) ',
            }}
            dropdownRenderer={({ props, methods }) => {
              return (
                <StyledDropdown>
                  {props.options.map((option) => (
                    <StyledDropdownOption
                      key={`${option.value}`}
                      $isActive={methods.isSelected(option)}
                      onClick={() => !disableSelect && methods.addItem(option)}
                    >
                      {/* // className={
                        //   methods.isSelected(option) ? activeItem : item
                        // } */}
                      {option.label}
                    </StyledDropdownOption>
                  ))}
                </StyledDropdown>
              )
            }}
            clearRenderer={({ methods }) => (
              <StyledCloseIcon
                onClick={(e) => {
                  e.stopPropagation()
                  methods.clearAll()
                  onChange([])
                }}
              />
            )}
            clearable={clearable && values.length > 0}
            closeOnClickInput
            {...rest}
          />
          <div className="" onClick={onClickRightDeacorator}>
            {rightDecorator}
          </div>
        </Text5>

        {error && <p className="">{error}</p>}
      </StyledMain>
    )
  }
)

const StyledCloseIcon = styled(Close)`
  width: 16px;
  height: 16px;

  & path {
    fill: #2cff80;
  }
`

export default React.memo(Select)
