import {
  $userPopupWeapons,
  $userStatisticPageWeapons,
  GameChoosen,
} from 'stores/statisticStore'
import { Text4 } from 'styles/typography'
import { styled } from 'styled-components'
import { useStore } from '@nanostores/react'
import { useTranslation } from 'react-i18next'
import BestWeaponsTexture from 'images/BestWeaponsTexture.svg'

const StyledRoot = styled.div`
  display: flex;
  flex-direction: column;
  background-image: url('${BestWeaponsTexture}');
  width: 232px;
  height: 300px;
  border: 1px solid rgba(255, 255, 255, 0.15);
  background-color: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(7.5px);
  padding: 25px 0 0 20px;
`
const StyledCaption = styled.div`
  font-family: Ruberoid;
  font-size: 16px;
  font-weight: 700;
  line-height: 100%;
  text-transform: uppercase;
  color: whitel;
`
const StyledDescription = styled.div`
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  margin-top: 8px;
  opacity: 0.3;
  color: white;
  line-height: 100%;
`
const StyledLine = styled.div`
  display: grid;
  grid-template-columns: 98px 1fr 1fr;
  margin-top: 15px;
`
const StyledWeaponLine = styled(StyledLine)`
  height: 49px;
  padding: 8px 0;
  margin-top: 0;

  &:not(:last-child) {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
`
const StyledTableDescr = styled(StyledDescription)`
  margin-top: 0;
  line-height: 100%;
`
const StyledWeaponCount = styled.div`
  display: flex;
  align-items: center;
  font-family: Ruberoid;
  font-size: 16px;
  font-weight: 700;
  line-height: 100%;
  color: white;
`
interface Image {
  $url?: string
  $color: string
}
const StyledWeapon = styled.div<Image>`
  background-image: url(${({ $url }) => $url || ''});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 85px;

  & > svg {
    fill: ${({ $color }) => $color};
  }
`
const StyledEmpty = styled(Text4)`
  display: flex;
  align-items: center;
  height: 100%;
  color: white;
`

interface IBestWeapons {
  gameChoosen: GameChoosen
  page: 'userProfile' | 'statistic'
}
const BestWeapons: React.FC<IBestWeapons> = ({ page }) => {
  const weaponsInfo =
    page === 'userProfile'
      ? useStore($userPopupWeapons)
      : useStore($userStatisticPageWeapons)
  const { t } = useTranslation()

  return (
    <StyledRoot>
      {weaponsInfo?.length ? (
        <>
          <StyledCaption>{weaponsInfo[0].weapon?.name || ''}</StyledCaption>
          <StyledDescription>
            {t('play.statistic.bestWeapon')}
          </StyledDescription>
          <StyledLine>
            <div></div>
            <StyledTableDescr>{t('play.statistic.kills')}</StyledTableDescr>
            <StyledTableDescr>{t('play.statistic.k/d')}</StyledTableDescr>
          </StyledLine>
          {weaponsInfo.slice(0, 4)?.map((gun) => {
            return (
              <StyledWeaponLine key={gun.weapon?.id}>
                <StyledWeapon $color="#ffcd29" $url={gun.weapon?.imgIconUrl}>
                  {/* <WeaponIcon className="yellow" /> */}
                </StyledWeapon>
                <StyledWeaponCount>{gun.killCount}</StyledWeaponCount>
                <StyledWeaponCount>{gun.KD?.toFixed(2)}</StyledWeaponCount>
              </StyledWeaponLine>
            )
          })}
        </>
      ) : (
        <>
          <StyledCaption>{t('play.statistic.bestWeapon')}</StyledCaption>
          <StyledEmpty>{t('play.statistic.weaponEmpty')}</StyledEmpty>
        </>
      )}
    </StyledRoot>
  )
}

export default BestWeapons
