import {
  $createdAppeal,
  $isCreatingAppeal,
  AppealStageTypes,
  backToStep,
  sendAppeal,
} from 'stores/chatStores/appealStore'
import { EnumClaimReportCategory } from 'api/schemas/supportApi'
import { useStore } from '@nanostores/react'
import { useTranslation } from 'react-i18next'
import BackIcon from 'images/BackIcon'
import Button from 'components/Button'
import styled from 'styled-components'

const StyledWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 7px;
`

const StyledButton = styled(Button)`
  width: 100%;
  display: flex;
  & > div {
    justify-content: space-between;
    width: 100%;
  }
`

const StyledNextButton = styled(StyledButton)`
  width: 100%;
`

const StyledPrevButton = styled(StyledButton)`
  min-width: 33%;
  width: 33%;
`

const StepsButtons: React.FC = () => {
  const { t } = useTranslation()
  const createdAppeal = useStore($createdAppeal)

  const handlePrevClick = () => {
    if (
      !createdAppeal.prevStep &&
      createdAppeal.currentStep !== AppealStageTypes.CHOOSE_CATEGORY
    )
      return

    if (createdAppeal.currentStep === AppealStageTypes.CHOOSE_CATEGORY) {
      $isCreatingAppeal.set(false)
      $createdAppeal.set({})
      return
    }

    backToStep(createdAppeal.prevStep || AppealStageTypes.CHOOSE_CATEGORY)
  }

  const handleNextClick = async () => {
    if (
      !createdAppeal.nextStep &&
      createdAppeal.currentStep !== AppealStageTypes.APPEAL_GENERATED
    )
      return

    if (createdAppeal.currentStep === AppealStageTypes.CHOOSE_CATEGORY) {
      let category: EnumClaimReportCategory | null = null
      switch (createdAppeal.nextStep) {
        case AppealStageTypes.CHOOSE_MATCH:
          category = EnumClaimReportCategory.ClaimReportCategoryMatchReport
          break
        case AppealStageTypes.CHOOSE_PLAYER:
          category = EnumClaimReportCategory.ClaimReportCategoryPlayerReport
          break
        case AppealStageTypes.CHOOSE_SERVICE_REASON:
          category = EnumClaimReportCategory.ClaimReportCategoryServiceReport
          break
        default:
          category = null
      }
      if (category) {
        $createdAppeal.set({
          ...createdAppeal,
          category,
        })
      }
    }

    if (createdAppeal.currentStep === AppealStageTypes.APPEAL_GENERATED) {
      await sendAppeal()
    } else {
      $createdAppeal.set({
        ...createdAppeal,
        nextStep: null,
        currentStep: createdAppeal.nextStep,
        prevStep: createdAppeal.currentStep,
      })
    }
  }

  return (
    <StyledWrapper>
      {createdAppeal.currentStep !== AppealStageTypes.CHOOSE_CATEGORY ? (
        <StyledPrevButton
          onClick={handlePrevClick}
          size="s"
          disabled={!createdAppeal.prevStep}
        >
          {t('chat.appeal.goBack')}
          <BackIcon />
        </StyledPrevButton>
      ) : null}
      <StyledNextButton
        onClick={handleNextClick}
        size="s"
        disabled={
          !createdAppeal.nextStep &&
          createdAppeal.currentStep !== AppealStageTypes.APPEAL_GENERATED
        }
      >
        {createdAppeal.currentStep === AppealStageTypes.APPEAL_GENERATED
          ? `${t('chat.appeal.confirm')}`
          : `${t('chat.appeal.next')}`}
      </StyledNextButton>
    </StyledWrapper>
  )
}

export default StepsButtons
