const GetRewardIcon: React.FC = () => {
  return (
    <svg
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_9359_244540)">
        <path
          fill="white"
          fill-rule="evenodd"
          clip-rule="evenodd"
          fill-opacity="0.15"
          d="M22.6815 15.6701L21.9903 4.83277C21.9821 4.70296 21.8911 4.59327 21.765 4.56115L11.242 1.87963C11.211 1.87173 11.1781 1.87914 11.1534 1.89957L8.82488 3.83116C8.76198 3.88334 8.78484 3.98484 8.86404 4.00502L17.9107 6.31033L1.25581 20.126L2.90609 22.1154L19.5594 8.30106L20.1534 17.6144C20.1586 17.696 20.2541 17.7372 20.317 17.685L22.6456 15.7534C22.6702 15.733 22.6835 15.702 22.6815 15.6701Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_9359_244540">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default GetRewardIcon
