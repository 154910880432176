import { $isOffline } from 'stores/appStore'
import { Api as AuthApi } from 'api/schemas/authApi'
import { Api as ChatApi } from 'api/schemas/chatApi'
import { Api as DictionaryApi } from 'api/schemas/dictionaryApi'
import { Api as FilestorageApi } from 'api/schemas/filestorageApi'
import { Api as FriendsApi } from 'api/schemas/friendsApi'
import { Api as GameControllerApi } from 'api/schemas/gamecontroller'
import { Api as JudgeApi } from 'api/schemas/judgeApi'
import { Api as LobbyApi } from 'api/schemas/lobbyApi'
import { Api as MatchApi } from 'api/schemas/matchApi'
import { Api as MatchmakingApi } from 'api/schemas/matchmakingApi'
import { Api as NotificationApi } from 'api/schemas/notificationApi'
import { Api as SocketApi } from 'api/schemas/socketApi'
import { Api as StatisticApi } from 'api/schemas/statisticApi'
import { Api as SupportApi } from 'api/schemas/supportApi'
import { Api as UserApi } from 'api/schemas/userApi'
import { Api as WalletApi } from 'api/schemas/walletApi'
import { logWarn } from 'utils/logger'
import { logout } from 'stores/authStore'
import { redirect } from 'react-router-dom'
import { t } from 'i18next'
import { v4 as uuidv4 } from 'uuid'
// eslint-disable-next-line import/named
import axios, { AxiosInstance } from 'axios'
import getNotificationTexts from 'utils/getNotificationTexts'
import routes from 'router/routes'
import showNotification from 'utils/showNotification'

interface Meta {
  code: number
  error?: string
  message: string
}

interface ApiErrorData {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any
  meta: Meta
}

// export const isProd = process.env.NODE_ENV === 'production'
export const isProd =
  !window.location.href.includes('test') &&
  !window.location.href.includes('localhost')

export const BASE_URL = isProd ? 'https://f2f.vin' : 'https://test.f2f.vin'
export const BASE_URL_WITH_PORT = `${BASE_URL}:${isProd ? '30' : '31'}`

export interface ApiError {
  response: {
    data: ApiErrorData
  }
}

const authApi = new AuthApi({
  baseURL: `${BASE_URL_WITH_PORT}001/f2f/auth/api/v1`,
})

const withHeaders = (instance: AxiosInstance) => {
  instance.interceptors.request.use(
    function (config) {
      config.headers.set({
        'x-request-id': uuidv4(),
      })
      config.withCredentials = true

      return config
    },
    function (error) {
      // showNotification(error.message, 'error')
      console.log(error)
      return Promise.reject(error)
    }
  )
}

withHeaders(authApi.instance)

export async function verifyToken() {
  try {
    await axios.get(
      `${BASE_URL_WITH_PORT}001/f2f/auth/api/v1/accessToken/verify`,
      {
        withCredentials: true,
        headers: {
          'x-request-id': uuidv4(),
        },
      }
    )
    return true
  } catch (e) {
    return false
  }
}

export async function refreshAccessToken() {
  try {
    await axios.get(
      `${BASE_URL_WITH_PORT}001/f2f/auth/api/v1/accessToken/refresh`,
      {
        withCredentials: true,
        headers: {
          'x-request-id': uuidv4(),
        },
      }
    )

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (err: any) {
    await logout()
    redirect(routes.login())

    if (err?.response?.status !== 401) {
      throw err
    }
  }
}

const withAuthAndErrors = (instance: AxiosInstance) => {
  instance.interceptors.request.use(
    function (config) {
      config.headers.set({
        'x-request-id': uuidv4(),
      })
      config.withCredentials = true

      return config
    },
    function (error) {
      // showNotification(error.message, 'error')
      console.log(error)
      return Promise.reject(error)
    }
  )

  instance.interceptors.response.use(
    (response) => {
      if ($isOffline.get()) {
        $isOffline.set(false)
      }
      return response
    },
    (error) => {
      const originalRequest = error.config

      if (!window.navigator.onLine) {
        return
      }

      console.log(error)
      if (
        (!error.response &&
          error.code === 'ERR_NETWORK' &&
          process.env.NODE_ENV !== 'production' &&
          !originalRequest._retry) ||
        (error?.response?.status === 401 && !originalRequest._retry)
      ) {
        originalRequest._retry = true
        return refreshAccessToken().then(() => {
          return axios(originalRequest)
        })
      }

      const notificationTexts = getNotificationTexts(
        error?.response?.data?.meta?.message
      )

      if (notificationTexts) {
        showNotification({
          type: 'info',
          id: notificationTexts.id,
          title: notificationTexts.title,
          text: notificationTexts.message,
        })
      } else {
        showNotification({
          id: `${error?.response?.data?.meta?.message}${error?.response?.status}${error?.response?.statusText}`,
          text:
            error?.response?.data?.meta?.message ||
            t('errors.defaultServerText'),
          title: `${error?.response?.status} ${
            error?.response?.statusText || t('errors.defaultServerTitle')
          }`,
        })
        logWarn(
          `Ошибка API ${error?.response?.statusText} ${error?.response?.status} ${error?.response?.data?.meta?.message}`,
          { ...error, reqId: error?.config?.headers?.['x-request-id'] }
        )
        return Promise.reject(error)
      }
    }
  )
}

const userApi = new UserApi({
  baseURL: `${BASE_URL_WITH_PORT}003/f2f/user/api/v1`,
})
withAuthAndErrors(userApi.instance)

const friendsApi = new FriendsApi({
  baseURL: `${BASE_URL_WITH_PORT}055/f2f/friends/api/v1`,
})

withAuthAndErrors(friendsApi.instance)

const chatApi = new ChatApi({
  baseURL: `${BASE_URL_WITH_PORT}066/f2f/chat/api/v1`,
})
withAuthAndErrors(chatApi.instance)

const notificationApi = new NotificationApi({
  baseURL: `${BASE_URL_WITH_PORT}007/f2f/notification/api/v1/`,
})
withAuthAndErrors(notificationApi.instance)

const lobbyApi = new LobbyApi({
  baseURL: `${BASE_URL_WITH_PORT}008/f2f/lobby/api/v1`,
})
withAuthAndErrors(lobbyApi.instance)

const matchApi = new MatchApi({
  baseURL: `${BASE_URL_WITH_PORT}012/f2f/match/api/v1/`,
})

withAuthAndErrors(matchApi.instance)

const statisticApi = new StatisticApi({
  baseURL: `${BASE_URL_WITH_PORT}013/f2f/statistic/api/v1/`,
})

withAuthAndErrors(statisticApi.instance)

const supportApi = new SupportApi({
  baseURL: `${BASE_URL_WITH_PORT}019/f2f/support/api/v1`,
})

withAuthAndErrors(supportApi.instance)

const gameController = new GameControllerApi({
  baseURL: `${BASE_URL_WITH_PORT}015/f2f/gamecontroller/api/v1/`,
})
withAuthAndErrors(gameController.instance)

const filestorageApi = new FilestorageApi({
  baseURL: `${BASE_URL_WITH_PORT}016/f2f/filestorage/api/v1`,
})

withAuthAndErrors(filestorageApi.instance)

const dictionaryApi = new DictionaryApi({
  baseURL: `${BASE_URL_WITH_PORT}021/f2f/dictionary/api/v1`,
})
withAuthAndErrors(dictionaryApi.instance)

const matchmakingApi = new MatchmakingApi({
  baseURL: `${BASE_URL_WITH_PORT}022/f2f/matchmaking/api/v1/`,
})
withAuthAndErrors(matchmakingApi.instance)

const socketApi = new SocketApi({
  baseURL: `${BASE_URL_WITH_PORT}011/api/v1/`,
})
withAuthAndErrors(socketApi.instance)

const walletApi = new WalletApi({
  baseURL: `${BASE_URL_WITH_PORT}004/f2f/wallet/api/v1/`,
})
withAuthAndErrors(walletApi.instance)

const judgeApi = new JudgeApi({
  baseURL: `${BASE_URL_WITH_PORT}023/f2f/judge/api/v1/`,
})
withAuthAndErrors(judgeApi.instance)

export const apiAuth = authApi

const api = {
  ...authApi,
  ...userApi,
  ...friendsApi,
  ...chatApi,
  ...notificationApi,
  ...lobbyApi,
  ...matchApi,
  ...statisticApi,
  ...gameController,
  ...supportApi,
  ...filestorageApi,
  ...dictionaryApi,
  ...socketApi,
  ...matchmakingApi,
  ...walletApi,
  judgeAPI: { ...judgeApi },
}

export default api
