import { Header5, Text3 } from 'styles/typography'
import { useTranslation } from 'react-i18next'
import BombWinIcon from 'images/BombWinIcon'
import ClearMineIcon from 'images/ClearMineIcon'
import MatchTimoutIcon from 'images/MatchTimoutIcon'
import RogerIcon from 'images/RogerIcon'
import SwitchIcon from 'images/SwitchIcon'
import WithTooltip from 'components/Tooltip/TableHeader/WithTooltip'
import matchBGBlue from 'images/history_match_back_blue.png'
import matchBGRed from 'images/history_match_back_red.png'
import styled from 'styled-components'

const getMatchBG = (teamColor: 'red' | 'blue') => {
  return teamColor === 'red' ? `'${matchBGRed}'` : `'${matchBGBlue}'`
}

interface Team {
  deaths: number
  length: number
  typeOfWin:
    | 'Elimination'
    | 'HostagesRescued'
    | 'HostageRescueFailed'
    | 'BombDefused'
    | 'BombExploded'
    | null
}

interface HistoryTimeLineProps {
  matchs: Match[]
  position: 'top' | 'bottom'
}

interface StyledRootProps {
  $position: 'top' | 'bottom'
}
const StyledRoot = styled.div<StyledRootProps>`
  display: flex;
  gap: 20px;

  align-items: center;
  justify-content: ${({ $position }) =>
    $position === 'top' ? 'end' : 'start'};

  flex-direction: ${({ $position }) =>
    $position === 'top' ? 'column' : 'column-reverse'};
`

interface StyledWinBarProps {
  $teamColor: 'red' | 'blue'
  $position: 'top' | 'bottom'
}

const StyledWinBar = styled.div<StyledWinBarProps>`
  background-image: url(${({ $teamColor }) => getMatchBG($teamColor)});
  background-repeat: no-repeat;
  width: 24px;
  height: 260px;
  align-items: center;
  display: flex;
  flex-direction: column-reverse;
  transform: rotate(
    ${({ $position }) => ($position === 'top' ? '0' : '180deg')}
  );
  gap: 10px;
  padding: 50px 0;
`

const StyledWinTypeIcon = styled.div`
  fill: white;
`

interface StyledRogerIconProps {
  $isDeath: boolean
  $position?: 'top' | 'bottom'
}

const StyledRogerIcon = styled(RogerIcon)<StyledRogerIconProps>`
  height: 25px;
  transform: rotate(
    ${({ $position }) => ($position === 'bottom' ? '180deg' : '0')}
  );
  fill: ${({ $isDeath }) => ($isDeath ? 'white' : 'rgba(255, 255, 255, 0.3)')};
`

interface StyledLoseItemProps {
  $position: 'top' | 'bottom'
}

const StyledLoseItem = styled(Header5)<StyledLoseItemProps>`
  display: flex;
  font-weight: normal;
  color: rgba(255, 255, 255, 0.3);
  align-items: center;
  flex-direction: ${({ $position }) =>
    $position === 'top' ? 'column' : 'column-reverse'};
`

interface StyledRoundItemProps {
  $position: 'top' | 'bottom'
}

const StyledRoundItem = styled(Text3)<StyledRoundItemProps>`
  font-weight: normal;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: ${({ $position }) => ($position === 'top' ? 'end' : 'start')};
  justify-content: center;
  line-height: 100%;
`

const StyledSwitchIcon = styled(SwitchIcon)`
  width: 100%;
  position: relative;
  fill: rgba(44, 255, 128, 1);
  /* &:after {
    content: '';
    width: 100%;
    height: 25px;
    position: absolute;
    border-radius: 10px;
    opacity: 0.7400000095367432;
    background: #2cff80;
    filter: blur(18px);
  } */
`

export interface Match {
  red: Team
  blue: Team
}

const HistoryTimeLine: React.FC<HistoryTimeLineProps> = ({
  matchs,
  position,
}) => {
  const { t } = useTranslation()

  const getTypeOfWinIcon = (
    type:
      | 'Elimination'
      | 'HostagesRescued'
      | 'HostageRescueFailed'
      | 'BombDefused'
      | 'BombExploded'
      | ''
      | null
  ) => {
    switch (type) {
      case 'BombExploded':
        return (
          <WithTooltip
            title={t('results.tooltips.planted.title')}
            text={t('results.tooltips.planted.description')}
          >
            <BombWinIcon />
          </WithTooltip>
        )
      case 'BombDefused':
        return (
          <WithTooltip
            title={t('results.tooltips.defuse.title')}
            text={t('results.tooltips.defuse.description')}
          >
            <ClearMineIcon />
          </WithTooltip>
        )
      case 'Elimination':
      case '':
        return <RogerIcon />
      default:
        return (
          <WithTooltip
            title={t('results.tooltips.timeisup.title')}
            text={t('results.tooltips.timeisup.description')}
          >
            <MatchTimoutIcon />
          </WithTooltip>
        )
    }
  }

  return matchs.map((match, index) => {
    let currentTeamName: 'red' | 'blue' = 'red'
    if (index > 14) {
      currentTeamName = position === 'top' ? 'red' : 'blue'
    } else {
      currentTeamName = position !== 'top' ? 'blue' : 'red'
    }

    const currentTeam = match[currentTeamName]

    return (
      <>
        {index % 5 === 0 &&
          index % 10 !== 0 &&
          (position === 'top' ? (
            <StyledRoundItem $position={position}>{index}</StyledRoundItem>
          ) : index === 15 ? (
            <StyledSwitchIcon />
          ) : (
            <div />
          ))}
        {index % 10 === 0 &&
          (position === 'bottom' ? (
            <StyledRoundItem $position={position}>
              {index === 0 ? index + 1 : index}
            </StyledRoundItem>
          ) : (
            <div />
          ))}
        <StyledRoot $position={position}>
          {currentTeam.typeOfWin || currentTeam.typeOfWin === '' ? (
            <>
              <StyledWinBar $position={position} $teamColor={currentTeamName}>
                {new Array(currentTeam.length).fill(1).map((_, index) => {
                  return (
                    <StyledRogerIcon
                      key={index}
                      $position={position}
                      $isDeath={index < currentTeam.deaths}
                    />
                  )
                })}
              </StyledWinBar>
              <StyledWinTypeIcon>
                {getTypeOfWinIcon(currentTeam.typeOfWin)}
              </StyledWinTypeIcon>
            </>
          ) : (
            <StyledLoseItem $position={position}>
              {currentTeam.deaths}
              <StyledRogerIcon $isDeath={false} />
            </StyledLoseItem>
          )}
        </StyledRoot>
        {position === 'bottom' && index === 29 && (
          <StyledRoundItem $position={position}>30</StyledRoundItem>
        )}
      </>
    )
  })
}

export default HistoryTimeLine
