import { Text4 } from 'styles/typography'
import GameFindedUserItem from 'components/GameFinded/GameFindedUserItem'
import React from 'react'
import styled from 'styled-components'

const StyledRoot = styled.div`
  display: flex;

  flex-direction: column;
  justify-content: end;
  height: 100%;
  gap: 12px;
`

const StyledList = styled.div`
  display: flex;
  gap: 4px;
`

const StyledDescription = styled(Text4)`
  color: white;
  width: 100%;
  text-align: center;
  font-weight: 500;
`

interface GameFindedUserListProps {
  maxPlayers: 2 | 10
  acceptedInviteCount: number
}

const GameFindedUserList: React.FC<GameFindedUserListProps> = ({
  maxPlayers,
  acceptedInviteCount,
}) => {
  return (
    <StyledRoot>
      <StyledDescription>{`Приняли ${acceptedInviteCount}/${maxPlayers}`}</StyledDescription>
      <StyledList>
        {Array(maxPlayers)
          .fill('')
          .map((_, index) => (
            <GameFindedUserItem
              key={index}
              isReady={index < acceptedInviteCount}
            />
          ))}
      </StyledList>
    </StyledRoot>
  )
}

export default GameFindedUserList
