import styled, { keyframes } from 'styled-components'

const slideUp = keyframes`
    from{
      top: 26px;
    }

    to {
      top:-264px;
    }
`

const slideDown = keyframes`
    from{
      bottom: 26px;
    }

    to {
      bottom: -264px;
    }
`

const StyledWrapper = styled.div`
  width: 279px;
  height: 134px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
`

const StyledLeftLetters = styled.div`
  position: absolute;

  animation-timing-function: linear;
  animation-duration: 10s;
  animation-name: ${slideUp};
  animation-iteration-count: infinite;
  animation-delay: 0;
`

const StyledRightLetters = styled.div`
  position: absolute;

  animation-timing-function: linear;
  animation-duration: 10s;
  animation-name: ${slideDown};
  animation-iteration-count: infinite;
  animation-delay: 0;
`

const StyledMask = styled.div`
  -webkit-mask-image: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 12.69%,
    #fff 17.51%,
    #fff 84.37%,
    rgba(255, 255, 255, 0) 88.58%
  );
  height: 172px;
  width: 5px;
  position: absolute;
  left: 12px;
  top: -21px;
`

const StyledMaskR = styled.div`
  -webkit-mask-image: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 12.69%,
    #fff 17.51%,
    #fff 84.37%,
    rgba(255, 255, 255, 0) 88.58%
  );
  height: 172px;
  width: 5px;
  position: absolute;
  right: 12px;
  top: -21px;
`

const BigLogo = () => (
  <StyledWrapper>
    <StyledMask>
      <StyledLeftLetters>
        <svg
          width="4"
          fill="none"
          height="597"
          viewBox="0 0 4 597"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill="#2CFF80"
            d="M0.857143 548.961H0V547.83L0.857143 546.973H4V547.83H1.34097C1.27532 547.83 1.21258 547.857 1.16759 547.905L0.921857 548.166C0.880289 548.21 0.857143 548.269 0.857143 548.329V548.961Z"
          />
          <path
            fill="#2CFF80"
            d="M0.857143 546.985H0V545.854L0.857143 544.996H4V545.854H1.34097C1.27532 545.854 1.21258 545.881 1.16759 545.928L0.921857 546.19C0.880289 546.234 0.857143 546.292 0.857143 546.353V546.985Z"
          />
          <path
            fill="#2CFF80"
            d="M0.857143 258.961H0V257.83L0.857143 256.973H4V257.83H1.34097C1.27532 257.83 1.21258 257.857 1.16759 257.905L0.921857 258.166C0.880289 258.21 0.857143 258.269 0.857143 258.329V258.961Z"
          />
          <path
            fill="#2CFF80"
            d="M0.857143 256.985H0V255.854L0.857143 254.996H4V255.854H1.34097C1.27532 255.854 1.21258 255.881 1.16759 255.928L0.921857 256.19C0.880289 256.234 0.857143 256.292 0.857143 256.353V256.985Z"
          />
          <path
            fill="#2CFF80"
            d="M0.857143 140.963H0V139.832L0.857143 138.975H4V139.832H1.34097C1.27532 139.832 1.21258 139.859 1.16759 139.907L0.921857 140.168C0.880289 140.212 0.857143 140.27 0.857143 140.331V140.963Z"
          />
          <path
            fill="#2CFF80"
            d="M0.857143 138.986H0V137.855L0.857143 136.998H4V137.855H1.34097C1.27532 137.855 1.21258 137.882 1.16759 137.93L0.921857 138.191C0.880289 138.235 0.857143 138.294 0.857143 138.354V138.986Z"
          />
          <path
            fill="#2CFF80"
            d="M0.857143 65.9638H0V64.8328L0.857143 63.9757H4V64.8328H1.34097C1.27532 64.8328 1.21258 64.8599 1.16759 64.9077L0.921857 65.1688C0.880289 65.213 0.857143 65.2713 0.857143 65.332V65.9638Z"
          />
          <path
            fill="#2CFF80"
            d="M0.857143 63.9872H0V62.8563L0.857143 61.9991H4V62.8563H1.34097C1.27532 62.8563 1.21258 62.8834 1.16759 62.9312L0.921857 63.1923C0.880289 63.2365 0.857143 63.2948 0.857143 63.3555V63.9872Z"
          />
          <path
            fill="#2CFF80"
            d="M0.857143 16.9645H0V15.8335L0.857143 14.9764H4V15.8335H1.34097C1.27532 15.8335 1.21258 15.8606 1.16759 15.9084L0.921857 16.1695C0.880289 16.2137 0.857143 16.2721 0.857143 16.3327V16.9645Z"
          />
          <path
            fill="#2CFF80"
            d="M0.857143 14.9879H0V13.857L0.857143 12.9998H4V13.857H1.34097C1.27532 13.857 1.21258 13.8841 1.16759 13.9319L0.921857 14.193C0.880289 14.2372 0.857143 14.2955 0.857143 14.3562V14.9879Z"
          />
          <path
            fill="#2CFF80"
            d="M0.857143 3.9646H0V2.83364L0.857143 1.9765H4V2.83364H1.34097C1.27532 2.83364 1.21258 2.86075 1.16759 2.90856L0.921857 3.16965C0.880289 3.21381 0.857143 3.27218 0.857143 3.33283V3.9646Z"
          />
          <path
            fill="#2CFF80"
            d="M0.857143 1.98806H0V0.857112L0.857143 -3.05176e-05H4V0.857112H1.34097C1.27532 0.857112 1.21258 0.884219 1.16759 0.932025L0.921857 1.19311C0.880289 1.23728 0.857143 1.29565 0.857143 1.3563V1.98806Z"
          />
          <path
            fill="#2CFF80"
            d="M0.857143 222.962H0V221.831L0.857143 220.973H4V221.831H1.34097C1.27532 221.831 1.21258 221.858 1.16759 221.906L0.921857 222.167C0.880289 222.211 0.857143 222.269 0.857143 222.33V222.962Z"
          />
          <path
            fill="#2CFF80"
            d="M0.857143 220.985H0V219.854L0.857143 218.997H4V219.854H1.34097C1.27532 219.854 1.21258 219.881 1.16759 219.929L0.921857 220.19C0.880289 220.234 0.857143 220.293 0.857143 220.353V220.985Z"
          />
          <path
            fill="#2CFF80"
            d="M0.857143 93.9634H0V92.8324L0.857143 91.9753H4V92.8324H1.34097C1.27532 92.8324 1.21258 92.8595 1.16759 92.9073L0.921857 93.1684C0.880289 93.2126 0.857143 93.2709 0.857143 93.3316V93.9634Z"
          />
          <path
            fill="#2CFF80"
            d="M0.857143 91.9868H0V90.8559L0.857143 89.9987H4V90.8559H1.34097C1.27532 90.8559 1.21258 90.883 1.16759 90.9308L0.921857 91.1919C0.880289 91.2361 0.857143 91.2944 0.857143 91.3551V91.9868Z"
          />
          <path
            fill="#2CFF80"
            d="M0.857143 122.963H0V121.832L0.857143 120.975H4V121.832H1.34097C1.27532 121.832 1.21258 121.859 1.16759 121.907L0.921857 122.168C0.880289 122.212 0.857143 122.271 0.857143 122.331V122.963Z"
          />
          <path
            fill="#2CFF80"
            d="M0.857143 120.986H0V119.855L0.857143 118.998H4V119.855H1.34097C1.27532 119.855 1.21258 119.883 1.16759 119.93L0.921857 120.191C0.880289 120.236 0.857143 120.294 0.857143 120.355V120.986Z"
          />
          <path
            fill="#2CFF80"
            d="M0.857143 83.9635H0V82.8325L0.857143 81.9754H4V82.8325H1.34097C1.27532 82.8325 1.21258 82.8596 1.16759 82.9075L0.921857 83.1685C0.880289 83.2127 0.857143 83.2711 0.857143 83.3317V83.9635Z"
          />
          <path
            fill="#2CFF80"
            d="M0.857143 81.987H0V80.856L0.857143 79.9989H4V80.856H1.34097C1.27532 80.856 1.21258 80.8831 1.16759 80.9309L0.921857 81.192C0.880289 81.2362 0.857143 81.2945 0.857143 81.3552V81.987Z"
          />
          <path
            fill="#2CFF80"
            d="M0.857143 293.961H0V292.83L0.857143 291.972H4V292.83H1.34097C1.27532 292.83 1.21258 292.857 1.16759 292.905L0.921857 293.166C0.880289 293.21 0.857143 293.268 0.857143 293.329V293.961Z"
          />
          <path
            fill="#2CFF80"
            d="M0.857143 291.984H0V290.853L0.857143 289.996H4V290.853H1.34097C1.27532 290.853 1.21258 290.88 1.16759 290.928L0.921857 291.189C0.880289 291.233 0.857143 291.292 0.857143 291.352V291.984Z"
          />
          <path
            fill="#2CFF80"
            d="M0.857143 306.96H0V305.829L0.857143 304.972H4V305.829H1.34097C1.27532 305.829 1.21258 305.857 1.16759 305.904L0.921857 306.165C0.880289 306.21 0.857143 306.268 0.857143 306.329V306.96Z"
          />
          <path
            fill="#2CFF80"
            d="M0.857143 304.984H0V303.853L0.857143 302.996H4V303.853H1.34097C1.27532 303.853 1.21258 303.88 1.16759 303.928L0.921857 304.189C0.880289 304.233 0.857143 304.291 0.857143 304.352V304.984Z"
          />
          <path
            fill="#2CFF80"
            d="M0.857143 250.961H0V249.83L0.857143 248.973H4V249.83H1.34097C1.27532 249.83 1.21258 249.857 1.16759 249.905L0.921857 250.166C0.880289 250.21 0.857143 250.269 0.857143 250.329V250.961Z"
          />
          <path
            fill="#2CFF80"
            d="M0.857143 248.985H0V247.854L0.857143 246.997H4V247.854H1.34097C1.27532 247.854 1.21258 247.881 1.16759 247.929L0.921857 248.19C0.880289 248.234 0.857143 248.292 0.857143 248.353V248.985Z"
          />
          <path
            fill="#2CFF80"
            d="M0.857143 232.961H0V231.83L0.857143 230.973H4V231.83H1.34097C1.27532 231.83 1.21258 231.858 1.16759 231.905L0.921857 232.166C0.880289 232.211 0.857143 232.269 0.857143 232.33V232.961Z"
          />
          <path
            fill="#2CFF80"
            d="M0.857143 230.985H0V229.854L0.857143 228.997H4V229.854H1.34097C1.27532 229.854 1.21258 229.881 1.16759 229.929L0.921857 230.19C0.880289 230.234 0.857143 230.292 0.857143 230.353V230.985Z"
          />
          <path
            fill="#2CFF80"
            d="M0.857143 430.963H0V429.832L0.857143 428.975H4V429.832H1.34097C1.27532 429.832 1.21258 429.859 1.16759 429.907L0.921857 430.168C0.880289 430.212 0.857143 430.27 0.857143 430.331V430.963Z"
          />
          <path
            fill="#2CFF80"
            d="M0.857143 428.986H0V427.855L0.857143 426.998H4V427.855H1.34097C1.27532 427.855 1.21258 427.882 1.16759 427.93L0.921857 428.191C0.880289 428.235 0.857143 428.294 0.857143 428.354V428.986Z"
          />
          <path
            fill="#2CFF80"
            d="M0.857143 355.964H0V354.833L0.857143 353.976H4V354.833H1.34097C1.27532 354.833 1.21258 354.86 1.16759 354.908L0.921857 355.169C0.880289 355.213 0.857143 355.271 0.857143 355.332V355.964Z"
          />
          <path
            fill="#2CFF80"
            d="M0.857143 353.987H0V352.856L0.857143 351.999H4V352.856H1.34097C1.27532 352.856 1.21258 352.883 1.16759 352.931L0.921857 353.192C0.880289 353.236 0.857143 353.295 0.857143 353.355V353.987Z"
          />
          <path
            fill="#2CFF80"
            d="M0.857143 512.962H0V511.831L0.857143 510.973H4V511.831H1.34097C1.27532 511.831 1.21258 511.858 1.16759 511.906L0.921857 512.167C0.880289 512.211 0.857143 512.269 0.857143 512.33V512.962Z"
          />
          <path
            fill="#2CFF80"
            d="M0.857143 510.985H0V509.854L0.857143 508.997H4V509.854H1.34097C1.27532 509.854 1.21258 509.881 1.16759 509.929L0.921857 510.19C0.880289 510.234 0.857143 510.293 0.857143 510.353V510.985Z"
          />
          <path
            fill="#2CFF80"
            d="M0.857143 383.963H0V382.832L0.857143 381.975H4V382.832H1.34097C1.27532 382.832 1.21258 382.86 1.16759 382.907L0.921857 383.168C0.880289 383.213 0.857143 383.271 0.857143 383.332V383.963Z"
          />
          <path
            fill="#2CFF80"
            d="M0.857143 381.987H0V380.856L0.857143 379.999H4V380.856H1.34097C1.27532 380.856 1.21258 380.883 1.16759 380.931L0.921857 381.192C0.880289 381.236 0.857143 381.294 0.857143 381.355V381.987Z"
          />
          <path
            fill="#2CFF80"
            d="M0.857143 412.963H0V411.832L0.857143 410.975H4V411.832H1.34097C1.27532 411.832 1.21258 411.859 1.16759 411.907L0.921857 412.168C0.880289 412.212 0.857143 412.271 0.857143 412.331V412.963Z"
          />
          <path
            fill="#2CFF80"
            d="M0.857143 410.986H0V409.855L0.857143 408.998H4V409.855H1.34097C1.27532 409.855 1.21258 409.883 1.16759 409.93L0.921857 410.191C0.880289 410.236 0.857143 410.294 0.857143 410.355V410.986Z"
          />
          <path
            fill="#2CFF80"
            d="M0.857143 373.963H0V372.833L0.857143 371.975H4V372.833H1.34097C1.27532 372.833 1.21258 372.86 1.16759 372.907L0.921857 373.169C0.880289 373.213 0.857143 373.271 0.857143 373.332V373.963Z"
          />
          <path
            fill="#2CFF80"
            d="M0.857143 371.987H0V370.856L0.857143 369.999H4V370.856H1.34097C1.27532 370.856 1.21258 370.883 1.16759 370.931L0.921857 371.192C0.880289 371.236 0.857143 371.295 0.857143 371.355V371.987Z"
          />
          <path
            fill="#2CFF80"
            d="M0.857143 583.961H0V582.83L0.857143 581.972H4V582.83H1.34097C1.27532 582.83 1.21258 582.857 1.16759 582.905L0.921857 583.166C0.880289 583.21 0.857143 583.268 0.857143 583.329V583.961Z"
          />
          <path
            fill="#2CFF80"
            d="M0.857143 581.984H0V580.853L0.857143 579.996H4V580.853H1.34097C1.27532 580.853 1.21258 580.88 1.16759 580.928L0.921857 581.189C0.880289 581.233 0.857143 581.292 0.857143 581.352V581.984Z"
          />
          <path
            fill="#2CFF80"
            d="M0.857143 596.96H0V595.829L0.857143 594.972H4V595.829H1.34097C1.27532 595.829 1.21258 595.857 1.16759 595.904L0.921857 596.165C0.880289 596.21 0.857143 596.268 0.857143 596.329V596.96Z"
          />
          <path
            fill="#2CFF80"
            d="M0.857143 594.984H0V593.853L0.857143 592.996H4V593.853H1.34097C1.27532 593.853 1.21258 593.88 1.16759 593.928L0.921857 594.189C0.880289 594.233 0.857143 594.291 0.857143 594.352V594.984Z"
          />
          <path
            fill="#2CFF80"
            d="M0.857143 540.961H0V539.83L0.857143 538.973H4V539.83H1.34097C1.27532 539.83 1.21258 539.857 1.16759 539.905L0.921857 540.166C0.880289 540.21 0.857143 540.269 0.857143 540.329V540.961Z"
          />
          <path
            fill="#2CFF80"
            d="M0.857143 538.985H0V537.854L0.857143 536.997H4V537.854H1.34097C1.27532 537.854 1.21258 537.881 1.16759 537.929L0.921857 538.19C0.880289 538.234 0.857143 538.292 0.857143 538.353V538.985Z"
          />
          <path
            fill="#2CFF80"
            d="M0.857143 522.961H0V521.83L0.857143 520.973H4V521.83H1.34097C1.27532 521.83 1.21258 521.858 1.16759 521.905L0.921857 522.166C0.880289 522.211 0.857143 522.269 0.857143 522.33V522.961Z"
          />
          <path
            fill="#2CFF80"
            d="M0.857143 520.985H0V519.854L0.857143 518.997H4V519.854H1.34097C1.27532 519.854 1.21258 519.881 1.16759 519.929L0.921857 520.19C0.880289 520.234 0.857143 520.292 0.857143 520.353V520.985Z"
          />
        </svg>
      </StyledLeftLetters>
    </StyledMask>

    <svg
      fill="none"
      width="138"
      height="137"
      viewBox="0 0 138 137"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        strokeWidth="2"
        stroke="#2CFF80"
        d="M29.7143 134H1V96.4551L29.7143 68H135V96.4551H45.9041C43.7155 96.4551 41.6228 97.3537 40.1155 98.9407L31.909 107.581C30.4999 109.065 29.7143 111.033 29.7143 113.079V134Z"
      />
      <path
        fill="#2CFF80"
        stroke="#2CFF80"
        d="M29.7398 67.6604H0.5V29.7398L29.7398 1H135.5V29.7398H45.9624C43.7612 29.7398 41.6576 30.6487 40.149 32.2516L31.9097 41.0059C30.5159 42.4868 29.7398 44.4438 29.7398 46.4774V67.6604Z"
      />
      <g filter="url(#filter0_d_7115_98337)">
        <path
          fill="#2CFF80"
          d="M108.616 118.414L108.607 123.824L132.604 123.864C132.649 123.864 132.686 123.886 132.716 123.931C132.76 123.961 132.782 124.005 132.782 124.065L132.776 127.493C132.776 127.537 132.754 127.582 132.709 127.626C132.68 127.656 132.643 127.671 132.598 127.671L108.601 127.63L108.589 134.754C108.589 134.813 108.566 134.865 108.522 134.909C108.492 134.939 108.455 134.954 108.411 134.954L104.337 134.947C104.277 134.947 104.225 134.932 104.181 134.902C104.151 134.858 104.137 134.806 104.137 134.746L104.164 118.407C104.167 116.834 104.726 115.492 105.841 114.38C106.97 113.254 108.329 112.693 109.917 112.695L135.784 112.739C135.844 112.739 135.888 112.761 135.918 112.806C135.962 112.836 135.984 112.88 135.984 112.94L135.978 116.368C135.978 116.412 135.956 116.457 135.911 116.501C135.882 116.531 135.837 116.546 135.778 116.545L110.534 116.503C110.015 116.502 109.562 116.694 109.175 117.08C108.804 117.45 108.617 117.895 108.616 118.414Z"
        />
      </g>
      <g filter="url(#filter1_d_7115_98337)">
        <path
          fill="#2CFF80"
          d="M93.011 118.41C93.0119 117.876 92.8197 117.423 92.4345 117.051C92.0641 116.68 91.6192 116.494 91.0998 116.493L67.1249 116.452C67.0655 116.452 67.0136 116.437 66.9691 116.408C66.9395 116.363 66.9248 116.311 66.9249 116.252L66.9306 112.824C66.9307 112.764 66.9457 112.72 66.9754 112.69C67.02 112.646 67.072 112.623 67.1313 112.624L91.1062 112.664C92.8574 112.667 94.3553 113.293 95.5998 114.541C96.8443 115.79 97.4651 117.29 97.4621 119.041L97.4616 119.353C97.4586 121.119 96.8328 122.624 95.5841 123.869C94.3354 125.098 92.8355 125.712 91.0843 125.709L72.0068 125.677C71.4725 125.676 71.0196 125.86 70.6479 126.231C70.2763 126.601 70.09 127.054 70.0891 127.588L70.0833 131.083L95.9726 131.126C96.0171 131.126 96.0542 131.149 96.0838 131.193C96.1283 131.223 96.1505 131.268 96.1504 131.327L96.1446 134.755C96.1445 134.8 96.1222 134.844 96.0776 134.889C96.0479 134.918 96.0107 134.933 95.9662 134.933L65.8251 134.882C65.7657 134.882 65.7138 134.867 65.6693 134.837C65.6397 134.793 65.6249 134.748 65.625 134.704L65.6359 128.204C65.6389 126.452 66.2573 124.962 67.4911 123.732C68.7398 122.488 70.2472 121.867 72.0132 121.87L91.0907 121.902C91.6101 121.903 92.0557 121.718 92.4273 121.348C92.8138 120.963 93.0075 120.51 93.0084 119.991L93.011 118.41Z"
        />
      </g>
      <defs>
        <filter
          y="111.234"
          x="102.383"
          width="35.3546"
          height="25.7655"
          id="filter0_d_7115_98337"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            type="matrix"
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="0.292245" />
          <feGaussianBlur stdDeviation="0.876735" />
          <feComposite operator="out" in2="hardAlpha" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.170833 0 0 0 0 1 0 0 0 0 0.5025 0 0 0 0.4 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_7115_98337"
          />
          <feBlend
            mode="normal"
            result="shape"
            in="SourceGraphic"
            in2="effect1_dropShadow_7115_98337"
          />
        </filter>
        <filter
          y="111.162"
          x="63.8715"
          width="35.3448"
          height="25.8163"
          id="filter1_d_7115_98337"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            type="matrix"
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="0.292245" />
          <feGaussianBlur stdDeviation="0.876735" />
          <feComposite operator="out" in2="hardAlpha" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.170833 0 0 0 0 1 0 0 0 0 0.5025 0 0 0 0.4 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_7115_98337"
          />
          <feBlend
            mode="normal"
            result="shape"
            in="SourceGraphic"
            in2="effect1_dropShadow_7115_98337"
          />
        </filter>
      </defs>
    </svg>
    <StyledMaskR>
      <StyledRightLetters>
        <svg
          width="4"
          fill="none"
          height="597"
          viewBox="0 0 4 597"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill="#2CFF80"
            d="M0.857143 548.961H0V547.83L0.857143 546.973H4V547.83H1.34097C1.27532 547.83 1.21258 547.857 1.16759 547.905L0.921857 548.166C0.880289 548.21 0.857143 548.269 0.857143 548.329V548.961Z"
          />
          <path
            fill="#2CFF80"
            d="M0.857143 546.985H0V545.854L0.857143 544.996H4V545.854H1.34097C1.27532 545.854 1.21258 545.881 1.16759 545.928L0.921857 546.19C0.880289 546.234 0.857143 546.292 0.857143 546.353V546.985Z"
          />
          <path
            fill="#2CFF80"
            d="M0.857143 258.961H0V257.83L0.857143 256.973H4V257.83H1.34097C1.27532 257.83 1.21258 257.857 1.16759 257.905L0.921857 258.166C0.880289 258.21 0.857143 258.269 0.857143 258.329V258.961Z"
          />
          <path
            fill="#2CFF80"
            d="M0.857143 256.985H0V255.854L0.857143 254.996H4V255.854H1.34097C1.27532 255.854 1.21258 255.881 1.16759 255.928L0.921857 256.19C0.880289 256.234 0.857143 256.292 0.857143 256.353V256.985Z"
          />
          <path
            fill="#2CFF80"
            d="M0.857143 140.963H0V139.832L0.857143 138.975H4V139.832H1.34097C1.27532 139.832 1.21258 139.859 1.16759 139.907L0.921857 140.168C0.880289 140.212 0.857143 140.27 0.857143 140.331V140.963Z"
          />
          <path
            fill="#2CFF80"
            d="M0.857143 138.986H0V137.855L0.857143 136.998H4V137.855H1.34097C1.27532 137.855 1.21258 137.882 1.16759 137.93L0.921857 138.191C0.880289 138.235 0.857143 138.294 0.857143 138.354V138.986Z"
          />
          <path
            fill="#2CFF80"
            d="M0.857143 65.9638H0V64.8328L0.857143 63.9757H4V64.8328H1.34097C1.27532 64.8328 1.21258 64.8599 1.16759 64.9077L0.921857 65.1688C0.880289 65.213 0.857143 65.2713 0.857143 65.332V65.9638Z"
          />
          <path
            fill="#2CFF80"
            d="M0.857143 63.9872H0V62.8563L0.857143 61.9991H4V62.8563H1.34097C1.27532 62.8563 1.21258 62.8834 1.16759 62.9312L0.921857 63.1923C0.880289 63.2365 0.857143 63.2948 0.857143 63.3555V63.9872Z"
          />
          <path
            fill="#2CFF80"
            d="M0.857143 16.9645H0V15.8335L0.857143 14.9764H4V15.8335H1.34097C1.27532 15.8335 1.21258 15.8606 1.16759 15.9084L0.921857 16.1695C0.880289 16.2137 0.857143 16.2721 0.857143 16.3327V16.9645Z"
          />
          <path
            fill="#2CFF80"
            d="M0.857143 14.9879H0V13.857L0.857143 12.9998H4V13.857H1.34097C1.27532 13.857 1.21258 13.8841 1.16759 13.9319L0.921857 14.193C0.880289 14.2372 0.857143 14.2955 0.857143 14.3562V14.9879Z"
          />
          <path
            fill="#2CFF80"
            d="M0.857143 3.9646H0V2.83364L0.857143 1.9765H4V2.83364H1.34097C1.27532 2.83364 1.21258 2.86075 1.16759 2.90856L0.921857 3.16965C0.880289 3.21381 0.857143 3.27218 0.857143 3.33283V3.9646Z"
          />
          <path
            fill="#2CFF80"
            d="M0.857143 1.98806H0V0.857112L0.857143 -3.05176e-05H4V0.857112H1.34097C1.27532 0.857112 1.21258 0.884219 1.16759 0.932025L0.921857 1.19311C0.880289 1.23728 0.857143 1.29565 0.857143 1.3563V1.98806Z"
          />
          <path
            fill="#2CFF80"
            d="M0.857143 222.962H0V221.831L0.857143 220.973H4V221.831H1.34097C1.27532 221.831 1.21258 221.858 1.16759 221.906L0.921857 222.167C0.880289 222.211 0.857143 222.269 0.857143 222.33V222.962Z"
          />
          <path
            fill="#2CFF80"
            d="M0.857143 220.985H0V219.854L0.857143 218.997H4V219.854H1.34097C1.27532 219.854 1.21258 219.881 1.16759 219.929L0.921857 220.19C0.880289 220.234 0.857143 220.293 0.857143 220.353V220.985Z"
          />
          <path
            fill="#2CFF80"
            d="M0.857143 93.9634H0V92.8324L0.857143 91.9753H4V92.8324H1.34097C1.27532 92.8324 1.21258 92.8595 1.16759 92.9073L0.921857 93.1684C0.880289 93.2126 0.857143 93.2709 0.857143 93.3316V93.9634Z"
          />
          <path
            fill="#2CFF80"
            d="M0.857143 91.9868H0V90.8559L0.857143 89.9987H4V90.8559H1.34097C1.27532 90.8559 1.21258 90.883 1.16759 90.9308L0.921857 91.1919C0.880289 91.2361 0.857143 91.2944 0.857143 91.3551V91.9868Z"
          />
          <path
            fill="#2CFF80"
            d="M0.857143 122.963H0V121.832L0.857143 120.975H4V121.832H1.34097C1.27532 121.832 1.21258 121.859 1.16759 121.907L0.921857 122.168C0.880289 122.212 0.857143 122.271 0.857143 122.331V122.963Z"
          />
          <path
            fill="#2CFF80"
            d="M0.857143 120.986H0V119.855L0.857143 118.998H4V119.855H1.34097C1.27532 119.855 1.21258 119.883 1.16759 119.93L0.921857 120.191C0.880289 120.236 0.857143 120.294 0.857143 120.355V120.986Z"
          />
          <path
            fill="#2CFF80"
            d="M0.857143 83.9635H0V82.8325L0.857143 81.9754H4V82.8325H1.34097C1.27532 82.8325 1.21258 82.8596 1.16759 82.9075L0.921857 83.1685C0.880289 83.2127 0.857143 83.2711 0.857143 83.3317V83.9635Z"
          />
          <path
            fill="#2CFF80"
            d="M0.857143 81.987H0V80.856L0.857143 79.9989H4V80.856H1.34097C1.27532 80.856 1.21258 80.8831 1.16759 80.9309L0.921857 81.192C0.880289 81.2362 0.857143 81.2945 0.857143 81.3552V81.987Z"
          />
          <path
            fill="#2CFF80"
            d="M0.857143 293.961H0V292.83L0.857143 291.972H4V292.83H1.34097C1.27532 292.83 1.21258 292.857 1.16759 292.905L0.921857 293.166C0.880289 293.21 0.857143 293.268 0.857143 293.329V293.961Z"
          />
          <path
            fill="#2CFF80"
            d="M0.857143 291.984H0V290.853L0.857143 289.996H4V290.853H1.34097C1.27532 290.853 1.21258 290.88 1.16759 290.928L0.921857 291.189C0.880289 291.233 0.857143 291.292 0.857143 291.352V291.984Z"
          />
          <path
            fill="#2CFF80"
            d="M0.857143 306.96H0V305.829L0.857143 304.972H4V305.829H1.34097C1.27532 305.829 1.21258 305.857 1.16759 305.904L0.921857 306.165C0.880289 306.21 0.857143 306.268 0.857143 306.329V306.96Z"
          />
          <path
            fill="#2CFF80"
            d="M0.857143 304.984H0V303.853L0.857143 302.996H4V303.853H1.34097C1.27532 303.853 1.21258 303.88 1.16759 303.928L0.921857 304.189C0.880289 304.233 0.857143 304.291 0.857143 304.352V304.984Z"
          />
          <path
            fill="#2CFF80"
            d="M0.857143 250.961H0V249.83L0.857143 248.973H4V249.83H1.34097C1.27532 249.83 1.21258 249.857 1.16759 249.905L0.921857 250.166C0.880289 250.21 0.857143 250.269 0.857143 250.329V250.961Z"
          />
          <path
            fill="#2CFF80"
            d="M0.857143 248.985H0V247.854L0.857143 246.997H4V247.854H1.34097C1.27532 247.854 1.21258 247.881 1.16759 247.929L0.921857 248.19C0.880289 248.234 0.857143 248.292 0.857143 248.353V248.985Z"
          />
          <path
            fill="#2CFF80"
            d="M0.857143 232.961H0V231.83L0.857143 230.973H4V231.83H1.34097C1.27532 231.83 1.21258 231.858 1.16759 231.905L0.921857 232.166C0.880289 232.211 0.857143 232.269 0.857143 232.33V232.961Z"
          />
          <path
            fill="#2CFF80"
            d="M0.857143 230.985H0V229.854L0.857143 228.997H4V229.854H1.34097C1.27532 229.854 1.21258 229.881 1.16759 229.929L0.921857 230.19C0.880289 230.234 0.857143 230.292 0.857143 230.353V230.985Z"
          />
          <path
            fill="#2CFF80"
            d="M0.857143 430.963H0V429.832L0.857143 428.975H4V429.832H1.34097C1.27532 429.832 1.21258 429.859 1.16759 429.907L0.921857 430.168C0.880289 430.212 0.857143 430.27 0.857143 430.331V430.963Z"
          />
          <path
            fill="#2CFF80"
            d="M0.857143 428.986H0V427.855L0.857143 426.998H4V427.855H1.34097C1.27532 427.855 1.21258 427.882 1.16759 427.93L0.921857 428.191C0.880289 428.235 0.857143 428.294 0.857143 428.354V428.986Z"
          />
          <path
            fill="#2CFF80"
            d="M0.857143 355.964H0V354.833L0.857143 353.976H4V354.833H1.34097C1.27532 354.833 1.21258 354.86 1.16759 354.908L0.921857 355.169C0.880289 355.213 0.857143 355.271 0.857143 355.332V355.964Z"
          />
          <path
            fill="#2CFF80"
            d="M0.857143 353.987H0V352.856L0.857143 351.999H4V352.856H1.34097C1.27532 352.856 1.21258 352.883 1.16759 352.931L0.921857 353.192C0.880289 353.236 0.857143 353.295 0.857143 353.355V353.987Z"
          />
          <path
            fill="#2CFF80"
            d="M0.857143 512.962H0V511.831L0.857143 510.973H4V511.831H1.34097C1.27532 511.831 1.21258 511.858 1.16759 511.906L0.921857 512.167C0.880289 512.211 0.857143 512.269 0.857143 512.33V512.962Z"
          />
          <path
            fill="#2CFF80"
            d="M0.857143 510.985H0V509.854L0.857143 508.997H4V509.854H1.34097C1.27532 509.854 1.21258 509.881 1.16759 509.929L0.921857 510.19C0.880289 510.234 0.857143 510.293 0.857143 510.353V510.985Z"
          />
          <path
            fill="#2CFF80"
            d="M0.857143 383.963H0V382.832L0.857143 381.975H4V382.832H1.34097C1.27532 382.832 1.21258 382.86 1.16759 382.907L0.921857 383.168C0.880289 383.213 0.857143 383.271 0.857143 383.332V383.963Z"
          />
          <path
            fill="#2CFF80"
            d="M0.857143 381.987H0V380.856L0.857143 379.999H4V380.856H1.34097C1.27532 380.856 1.21258 380.883 1.16759 380.931L0.921857 381.192C0.880289 381.236 0.857143 381.294 0.857143 381.355V381.987Z"
          />
          <path
            fill="#2CFF80"
            d="M0.857143 412.963H0V411.832L0.857143 410.975H4V411.832H1.34097C1.27532 411.832 1.21258 411.859 1.16759 411.907L0.921857 412.168C0.880289 412.212 0.857143 412.271 0.857143 412.331V412.963Z"
          />
          <path
            fill="#2CFF80"
            d="M0.857143 410.986H0V409.855L0.857143 408.998H4V409.855H1.34097C1.27532 409.855 1.21258 409.883 1.16759 409.93L0.921857 410.191C0.880289 410.236 0.857143 410.294 0.857143 410.355V410.986Z"
          />
          <path
            fill="#2CFF80"
            d="M0.857143 373.963H0V372.833L0.857143 371.975H4V372.833H1.34097C1.27532 372.833 1.21258 372.86 1.16759 372.907L0.921857 373.169C0.880289 373.213 0.857143 373.271 0.857143 373.332V373.963Z"
          />
          <path
            fill="#2CFF80"
            d="M0.857143 371.987H0V370.856L0.857143 369.999H4V370.856H1.34097C1.27532 370.856 1.21258 370.883 1.16759 370.931L0.921857 371.192C0.880289 371.236 0.857143 371.295 0.857143 371.355V371.987Z"
          />
          <path
            fill="#2CFF80"
            d="M0.857143 583.961H0V582.83L0.857143 581.972H4V582.83H1.34097C1.27532 582.83 1.21258 582.857 1.16759 582.905L0.921857 583.166C0.880289 583.21 0.857143 583.268 0.857143 583.329V583.961Z"
          />
          <path
            fill="#2CFF80"
            d="M0.857143 581.984H0V580.853L0.857143 579.996H4V580.853H1.34097C1.27532 580.853 1.21258 580.88 1.16759 580.928L0.921857 581.189C0.880289 581.233 0.857143 581.292 0.857143 581.352V581.984Z"
          />
          <path
            fill="#2CFF80"
            d="M0.857143 596.96H0V595.829L0.857143 594.972H4V595.829H1.34097C1.27532 595.829 1.21258 595.857 1.16759 595.904L0.921857 596.165C0.880289 596.21 0.857143 596.268 0.857143 596.329V596.96Z"
          />
          <path
            fill="#2CFF80"
            d="M0.857143 594.984H0V593.853L0.857143 592.996H4V593.853H1.34097C1.27532 593.853 1.21258 593.88 1.16759 593.928L0.921857 594.189C0.880289 594.233 0.857143 594.291 0.857143 594.352V594.984Z"
          />
          <path
            fill="#2CFF80"
            d="M0.857143 540.961H0V539.83L0.857143 538.973H4V539.83H1.34097C1.27532 539.83 1.21258 539.857 1.16759 539.905L0.921857 540.166C0.880289 540.21 0.857143 540.269 0.857143 540.329V540.961Z"
          />
          <path
            fill="#2CFF80"
            d="M0.857143 538.985H0V537.854L0.857143 536.997H4V537.854H1.34097C1.27532 537.854 1.21258 537.881 1.16759 537.929L0.921857 538.19C0.880289 538.234 0.857143 538.292 0.857143 538.353V538.985Z"
          />
          <path
            fill="#2CFF80"
            d="M0.857143 522.961H0V521.83L0.857143 520.973H4V521.83H1.34097C1.27532 521.83 1.21258 521.858 1.16759 521.905L0.921857 522.166C0.880289 522.211 0.857143 522.269 0.857143 522.33V522.961Z"
          />
          <path
            fill="#2CFF80"
            d="M0.857143 520.985H0V519.854L0.857143 518.997H4V519.854H1.34097C1.27532 519.854 1.21258 519.881 1.16759 519.929L0.921857 520.19C0.880289 520.234 0.857143 520.292 0.857143 520.353V520.985Z"
          />
        </svg>
      </StyledRightLetters>
    </StyledMaskR>
  </StyledWrapper>
)

export default BigLogo
