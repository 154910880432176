import { $match, getMatch } from 'stores/lobbyStore'
import { $modalResultMatchId } from 'stores/appStore'
import { Header2, Header3 } from 'styles/typography'
import { useEffect, useState } from 'react'
import { useStore } from '@nanostores/react'
import { useTranslation } from 'react-i18next'
import Coin from 'images/Coin'
import Loading from 'components/Loading'
import MatchDemo from 'components/MatchResults/MatchDemo'
import MatchInfoHeader from 'components/MatchResults/MatchInfoHeader'
import MatchResult1x1CS from 'components/MatchResults/MatchResult1x1CS'
import MatchResultCustomCS from 'components/MatchResults/MatchResultCustomCS'
import ModalHeader from 'components/Modals/ModalHeader'
import ModalWrapper from 'components/Modals/ModalWrapper'
import ReportServerButton from 'components/ReportServerButton'
import TabsSwitcher from 'components/TabsSwitcher'
import WinnerTeam from 'components/MatchResults/WinnerTeam'
import styled from 'styled-components'

const MatchResults: React.FC = () => {
  const { t } = useTranslation()

  const [tab, setTab] = useState<'scoreboard' | 'history'>('scoreboard')

  const match = useStore($match)
  const matchId = useStore($modalResultMatchId)

  useEffect(() => {
    if (matchId && match?.id !== matchId) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      getMatch(matchId)
    }
  }, [match, matchId])

  if (!matchId || !match || (matchId && match?.id !== matchId)) {
    return (
      <StyledModalWrapper>
        <StyledModalHeader>
          <ModalHeader title={t('modal.titles.matchResults')} />
        </StyledModalHeader>
        <StyledWrapper>
          <Loading />
        </StyledWrapper>
      </StyledModalWrapper>
    )
  }

  const is1v1 = match.participants?.length === 2

  if (is1v1) {
    return <MatchResult1x1CS />
  }

  return (
    <StyledModalWrapper>
      <StyledModalHeader>
        <ModalHeader title={t('modal.titles.matchResults')} />
      </StyledModalHeader>
      <StyledWrapper>
        <StyledHeader>
          <StyledTabsWrapper>
            <TabsSwitcher
              defaultActiveKey={tab}
              onChange={(key: string) => {
                setTab(key as 'scoreboard' | 'history')
              }}
              items={[
                {
                  key: 'scoreboard',
                  label: t('results.scoreboard.title'),
                },
                {
                  key: 'history',
                  label: t('results.history.title'),
                },
              ]}
            />
          </StyledTabsWrapper>

          <StyledMainInfo>
            <WinnerTeam />
            <MatchInfoHeader />
          </StyledMainInfo>

          <StyledBalance>
            <StyledCoin />
            <div>{match?.bet}</div>
          </StyledBalance>
        </StyledHeader>

        <MatchResultCustomCS tab={tab} />

        <StyledButtonsWrapper>
          <MatchDemo match={match} />
          <ReportServerButton matchId={matchId} />
        </StyledButtonsWrapper>
      </StyledWrapper>
    </StyledModalWrapper>
  )
}

const StyledModalWrapper = styled(ModalWrapper)`
  width: 1648px;
  padding-bottom: 38px;
`

const StyledModalHeader = styled.div`
  padding: 13px 11px 13px 20px;
`

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  padding: 0 40px 38px 0;
`

const StyledHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;
  padding-left: 20px;
  margin-bottom: 28px;
`

const StyledMainInfo = styled.div`
  width: 100%;
  justify-content: center;
  position: relative;
  display: flex;
  gap: 64px;
`

const StyledBalance = styled(Header2)`
  display: flex;
  font-weight: 400;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
`

const StyledCoin = styled(Coin)`
  fill: #ffcd29;
  height: 42px;
  width: 42px;
  margin-right: 20px;
`

const StyledTabsWrapper = styled(Header3)`
  display: flex;
  align-items: center;
  gap: 22px;
  z-index: 10;
`

const StyledButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  height: 50px;
  margin-top: 15px;
  padding-left: 196px;
`

export default MatchResults
