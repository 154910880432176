const SwitchIcon = ({ className }: { className?: string }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M2.40821 3.02179C2.2709 3.07374 2.13359 3.20362 2.07051 3.34464C2.00371 3.48937 2 3.65265 2 6.57688C2 9.52338 2.00371 9.6644 2.07051 9.81283C2.21152 10.1208 1.98887 10.1023 5.4883 10.1134C8.93949 10.1246 8.80589 10.132 8.99886 9.87592C9.14359 9.68666 9.15843 9.46029 9.03968 9.2599C8.98773 9.17084 8.49417 8.65131 7.94124 8.09837C7.38831 7.54915 6.93557 7.08528 6.93557 7.07044C6.93557 7.0556 7.03948 6.96653 7.16565 6.86634C8.15647 6.10559 9.28089 5.63059 10.5538 5.44133C10.9508 5.38196 12.0307 5.38196 12.4463 5.44133C15.0069 5.81243 17.0962 7.43411 18.087 9.82397C18.2132 10.1246 18.3802 10.6664 18.4358 10.9558C18.4618 11.0783 18.4915 11.2082 18.4989 11.2416C18.5175 11.3046 18.6065 11.3083 19.7161 11.3083H20.9147L20.8665 10.9818C20.4583 8.38412 18.9145 6.01653 16.6768 4.54699C14.0569 2.82882 10.7245 2.52452 7.8262 3.74542C7.05432 4.07199 6.2342 4.56555 5.56994 5.10363L5.22482 5.38196L4.08556 4.2464C3.32852 3.49308 2.90176 3.0923 2.80899 3.05148C2.67168 2.99581 2.51211 2.98468 2.40821 3.02179Z" />
    <path d="M2.13418 14.0059C2.54238 16.6073 4.08614 18.9749 6.32385 20.4444C8.94378 22.1626 12.2762 22.4669 15.1745 21.246C15.9464 20.9195 16.7665 20.4259 17.4307 19.8878L17.7759 19.6095L18.9337 20.7599C20.1583 21.9771 20.2251 22.029 20.5034 21.9845C20.6852 21.9548 20.8485 21.8249 20.9302 21.6468C20.997 21.5021 21.0007 21.3388 21.0007 18.4146C21.0007 15.4681 20.997 15.327 20.9302 15.1786C20.7892 14.8706 21.0118 14.8891 17.5124 14.878C14.0612 14.8669 14.1948 14.8595 14.0018 15.1155C13.8571 15.3048 13.8422 15.5311 13.961 15.7315C14.0129 15.8206 14.5065 16.3401 15.0594 16.8931C15.6124 17.4423 16.0651 17.9062 16.0651 17.921C16.0651 17.9358 15.9612 18.0249 15.835 18.1251C14.8442 18.8858 13.7198 19.3609 12.4469 19.5501C12.0499 19.6095 10.97 19.6095 10.5543 19.5501C7.99378 19.179 5.90451 17.5573 4.91369 15.1675C4.78751 14.8669 4.62052 14.3251 4.56486 14.0356C4.53888 13.9132 4.50919 13.7833 4.50177 13.7462C4.48321 13.6868 4.39415 13.6831 3.28458 13.6831H2.08594L2.13418 14.0059Z" />
  </svg>
)

export default SwitchIcon
