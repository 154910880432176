const CloseEyeIcon = () => (
  <svg
    width="16"
    fill="none"
    height="16"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="white"
      d="M14.7215 7.59719C14.1722 6.44 13.5186 5.49625 12.7605 4.76594L11.9655 5.56094C12.6138 6.18047 13.1785 6.99094 13.6668 8.00031C12.3668 10.6909 10.5215 11.9691 7.9996 11.9691C7.24262 11.9691 6.54538 11.8525 5.90788 11.6194L5.04648 12.4808C5.93189 12.8896 6.91627 13.0941 7.9996 13.0941C11.0027 13.0941 13.2434 11.53 14.7215 8.40188C14.7809 8.27607 14.8117 8.13867 14.8117 7.99953C14.8117 7.8604 14.7809 7.72299 14.7215 7.59719ZM13.7282 2.58719L13.0621 1.92031C13.0505 1.90869 13.0367 1.89947 13.0215 1.89318C13.0064 1.88689 12.9901 1.88365 12.9737 1.88365C12.9572 1.88365 12.941 1.88689 12.9258 1.89318C12.9106 1.89947 12.8968 1.90869 12.8852 1.92031L11.1763 3.62844C10.2341 3.14719 9.17523 2.90656 7.9996 2.90656C4.99648 2.90656 2.75585 4.47063 1.27773 7.59875C1.21831 7.72456 1.1875 7.86196 1.1875 8.00109C1.1875 8.14023 1.21831 8.27763 1.27773 8.40344C1.86825 9.64719 2.57919 10.6441 3.41054 11.3942L1.7571 13.0472C1.73368 13.0706 1.72052 13.1024 1.72052 13.1355C1.72052 13.1687 1.73368 13.2005 1.7571 13.2239L2.42413 13.8909C2.44757 13.9144 2.47935 13.9275 2.51249 13.9275C2.54563 13.9275 2.57741 13.9144 2.60085 13.8909L13.7282 2.76406C13.7398 2.75245 13.749 2.73867 13.7553 2.72349C13.7616 2.70832 13.7649 2.69205 13.7649 2.67563C13.7649 2.6592 13.7616 2.64293 13.7553 2.62776C13.749 2.61258 13.7398 2.5988 13.7282 2.58719ZM2.33241 8.00031C3.63398 5.30969 5.47929 4.03156 7.9996 4.03156C8.85179 4.03156 9.62663 4.17781 10.3296 4.47516L9.23116 5.57359C8.71096 5.29604 8.11532 5.19303 7.53211 5.27976C6.9489 5.36649 6.40901 5.63838 5.99209 6.0553C5.57516 6.47222 5.30328 7.01211 5.21655 7.59532C5.12982 8.17853 5.23283 8.77417 5.51038 9.29438L4.20695 10.5978C3.48554 9.96109 2.86366 9.09844 2.33241 8.00031ZM6.1871 8.00031C6.18738 7.72479 6.25264 7.45321 6.37759 7.20765C6.50254 6.96209 6.68365 6.74946 6.90621 6.58705C7.12878 6.42463 7.38651 6.317 7.65849 6.27291C7.93046 6.22882 8.209 6.2495 8.47148 6.33328L6.27007 8.53469C6.21489 8.36194 6.1869 8.18166 6.1871 8.00031Z"
    />
    <path
      fill="white"
      d="M7.93724 9.75018C7.88318 9.75018 7.8299 9.74768 7.77709 9.74283L6.95178 10.5681C7.44817 10.7582 7.989 10.8005 8.50888 10.6898C9.02877 10.5791 9.50546 10.3201 9.88132 9.94425C10.2572 9.56839 10.5161 9.09171 10.6268 8.57182C10.7376 8.05193 10.6953 7.5111 10.5052 7.01471L9.67989 7.84002C9.68474 7.89284 9.68724 7.94612 9.68724 8.00018C9.68736 8.23003 9.64218 8.45765 9.55428 8.67002C9.46638 8.8824 9.33748 9.07536 9.17495 9.23789C9.01242 9.40042 8.81946 9.52932 8.60708 9.61722C8.39471 9.70512 8.16709 9.7503 7.93724 9.75018Z"
    />
  </svg>
)

export default CloseEyeIcon
