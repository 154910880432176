import { $userProfile } from 'stores/userStore'
import {
  DtoCSProfile,
  DtoCSTotalStat,
  DtoDOTA2Profile,
  DtoDOTA2TotalStat,
  DtoStanding,
  DtoTotalStat,
  DtoTournamentTable,
  DtoUserTotalStat,
} from 'api/schemas/statisticApi'
import { DtoJudgeClaimStat, DtoUserClaimStat } from 'api/schemas/judgeApi'
import { DtoMatch } from 'api/schemas/matchApi'
import { DtoUserPublic } from 'api/schemas/userApi'
import { atom, computed } from 'nanostores'
import { logger } from '@nanostores/logger'
import api from 'api/api'

export type GameFormat = 'FIVE_TO_FIVE' | 'ONE_TO_ONE' | 'CUSTOM'
export type MainGameFormat = 'FIVE_TO_FIVE' | 'ONE_TO_ONE'
export type GameChoosen = 'DOTA2' | 'CS'
export type RankTypes =
  | 'S+'
  | 'S'
  | 'AAA'
  | 'AA+'
  | 'AA'
  | 'A+'
  | 'A'
  | 'BBB'
  | 'BB+'
  | 'BB'
  | 'B+'
  | 'B'
  | 'CCC'
  | 'CC+'
  | 'CC'
  | 'C+'
  | 'C'
  | 'DDD'
  | 'DD+'
  | 'DD'
  | 'D+'
  | 'D'

export interface OnlineChart {
  time: string
  onlineCount: number
}

export const $matchesHistory = atom<DtoMatch[] | null>(null)
export const $matchesHistorySearch = atom<DtoMatch[] | null>(null)

export const $appealMatchesHistory = atom<DtoMatch[] | null>(null)

export const $matchInfo = atom<DtoMatch | null>(null)

// MainPageStats
export const $mainPageStatisticGameFormat = atom<MainGameFormat>('FIVE_TO_FIVE')

export const $personalStatisticCS = atom<DtoCSProfile[] | null>(null)
export const $gameChoosen = atom<GameChoosen>('CS')

export const $totalStat = atom<DtoTotalStat | null>(null)
export const $userPosition = atom<DtoStanding[] | null>(null)
// MainPageStats

// userPopupStat
export const $userPopupId = atom<string | null>(null)
export const $userPopupStat = atom<DtoUserTotalStat | null>(null)
export const $userPopupInfo = atom<DtoUserPublic | null>(null)
export const $userPopupGame = atom<GameChoosen>('CS')
export const $userPopupGameFormat = atom<GameFormat>('CUSTOM')
// userPopupStat

// UserStatisticPage
export const $userStatisticPageStat = atom<DtoUserTotalStat | null>(null)
export const $userStatisticPageInfo = atom<DtoUserPublic | null>(null)
export const $userStatisticPageGame = atom<GameChoosen>('CS')
export const $userStatisticPageGameFormat = atom<GameFormat>('CUSTOM')
// UserStatisticPage

// JudgeMyTicketsPage
export const $myTicketsStatistic = atom<DtoUserClaimStat | null>(null)
// JudgeMyTicketsPage

// JudgeMyWorkPage
export const $myWorkJudgeStatistic = atom<DtoJudgeClaimStat | null>(null)
// JudgeMyWorkPage

// MainPageStats
export const $onlineChart = computed(
  [$totalStat],
  (stat: DtoTotalStat | null) => {
    if (!stat?.onlineStats) return null
    const data = stat.onlineStats
    const firstElements = data.slice(0, 24)
    const chunkSize = 3
    const out = []
    for (let i = 0; i < firstElements.length; i += chunkSize) {
      const chunk = firstElements.slice(i, i + chunkSize)
      const sum = Math.round(
        chunk.reduce((summ, current) => summ + (current?.onlineCount || 0), 0) /
          3
      )
      const date = new Date(chunk[0]?.createdAt as string).getUTCHours()
      out.push({
        onlineCount: sum,
        time: date < 10 ? `0${date}` : `${date}`,
      })
    }
    return out.reverse()
  }
)
export const $onlineChangePercent = computed(
  [$totalStat],
  (stat: DtoTotalStat | null) => {
    const data = stat?.onlineStats
    if (!data || data.slice(0, 48).length < 48) return 0
    const firstDay = data
      .slice(0, 24)
      .reduce((sum, item) => sum + (item.onlineCount || 0), 0)
    const secondDay =
      data
        .slice(24, 48)
        .reduce((sum, item) => sum + (item.onlineCount || 0), 0) || 1
    return Math.round(((firstDay - secondDay) * 100) / secondDay)
  }
)
export const $totalMatches = computed(
  [$totalStat],
  (stat: DtoTotalStat | null) => {
    if (!stat?.totalGameStat) return null
    return stat.totalGameStat.reduce((currentSum, currentItem) => {
      return currentSum + (currentItem?.totalMatches || 0)
    }, 0)
  }
)
export const $totalReward = computed(
  [$totalStat],
  (stat: DtoTotalStat | null) => {
    if (!stat?.totalGameStat) return null
    return stat.totalGameStat.reduce((currentSum, currentItem) => {
      return currentSum + (currentItem?.totalReward || 0)
    }, 0)
  }
)
export const $CSLobbyCount = computed(
  [$totalStat],
  (stat: DtoTotalStat | null) => {
    if (!stat?.totalGameStat) return 0
    return stat.totalGameStat.reduce((currentSum, currentItem) => {
      return currentItem.gameName === 'CS'
        ? currentSum + (currentItem?.onlineLobbyCount || 0)
        : currentSum
    }, 0)
  }
)
export const $CSInGameCount = computed(
  [$totalStat],
  (stat: DtoTotalStat | null) => {
    if (!stat?.totalGameStat) return 0
    return stat.totalGameStat.reduce((currentSum, currentItem) => {
      return currentItem.gameName === 'CS'
        ? currentSum + (currentItem?.onlineMatchCount || 0)
        : currentSum
    }, 0)
  }
)
export const $CSAllCount = computed([$CSLobbyCount], (CSLobbyCount: number) => {
  return CSLobbyCount
})
export const $DotaLobbyCount = computed(
  [$totalStat],
  (stat: DtoTotalStat | null) => {
    if (!stat?.totalGameStat) return 0
    return stat.totalGameStat.reduce((currentSum, currentItem) => {
      return currentItem.gameName === 'DOTA2'
        ? currentSum + (currentItem?.onlineLobbyCount || 0)
        : currentSum
    }, 0)
  }
)
export const $DotaInGameCount = computed(
  [$totalStat],
  (stat: DtoTotalStat | null) => {
    if (!stat?.totalGameStat) return 0
    return stat.totalGameStat.reduce((currentSum, currentItem) => {
      return currentItem.gameName === 'DOTA2'
        ? currentSum + (currentItem?.onlineMatchCount || 0)
        : currentSum
    }, 0)
  }
)
export const $DotaAllCount = computed(
  [$DotaLobbyCount],
  (DotaLobbyCount: number) => {
    return DotaLobbyCount
  }
)
export const $currentOnline = computed(
  [$totalStat],
  (stat: DtoTotalStat | null) => {
    return stat?.currentOnlineCount || 0
  }
)
export const $tournamentTables = computed(
  [$totalStat],
  (stat: DtoTotalStat | null) => {
    return stat?.tournamentTables || null
  }
)
export const $CSActiveTable = computed(
  [$tournamentTables, $mainPageStatisticGameFormat],
  (tables: DtoTournamentTable[] | null, gameFormat: MainGameFormat) => {
    return tables?.filter(
      (item) => item.gameName === 'CS' && item.gameFormat === gameFormat
    )[0]
  }
)
export const $DotaActiveTable = computed(
  [$tournamentTables, $mainPageStatisticGameFormat],
  (tables: DtoTournamentTable[] | null, gameFormat: MainGameFormat) => {
    return tables?.filter(
      (item) => item.gameName === 'DOTA2' && item.gameFormat === gameFormat
    )[0]
  }
)
// MainPageStats

// userPopupStat
export const $nickHistory = computed(
  $userPopupInfo,
  (userInfo: DtoUserPublic | null) => {
    const names: {
      id: string
      label: string
      value: string
    }[] = []
    if (!userInfo?.steamNameHistory) return names

    const history = userInfo.steamNameHistory.map((nick, index) => {
      return {
        id: `${index + 2}`,
        label: nick.name || '',
        value: nick.name || '',
      }
    })
    return [...names, ...history]
  }
)

export const $userPopupActiveGameStats = computed(
  [$userPopupStat, $userPopupGame, $userPopupGameFormat],
  (userPopupStat: DtoUserTotalStat | null, userPopupGame: GameChoosen) => {
    if (!userPopupStat) return null
    return userPopupGame === 'CS'
      ? userPopupStat.CSTotalStat || null
      : userPopupStat.DOTA2TotalStat || null
  }
)
export const $userPopupActiveGameTime = computed(
  $userPopupActiveGameStats,
  (userPopupActiveGameStats: DtoCSTotalStat | DtoDOTA2TotalStat | null) => {
    return {
      gamesInTotal: userPopupActiveGameStats?.gamesInTotal || 0,
      spentTimeInGames: Math.round(
        (userPopupActiveGameStats?.spentTimeInGamesMin || 0) / 60
      ),
    }
  }
)
export const $userPopupActiveGameInfo = computed(
  [$userPopupActiveGameStats, $userPopupGame, $userPopupGameFormat],
  (
    userPopupActiveGameStats: DtoCSTotalStat | DtoDOTA2TotalStat | null,
    userPopupGame: GameChoosen,
    userPopupGameFormat: GameFormat
  ) => {
    if (!userPopupActiveGameStats) return null
    let activeGame: DtoCSProfile[] | DtoDOTA2Profile[] | null = null
    if (userPopupGame === 'CS') {
      activeGame =
        (userPopupActiveGameStats as DtoCSTotalStat).CSProfiles || null
    }
    if (userPopupGame === 'DOTA2') {
      activeGame =
        (userPopupActiveGameStats as DtoDOTA2TotalStat).DOTA2Profiles || null
    }
    return !activeGame
      ? null
      : {
          ...activeGame.filter(
            (game) => game.gameFormat === userPopupGameFormat
          )[0],
        }
  }
)
export const $userPopupWeapons = computed(
  [$userPopupActiveGameInfo, $userPopupGame],
  (
    userPopupActiveGameInfo: DtoCSProfile | DtoDOTA2Profile | null,
    userPopupGame: GameChoosen
  ) => {
    if (
      userPopupGame !== 'CS' ||
      !(userPopupActiveGameInfo as DtoCSProfile)?.CSWeaponProfileStats?.length
    )
      return null
    return (userPopupActiveGameInfo as DtoCSProfile).CSWeaponProfileStats?.sort(
      (a, b) => (b?.KD || 0) - (a?.KD || 0)
    )
  }
)
export const $userPopupMaps = computed(
  [$userPopupActiveGameInfo, $userPopupGame],
  (
    userPopupActiveGameInfo: DtoCSProfile | DtoDOTA2Profile | null,
    userPopupGame: GameChoosen
  ) => {
    if (
      userPopupGame !== 'CS' ||
      !(userPopupActiveGameInfo as DtoCSProfile)?.CSMapProfileStats?.length
    )
      return null
    return (userPopupActiveGameInfo as DtoCSProfile).CSMapProfileStats?.sort(
      (a, b) => (b.gameCount || 0) - (a.gameCount || 0)
    )?.slice(0, 10)
  }
)
export const $userPopupMapData = computed($userPopupMaps, (userPopupMaps) => {
  if (!userPopupMaps?.length) {
    return new Array(10).fill('').map((item, i) => {
      return {
        imgUrl: '',
        gameCount: 0,
        name: `map_${i}`,
      }
    })
  }

  const filledMaps = userPopupMaps.slice(0, 10).map((map, i) => ({
    gameCount: map?.gameCount || 0,
    imgUrl: map.gameMap?.imgIconUrl || '',
    name: map.gameMap?.name || `map_${i}`,
  }))

  while (filledMaps.length < 10) {
    const i = filledMaps.length
    filledMaps.push({
      imgUrl: '',
      gameCount: 0,
      name: `map_${i}`,
    })
  }

  return filledMaps.sort((first, second) => second.gameCount - first.gameCount)
})
// userPopupStat

// UserStatisticPage
export const $userStatisticPageActiveGameStats = computed(
  [
    $userStatisticPageStat,
    $userStatisticPageGame,
    $userStatisticPageGameFormat,
  ],
  (
    userStatisticPageStat: DtoUserTotalStat | null,
    userStatisticPageGame: GameChoosen
  ) => {
    if (!userStatisticPageStat) return null
    return userStatisticPageGame === 'CS'
      ? userStatisticPageStat.CSTotalStat || null
      : userStatisticPageStat.DOTA2TotalStat || null
  }
)
export const $userStatisticPageActiveGameTime = computed(
  $userStatisticPageActiveGameStats,
  (
    userStatisticPageActiveGameStats: DtoCSTotalStat | DtoDOTA2TotalStat | null
  ) => {
    return {
      gamesInTotal: userStatisticPageActiveGameStats?.gamesInTotal || 0,
      spentTimeInGames: Math.round(
        (userStatisticPageActiveGameStats?.spentTimeInGamesMin || 0) / 60
      ),
    }
  }
)
export const $userStatisticPageActiveGameInfo = computed(
  [
    $userStatisticPageActiveGameStats,
    $userStatisticPageGame,
    $userStatisticPageGameFormat,
  ],
  (
    userStatisticPageActiveGameStats: DtoCSTotalStat | DtoDOTA2TotalStat | null,
    userStatisticPageGame: GameChoosen,
    userStatisticPageGameFormat: GameFormat
  ) => {
    if (!userStatisticPageActiveGameStats) return null
    let activeGame: DtoCSProfile[] | DtoDOTA2Profile[] | null = null
    if (userStatisticPageGame === 'CS') {
      activeGame =
        (userStatisticPageActiveGameStats as DtoCSTotalStat).CSProfiles || null
    }
    if (userStatisticPageGame === 'DOTA2') {
      activeGame =
        (userStatisticPageActiveGameStats as DtoDOTA2TotalStat).DOTA2Profiles ||
        null
    }
    return !activeGame
      ? null
      : {
          ...activeGame.filter(
            (game) => game.gameFormat === userStatisticPageGameFormat
          )[0],
        }
  }
)
export const $userStatisticPageWeapons = computed(
  [$userStatisticPageActiveGameInfo, $userStatisticPageGame],
  (
    userStatisticPageActiveGameInfo: DtoCSProfile | DtoDOTA2Profile | null,
    userStatisticPageGame: GameChoosen
  ) => {
    if (
      userStatisticPageGame !== 'CS' ||
      !(userStatisticPageActiveGameInfo as DtoCSProfile)?.CSWeaponProfileStats
        ?.length
    )
      return null
    return (
      userStatisticPageActiveGameInfo as DtoCSProfile
    ).CSWeaponProfileStats?.sort((a, b) => (b?.KD || 0) - (a?.KD || 0))
  }
)
export const $userStatisticPageMaps = computed(
  [$userStatisticPageActiveGameInfo, $userStatisticPageGame],
  (
    userStatisticPageActiveGameInfo: DtoCSProfile | DtoDOTA2Profile | null,
    userPopupGame: GameChoosen
  ) => {
    if (
      userPopupGame !== 'CS' ||
      !(userStatisticPageActiveGameInfo as DtoCSProfile)?.CSMapProfileStats
        ?.length
    )
      return null
    return (
      userStatisticPageActiveGameInfo as DtoCSProfile
    ).CSMapProfileStats?.slice(0, 10)
  }
)
export const $userStatisticPageMapData = computed(
  $userStatisticPageMaps,
  (userStatisticPageMaps) => {
    if (!userStatisticPageMaps?.length) {
      return new Array(10).fill('').map((item, i) => {
        return {
          imgUrl: '',
          gameCount: 0,
          name: `map_${i}`,
        }
      })
    }

    const filledMaps = userStatisticPageMaps.slice(0, 10).map((map, i) => ({
      gameCount: map?.gameCount || 0,
      imgUrl: map?.gameMap?.imgIconUrl || '',
      name: map?.gameMap?.label || map?.gameMap?.name || `map_${i}`,
    }))

    while (filledMaps.length < 10) {
      const i = filledMaps.length
      filledMaps.push({
        imgUrl: '',
        gameCount: 0,
        name: `map_${i}`,
      })
    }

    return filledMaps.sort(
      (first, second) => second.gameCount - first.gameCount
    )
  }
)
// UserStatisticPage

// ACTIONS
// MainPageStats
export const setMainPageGameFormat = (format: MainGameFormat) => {
  $mainPageStatisticGameFormat.set(format)
}

export const getTotalStat = async () => {
  try {
    const response = await api.total.getTotal()
    return $totalStat.set(response.data)
  } catch (e) {
    console.log(e)
  }
}

export const getUserPosition = async () => {
  let data: DtoStanding[]
  try {
    const res = (await api.standing.getUser()).data as DtoStanding[]
    const userName = $userProfile.get()?.name
    const userImg = $userProfile.get()?.imgUrl

    data = [
      ...res.map((item) => {
        return {
          ...item,
          userImg,
          userName,
        }
      }),
    ]

    return $userPosition.set(data)
  } catch (e) {
    console.log(e)
  }
}
// MainPageStats

// userPopupStat
export const getuserPopupStat = async (id: string) => {
  try {
    const response = await api.total.getUserId(id)
    $userPopupStat.set(response.data)
  } catch (e) {
    console.log(e)
  }
}

export const getUserInfo = async (id: string) => {
  try {
    const response = await api.users.getUsers2(id)
    $userPopupInfo.set(response.data)
  } catch (e) {
    console.log(e)
  }
}

export const setUserPopupGame = (game: GameChoosen) => {
  $userPopupGame.set(game)
}

export const setUserPopupGameFormat = (gameFormat: GameFormat) => {
  $userPopupGameFormat.set(gameFormat)
}
// userPopupStat

// UserStatisticPage
export const getUserStatisticPageStat = async (id: string) => {
  try {
    const response = await api.total.getUserId(id)
    $userStatisticPageStat.set(response.data)
  } catch (e) {
    console.log(e)
  }
}

export const getUserStatisticPageInfo = async (id: string) => {
  try {
    const response = await api.users.getUsers2(id)
    $userStatisticPageInfo.set(response.data)
  } catch (e) {
    console.log(e)
  }
}

export const setUserStatisticPageGame = (game: GameChoosen) => {
  $userStatisticPageGame.set(game)
}

export const setUserStatisticPageGameFormat = (gameFormat: GameFormat) => {
  $userStatisticPageGameFormat.set(gameFormat)
}
// UserStatisticPage

export const getMatchInfo = async (id: string, gameFormat: GameFormat) => {
  try {
    const res = await api.match.getMatch({ gameFormat, matchId: id })
    const data = res.data[0]
    return $matchInfo.set(data)
  } catch (e) {
    console.log(e)
  }
}

export const getMatches = async (gameFormat?: GameFormat) => {
  let data: DtoMatch[] | null = null
  try {
    const res = await api.match.getMatch({ gameFormat })
    data = res.data
    if (data) {
      return $matchesHistory.set(data)
    } else {
      return $matchesHistory.set(null)
    }
  } catch (e) {
    console.log(e)
  }
}

export const getAppealMatches = async (gameFormat?: GameFormat) => {
  let data: DtoMatch[] | null = null
  try {
    const res = await api.match.getMatch({ gameFormat })
    data = res.data
    if (data) {
      return $appealMatchesHistory.set(data)
    } else {
      return $appealMatchesHistory.set(null)
    }
  } catch (e) {
    console.log(e)
  }
}

export const getSearcedMatches = async (
  gameFormat: GameFormat,
  externalMatchId?: string
) => {
  let data: DtoMatch[] | null = null
  try {
    const res = await api.match.getMatch({ gameFormat, externalMatchId })
    data = res.data
    return $matchesHistorySearch.set(data)
  } catch (e) {
    console.log(e)
  }
}

// MY TICKETS

export const getMyTicketsStatistic = async () => {
  try {
    const { data } = await api.judgeAPI.statistic.getUser()

    if (data) {
      $myTicketsStatistic.set(data)
    }
  } catch (error) {
    console.log('getMyTicketsStatistic', error)
  }
}

// MY WORK

export const getMyWorkJudgeStatistic = async () => {
  try {
    const { data } = await api.judgeAPI.statistic.getJudge()

    if (data) {
      $myWorkJudgeStatistic.set(data)
    }
  } catch (error) {
    console.log('getMyWorkJudgeStatistic', error)
  }
}

// SUBSCRIBE

logger(
  {
    totalStat: $totalStat,
    CSAllCount: $CSAllCount,
    gameChoosen: $gameChoosen,
    onlineChart: $onlineChart,
    totalReward: $totalReward,
    totalMatches: $totalMatches,
    CSLobbyCount: $CSLobbyCount,
    DotaAllCount: $DotaAllCount,
    CSInGameCount: $CSInGameCount,
    currentOnline: $currentOnline,
    userPopupStat: $userPopupStat,
    MatchesHistory: $matchesHistory,
    DotaLobbyCount: $DotaLobbyCount,
    DotaInGameCount: $DotaInGameCount,
    tournamentTables: $tournamentTables,
    userPopupGameFormat: $userPopupGameFormat,
    onlineChangePercent: $onlineChangePercent,
    userActiveGameInfo: $userPopupActiveGameInfo,
    mainPageStatisticGameFormat: $mainPageStatisticGameFormat,
  },
  {
    messages: {
      mount: false,
      unmount: false,
    },
  }
)
