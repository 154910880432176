/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface DtoAttachment {
  bucket?: string
  createdAt?: string
  extension?: string
  id?: string
  name?: string
  path?: string
  size?: number
  url?: string
}

export interface ResponseResponse {
  data?: any
  meta?: ResponseMeta
  pagination?: ResponsePagination
}

export interface ResponseMeta {
  code?: string
  error?: string
  message?: string
  requestId?: string
  traceId?: string
}

export interface ResponsePagination {
  page?: number
  pages?: number
  perPage?: number
}

export type PostV1InternalDeleteError = ResponseResponse

export type GetV1InternalDownloadUrlMatchDemoError = ResponseResponse

export type GetV1InternalDownloadUrlError = ResponseResponse

export type PostV1UploadClaimError = ResponseResponse

export type PostV1UploadMatchError = ResponseResponse

export type PostV1UploadMatchDemoError = ResponseResponse

export type PostV1UploadProfileAvatarError = ResponseResponse

export namespace Api {
  /**
   * No description
   * @tags delete
   * @name PostV1InternalDelete
   * @summary Delete file by id
   * @request POST:/api/v1/internal/delete/{id}
   */
  export namespace PostV1InternalDelete {
    export type RequestParams = {
      /**
       * File id
       * @format uuid
       */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ResponseResponse & {
      data?: {
        url?: string
      }
    }
  }

  /**
   * No description
   * @tags download
   * @name GetV1InternalDownloadUrlMatchDemo
   * @summary Get an url to download match demo
   * @request GET:/api/v1/internal/download-url/match/demo/{id}
   */
  export namespace GetV1InternalDownloadUrlMatchDemo {
    export type RequestParams = {
      /**
       * Match id
       * @format uuid
       */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ResponseResponse & {
      data?: {
        url?: string
      }
    }
  }

  /**
   * No description
   * @tags download
   * @name GetV1InternalDownloadUrl
   * @summary Get an url to download file by it`s id
   * @request GET:/api/v1/internal/download-url/{id}
   */
  export namespace GetV1InternalDownloadUrl {
    export type RequestParams = {
      /**
       * File Id
       * @format uuid
       */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ResponseResponse & {
      data?: {
        url?: string
      }
    }
  }

  /**
   * @description Upload a user application
   * @tags upload
   * @name PostV1UploadClaim
   * @summary Upload a user application
   * @request POST:/api/v1/upload/claim
   */
  export namespace PostV1UploadClaim {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = {
      /** User claim`s file */
      file: File
      /** @format uuid */
      claimId: string
      /** @format uuid */
      userId: string
    }
    export type RequestHeaders = {
      /** Secret api key */
      'API-KEY': string
    }
    export type ResponseBody = ResponseResponse & {
      data?: DtoAttachment
    }
  }

  /**
   * @description Upload a match file
   * @tags upload
   * @name PostV1UploadMatch
   * @summary Upload a match file
   * @request POST:/api/v1/upload/match
   */
  export namespace PostV1UploadMatch {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = {
      /** Match file */
      file: File
      /** @format uuid */
      matchId: string
    }
    export type RequestHeaders = {
      /** Secret api key */
      'API-KEY': string
    }
    export type ResponseBody = ResponseResponse & {
      data?: DtoAttachment
    }
  }

  /**
   * @description Upload a match demo file
   * @tags upload
   * @name PostV1UploadMatchDemo
   * @summary Upload a match demo file
   * @request POST:/api/v1/upload/match/demo
   */
  export namespace PostV1UploadMatchDemo {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = {
      /** Match demo file */
      file: File
      /** @format uuid */
      matchId: string
    }
    export type RequestHeaders = {
      /** Secret api key */
      'API-KEY': string
    }
    export type ResponseBody = ResponseResponse & {
      data?: DtoAttachment
    }
  }

  /**
   * @description Upload a user avatar
   * @tags upload
   * @name PostV1UploadProfileAvatar
   * @summary Upload a user avatar
   * @request POST:/api/v1/upload/profile/avatar
   */
  export namespace PostV1UploadProfileAvatar {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = {
      /** User avatar file */
      file: File
      /** @format uuid */
      userId: string
    }
    export type RequestHeaders = {
      /** Secret api key */
      'API-KEY': string
    }
    export type ResponseBody = ResponseResponse & {
      data?: DtoAttachment
    }
  }
}

import type { AxiosInstance, AxiosRequestConfig, HeadersDefaults, ResponseType } from 'axios'
import axios from 'axios'

export type QueryParamsType = Record<string | number, any>

export interface FullRequestParams extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean
  /** request path */
  path: string
  /** content type of request body */
  type?: ContentType
  /** query params */
  query?: QueryParamsType
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType
  /** request body */
  body?: unknown
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
  securityWorker?: (
    securityData: SecurityDataType | null
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void
  secure?: boolean
  format?: ResponseType
}

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance
  private securityData: SecurityDataType | null = null
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker']
  private secure?: boolean
  private format?: ResponseType

  constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({
      ...axiosConfig,
      baseURL: axiosConfig.baseURL || '//localhost:8080/f2f/storage/api/v1',
    })
    this.secure = secure
    this.format = format
    this.securityWorker = securityWorker
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data
  }

  protected mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method)

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method && this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    }
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === 'object' && formItem !== null) {
      return JSON.stringify(formItem)
    } else {
      return `${formItem}`
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    if (input instanceof FormData) {
      return input
    }
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key]
      const propertyContent: any[] = property instanceof Array ? property : [property]

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem))
      }

      return formData
    }, new FormData())
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<T> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {}
    const requestParams = this.mergeRequestParams(params, secureParams)
    const responseFormat = format || this.format || undefined

    if (type === ContentType.FormData && body && body !== null && typeof body === 'object') {
      body = this.createFormData(body as Record<string, unknown>)
    }

    if (type === ContentType.Text && body && body !== null && typeof body !== 'string') {
      body = JSON.stringify(body)
    }

    return this.instance
      .request({
        ...requestParams,
        headers: {
          ...(requestParams.headers || {}),
          ...(type ? { 'Content-Type': type } : {}),
        },
        params: query,
        responseType: responseFormat,
        data: body,
        url: path,
      })
      .then((response) => response.data)
  }
}

/**
 * @title AttachmentService manager
 * @version 1.0
 * @baseUrl //localhost:8080/f2f/storage/api/v1
 * @contact Kakharov Azamat
 *
 * This is a storage manager service
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  api = {
    /**
     * No description
     *
     * @tags delete
     * @name PostV1InternalDelete
     * @summary Delete file by id
     * @request POST:/api/v1/internal/delete/{id}
     */
    postV1InternalDelete: (id: string, params: RequestParams = {}) =>
      this.request<
        ResponseResponse & {
          data?: {
            url?: string
          }
        },
        PostV1InternalDeleteError
      >({
        path: `/api/v1/internal/delete/${id}`,
        method: 'POST',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags download
     * @name GetV1InternalDownloadUrlMatchDemo
     * @summary Get an url to download match demo
     * @request GET:/api/v1/internal/download-url/match/demo/{id}
     */
    getV1InternalDownloadUrlMatchDemo: (id: string, params: RequestParams = {}) =>
      this.request<
        ResponseResponse & {
          data?: {
            url?: string
          }
        },
        GetV1InternalDownloadUrlMatchDemoError
      >({
        path: `/api/v1/internal/download-url/match/demo/${id}`,
        method: 'GET',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags download
     * @name GetV1InternalDownloadUrl
     * @summary Get an url to download file by it`s id
     * @request GET:/api/v1/internal/download-url/{id}
     */
    getV1InternalDownloadUrl: (id: string, params: RequestParams = {}) =>
      this.request<
        ResponseResponse & {
          data?: {
            url?: string
          }
        },
        GetV1InternalDownloadUrlError
      >({
        path: `/api/v1/internal/download-url/${id}`,
        method: 'GET',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Upload a user application
     *
     * @tags upload
     * @name PostV1UploadClaim
     * @summary Upload a user application
     * @request POST:/api/v1/upload/claim
     */
    postV1UploadClaim: (
      data: {
        /** User claim`s file */
        file: File
        /** @format uuid */
        claimId: string
        /** @format uuid */
        userId: string
      },
      params: RequestParams = {}
    ) =>
      this.request<
        ResponseResponse & {
          data?: DtoAttachment
        },
        PostV1UploadClaimError
      >({
        path: `/api/v1/upload/claim`,
        method: 'POST',
        body: data,
        type: ContentType.FormData,
        format: 'json',
        ...params,
      }),

    /**
     * @description Upload a match file
     *
     * @tags upload
     * @name PostV1UploadMatch
     * @summary Upload a match file
     * @request POST:/api/v1/upload/match
     */
    postV1UploadMatch: (
      data: {
        /** Match file */
        file: File
        /** @format uuid */
        matchId: string
      },
      params: RequestParams = {}
    ) =>
      this.request<
        ResponseResponse & {
          data?: DtoAttachment
        },
        PostV1UploadMatchError
      >({
        path: `/api/v1/upload/match`,
        method: 'POST',
        body: data,
        type: ContentType.FormData,
        format: 'json',
        ...params,
      }),

    /**
     * @description Upload a match demo file
     *
     * @tags upload
     * @name PostV1UploadMatchDemo
     * @summary Upload a match demo file
     * @request POST:/api/v1/upload/match/demo
     */
    postV1UploadMatchDemo: (
      data: {
        /** Match demo file */
        file: File
        /** @format uuid */
        matchId: string
      },
      params: RequestParams = {}
    ) =>
      this.request<
        ResponseResponse & {
          data?: DtoAttachment
        },
        PostV1UploadMatchDemoError
      >({
        path: `/api/v1/upload/match/demo`,
        method: 'POST',
        body: data,
        type: ContentType.FormData,
        format: 'json',
        ...params,
      }),

    /**
     * @description Upload a user avatar
     *
     * @tags upload
     * @name PostV1UploadProfileAvatar
     * @summary Upload a user avatar
     * @request POST:/api/v1/upload/profile/avatar
     */
    postV1UploadProfileAvatar: (
      data: {
        /** User avatar file */
        file: File
        /** @format uuid */
        userId: string
      },
      params: RequestParams = {}
    ) =>
      this.request<
        ResponseResponse & {
          data?: DtoAttachment
        },
        PostV1UploadProfileAvatarError
      >({
        path: `/api/v1/upload/profile/avatar`,
        method: 'POST',
        body: data,
        type: ContentType.FormData,
        format: 'json',
        ...params,
      }),
  }
}
