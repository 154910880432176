import {
  $CSAllCount,
  $CSInGameCount,
  $DotaAllCount,
  $DotaInGameCount,
} from 'stores/statisticStore'
import { Header3, Header5, Header9 } from 'styles/typography'
import { useStore } from '@nanostores/react'
import { useTranslation } from 'react-i18next'
import CS from 'images/RankGameCS.svg'
import Dota from 'images/dotaMainPageLogo.svg'
import styled from 'styled-components'

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-radius: 6px;
  border: 1px solid rgba(255, 255, 255, 0.15);
  background: radial-gradient(
    121.38% 134.39% at 90.55% 100%,
    rgba(41, 101, 255, 0.2) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  background-color: rgba(255, 255, 255, 0.03);
  padding: 32px 16px;
`
const StyledLegend = styled.div`
  display: grid;
  grid-template-columns: 135px 130px 134px;
  padding: 0 12px 0 12px;
`
const StyledLegendItem = styled(Header9)`
  color: white;
  line-height: 115.5%;
  opacity: 0.5;
  text-transform: uppercase;

  &:not(:first-child) {
    text-align: center;
  }
`
const StyledItems = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`
const StyledItem = styled.div`
  display: grid;
  grid-template-columns: 135px 130px 134px;
  align-items: center;
  padding: 10px;
  border-radius: 6px;
  border: 1px solid rgba(255, 255, 255, 0.15);
  background: rgba(255, 255, 255, 0.15);
  text-align: center;
`
const StyledItemLogo = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`
const StyledCsLogo = styled.div`
  background-image: url('${CS}');
  width: 45px;
  height: 45px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
`
const StyledDotaLogo = styled.div`
  background-image: url('${Dota}');
  width: 45px;
  height: 45px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`
const StyledItemLogoText = styled(Header5)`
  color: white;
  line-height: 100%;
`
const StyledText = styled(Header3)`
  color: white;
  line-height: 115.5%;
`

const GamesOnline: React.FC = () => {
  const { t } = useTranslation()
  const CSAllCount = useStore($CSAllCount)
  const CSInGameCount = useStore($CSInGameCount)
  const DotaAllCount = useStore($DotaAllCount)
  const DotaInGameCount = useStore($DotaInGameCount)

  return (
    <StyledWrapper>
      <StyledLegend>
        <StyledLegendItem>{t('home.game')}</StyledLegendItem>
        <StyledLegendItem>{t('home.online')}</StyledLegendItem>
        <StyledLegendItem>{t('home.inGame')}</StyledLegendItem>
      </StyledLegend>
      <StyledItems>
        <StyledItem>
          <StyledItemLogo>
            <StyledCsLogo />
            <StyledItemLogoText>CS 2</StyledItemLogoText>
          </StyledItemLogo>
          <StyledText>{CSAllCount}</StyledText>
          <StyledText>{CSInGameCount}</StyledText>
        </StyledItem>
        <StyledItem>
          <StyledItemLogo>
            <StyledDotaLogo />
            <StyledItemLogoText>DOTA 2</StyledItemLogoText>
          </StyledItemLogo>
          <StyledText>{DotaAllCount}</StyledText>
          <StyledText>{DotaInGameCount}</StyledText>
        </StyledItem>
      </StyledItems>
    </StyledWrapper>
  )
}

export default GamesOnline
