const StarIcon = ({ className }: { className?: string }) => (
  <svg
    width="24"
    height="24"
    fill="inherit"
    viewBox="0 0 24 24"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M11.5383 2.10997C11.7091 1.69932 12.2909 1.69932 12.4617 2.10996L14.7923 7.71339C14.8643 7.8865 15.0271 8.00479 15.214 8.01977L21.2633 8.50475C21.7066 8.54029 21.8864 9.09354 21.5486 9.38288L16.9397 13.331C16.7973 13.4529 16.7351 13.6443 16.7786 13.8267L18.1867 19.7298C18.2899 20.1625 17.8193 20.5044 17.4397 20.2726L12.2606 17.1092C12.1006 17.0115 11.8994 17.0115 11.7394 17.1092L6.56027 20.2726C6.18073 20.5044 5.7101 20.1625 5.81329 19.7299L7.22141 13.8267C7.26491 13.6443 7.20273 13.4529 7.06033 13.331L2.45136 9.38288C2.11359 9.09354 2.29335 8.54029 2.73668 8.50475L8.78604 8.01977C8.97294 8.00479 9.13574 7.8865 9.20775 7.71339L11.5383 2.10997Z" />
  </svg>
)

export default StarIcon
