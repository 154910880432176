import { styled } from 'styled-components'
import NickTrimmer from 'components/NickTrimmer'

interface StyledCarProps {
  nick: string
  avatar: string
}

const StyledPersonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 7px;
`

const StyledImage = styled.img`
  width: 30px;
  height: 30px;
`

const StyledText = styled.div`
  font-family: Ruberoid;
  font-size: 12px;
  color: white;
  font-weight: 600;
  line-height: 115.5%;
  max-width: 78%;
`
const PersonInChat: React.FC<StyledCarProps> = ({ nick, avatar }) => {
  return (
    <StyledPersonWrapper>
      <StyledImage src={avatar} />
      <StyledText>
        <NickTrimmer>{nick}</NickTrimmer>
      </StyledText>
    </StyledPersonWrapper>
  )
}

export default PersonInChat
