const ImageFileIcon = () => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_10504_130552)">
      <rect
        x="1.71484"
        y="3.10938"
        width="19"
        height="15.5"
        rx="2"
        stroke="white"
        stroke-width="2"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.35 10.5023C8.31983 10.5023 9.10603 9.7161 9.10603 8.74627C9.10603 7.77644 8.31983 6.99023 7.35 6.99023C6.38016 6.99023 5.59396 7.77644 5.59396 8.74627C5.59396 9.7161 6.38016 10.5023 7.35 10.5023ZM12.742 9.35735L8.4058 14.1754L7.05214 12.8217C6.93498 12.7046 6.74503 12.7046 6.62788 12.8217L2.10214 17.3475C1.91315 17.5364 2.047 17.8596 2.31427 17.8596H5.76362H11.3657H20.1664C20.4261 17.8596 20.5631 17.552 20.3894 17.3589L13.188 9.35735C13.0688 9.22494 12.8612 9.22494 12.742 9.35735Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_10504_130552">
        <rect
          width="21"
          height="21"
          fill="white"
          transform="translate(0.714844 0.359375)"
        />
      </clipPath>
    </defs>
  </svg>
)

export default ImageFileIcon
