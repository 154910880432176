import { Text4 } from 'styles/typography'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const StyledMailInfo = styled(Text4)`
  position: absolute;
  top: 52px;
  right: 52px;
  line-height: 125%;
  color: rgba(255, 255, 255, 0.5);
  width: 250px;
`

const StyledMail = styled.a`
  transition: 0.2s;

  &:hover {
    color: #2cff80;
  }
`

const TellUsYourProblem: React.FC = () => {
  const { t } = useTranslation()
  return (
    <StyledMailInfo>
      {t('haveAProblem')}
      <StyledMail href="mailto:support@f2f.vin"> support@f2f.vin</StyledMail>
    </StyledMailInfo>
  )
}

export default TellUsYourProblem
