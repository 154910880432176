import { $match } from 'stores/lobbyStore'
import { Header1, Header6, SideHeader1 } from 'styles/typography'
import { useStore } from '@nanostores/react'
import { useTranslation } from 'react-i18next'
import WinnerStarIcon from 'components/MatchResults/WinnerStarIcon'
import styled from 'styled-components'

const WinnerLeftPart = () => {
  const { t } = useTranslation()
  const match = useStore($match)

  if (!match) return null

  const winner = match.CSMatchResult?.winTeamColor
  const isRedWin = winner === 'RED'
  const isBlueWin = winner === 'BLUE'
  const isDraw = match.CSMatchResult?.isDraw

  return (
    <StyledLeftPart>
      <StyledRedTeamInfo $isWin={isRedWin}>
        <StyledTeamName $isWin={isRedWin}>TEAM RED</StyledTeamName>
        {isRedWin && <WinnerStarIcon />}
        <StyledScore $isWin={isRedWin}>
          {isRedWin
            ? match.CSMatchResult?.winTeamScore
            : match.CSMatchResult?.loseTeamScore}
        </StyledScore>
      </StyledRedTeamInfo>
      {isDraw && <StyledDrawLabel>{t('play.statistic.draw')}</StyledDrawLabel>}
      <StyledBlueTeamInfo $isWin={isBlueWin}>
        <StyledScore $isWin={isBlueWin}>
          {isBlueWin
            ? match.CSMatchResult?.winTeamScore
            : match.CSMatchResult?.loseTeamScore}
        </StyledScore>
        {isBlueWin && <WinnerStarIcon />}
        <StyledTeamName $isWin={isBlueWin}>TEAM BLUE</StyledTeamName>
      </StyledBlueTeamInfo>
    </StyledLeftPart>
  )
}

const StyledLeftPart = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: start;
  height: 100%;
  gap: 32px;
  padding-top: 48px;
`

const StyledTeamInfo = styled.div<WinProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  gap: 12px;
  height: 350px;
  padding-left: 30px;
  margin-right: 86px;
  width: 110px;
  flex-shrink: 0;
`

const StyledRedTeamInfo = styled(StyledTeamInfo)`
  background:
    linear-gradient(
        to bottom right,
        #ff7a00 0%,
        rgba(255, 0, 0, 0.3) 40%,
        rgba(255, 0, 153, 0) 60%
      )
      bottom left / 17% 50% no-repeat,
    linear-gradient(
        to top right,
        #ff7a00 0%,
        rgba(255, 0, 0, 0.3) 40%,
        rgba(255, 0, 153, 0) 60%
      )
      top left / 17% 50% no-repeat;
`

const StyledBlueTeamInfo = styled(StyledTeamInfo)`
  background:
    linear-gradient(
        to bottom right,
        #00d1ff 0%,
        rgba(2, 72, 253, 0.56) 40%,
        rgba(0, 41, 255, 0) 60%
      )
      bottom left / 17% 50% no-repeat,
    linear-gradient(
        to top right,
        #00d1ff 0%,
        rgba(2, 72, 253, 0.56) 40%,
        rgba(0, 41, 255, 0) 60%
      )
      top left / 17% 50% no-repeat;
`

const StyledDrawLabel = styled(Header6)`
  position: absolute;
  border-radius: 6px 0px;
  background: var(--desktop-green, #2cff80);
  display: flex;
  width: 116px;
  padding: 6px 8px;
  justify-content: center;
  align-items: center;
  left: -114px;
  top: -12px;

  font-weight: 400;
  color: var(--desktop-solid-black, #000);
`

interface WinProps {
  $isWin: boolean
}

const StyledScore = styled(Header1)<WinProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ $isWin }) => ($isWin ? '#ffcd29 ' : 'rgba(255, 255, 255, 0.2)')};
  text-align: center;
  flex-direction: column;
  line-height: 100%;
  text-transform: uppercase;
  margin: 0 auto;
`

interface StyledTeamNameProps {
  $isWin: boolean
}

const StyledTeamName = styled(SideHeader1)<StyledTeamNameProps>`
  display: flex;
  writing-mode: vertical-rl;
  color: ${({ $isWin }) => ($isWin ? 'white' : 'rgba(255, 255, 255, 0.3)')};
  transform: rotate(180deg);
  justify-content: center;
  line-height: 90%;
`

export default WinnerLeftPart
