import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

export const Header1 = styled.div`
  font-family: 'Ruberoid';
  font-size: 68px;
  font-weight: bold;
`
export const Header2 = styled.div`
  font-family: 'Ruberoid';
  font-size: 42px;
  font-weight: bold;
`

export const Header3 = styled.div`
  font-family: 'Ruberoid';
  font-size: 26px;
  font-weight: bold;
`

export const Header4 = styled.div`
  font-family: 'Ruberoid';
  font-size: 21px;
  font-weight: bold;
`

export const Header5 = styled.div`
  font-family: 'Ruberoid';
  font-size: 16px;
  font-weight: bold;
`

export const Header6 = styled.div`
  font-family: 'Ruberoid';
  font-size: 12px;
  font-weight: bold;
`

export const Header7 = styled.div`
  font-family: 'Ruberoid';
  font-size: 12px;
  font-weight: bold;
`

export const Header8 = styled.div`
  font-family: 'Ruberoid';
  font-size: 12px;
  font-weight: 400;
`

export const Header9 = styled.div`
  font-family: 'Ruberoid';
  font-size: 10px;
  font-weight: bold;
`

export const Text3 = styled.div`
  font-family: 'Montserrat';
  font-size: 26px;
`

export const Text4 = styled.div`
  font-family: 'Montserrat';
  font-size: 16px;
`

export const Text5 = styled.div`
  font-family: 'Montserrat';
  line-height: normal;
  font-size: 12px;
`

export const Text6 = styled.div`
  font-family: 'Montserrat';
  font-size: 10px;
`

export const SideHeader1 = styled.div`
  font-family: 'Nebula', 'Nebulosa';
  font-size: 32px;
`

export const SideHeader2 = styled.div`
  font-family: 'Nebula', 'Nebulosa';
  font-size: 24px;
`
export const SideHeader3 = styled.div`
  font-family: 'Nebula', 'Nebulosa';
  font-size: 16px;
`

export const SideHeader4 = styled.div`
  font-family: 'Nebula', 'Nebulosa';
  font-size: 10px;
  line-height: normal;
`

export const SideHeader5 = styled.div`
  font-family: 'Nebula', 'Nebulosa';
  font-size: 8px;
`

export const Link = styled(NavLink)`
  color: #2cff80;
  transition: 0.2s;

  &:hover {
    color: rgba(44, 255, 128, 0.3);
  }
`

export const TextLink = styled.span`
  color: #2cff80;
  transition: 0.2s;

  cursor: pointer;

  &:hover {
    color: rgba(44, 255, 128, 0.3);
  }
`

export const FormError = styled(Text6)`
  color: #ff4444;
  width: max-content;
  font-weight: 500;
`
