import {
  $appeals,
  $currentAppealID,
  $isCreatingAppeal,
  chooseAppeal,
  getAppeals,
  handleCreateNewAppeal,
} from 'stores/chatStores/appealStore'
import { Header9 } from 'styles/typography'
import { useEffect } from 'react'
import { useStore } from '@nanostores/react'
import { useTranslation } from 'react-i18next'
import AddApeal from 'images/chat/AddAppeal.svg'
import Loader from 'images/chat/Loader'
import styled from 'styled-components'

const StyledSupportPanel = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 162px;
  height: auto;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
`

const StyledCreateAppeal = styled(Header9)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  width: 100%;
  min-height: 32px;
  border-radius: 3px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: rgba(255, 255, 255, 0.03);
  backdrop-filter: blur(7.5px);
  text-transform: uppercase;
  cursor: pointer;
`
const StyledAddImage = styled.div`
  width: 24px;
  height: 24px;
  background-image: url(${AddApeal});
`
const StyledAppeals = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  gap: 5px;
  height: auto;
`
const StyledNewAppeal = styled(Header9)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: black;
  width: 100%;
  height: 39px;
  padding: 5px;
  gap: 9px;
  font-weight: bold;
  border-radius: 3px;
  border: 1px solid rgba(255, 255, 255, 0.15);
  background: #ffcd29;
  text-transform: uppercase;
  cursor: pointer;
`
interface OldAppealProps {
  $isActive?: boolean
  $newMessage?: boolean
}

const StyledOldAppeal = styled(Header9)<OldAppealProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${({ $isActive }) => ($isActive ? 'black' : 'white')};
  width: 100%;
  height: 39px;
  padding: 5px;
  border-radius: 3px;
  border: 1px solid rgba(255, 255, 255, 0.15);
  background: ${({ $isActive }) => ($isActive ? '#ffcd29' : 'unset')};
  text-transform: uppercase;
  cursor: pointer;

  &:after {
    display: ${({ $isActive, $newMessage }) =>
      !$isActive && $newMessage ? 'block' : 'none'};
    content: '';
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background-color: #2cff80;
    margin-right: 5px;
  }
`

const SupportLeftPanel: React.FC = () => {
  const { t } = useTranslation()
  const isCreatingAppeal = useStore($isCreatingAppeal)
  const currentAppealID = useStore($currentAppealID)
  const appeals = useStore($appeals)

  const handleChooseAppeal = (id?: string) => async () => {
    await chooseAppeal(id)
  }

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    getAppeals()
  }, [])

  const appealsList = appeals?.map((item, i) => {
    return (
      <StyledOldAppeal
        key={item.id}
        onClick={handleChooseAppeal(item.id)}
        $isActive={item.id === currentAppealID}
      >
        {t('chat.appeal.appeal')} {i + 1}
      </StyledOldAppeal>
    )
  })

  return (
    <StyledSupportPanel id="appealsScroll">
      <StyledCreateAppeal onClick={() => handleCreateNewAppeal()}>
        {t('chat.appeal.addAppeal')}
        <StyledAddImage />
      </StyledCreateAppeal>
      <StyledAppeals>
        {isCreatingAppeal && (
          <StyledNewAppeal>
            {t('chat.appeal.addAppeal')}
            <Loader />
          </StyledNewAppeal>
        )}
        {appealsList}
      </StyledAppeals>
    </StyledSupportPanel>
  )
}

export default SupportLeftPanel
