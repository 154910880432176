import { Header6 } from 'styles/typography'
import { setModalType } from 'stores/appStore'
import Close from 'images/Close'
import styled from 'styled-components'

interface ModalHeaderProps {
  title: string
}

const ModalHeader = ({ title }: ModalHeaderProps) => (
  <StyledHeader>
    <StyledTitle>{title}</StyledTitle>
    <StyledClose onClick={() => setModalType(null)}>
      <Close />
    </StyledClose>
  </StyledHeader>
)

const StyledClose = styled.div`
  cursor: pointer;
`

const StyledHeader = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
`

const StyledTitle = styled(Header6)`
  opacity: 0.2;
  text-transform: uppercase;
`

export default ModalHeader
