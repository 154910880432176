import avatar_test from 'images/Avatar_test.png'
import styled from 'styled-components'

interface StyledLobbyAvatar {
  src?: string
  width?: number
  height?: number
}

const StyledLobbyAvatar = styled.div<StyledLobbyAvatar>`
  height: ${({ width }) => `${width || '50'}px`};
  min-width: ${({ height }) => `${height || '50'}px`};
  background-image: ${({ src }) => `url('${src || avatar_test}')`};
  position: relative;
  background-size: cover;
`

interface StyledFlagAvatar {
  src?: string
}

const StyledFlag = styled.div<StyledFlagAvatar>`
  background-image: ${({ src }) => `url('${src || null}')`};
  background-repeat: no-repeat;
  height: 9px;
  width: 13px;
  position: absolute;
  bottom: 0px;
`

interface AvatarWithFlagProps {
  width?: number
  height?: number
  flagSrc?: string
  avatarSrc?: string
}

const AvatarWithFlag: React.FC<AvatarWithFlagProps> = ({
  width,
  height,
  flagSrc,
  avatarSrc,
}) => {
  return (
    <StyledLobbyAvatar width={width} height={height} src={avatarSrc}>
      <StyledFlag src={flagSrc} />
    </StyledLobbyAvatar>
  )
}

export default AvatarWithFlag
