import { $appealMessages, $currentAppeal } from 'stores/chatStores/appealStore'
import { ChangeEvent, useState } from 'react'
import { DtoAttachmentCreate } from 'api/schemas/chatApi'
import { Text6 } from 'styles/typography'
import { css, styled } from 'styled-components'
import { sendMessage } from 'stores/chatStores/chatStore'
import { useStore } from '@nanostores/react'
import { useTranslation } from 'react-i18next'
import AddFile from 'images/chat/AddFile'
import DeleteFileIcon from 'images/chat/DeleteFileIcon.svg'
import EmptyFileImg from 'images/chat/EmptyFileImg.svg'
import Input from 'components/Input'
import Modal from 'components/Modal'
import api from 'api/api'

const StyledInputIcons = styled.div`
  display: flex;
  gap: 6px;
`

const StyledFileInput = styled.input`
  display: none;
`

const StyledFileLabel = styled.label`
  cursor: pointer;
`

interface StyledInputProps {
  $hasFiles: boolean
}

const StyledInput = styled(Input)<StyledInputProps>`
  ${({ $hasFiles }) =>
    $hasFiles &&
    css`
      & #InputWrapper {
        border-radius: 0px 0px 16px 16px;
        border: 1px solid rgba(255, 255, 255, 0.15);
        background: #fff;
      }
    `}
`

const StyledImagesWrapper = styled.div`
  display: flex;
  align-items: center;
  width: -webkit-fill-available;
  gap: 10px;

  padding: 12px;
  object-fit: contain;
  border-radius: 3px 3px 0px 0px;
  border-top: 1px solid rgba(255, 255, 255, 0.15);
  border-right: 1px solid rgba(255, 255, 255, 0.15);
  border-left: 1px solid rgba(255, 255, 255, 0.15);
  background: rgba(255, 255, 255, 0.05);
`
interface IStyledImg {
  src: string
}
const StyledImage = styled.div<IStyledImg>`
  display: flex;
  width: 75px;
  height: 75px;
  cursor: pointer;
  background-image: ${({ src }) => `url('${src || null}')`};
  background-size: cover;
  background-position: center;
`

const StyledInputWrapper = styled.div`
  position: relative;
  width: 100%;
`

const StyledBigFile = styled.img`
  width: 543px;
  height: 543px;

  margin-top: 20px;
  border: 1px solid rgba(255, 255, 255, 0.15);
  object-fit: contain;

  cursor: pointer;

  object-fit: contain;
`
const StyledDeleteFileButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  cursor: pointer;
`
const StyledDeleteFileIcon = styled.div<IStyledImg>`
  width: 24px;
  height: 3px;
  background-image: ${({ src }) => `url('${src || null}')`};
  background-size: cover;
  background-position: center;
`

const StyledError = styled(Text6)`
  color: #f44;
`

interface SupportChatInputProps {
  disabled?: boolean
}

const SupportChatInput: React.FC<SupportChatInputProps> = ({ disabled }) => {
  const { t } = useTranslation()
  const appealMessages = useStore($appealMessages)
  const currentAppeal = useStore($currentAppeal)

  const [files, setFiles] = useState<FileList | null>(null)
  const [loadedFiles, setLoadedFiles] = useState<DtoAttachmentCreate[]>([])
  const [modalImg, setModalImg] = useState<DtoAttachmentCreate | null>(null)
  const [inputValue, setInputValue] = useState('')
  const [error, setError] = useState('')

  const loadFiles = (filesToLoad: FileList | null) => {
    if (!filesToLoad) {
      setLoadedFiles([])
      return
    }
    const iter = (filesToLoad: FileList, index: number | undefined = 0) => {
      void api.claim
        .postAttachment(currentAppeal?.id || '', {
          file: filesToLoad[index],
        })
        .then(({ data }) => {
          setLoadedFiles((prevState) => [
            ...prevState,
            {
              attachmentId: data.attachmentId,
              attachmentUrl: data.attachmentUrl,
            },
          ])
          if (filesToLoad.length > index + 1) {
            iter(filesToLoad, index + 1)
          }
        })
    }
    iter(filesToLoad)
  }

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files
    setError('')

    if (!files || !files.length) return
    if (files.length > 10) {
      setError(t('chat.appeal.maxFilesLength'))
      return
    }
    try {
      setFiles(files)
      setLoadedFiles([])
      loadFiles(files)
    } catch (e) {
      setError(t('chat.appeal.filesError'))
    }
  }

  const handleClearFiles = () => {
    setFiles(null)
    loadFiles(null)
  }

  const handleModalOpen = (img: DtoAttachmentCreate) => () => {
    setModalImg(img)
  }
  const handleModalClose = () => setModalImg(null)

  const onSendMessage = async () => {
    if (
      inputValue.trim().length &&
      appealMessages?.id &&
      (!files || files.length === loadedFiles.length)
    ) {
      await sendMessage(appealMessages.id, inputValue.trim(), loadedFiles)
    }
    setInputValue('')
    handleClearFiles()
  }

  const handleKeyDown: React.KeyboardEventHandler<HTMLInputElement> = async (
    e
  ) => {
    if (e.key === 'Enter') {
      await onSendMessage()
    }
  }

  return (
    <StyledInputWrapper>
      {files?.length && (
        <StyledImagesWrapper>
          {Object.values(loadedFiles).map((file) => (
            <StyledImage
              key={file.attachmentId}
              src={file.attachmentUrl || ''}
              onClick={handleModalOpen(file)}
            />
          ))}
          {files.length !== loadedFiles.length && (
            <StyledImage src={EmptyFileImg} />
          )}
          <StyledDeleteFileButton onClick={handleClearFiles}>
            <StyledDeleteFileIcon src={DeleteFileIcon} />
          </StyledDeleteFileButton>
        </StyledImagesWrapper>
      )}
      {error && <StyledError>{error}</StyledError>}
      <StyledInput
        value={inputValue}
        onKeyDown={handleKeyDown}
        disabled={!appealMessages?.id || disabled}
        placeholder={t('chat.message')}
        $hasFiles={Boolean(files?.length)}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          setInputValue(e.target.value)
        }
        rightDecorator={
          !!appealMessages?.id && (
            <StyledInputIcons>
              <StyledFileLabel>
                <AddFile />
                <StyledFileInput
                  type="file"
                  multiple={true}
                  onChange={handleFileChange}
                  accept=".png,.jpg,.jpeg,.webp"
                />
              </StyledFileLabel>
            </StyledInputIcons>
          )
        }
      />
      <Modal isOpen={Boolean(modalImg)} onClose={handleModalClose}>
        <StyledBigFile
          key={modalImg?.attachmentId}
          src={modalImg?.attachmentUrl}
        />
      </Modal>
    </StyledInputWrapper>
  )
}

export default SupportChatInput
