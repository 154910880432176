import { Header1, Header3, Text4, Text5 } from 'styles/typography'
import { useTranslation } from 'react-i18next'
import JudgeTicketTimerTexture from 'images/judge/JudgeTicketTimerTexture.svg'
import styled from 'styled-components'

const Timer = () => {
  const { t } = useTranslation()
  return (
    <StyledTimerWrapper>
      <TimerHeaderWrapper>
        <TimerContent>
          <StyledTimerFiller>
            <span>+</span>
            <span>+</span>
          </StyledTimerFiller>
          <TimerTitle> {t('judge.voteModal.timer')}</TimerTitle>
          <TimerDigits>
            <StyledDigitsWrapper>
              <StyledTimeDigits>
                <StyledDigitActive>
                  <StyledGrayDescription>0</StyledGrayDescription>
                  <StyledDigit>5</StyledDigit>
                </StyledDigitActive>
              </StyledTimeDigits>
              <StyledGrayDescription>
                {t('judge.voteModal.minutesFull')}
              </StyledGrayDescription>
            </StyledDigitsWrapper>
            <StyledLine />
            <StyledDigitsWrapper>
              <StyledTimeDigits>
                <StyledDigitActive>
                  <StyledGrayDescription></StyledGrayDescription>
                  <StyledDigit>50</StyledDigit>
                </StyledDigitActive>
              </StyledTimeDigits>
              <StyledGrayDescription>
                {t('judge.voteModal.secundes')}
              </StyledGrayDescription>
            </StyledDigitsWrapper>
          </TimerDigits>
        </TimerContent>
      </TimerHeaderWrapper>
      <TimerFooterWrapper>
        <TimerDescription>
          {t('judge.voteModal.timerEnableDecision')}
        </TimerDescription>
        <StyledTimerFiller>
          <span>+</span>
          <span>+</span>
        </StyledTimerFiller>
      </TimerFooterWrapper>
    </StyledTimerWrapper>
  )
}
export default Timer

const StyledTimerWrapper = styled.div`
  display: flex;
  padding: 18px 24px 18px 24px;
  width: 300px;
  border: 1px solid #ffffff26;
  border-radius: 3px;
  flex-direction: column;
  justify-content: space-between;
  background: url(${JudgeTicketTimerTexture});
  background-position-y: bottom;
  background-repeat: no-repeat;
`

const TimerContent = styled.div`
  display: flex;
  flex-direction: column;
`

const TimerDescription = styled(Text4)`
  font-weight: 500;
  line-height: 20px;
  color: #ffffff26;
  margin: 0px 0px 12px 0px;
`
const StyledTimerFiller = styled.div`
  color: #ffcd29;
  display: flex;
  justify-content: space-between;
  width: 100%;
`
const TimerDigits = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
`
const TimerTitle = styled(Header3)`
  margin: 10px 0px 0px 0px;
  font-weight: 400;
`
const StyledDigitsWrapper = styled(Text5)`
  padding: 19px 0px 0px 0px;
  display: flex;
  flex-direction: column;
  line-height: 60px;
`
const StyledLine = styled.div`
  border-right: 1px solid #ffffff26;
  width: 1px;
  height: 47px;
`
const StyledGrayDescription = styled.div`
  color: #ffffff26;
  line-height: 13px;
`
const StyledTimeDigits = styled(Header1)`
  display: flex;
  font-weight: 400;
`
const StyledDigitActive = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
`
const StyledDigit = styled.div`
  display: flex;
`
const TimerHeaderWrapper = styled.div``
const TimerFooterWrapper = styled.div``
