const People = ({ className }: { className?: string }) => (
  <svg
    width="27"
    height="27"
    viewBox="0 0 27 27"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.2437 12.5111C15.0692 12.5111 16.549 11.0313 16.549 9.20577C16.549 7.38026 15.0692 5.90039 13.2437 5.90039C11.4181 5.90039 9.93828 7.38026 9.93828 9.20577C9.93828 11.0313 11.4181 12.5111 13.2437 12.5111ZM17.6583 15.8701C18.8292 17.041 19.487 18.629 19.487 20.2849H13.2435L7 20.2849C7 18.629 7.65779 17.041 8.82868 15.8701C9.99956 14.6992 11.5876 14.0414 13.2435 14.0414C14.8994 14.0414 16.4874 14.6992 17.6583 15.8701Z"
    />
  </svg>
)

export default People
