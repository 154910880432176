import {
  $userStatisticPageGameFormat,
  GameFormat,
  getMatches,
} from 'stores/statisticStore'
import { Header3 } from 'styles/typography'
import { Outlet } from 'react-router-dom'
import { useStore } from '@nanostores/react'
import { useTranslation } from 'react-i18next'
import TabsSwitcher from 'components/TabsSwitcher'
import styled from 'styled-components'

const StatisticLayout: React.FC = () => {
  const { t } = useTranslation()
  const gameFormat = useStore($userStatisticPageGameFormat)

  const handleChooseGameFormat = async (format: GameFormat) => {
    switch (format) {
      case 'ONE_TO_ONE':
        await getMatches('ONE_TO_ONE')
        return $userStatisticPageGameFormat.set('ONE_TO_ONE')
      case 'CUSTOM':
        await getMatches('CUSTOM')
        return $userStatisticPageGameFormat.set('CUSTOM')
      case 'FIVE_TO_FIVE':
        await getMatches('FIVE_TO_FIVE')
        return $userStatisticPageGameFormat.set('FIVE_TO_FIVE')
    }
  }

  return (
    <StyledWrapper>
      <StyledPlayHeader>
        <StyledTabsWrapper>
          <TabsSwitcher
            defaultActiveKey={gameFormat}
            onChange={async (key: string) => {
              await handleChooseGameFormat(
                key as 'FIVE_TO_FIVE' | 'ONE_TO_ONE' | 'CUSTOM'
              )
            }}
            items={[
              {
                label: '5x5',
                key: 'FIVE_TO_FIVE',
              },
              {
                label: '1x1',
                key: 'ONE_TO_ONE',
              },
              {
                key: 'CUSTOM',
                label: t('play.tabs.custom'),
              },
            ]}
          />
        </StyledTabsWrapper>
      </StyledPlayHeader>
      <Outlet />
    </StyledWrapper>
  )
}

export default StatisticLayout

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  height: 100%;

  padding-bottom: 28px;
`

const StyledPlayHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
`

const StyledTabsWrapper = styled(Header3)`
  display: flex;
  align-items: center;
  gap: 22px;
  margin-top: 32px;
  z-index: 10;
`
