import {
  DtoAttachment,
  DtoJudgeClaim,
  DtoUpdateJudgeClaimPayload,
  EnumCreateJudgeClaimStatus,
} from 'api/schemas/supportApi'
import { DtoClaimPreview, DtoRewardInfo } from 'api/schemas/judgeApi'
import { atom } from 'nanostores'
import api from 'api/api'

export const $currentJudgeClaim = atom<DtoJudgeClaim | null>(null)
export const $reportPopupId = atom<string | null>(null)
export const $myFinishedClaims = atom<DtoClaimPreview[]>([])
export const $currentWorkClaim = atom<DtoClaimPreview | null>(null)
export const $myTickets = atom<DtoClaimPreview[]>([])
export const $currentRewardInfo = atom<DtoRewardInfo | null>(null)

export const setReportPopupId = (id: string | null) => {
  $reportPopupId.set(id)
}

export const getJudgeClaimCurrent = async () => {
  try {
    const data = await api.judge.getClaimCurrent()

    if (data) {
      $currentJudgeClaim.set(data.data)
    }
  } catch (error) {
    console.log('getJudgeClaimCurrent', error)
  }
}

export const createJudgeClaim = async () => {
  try {
    const data = await api.judge.postClaim()

    if (data) {
      // TODO временное решение по просьбе бэка, так как они пока не возвращают claim в ответе postClaim
      // https://bytec.atlassian.net/browse/FTF-1346
      await getJudgeClaimCurrent()
    }
  } catch (error) {
    console.log('createJudgeClaim', error)
  }
}

export const addFileToClaim = async (claimId: string, file: File) => {
  try {
    const data = await api.judge.postClaimAttachment(claimId, { file })

    if (data) {
      const newAttachment = data.data as DtoAttachment

      const current = $currentJudgeClaim.get()
      $currentJudgeClaim.set({
        ...current!,
        attachments: [...current!.attachments!, newAttachment],
      })
    }
  } catch (error) {
    console.log('addFileToClaim', error)
  }
}

export const removeFileFromClaim = (
  claimId: string,
  attachment: DtoAttachment
) => {
  const claim = $currentJudgeClaim.get()

  if (!claim) return

  // soft remove
  $currentJudgeClaim.set({
    ...claim,
    attachments: claim.attachments!.filter((a) => a !== attachment),
  })

  api.judge
    .deleteClaimAttachment(claimId, attachment.attachmentId!)
    .catch((e) => {
      // revert remove
      const current = $currentJudgeClaim.get()
      $currentJudgeClaim.set({
        ...current!,
        attachments: [...current!.attachments!, attachment],
      })
      console.log('removeFileFromClaim', e)
    })
}

export const submitJudgeClaim = async (
  claimId: string,
  payload: DtoUpdateJudgeClaimPayload
) => {
  try {
    await api.judge.putClaim({
      claimId,
      payload,
      status: EnumCreateJudgeClaimStatus.CreateJudgeClaimStatusCompleted,
    })

    // TODO временное решение по просьбе бэка, так как они пока не возвращают claim в ответе putClaim
    // https://bytec.atlassian.net/browse/FTF-1346
    await getJudgeClaimCurrent()
  } catch (error) {
    console.log('submitJudgeClaim', error)
    throw new Error()
  }
}

export const markDeclinedJudgeClaimAsViewed = async (claimId: string) => {
  try {
    await api.judge.putClaimView(claimId)

    const current = $currentJudgeClaim.get()
    $currentJudgeClaim.set({
      ...current!,
      declinedAtViewed: new Date().toISOString(),
    })
  } catch (error) {
    console.log('markDeclinedJudgeClaimAsViewed', error)
  }
}

export const getMyFinishedClaims = async () => {
  try {
    const { data } = await api.judgeAPI.claim.getDayFinished()

    if (data) {
      $myFinishedClaims.set(data)
    }
  } catch (error) {
    console.log('getMyFinishedClaims', error)
  }
}

export const getMyTickets = async () => {
  try {
    const { data } = await api.judgeAPI.claim.getClaim2()

    if (data) {
      $myTickets.set(data)
    }
  } catch (error) {
    console.log('getMyTickets', error)
  }
}

export const getCurrentWorkClaim = async () => {
  try {
    const { data } = await api.judgeAPI.claim.getCurrent()

    if (data) {
      $currentWorkClaim.set(data)
    }
  } catch (error) {
    console.log('getCurrentWorkClaim', error)
  }
}

export const getNextWorkClaim = async () => {
  try {
    const { data } = await api.judgeAPI.claim.postTakeToWork()

    if (data) {
      $currentWorkClaim.set(data)
    }

    return data
  } catch (error) {
    console.log('getNextWorkClaim', error)
  }
}

export const getRewardInfo = async () => {
  try {
    const { data } = await api.judgeAPI.reward.getInfo()

    if (data) {
      $currentRewardInfo.set(data)
    }
  } catch (error) {
    console.log('getRewardInfo', error)
  }
}
