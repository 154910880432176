import { $onlineChart } from 'stores/statisticStore'
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
} from 'recharts'
import { useStore } from '@nanostores/react'
import CustomTooltip from 'components/Home/ChartComponents/CustomTooltip'
import CustomizedDot from 'components/Home/ChartComponents/CustomizedDot'
import CustomizedX from 'components/Home/ChartComponents/CustomizedX'
import styled from 'styled-components'

const StyledWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 140px;
  gap: 4px;
`
const StyledTexts = styled.div`
  display: flex;
  writing-mode: vertical-lr;
  transform: scale(-1, -1);
  justify-content: space-between;
`
const StyledText = styled.div`
  font-family: 'Nebula', 'Nebulosa';
  font-size: 8px;
  font-weight: 400;
  line-height: 90%;
  color: white;
`
const StyledChartWrapper = styled.div`
  width: 100%;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 3px;
  border: 1px solid rgba(255, 255, 255, 0.15);
  background: rgba(29, 30, 34, 0.3);
`

const OnlineChart: React.FC = () => {
  const data = useStore($onlineChart)

  return (
    <StyledWrapper>
      <StyledTexts>
        <StyledText>TIME</StyledText>
        <StyledText>ONLINE</StyledText>
      </StyledTexts>
      <StyledChartWrapper>
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            data={data || undefined}
            margin={{
              top: 5,
              left: 20,
              right: 20,
              bottom: 0,
            }}
          >
            <CartesianGrid
              vertical={false}
              stroke="rgba(255, 255, 255, 0.05)"
            />
            <Tooltip cursor={false} content={<CustomTooltip />} />
            <XAxis
              dataKey="time"
              axisLine={false}
              tickLine={false}
              tick={<CustomizedX />}
            />
            <Line
              type="linear"
              stroke="#FFCD29"
              activeDot={false}
              dataKey="onlineCount"
              dot={<CustomizedDot />}
            />
          </LineChart>
        </ResponsiveContainer>
      </StyledChartWrapper>
    </StyledWrapper>
  )
}

export default OnlineChart
