import { css } from 'styled-components'

export const defaultTableGridStyles = css`
  display: grid;
  grid-template-columns: 80px 120px 275px 240px 200px 160px 160px 80px;
  grid-gap: 36px;
  align-items: center;
`

export const tableGridStylesWithTimer = css`
  display: grid;
  grid-template-columns: 54px 54px 56px 275px 240px 200px 160px 160px 80px;
  grid-gap: 36px;
  align-items: center;
`
