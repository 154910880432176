import { styled } from 'styled-components'
import BombWinIcon from 'images/BombWinIcon'
import ClearMineIcon from 'images/ClearMineIcon'
import HostagesIcon from 'images/HostagesIcon'
import MatchTimoutIcon from 'images/MatchTimoutIcon'
import React from 'react'
import RogerIcon from 'images/RogerIcon'

const StyledRoot = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -4px;
`

interface StyledMatchIconProps {
  $isWin: boolean
}

const StyledMatchIcon = styled.div<StyledMatchIconProps>`
  width: 24px;
  fill: ${({ $isWin }) => ($isWin ? 'white' : 'rgba(255, 255, 255, 0.3)')};
  & > svg {
    width: 24px;
    height: 24px;
  }
`

const getTypeOfWinIcon = (
  type:
    | 'Elimination'
    | 'HostagesRescued'
    | 'BombDefused'
    | 'BombExploded'
    | 'HostagesNotRescued'
    | ''
    | null
) => {
  switch (type) {
    case 'BombExploded':
      return <BombWinIcon />
    case 'BombDefused':
      return <ClearMineIcon />
    case 'HostagesRescued':
    case 'HostagesNotRescued':
      return <HostagesIcon />
    case 'Elimination':
    case '':
      return <RogerIcon />
    default:
      return <MatchTimoutIcon />
  }
}

interface MatchItemProps {
  round: number
  result: 'lose' | 'win' | 'drow'
  typeOfWin:
    | 'Elimination'
    | 'HostagesRescued'
    | 'HostagesNotRescued'
    | 'BombDefused'
    | 'BombExploded'
    | null
}

const MatchItem: React.FC<MatchItemProps> = ({ result, typeOfWin }) => {
  return (
    <StyledRoot>
      <StyledMatchIcon $isWin={result === 'win'}>
        {getTypeOfWinIcon(typeOfWin)}
      </StyledMatchIcon>
    </StyledRoot>
  )
}

export default MatchItem
