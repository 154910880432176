// import { Tooltip } from 'react-tooltip'

import { Text6 } from 'styles/typography'
import React, { MutableRefObject } from 'react'
import styled, { css } from 'styled-components'

const StyledLabel = styled.label`
  display: flex;
  justify-content: space-between;
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 16px;

  color: #ffcd29;

  margin-bottom: 12px;
`

interface StyledInputWrapperProps {
  $isError?: boolean
  $hideBorder?: boolean
}

const StyledInputWrapper = styled.div<StyledInputWrapperProps>`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  background-color: transparent;
  outline: none;

  border: ${({ $hideBorder }) =>
    `${$hideBorder ? 'unset' : '1px solid rgba(255, 255, 255, 0.15)'}`};
  border-radius: 3px;

  font-family: 'Montserrat';
  font-size: 16px;

  transition: 0.2s;

  &:focus {
    ${({ $hideBorder }) =>
      `${$hideBorder ? 'unset' : 'border: 1px solid #ffcd29'}`};
  }
  &:focus-within {
    border: ${({ $hideBorder }) =>
      `${$hideBorder ? 'unset' : '1px solid #ffcd29'}`};
  }

  ${({ $isError }) =>
    $isError &&
    css`
      border: 1px solid #ff4444;
    `}
`

const StyledInput = styled.input`
  width: 100%;
  height: auto;
  background-color: transparent;
  outline: none;
  padding: 9px 12px;

  &::-webkit-input-placeholder {
    color: rgba(255, 255, 255, 0.25);
  }
`

const StyledRightDecorator = styled.button`
  padding-right: 13px;
  cursor: pointer;
`

const StyledWrapper = styled.div`
  position: relative;
`

const StyledError = styled(Text6)`
  color: #ff4444;
  position: absolute;
  bottom: 15px;
  left: calc(100% + 11px);
  width: max-content;
  font-weight: 500;
`

type View = 'default' | 'white'

interface InputProps {
  view?: View
  tooltip?: string
  hideBorder?: boolean
  label?: string | null
  error?: string | null
  secondLabel?: React.ReactNode
  leftDecorator?: React.ReactNode
  rightDecorator?: React.ReactNode
  onClickLeftDeacorator?: () => void
  onClickRightDeacorator?: () => void
}

const Input = React.forwardRef(
  (
    {
      id,
      label,
      error,
      // tooltip,
      className,
      hideBorder,
      secondLabel,
      // leftDecorator,
      rightDecorator,
      // view = 'default',
      // onClickLeftDeacorator,
      onClickRightDeacorator,
      ...rest
    }: InputProps & React.InputHTMLAttributes<HTMLInputElement>,

    _ref
  ) => {
    return (
      <StyledWrapper className={className}>
        {label && (
          <StyledLabel htmlFor={id}>
            <div>{label}</div>
            {/* {tooltip && (
            <>
              <div id="info">i</div>
              <Tooltip
                className={tooltipBox}
                classNameArrow={tooltipArrow}
                anchorId="info"
                content={tooltip}
                wrapper={'span'}
                place="top"
              />
            </>
          )} */}
            {secondLabel}
          </StyledLabel>
        )}
        <StyledInputWrapper $hideBorder={hideBorder} $isError={Boolean(error)}>
          {/* {leftDecorator && (
            <div
              className={`${leftDecoratorStyles} w-[45px] max-w-[45px]`}
              onClick={onClickLeftDeacorator}
            >
              {leftDecorator}
            </div>
          )} */}
          <StyledInput
            id={id}
            ref={_ref as MutableRefObject<HTMLInputElement>}
            {...rest}
          />
          <StyledRightDecorator type="button" onClick={onClickRightDeacorator}>
            {rightDecorator}
          </StyledRightDecorator>
        </StyledInputWrapper>

        {error && <StyledError>{error}</StyledError>}
      </StyledWrapper>
    )
  }
)

export default Input
