import { Header5, Header9 } from 'styles/typography'
import { RankTypes } from 'stores/statisticStore'
import { getMainPageRank } from 'utils/rankGetters'
import { useTranslation } from 'react-i18next'
import NickTrimmer from 'components/NickTrimmer'
import WithUserMenu from 'components/ContextMenu/WithUserMenu'
import avatar from 'images/Avatar_test.png'
import star from 'images/personRank/star.svg'
import styled, { css } from 'styled-components'

interface Rank {
  $rank?: string
  $isYou?: boolean
}

const StyledWrapper = styled.div<Rank>`
  display: flex;
  align-items: center;
  padding: 10px 7px;
  position: relative;
  width: 195px;
  height: 44px;
  border-radius: 0px 6px;
  border: 1px solid rgba(255, 255, 255, 0.15);
  background: ${({ $isYou }) =>
    $isYou ? 'rgba(238, 157, 62, 0.15)' : 'rgba(255, 255, 255, 0.03)'};

  &:before {
    display: block;
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    background-image: url(${({ $rank }) => ($rank ? $rank : 'unset')});
    background-position: -1px -1px;
    background-repeat: no-repeat;
    width: 195px;
    height: 44px;
    z-index: -1;
  }
`
interface IsTopProps {
  $isTop: boolean
}
const StyledPosition = styled(Header5)<IsTopProps>`
  font-weight: 400;
  line-height: 100%; /* 16px */
  text-transform: uppercase;
  color: ${({ $isTop }) => ($isTop ? '#2CFF80' : 'white')};
  margin-right: 3px;
  width: 24px;
`
const StyledImageWrapper = styled.div`
  display: flex;
  align-items: center;
  min-width: 24px;
  height: 24px;
  position: relative;
  margin-right: 8px;
`
const StyledIsYouText = styled.div`
  display: flex;
  justify-content: center;
  font-size: 8px;
  line-height: 100%;
  font-weight: 600;
  font-family: Ruberoid;
  color: black;
  padding: 1px 2px;
  background-color: #f2b927;
  position: absolute;
  bottom: -13px;
  width: 100%;

  &:before {
    display: block;
    content: '';
    width: 4px;
    height: 4px;
    background-color: #f2b927;
    position: absolute;
    top: -2px;
    transform: rotate(45deg);
    z-index: -1;
  }
`
interface StyledImage {
  $isTop: boolean
  $imgUrl?: string
}
const StyledImage = styled.div<StyledImage>`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  position: relative;
  background-image: url(${({ $imgUrl }) => ($imgUrl ? $imgUrl : avatar)});
  background-size: cover;

  &:after {
    ${({ $isTop }) =>
      $isTop
        ? css`
            display: block;
            content: '';
            width: 16px;
            height: 16px;
            position: absolute;
            background-image: url(${star});
            right: -8px;
          `
        : ''};
  }
`
const StyledName = styled(Header9)`
  color: white;
  font-weight: 400;
  line-height: 115.5%;
  width: 80px;
`
const StyledScore = styled(Header9)`
  color: white;
  font-weight: 400;
  line-height: 115.5%;
  margin: 0 10px 0 auto;
`

interface Person {
  id: string
  nick: string
  isTop: boolean
  isYou?: boolean
  isAll?: boolean
  imgUrl?: string
  rank?: RankTypes
  score: string | number
  position: string | number
}

const StandingPerson: React.FC<Person> = ({
  id,
  nick,
  rank,
  isYou,
  isTop,
  score,
  imgUrl,
  position,
}) => {
  const { t } = useTranslation()
  const profile = {
    id,
    imgUrl,
    name: nick,
    isChosen: false,
  }

  return (
    <>
      <WithUserMenu profile={{ ...profile }}>
        <StyledWrapper $isYou={isYou} $rank={getMainPageRank(rank)}>
          <StyledPosition $isTop={isTop}>{position}</StyledPosition>
          <StyledImageWrapper>
            <StyledImage $isTop={isTop} $imgUrl={imgUrl} />
            {isYou && <StyledIsYouText>{t('home.you')}</StyledIsYouText>}
          </StyledImageWrapper>
          <StyledName>
            <NickTrimmer>{nick}</NickTrimmer>
          </StyledName>
          <StyledScore>{Math.round(+score || 0)}</StyledScore>
        </StyledWrapper>
      </WithUserMenu>
    </>
  )
}

export default StandingPerson
