import SupportChat from 'components/Chat/Support/SupportChat'
import SupportLeftPanel from 'components/Chat/Support/SupportLeftPanel'
import styled from 'styled-components'

const StyledSupportWrapper = styled.div`
  display: flex;
  gap: 10px;
  padding-right: 20px;
  padding-bottom: 10px;
  max-height: 100%;
  min-height: calc(100% - 40px);
  overflow: hidden;
`

const SupportWrapper: React.FC = () => {
  return (
    <StyledSupportWrapper>
      <SupportLeftPanel />
      <SupportChat />
    </StyledSupportWrapper>
  )
}

export default SupportWrapper
