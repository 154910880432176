import People from 'images/People'
import styled, { css } from 'styled-components'

interface StyledRootProps {
  $isReady: boolean
}

const StyledRoot = styled.div<StyledRootProps>`
  display: flex;

  justify-content: center;
  align-items: center;

  border-radius: 1.6px;
  @keyframes fadeInOut {
    from {
      background: rgba(255, 255, 255, 0.1);
      --child-color: rgba(121, 121, 121, 1);
      & svg {
        fill: rgba(121, 121, 121, 1);
      }
    }

    to {
      background: rgba(44, 255, 128, 0.3);

      --child-color: #d1d1d1;
      & svg {
        fill: #d1d1d1;
      }
    }
  }

  ${({ $isReady }) =>
    $isReady
      ? css`
          border: 0.8px solid rgba(255, 255, 255, 0.15);
          background: #2cff80;
          box-shadow: 0px 0.8px 4.8px 0px rgba(44, 255, 128, 0.4);

          & svg {
            fill: rgba(0, 0, 0, 1);
          }
        `
      : css`
          border: 0.8px solid rgba(255, 255, 255, 0.15);
          background: rgba(255, 255, 255, 0.1);

          animation-duration: 1.5s;
          animation-name: fadeInOut;
          animation-iteration-count: infinite;
          animation-direction: alternate;

          & svg {
            fill: var(--child-color);
          }
        `}

  & svg {
    transition: 0.7s;
  }

  transition: 0.5s;
  width: 48px;
  height: 48px;
`

interface GameFindedUserItemProps {
  isReady: boolean
}

const GameFindedUserItem: React.FC<GameFindedUserItemProps> = ({ isReady }) => {
  return (
    <StyledRoot $isReady={isReady}>
      <People />
    </StyledRoot>
  )
}

export default GameFindedUserItem
