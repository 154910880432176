/* eslint-disable @typescript-eslint/no-explicit-any */
import { setUserProfile } from 'stores/userStore'

const userListiner = async (data: any) => {
  if (data.entityAction === 'UPDATE') {
    await setUserProfile(data.data)
  }
}

export default userListiner
