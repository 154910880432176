import { Text3, Text5 } from 'styles/typography'
import { useTranslation } from 'react-i18next'

import styled from 'styled-components'

const TicketDate = () => {
  const { t } = useTranslation()

  return (
    <>
      <StyledDateContainer>
        <DateDescription>
          <StyledGrayDescription>
            {t('judge.voteModal.applicationDate')}
          </StyledGrayDescription>
        </DateDescription>
        <DateContainer>
          <DateValue>12</DateValue>
          <DateValue>05</DateValue>
          <DateValue>
            <StyledGrayDescription>25</StyledGrayDescription>
          </DateValue>
        </DateContainer>
      </StyledDateContainer>
    </>
  )
}
export default TicketDate

const StyledGrayDescription = styled.div`
  opacity: 0.3;
`
const StyledDateContainer = styled.div`
  border-radius: 3px;
  border: 1px solid #ffffff26;
  height: 100%;
  width: 86px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 5px 187px 5px;
  justify-content: space-between;
`
const DateDescription = styled(Text5)`
  font-weight: 500;
  text-align: center;
`
const DateContainer = styled(Text3)`
  display: flex;
  flex-direction: column;
`
const DateValue = styled.div`
  display: flex;
  justify-content: center;
  padding: 9px 0px 9px 0px;
  &:nth-child(2) {
    border-top: 1px solid #ffffff26;
  }
  &:nth-child(3) {
    border-top: 1px solid #ffffff26;
  }
`
