import { $isAppLoading } from 'stores/appStore'
import { getBalance } from 'stores/walletStore'
import { getChatsFriends } from 'stores/chatStores/singleChat'
import { getFriends } from 'stores/friendsStore'
import { getGroupsChats } from 'stores/chatStores/groupChats'
import { getJudgeClaimCurrent } from 'stores/judgeStore'
import { getLobby } from 'stores/lobbyStore'
import { getNotifications } from 'stores/chatStores/notificationsStore'
import { getParty } from 'stores/partyStore'
import { getUserProfile, syncSteam } from 'stores/userStore'
import fontObservers from 'fonts/observers'
import initSocket from 'sockets'

const mainLoader = async () => {
  try {
    $isAppLoading.set(true)
    await getUserProfile()
    await Promise.all([
      initSocket(undefined, true),
      getFriends(),
      getLobby(),
      getParty(),
      getChatsFriends(),
      getGroupsChats(),
      getNotifications(),
      getJudgeClaimCurrent(),
      getBalance(),
      ...fontObservers,
    ])

    $isAppLoading.set(false)
    void syncSteam()
  } catch (e) {
    console.log(e)
  }

  return true
}

export default mainLoader
