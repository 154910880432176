import { SpinnerCircular } from 'spinners-react'
import { Text4, Text5 } from 'styles/typography'
import { styled } from 'styled-components'
import { t } from 'i18next'
import { toast } from 'react-toastify'

import { useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'

const StyledWrapper = styled.div`
  display: flex;
  gap: 20px;

  align-items: center;
  justify-content: space-between;
`

const StyledForceReconnet = styled(Text5)`
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`

interface ShowReconnection {
  id?: string
}

const ReconnectionPopup = () => {
  const [isShowUpdate, setIsShowUpdate] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setIsShowUpdate(true)
    }, 5000)
  }, [])

  return (
    <StyledWrapper>
      <SpinnerCircular
        size="22"
        thickness={220}
        color="inherit"
        secondaryColor="transparent"
      />
      <Text4>{t('errors.reconnect')}...</Text4>
      <StyledForceReconnet onClick={() => window.location.reload()}>
        {isShowUpdate ? t('errors.tryNow') : ''}
      </StyledForceReconnet>
    </StyledWrapper>
  )
}

const showReconnection = ({ id }: ShowReconnection) => {
  const toastId = id || uuidv4()
  return toast(
    () => {
      return <ReconnectionPopup />
    },
    {
      icon: false,
      draggable: false,
      autoClose: false,
      theme: 'colored',
      toastId: toastId,
      pauseOnHover: true,
      closeButton: false,
      progress: undefined,
      closeOnClick: false,
      hideProgressBar: true,
      position: 'top-center',
    }
  )
}
export default showReconnection
