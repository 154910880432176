import { styled } from 'styled-components'
import Close from 'images/Close'
import StyledModal from 'styled-react-modal'

const StyledWrapper = styled.div`
  position: relative;
`

const StyledClose = styled.div`
  position: absolute;
  top: 24px;
  right: 24px;
  cursor: pointer;

  z-index: 999;
`

interface ModalProps {
  isOpen: boolean
  onClose: () => void
  children: React.ReactNode
}

const Modal: React.FC<ModalProps> = ({ isOpen, onClose, children }) => {
  return (
    <StyledModal
      isOpen={isOpen}
      onEscapeKeydown={onClose}
      onBackgroundClick={onClose}
    >
      <StyledWrapper>
        <StyledClose onClick={onClose}>
          <Close />
        </StyledClose>
        {children}
      </StyledWrapper>
    </StyledModal>
  )
}

export default Modal
