import { Header6, Text5 } from 'styles/typography'
import { ToastContentProps } from 'react-toastify'
import { deletePopup } from 'stores/appStore'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import ButtonCorner from 'images/ButtonCorner'
import FriendAcceptIcon from 'images/FriendAcceptIcon'
import FriendInviteIcon from 'images/FriendInviteIcon'
import LobbyInviteIcon from 'images/LobbyInviteIcon'
import NickTrimmer from 'components/NickTrimmer'
import PartyInviteIcon from 'images/PartyInviteIcon'
import SmallButton from 'components/SmallButton'
import styled from 'styled-components'

interface Author {
  id: string
  name: string
  imgUrl: string
}

const StyledCorner = styled(ButtonCorner)`
  position: absolute;
  bottom: 0;
  right: 0;
  fill: #ffcd29;
`
export interface SenderInfo {
  author: Author
}

const StyledRoot = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 15px;
`

const StyledButtons = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`

const StyledInfo = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`

const StyledSenderInfo = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 7px;
`

const StyledImage = styled.img`
  min-width: 30px;
  height: 30px;
`

const StyledColorText = styled.span`
  color: #2cff80;
`

const getInviteIconByType = (
  type?: 'party' | 'friend' | 'lobby' | 'friendAccepted'
) => {
  switch (type) {
    case 'friend':
      return <FriendInviteIcon />
    case 'lobby':
      return <LobbyInviteIcon />
    case 'friendAccepted':
      return <FriendAcceptIcon />
    case 'party':
    default:
      return <PartyInviteIcon />
  }
}

const getInviteTextByType = (
  type?: 'party' | 'friend' | 'lobby' | 'friendAccepted',
  name?: string
) => {
  const { t } = useTranslation()

  switch (type) {
    case 'friend':
      return (
        <Text5>
          <StyledColorText>
            {t('notifications.invites.friendText.part1')}
          </StyledColorText>
          {` `}

          {t('notifications.invites.friendText.part2', { name: name })}
          {` `}
          {t('notifications.invites.friendText.part3')}
        </Text5>
      )
    case 'friendAccepted':
      return (
        <Text5>
          <StyledColorText>
            {t('notifications.invites.friendAcceptText.part1')}
          </StyledColorText>
          {` `}

          {t('notifications.invites.friendAcceptText.part2', { name: name })}
          {` `}
          {t('notifications.invites.friendAcceptText.part3')}
        </Text5>
      )
    case 'lobby':
      return (
        <Text5>
          {t('notifications.invites.lobbyText.part1')}
          {` `}
          <StyledColorText>
            {t('notifications.invites.lobbyText.part2')}
          </StyledColorText>
          {` `}
          {t('notifications.invites.lobbyText.part3')}
        </Text5>
      )
    case 'party':
    default:
      return (
        <Text5>
          {t('notifications.invites.partyText.part1')}
          {` `}
          <StyledColorText>
            {t('notifications.invites.partyText.part2', { name: name })}
          </StyledColorText>
          {` `}
          {t('notifications.invites.partyText.part3')}
        </Text5>
      )
  }
}

interface MessageProps {
  id: string
  text: string
  info?: SenderInfo
  redirect?: string
  onAccept?: () => void
  onReject?: () => void
  type?: 'party' | 'friend' | 'lobby' | 'friendAccepted'
}

const InviteModal = ({
  id,
  type,
  info,
  redirect,
  onReject,
  onAccept,
  closeToast,
}: ToastContentProps<unknown> & MessageProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const handleReject = () => {
    onReject && onReject()
    closeToast && closeToast()
    deletePopup(id)
  }
  const handleAccept = () => {
    onAccept && onAccept()
    closeToast && closeToast()
    deletePopup(id)
    redirect && navigate(redirect)
  }

  return (
    <StyledRoot>
      <StyledInfo>
        <StyledSenderInfo>
          <StyledImage src={info?.author.imgUrl} />
          <Header6>
            <NickTrimmer>{info?.author.name}</NickTrimmer>
          </Header6>
        </StyledSenderInfo>
        {getInviteIconByType(type)}
      </StyledInfo>
      {getInviteTextByType(type, info?.author.name)}
      {type === 'friendAccepted' ? (
        <StyledButtons>
          <SmallButton onClick={handleAccept}>
            {t('notifications.profile')}
          </SmallButton>
          <SmallButton withoutBorder onClick={handleReject}>
            {'Ok'}
          </SmallButton>
        </StyledButtons>
      ) : (
        <StyledButtons>
          <SmallButton onClick={handleAccept}>
            {t('buttons.accept')}
          </SmallButton>
          <SmallButton withoutBorder view="secondary" onClick={handleReject}>
            {t('buttons.decline')}
          </SmallButton>
        </StyledButtons>
      )}
      <StyledCorner />
    </StyledRoot>
  )
}
export default InviteModal
