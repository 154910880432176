import 'react-toastify/dist/ReactToastify.min.css'
// import 'react-indiana-drag-scroll/dist/style.css'
import * as Sentry from '@sentry/react'
import { BASE_URL } from 'api/api'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { ModalProvider } from 'styled-react-modal'
import { setIsOffline } from 'stores/appStore'
import App from 'App'
import AppInfo from 'components/Version'
import ReactDOM from 'react-dom/client'
import UpdateModal from 'components/Modals/UpdateModal'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import utc from 'dayjs/plugin/utc'

dayjs.extend(duration)
dayjs.extend(utc)

declare global {
  interface Window {
    IS_WEB: boolean
    BASE_URL: string
    TEST_VAR: () => string
    shell: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      openExternal: (data: any) => any
    }
    ipcRenderer: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      invoke: (name: string, value?: any) => any
    }
  }
}

if (!window.ipcRenderer) {
  window.ipcRenderer = { invoke: () => {} }
  window.IS_WEB = true
  window.shell = {
    openExternal: (data) => {
      window.open(data)
    },
  }
}

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Performance Monitoring
    tracePropagationTargets: [],
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    dsn: 'https://51f988e24ee4ee7a20c38e03f6d8686a@o4505986928082944.ingest.sentry.io/4505986980708352',

    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
          'localhost',
          /^https:\/\/f2f\.vin\/f2f/,
          BASE_URL,
        ],
      }),
      new Sentry.Replay({
        networkDetailAllowUrls: [window.location.origin, BASE_URL],
      }),
    ],
  })
}

window.addEventListener('online', () => {
  setIsOffline(false)
})
window.addEventListener('offline', () => {
  setIsOffline(true)
})

document.addEventListener('contextmenu', (event) => event.preventDefault())

const error = console.error
// eslint-disable-next-line @typescript-eslint/no-explicit-any
console.error = (...args: any) => {
  // // TODO:  Костыль до обновления библиотек rctx-contextmenu и recharts
  if (/defaultProps/.test(args[0])) return
  // // TODO:  Костыль до обновления svg
  if (/Invalid DOM property/.test(args[0])) return

  error(...args)
}

// function adjustBrowserZoom() {
//   const pageWidth = window.outerWidth
//   const pageHeight = window.outerHeight
//   const widthDefault = 1920 // Масштаб по умолчанию

//   const heightDefault = 1117 // Высота контента

//   const scaleWidth = pageWidth / widthDefault
//   const scaleHeight = pageHeight / heightDefault
//   const minScale = Math.min(scaleWidth, scaleHeight)

//   // webFrame.setZoomFactor(Number(minScale))
//   // if (document?.getElementById('root')?.style) {
//   //   document.getElementById('root').style.transform =
//   //     `scale(${Number(minScale)})`
//   // }

//   document.body.style.transform = `scale(${Number(minScale)})`
// }

// window.onload = adjustBrowserZoom
// window.onresize = adjustBrowserZoom

ReactDOM.createRoot(document.getElementById('root')!).render(
  // <React.StrictMode>
  <DndProvider backend={HTML5Backend}>
    <ModalProvider>
      <AppInfo />
      <App />
      <UpdateModal />
    </ModalProvider>
  </DndProvider>
  // </React.StrictMode>
)
