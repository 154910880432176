import {
  $mainPageStatisticGameFormat,
  setMainPageGameFormat,
} from 'stores/statisticStore'
import { $showStandingsPopup } from 'stores/userStore'
import { useEffect, useState } from 'react'
import { useStore } from '@nanostores/react'
import { useTranslation } from 'react-i18next'
import Modal from 'components/Modal'
import PopupPlayers from 'components/Home/PopupPlayers'
import StandingItems from 'components/Home/StandingItems'
import Toggle from 'components/Toggle'
import styled from 'styled-components'

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`
const StyledToggleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  height: 30px;
`
const StyledMore = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  color: white;
  padding: 8px 13px;
  font-family: Ruberoid;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  border-radius: 3px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: rgba(255, 255, 255, 0.03);
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    background: rgba(238, 157, 62, 0.15);
  }
`

const Standings: React.FC = () => {
  const { t } = useTranslation()
  type RatingTypes = 'RATING 5X5' | 'RATING 1X1'
  const RATING: RatingTypes[] = [t('home.rating5x5'), t('home.rating1x1')]
  const gameFormat = useStore($mainPageStatisticGameFormat)
  const showStandingsPopup = useStore($showStandingsPopup)

  const [rating, setRating] = useState<RatingTypes>(
    t('home.rating1x1') as RatingTypes
  )

  const handleRatingChange = (rating: RatingTypes) => {
    rating.toUpperCase() === 'RATING 1X1'
      ? setMainPageGameFormat('ONE_TO_ONE')
      : setMainPageGameFormat('FIVE_TO_FIVE')
    setRating(rating)
  }

  const toggglePopup = () => {
    $showStandingsPopup.set(!showStandingsPopup)
  }

  useEffect(() => {
    if (gameFormat === 'FIVE_TO_FIVE')
      setRating(t('home.rating5x5') as RatingTypes)
    else setRating(t('home.rating1x1') as RatingTypes)
  }, [gameFormat, t])

  return (
    <StyledWrapper>
      <StandingItems />
      <StyledToggleWrapper>
        <Toggle
          value={rating}
          items={RATING}
          padding="2.5px 23px"
          onChange={handleRatingChange}
        />
        <StyledMore onClick={toggglePopup}>{t('home.watchMore')}</StyledMore>
      </StyledToggleWrapper>
      <Modal onClose={toggglePopup} isOpen={showStandingsPopup}>
        <PopupPlayers />
      </Modal>
    </StyledWrapper>
  )
}

export default Standings
