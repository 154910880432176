import Slider from 'rc-slider'

import { CSSProperties, useEffect, useState } from 'react'
import styled from 'styled-components'

import SliderHandleActiveIcon from 'images/SliderHandleActive.svg'
import SliderHandleIcon from 'images/SliderHandle.svg'
import SoundIcon from 'images/SoundIcon.svg'
import SoundIconMuted from 'images/SoundIconMuted.svg'

import 'rc-slider/assets/index.css'

interface AudioSliderProps {
  initialValue: number
  onVolumeChange: (value: number) => Promise<void>
}

const AudioSlider = ({ initialValue, onVolumeChange }: AudioSliderProps) => {
  const [value, setValue] = useState(initialValue)
  const muted = value === 0

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  const handleChange = (v: number | number[]) => {
    if (typeof v === 'number') {
      setValue(v)
    }
  }

  const handleSaveVolume = async (v: number | number[]) => {
    if (typeof v === 'number') {
      await onVolumeChange(v)
    }
  }

  return (
    <StyledWrapper>
      <StyledSoundIcon muted={muted} />
      <StyledLeftDot muted={muted} />
      <Slider
        min={0}
        max={100}
        value={value}
        onChange={handleChange}
        onChangeComplete={handleSaveVolume}
        style={sliderStyles}
        styles={{
          rail: railStyles,
          track: trackStyles,
        }}
        handleRender={({ props }, { dragging }) => (
          <Handle {...props} muted={muted} dragging={dragging} />
        )}
      />
      <StyledRightDot muted={muted} />
      <StyledPercentValue>{value}%</StyledPercentValue>
    </StyledWrapper>
  )
}

const getColorByMuted = (muted: boolean) => (muted ? '#6F6F6F' : '#FFCD29')

const sliderStyles: CSSProperties = {
  height: 16,
  padding: 0,
}

const railStyles: CSSProperties = {
  height: 16,
  borderRadius: 40,
  border: '1px solid rgba(255, 255, 255, 0.15)',
  background:
    'linear-gradient(180deg, rgba(114, 132, 180, 0.10) 0%, rgba(59, 79, 131, 0.00) 100%), rgba(29, 30, 34, 0.50)',
}

const trackStyles: CSSProperties = {
  height: 16,
  background: '#FFCD29',
}

interface MutedProps {
  muted: boolean
}

interface HandleProps extends MutedProps {
  dragging: boolean
}

const Handle = styled.div<HandleProps>`
  width: 24px;
  height: 24px;
  margin-top: -4px;
  opacity: 1;
  border: none;

  ${({ muted, dragging }) => `background:
    url(${dragging ? SliderHandleActiveIcon : SliderHandleIcon})
      no-repeat center/50%,
    ${getColorByMuted(muted)};`}

  &:hover,
  &:active {
    border: ${({ dragging }) =>
      dragging ? '1px solid rgba(0, 0, 0, 0.29) !important' : 'none'};
    box-shadow: none !important;
  }
`

const StyledWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const StyledSoundIcon = styled.div<MutedProps>`
  width: 24px;
  height: 24px;
  margin-right: 8px;

  background-image: url(${({ muted }) => (muted ? SoundIconMuted : SoundIcon)});
`

const StyledDot = styled.div<MutedProps>`
  width: 4px;
  height: 4px;
  border-radius: 6px;

  background-color: ${({ muted }) => getColorByMuted(muted)};
`

const StyledLeftDot = styled(StyledDot)`
  margin: 0 24px 0 8px;
`

const StyledRightDot = styled(StyledDot)`
  margin: 0 8px 0 24px;
`

const StyledPercentValue = styled.span`
  width: 80px;
  color: #fff;
  text-align: right;
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
`

export default AudioSlider
