import styled from 'styled-components'

const StyledNick = styled.div`
  width: inherit;
  height: inherit;
  padding: 10px 0 10px 10px;
  margin: -10px 0 -10px -10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

interface nickProps {
  children: React.ReactNode
}

const NickTrimmer: React.FC<nickProps> = ({ children }) => {
  return <StyledNick>{children}</StyledNick>
}

export default NickTrimmer
