import { Header3, Header5, Header6, Text4 } from 'styles/typography'
import { useTranslation } from 'react-i18next'

import ModalHeader from 'components/Modals/ModalHeader'
import ModalWrapper from 'components/Modals/ModalWrapper'

import { Tooltip } from 'react-tooltip'
import { useState } from 'react'
import Button from 'components/Button'
import CopyWrapper from 'components/CopyWrapper'
import JudgeDownloadDemo from 'images/judge/JudgeDownloadDemo.svg'
import JudgeStarIcon from 'images/judge/JudgeStarIcon'
import JudgeVoteMatch from 'components/Judge/Match/JudgeVoteMatch'
import JudgeVoteResult from 'components/Judge/Result/JudgeVoteResult'
import JudgeVoteTicket from 'components/Judge/Ticket/JudgeVoteTicket'
import RemainingTimer from 'components/RemainingTimer'
import TabsSwitcher from 'components/TabsSwitcher'
import routes from 'router/routes'
import styled from 'styled-components'

const JudgeVoteModal = () => {
  const { t } = useTranslation()

  const voter = true

  const [tab, setTab] = useState<
    '/spectating/my-tickets' | 'matchInfo' | 'ticketResult'
  >('/spectating/my-tickets')
  const handleChangeTab = (key: string) => {
    setTab(key as '/spectating/my-tickets' | 'matchInfo' | 'ticketResult')
  }
  return (
    <ModalWrapper>
      <StyledModalHeader>
        <ModalHeader title={t('judge.voteModal.results')} />
      </StyledModalHeader>
      <StyledPlayHeader>
        <StyledTabsWrapper>
          <TabsSwitcher
            defaultActiveKey={routes.spectatingMyTickets()}
            onChange={handleChangeTab}
            items={[
              {
                key: routes.spectatingMyTickets(),
                label: t('judge.voteModal.application'),
              },
              {
                key: 'matchInfo',
                label: t('judge.voteModal.matchInfo'),
              },
              {
                key: 'ticketResult',
                label: t('judge.voteModal.applicationResult'),
              },
            ]}
          />
        </StyledTabsWrapper>
        {tab === 'matchInfo' && (
          <>
            <StyledMatchInfo>
              <InfoIconContainer>
                <StyledWinnerIcon>
                  <JudgeStarIcon />
                </StyledWinnerIcon>
                <InfoContainer>
                  <SetMatchInfo>Team Red</SetMatchInfo>
                  <MatchInfo>Winner</MatchInfo>
                </InfoContainer>
              </InfoIconContainer>
              <InfoIconContainer>
                <SetMapIcon />
                <InfoContainer>
                  <SetMatchInfo>Mirage</SetMatchInfo>
                  <MatchInfo>map</MatchInfo>
                </InfoContainer>
              </InfoIconContainer>
              <InfoContainer>
                <SetMatchInfo>
                  <CopyWrapper textToCopy={'12345'}>12345</CopyWrapper>
                </SetMatchInfo>
                <MatchInfo>id</MatchInfo>
              </InfoContainer>

              <InfoContainer>
                <SetMatchInfo>45 min</SetMatchInfo>
                <MatchInfo>Duration</MatchInfo>
              </InfoContainer>
              <InfoContainer>
                <SetMatchInfo>today 22:15</SetMatchInfo>
                <MatchInfo>Date</MatchInfo>
              </InfoContainer>
            </StyledMatchInfo>
            <StyledToolTip
              id="remainingTime"
              offset={-15}
              style={{
                width: '109px',
                fontSize: '10px',
                fontWeight: '500',
                fontFamily: 'Montserrat',
                backdropFilter: 'blur(70.5px)',
                borderRadius: '3px 0px 3px 0px',
                background: 'rgba(255, 255, 255, 0.03)',
              }}
              place="top-start"
            />
            <StyledTimer
              data-tooltip-id="remainingTime"
              data-tooltip-content="Оставшееся время"
            >
              <RemainingTimer total={30} currentProgress={20} />
            </StyledTimer>
          </>
        )}
      </StyledPlayHeader>
      <StyledRoot>
        {tab === routes.spectatingMyTickets() && <JudgeVoteTicket />}
        {tab === 'ticketResult' && <JudgeVoteResult />}
      </StyledRoot>
      {tab === 'matchInfo' && <JudgeVoteMatch />}
      <StyledRoot>
        {tab !== 'ticketResult' && (
          <StyledFooter>
            {voter && (
              <>
                <Button>
                  <StyledDemoIcon />
                  {t('judge.voteModal.download')}
                </Button>
                <ProcessNotification>
                  <JudgeVoteCircle />
                  <JudgeVoteCircle />
                  <StyledProcessNotification>
                    {t('judge.voteModal.userParticipates')}
                  </StyledProcessNotification>
                </ProcessNotification>
              </>
            )}
          </StyledFooter>
        )}
      </StyledRoot>
    </ModalWrapper>
  )
}
export default JudgeVoteModal

const StyledModalHeader = styled.div`
  padding: 13px 20px 0px 20px;
`
const StyledPlayHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 11px;
`
const StyledTabsWrapper = styled(Header3)`
  padding: 0px 0px 0px 20px;
  display: flex;
  align-items: center;
  margin-top: 14px;
  z-index: 10;
`
const StyledRoot = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 40px 0px 20px;
`
const StyledFooter = styled.div`
  border-top: 1px solid #ffffff26;
  height: 65px;
  padding: 15px 0px 0px 0px;
  display: flex;
  justify-content: space-between;
`

const StyledDemoIcon = styled.div`
  width: 24px;
  height: 20px;
  margin-right: 10px;
  padding: 2px 0;
  background-image: url(${JudgeDownloadDemo});
`
const ProcessNotification = styled(Header6)`
  width: 1072px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  gap: 3px;
  padding: 13px 15px;
  background: rgba(255, 255, 255, 0.03);
  border: 1px solid rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(7.5px);
  fill: #ffcd29;
  border-radius: 3px;
  font-size: 14px;
`
const JudgeVoteCircle = styled.div`
  width: 9px;
  height: 9px;
  border-radius: 100%;
  border: 1px solid white;
  margin: -7px 0px 0px 0px;
`
const StyledProcessNotification = styled.div`
  margin: 0px 0px 0px 13px;
`
const StyledMatchInfo = styled.div`
  display: flex;
  margin: 0px 0px 15px 85px;
  gap: 48px;
`
const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`
const InfoIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
const MatchInfo = styled(Text4)`
  text-align: center;
  opacity: 0.3;
  text-transform: uppercase;
`
const SetMatchInfo = styled(Header5)`
  text-align: center;
  text-transform: uppercase;
`
const SetMapIcon = styled.div`
  border: 1px solid white;
  border-radius: 100%;
  width: 34px;
  height: 34px;
  margin: 0px 16px 0px 0px;
`
const StyledTimer = styled.div`
  display: flex;
  margin: 0px 0px 19px 69px;
`
const StyledToolTip = styled(Tooltip)`
  z-index: 10;
  height: 20px;
  display: flex;
  white-space: nowrap;
  align-items: center;
  justify-content: center;
  border: 0.5px solid rgba(255, 255, 255, 0.1);
  & .react-tooltip-arrow {
    display: none;
  }
`
const StyledWinnerIcon = styled.div`
  margin: 0px 18px 0px 0px;
`
