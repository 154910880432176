import { css, styled } from 'styled-components'
import { useState } from 'react'
import StyledModal from 'styled-react-modal'

import ChangeSlideIcon from 'images/NextSlideIcon.svg'
import Close from 'images/Close'

interface ImageModalProps {
  images: { url: string }[]
  initialIndex: number
  onClose: () => void
}

const ImageModal = ({ images, onClose, initialIndex }: ImageModalProps) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(initialIndex)

  if (images.length === 0) return null

  const handleClickPrev = () =>
    setCurrentImageIndex((prev) =>
      prev - 1 < 0 ? images.length - 1 : prev - 1
    )

  const handleClickNext = () =>
    setCurrentImageIndex((prev) => (prev + 1 === images.length ? 0 : prev + 1))

  return (
    <StyledModal isOpen onEscapeKeydown={onClose} onBackgroundClick={onClose}>
      <StyledWrapper>
        <StyledClose onClick={onClose}>
          <StyledCloseIcon />
        </StyledClose>
        <ModalContent>
          <ChangeSlideButton direction="prev" onClick={handleClickPrev} />
          <StyledBigFile src={images[currentImageIndex].url} />
          <ChangeSlideButton direction="next" onClick={handleClickNext} />
        </ModalContent>
      </StyledWrapper>
    </StyledModal>
  )
}

const StyledWrapper = styled.div`
  position: relative;
`

const StyledClose = styled.div`
  position: absolute;
  top: -24px;
  right: -24px;
  cursor: pointer;

  background-color: rgba(255, 205, 41, 1);
  z-index: 999;
  border-radius: 50%;
`
const StyledCloseIcon = styled(Close)`
  & path {
    fill: black;
  }
`

const ModalContent = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`

interface ChangeSlideButtonProps {
  direction: 'prev' | 'next'
}

const ChangeSlideButton = styled.div<Pick<ChangeSlideButtonProps, 'direction'>>`
  width: 60px;
  height: 60px;

  background-image: url(${ChangeSlideIcon});
  background-repeat: no-repeat;
  background-position: calc(50%) calc(50%);

  cursor: pointer;

  ${({ direction }) =>
    direction === 'next' &&
    css`
      transform: rotate(180deg);
    `};
`

const StyledBigFile = styled.img`
  width: 1200px;
  max-height: 800px;

  margin-top: 20px;
  border: 1px solid rgba(255, 255, 255, 0.15);
  object-fit: contain;

  cursor: pointer;
`

export default ImageModal
