import StandingItem from 'components/Home/StandingItem'
import styled from 'styled-components'

const StyledWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 8px;
  grid-row-gap: 16px;
`

const StandingItems: React.FC = () => {
  return (
    <StyledWrapper>
      <StandingItem game="CS" position="top" />
      <StandingItem game="DOTA2" position="top" />
      <StandingItem game="CS" position="other" />
      <StandingItem game="DOTA2" position="other" />
    </StyledWrapper>
  )
}

export default StandingItems
