import { $match } from 'stores/lobbyStore'
import { Header6 } from 'styles/typography'
import { styled } from 'styled-components'
import { useStore } from '@nanostores/react'
import { useTranslation } from 'react-i18next'
import HistoryTimeLine, { Match } from 'components/MatchResults/HistoryTimeLine'

const ResultsMatchHistory = () => {
  const match = useStore($match)
  const { t } = useTranslation()

  const redLength =
    match?.participants?.filter(
      (participant) => participant.teamColor === 'RED'
    ).length || 0

  const blueLength =
    match?.participants?.filter(
      (participant) => participant.teamColor === 'BLUE'
    ).length || 0

  const rounds =
    match?.CSMatchResult?.CSMatchRoundResults?.reduce((acc, round) => {
      const winner = {
        deaths: round?.winTeamDeadCount || 0,
        typeOfWin: round.winCondition as
          | 'Elimination'
          | 'HostagesRescued'
          | 'HostageRescueFailed'
          | 'BombDefused'
          | 'BombExploded'
          | null,
      }

      const loser = {
        typeOfWin: null,
        deaths: round?.loseTeamDeadCount || 0,
      }

      const roundData = {
        red: round?.winTeamColor?.toUpperCase() === 'RED' ? winner : loser,
        blue: round?.winTeamColor?.toUpperCase() === 'BLUE' ? winner : loser,
      } as Match
      roundData.blue.length = blueLength
      roundData.red.length = redLength

      acc.push(roundData)

      return acc
    }, [] as Match[]) || []

  return (
    <StyledRoot>
      <StyledTimeLineWrapper>
        <StyledTimeLinePart gridTemplate={getGridByRounds(rounds.length)}>
          <HistoryTimeLine position="top" matchs={rounds} />
        </StyledTimeLinePart>
        <StyledSeprator>
          {match?.CSMatchResult?.isDraw && (
            <StyledDrawLabel>{t('play.statistic.draw')}</StyledDrawLabel>
          )}
        </StyledSeprator>
        <StyledTimeLinePart gridTemplate={getGridByRounds(rounds.length)}>
          <HistoryTimeLine matchs={rounds} position="bottom" />
        </StyledTimeLinePart>
      </StyledTimeLineWrapper>
    </StyledRoot>
  )
}

const StyledRoot = styled.div`
  height: 100%;
  display: flex;
  width: 100%;
  justify-content: center;
  padding-top: 70px;
  gap: 40px;
`

const StyledTimeLineWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
`

const StyledSeprator = styled.div`
  background-color: #2cff80;
  width: 100%;
  height: 5px;
  border-radius: 2.5px;
  position: relative;
`

interface StyledTimeLinePartProps {
  gridTemplate: string
}

const StyledTimeLinePart = styled.div<StyledTimeLinePartProps>`
  display: grid;

  grid-template-columns: ${({ gridTemplate }) => gridTemplate};
  gap: 8px;
  min-height: 305px;
`

const StyledDrawLabel = styled(Header6)`
  position: absolute;
  border-radius: 6px 0px;
  background: var(--desktop-green, #2cff80);
  display: flex;
  width: 116px;
  padding: 6px 8px;
  justify-content: center;
  align-items: center;
  left: -114px;
  top: -12px;

  font-weight: 400;
  color: var(--desktop-solid-black, #000);
`

const getGridByRounds = (roundsLength: number) => {
  return `43px ${'repeat(5, 24px) 43px '.repeat(Math.ceil(roundsLength / 5))}`
}

export default ResultsMatchHistory
