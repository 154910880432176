import {
  $chatOpen,
  $lastMessageForFastShow,
  $tabActive,
  setChatOpen,
} from 'stores/chatStores/chatStore'
import { $userProfile } from 'stores/userStore'
import { DtoChat, DtoChatParticipant, DtoMessage } from 'api/schemas/chatApi'
import { atom, computed } from 'nanostores'
import { logInfo } from 'utils/logger'
import { logger } from '@nanostores/logger'
import api from 'api/api'
import scrollChatToBottom from 'utils/scrollChatToBottom'

// STORE

export const $chats = atom<DtoChat[]>([])
export const $activeChatID = atom<string>('')

// COMPUTEDS

export const $chatsHaveUnreadMessage = computed([$chats], (chats) => {
  return chats.find((item) => item.haveUnreadMessage === true) !== undefined
})
export const $activeChatData = computed([$chats], (chats) => {
  return chats.find((item) => item.id === $activeChatID.get()) || {}
})
export const $activeChatMessages = computed([$activeChatData], (data) => {
  return data?.messages || []
})

// ACTIONS

export const getPartner = (participants: DtoChatParticipant[]) => {
  return participants.filter((user) => user.id !== $userProfile.get()?.id)[0]
}
// @description получить сообщения из чата по id чата
export const getCurrentChat = async (chatID: string) => {
  const { data } = (await api.chats.getChats(chatID)) as { data: DtoChat }
  return $chats.set(
    $chats.get().map((chat) => {
      if (chat.id === chatID) return { ...chat, data }
      return chat
    })
  )
}

export const deleteChat = (id: string) => {
  const newChats = $chats.get().filter((ch) => ch.id !== id)
  $chats.set(newChats)

  if ($activeChatID.get() === id) {
    $activeChatID.set('')
  }
}

// @description получить список личных чатов
export const getChatsFriends = async () => {
  try {
    const { data }: { data?: DtoChat[] } = await api.chats.getFriends()
    checkSingleChatExist(data)

    return $chats.set(data || [])
  } catch (error) {
    logInfo('Ошибка getChatsFriends', { error })
  }
}

// @description пометить сообщения в чате прочитанными
export const readMessages = async (chatID: string) => {
  console.log('readMessage')
  await api.chats.postRead(chatID)
  const result = $chats
    .get()
    .map((chat) =>
      chat.id === chatID ? { ...chat, haveUnreadMessage: false } : chat
    )
  return $chats.set(result)
}

// @description получить сообщения из чата
export const getChatMessages = async (id: string, fromCreatedAt?: string) => {
  console.log('getChatMessages')

  const currentChat = $chats.get().find((chat) => chat.id === id)
  const isUnread = !!currentChat?.haveUnreadMessage

  if (isUnread || fromCreatedAt) {
    const { data } = (await api.chats.getChats(id, {
      fromCreatedAt,
    })) as { data: DtoChat }
    const result = $chats.get().map((chat) => {
      /* подгужаем сообщения которые были раньше */
      const messages: DtoMessage[] | undefined = fromCreatedAt
        ? chat.messages?.length
          ? [...chat.messages.reverse(), ...(data.messages as DtoMessage[])]
          : data.messages
        : data.messages
      return chat.id === id ? { ...chat, messages: messages || [] } : chat
    })
    return $chats.set(result)
  } else {
    return $chats
  }
}

// @description id друга, создает чат если его нет, и открывает его
export const getPartnerChat = async (partnerId: string) => {
  console.log('getPartnerChat')
  if (!$chatOpen.get()) await setChatOpen(true)
  if (
    $chats
      .get()
      .find(
        (chat) =>
          getPartner(chat.participants as DtoChatParticipant[])?.id ===
          partnerId
      )?.id === $activeChatID.get()
  )
    return

  $tabActive.set('chat')
  const friendChat = $chats
    .get()
    .find(
      (chat) =>
        getPartner(chat.participants as DtoChatParticipant[])?.id === partnerId
    )

  if (friendChat?.id) {
    $activeChatID.set(friendChat.id)
    await getChatMessages(friendChat.id)
    await readMessages($activeChatID.get())
  } else {
    const { data } = (await api.chats.getPartner(partnerId)) as {
      data: DtoChat
    }
    if (data.id) $activeChatID.set(data.id)
    await readMessages($activeChatID.get())
    return $chats.set([...$chats.get(), data])
  }
}

// @description добавляет сообщение которое пришло от сокета в активный личный чат
export const updateChatMessagesFromSocket = async (data: DtoChat) => {
  console.log('updateChatMessagesFromSocket')

  const store = $chats
  const updatedChat = store.get().find((chat) => chat.id === data.id)
  const updatedChatOpen = data.id === $activeChatID.get()
  const lastMessage = data?.messages?.[data?.messages?.length - 1]

  if (lastMessage) {
    $lastMessageForFastShow.set({
      ...lastMessage,
      tab: 'chat',
    })
  }

  if (updatedChat) {
    const messages = updatedChat.messages
      ? [...(data.messages as DtoMessage[]), ...updatedChat.messages]
      : [...(data.messages as DtoMessage[])]
    const result = store.get().map((chat) => {
      if (chat.id === data.id) {
        return {
          ...chat,
          messages,
          haveUnreadMessage: !updatedChatOpen,
        } as DtoChat
      }
      return chat
    })
    store.set(result)
  } else {
    store.set([
      { ...data, haveUnreadMessage: !updatedChatOpen },
      ...store.get(),
    ])
  }

  if (updatedChatOpen) {
    await readMessages($activeChatID.get())
    const isUserMessage = data.messages?.find(
      (message) => message.authorId === $userProfile.get()?.id
    )

    if (isUserMessage) scrollChatToBottom()
  }
}

const checkSingleChatExist = (chats: DtoChat[] | undefined) => {
  const activeChatExist = !!chats?.find(
    (chat) => chat.id === $activeChatID.get()
  )

  if (!activeChatExist) $activeChatID.set('')
}

logger(
  {
    getAllChats: $chats,
    activeChatID: $activeChatID,
    activeChatData: $activeChatData,
    activeChatMessages: $activeChatMessages,
    chatsHaveUnreadMessage: $chatsHaveUnreadMessage,
  },
  {
    messages: {
      mount: false,
      unmount: false,
    },
  }
)
