import { Header5, Header6, Text4 } from 'styles/typography'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Button from 'components/Button'
import JudgeIcon from 'images/judge/JudgeIcon'
import ResultInput from 'components/Judge/Result/ResultInput'
import Timer from 'components/Judge/Result/Timer'
import VoteProcess from 'components/Judge/Ticket/VoteProcess'
import styled from 'styled-components'

interface FormData {
  explanation: string
  timeCode: string[]
  files: File[]
}

const JudgeVoteResult = () => {
  const { t } = useTranslation()

  const [isGuiltyConfirm, setIsGuiltyConfirm] = useState(false)
  const [isGuilty, setIsGuilty] = useState(false)
  const [inputData, setInputData] = useState<FormData>()

  const handleConfirm = () => {
    console.log('Inputs:', inputData)
    console.log('IsGuilty:', isGuilty)
  }

  const handleGuiltyConfirm = (isGuilty: boolean) => {
    setIsGuilty(isGuilty)
    setIsGuiltyConfirm(true)
  }

  return (
    <>
      <VoteProcess />
      <StyledMainContent>
        <Timer />
        <ResultInput
          setInputData={setInputData}
          isGuiltyConfirm={isGuiltyConfirm}
        />
      </StyledMainContent>
      <StyledFooter>
        <ConfirmUnguilty
          onClick={() => handleGuiltyConfirm(false)}
          disabled={
            (isGuiltyConfirm && isGuilty) || inputData?.explanation === ''
          }
        >
          <JudgeGuiltyIcon />
          <StyledDescription>
            {t('judge.voteModal.notGuilty')}
          </StyledDescription>
        </ConfirmUnguilty>
        {!isGuiltyConfirm && (
          <ConfirmGuilty
            onClick={() => handleGuiltyConfirm(true)}
            disabled={inputData?.explanation === ''}
          >
            <JudgeGuiltyIcon />
            <StyledDescription>{t('judge.voteModal.guilty')}</StyledDescription>
          </ConfirmGuilty>
        )}
        {isGuiltyConfirm && inputData?.explanation !== '' && (
          <ConfirmWrapper>
            <StyledConfirmDescription>
              {t('judge.voteModal.areYouSure')}
            </StyledConfirmDescription>
            <ButtonsWrapper>
              <ButtonCancel onClick={() => setIsGuiltyConfirm(false)}>
                <ButtonTitle>{t('judge.voteModal.cancel')}</ButtonTitle>
              </ButtonCancel>
              <ButtonConfirm onClick={() => handleConfirm()}>
                <ButtonTitle>{t('judge.voteModal.yes')}</ButtonTitle>
              </ButtonConfirm>
            </ButtonsWrapper>
          </ConfirmWrapper>
        )}
      </StyledFooter>
    </>
  )
}

export default JudgeVoteResult

const StyledMainContent = styled.div`
  display: flex;
  width: 100%;
  height: 554px;
  margin: 0px 0px 32px 0px;
  gap: 15px;
`
const StyledFooter = styled.div`
  border-top: 1px solid #ffffff26;
  height: 65px;
  padding: 20px 0px 0px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const ConfirmUnguilty = styled(Button)`
  padding: 0px 0px 0px 11px;
  width: 308px;
  height: 42px;
  fill: #ffcd29;
  &:disabled,
  &:active {
    fill: white;
  }
`
const ConfirmGuilty = styled(Button)`
  padding: 0px 0px 0px 11px;
  height: 42px;
  width: 1072px;
  fill: #ffcd29;
  &:disabled,
  &:active {
    fill: white;
  }
`
const StyledDescription = styled(Header5)`
  margin: 0px 0px 0px 8px;
`
const JudgeGuiltyIcon = styled(JudgeIcon)``
const ConfirmWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: 32px;
`
const StyledConfirmDescription = styled(Text4)`
  font-size: 16px;
  font-weight: 500;
  color: #ff4444;
`
const ButtonsWrapper = styled.div`
  display: flex;
  height: 32px;
  gap: 16px;
`
const ButtonCancel = styled(Button)`
  justify-content: center;
  display: flex;
  border: none;
  background: none;
  &:hover,
  &:active {
    border: none;
    background: none;
  }
  &:active {
    color: #ff4444;
  }
`
const ButtonConfirm = styled(Button)`
  justify-content: center;
  width: 100px;
`
const ButtonTitle = styled(Header6)`
  font-size: 14px;
`
