import { SpinnerCircularFixed } from 'spinners-react'
import styled from 'styled-components'

const StyledRoot = styled.div`
  width: 16px;
  height: 16px;

  display: flex;

  align-items: center;
  justify-content: center;
`

const Loader: React.FC = () => {
  return (
    <StyledRoot>
      <SpinnerCircularFixed
        size="16"
        color="white"
        thickness={200}
        secondaryColor="rgba(255, 255, 255, 0.3)"
      />
    </StyledRoot>
  )
}

export default Loader
