import { useTranslation } from 'react-i18next'
import Background from 'images/judge/WelcomeBackgroundSlide2.svg'
import InfoCardContent from 'components/Judge/Welcome/InfoCard/Content'
import InfoCardWrapper from 'components/Judge/Welcome/InfoCard/Wrapper'
import JudgeWelcomeSlide2InfoCardBG from 'images/judge/JudgeWelcomeSlide2InfoCardBG.svg'
import JudgeWelcomeSlide2Man1Background from 'images/judge/JudgeWelcomeSlide2Man1.png'
import JudgeWelcomeSlide2Man2Background from 'images/judge/JudgeWelcomeSlide2Man2.png'
import styled, { keyframes } from 'styled-components'

const JudgeWelcomeSlide2 = () => {
  const { t } = useTranslation()

  return (
    <StyledWrapper>
      <StyledAnimatedBackground />
      <StyledInfoCardWrapper>
        <InfoCardContent
          slideNumber="02"
          title={t('judge.welcomePage.infoTitleSlide2')}
          description={t('judge.welcomePage.infoDescriptionSlide2')}
        />
      </StyledInfoCardWrapper>
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  background-image: url(${Background});
`

const StyledInfoCardWrapper = styled(InfoCardWrapper)`
  width: 860px;
  height: 268px;
  top: 24px;
  left: 734px;
  padding: 64px 42px 64px 90px;
  gap: 36px;
  background-image: url(${JudgeWelcomeSlide2InfoCardBG});
  background-repeat: no-repeat;
`

const fadeInOut = keyframes`
  0%, 16.66% {
    opacity: 0;
  }
  33.33%, 66.66% {
    opacity: 1;
  }
  83.33%, 100% {
    opacity: 0;
  }
`

const StyledAnimatedBackground = styled.div`
  position: absolute;
  top: 0;
  left: 61px;
  width: 1076px;
  height: 639px;

  background-image: url(${JudgeWelcomeSlide2Man1Background});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    background-image: url(${JudgeWelcomeSlide2Man2Background});
    background-size: cover;
    background-repeat: no-repeat;

    background-position: calc(50% + 6px) calc(50%);

    opacity: 0;

    animation: ${fadeInOut} 4s infinite;
  }
`

export default JudgeWelcomeSlide2
