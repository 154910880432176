/* eslint-disable @typescript-eslint/no-explicit-any */
import { $lobby, setLobby } from 'stores/lobbyStore'
import { $userProfile } from 'stores/userStore'
import { isEqual } from 'lodash'

const lobbyListiner = async (data: any) => {
  if (data.event === 'CREATE_AUTO') {
    const isMyEvent = data.originalData.userDetails
      .map((u: any) => u.userId)
      .includes($userProfile.get()?.id)
    if (!isMyEvent) return
    await setLobby(data.data)
    return
  }

  if (data.entityAction === 'UPDATE') {
    if (data.event === 'JOIN_AUTO') {
      const isMyEvent = data.originalData.userDetails
        .map((u: any) => u.userId)
        .includes($userProfile.get()?.id)
      if (!isMyEvent) return
      await setLobby(data.data)
      return
    }

    if (
      !isEqual(data.data, $lobby.get()) &&
      $lobby.get()?.id === data.data.id
    ) {
      await setLobby(data.data)
    }
  }

  if (data.entityAction === 'DELETE') {
    await setLobby(null)

    console.log('Лобби удалена')
  }

  if (data.event === 'CLOSED') {
    if ($lobby.get()?.id === data.data.id) {
      await setLobby(null)
    }

    console.log('Лобби удалена')
  }
}

export default lobbyListiner
