import BordersBackground from 'images/judge/NextSlideIconCorners.svg'
import NextSlideIcon from 'images/judge/NextSlideIcon.svg'
import styled, { css } from 'styled-components'

type Direction = 'next' | 'prev'

interface ChangeSlideButtonProps {
  onClick: () => void
  direction: Direction
}

const ChangeSlideButton = ({ onClick, direction }: ChangeSlideButtonProps) => (
  <Wrapper onClick={onClick}>
    <Borders />
    <Icon direction={direction} />
  </Wrapper>
)

const Borders = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url(${BordersBackground});
  background-repeat: no-repeat;
  opacity: 0;
  transition: opacity 300ms linear;
`

const Icon = styled.div<Pick<ChangeSlideButtonProps, 'direction'>>`
  width: 100%;
  height: 100%;
  background-image: url(${NextSlideIcon});
  background-repeat: no-repeat;
  background-position: calc(50%) calc(50%);

  ${({ direction }) =>
    direction === 'prev' &&
    css`
      transform: rotate(180deg);
    `};
`

const Wrapper = styled.div`
  position: relative;
  width: 102px;
  height: 58px;
  cursor: pointer;

  &:hover {
    ${Borders} {
      opacity: 1;
      transition: opacity 300ms linear;
    }
  }
`

export default ChangeSlideButton
