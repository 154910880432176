import { Header5 } from 'styles/typography'
import { SpinnerCircularFixed } from 'spinners-react'
import buttonClickSound from 'sounds/simple-buttons.mp3'
import styled, { css } from 'styled-components'
import useSound from 'hooks/useSound'

interface StyledButton {
  $isDisabled?: boolean
}

const StyledButton = styled.button<StyledButton>`
  width: 100%;

  background: rgba(255, 255, 255, 0.03);
  border: 1px solid rgba(255, 255, 255, 0.15);
  border-radius: 3px;
  padding: 13px 11px;
  transition: 0.3s;

  font-family: 'Montserrat';
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  height: 42px;
  font-size: 16px;
  ${({ $isDisabled }) =>
    !$isDisabled &&
    css`
      &:hover {
        background: rgba(238, 157, 62, 0.15);
        border: 1px solid rgba(255, 255, 255, 0.15);
        backdrop-filter: blur(7.5px);

        border-radius: 3px;
      }

      &:focus-visible {
        background: rgba(238, 157, 62, 0.15);
        border: 1px solid rgba(255, 255, 255, 0.15);
        backdrop-filter: blur(7.5px);
        outline: none;
        border-radius: 3px;
      }
      &:active {
        background: #ffcd29;
        border: 1px solid rgba(255, 255, 255, 0.2);
        border-radius: 3px;
        font-weight: 500;
        transform: scale(1.01);
        & > div > div {
          color: #000000 !important;
        }
        color: #000000;
      }
    `}

  ${({ $isDisabled }) =>
    $isDisabled &&
    css`
      color: rgba(255, 255, 255, 0.15);
    `}
`

const StyledSpinnerCircular = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`

const StyledContent = styled(Header5)`
  width: 100%;
`

interface ButtonProps {
  isLoading?: boolean
}

const FormButton: React.FC<
  ButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>
> = ({ onClick, children, disabled, isLoading, ...rest }) => {
  const [play] = useSound(buttonClickSound)

  const onClickWithSound = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (onClick) {
      onClick(e)
    }
    play()
  }

  return (
    <StyledButton $isDisabled={disabled} onClick={onClickWithSound} {...rest}>
      {isLoading ? (
        <StyledSpinnerCircular>
          <SpinnerCircularFixed
            size="24"
            thickness={250}
            color="rgba(44, 255, 128, 1)"
            secondaryColor="rgba(255, 255, 255, 0.1)"
          />
        </StyledSpinnerCircular>
      ) : (
        <StyledContent>{children}</StyledContent>
      )}
    </StyledButton>
  )
}

export default FormButton
