import { Header2, Text4 } from 'styles/typography'
import Button from 'components/Button'
import GameFindedUserList from 'components/GameFinded/GameFindedUserList'
import StyledModal from 'styled-react-modal'
import TopGreenLine from 'images/topGreenLine.svg'
import popupBackLeft from 'images/confirmPopupBackLeft.svg'
import popupBackRight from 'images/confirmPopupBackRight.svg'
import styled from 'styled-components'

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 11;
`
const StyledBack = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0px;
  left: 0px;
  background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 54.86%,
      rgba(44, 255, 128, 0.2) 100%
    ),
    linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.9) 45.98%),
    rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(5px);
  width: 100%;
  height: 100%;
`
const StyledTopLine = styled.div`
  width: 1920px;
  height: 236px;
  background: url(${TopGreenLine});
  position: absolute;
  top: -47%;
  z-index: 0;
`
const StyledPopup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 700px;
  height: 230px;
  position: relative;
  z-index: 1;

  &:before {
    display: block;
    content: '';
    width: 895px;
    height: 647px;
    background: url(${popupBackLeft});
    position: absolute;
    left: -585px;
    margin-top: 6px;
    z-index: -1;
  }

  &:after {
    display: block;
    content: '';
    width: 994px;
    height: 647px;
    background: url(${popupBackRight});
    position: absolute;
    right: -635px;
    margin-top: 6px;
    z-index: -1;
  }
`
const StyledPopupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: 0 20px;
  border-radius: 3px;
  border: 1px solid rgba(255, 255, 255, 0.15);
  background: rgba(255, 255, 255, 0.03);
  border-top: 8px solid #2cff80;
  padding: 0 20px 14px 20px;
  position: relative;
`
const StyledCaption = styled(Header2)`
  color: #2cff80;
  line-height: 90%; /* 37.8px */
  text-transform: uppercase;
  margin-top: 36px;
`
const StyledDescription = styled(Text4)`
  color: white;
  line-height: 125%;
  margin-top: 12px;
  width: 528px;
  text-align: center;
`
const StyledPopupButtons = styled.div`
  display: flex;
  gap: 8px;
  margin-top: auto;
  width: 100%;
`
const StyledButton = styled(Button)`
  justify-content: center;
  width: 100%;
`
const StyledTimerWrapper = styled.div`
  position: absolute;
  left: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
`
interface StyledTimerCircleProps {
  $timeToConfirm: number
}
const StyledTimerCircle = styled.svg<StyledTimerCircleProps>`
  --size: 30px;
  --half-size: calc(var(--size) / 2);
  --stroke-width: 2px;
  --radius: calc((var(--size) - var(--stroke-width)) / 2);
  --circumference: calc(var(--radius) * pi * 2);
  --dash: calc((var(--progress) * var(--circumference)) / 100);
  animation: progress-animation
    ${({ $timeToConfirm }) => ($timeToConfirm ? $timeToConfirm : '10')}s linear
    0s 1 forwards;
  transform: scale(-1, 1);

  & circle {
    cx: var(--half-size);
    cy: var(--half-size);
    r: var(--radius);

    stroke-width: var(--stroke-width);
    fill: none;
    stroke-linecap: unset;
  }

  & circle.fg {
    transform: rotate(-90deg);
    transform-origin: var(--half-size) var(--half-size);
    stroke-dasharray: var(--dash) calc(var(--circumference) - var(--dash));
    transition: stroke-dasharray 0.3s linear 0s;
    stroke: #2cff80;
  }

  @property --progress {
    syntax: '<number>';
    inherits: false;
    initial-value: 0;
  }

  @keyframes progress-animation {
    from {
      --progress: 100;
    }
    to {
      --progress: 0;
    }
  }
`
const StyledTimerText = styled.div`
  font-family: Montserrat;
  font-size: 11.25px;
  font-style: normal;
  color: #2cff80;
  position: absolute;
`

interface ModalProps {
  timer: number
  caption: string
  isOpen: boolean
  description: string
  confirmCount: number
  isConfirmed: boolean
  acceptTitle?: string
  onAccept: () => void
  timeToConfirm: number
  isFiveToFive: boolean
  declineTitle?: string
  onDecline?: () => void
}

const GameFindedModal: React.FC<ModalProps> = ({
  timer,
  isOpen,
  caption,
  onAccept,
  isConfirmed,
  description,
  acceptTitle,
  confirmCount,
  isFiveToFive,
  timeToConfirm,
}) => {
  return (
    <StyledModal isOpen={isOpen}>
      <StyledWrapper>
        <StyledBack />
        <StyledPopup>
          <StyledTopLine />
          <StyledPopupWrapper>
            <StyledCaption>{caption}</StyledCaption>
            {isConfirmed ? (
              <GameFindedUserList
                maxPlayers={isFiveToFive ? 10 : 2}
                acceptedInviteCount={confirmCount}
              />
            ) : (
              <>
                <StyledDescription>{description}</StyledDescription>
                <StyledPopupButtons>
                  {acceptTitle && (
                    <StyledButton size="s" onClick={onAccept}>
                      <StyledTimerWrapper>
                        <StyledTimerCircle
                          width="30"
                          height="30"
                          viewBox="0 0 30 30"
                          className="circular-progress"
                          $timeToConfirm={timeToConfirm}
                        >
                          <circle className="fg"></circle>
                        </StyledTimerCircle>
                        <StyledTimerText>{timer}</StyledTimerText>
                      </StyledTimerWrapper>
                      {acceptTitle}
                    </StyledButton>
                  )}
                </StyledPopupButtons>
              </>
            )}
          </StyledPopupWrapper>
        </StyledPopup>
      </StyledWrapper>
    </StyledModal>
  )
}

export default GameFindedModal
