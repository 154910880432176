import { $userPopupActiveGameInfo, RankTypes } from 'stores/statisticStore'
import { useStore } from '@nanostores/react'
import BestWeapons from 'components/PersonalStatistic/BestWeapons'
import CSStatistic from 'components/PersonalAccount/CSStatistic'
import MapStatistic from 'components/PersonalStatistic/MapStatistic'
import PersonCard from 'components/PersonalStatistic/PersonCard'
import Rank from 'components/PersonalStatistic/Rank'
import styled from 'styled-components'

const StyledWrapper = styled.div`
  display: flex;
  gap: 5px;
  margin-top: 32px;
`

const PersonalStatisticBlocks: React.FC = () => {
  const userPopupActiveGameInfo = useStore($userPopupActiveGameInfo)

  return (
    <StyledWrapper>
      <PersonCard gameChoosen="CS" page="userProfile" />
      <MapStatistic gameChoosen="CS" page="userProfile" />
      <Rank
        rating={userPopupActiveGameInfo?.rating}
        nextRankRating={userPopupActiveGameInfo?.nextRankRating}
        prevRankRating={userPopupActiveGameInfo?.prevRankRating}
        rank={(userPopupActiveGameInfo?.rank as RankTypes) || undefined}
      />
      <BestWeapons gameChoosen="CS" page="userProfile" />
      <CSStatistic />
    </StyledWrapper>
  )
}

export default PersonalStatisticBlocks
