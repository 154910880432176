import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { EnumClaimReason, EnumClaimStatus } from 'api/schemas/judgeApi'
import { Option } from 'components/Select'

export const useClaimStatusTranslations = () => {
  const { t } = useTranslation()

  return useMemo(() => {
    const statusLabels = {
      [EnumClaimStatus.ClaimStatusFinish]: t('judge.judgePage.solved'),
      [EnumClaimStatus.ClaimStatusPreparing]: t('judge.judgePage.draft'),
      [EnumClaimStatus.ClaimStatusApproved]: t('judge.judgePage.approved'),
      [EnumClaimStatus.ClaimStatusNew]: t(
        'judge.judgePage.awaitingVerification'
      ),
      [EnumClaimStatus.ClaimStatusInProgress]: t(
        'judge.judgePage.inConsideration'
      ),
    }

    const statusOptions = Object.entries(statusLabels)
      .map(([value, label]) => ({
        label,
        value,
      }))
      .filter(({ value }) => value !== EnumClaimStatus.ClaimStatusPreparing)

    const toStatusSelectValue = (
      status: EnumClaimStatus | undefined
    ): Option[] => {
      switch (status) {
        case EnumClaimStatus.ClaimStatusNew:
          return [
            {
              value: EnumClaimStatus.ClaimStatusNew,
              label: statusLabels[EnumClaimStatus.ClaimStatusNew],
            },
          ]
        case EnumClaimStatus.ClaimStatusInProgress:
          return [
            {
              value: EnumClaimStatus.ClaimStatusInProgress,
              label: statusLabels[EnumClaimStatus.ClaimStatusInProgress],
            },
          ]
        case EnumClaimStatus.ClaimStatusFinish:
          return [
            {
              value: EnumClaimStatus.ClaimStatusFinish,
              label: statusLabels[EnumClaimStatus.ClaimStatusFinish],
            },
          ]
        case EnumClaimStatus.ClaimStatusApproved:
          return [
            {
              value: EnumClaimStatus.ClaimStatusApproved,
              label: statusLabels[EnumClaimStatus.ClaimStatusApproved],
            },
          ]
        default:
          return []
      }
    }

    const fromStatusSelectValue = (values: Option[]) => {
      if (!values.length) return

      const [{ value }] = values

      return value as EnumClaimStatus
    }

    return {
      statusLabels,
      statusOptions,
      toStatusSelectValue,
      fromStatusSelectValue,
    }
  }, [t])
}

export const useClaimReasonTranslations = () => {
  const { t } = useTranslation()

  return useMemo(
    () => ({
      [EnumClaimReason.ClaimReasonCheating]: t('chat.appeal.cheating'),
      [EnumClaimReason.ClaimReasonGriefing]: t('chat.appeal.griefing'),
      [EnumClaimReason.ClaimReasonGameCrushed]: t('chat.appeal.gameCrushed'),
      [EnumClaimReason.ClaimReasonLoweringFPS]: t('chat.appeal.loveringFPS'),
      [EnumClaimReason.ClaimReasonNetworkDelay]: t('chat.appeal.networkDelay'),
      [EnumClaimReason.ClaimReasonUnsportsmanlikeBehavior]: t(
        'chat.appeal.unsportsmanlikeBehavior'
      ),
    }),
    [t]
  )
}

export type GuiltyStatus = 'IN_WORK' | 'GUILTY' | 'NOT_GUILTY'

export const useGuiltyTranslations = () => {
  const { t } = useTranslation()

  return useMemo(() => {
    const guiltyLabels: Record<GuiltyStatus, string> = {
      GUILTY: t('judge.judgePage.guilty'),
      IN_WORK: t('judge.judgePage.inWork'),
      NOT_GUILTY: t('judge.judgePage.notGuilty'),
    }

    const guiltyOptions = Object.entries(guiltyLabels).map(
      ([value, label]) => ({
        label,
        value,
      })
    )

    const toGuiltySelectValue = (
      isGuiltyVerdict: boolean | null | undefined
    ): Option[] => {
      switch (isGuiltyVerdict) {
        case false:
          return [{ value: 'NOT_GUILTY', label: guiltyLabels['NOT_GUILTY'] }]
        case true:
          return [{ value: 'GUILTY', label: guiltyLabels['GUILTY'] }]
        case null:
          return [{ value: 'IN_WORK', label: guiltyLabels['IN_WORK'] }]
        default:
          return []
      }
    }

    const toGuiltyLabel = (isGuiltyVerdict: boolean | null | undefined) => {
      switch (isGuiltyVerdict) {
        case false:
          return guiltyLabels['NOT_GUILTY']
        case true:
          return guiltyLabels['GUILTY']
        case null:
          return guiltyLabels['IN_WORK']
        default:
          return ''
      }
    }

    const fromGuiltySelectValue = (values: Option[]) => {
      if (!values.length) return

      const [{ value }] = values

      switch (value) {
        case 'NOT_GUILTY':
          return false
        case 'GUILTY':
          return true
        case 'IN_WORK':
        default:
          return null
      }
    }

    return {
      guiltyOptions,
      toGuiltyLabel,
      toGuiltySelectValue,
      fromGuiltySelectValue,
    }
  }, [t])
}
