import { useTranslation } from 'react-i18next'
import React from 'react'
import styled from 'styled-components'

interface RemainingTimerProps {
  size: number
  progress: number
  descfont: number
  timerfont: number
  left: number
  top: number
  lineheight: number
}

const RemainingTimer: React.FC<RemainingTimerProps> = ({
  top,
  size,
  left,
  descfont,
  progress,
  timerfont,
  lineheight,
}) => {
  const { t } = useTranslation()

  const progressPercentage = (progress / 100) * 283

  return (
    <StyledProgressBarContainer>
      <StyledProgressBarSvg size={size} viewBox="0 0 110 110">
        <StyledProgressBarCircle
          stroke="#FFFFFF26"
          strokeWidth="5"
          fill="transparent"
        />
        <StyledProgressBarCircle
          stroke="#FFCD29"
          strokeWidth="5"
          fill="transparent"
          style={{
            strokeDasharray: `${progressPercentage} ${360 - progressPercentage}`,
          }}
        />
      </StyledProgressBarSvg>
      <StyledProgressBarText $left={left} $top={top} $lineheight={lineheight}>
        <StyledProgressBarMinutes $timerfont={timerfont}>
          25
        </StyledProgressBarMinutes>
        <StyledProgressBarDescrption $descfont={descfont}>
          {t('judge.voteModal.minutes')}
        </StyledProgressBarDescrption>
      </StyledProgressBarText>
    </StyledProgressBarContainer>
  )
}

export default RemainingTimer

const StyledProgressBarSvg = styled.svg<{ size: number }>`
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
`

const StyledProgressBarContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
`

const StyledProgressBarCircle = styled.circle`
  cx: 51.5;
  cy: 51.5;
  r: 45;
`

const StyledProgressBarText = styled.div<{
  $top: number
  $left: number
  $lineheight: number
}>`
  display: flex;
  position: absolute;
  top: ${(props) => props.$top}%;
  left: ${(props) => props.$left}%;
  transform: translate(-50%, -50%);
  flex-direction: column;
  line-height: ${(props) => props.$lineheight}px;
  text-align: center;
`

const StyledProgressBarMinutes = styled.div<{ $timerfont: number }>`
  line-height: 15px;
  font-family: 'Ruberoid';
  font-size: ${(props) => props.$timerfont}px;
  font-weight: bold;
`

const StyledProgressBarDescrption = styled.div<{ $descfont: number }>`
  font-weight: 500;
  color: #ffffff;
  opacity: 30%;
  font-family: 'Montserrat';
  font-size: ${(props) => props.$descfont}px;
`
