import { DtoGameMap } from 'api/schemas/lobbyApi'
import { Header5 } from 'styles/typography'
import { useTranslation } from 'react-i18next'
import CheckboxEmpty from 'images/CheckboxEmpty'
import CheckboxFilled from 'images/CheckboxFilled'
import React from 'react'
import map_test from 'images/map_test.jpg'
import styled, { css } from 'styled-components'

type SettingsStatus = 'starting' | 'started' | 'preparing'

const getColor = (status: SettingsStatus) => {
  switch (status) {
    case 'preparing':
      return {
        border: '#ffcd29',
        background: 'transparent',
        hover: 'rgba(238, 157, 62, 0.1)',
      }
    case 'starting':
      return {
        border: '#2CFF80',
        background: 'transparent',
        hover: 'rgba(238, 157, 62, 0.15)',
      }
    case 'started':
      return {
        border: 'rgba(44, 255, 128, 1)',
        hover:
          'linear-gradient(180deg, rgba(0, 217, 87, 0.86) 0%, rgba(0, 152, 61, 0.00) 15%, rgba(1, 165, 67, 0.12) 45%, rgba(13, 255, 110, 0.46) 100%);',
        background:
          'linear-gradient(180deg, rgba(0, 217, 87, 0.86) 0%, rgba(0, 152, 61, 0.00) 15%, rgba(1, 165, 67, 0.12) 45%, rgba(13, 255, 110, 0.46) 100%);',
      }
  }
}

const StyledMapWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 8px;
`

const StyledMapContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(7, auto);

  gap: 8px;
`

interface StyledMapItem {
  $url?: string
  $disabled?: boolean
}

const StyledMapItem = styled.div<StyledMapItem>`
  position: relative;

  height: 150px;
  width: 150px;

  border-radius: 6px;

  background-position: center;
  background-size: cover;

  cursor: pointer;

  ${({ $url }) => css`
    background-image: url('${$url ? $url : map_test}');
  `}

  &:hover {
    background-color: rgba(238, 157, 62, 0.15);
  }

  ${({ $disabled }) =>
    $disabled &&
    css`
      opacity: 0.8;
      cursor: default;
      &:hover {
        background-color: transparent;
      }
    `}

  transition: 0.2s;
`

interface StyledMapItemMaskProps {
  $isActive: boolean
  $disabled?: boolean
  $isInactive: boolean
  $status: SettingsStatus
}

const StyledMapItemMask = styled.div<StyledMapItemMaskProps>`
  position: absolute;
  height: 150px;
  width: 150px;

  border-radius: 6px;

  &:hover {
    background: ${({ $status }) => getColor($status).hover};
  }

  ${({ $status, $isActive, $isInactive }) => css`
    border: ${$isActive
      ? `2px solid ${getColor($status).border}`
      : '2px solid transparent'};
    background: ${$isActive
      ? getColor($status).background
      : $isInactive
        ? 'rgba(0, 0, 0, 0.5)'
        : 'transparent'};
  `}

  ${({ $status, $isActive, $disabled, $isInactive }) =>
    $disabled &&
    css`
      cursor: default;
      &:hover {
        background: ${$isActive
          ? getColor($status).hover
          : $isInactive
            ? 'rgba(0, 0, 0, 0.5)'
            : 'transparent'};
      }
    `}

  transition: 0.2s;
`

const StlyledCheckboxEmpty = styled(CheckboxEmpty)`
  position: absolute;
  top: 7px;
  left: 7px;
`

const StlyledCheckboxFilled = styled(CheckboxFilled)`
  position: absolute;
  top: 7px;
  left: 7px;
`

const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  height: 24px;
  width: 100%;
`

const StyledLine = styled.div`
  width: 100%;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  margin-bottom: -6px;
`

const StyledText = styled(Header5)`
  color: rgba(255, 255, 255, 0.3);
  text-transform: uppercase;
`

const StyledAllCheckboxFilled = styled(CheckboxFilled)`
  cursor: pointer;
`

const StyledAllCheckboxEmpty = styled(CheckboxEmpty)`
  cursor: pointer;
`

interface MapListProps {
  disabled?: boolean
  multiple?: boolean
  mapList?: DtoGameMap[]
  status?: SettingsStatus
  currentMaps?: DtoGameMap[] | null
  onChangeGameMap?: (ids: string[] | null) => void
}

const MapList: React.FC<MapListProps> = ({
  mapList,
  multiple,
  disabled,
  currentMaps,
  onChangeGameMap,
  status = 'preparing',
}) => {
  const { t } = useTranslation()

  const handleChangeGameMap = (id: string) => () => {
    if (disabled || !onChangeGameMap || !currentMaps) return

    if (multiple) {
      const newValues = currentMaps.some((m) => m.id === id)
        ? currentMaps.filter((v) => v.id !== id).map((m) => m.id || '')
        : [...currentMaps.map((m) => m.id || ''), id]

      onChangeGameMap(newValues)
      return
    }

    return onChangeGameMap([id])
  }

  const multipleSelect = (select: boolean) => () => {
    if (disabled || !onChangeGameMap || !currentMaps || !mapList) return
    return onChangeGameMap(select ? mapList.map((m) => m.id || '') : [])
  }

  const isMapChoosen = (id?: string) =>
    Boolean(currentMaps?.some((m) => m.id === id))

  const isAllSelected = Boolean(currentMaps?.length === mapList?.length)

  return (
    <>
      <StyledMapWrapper>
        <StyledHeader>
          <StyledLine />
          {multiple && (
            <>
              <StyledText>{t('play.all')}</StyledText>

              {isAllSelected ? (
                <StyledAllCheckboxFilled
                  onClick={multipleSelect(false)}
                  color={
                    status === 'preparing' ? '#ffcd29' : 'rgba(44, 255, 128, 1)'
                  }
                />
              ) : (
                <StyledAllCheckboxEmpty onClick={multipleSelect(true)} />
              )}
            </>
          )}
        </StyledHeader>
        <StyledMapContainer>
          {mapList?.map((_, index) => (
            <StyledMapItem
              key={index}
              $disabled={disabled}
              title={mapList?.[index]?.label}
              $url={mapList?.[index]?.imgUrl}
              onClick={handleChangeGameMap(mapList[index].id || '')}
            >
              <StyledMapItemMask
                $status={status}
                $disabled={disabled}
                $isActive={isMapChoosen(mapList?.[index]?.id)}
                $isInactive={Boolean(
                  currentMaps?.length && !isMapChoosen(mapList?.[index]?.id)
                )}
              />
              {!isMapChoosen(mapList?.[index]?.id) && <StlyledCheckboxEmpty />}
              {isMapChoosen(mapList?.[index]?.id) && mapList?.[index]?.id && (
                <StlyledCheckboxFilled
                  color={
                    status === 'preparing' ? '#ffcd29' : 'rgba(44, 255, 128, 1)'
                  }
                />
              )}
            </StyledMapItem>
          ))}
        </StyledMapContainer>
      </StyledMapWrapper>
    </>
  )
}

export default MapList
