import { $match } from 'stores/lobbyStore'
import { $userProfile } from 'stores/userStore'
import {
  AppealStageTypes,
  handleCreateNewAppeal,
} from 'stores/chatStores/appealStore'
import { EnumClaimReportCategory } from 'api/schemas/supportApi'
import {
  Header1,
  Header2,
  Header3,
  SideHeader2,
  SideHeader3,
  Text4,
  Text5,
} from 'styles/typography'
import { setTabActive } from 'stores/chatStores/chatStore'
import { useStore } from '@nanostores/react'
import { useTranslation } from 'react-i18next'
import Coin from 'images/Coin'
import MatchDemo from 'components/MatchResults/MatchDemo'
import MatchInfoHeader from 'components/MatchResults/MatchInfoHeader'
import MatchItem from 'components/PlayerCardResultCS1x1/MatchItem'
import ModalHeader from 'components/Modals/ModalHeader'
import ModalWrapper from 'components/Modals/ModalWrapper'
import PlayerCardResultCS1x1 from 'components/PlayerCardResultCS1x1/PlayerCardResultCS1x1'
import React from 'react'
import ReportServerButton from 'components/ReportServerButton'
import SwitchIcon from 'images/SwitchIcon'
import cardBG from 'images/1x1CSResultsStatsBG.png'
import styled, { css } from 'styled-components'

interface MatchItemRound {
  result: 'lose' | 'win' | 'drow'
  typeOfWin:
    | 'Elimination'
    | 'HostagesRescued'
    | 'HostagesNotRescued'
    | 'BombDefused'
    | 'BombExploded'
    | null
}

export type Round = Record<string, MatchItemRound>

const MatchResult1x1CS = () => {
  const { t } = useTranslation()
  const currentUser = useStore($userProfile)
  const match = useStore($match)

  if (!match || !currentUser) return null

  const you = match.participants?.find(
    ({ user }) => user?.id === currentUser.id
  )

  const opponent = match.participants?.find(
    (participant) => participant !== you
  )

  if (!you || !opponent) {
    return null
  }

  const isYouWinner = match.CSMatchResult?.winTeamColor === you.teamColor
  const isOpponentWinner =
    match.CSMatchResult?.winTeamColor === opponent.teamColor

  const youScore = isYouWinner ? match.winTeamScore : match.loseTeamScore

  const opponentScore = isOpponentWinner
    ? match.winTeamScore
    : match.loseTeamScore

  const rounds =
    match.CSMatchResult?.CSMatchRoundResults?.reduce((acc, round) => {
      const yourRound = {
        result:
          round?.winTeamColor?.toUpperCase() === you?.teamColor
            ? 'win'
            : 'lose',
        typeOfWin: round.winCondition as
          | 'Elimination'
          | 'HostagesRescued'
          | 'HostagesNotRescued'
          | 'BombDefused'
          | 'BombExploded'
          | null,
      } as MatchItemRound

      const opponentsRound = {
        result:
          round?.winTeamColor?.toUpperCase() === opponent?.teamColor
            ? 'win'
            : 'lose',
        typeOfWin: round.winCondition as
          | 'Elimination'
          | 'HostagesRescued'
          | 'HostagesNotRescued'
          | 'BombDefused'
          | 'BombExploded'
          | null,
      } as MatchItemRound
      const roundData = {
        [you.user?.id as string]: yourRound,
        [opponent.user?.id as string]: opponentsRound,
      }

      acc.push(roundData)

      return acc
    }, [] as Round[]) || []

  const bombsInfo = rounds.reduce(
    (acc, round) => {
      if (
        round[you.user?.id as string].result === 'win' &&
        round[you.user?.id as string].typeOfWin === 'BombDefused'
      ) {
        acc.you.defused = acc.you.defused + 1
      }
      if (
        round[you.user?.id as string].result === 'win' &&
        round[you.user?.id as string].typeOfWin === 'BombExploded'
      ) {
        acc.you.exploded = acc.you.exploded + 1
      }

      if (
        round[opponent.user?.id as string].result === 'win' &&
        round[opponent.user?.id as string].typeOfWin === 'BombDefused'
      ) {
        acc.opponent.defused = acc.opponent.defused + 1
      }
      if (
        round[opponent.user?.id as string].result === 'win' &&
        round[opponent.user?.id as string].typeOfWin === 'BombExploded'
      ) {
        acc.opponent.exploded = acc.opponent.exploded + 1
      }

      return acc
    },
    {
      you: { defused: 0, exploded: 0 },
      opponent: { defused: 0, exploded: 0 },
    }
  )

  const handlePlayerReport =
    (userId: string) => async (e: React.MouseEvent<HTMLElement>) => {
      e.preventDefault()
      e.stopPropagation()
      setTabActive('support', true)
      await handleCreateNewAppeal({
        game: 'CS 2',
        reportedUserId: userId,
        reportedMatchId: match.id,
        nextStep: AppealStageTypes.ADD_FILES,
        prevStep: AppealStageTypes.CHOOSE_PLAYER,
        currentStep: AppealStageTypes.CHOOSE_PLAYER_REASON,
        category: EnumClaimReportCategory.ClaimReportCategoryPlayerReport,
      })
    }

  return (
    <StyledModalWrapper>
      <StyledModalHeader>
        <ModalHeader title={t('modal.titles.matchResults')} />
      </StyledModalHeader>
      <StyledWrapper>
        <StyledHeader>
          <StyledMainInfo>
            <MatchInfoHeader />
          </StyledMainInfo>

          <StyledBalance>
            <StyledCoin />
            <div>{match?.bet}</div>
          </StyledBalance>
        </StyledHeader>

        <StyledRoot>
          <StyledTeams>
            <StyledTeam>
              <PlayerCardResultCS1x1 score={youScore} player={you.user} />
            </StyledTeam>
            <StyledTeam>
              <PlayerCardResultCS1x1
                score={opponentScore}
                player={opponent.user}
              />
            </StyledTeam>
          </StyledTeams>
          <StyledTeamsInfo>
            <StyledTeamInfo>
              {you.teamColor === 'RED' ? (
                <StyledRedTeamTitle>
                  {t('play.ratingGame.you')}
                </StyledRedTeamTitle>
              ) : (
                <StyledBlueTeamTitle>
                  {t('play.ratingGame.you')}
                </StyledBlueTeamTitle>
              )}
            </StyledTeamInfo>
            <StyledTeamInfo>
              {opponent.teamColor === 'RED' ? (
                <StyledRedTeamTitle>
                  {t('play.ratingGame.opponent')}
                </StyledRedTeamTitle>
              ) : (
                <StyledBlueTeamTitle>
                  {t('play.ratingGame.opponent')}
                </StyledBlueTeamTitle>
              )}
            </StyledTeamInfo>
          </StyledTeamsInfo>
          <StyledRoundsWrapper>
            <StyledRoundsPart>
              <StyledRoundsLine>
                <StyledRoundsLineTitle
                  $align="left"
                  $color={opponent.teamColor}
                >
                  {t('play.ratingGame.opponent')}
                </StyledRoundsLineTitle>
                {rounds.slice(0, 8).map((round, index) => (
                  <MatchItem
                    key={index}
                    round={index + 1}
                    result={round[opponent.user?.id || ''].result}
                    typeOfWin={round[opponent.user?.id || ''].typeOfWin}
                  />
                ))}
              </StyledRoundsLine>
              <StyledRoundsDivider />
              <StyledRoundsLine>
                <StyledRoundsLineTitle $align="left" $color={you.teamColor}>
                  {t('play.ratingGame.you')}
                </StyledRoundsLineTitle>
                {rounds.slice(0, 8).map((round, index) => (
                  <MatchItem
                    key={index}
                    round={index + 1}
                    result={round[you.user?.id || ''].result}
                    typeOfWin={round[you.user?.id || ''].typeOfWin}
                  />
                ))}
              </StyledRoundsLine>
            </StyledRoundsPart>
            <StyledChangeSidesWrapper>
              <StyledSwitchIcon />

              <Header3>{t('play.ratingGame.changeSides')}</Header3>
              <StyledNumberOfChangedSides>8</StyledNumberOfChangedSides>
            </StyledChangeSidesWrapper>
            <StyledRoundsPart>
              <StyledRoundsLine>
                {rounds.slice(8).map((round, index) => (
                  <MatchItem
                    key={index}
                    round={index + 1}
                    result={round[you.user?.id || ''].result}
                    typeOfWin={round[you.user?.id || ''].typeOfWin}
                  />
                ))}
                <StyledRoundsLineTitle $align="right" $color={you.teamColor}>
                  {t('play.ratingGame.you')}
                </StyledRoundsLineTitle>
              </StyledRoundsLine>
              <StyledRoundsDivider />
              <StyledRoundsLine>
                {rounds.slice(8).map((round, index) => (
                  <MatchItem
                    key={index}
                    round={index + 1}
                    result={round[opponent.user?.id || ''].result}
                    typeOfWin={round[opponent.user?.id || ''].typeOfWin}
                  />
                ))}
                <StyledRoundsLineTitle
                  $align="right"
                  $color={opponent.teamColor}
                >
                  {t('play.ratingGame.opponent')}
                </StyledRoundsLineTitle>
              </StyledRoundsLine>
            </StyledRoundsPart>
          </StyledRoundsWrapper>
          <StyledStatsWrapper>
            <StyledStatsCard>
              {isYouWinner && <StyledWinnerTitle>winner</StyledWinnerTitle>}
              <StyledStatsHeader>
                <StyledWeaponWrapper>
                  <StyledWeaponTitles>
                    <StyledWeaponName
                      $length={
                        you.user?.CSMatchProfileStat?.CSBestWeaponProfileStat
                          ?.weapon?.name?.length || 0
                      }
                    >
                      {you.user?.CSMatchProfileStat?.CSBestWeaponProfileStat?.weapon?.name?.replace(
                        '_',
                        ' '
                      ) || ''}
                    </StyledWeaponName>
                    <StyledInfoText>{t('results.info')}</StyledInfoText>
                  </StyledWeaponTitles>
                  <StyledWeaponImageWrapper>
                    <StyledStatsTitle>
                      {t('results.topWeapon')}
                    </StyledStatsTitle>
                    <StyledWeaponImg
                      src={
                        you.user?.CSMatchProfileStat?.CSBestWeaponProfileStat
                          ?.weapon?.imgUrl || ''
                      }
                    />
                  </StyledWeaponImageWrapper>
                </StyledWeaponWrapper>
                <StyledDamagesWrapper>
                  <StyledDamageWrapper>
                    <Header3>
                      {you.user?.CSMatchProfileStat?.CSBestWeaponProfileStat
                        ?.headShotCount || 0}
                    </Header3>
                    <StyledStatsTitle>
                      {t('results.headshots')}
                    </StyledStatsTitle>
                  </StyledDamageWrapper>
                  <StyledDamageWrapper>
                    <Header3>
                      {you.user?.CSMatchProfileStat?.CSBestWeaponProfileStat
                        ?.damage || 0}
                    </Header3>
                    <StyledStatsTitle>{t('results.damage')}</StyledStatsTitle>
                  </StyledDamageWrapper>
                </StyledDamagesWrapper>
              </StyledStatsHeader>
              <StyledStatsFooter>
                <StyledStatsFooterItem>
                  <Header2>{bombsInfo.you.defused}</Header2>
                  <StyledStatsTitle>
                    {t('results.bombsCleared')}
                  </StyledStatsTitle>
                </StyledStatsFooterItem>
                <StyledStatsFooterItem>
                  <Header2>{bombsInfo.you.exploded}</Header2>
                  <StyledStatsTitle>{t('results.bombsLaid')}</StyledStatsTitle>
                </StyledStatsFooterItem>
              </StyledStatsFooter>
              <StyledRoundsItems $isWin={isYouWinner}>
                {new Array(20).fill('').map((_, index) => {
                  return (
                    <StyledRoundsItem
                      key={index}
                      $isActive={(youScore || 0) > index}
                    />
                  )
                })}
              </StyledRoundsItems>

              <StyledScoreWrapper>
                <StyledKils $isWin={isYouWinner}>{youScore}</StyledKils>
                <StyledScore>{t('results.score')}</StyledScore>
              </StyledScoreWrapper>
            </StyledStatsCard>
            <StyledStatsCard $isRight>
              {isOpponentWinner && (
                <StyledWinnerTitle $isRight>winner</StyledWinnerTitle>
              )}
              <StyledStatsHeader $isRight>
                <StyledWeaponWrapper $isRight>
                  <StyledWeaponTitles $isRight>
                    <StyledWeaponName
                      $length={
                        opponent.user?.CSMatchProfileStat
                          ?.CSBestWeaponProfileStat?.weapon?.name?.length || 0
                      }
                    >
                      {opponent.user?.CSMatchProfileStat?.CSBestWeaponProfileStat?.weapon?.name?.replace(
                        '_',
                        ' '
                      ) || ''}
                    </StyledWeaponName>
                    <StyledInfoText>{t('results.info')}</StyledInfoText>
                  </StyledWeaponTitles>
                  <StyledWeaponImageWrapper $isRight>
                    <StyledStatsTitle>
                      {t('results.topWeapon')}
                    </StyledStatsTitle>
                    <StyledWeaponImg
                      $isRight
                      src={
                        opponent.user?.CSMatchProfileStat
                          ?.CSBestWeaponProfileStat?.weapon?.imgUrl || ''
                      }
                    />
                  </StyledWeaponImageWrapper>
                </StyledWeaponWrapper>
                <StyledDamagesWrapper>
                  <StyledDamageWrapper $isRight>
                    <Header3>
                      {opponent.user?.CSMatchProfileStat
                        ?.CSBestWeaponProfileStat?.headShotCount || 0}
                    </Header3>
                    <StyledStatsTitle>
                      {t('results.headshots')}
                    </StyledStatsTitle>
                  </StyledDamageWrapper>
                  <StyledDamageWrapper $isRight>
                    <Header3>
                      {opponent.user?.CSMatchProfileStat
                        ?.CSBestWeaponProfileStat?.damage || 0}
                    </Header3>
                    <StyledStatsTitle>{t('results.damage')}</StyledStatsTitle>
                  </StyledDamageWrapper>
                </StyledDamagesWrapper>
              </StyledStatsHeader>
              <StyledStatsFooter $isRight>
                <StyledPlayerStat>
                  <StyledLike>{t('results.like')}</StyledLike>
                  <StyledReport
                    onClick={handlePlayerReport(opponent.user?.id || '')}
                  >
                    {t('results.report')}
                  </StyledReport>
                </StyledPlayerStat>
                <StyledStatsFooterItem>
                  <Header2>{bombsInfo.opponent.defused}</Header2>
                  <StyledStatsTitle>
                    {t('results.bombsCleared')}
                  </StyledStatsTitle>
                </StyledStatsFooterItem>
                <StyledStatsFooterItem>
                  <Header2>{bombsInfo.opponent.exploded}</Header2>
                  <StyledStatsTitle>{t('results.bombsLaid')}</StyledStatsTitle>
                </StyledStatsFooterItem>
              </StyledStatsFooter>
              <StyledRoundsItems $isRight $isWin={isOpponentWinner}>
                {new Array(20).fill('').map((_, index) => {
                  return (
                    <StyledRoundsItem
                      key={index}
                      $isActive={(opponentScore || 0) > index}
                    />
                  )
                })}
              </StyledRoundsItems>
              <StyledScoreWrapper $isRight>
                <StyledKils $isWin={isOpponentWinner}>
                  {opponentScore}
                </StyledKils>
                <StyledScore>{t('results.score')}</StyledScore>
              </StyledScoreWrapper>
            </StyledStatsCard>
          </StyledStatsWrapper>
        </StyledRoot>

        <StyledButtonsWrapper>
          <MatchDemo match={match} />
          <ReportServerButton matchId={match.id!} />
        </StyledButtonsWrapper>
      </StyledWrapper>
    </StyledModalWrapper>
  )
}

const StyledModalWrapper = styled(ModalWrapper)`
  /* padding-bottom: 38px; */
`

const StyledModalHeader = styled.div`
  padding: 13px 11px 13px 20px;
`

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  padding: 0 40px 40px 20px;
`

const StyledHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;
  padding-left: 20px;
  margin-bottom: 28px;
`

const StyledMainInfo = styled.div`
  width: 100%;
  justify-content: center;
  position: relative;
  display: flex;
  gap: 64px;
`

const StyledBalance = styled(Header2)`
  display: flex;
  font-weight: 400;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
`

const StyledCoin = styled(Coin)`
  fill: #ffcd29;
  height: 42px;
  width: 42px;
  margin-right: 20px;
`

const StyledButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  height: 50px;
  margin-top: 20px;
`

const StyledRoot = styled.div``

const StyledTeams = styled.div`
  position: relative;

  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 8px;
`

interface StatSideProps {
  $isRight?: boolean
}

const StyledTeam = styled.div`
  display: flex;
  gap: 5px;
  justify-content: space-between;
`

const StyledTeamsInfo = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 102px;
  margin-bottom: 15px;
`

const StyledTeamInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const StyledRedTeamTitle = styled(SideHeader2)`
  color: #ff4444;
  text-transform: uppercase;
  text-shadow: 0px -1px 13px rgba(255, 65, 65, 0.5);
`

const StyledBlueTeamTitle = styled(SideHeader2)`
  text-transform: uppercase;

  color: #0fa9ff;
  text-shadow: 0px -1px 13px rgba(0, 117, 255, 0.5);
`

interface StyledKilsProps {
  $isWin?: boolean
}

const StyledKils = styled(Header1)<StyledKilsProps>`
  text-transform: uppercase;
  line-height: 100%;
  color: ${({ $isWin }) => ($isWin ? '#2CFF80' : 'rgba(255, 255, 255, 0.15)')};
`

const StyledScore = styled(Header3)`
  color: rgba(255, 255, 255, 0.15);
  text-transform: uppercase;
`

const StyledWeaponImg = styled.img<StatSideProps>`
  position: relative;
  top: -10px;
  max-height: 75px;

  transform: scaleX(${({ $isRight }) => ($isRight ? -1 : 1)});
`

const StyledRoundsWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 24px;
  margin-bottom: 40px;
  align-items: center;
`

const StyledRoundsPart = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

const StyledRoundsDivider = styled.div`
  height: 1px;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.15);
`

const StyledRoundsLine = styled.div`
  display: flex;
  align-items: center;
  width: 386px;
  gap: 10px;
`

interface StyledRoundsLineTitleProps {
  $align: string
  $color?: string
}

const StyledRoundsLineTitle = styled(Text4)<StyledRoundsLineTitleProps>`
  color: ${({ $color }) => ($color === 'RED' ? '#F44' : '#0FA9FF')};
  text-align: ${({ $align }) => $align};
  text-transform: capitalize;
  width: 100%;
`

const StyledChangeSidesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
`

const StyledNumberOfChangedSides = styled(Header3)`
  color: #2cff80;
`

const StyledSwitchIcon = styled(SwitchIcon)`
  width: 100%;
  position: relative;
  fill: white;
  margin-top: 5px;
`

const StyledStatsWrapper = styled.div`
  display: flex;
  gap: 30px;
  width: 100%;
`

interface StatSideProps {
  $isRight?: boolean
}

const StyledStatsCard = styled.div<StatSideProps>`
  width: 771px;
  height: 298px;

  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: ${({ $isRight }) => ($isRight ? '8px 0px ' : '0px 8px')};

  position: relative;

  &:before {
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-image: url('${cardBG}');
    transform: scaleX(${({ $isRight }) => ($isRight ? -1 : 1)});
    background-size: cover;
  }
`

const StyledScoreWrapper = styled.div<StatSideProps>`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  bottom: 24px;
  ${({ $isRight }) =>
    $isRight
      ? css`
          left: 27px;
        `
      : css`
          right: 27px;
        `}
  width: 119px;
`

const StyledWeaponWrapper = styled.div<StatSideProps>`
  display: flex;
  flex-direction: column;
  min-width: 460px;
  ${({ $isRight }) =>
    $isRight
      ? css`
          align-items: end;
        `
      : css`
          align-items: start;
        `}
`

const StyledWeaponTitles = styled.div<StatSideProps>`
  display: flex;
  gap: 12px;
  align-items: start;
  flex-direction: ${({ $isRight }) => ($isRight ? 'row-reverse' : 'row')};
`
interface StyledWeaponNameProps {
  $length?: number
}

const StyledWeaponName = styled(Header2)<StyledWeaponNameProps>`
  text-transform: uppercase;
  line-height: 100%;
  ${({ $length }) =>
    $length &&
    $length > 8 &&
    css`
      font-size: 26px;
    `}
`

const StyledStatsTitle = styled(Text4)`
  color: rgba(255, 255, 255, 0.2);
  white-space: pre-wrap;
  &:first-letter {
    text-transform: uppercase;
  }
`

const StyledStatsHeader = styled.div<StatSideProps>`
  display: flex;

  flex-direction: ${({ $isRight }) => ($isRight ? 'row-reverse' : 'row')};
  padding-top: 18px;
  ${({ $isRight }) =>
    $isRight
      ? css`
          padding-right: 32px;
        `
      : css`
          padding-left: 32px;
        `}
`

const StyledDamageWrapper = styled.div<StatSideProps>`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin: 0 10px;

  & div {
    font-weight: 400;
    line-height: 120%;
  }

  ${({ $isRight }) =>
    $isRight
      ? css`
          align-items: end;
        `
      : css`
          align-items: start;
        `}
`

const StyledDamagesWrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
  justify-content: start;
  gap: 8px;
`

const StyledStatsFooter = styled.div<StatSideProps>`
  display: flex;
  gap: 28px;
  position: absolute;
  bottom: 38px;
  width: calc(100% - 190px);

  ${({ $isRight }) =>
    $isRight
      ? css`
          justify-content: end;
          flex-direction: row-reverse;
          left: 190px;
        `
      : css`
          justify-content: end;
          flex-direction: row;
          right: 190px;
        `}
`

const StyledStatsFooterItem = styled.div`
  display: flex;
  align-items: end;
  gap: 8px;
  & div {
    line-height: 100%;
  }
`

const StyledWeaponImageWrapper = styled.div<StatSideProps>`
  display: flex;
  gap: 35px;
  padding-top: 12px;
  flex-direction: ${({ $isRight }) => ($isRight ? 'row-reverse' : 'row')};
`

const StyledInfoText = styled(SideHeader3)`
  color: rgba(255, 255, 255, 0.2);
`

interface StyledRoundsItemsProps {
  $isWin?: boolean
  $isRight?: boolean
}

const StyledRoundsItems = styled.div<StyledRoundsItemsProps>`
  display: flex;
  gap: 4.4px;
  position: absolute;
  bottom: 15.5px;

  ${({ $isWin }) =>
    $isWin
      ? css`
          &:after {
            content: '';
            position: absolute;
            width: 100%;
            height: 40px;
            flex-shrink: 0;
            bottom: 0px;
            border-radius: 50% 50% 0 0;
            background: linear-gradient(
              0deg,
              rgba(0, 181, 94, 0.4) 0%,
              rgba(0, 86, 60, 0) 60%
            );
            z-index: -10;
            border-radius: 50% 50% 0 0;
          }
        `
      : ''}

  z-index: 0;

  ${({ $isRight }) =>
    $isRight
      ? css`
          right: 12px;
          flex-direction: row-reverse;
        `
      : css`
          flex-direction: row;
          left: 12px;
        `}
`

interface StyledRoundsItemProps {
  $isActive?: boolean
}

const StyledRoundsItem = styled.div<StyledRoundsItemProps>`
  height: 4px;
  width: 25px;
  background-color: ${({ $isActive }) =>
    $isActive ? '#2CFF80' : 'rgba(255, 255, 255, 0.15)'};
`

const StyledWinnerTitle = styled.div<StatSideProps>`
  position: absolute;
  bottom: 65px;
  ${({ $isRight }) =>
    $isRight
      ? css`
          left: 190px;
        `
      : css`
          right: 190px;
        `}
  text-align: center;
  font-family: Nebula;
  -webkit-text-fill-color: transparent;
  width: 550px;
  text-align: center;
  font-family: Nebula;
  font-size: 126px;
  font-style: normal;
  font-weight: 400;
  line-height: 90%;
  color: transparent;
  text-shadow:
    0px 2px 0px rgba(255, 255, 255, 0.05),
    0px -2px 0px rgba(0, 0, 0, 0.15);
  white-space: nowrap;
`

const StyledPlayerStat = styled.div`
  position: relative;
  z-index: 10;
  display: flex;
  gap: 16px;
  align-items: end;
  margin: 0 30px;
  width: -webkit-fill-available;
  justify-content: end;
`

const StyledLike = styled(Text5)`
  color: #2cff80;
  cursor: pointer;
  position: relative;
  &:after {
    content: '';
    width: 100%;
    height: 0.5px;
    background-color: #2cff80;
    position: absolute;
    bottom: -1px;
    left: 0;
  }
  transition: 0.2s;
  &:hover {
    color: white;
    &:after {
      background-color: white;
    }
  }
`

const StyledReport = styled(Text5)`
  color: #f44;
  cursor: pointer;
  position: relative;
  &:after {
    content: '';
    width: 100%;
    height: 0.5px;
    background-color: #f44;
    position: absolute;
    bottom: -1px;
    left: 0;
  }
  transition: 0.2s;
  &:hover {
    color: white;
    &:after {
      background-color: white;
    }
  }
`

export default MatchResult1x1CS
