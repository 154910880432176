import styled from 'styled-components'

import MyFinishedClaims from 'components/Judge/Work/MyFinishedClaims'
import MyWorkClaim from 'components/Judge/Work/MyWorkClaim'
import MyWorkRating from 'components/Judge/Work/MyWorkRating'

const JudgeMyWork = () => {
  return (
    <StyledRoot>
      <MyWorkRating />
      <MyWorkClaim />
      <StyledTableWrapper>
        <MyFinishedClaims />
      </StyledTableWrapper>
    </StyledRoot>
  )
}

const StyledRoot = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 24px;
  gap: 32px;

  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 2px;
  }

  &::-webkit-scrollbar-track {
    margin: 4px 0 4px;
    border-radius: 1px;
    opacity: 0.1;
    background: rgba(217, 217, 217, 0.1);
  }

  &::-webkit-scrollbar-thumb {
    background-color: white;
    border-radius: 1px;
  }
`

const StyledTableWrapper = styled.div`
  margin-top: 8px;
`

export default JudgeMyWork
