import GamesOnline from 'components/Home/GamesOnline'
import HomeSchedule from 'components/Home/HomeSchedule'
import styled from 'styled-components'

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`

const StatisticsFTF: React.FC = () => {
  return (
    <StyledWrapper>
      <HomeSchedule />
      <GamesOnline />
    </StyledWrapper>
  )
}

export default StatisticsFTF
