import { DtoComposite } from 'api/schemas/dictionaryApi'
import { DtoLobby } from 'api/schemas/lobbyApi'
import { DtoMatch } from 'api/schemas/matchApi'
import {
  changeTeam,
  getConfig,
  getMatch,
  leaveLobby,
  setMatch,
} from 'stores/lobbyStore'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import routes from 'router/routes'

const useLobbyCS = ({
  match,
  lobby,
  config,
  isReady,
}: {
  isReady?: boolean
  lobby: DtoLobby | null
  match: DtoMatch | null
  config: DtoComposite | null
}) => {
  const navigate = useNavigate()

  useEffect(() => {
    if (!lobby) {
      navigate(routes.play())
    }
  }, [lobby, navigate])

  useEffect(() => {
    if (!config) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      getConfig()
    }
  }, [config, lobby])

  useEffect(() => {
    if (lobby?.activeMatchId && !match) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      getMatch(lobby?.activeMatchId)
    }
    if (
      !lobby?.activeMatchId &&
      match &&
      !(
        match.status === 'MATCH_FINISHED' ||
        match.status === 'MATCH_PROCESSING_COMPLETED'
      )
    ) {
      setMatch(null)
    }
  }, [match, lobby])

  const handlePositionClick =
    (position: number, team: 'BLUE' | 'RED') => async () => {
      if (!isReady) {
        await changeTeam(team, position)
      }
    }

  const handleLeaveLobby = async () => {
    if (
      lobby?.status === 'NEW' ||
      lobby?.status === 'READY_TO_SEARCH' ||
      lobby?.status === 'SEARCHING' ||
      lobby?.status === 'SEARCHING_STOP'
    ) {
      await leaveLobby()
    }
  }

  return { handleLeaveLobby, handlePositionClick }
}

export default useLobbyCS
