import { getCurrentVolume } from 'stores/userStore'

const playSound = async (audio: HTMLAudioElement) => {
  const volume = getCurrentVolume()

  if (volume > 0) {
    audio.volume = volume / 100
    await audio.play()
  }
}

export default playSound
