const Question: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
    >
      <circle cx="10.46" cy="10.5" r="9.03809" stroke="#FFCD29" />
      <path
        d="M14.0299 8.8684C14.0299 9.2558 13.9125 9.60797 13.6777 9.92493C13.4546 10.2301 13.167 10.4708 12.8149 10.6469L11.6527 11.228C11.3475 11.3689 11.1949 11.5743 11.1949 11.8443V13.1473H9.75094V11.7739C9.75094 11.5508 9.79202 11.3454 9.8742 11.1575C9.95637 10.958 10.0444 10.7936 10.1383 10.6645C10.2322 10.5354 10.3849 10.4004 10.5962 10.2595C10.8192 10.1186 10.9836 10.0188 11.0892 9.96015C11.2066 9.88971 11.4003 9.78993 11.6703 9.6608C11.952 9.53167 12.1281 9.44949 12.1986 9.41428C12.4568 9.27341 12.5859 9.07384 12.5859 8.81558V7.70622C12.5859 7.62405 12.5742 7.55949 12.5507 7.51253C12.4803 7.35992 12.3394 7.19557 12.1281 7.01948C11.9286 6.83166 11.7525 6.73774 11.5999 6.73774H9.6805C9.61007 6.73774 9.55137 6.74948 9.50441 6.77296C9.3518 6.85513 9.17572 6.996 8.97615 7.19557C8.77658 7.3834 8.62984 7.56535 8.53593 7.74144L8.35984 8.09362L6.96875 7.68862C7.1331 7.30122 7.29158 7.00187 7.44419 6.79057C8.16028 5.83969 8.88811 5.36426 9.62768 5.36426H11.6175C12.0636 5.36426 12.4568 5.48752 12.7973 5.73404C12.9264 5.82796 13.0966 5.98057 13.3079 6.19187C13.5192 6.40318 13.6601 6.56165 13.7305 6.66731C13.9301 6.97253 14.0299 7.30709 14.0299 7.67101V8.8684ZM11.3533 16.0176H9.59246V14.3271H11.3533V16.0176Z"
        fill="#FFCD29"
      />
    </svg>
  )
}

export default Question
