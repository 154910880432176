const Logo = () => (
  <svg
    width="29"
    fill="none"
    height="29"
    viewBox="0 0 29 29"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      stroke="#2CFF80"
      strokeWidth="0.411764"
      d="M6.11684 28.3821H0.205078V20.6523L6.11684 14.7939H27.7933V20.6523H9.45004C8.99944 20.6523 8.56859 20.8373 8.25828 21.1641L6.56869 22.943C6.27859 23.2485 6.11684 23.6537 6.11684 24.0749V28.3821Z"
    />
    <path
      fill="#2CFF80"
      stroke="#2CFF80"
      strokeWidth="0.205882"
      d="M6.12152 14.724H0.101562V6.91688L6.12152 0.999878H27.8957V6.91688H9.46147C9.00828 6.91688 8.57519 7.104 8.26459 7.43401L6.56826 9.23636C6.28131 9.54125 6.12152 9.94415 6.12152 10.3628V14.724Z"
    />
    <g filter="url(#filter0_d_4550_37537)">
      <path
        fill="#2CFF80"
        d="M22.3639 25.1732L22.362 26.2869L27.3026 26.2952C27.3118 26.2952 27.3194 26.2998 27.3255 26.3089C27.3346 26.3151 27.3392 26.3242 27.3392 26.3365L27.338 27.0423C27.338 27.0514 27.3334 27.0606 27.3242 27.0697C27.3181 27.0758 27.3104 27.0789 27.3013 27.0789L22.3607 27.0706L22.3582 28.5372C22.3582 28.5494 22.3536 28.5601 22.3444 28.5692C22.3383 28.5753 22.3306 28.5784 22.3215 28.5783L21.4828 28.5769C21.4705 28.5769 21.4599 28.5738 21.4507 28.5677C21.4446 28.5585 21.4416 28.5478 21.4416 28.5356L21.4472 25.1716C21.4478 24.8478 21.5628 24.5714 21.7924 24.3427C22.025 24.1108 22.3047 23.9952 22.6317 23.9958L27.9572 24.0047C27.9695 24.0047 27.9786 24.0093 27.9847 24.0185C27.9939 24.0246 27.9984 24.0338 27.9984 24.046L27.9972 24.7518C27.9972 24.761 27.9926 24.7701 27.9834 24.7793C27.9773 24.7854 27.9681 24.7884 27.9559 24.7884L22.7587 24.7797C22.6517 24.7795 22.5585 24.8191 22.4789 24.8984C22.4024 24.9746 22.364 25.0662 22.3639 25.1732Z"
      />
    </g>
    <g filter="url(#filter1_d_4550_37537)">
      <path
        fill="#2CFF80"
        d="M19.1502 25.1724C19.1504 25.0624 19.1108 24.9691 19.0315 24.8926C18.9552 24.8161 18.8636 24.7777 18.7567 24.7776L13.8207 24.7693C13.8085 24.7692 13.7978 24.7662 13.7886 24.76C13.7825 24.7509 13.7795 24.7402 13.7795 24.728L13.7807 24.0222C13.7807 24.0099 13.7838 24.0008 13.7899 23.9947C13.7991 23.9855 13.8098 23.981 13.822 23.981L18.758 23.9893C19.1186 23.9899 19.4269 24.1187 19.6832 24.3758C19.9394 24.6329 20.0672 24.9417 20.0666 25.3022L20.0665 25.3664C20.0659 25.73 19.937 26.0399 19.6799 26.2961C19.4229 26.5493 19.1141 26.6756 18.7535 26.675L14.8258 26.6684C14.7158 26.6682 14.6225 26.7062 14.546 26.7825C14.4695 26.8587 14.4312 26.9519 14.431 27.0618L14.4298 27.7814L19.7599 27.7903C19.7691 27.7904 19.7767 27.795 19.7828 27.8041C19.792 27.8103 19.7965 27.8194 19.7965 27.8317L19.7953 28.5375C19.7953 28.5466 19.7907 28.5558 19.7815 28.5649C19.7754 28.571 19.7678 28.5741 19.7586 28.5741L13.5531 28.5636C13.5409 28.5636 13.5302 28.5605 13.521 28.5544C13.5149 28.5452 13.5119 28.5361 13.5119 28.5269L13.5141 27.1886C13.5147 26.8281 13.6421 26.5212 13.8961 26.2681C14.1532 26.0118 14.4635 25.884 14.8271 25.8847L18.7548 25.8913C18.8618 25.8914 18.9535 25.8534 19.03 25.7771C19.1096 25.6978 19.1495 25.6047 19.1496 25.4978L19.1502 25.1724Z"
      />
    </g>
    <defs>
      <filter
        y="23.6949"
        x="21.0804"
        width="7.27866"
        height="5.30466"
        id="filter0_d_4550_37537"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          type="matrix"
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy="0.060168" />
        <feGaussianBlur stdDeviation="0.180504" />
        <feComposite operator="out" in2="hardAlpha" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.170833 0 0 0 0 1 0 0 0 0 0.5025 0 0 0 0.4 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_4550_37537"
        />
        <feBlend
          mode="normal"
          result="shape"
          in="SourceGraphic"
          in2="effect1_dropShadow_4550_37537"
        />
      </filter>
      <filter
        y="23.6801"
        x="13.1507"
        width="7.2767"
        height="5.31516"
        id="filter1_d_4550_37537"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          type="matrix"
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy="0.060168" />
        <feGaussianBlur stdDeviation="0.180504" />
        <feComposite operator="out" in2="hardAlpha" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.170833 0 0 0 0 1 0 0 0 0 0.5025 0 0 0 0.4 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_4550_37537"
        />
        <feBlend
          mode="normal"
          result="shape"
          in="SourceGraphic"
          in2="effect1_dropShadow_4550_37537"
        />
      </filter>
    </defs>
  </svg>
)

export default Logo
