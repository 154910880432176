// eslint-disable-next-line @typescript-eslint/no-explicit-any
const CustomizedX: React.FC = (props: any) => {
  const { x, y, payload } = props

  return (
    <svg
      y={y - 2}
      x={x - 10}
      width="20"
      height="15"
      viewBox="0 0 16 15"
      xmlns="http://www.w3.org/2000/svg"
    >
      <text
        y="15"
        x="10"
        fill="white"
        fontSize={12}
        textAnchor="middle"
        fontFamily="Nebula, Nebulosa"
      >
        {payload.value}
      </text>
      <rect x="6" rx="3" width="5" height="5" fill="#2CFF80" />
    </svg>
  )
}

export default CustomizedX
