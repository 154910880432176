const JudgeLinkIcon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
    >
      <g clip-path="url(#clip0_11811_97894)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M13.2341 7.86817L13.2341 20.8682H15.7341C16.0103 20.8682 16.2341 21.092 16.2341 21.3682V22.3682C16.2341 22.6443 16.0103 22.8682 15.7341 22.8682H12.7341H11.7341H8.73415C8.458 22.8682 8.23415 22.6443 8.23415 22.3682V21.3682C8.23415 21.092 8.458 20.8682 8.73415 20.8682H11.2341L11.2341 7.86817H6.93258L8.04268 10.0111C8.13669 10.1926 8.01206 10.3795 7.79704 10.3795L6.76534 10.3795C6.5383 10.3795 6.31786 10.25 6.2186 10.0584L5.23338 8.15655L4.24816 10.0584C4.1489 10.25 3.92846 10.3795 3.70142 10.3795L2.66972 10.3795C2.4547 10.3795 2.33007 10.1926 2.42408 10.0111L3.53418 7.86817H2.73415C2.458 7.86817 2.23415 7.64431 2.23415 7.36817L2.23415 6.36816C2.23415 6.09202 2.458 5.86816 2.73415 5.86816L11.2341 5.86816V3.36816C11.2341 3.09202 11.458 2.86816 11.7341 2.86816H12.7341C13.0103 2.86816 13.2341 3.09202 13.2341 3.36816V5.86816L21.7341 5.86816C22.0103 5.86816 22.2341 6.09202 22.2341 6.36816V7.36817C22.2341 7.64431 22.0103 7.86817 21.7341 7.86817H20.6328L21.9555 13.3685C22.0092 13.592 21.8361 13.869 21.6427 13.869L20.4724 13.869C20.3209 13.869 20.2012 13.7608 20.1591 13.5857L19.0752 9.07815L17.9913 13.5857C17.9492 13.7608 17.8295 13.869 17.6779 13.869L16.5077 13.869C16.3143 13.869 16.1412 13.592 16.1949 13.3685L17.5176 7.86817H13.2341ZM9.73415 11.8682C10.0103 11.8682 10.2367 12.0926 10.2092 12.3673C10.0946 13.5101 9.58896 14.5844 8.76968 15.4037C7.832 16.3414 6.56023 16.8682 5.23415 16.8682C3.90807 16.8682 2.6363 16.3414 1.69861 15.4037C0.879336 14.5844 0.373737 13.5101 0.259112 12.3673C0.231552 12.0926 0.458005 11.8682 0.734147 11.8682L5.23415 11.8682H9.73415ZM24.2084 15.3673C24.236 15.0926 24.0095 14.8682 23.7334 14.8682H19.2334L14.7334 14.8682C14.4573 14.8682 14.2308 15.0926 14.2584 15.3673C14.373 16.5101 14.8786 17.5844 15.6979 18.4037C16.6355 19.3414 17.9073 19.8682 19.2334 19.8682C20.5595 19.8682 21.8313 19.3414 22.7689 18.4037C23.5882 17.5844 24.0938 16.5101 24.2084 15.3673Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_11811_97894">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0.234375 0.868164)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default JudgeLinkIcon
