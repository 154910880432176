import { ChangeEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { DtoJudgeClaim, EnumGameExperience } from 'api/schemas/supportApi'

import { Header3, Header5, Header7 } from 'styles/typography'
import { submitJudgeClaim } from 'stores/judgeStore'
import GameExperienceInput from 'components/Judge/JoinPopup/GameExperienceInput'
import Input from 'components/Input'
import JudgeExperienceAttachmentsInput from 'components/Judge/JoinPopup/JudgeExperienceAttachmentsInput'
import JudgeExperienceInput from 'components/Judge/JoinPopup/JudgeExperienceInput'
import RequiredFieldIndicator from 'components/RequiredFieldIndicator'

interface JoinJudgePopupProps {
  onClose: () => void
  claim: DtoJudgeClaim
}

const JoinJudgePopup = ({ claim, onClose }: JoinJudgePopupProps) => {
  const { t } = useTranslation()

  const [gameExperience, setGameExperience] = useState(
    claim.gameExperience || EnumGameExperience.GameExperienceLessThanYear
  )
  const [judgeExperienceExists, setJudgeExperienceExists] = useState(true)
  const [description, setDescription] = useState(claim.description)
  const [isLoading, setIsLoading] = useState(false)

  const disabled =
    !description ||
    (judgeExperienceExists && !claim.attachments?.length) ||
    isLoading

  const handleSubmit = async () => {
    if (disabled) return

    try {
      setIsLoading(true)

      await submitJudgeClaim(claim.id!, {
        description,
        gameExperience,
        judgeExperienceExists,
      })

      onClose()
    } catch (error) {
      console.log('error', error)
      setIsLoading(false)
    }
  }

  return (
    <StyledWrapper>
      <StyledCaption>{t('judge.welcomePage.popupCaption')}</StyledCaption>

      <StyledContainer>
        <GameExperienceInput
          value={gameExperience}
          onChange={setGameExperience}
        />

        <JudgeExperienceInput
          value={judgeExperienceExists}
          onChange={setJudgeExperienceExists}
        />

        {judgeExperienceExists && <JudgeExperienceAttachmentsInput />}

        <StyledInputWrapper>
          <StyledInputCaption>
            {t('judge.welcomePage.motivation')}
            <RequiredFieldIndicator />
          </StyledInputCaption>
          <StyledInput
            placeholder={t('judge.welcomePage.startTyping')}
            value={description}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setDescription(e.target.value)
            }
          />
        </StyledInputWrapper>

        <StyledButtonsWrapper>
          <StyledCancel onClick={onClose}>
            {t('judge.welcomePage.cancel')}
          </StyledCancel>
          <StyledJoin $disabled={disabled} onClick={handleSubmit}>
            {t('judge.welcomePage.join')}
          </StyledJoin>
        </StyledButtonsWrapper>
      </StyledContainer>
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 532px;
  padding: 64px 24px 24px 24px;
  border-radius: 6px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  background: #252525;
`
const StyledCaption = styled(Header3)`
  color: #ffcd29;
  text-align: center;
  font-style: normal;
  font-weight: 400;
  line-height: 115%;
  text-transform: uppercase;
  margin-bottom: 32px;
`
const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const StyledInputWrapper = styled.div`
  margin-top: 25px;
`
const StyledInputCaption = styled(Header7)`
  display: flex;
  margin-bottom: 8px;
  gap: 4px;
`
const StyledInput = styled(Input)`
  & > div {
    height: 32px;
  }

  ::placeholder {
    color: rgba(255, 255, 255, 0.25);
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 115.5%;
  }
`
const StyledButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 48px;
  gap: 32px;
`
const StyledCancel = styled(Header5)`
  flex: 1;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
`
interface JoinDisabled {
  $disabled: boolean
}
const StyledJoin = styled(Header5)<JoinDisabled>`
  flex: 1;
  text-transform: uppercase;
  padding: 8px 20px;
  border-radius: 3px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  text-align: center;
  background: ${({ $disabled }) =>
    $disabled ? 'rgba(255, 255, 255, 0.15)' : 'rgba(255, 255, 255, 0.03)'};
  color: ${({ $disabled }) =>
    $disabled ? 'rgba(255, 255, 255, 0.15)' : 'rgba(255, 255, 255, 1)'};
  backdrop-filter: blur(7.5px);
  transition: 0.2s;
  cursor: ${({ $disabled }) => ($disabled ? 'not-allowed' : 'pointer')};

  &:hover {
    background-color: ${({ $disabled }) =>
      $disabled ? 'rgba(255, 255, 255, 0.15)' : 'rgba(238, 157, 62, 0.15)'};
  }
`

export default JoinJudgePopup
