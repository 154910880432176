import { SVGProps } from 'react'

const Arrow = ({ ...rest }: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="25"
    fill="none"
    viewBox="0 0 24 25"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      fill="white"
      d="M11.8336 15.3891L6.04453 11.5297C6.01671 11.5111 6 11.4799 6 11.4465L6 9.68685C6 9.60698 6.08901 9.55934 6.15547 9.60365L11.8336 13.3891C11.9344 13.4562 12.0656 13.4562 12.1664 13.3891L17.8445 9.60365C17.911 9.55934 18 9.60698 18 9.68685L18 11.4465C18 11.4799 17.9833 11.5111 17.9555 11.5297L12.1664 15.3891C12.0656 15.4562 11.9344 15.4562 11.8336 15.3891Z"
    />
  </svg>
)

export default Arrow
