import styled from 'styled-components'

import {
  DtoJudgeClaim,
  EnumCreateJudgeClaimStatus,
} from 'api/schemas/supportApi'

import JudgeApplicationWizard from 'components/Judge/JudgeApplicationWizard'
import JudgeJoinButton from 'components/Judge/Welcome/JudgeJoinButton'
import JudgeWelcomeSlider from 'components/Judge/Welcome/JudgeWelcomeSlider'

interface JudgeWelcomeProps {
  claim: DtoJudgeClaim | null
}

const JudgeWelcome = ({ claim }: JudgeWelcomeProps) => {
  const showWizard =
    claim &&
    claim.createStatus ===
      EnumCreateJudgeClaimStatus.CreateJudgeClaimStatusCompleted

  return (
    <StyledWrapper>
      <JudgeWelcomeSlider />

      {showWizard ? (
        <JudgeApplicationWizard claim={claim} />
      ) : (
        <JudgeJoinButton claim={claim} />
      )}
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  width: 1630px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 18px;
`

export default JudgeWelcome
