const BackIcon = ({ className }: { className?: string }) => (
  <svg
    width="24"
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="#FFCD29"
      clip-rule="evenodd"
      fill-rule="evenodd"
      d="M4.54072 8.22717L7.42876 10.9535L6.05586 12.4078L1.31355 7.93109C1.11342 7.74217 1 7.47913 1 7.20392C1 6.92871 1.11342 6.66566 1.31355 6.47674L6.05586 2L7.42876 3.45435L4.49145 6.22717H15.2501C19.5303 6.22717 23.0001 9.69696 23.0001 13.9772C23.0001 18.2574 19.5303 21.7272 15.2501 21.7272H2.00012V19.7272H15.2501C18.4258 19.7272 21.0001 17.1528 21.0001 13.9772C21.0001 10.8015 18.4258 8.22717 15.2501 8.22717H4.54072Z"
    />
  </svg>
)

export default BackIcon
