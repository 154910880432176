const FriendInviteIcon = ({ className }: { className?: string }) => (
  <svg
    width="37"
    height="37"
    fill="none"
    viewBox="0 0 37 37"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="#FFCD29"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19.7413 11.722C19.7413 14.1424 17.7791 16.1046 15.3587 16.1046C12.9382 16.1046 10.9761 14.1424 10.9761 11.722C10.9761 9.30152 12.9382 7.33936 15.3587 7.33936C17.7791 7.33936 19.7413 9.30152 19.7413 11.722ZM18.9495 19.1888C17.1836 20.4957 16.0387 22.5939 16.0387 24.9595C16.0387 25.5409 16.1079 26.1062 16.2385 26.6476H15.3583L7.08008 26.6476C7.08008 24.4521 7.95225 22.3465 9.50473 20.794C11.0572 19.2415 13.1628 18.3693 15.3583 18.3693C16.6136 18.3693 17.8396 18.6545 18.9495 19.1888Z"
    />
    <path
      fill="#FFCD29"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M25.3137 19.5835H23.0875V23.4794L19.1914 23.4794V25.7056H23.0875V29.6016H25.3137V25.7056H29.2095V23.4794L25.3137 23.4794V19.5835Z"
    />
  </svg>
)

export default FriendInviteIcon
