const LobbyInviteIcon = ({ className }: { className?: string }) => (
  <svg
    width="66"
    height="33"
    fill="none"
    viewBox="0 0 66 33"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="#FFCD29"
      clipRule="evenodd"
      fillRule="evenodd"
      d="M19.6929 15.9767L15.088 11.6296L16.4609 10.1753L22.8955 16.2496C23.0956 16.4385 23.209 16.7015 23.209 16.9767C23.209 17.252 23.0956 17.515 22.8955 17.7039L16.4609 23.7782L15.088 22.3239L19.6929 17.9767H7V15.9767H19.6929Z"
    />
    <path
      fill="#FFCD29"
      clipRule="evenodd"
      fillRule="evenodd"
      d="M45.1499 15.3936C47.0535 15.3936 48.5967 13.8505 48.5967 11.9468C48.5967 10.0432 47.0535 8.5 45.1499 8.5C43.2463 8.5 41.7031 10.0432 41.7031 11.9468C41.7031 13.8505 43.2463 15.3936 45.1499 15.3936ZM49.7536 18.8963C50.9746 20.1173 51.6606 21.7733 51.6606 23.5H45.1499L38.6392 23.5C38.6392 21.7733 39.3252 20.1173 40.5462 18.8963C41.7671 17.6753 43.4232 16.9893 45.1499 16.9893C46.8766 16.9893 48.5326 17.6753 49.7536 18.8963ZM56.2107 14.4698C56.2107 15.9255 55.0306 17.1056 53.5749 17.1056C52.1192 17.1056 50.9391 15.9255 50.9391 14.4698C50.9391 13.0141 52.1192 11.834 53.5749 11.834C55.0306 11.834 56.2107 13.0141 56.2107 14.4698ZM58.5538 23.4999C58.5538 22.1795 58.0292 20.9131 57.0955 19.9794C56.1618 19.0457 54.8955 18.5212 53.575 18.5212C52.9368 18.5212 52.3111 18.6437 51.7296 18.8758C52.6748 20.2209 53.1919 21.8337 53.1919 23.4999H53.575H58.5538ZM36.9788 17.1056C35.5231 17.1056 34.343 15.9255 34.343 14.4698C34.343 13.0141 35.5231 11.834 36.9788 11.834C38.4345 11.834 39.6146 13.0141 39.6146 14.4698C39.6146 15.9255 38.4345 17.1056 36.9788 17.1056ZM33.4582 19.9794C32.5245 20.9131 32 22.1795 32 23.4999H36.9788H37.3619C37.3619 21.8337 37.8789 20.2209 38.8241 18.8758C38.2426 18.6437 37.617 18.5212 36.9787 18.5212C35.6583 18.5212 34.3919 19.0457 33.4582 19.9794Z"
    />
  </svg>
)

export default LobbyInviteIcon
