import { logError } from 'utils/logger'

const getPing = async (server?: string) => {
  if (server) {
    try {
      const time = await window.ipcRenderer.invoke('getPing', server)

      return time
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      logError(err?.message)
    }
  }
  return 999
}
export default getPing
