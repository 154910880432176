import { DtoLobby } from 'api/schemas/lobbyApi'
import { Header5, Header6, SideHeader4, Text6 } from 'styles/typography'
import { Player } from 'stores/lobbyStore'
import { useTranslation } from 'react-i18next'
import AvatarWithFlag from 'components/AvatarWithFlag'
import MatchesCircleIcon from 'images/MatchesCircleIcon'
import PlayerStatuses from 'components/PlayerCardMin/PlayerStatuses'
import PowerCSIcon from 'images/PowerCSIcon'
import blue_0 from 'images/userCard/readyWrappers/blue_0.png'
import blue_1 from 'images/userCard/readyWrappers/blue_1.png'
import blue_2 from 'images/userCard/readyWrappers/blue_2.png'
import blue_3 from 'images/userCard/readyWrappers/blue_3.png'
import blue_4 from 'images/userCard/readyWrappers/blue_4.png'
import cardCSGoBackground from 'images/CardCSGoBackground.png'
import red_0 from 'images/userCard/readyWrappers/red_0.png'
import red_1 from 'images/userCard/readyWrappers/red_1.png'
import red_2 from 'images/userCard/readyWrappers/red_2.png'
import red_3 from 'images/userCard/readyWrappers/red_3.png'
import red_4 from 'images/userCard/readyWrappers/red_4.png'
import styled from 'styled-components'

const getWrapper = (team: 'RED' | 'BLUE', position: number) => {
  switch (`${team}_${position}`) {
    case 'BLUE_0':
      return blue_0
    case 'BLUE_1':
      return blue_1
    case 'BLUE_2':
      return blue_2
    case 'BLUE_3':
      return blue_3
    case 'BLUE_4':
      return blue_4
    case 'RED_0':
      return red_0
    case 'RED_1':
      return red_1
    case 'RED_2':
      return red_2
    case 'RED_3':
      return red_3
    case 'RED_4':
      return red_4
  }
}

const StyledCardWrapper = styled.div`
  position: relative;
  background-image: url('${cardCSGoBackground}');
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  flex-direction: column;
  width: 158px;
  height: 233px;
  padding-top: 3px;
  /* cursor: pointer; */
`

const StyledMainInfoWrapper = styled.div`
  display: flex;
  justify-content: start;
  gap: 9px;
`

const StyledPower = styled(Header6)`
  display: flex;
  align-items: center;
  gap: 4px;
  font-weight: 600;
`

const StyledNickname = styled(Header5)``

const StyledMainInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const StyledMatchesWrapper = styled.div`
  position: relative;
  display: flex;
`

const StyledMatchesTitle = styled(SideHeader4)`
  position: absolute;
  color: rgba(255, 255, 255, 0.2);
  left: 12px;
`

const StyledMatchesContainer = styled(Header6)`
  padding-top: 13px;
  padding-left: 12px;
  padding-right: 12px;
  width: 100%;
  gap: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  font-weight: normal;
`

const StyledTotal = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledRanked = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledLoseWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: end;
`

const StyledText = styled(Text6)`
  color: rgba(255, 255, 255, 0.45);
`

const StyledWins = styled.div`
  padding-top: 3px;
  display: flex;
  align-items: center;
  gap: 4px;
`

const StyledWinsIcon = styled(MatchesCircleIcon)`
  fill: #5fe33e;
`

const StyledDrowIcon = styled(MatchesCircleIcon)`
  fill: #f2b927;
`

const StyledLoseIcon = styled(MatchesCircleIcon)`
  fill: #f84848;
`

const StyledDrow = styled.div`
  padding-top: 3px;
  display: flex;
  align-items: center;
  gap: 4px;
`

const StyledLose = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`

const StyledStatWrapper = styled(Header6)`
  padding-top: 10px;
  padding-left: 12px;
  padding-right: 12px;
  width: 100%;
  display: grid;
  row-gap: 5px;
  grid-template-columns: 1fr 1fr 1fr;
  font-weight: normal;
`

const StyledStatItem = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledStatKDItem = styled.div`
  grid-column-start: 1;
  grid-column-end: 3;
`

const StyledSettingsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 5px 12px;
`

interface StyledStatusWrapperProps {
  $player: Player
}

const StyledStatusWrapper = styled.div<StyledStatusWrapperProps>`
  width: 158px;
  height: 233px;
  bottom: 0;
  position: absolute;
  background-image: ${({ $player }) =>
    `url('${getWrapper($player.team, $player.position)}')`};

  opacity: ${({ $player }) => ($player.isReady ? 1 : 0)};

  transition: 0.2s;
  z-index: -1;
`

interface DotaUserCardProps {
  player: Player
  lobby?: DtoLobby
  disabled?: boolean
}

const DotaUserCard: React.FC<DotaUserCardProps> = ({ player }) => {
  const { t } = useTranslation()
  return (
    <StyledCardWrapper>
      <StyledMainInfoWrapper>
        <AvatarWithFlag />
        <StyledMainInfo>
          <StyledPower>
            <PowerCSIcon />
            <div>120</div>
          </StyledPower>
          <StyledNickname>MarkQuincy</StyledNickname>
        </StyledMainInfo>
      </StyledMainInfoWrapper>
      <StyledMatchesWrapper>
        <StyledMatchesTitle>{t('play.userCard.matches')}</StyledMatchesTitle>
        <StyledMatchesContainer>
          <StyledTotal>
            <div>232</div>
            <StyledText>Total</StyledText>
            <StyledWins>
              <StyledWinsIcon />
              134
            </StyledWins>
          </StyledTotal>
          <StyledRanked>
            <div>42</div>
            <StyledText>Ranked</StyledText>
            <StyledDrow>
              <StyledDrowIcon />4
            </StyledDrow>
          </StyledRanked>
          <StyledLoseWrapper>
            <StyledLose>
              <StyledLoseIcon />
              72
            </StyledLose>
          </StyledLoseWrapper>
        </StyledMatchesContainer>
      </StyledMatchesWrapper>
      <StyledStatWrapper>
        <StyledStatItem>
          <div>233.4</div>
          <StyledText>LH</StyledText>
        </StyledStatItem>
        <StyledStatItem>
          <div>34.5K</div>
          <StyledText>DMG</StyledText>
        </StyledStatItem>
        <StyledStatItem>
          <div></div>
          <StyledText>Favorite</StyledText>
        </StyledStatItem>
        <StyledStatKDItem>
          <div>1.3 / 0.4 / 0.1</div>
          <StyledText>KDA</StyledText>
        </StyledStatKDItem>
        <StyledStatItem>
          <div>24.4K</div>
          <StyledText>NET</StyledText>
        </StyledStatItem>
      </StyledStatWrapper>
      <StyledSettingsWrapper>
        <PlayerStatuses isYou isHost />
      </StyledSettingsWrapper>
      <StyledStatusWrapper $player={player} />
    </StyledCardWrapper>
  )
}

export default DotaUserCard
