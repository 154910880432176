import styled from 'styled-components'

const ModalWrapper = styled.div`
  position: absolute;
  border: 1px solid rgba(255, 255, 255, 0.3);
  background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0) 73.99%,
      rgba(114, 132, 180, 0.2) 100%
    ),
    #1d1e22;

  border-radius: 8px;

  width: 1630px;
  height: 1011px;

  top: calc(50% - 30px);
  left: calc(50%);
  transform: translate(-50%, -50%);
  z-index: 10;

  display: flex;
  flex-direction: column;
`

export default ModalWrapper
