import { DtoMatch, EnumDemoStatus } from 'api/schemas/matchApi'
import { Text5 } from 'styles/typography'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Button from 'components/Button'
import DemoInProgressIcon from 'images/DemoInProgress.svg'
import DownloadDemoIcon from 'images/DownloadDemo.svg'
import api from 'api/api'
import styled from 'styled-components'

interface MatchDemoProps {
  match: DtoMatch
}

const MatchDemo = ({ match }: MatchDemoProps) => {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)

  const unavailable = match.demoStatus === EnumDemoStatus.DemoStatusNotAvailable

  if (unavailable) return null

  const inProgress =
    match.demoStatus === EnumDemoStatus.InProgressStatusAvailable
  const disabled =
    match.demoStatus !== EnumDemoStatus.DemoStatusAvailable || isLoading

  const handleClick = async () => {
    if (!match.id) return

    try {
      setIsLoading(true)
      const data = await api.match.getIdDemo(match.id)
      const fileURL = data?.data?.url

      if (!fileURL) return

      if (window.IS_WEB) {
        window.shell.openExternal(fileURL)
      } else {
        window.ipcRenderer.invoke('download', {
          payload: {
            fileURL,
          },
        })
      }
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <StyledWrapper>
      <Button disabled={disabled} onClick={handleClick}>
        <StyledDemoIcon />
        {t('results.scoreboard.downloadDemo')}
      </Button>

      {inProgress && (
        <StyledDemoInProgressLabel>
          <StyledLoadingIcon />
          <StyledHintTextContainer>
            <Text5>{t('results.demo.match')}</Text5>
            <Text5>{t('results.demo.inProgress')}</Text5>
          </StyledHintTextContainer>
        </StyledDemoInProgressLabel>
      )}
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  display: flex;
`

const StyledDemoIcon = styled.div`
  width: 24px;
  height: 20px;
  margin-right: 10px;
  padding: 2px 0;
  background-image: url(${DownloadDemoIcon});
`

const StyledLoadingIcon = styled.div`
  width: 22px;
  height: 22px;
  margin-right: 10px;
  background-image: url(${DemoInProgressIcon});
`

const StyledDemoInProgressLabel = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 15px;
`

const StyledHintTextContainer = styled.div``

export default MatchDemo
