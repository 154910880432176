import { $userProfile } from 'stores/userStore'
import textCutter from 'utils/textCutter'

export interface Article {
  id: string
  text: string
  title: string
  description: string
}

const news = (): Article[] => [
  {
    id: '3',
    title: `Альфа-Тестированиe F2F`,
    description: `Уважаемый ${textCutter($userProfile.get()?.name)},

      Мы рады сообщить вам, что вы были отобраны для участия в альфа-тестировании нашей новой платформы F2F. Это значительный шаг в развитии нашего сервиса, и ваше участие играет ключевую роль в этом процессе.`,
    text: `
# Официальное Объявление о Начале Альфа-Тестирования Платформы F2F

Уважаемый ${textCutter($userProfile.get()?.name)},

Мы рады сообщить вам, что вы были отобраны для участия в альфа-тестировании нашей новой платформы F2F. Это значительный шаг в развитии нашего сервиса, и ваше участие играет ключевую роль в этом процессе.

На данный момент в рамках альфа-теста вам доступна игра CS:GO 2 в режиме Custom-матча. Мы обеспечили размещение серверов в России и Дубае для обеспечения оптимального игрового опыта.

В рамках текущей версии платформы вы сможете воспользоваться следующими функциями:

Добавление друзей по нику из Steam и формирование списка избранных контактов.
Создание пати и лобби для совместной игры.
Возможности чата и отправки приглашений.
Рейтинговая система и детализированная статистика игрока.
Для сбора обратной связи мы создали специальную группу в Telegram, где вы сможете делиться своими пожеланиями, замечаниями и сообщать о найденных ошибках. Ваше мнение и предложения будут важным вкладом в развитие и улучшение F2F, и мы гарантируем внимательное рассмотрение всех обращений.


Благодарим вас за участие в альфа-тестировании F2F и ценим ваш вклад в улучшение нашего сервиса.

С уважением,
Команда F2F
`,
  },
  {
    id: '2',
    title: `Приветствуем тебя, ${textCutter($userProfile.get()?.name)}!`,
    description:
      'F2F-инновационная платформа, где киберспорт оживает! Если ты ценишь честные, захватывающие матчи и готов доказать своё мастерство, то ты точно в нужном месте. Здесь ты не только сможешь показать свою силу, но и завоевать ценные призы за победы.',
    text: `
# Приветствуем тебя, ${textCutter($userProfile.get()?.name)}!

F2F-инновационная платформа, где киберспорт оживает! Если ты ценишь честные, захватывающие матчи и готов доказать своё мастерство, то ты точно в нужном месте. Здесь ты не только сможешь показать свою силу, но и завоевать ценные призы за победы.

## Наша миссия

Сделать каждый матч незабываемым. Мы стремимся, чтобы игроки вкладывали всю свою мощь в каждую игру, создавая напряженные и динамичные сражения. Для этого мы ввели систему взносов, придающих каждому матчу особую ценность и важность.

## Уникальность

Мы позаботились о справедливости и равных возможностях для всех. Наша уникальная рейтинговая система учитывает множество аспектов твоей игры, формируя точный профиль в системе. Это позволяет эффективно сегментировать игроков и обеспечивать равные условия для всех.

Наша система калибровки быстро и точно определит твой уровень, поместив тебя в соответствующий рейтинг, который будет отражать твои игровые навыки.

## Матчмейкинг на F2F настроен для тебя:
Ты выбираешь размер взноса для участия в матче.
Ты выбираешь карту, на которой будет разыгрываться битва.
Ты выбираешь сервер для оптимального соединения.

Система автоматически подберет тебе идеальных тиммейтов и соперников, соответствующих твоему рейтингу и выбранным параметрам. Остаётся только дождаться начала игры и показать своё мастерство.

${textCutter($userProfile.get()?.name)}, добро пожаловать в мир F2F, где каждый матч - это новое приключение и шанс на победу!
`,
  },
]

export default news
