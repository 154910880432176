import { $userProfile } from 'stores/userStore'
import { DtoLobby } from 'api/schemas/lobbyApi'
import { Header6, Header7, Text6 } from 'styles/typography'
import { Player } from 'stores/lobbyStore'
import { RankTypes } from 'stores/statisticStore'
import { getLobbyRank } from 'utils/rankGetters'
import { useStore } from '@nanostores/react'
import AvatarWithFlag from 'components/AvatarWithFlag'
import MatchesCircleIcon from 'images/MatchesCircleIcon'
import NickTrimmer from 'components/NickTrimmer'
import PlayerStatuses from 'components/PlayerCardMin/PlayerStatuses'
import PowerCSIcon from 'images/PowerCSIcon'
import WithUserMenu from 'components/ContextMenu/WithUserMenu'
import blue_0 from 'images/userCard/readyWrappers/blue_0.png'
import blue_1 from 'images/userCard/readyWrappers/blue_1.png'
import blue_2 from 'images/userCard/readyWrappers/blue_2.png'
import blue_3 from 'images/userCard/readyWrappers/blue_3.png'
import blue_4 from 'images/userCard/readyWrappers/blue_4.png'
import cardCSGoBackground from 'images/CardCSGoBackground.png'
import red_0 from 'images/userCard/readyWrappers/red_0.png'
import red_1 from 'images/userCard/readyWrappers/red_1.png'
import red_2 from 'images/userCard/readyWrappers/red_2.png'
import red_3 from 'images/userCard/readyWrappers/red_3.png'
import red_4 from 'images/userCard/readyWrappers/red_4.png'
import round from 'utils/round'
import styled, { css } from 'styled-components'

const getWrapper = (team: 'RED' | 'BLUE', position: number) => {
  switch (`${team.toLowerCase()}_${position}`) {
    case 'blue_0':
      return blue_0
    case 'blue_1':
      return blue_1
    case 'blue_2':
      return blue_2
    case 'blue_3':
      return blue_3
    case 'blue_4':
      return blue_4
    case 'red_0':
      return red_0
    case 'red_1':
      return red_1
    case 'red_2':
      return red_2
    case 'red_3':
      return red_3
    case 'red_4':
      return red_4
  }
}

interface StyledCardWrapperProps {
  disabled?: boolean
  $rankImage?: string
}

const StyledCardWrapper = styled.div<StyledCardWrapperProps>`
  position: relative;
  background-image: url('${cardCSGoBackground}');
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  flex-direction: column;
  width: 158px;
  height: 233px;
  backdrop-filter: blur(3px);
  padding-top: 2px;
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.8;
      cursor: default;
    `}

  &:after {
    display: block;
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    background-image: ${({ $rankImage }) =>
      $rankImage ? `url(${$rankImage})` : 'unset'};
    z-index: -1;
    top: -1px;
  }
`

const StyledMainInfoWrapper = styled.div`
  display: flex;
  justify-content: start;
  gap: 5px;
`

const StyledPower = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  font-weight: 600;
`

const StyledNickname = styled(Header6)``

const StyledMainInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: end;
  overflow: hidden;
`

const StyledMatchesWrapper = styled.div`
  position: relative;
  display: flex;
  padding-top: 3px;
`

const StyledMatchesContainer = styled(Header7)`
  padding-top: 13px;
  padding-left: 12px;
  padding-right: 12px;
  width: 100%;
  gap: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  font-weight: normal;
`

const StyledTotal = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledRanked = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledLoseWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: end;
`

const StyledText = styled(Text6)`
  color: rgba(255, 255, 255, 0.45);
`

const StyledWins = styled.div`
  padding-top: 3px;
  display: flex;
  align-items: center;
  gap: 4px;
`

const StyledWinsIcon = styled(MatchesCircleIcon)`
  fill: #5fe33e;
`

const StyledDrowIcon = styled(MatchesCircleIcon)`
  fill: #f2b927;
`

const StyledLoseIcon = styled(MatchesCircleIcon)`
  fill: #f84848;
`

const StyledDrow = styled.div`
  padding-top: 3px;
  display: flex;
  align-items: center;
  gap: 4px;
`

const StyledLose = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`

const StyledStatWrapper = styled(Header7)`
  padding-top: 10px;
  padding-left: 12px;
  padding-right: 12px;
  width: 100%;
  display: grid;
  row-gap: 5px;
  grid-template-columns: 1fr 1fr 1fr;
  font-weight: normal;
`

const StyledStatItem = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledStatKDItem = styled.div`
  grid-column-start: 1;
  grid-column-end: 3;
`

const StyledSettingsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 5px 12px;
`

interface StyledStatusWrapperProps {
  $player: Player
}

const StyledStatusWrapper = styled.div<StyledStatusWrapperProps>`
  width: 158px;
  height: 233px;
  bottom: 0;
  position: absolute;
  background-image: ${({ $player }) =>
    `url('${getWrapper($player.team, $player.position)}')`};

  opacity: ${({ $player }) => ($player.isReady ? 1 : 0)};

  transition: 0.2s;
  z-index: -1;
`
interface CSUserCardProps {
  player: Player
  lobby?: DtoLobby
  disabled?: boolean
  teamIndex?: number
}

const CSUserCard: React.FC<CSUserCardProps> = ({
  lobby,
  player,
  disabled,
  teamIndex,
}) => {
  const currentUser = useStore($userProfile)

  const profile = player.profile?.user

  if (!profile) {
    return null
  }

  const getWinrate = (wins: number, loses: number, draws: number) => {
    const total = loses + wins + draws
    const fixedTotal = total || 1

    return Math.round((wins / fixedTotal) * 100)
  }

  return (
    <WithUserMenu profile={profile}>
      <StyledCardWrapper
        disabled={disabled}
        $rankImage={getLobbyRank(profile?.CSProfileStat?.rank as RankTypes)}
      >
        <StyledMainInfoWrapper>
          <AvatarWithFlag avatarSrc={profile?.imgUrl} />
          <StyledMainInfo>
            <StyledNickname title={profile?.name}>
              <NickTrimmer>{profile?.name}</NickTrimmer>
            </StyledNickname>
          </StyledMainInfo>
        </StyledMainInfoWrapper>
        <StyledMatchesWrapper>
          <StyledMatchesContainer>
            <StyledTotal>
              <div>
                {(profile?.CSProfileStat?.matchWinCount || 0) +
                  (profile?.CSProfileStat?.matchDrawCount || 0) +
                  (profile?.CSProfileStat?.matchLoseCount || 0)}
              </div>
              <StyledText>Total</StyledText>
              <StyledWins>
                <StyledWinsIcon />
                {profile?.CSProfileStat?.matchWinCount}
              </StyledWins>
            </StyledTotal>
            <StyledRanked>
              {/* <div>42</div>
              <StyledText>Ranked</StyledText> */}
              <StyledPower>
                <PowerCSIcon />
                <div>{round(profile?.CSProfileStat?.rating)}</div>
              </StyledPower>
              <StyledText>Rating</StyledText>
              <StyledDrow>
                <StyledDrowIcon />
                {profile?.CSProfileStat?.matchDrawCount}
              </StyledDrow>
            </StyledRanked>
            <StyledLoseWrapper>
              <StyledLose>
                <StyledLoseIcon />
                {profile?.CSProfileStat?.matchLoseCount}
              </StyledLose>
            </StyledLoseWrapper>
          </StyledMatchesContainer>
        </StyledMatchesWrapper>
        <StyledStatWrapper>
          <StyledStatItem>
            <div>{round(profile?.CSProfileStat?.score)}</div>
            <StyledText>Score</StyledText>
          </StyledStatItem>
          <StyledStatItem>
            <div>
              {getWinrate(
                profile?.CSProfileStat?.matchWinCount || 0,
                profile?.CSProfileStat?.matchDrawCount || 0,
                profile?.CSProfileStat?.matchLoseCount || 0
              )}
              %
            </div>
            <StyledText>Win</StyledText>
          </StyledStatItem>
          <StyledStatItem>
            <div>{round(profile?.CSProfileStat?.HS)} %</div>
            <StyledText>HS</StyledText>
          </StyledStatItem>
          <StyledStatKDItem>
            <div>{round(profile?.CSProfileStat?.KD)}</div>
            <StyledText>KD</StyledText>
          </StyledStatKDItem>
          <StyledStatItem>
            <div>{round(profile?.CSProfileStat?.ADR)}</div>
            <StyledText>ADR</StyledText>
          </StyledStatItem>
        </StyledStatWrapper>
        <StyledSettingsWrapper>
          <PlayerStatuses
            teamIndex={teamIndex}
            isHost={profile?.id === lobby?.hostId}
            isYou={profile?.id === currentUser?.id}
          />
        </StyledSettingsWrapper>
        <StyledStatusWrapper $player={player} />
      </StyledCardWrapper>
    </WithUserMenu>
  )
}

export default CSUserCard
