import {
  $activeGroupChatType,
  $activeGroupsChatID,
  $groupChatsInfo,
  $isGroupUserListOpen,
  getGroupsChatMessages,
} from 'stores/chatStores/groupChats'
import { styled } from 'styled-components'
import { useStore } from '@nanostores/react'
import UserIcon from 'images/chat/UserIcon'
import textCutter from 'utils/textCutter'

interface StyledDots {
  $color: string
}
interface TabActive {
  $isOpen?: boolean
  $isActive: boolean
}
const StyledBarWrapper = styled.div`
  display: flex;
  align-items: center;
  min-height: 36px;
  padding: 0 11px 0 0;
  border: 1px solid rgba(255, 255, 255, 0.15);
  border-radius: 3px;
`

const StyledImage = styled.img`
  width: 21px;
  height: 21px;
`

const StyledPartyName = styled.div`
  font-family: Ruberoid;
  font-size: 12px;
  font-weight: 600;
  color: white;
  margin: 0 13px;
`

const StyledUserCounter = styled.div<TabActive>`
  display: flex;
  align-items: center;
  padding: 0 10px;
  border-right: 1px solid rgba(255, 255, 255, 0.15);
  border-left: 1px solid rgba(255, 255, 255, 0.15);
  background-color: ${({ $isOpen }) => ($isOpen ? '#FFCD29' : 'unset')};
  height: 100%;
  opacity: ${({ $isActive }) => ($isActive ? '1' : '0.5')};
`

const StyledUserCounterText = styled.div`
  font-family: Ruberoid;
  font-size: 11.846px;
  font-weight: 600;
  color: white;
  font-style: normal;
  margin-left: 5px;
`

const StyledDots = styled.div`
  display: flex;
  gap: 8px;
  margin-left: auto;
`

const StyledDot = styled.div<StyledDots>`
  display: flex;
  align-items: center;
  font-family: Ruberoid;
  font-size: 12px;
  font-weight: 600;
  color: white;

  &:before {
    display: block;
    content: '';
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: ${({ $color }) => $color};
    margin-right: 6px;
  }
`

const StyledOptionsDots = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3px;
  width: 30px;
  height: 17px;
  border: 1px solid rgba(255, 255, 255, 0.15);
  margin-left: 20px;
  border-radius: 8px;
  cursor: pointer;
`

const StyledOptionsDot = styled.div`
  width: 2px;
  height: 2px;
  border-radius: 50%;
  background-color: #ffcd29;
`
const StyledPartyWrapper = styled.div<TabActive>`
  display: flex;
  align-items: center;
  padding-left: 11px;
  opacity: ${({ $isActive }) => ($isActive ? '1' : '0.5')};
  cursor: pointer;
`

const PartyBar: React.FC = () => {
  const groupChatsInfo = useStore($groupChatsInfo)
  const activeGroupChatType = useStore($activeGroupChatType)
  const activeGroupsChatID = useStore($activeGroupsChatID)
  const isGroupUserListOpen = useStore($isGroupUserListOpen)

  const handleSetChatActive = async (
    chatId: string | undefined,
    counterClick?: boolean
  ) => {
    console.log(1)
    if (!chatId || chatId === activeGroupsChatID) {
      if (counterClick) $isGroupUserListOpen.set(!isGroupUserListOpen)
      return
    }

    $activeGroupsChatID.set(chatId)
    counterClick
      ? $isGroupUserListOpen.set(true)
      : $isGroupUserListOpen.set(false)
    await getGroupsChatMessages()
  }

  return (
    <StyledBarWrapper>
      {groupChatsInfo.party && (
        <>
          <StyledPartyWrapper
            $isActive={activeGroupChatType === 'party'}
            onClick={() => handleSetChatActive(groupChatsInfo.party?.chatId)}
          >
            <StyledImage src={groupChatsInfo.party.imgUrl} />
            <StyledPartyName>
              {textCutter(groupChatsInfo.party.name)}’s Party
            </StyledPartyName>
          </StyledPartyWrapper>
          <StyledUserCounter
            $isActive={activeGroupChatType === 'party'}
            $isOpen={activeGroupChatType === 'party' && isGroupUserListOpen}
            onClick={() =>
              handleSetChatActive(groupChatsInfo.party?.chatId, true)
            }
          >
            <UserIcon
              color={
                activeGroupChatType === 'party' && isGroupUserListOpen
                  ? '#000000'
                  : '#2CFF80'
              }
            />
            <StyledUserCounterText>
              {`${groupChatsInfo.party?.participantCount || 0} / 5 ( + ${
                groupChatsInfo.party?.invitedCount || 0
              })`}
            </StyledUserCounterText>
          </StyledUserCounter>
        </>
      )}
      {groupChatsInfo.lobby && (
        <>
          <StyledPartyWrapper
            $isActive={activeGroupChatType === 'lobby'}
            onClick={() => handleSetChatActive(groupChatsInfo.lobby?.chatId)}
          >
            <StyledImage src={groupChatsInfo.lobby.imgUrl} />
            <StyledPartyName>
              {textCutter(groupChatsInfo.lobby.name)}’s Lobby
            </StyledPartyName>
          </StyledPartyWrapper>
          <StyledUserCounter
            $isActive={activeGroupChatType === 'lobby'}
            $isOpen={activeGroupChatType === 'lobby' && isGroupUserListOpen}
            onClick={() =>
              handleSetChatActive(groupChatsInfo.lobby?.chatId, true)
            }
          >
            <UserIcon
              color={
                activeGroupChatType === 'lobby' && isGroupUserListOpen
                  ? '#000000'
                  : '#2CFF80'
              }
            />
            <StyledUserCounterText>
              {`${groupChatsInfo.lobby?.participantCount || 0} / ${!groupChatsInfo.lobby?.isRaiting ? '10' : groupChatsInfo.lobby.participantCount > 5 ? '10' : '5'} ( + ${
                groupChatsInfo.lobby?.invitedCount || 0
              })`}
            </StyledUserCounterText>
          </StyledUserCounter>
        </>
      )}
      <StyledDots>
        <StyledDot $color={'#2CFF80'}>
          {activeGroupChatType === 'party'
            ? groupChatsInfo.party?.onlineCount || 0
            : groupChatsInfo.lobby?.onlineCount || 0}
        </StyledDot>
        <StyledDot $color={'#5C5C5C'}>
          {activeGroupChatType === 'party'
            ? groupChatsInfo.party?.offlineCount || 0
            : groupChatsInfo.lobby?.offlineCount || 0}
        </StyledDot>
      </StyledDots>
      <StyledOptionsDots>
        <StyledOptionsDot />
        <StyledOptionsDot />
        <StyledOptionsDot />
      </StyledOptionsDots>
    </StyledBarWrapper>
  )
}

export default PartyBar
