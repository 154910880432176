import FontFaceObserver from 'fontfaceobserver'

const fonts = [
  {
    weight: 400,
    fontFamily: 'Montserrat',
  },
  {
    weight: 500,
    fontFamily: 'Montserrat',
  },
  {
    weight: 400,
    fontFamily: 'Nebula',
  },
  {
    weight: 400,
    fontFamily: 'Nebulosa',
  },
  {
    weight: 400,
    fontFamily: 'Ruberoid',
  },
  {
    weight: 700,
    fontFamily: 'Ruberoid',
  },
]

const observers = fonts.map(({ weight, fontFamily }) =>
  new FontFaceObserver(fontFamily, { weight }).load(null, 5000)
)

export default observers
