import { RankTypes } from 'stores/statisticStore'
import { SideHeader3 } from 'styles/typography'
import {
  getStatisticRankBackground,
  getStatisticRankRank,
} from 'utils/rankGetters'
import { styled } from 'styled-components'
import { useTranslation } from 'react-i18next'
import RankCircle from 'images/RankCircle.svg'
import RankExp from 'images/RankExp.svg'
import RankShield from 'images/RankShield.svg'

interface StyledRootBack {
  $background: string
}
const StyledRoot = styled.div<StyledRootBack>`
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid rgba(255, 255, 255, 0.15);
  background: ${({ $background }) => ($background ? $background : 'unset')};
  backdrop-filter: blur(2px);
  width: 120px;
  height: 300px;
  position: relative;
`
const StyledShield = styled.div`
  background-image: url('${RankShield}');
  width: 56px;
  height: 8.5px;
  position: absolute;
  top: 0;
`
const StyledCircle = styled.div`
  position: absolute;
  background-image: url('${RankCircle}');
  width: 4px;
  height: 4px;
`
const StyledCircleTopLeft = styled(StyledCircle)`
  left: 4px;
  top: 4px;
`
const StyledCircleTopRight = styled(StyledCircle)`
  right: 4px;
  top: 4px;
`
const StyledCircleBottomLeft = styled(StyledCircle)`
  left: 4px;
  bottom: 4px;
`
const StyledCircleBottomRight = styled(StyledCircle)`
  right: 4px;
  bottom: 4px;
`
const StyledRank = styled.div`
  display: flex;
  align-items: center;
  margin-top: 32px;
  width: 100%;
  height: 12px;
`
const StyledRankLine = styled.div`
  display: flex;
  width: 100%;
  height: 1px;
  opacity: 0.2;
  background-color: white;
`
const StyledRankImg = styled.img`
  width: 100%;
  height: 12px;
  margin: 0 5px;
  position: relative;
`
const StyledRankText = styled(SideHeader3)`
  font-size: 18px;
  font-weight: 400;
  line-height: 115%;
  color: white;
  margin-top: 5px;
  letter-spacing: 2px;
`
const StyledRankBattery = styled.div`
  display: flex;
  flex-direction: column;
  width: 65px;
  height: 149px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  margin-top: 8px;
  border-radius: 6px;
  padding: 2px 2px;
  justify-content: space-around;
`
const StyledBatteryItem = styled.div`
  width: 100%;
  height: 5px;
  background-color: rgba(255, 255, 255, 0.05);

  &:first-child {
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
  }

  &:last-child {
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
  }
`
const StyledBatteryItemActive = styled(StyledBatteryItem)`
  background-color: #2cff80;
`
const StyledExp = styled.div`
  display: flex;
  align-items: center;
  font-family: Ruberoid;
  font-size: 21px;
  font-weight: 400;
  font-style: normal;
  line-height: 115%;
  color: white;
  margin-top: 13px;

  &:before {
    display: block;
    content: '';
    width: 17px;
    height: 14px;
    background-image: url('${RankExp}');
    margin-right: 9px;
  }
`

interface IRank {
  rating?: number
  rank?: RankTypes
  prevRankRating?: number
  nextRankRating?: number
}

const Rank: React.FC<IRank> = ({
  rank,
  rating,
  nextRankRating,
  prevRankRating,
}) => {
  const { t } = useTranslation()
  const fillCount =
    rating && prevRankRating && nextRankRating
      ? Math.round(
          (rating - prevRankRating) / ((nextRankRating - prevRankRating) / 20)
        )
      : 0
  const notFillCount = 20 - fillCount

  return (
    <StyledRoot $background={getStatisticRankBackground(rank)}>
      <StyledCircleTopLeft />
      <StyledCircleTopRight />
      <StyledCircleBottomLeft />
      <StyledCircleBottomRight />
      <StyledShield />
      <StyledRank>
        <StyledRankLine />
        <StyledRankImg src={getStatisticRankRank(rank)} />
        <StyledRankLine />
      </StyledRank>
      <StyledRankText>{t('play.statistic.rank')}</StyledRankText>
      <StyledRankBattery>
        {new Array(notFillCount).fill('').map((_, index) => (
          <StyledBatteryItem key={index} />
        ))}
        {new Array(fillCount).fill('').map((_, index) => (
          <StyledBatteryItemActive key={index} />
        ))}
      </StyledRankBattery>
      <StyledExp>{Math.round(rating || 0)}</StyledExp>
    </StyledRoot>
  )
}

export default Rank
