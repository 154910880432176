import {
  $activeChatID,
  $activeChatMessages,
} from 'stores/chatStores/singleChat'
import { $tabActive, sendMessage } from 'stores/chatStores/chatStore'
import { styled } from 'styled-components'
import { useState } from 'react'
import { useStore } from '@nanostores/react'
import { useTranslation } from 'react-i18next'
import ChatField from 'components/Chat/ChatField'
import ChatLeftUsersPanel from 'components/Chat/ChatLeftUsersPanel'
import Input from 'components/Input'

const StyledChatWrapper = styled.div`
  display: flex;
  gap: 10px;
  padding-right: 20px;
  height: calc(100% - 90px);
  padding-bottom: 10px;
`

const StyledChatRight = styled.div`
  display: flex;
  flex-direction: column;
  gap: 9px;
  width: 100%;
`

const StyledChatField = styled.div`
  min-height: 100%;
`

const ChatWrapper: React.FC = () => {
  const { t } = useTranslation()
  const activeChatID = useStore($activeChatID)
  const [inputValue, setInputValue] = useState('')
  const activeChatMessages = useStore($activeChatMessages)
  const tabActive = useStore($tabActive)

  const handleKeyDown: React.KeyboardEventHandler<HTMLInputElement> = async (
    e
  ) => {
    if (e.key === 'Enter') {
      const inputElement = e.currentTarget as HTMLInputElement
      setInputValue('') // Clear the input after sending the message
      if (inputElement.value.trim().length)
        await sendMessage(activeChatID, inputElement.value)
    }
  }

  return (
    <StyledChatWrapper>
      <ChatLeftUsersPanel />
      <StyledChatRight>
        <StyledChatField>
          <ChatField messages={activeChatMessages} />
        </StyledChatField>
        {tabActive !== 'invite' && (
          <Input
            value={inputValue}
            disabled={!activeChatID}
            onKeyDown={handleKeyDown}
            placeholder={t('chat.message')}
            onChange={(e) =>
              e.target.value.length < 1000 && setInputValue(e.target.value)
            }
          />
        )}
      </StyledChatRight>
    </StyledChatWrapper>
  )
}

export default ChatWrapper
