const MatchesCircleIcon = ({ className }: { className?: string }) => (
  <svg
    width="5"
    height="6"
    viewBox="0 0 5 6"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle r="2.11268" cy="3.00709" cx="2.11268" />
  </svg>
)

export default MatchesCircleIcon
