import { initReactI18next } from 'react-i18next'
import { use } from 'i18next'
import translationEn from 'i18n/en/translation.json'
import translationRu from 'i18n/ru/translation.json'

const resources = {
  ru: {
    translation: translationRu,
  },
  en: {
    translation: translationEn,
  },
}

// eslint-disable-next-line @typescript-eslint/no-floating-promises
use(initReactI18next).init({
  resources,
  lng: 'en', // if you're using a language detector, do not define the lng option
  compatibilityJSON: 'v3',
})

export default resources
