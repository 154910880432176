import { DtoNotification } from 'api/schemas/notificationApi'
import { Text5 } from 'styles/typography'
import { declineLobby, joinToLobby } from 'stores/lobbyStore'
import { declinePartyInvite, joinToParty } from 'stores/partyStore'
import { firendInviteAccept, firendInviteDecline } from 'stores/friendsStore'
import {
  isInvite,
  removeNotification,
} from 'stores/chatStores/notificationsStore'
import { redirect } from 'react-router-dom'
import { styled } from 'styled-components'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import FriendInviteIcon from 'images/FriendInviteIcon'
import LobbyInviteIcon from 'images/LobbyInviteIcon'
import NickTrimmer from 'components/NickTrimmer'
import PartyInviteIcon from 'images/PartyInviteIcon'
import SmallButton from 'components/SmallButton'
import WithUserMenu from 'components/ContextMenu/WithUserMenu'
import dayjs from 'dayjs'
import routes from 'router/routes'

// interface StyledDeclineButton {
//   $isDeclined?: boolean
// }

//TODO поменять кнопки на компоненты
const StyledAcceptButton = styled(SmallButton)`
  width: 188px;
  height: 32px;

  display: flex;
  justify-content: center;
`

const StyledDeclineButton = styled(SmallButton)`
  color: 'rgba(255, 255, 255, 0.15)';
  min-width: 100px;

  display: flex;
  justify-content: center;
`

const StyledInvite = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 6px 6px 6px 1px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  min-width: 492px;
  margin-right: 170px;
  padding: 10px;
  gap: 10px;
`

const StyledTopWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const StyledTextWrapper = styled.div`
  display: flex;
  align-items: center;
`

const StyledText = styled.div`
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
  color: white;
  margin-left: 10px;
`

const StyledDate = styled.div`
  font-family: Montserrat;
  font-size: 10px;
  font-weight: 500;
  opacity: 0.5;
  color: white;
  margin-left: 10px;
  padding-top: 10px;
`

const StyledBottomWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const StyledColorText = styled.span`
  color: #2cff80;
`
const StyledInviteWrapper = styled.div`
  width: 660px;
  display: flex;
  align-items: flex-end;
`
const StyledCardWrapper = styled.div`
  display: flex;
  align-items: center;
  min-width: 160px;
  max-width: 160px;
  margin-right: 20px;
  cursor: pointer;
`
const StyledImage = styled.img`
  width: 30px;
  height: 30px;
`
const StyledNick = styled.div`
  font-family: Ruberoid;
  font-size: 12px;
  font-weight: 600;
  margin-left: 7px;
  max-width: 130px;
`

const getInviteIconByType = (type?: 'party' | 'friend' | 'lobby') => {
  switch (type) {
    case 'friend':
      return <FriendInviteIcon />
    case 'lobby':
      return <LobbyInviteIcon />
    case 'party':
    default:
      return <PartyInviteIcon />
  }
}

const getInviteTextByType = (
  type?: 'party' | 'friend' | 'lobby',
  name?: string
) => {
  const { t } = useTranslation()

  switch (type) {
    case 'friend':
      return (
        <Text5>
          <StyledColorText>
            {t('notifications.invites.friendText.part1')}
          </StyledColorText>
          {` `}

          {t('notifications.invites.friendText.part2', { name: name })}
          {` `}
          {t('notifications.invites.friendText.part3')}
        </Text5>
      )
    case 'lobby':
      return (
        <Text5>
          {t('notifications.invites.lobbyText.part1')}
          {` `}
          <StyledColorText>
            {t('notifications.invites.lobbyText.part2')}
          </StyledColorText>
          {` `}
          {t('notifications.invites.lobbyText.part3')}
        </Text5>
      )
    case 'party':
    default:
      return (
        <Text5>
          {t('notifications.invites.partyText.part1')}
          {` `}
          <StyledColorText>
            {t('notifications.invites.partyText.part2', { name: name })}
          </StyledColorText>
          {` `}
          {t('notifications.invites.partyText.part3')}
        </Text5>
      )
  }
}
interface MessageProps {
  notification: DtoNotification
}
const ChatNotification: React.FC<MessageProps> = ({ notification }) => {
  const { t } = useTranslation()
  const [isAccepted, setIsAccepted] = useState(false)

  const [isLoading, setIsLoading] = useState(false)

  const getType = () => {
    if (notification.payload.partyId) return 'party'
    if (notification.payload.friendRequestId) return 'friend'
    if (notification.payload.lobbyId) return 'lobby'
  }
  const onAccept = async () => {
    if (isAccepted || isLoading) return

    setIsLoading(true)

    if (isInvite(notification.type)) {
      if (notification.payload.friendRequestId)
        await firendInviteAccept(notification.payload.friendRequestId)
      if (notification.payload.partyId)
        await joinToParty(notification.payload.partyId)
      if (notification.payload.lobbyId) {
        await joinToLobby(notification.payload.lobbyId)
        redirect(routes.play())
      }
    }

    removeNotification(notification.id)

    setIsAccepted(true)
    setIsLoading(false)
  }
  const onDecline = async () => {
    if (isAccepted || isLoading) return
    setIsLoading(true)
    try {
      if (notification.payload.friendRequestId)
        await firendInviteDecline(notification.payload.friendRequestId)
      if (notification.payload.partyId)
        await declinePartyInvite(notification.payload.partyId)
      if (notification.payload.lobbyId)
        await declineLobby(notification.payload.lobbyId)
    } catch (e) {
      console.log('Ошибка коннекта')
    }

    removeNotification(notification.id)

    setIsAccepted(true)
    setIsLoading(false)
  }
  const author = notification.usersById[notification.payload.authorId || '']

  const profile = {
    id: author?.id,
    name: author?.name,
    imgUrl: author?.imgUrl,
  }
  console.log(notification)
  return (
    <StyledInviteWrapper>
      <WithUserMenu profile={{ ...profile }}>
        <StyledCardWrapper>
          <StyledImage src={author?.imgUrl} />
          <StyledNick>
            <NickTrimmer>{author?.name}</NickTrimmer>
          </StyledNick>
        </StyledCardWrapper>
      </WithUserMenu>
      <StyledInvite>
        <StyledTopWrapper>
          <StyledTextWrapper>
            {getInviteIconByType(getType())}
            <StyledText>
              {getInviteTextByType(getType(), author?.name)}
            </StyledText>
          </StyledTextWrapper>

          <StyledDate>
            {dayjs(notification.createdAt).format('DD.MM.YYYY HH:mm')}
          </StyledDate>
        </StyledTopWrapper>

        <StyledBottomWrapper>
          <StyledDeclineButton
            withoutBorder
            view="secondary"
            onClick={onDecline}
            isLoading={isLoading}
            isActive={isAccepted}
            disabled={isAccepted || isLoading}
          >
            {t('chat.inviteActions.Decline')}
          </StyledDeclineButton>

          <StyledAcceptButton
            onClick={onAccept}
            isLoading={isLoading}
            isActive={isAccepted}
            disabled={isAccepted || isLoading}
          >
            {t('chat.inviteActions.Accept')}
          </StyledAcceptButton>
        </StyledBottomWrapper>
      </StyledInvite>
    </StyledInviteWrapper>
  )
}

export default ChatNotification
