const LeavePartyIcon = () => (
  <svg
    width="24"
    fill="none"
    height="25"
    viewBox="0 0 24 25"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="white"
      clipRule="evenodd"
      fillRule="evenodd"
      d="M12 23.5128C18.0751 23.5128 23 18.5879 23 12.5128C23 6.43769 18.0751 1.51282 12 1.51282C5.92487 1.51282 1 6.43769 1 12.5128C1 18.5879 5.92487 23.5128 12 23.5128ZM16.4699 18.1352L12.14 13.8052L7.80997 18.1352L6.60599 16.9312L10.936 12.6012L6.60605 8.27127L7.75858 7.11874L12.0885 11.4487L16.4184 7.11874L17.6224 8.32272L13.2925 12.6526L17.6225 16.9826L16.4699 18.1352Z"
    />
  </svg>
)

export default LeavePartyIcon
