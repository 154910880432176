import { $activeGroupChatType } from 'stores/chatStores/groupChats'
import { DtoUser } from 'api/schemas/chatApi'
import { Text5 } from 'styles/typography'
import { styled } from 'styled-components'
import { t } from 'i18next'
import { useStore } from '@nanostores/react'
import PersonInChat from 'components/Chat/PersonInChat'
import PersonMessage from 'components/Chat/PersonMessage'
import WithUserMenu from 'components/ContextMenu/WithUserMenu'

export type chatActionType = 'JOIN' | 'LEFT' | 'KICKED' | undefined
interface Message {
  nick: string
  avatar: string
  personId: string
  message?: string
  subject?: DtoUser
  date: string | null
  type: 'message' | 'action'
  actionType?: chatActionType
}

const StyledMessageItem = styled.div`
  display: flex;
  gap: 15px;
`

const StyledActionWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 8px 0;

  &:before,
  &:after {
    display: block;
    content: '';
    width: 100%;
    height: 1px;
    background-color: rgba(255, 255, 255, 0.1);
  }

  &:before {
    margin-right: 20px;
  }

  &:after {
    margin-left: 20px;
  }
`

const StyledActionText = styled(Text5)`
  min-width: fit-content;
  color: #ffcd29;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 500;
  margin-left: 5px;
`
const StyledPersonCard = styled.div`
  min-width: 127px;
`

const StyledActionPersonWrapper = styled.div`
  min-width: fit-content;
`
const StyledRemovedPersonWrapper = styled(StyledActionPersonWrapper)`
  margin-left: 8px;
`

const MessageItem: React.FC<Message> = ({
  date,
  nick,
  type,
  avatar,
  subject,
  message,
  personId,
  actionType,
}) => {
  const activeGroupChatType = useStore($activeGroupChatType)
  const profile = {
    name: nick,
    id: personId,
    imgUrl: avatar,
  }

  return (
    <StyledMessageItem>
      {type === 'message' && (
        <>
          <StyledPersonCard>
            <WithUserMenu profile={{ ...profile }}>
              <PersonInChat nick={nick} avatar={avatar} />
            </WithUserMenu>
          </StyledPersonCard>
          <PersonMessage date={date} message={message} />
        </>
      )}
      {type === 'action' && actionType !== 'KICKED' && (
        <StyledActionWrapper>
          <StyledActionPersonWrapper>
            <WithUserMenu profile={{ ...profile }}>
              <PersonInChat nick={nick} avatar={avatar} />
            </WithUserMenu>
          </StyledActionPersonWrapper>
          <StyledActionText>
            {t(
              `chat.chatActions.${
                activeGroupChatType === 'party'
                  ? `${actionType}-party`
                  : `${actionType}-lobby`
              }` as 'chat.chatActions.KICKED-party'
            )}
          </StyledActionText>
        </StyledActionWrapper>
      )}
      {type === 'action' && actionType === 'KICKED' && (
        <StyledActionWrapper>
          <StyledActionPersonWrapper>
            <WithUserMenu profile={{ ...profile }}>
              <PersonInChat nick={nick} avatar={avatar} />
            </WithUserMenu>
          </StyledActionPersonWrapper>
          <StyledActionText>
            {t('chat.chatActions.removed')}
            {t(
              `chat.chatActions.${
                activeGroupChatType === 'party'
                  ? `${actionType}-party`
                  : `${actionType}-lobby`
              }` as 'chat.chatActions.KICKED-party'
            )}
          </StyledActionText>
          <StyledRemovedPersonWrapper>
            <WithUserMenu profile={subject}>
              <PersonInChat
                nick={subject?.name || ''}
                avatar={subject?.imgUrl || ''}
              />
            </WithUserMenu>
          </StyledRemovedPersonWrapper>
        </StyledActionWrapper>
      )}
    </StyledMessageItem>
  )
}

export default MessageItem
