const ShevronLeftIcor = ({ className }: { className?: string }) => (
  <svg
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="inherit"
      d="M16.3891 11.3336L11.1964 3.54453C11.1778 3.51671 11.1466 3.5 11.1131 3.5L8.68685 3.5C8.60698 3.5 8.55934 3.58901 8.60365 3.65547L13.7224 11.3336C13.7896 11.4344 13.7896 11.5656 13.7224 11.6664L8.60365 19.3445C8.55934 19.411 8.60698 19.5 8.68685 19.5H11.1131C11.1466 19.5 11.1778 19.4833 11.1964 19.4555L16.3891 11.6664C16.4562 11.5656 16.4562 11.4344 16.3891 11.3336Z"
    />
  </svg>
)

export default ShevronLeftIcor
