import {
  $currentAppeal,
  $showMessageButtons,
} from 'stores/chatStores/appealStore'
import { DtoAttachmentCreate, DtoMessage } from 'api/schemas/chatApi'
import { useState } from 'react'
import { useStore } from '@nanostores/react'
import Modal from 'components/Modal'
import SupportMessageButtons from 'components/Chat/Support/supportComponents/SupportMessageButtons'
import SupportMessageHead from 'components/Chat/Support/supportComponents/SupportMessageHead'
import SupportMessageInfo from 'components/Chat/Support/supportComponents/SupportMessageInfo'
import SupportMessageStars from 'components/Chat/Support/supportComponents/SupportMessageStars'
import dayjs from 'dayjs'
import styled from 'styled-components'

const StyledMessageWrapper = styled.div`
  display: flex;
  gap: 15px;
  width: 512px;
`
interface MessageImage {
  $imgUrl?: string
}
const StyledMessageImage = styled.div<MessageImage>`
  align-self: flex-end;
  min-width: 30px;
  height: 30px;
  background-image: url(${({ $imgUrl }) => ($imgUrl ? $imgUrl : 'unset')});
  background-size: contain;
`
const StyledMessageItem = styled.div`
  border-radius: 6px 6px 6px 1px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  width: 100%;
  padding: 10px;
`
const StyledMessageContent = styled.div`
  display: flex;
  flex-direction: column;
`
const StyledMessageFiles = styled.div`
  display: flex;
  gap: 16px;
  margin-top: 10px;
`
const StyledMessageFileWrapper = styled.div`
  cursor: pointer;
`
const StyledMessageFile = styled.img`
  width: 75px;
  height: 75px;
  border: 1px solid rgba(255, 255, 255, 0.15);
`
const StyledMessage = styled.p`
  font-size: 12px;
  font-weight: 500;
  color: white;
  word-break: break-word;
  user-select: text;
`
const StyledBigFile = styled.img`
  width: 543px;
  height: 543px;

  margin-top: 20px;
  border: 1px solid rgba(255, 255, 255, 0.15);
  object-fit: contain;

  cursor: pointer;

  object-fit: contain;
`
const StyledDate = styled.span`
  font-size: 10px;
  font-weight: 500;
  opacity: 0.5;
  color: white;
  float: right;
  margin-left: 10px;
  padding-top: 3px;
`
interface ISupportMessage {
  message: DtoMessage
}
const SupportMessage: React.FC<ISupportMessage> = ({ message }) => {
  const showMessageButtons = useStore($showMessageButtons)
  const currentAppeal = useStore($currentAppeal)
  const [modalImg, setModalImg] = useState<DtoAttachmentCreate | null>(null)
  const handleModalClose = () => setModalImg(null)
  const handleModalOpen = (img: DtoAttachmentCreate) => () => {
    setModalImg(img)
  }

  return (
    <StyledMessageWrapper>
      <StyledMessageImage $imgUrl={message.author?.imgUrl} />
      <StyledMessageItem>
        {message.isSystem ? (
          <SupportMessageHead
            status={message.status}
            id={currentAppeal?.externalId}
          />
        ) : (
          <StyledMessageContent>
            <StyledMessage>
              {message.text}
              {message.createdAt && (
                <StyledDate>
                  {dayjs(message.createdAt).format('DD.MM.YYYY HH:mm')}
                </StyledDate>
              )}
            </StyledMessage>
            {message.attachments?.length && (
              <StyledMessageFiles>
                {message.attachments.map((item) => {
                  return (
                    <StyledMessageFileWrapper
                      onClick={handleModalOpen({
                        attachmentId: item.attachmentId,
                        attachmentUrl: item.attachmentUrl,
                      })}
                    >
                      <StyledMessageFile
                        key={item.attachmentId}
                        src={item.attachmentUrl}
                      />
                    </StyledMessageFileWrapper>
                  )
                })}
              </StyledMessageFiles>
            )}
            <Modal isOpen={Boolean(modalImg)} onClose={handleModalClose}>
              <StyledBigFile
                key={modalImg?.attachmentId}
                src={modalImg?.attachmentUrl}
              />
            </Modal>
          </StyledMessageContent>
        )}
        {message.isSystem && message.status !== 'APPROVE_SUCCESS' ? (
          <SupportMessageInfo message={message} />
        ) : null}
        {message.status === 'FINISH' && showMessageButtons && (
          <SupportMessageButtons message={message} />
        )}
        {message.status === 'APPROVE_SUCCESS' && <SupportMessageStars />}
      </StyledMessageItem>
    </StyledMessageWrapper>
  )
}

export default SupportMessage
