import { Controller, useForm } from 'react-hook-form'
import { Header2, Text3, Text4, Text5 } from 'styles/typography'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Button from 'components/Button'
import CopyWrapper from 'components/CopyWrapper'
import ImageFileIcon from 'images/judge/ImageFileIcon'
import Input from 'components/Input'
import PaperClip from 'images/judge/PaperClip'
import TimeCode from 'components/TimeCode'
import TrashIcon from 'images/judge/TrashIcon'
import VideoFileIcon from 'images/judge/VideoFileIcon'
import styled from 'styled-components'

interface FormData {
  explanation: string
  timeCode: string[]
  files: File[]
}

interface ResultInputProps {
  setInputData: (data: FormData) => void
  isGuiltyConfirm: boolean
}
interface StyledTimeCodeWrapperProps {
  $isGuiltyConfirm: boolean
}
const ResultInput: React.FC<ResultInputProps> = ({
  setInputData,
  isGuiltyConfirm,
}) => {
  const { t } = useTranslation()

  const { watch, control } = useForm<FormData>({
    defaultValues: {
      files: [],
      timeCode: [],
      explanation: '',
    },
  })

  const [isExplanationError, setIsExplanationError] = useState(false)
  const [files, setFiles] = useState<File[]>([])
  const watchExplanation = watch('explanation')
  const watchTimeCodes = watch('timeCode')

  useEffect(() => {
    if (watchExplanation !== '') {
      setInputData({
        files,
        timeCode: watchTimeCodes,
        explanation: watchExplanation,
      })
      setIsExplanationError(false)
    } else {
      setInputData({
        files,
        timeCode: watchTimeCodes,
        explanation: watchExplanation,
      })
      setIsExplanationError(true)
    }
  }, [watchExplanation, watchTimeCodes, files, setInputData])

  const handleFileChange = (newFiles: File[]) => {
    setFiles((prevFiles) => [...prevFiles, ...newFiles].slice(0, 3))
  }

  const removeFile = (fileName: string, index: number) => {
    setFiles((prevFiles) =>
      prevFiles.filter((file) => file.name + index !== fileName + index)
    )
  }

  return (
    <StyledResultInput>
      <StyledHeaderWrapper>
        <StyledHeader>{t('judge.voteModal.decision')}</StyledHeader>
        <StyledIdContainer>
          <CopyWrapper textToCopy={'123456'}>
            <StyledDescription>
              <StyledTicket>{t('judge.voteModal.applicationId')}</StyledTicket>
              <StyledTicketID>123456</StyledTicketID>
            </StyledDescription>
          </CopyWrapper>
        </StyledIdContainer>
      </StyledHeaderWrapper>
      <StyledInputWrapper>
        <StyledInputContainer>
          <StyledInputCount>
            <InputCountContainer>
              <StyledGreenDescription>01</StyledGreenDescription>
              <StyledGreenDescription>/</StyledGreenDescription>
              <StyledGrayDescription>03</StyledGrayDescription>
            </InputCountContainer>
          </StyledInputCount>
          <StyledInputInfo>
            <InputTitle>
              {t('judge.voteModal.explain')}
              <StyledGreenDescription>*</StyledGreenDescription>
            </InputTitle>
            <InputContainer>
              <Controller
                name="explanation"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <StyledInput
                    {...field}
                    placeholder={t('judge.voteModal.startWrite')}
                    disabled={isGuiltyConfirm}
                  />
                )}
              />
              <InputRequiredDescription>
                {isExplanationError ? (
                  <StyledRedDescription>
                    {t('judge.voteModal.requiredFill')}
                  </StyledRedDescription>
                ) : (
                  <StyledGreenDescription>
                    {t('judge.voteModal.requiredFill')}
                  </StyledGreenDescription>
                )}
              </InputRequiredDescription>
            </InputContainer>
          </StyledInputInfo>
        </StyledInputContainer>
        <StyledInputContainer>
          <StyledInputCount>
            <InputCountContainer>
              <StyledGreenDescription>02</StyledGreenDescription>
              <StyledGreenDescription>/</StyledGreenDescription>
              <StyledGrayDescription>03</StyledGrayDescription>
            </InputCountContainer>
          </StyledInputCount>
          <StyledInputInfo>
            <InputTitle>{t('judge.voteModal.setTimeCode')}</InputTitle>
            <InputContainer>
              <Controller
                name="timeCode"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <StyledTimeCodeWrapper $isGuiltyConfirm={isGuiltyConfirm}>
                    <StyledTimeCode
                      values={value}
                      onChange={(newValues) => {
                        onChange(newValues)
                      }}
                    />
                  </StyledTimeCodeWrapper>
                )}
              />
            </InputContainer>
          </StyledInputInfo>
        </StyledInputContainer>
        <StyledInputContainer>
          <StyledInputCount>
            <InputCountContainer>
              <StyledGreenDescription>03</StyledGreenDescription>
              <StyledGreenDescription>/</StyledGreenDescription>
              <StyledGrayDescription>03</StyledGrayDescription>
            </InputCountContainer>
          </StyledInputCount>
          <StyledInputInfo>
            <InputContainer>
              <Controller
                name="files"
                control={control}
                render={({ field: { onChange } }) => (
                  <StyledFileLabel>
                    {files.length !== 3 ? (
                      <FileAreaDescription>
                        {t('judge.voteModal.addFiles')}
                        <StyledClipButton />
                      </FileAreaDescription>
                    ) : (
                      <StyledUploadFilesHint>
                        {t('judge.voteModal.maxUploadFiles')}
                      </StyledUploadFilesHint>
                    )}
                    {files.length !== 3 && (
                      <>
                        <AcceptedFilesHint>
                          <StyledGrayDescription>
                            {t('judge.voteModal.maxUploadFilesHint')}
                          </StyledGrayDescription>
                        </AcceptedFilesHint>
                        <StyledFileInput
                          disabled={isGuiltyConfirm}
                          type="file"
                          accept="*"
                          multiple
                          onChange={(e) => {
                            const selectedFiles = e.target.files
                            if (selectedFiles) {
                              const newFiles = [...selectedFiles]
                              handleFileChange(newFiles)
                              onChange([...files, ...newFiles])
                            }
                          }}
                        />
                      </>
                    )}
                  </StyledFileLabel>
                )}
              />
              <AttachedFilesWrapper>
                {files.map((file, index) => (
                  <File key={file.name + index}>
                    <FileInfoWrapper>
                      {file.type.startsWith('video/') ? (
                        <VideoFileIcon />
                      ) : (
                        <ImageFileIcon />
                      )}
                      {file.name}
                    </FileInfoWrapper>
                    <FileDeleteLogo
                      disabled={isGuiltyConfirm}
                      onClick={(e) => {
                        e.preventDefault()
                        removeFile(file.name, index)
                      }}
                    >
                      <TrashIcon />
                    </FileDeleteLogo>
                  </File>
                ))}
              </AttachedFilesWrapper>
            </InputContainer>
          </StyledInputInfo>
        </StyledInputContainer>
      </StyledInputWrapper>
    </StyledResultInput>
  )
}

export default ResultInput

const StyledResultInput = styled.div`
  width: 100%;
  border: 1px solid #ffffff26;
  border-radius: 3px;
`

const StyledHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 37px 50px 0px 32px;
`

const StyledHeader = styled(Header2)`
  line-height: 34px;
  font-weight: 400;
`
const StyledDescription = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`
const StyledIdContainer = styled.div`
  margin: 3px 0px 0px 0px;
  padding: 5px 25px;
  display: flex;
  width: auto;
  align-items: center;
  height: 31px;
  border-radius: 100px;
  border: 1px solid rgba(255, 255, 255, 0.3);
`
const StyledTicket = styled(Text4)`
  border-right: 1px solid #ffffff26;
  font-weight: 500;
  padding: 0px 10px 0px 0px;
`
const StyledTicketID = styled(Text4)`
  font-weight: 500;
  padding: 0px 0px 0px 10px;
`
const StyledInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 32px 0px 32px;
  gap: 13px;
`
const StyledInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  border: 1px solid #ffffff26;
  border-radius: 3px;
  background: #ffffff08;
`
const StyledInputCount = styled(Text4)`
  padding: 17px 29px 0px 23px;
  width: 134px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`
const InputCountContainer = styled(Text3)`
  gap: 7px;
  display: flex;
`
const StyledGreenDescription = styled.div`
  color: #2cff80;
`
const StyledRedDescription = styled.div`
  color: #ff4444;
`
const StyledGrayDescription = styled.div`
  color: #ffffff26;
`
const StyledInputInfo = styled.div`
  padding: 24px 24px 24px 0px;
  width: 100%;
  height: 100%;
`
const InputTitle = styled(Text4)`
  padding: 0px 0px 24px 0px;
  font-weight: 500;
  line-height: 20px;
  display: flex;
  gap: 5px;
`
const InputContainer = styled.div``
const InputRequiredDescription = styled(Text5)`
  padding: 6px 0px 0px 0px;
  text-align: right;
  font-weight: 500;
`
const StyledInput = styled(Input)`
  input::placeholder {
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 500;
    margin: 0;
    padding: 0;
  }
  display: flex;
  height: 34px;
`
const StyledTimeCode = styled(TimeCode)`
  border: none;
  display: flex;
  input {
    border: none;
    padding: 0;
    font-size: 16px;
    &:focus-within {
      height: 24px;
    }
    &:first-child {
      border-right: 1px solid #ffffff26;
      &:focus-within {
        border-right: 1px solid #ffcd29;
      }
    }
    &:last-child {
      &:focus-within {
        border-radius: 0px 3px 3px 0px;
      }
    }
  }
`
const StyledTimeCodeWrapper = styled.div<StyledTimeCodeWrapperProps>`
  pointer-events: ${(props) => (props.$isGuiltyConfirm ? 'none' : 'all')};
`
const StyledFileInput = styled.input`
  display: none;
`
const StyledFileLabel = styled.label`
  padding: 10px 31px 10px 13px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 34px;
  border-radius: 3px;
  border: 1px dashed rgba(255, 255, 255, 0.15);
  background: rgba(255, 255, 255, 0.03);
  cursor: pointer;
  transition: 0.2s;
  font-family: Montserrat;
  font-size: 10px;
  font-weight: 500;

  &:hover {
    border-color: #ffcd29;
    background: rgba(238, 157, 62, 0.15);
  }
`
const FileAreaDescription = styled(Text5)`
  gap: 13px;
  display: flex;
  align-items: center;
`
const StyledClipButton = styled(PaperClip)`
  width: 24px;
  height: 24px;
`
const AcceptedFilesHint = styled(Text5)`
  color: #fff;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
`
const AttachedFilesWrapper = styled.div`
  padding: 8px 0px 0px 0px;
  gap: 6px;
  display: flex;
  flex-direction: row;
`
const File = styled.div`
  padding: 7px 7px 7px 11px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 210px;
  height: 34px;
  border: 1px solid #ffffff26;
  border-radius: 3px;
  transition: 0.3s;
  &:hover {
    border-color: #ffcd29;
    background: rgba(238, 157, 62, 0.15);
  }
`
const FileInfoWrapper = styled(Text5)`
  display: flex;
  align-items: center;
  font-weight: 500;
  gap: 9px;
`
const StyledUploadFilesHint = styled(Text5)`
  display: flex;
  width: 100%;
  height: 100%;
  text-align: center;
  justify-content: center;
  align-items: center;
`
const FileDeleteLogo = styled(Button)`
  display: flex;
  justify-content: center;
  width: 20px;
  height: 20px;
  cursor: pointer;
  background: none;
  border: none;
  transition: 0.3s;
  svg {
    stroke: none;
  }
  &:hover {
    border: none;
    background: none;
    svg {
      opacity: 0.8;
    }
  }
  &:active {
    opacity: 0.5;
  }
`
