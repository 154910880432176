import { SVGProps } from 'react'

const TrashIcon = ({ ...rest }: SVGProps<SVGSVGElement>) => (
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.38246 2.45336C7.38246 2.03362 7.72272 1.69336 8.14246 1.69336H13.2891C13.7089 1.69336 14.0491 2.03362 14.0491 2.45336V3.35957H17.4558C17.8756 3.35957 18.2158 3.69983 18.2158 4.11957V4.26624C18.2158 4.68597 17.8756 5.02624 17.4558 5.02624H13.3157C13.3069 5.02654 13.298 5.02669 13.2891 5.02669H8.14246C8.13357 5.02669 8.12471 5.02654 8.11589 5.02624H3.97582C3.55608 5.02624 3.21582 4.68597 3.21582 4.26624V4.11957C3.21582 3.69983 3.55608 3.35957 3.97582 3.35957H7.38246V2.45336ZM3.35563 6.74079C3.28139 6.27887 3.63815 5.8602 4.106 5.8602H17.3222C17.7901 5.8602 18.1468 6.27887 18.0726 6.74079L16.174 18.5541C16.1148 18.9226 15.7968 19.1935 15.4236 19.1935H6.00457C5.63138 19.1935 5.31341 18.9226 5.2542 18.5541L3.35563 6.74079ZM10.7146 7.59988C11.1344 7.59988 11.4746 7.94015 11.4746 8.35988V15.8599C11.4746 16.2796 11.1344 16.6199 10.7146 16.6199C10.2949 16.6199 9.95465 16.2796 9.95465 15.8599L9.95465 8.35988C9.95465 7.94015 10.2949 7.59988 10.7146 7.59988ZM14.1316 8.23494C14.2006 7.82091 14.5922 7.54122 15.0062 7.61022C15.4203 7.67923 15.7 8.0708 15.6309 8.48483L14.3809 15.9848C14.3119 16.3989 13.9204 16.6785 13.5063 16.6095C13.0923 16.5405 12.8126 16.149 12.8816 15.7349L14.1316 8.23494ZM6.42306 7.61022C6.83709 7.54122 7.22866 7.82091 7.29767 8.23494L8.54767 15.7349C8.61667 16.149 8.33698 16.5405 7.92295 16.6095C7.50892 16.6785 7.11735 16.3989 7.04835 15.9848L5.79835 8.48483C5.72934 8.0708 6.00904 7.67923 6.42306 7.61022Z"
      fill="#FFCD29"
    />
  </svg>
)

export default TrashIcon
