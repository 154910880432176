import { RankTypes } from 'stores/statisticStore'
import RankA from 'images/rank/A.svg'
import RankAA from 'images/rank/AA.svg'
import RankAAA from 'images/rank/AAA.svg'
import RankAAPlus from 'images/rank/AAPlus.svg'
import RankAPlus from 'images/rank/APlus.svg'
import RankB from 'images/rank/B.svg'
import RankBB from 'images/rank/BB.svg'
import RankBBB from 'images/rank/BBB.svg'
import RankBBPlus from 'images/rank/BBPlus.svg'
import RankBPlus from 'images/rank/BPlus.svg'
import RankC from 'images/rank/C.svg'
import RankCC from 'images/rank/CC.svg'
import RankCCC from 'images/rank/CCC.svg'
import RankCCPlus from 'images/rank/CCPlus.svg'
import RankCPlus from 'images/rank/CPlus.svg'
import RankD from 'images/rank/D.svg'
import RankDD from 'images/rank/DD.svg'
import RankDDD from 'images/rank/DDD.svg'
import RankDDPlus from 'images/rank/DDPlus.svg'
import RankDPlus from 'images/rank/DPlus.svg'
import RankS from 'images/rank/S.svg'
import RankSPlus from 'images/rank/SPlus.svg'

import mainPageRankA from 'images/mainPageRank/A.svg'
import mainPageRankAA from 'images/mainPageRank/AA.svg'
import mainPageRankAAA from 'images/mainPageRank/AAA.svg'
import mainPageRankAAPlus from 'images/mainPageRank/AAPlus.svg'
import mainPageRankAPlus from 'images/mainPageRank/APlus.svg'
import mainPageRankB from 'images/mainPageRank/B.svg'
import mainPageRankBB from 'images/mainPageRank/BB.svg'
import mainPageRankBBB from 'images/mainPageRank/BBB.svg'
import mainPageRankBBPlus from 'images/mainPageRank/BBPlus.svg'
import mainPageRankBPlus from 'images/mainPageRank/BPlus.svg'
import mainPageRankC from 'images/mainPageRank/C.svg'
import mainPageRankCC from 'images/mainPageRank/CC.svg'
import mainPageRankCCC from 'images/mainPageRank/CCC.svg'
import mainPageRankCCPlus from 'images/mainPageRank/CCPlus.svg'
import mainPageRankCPlus from 'images/mainPageRank/CPlus.svg'
import mainPageRankD from 'images/mainPageRank/D.svg'
import mainPageRankDD from 'images/mainPageRank/DD.svg'
import mainPageRankDDD from 'images/mainPageRank/DDD.svg'
import mainPageRankDDPlus from 'images/mainPageRank/DDPlus.svg'
import mainPageRankDPlus from 'images/mainPageRank/DPlus.svg'
import mainPageRankS from 'images/mainPageRank/S.svg'
import mainPageRankSPlus from 'images/mainPageRank/SPlus.svg'

import lobbyRankA from 'images/lobbyRank/A.svg'
import lobbyRankAA from 'images/lobbyRank/AA.svg'
import lobbyRankAAA from 'images/lobbyRank/AAA.svg'
import lobbyRankAAPlus from 'images/lobbyRank/AAPlus.svg'
import lobbyRankAPlus from 'images/lobbyRank/APlus.svg'
import lobbyRankB from 'images/lobbyRank/B.svg'
import lobbyRankBB from 'images/lobbyRank/BB.svg'
import lobbyRankBBB from 'images/lobbyRank/BBB.svg'
import lobbyRankBBPlus from 'images/lobbyRank/BBPlus.svg'
import lobbyRankBPlus from 'images/lobbyRank/BPlus.svg'
import lobbyRankC from 'images/lobbyRank/C.svg'
import lobbyRankCC from 'images/lobbyRank/CC.svg'
import lobbyRankCCC from 'images/lobbyRank/CCC.svg'
import lobbyRankCCPlus from 'images/lobbyRank/CCPlus.svg'
import lobbyRankCPlus from 'images/lobbyRank/CPlus.svg'
import lobbyRankD from 'images/lobbyRank/D.svg'
import lobbyRankDD from 'images/lobbyRank/DD.svg'
import lobbyRankDDD from 'images/lobbyRank/DDD.svg'
import lobbyRankDDPlus from 'images/lobbyRank/DDPlus.svg'
import lobbyRankDPlus from 'images/lobbyRank/DPlus.svg'
import lobbyRankS from 'images/lobbyRank/S.svg'
import lobbyRankSPlus from 'images/lobbyRank/SPlus.svg'

export const getStatisticRankRank = (rank: RankTypes | undefined): string => {
  switch (rank) {
    case 'S+':
      return RankSPlus
    case 'S':
      return RankS
    case 'AAA':
      return RankAAA
    case 'AA+':
      return RankAAPlus
    case 'AA':
      return RankAA
    case 'A+':
      return RankAPlus
    case 'A':
      return RankA
    case 'BBB':
      return RankBBB
    case 'BB+':
      return RankBBPlus
    case 'BB':
      return RankBB
    case 'B+':
      return RankBPlus
    case 'B':
      return RankB
    case 'CCC':
      return RankCCC
    case 'CC+':
      return RankCCPlus
    case 'CC':
      return RankCC
    case 'C+':
      return RankCPlus
    case 'C':
      return RankC
    case 'DDD':
      return RankDDD
    case 'DD+':
      return RankDDPlus
    case 'DD':
      return RankDD
    case 'D+':
      return RankDPlus
    case 'D':
      return RankD
    default:
      return RankD
  }
}
export const getStatisticRankBackground = (
  rank: RankTypes | undefined
): string => {
  switch (rank) {
    case 'S+':
      return 'linear-gradient(0deg, #8F20FF 0.11%, rgba(43, 0, 85, 0.00) 99.98%), radial-gradient(247.93% 100% at 50.41% 100%, rgba(143, 0, 255, 0.60) 0%, rgba(104, 62, 221, 0.22) 54.01%, rgba(201, 48, 255, 0.00) 100%), linear-gradient(0deg, rgba(50, 0, 67, 0.00) 0%, rgba(111, 0, 164, 0.80) 31.68%, rgba(50, 0, 67, 0.00) 133%), rgba(0, 0, 0, 0.03)'
    case 'S':
      return 'linear-gradient(0deg, #8F20FF 0.05%, rgba(43, 0, 85, 0.00) 47.75%), radial-gradient(89.26% 36% at 50.41% 100%, rgba(143, 0, 255, 0.60) 0%, rgba(104, 62, 221, 0.22) 54.01%, rgba(201, 48, 255, 0.00) 100%), linear-gradient(0deg, rgba(50, 0, 67, 0.00) 0%, rgba(111, 0, 164, 0.80) 7.7%, rgba(50, 0, 67, 0.00) 133%), rgba(0, 0, 0, 0.03)'
    case 'AAA':
      return 'linear-gradient(180deg, rgba(224, 59, 22, 0.00) 71.83%, rgba(255, 229, 0, 0.50) 100%, rgba(255, 229, 0, 0.50) 100%), radial-gradient(275.62% 111.17% at 50.41% 100%, rgba(255, 199, 0, 0.70) 7.29%, rgba(221, 186, 62, 0.26) 66.04%, rgba(255, 142, 10, 0.00) 100%), linear-gradient(0deg, rgba(50, 0, 67, 0.00) 0%, rgba(164, 69, 0, 0.80) 32.46%, rgba(50, 0, 67, 0.00) 133.33%), rgba(0, 0, 0, 0.03)'
    case 'AA+':
      return 'linear-gradient(180deg, rgba(224, 59, 22, 0.00) 71.83%, rgba(255, 229, 0, 0.50) 100%, rgba(255, 229, 0, 0.50) 100%), radial-gradient(234.3% 94.5% at 50.41% 100%, rgba(255, 199, 0, 0.70) 7.29%, rgba(221, 186, 62, 0.26) 66.04%, rgba(255, 142, 10, 0.00) 100%), linear-gradient(0deg, rgba(50, 0, 67, 0.00) 0%, rgba(164, 69, 0, 0.80) 32.46%, rgba(50, 0, 67, 0.00) 133.33%), rgba(0, 0, 0, 0.03)'
    case 'AA':
      return 'linear-gradient(180deg, rgba(224, 59, 22, 0.00) 71.83%, rgba(255, 229, 0, 0.50) 100%, rgba(255, 229, 0, 0.50) 100%), radial-gradient(186.36% 75.17% at 50.41% 100%, rgba(255, 199, 0, 0.70) 7.29%, rgba(221, 186, 62, 0.26) 59.75%, rgba(255, 142, 10, 0.00) 100%), linear-gradient(0deg, rgba(50, 0, 67, 0.00) 0%, rgba(164, 69, 0, 0.80) 32.46%, rgba(50, 0, 67, 0.00) 133.33%), rgba(0, 0, 0, 0.03)'
    case 'A+':
      return 'linear-gradient(180deg, rgba(224, 59, 22, 0.00) 71.83%, rgba(255, 229, 0, 0.50) 100%, rgba(255, 229, 0, 0.50) 100%), radial-gradient(143.39% 57.83% at 50.41% 101.83%, rgba(255, 199, 0, 0.70) 7.29%, rgba(221, 186, 62, 0.26) 72.21%, rgba(255, 142, 10, 0.00) 100%), linear-gradient(0deg, rgba(50, 0, 67, 0.00) 0%, rgba(164, 69, 0, 0.80) 1.26%, rgba(50, 0, 67, 0.00) 133.33%), rgba(0, 0, 0, 0.03)'
    case 'A':
      return 'linear-gradient(180deg, rgba(224, 59, 22, 0.00) 71.83%, rgba(255, 229, 0, 0.50) 100%, rgba(255, 229, 0, 0.50) 100%), radial-gradient(78.1% 31.5% at 50.41% 101.83%, rgba(255, 199, 0, 0.70) 7.29%, rgba(221, 186, 62, 0.26) 43.15%, rgba(255, 142, 10, 0.00) 100%), linear-gradient(0deg, rgba(50, 0, 67, 0.00) 0%, rgba(164, 69, 0, 0.80) 1.26%, rgba(50, 0, 67, 0.00) 133.33%), rgba(0, 0, 0, 0.03)'
    case 'BBB':
      return 'linear-gradient(180deg, rgba(0, 7, 71, 0.00) 20.52%, rgba(81, 98, 255, 0.50) 92.92%), radial-gradient(247.93% 100% at 50.41% 100%, rgba(0, 193, 236, 0.70) 7.29%, rgba(62, 183, 221, 0.26) 76.72%, rgba(0, 36, 130, 0.00) 100%), linear-gradient(0deg, rgba(50, 0, 67, 0.00) 5.17%, rgba(0, 24, 87, 0.80) 70.38%, rgba(50, 0, 67, 0.00) 122.5%), rgba(0, 0, 0, 0.03)'
    case 'BB+':
      return 'linear-gradient(180deg, rgba(0, 7, 71, 0.00) 39.84%, rgba(81, 98, 255, 0.50) 94.62%), radial-gradient(247.93% 100% at 50.41% 100%, rgba(0, 193, 236, 0.70) 7.29%, rgba(62, 183, 221, 0.26) 65.53%, rgba(0, 36, 130, 0.00) 100%), linear-gradient(0deg, rgba(50, 0, 67, 0.00) 5.17%, rgba(0, 24, 87, 0.80) 71.66%, rgba(50, 0, 67, 0.00) 124.67%), rgba(0, 0, 0, 0.03)'
    case 'BB':
      return 'linear-gradient(180deg, rgba(0, 7, 71, 0.00) 23.19%, rgba(81, 98, 255, 0.50) 93.16%), radial-gradient(211.16% 85.17% at 50.41% 100%, rgba(0, 193, 236, 0.70) 7.29%, rgba(62, 183, 221, 0.26) 67.42%, rgba(0, 36, 130, 0.00) 100%), linear-gradient(0deg, rgba(50, 0, 67, 0.00) 5.17%, rgba(0, 24, 87, 0.80) 55.79%, rgba(50, 0, 67, 0.00) 127.67%), rgba(0, 0, 0, 0.03)'
    case 'B+':
      return 'linear-gradient(180deg, rgba(0, 7, 71, 0.00) 23.19%, rgba(81, 98, 255, 0.50) 93.16%), radial-gradient(175.62% 70.83% at 50.41% 100%, rgba(0, 193, 236, 0.70) 7.29%, rgba(62, 183, 221, 0.26) 65.85%, rgba(0, 36, 130, 0.00) 100%), linear-gradient(0deg, rgba(50, 0, 67, 0.00) 5.17%, rgba(0, 24, 87, 0.80) 31.89%, rgba(50, 0, 67, 0.00) 129.5%), rgba(0, 0, 0, 0.03)'
    case 'B':
      return 'linear-gradient(180deg, rgba(0, 7, 71, 0.00) 23.19%, rgba(81, 98, 255, 0.50) 93.16%), radial-gradient(112.4% 45.33% at 50.41% 100%, rgba(0, 193, 236, 0.70) 7.29%, rgba(62, 183, 221, 0.26) 42.77%, rgba(0, 36, 130, 0.00) 100%), linear-gradient(0deg, rgba(50, 0, 67, 0.00) -20.5%, rgba(0, 24, 87, 0.80) 11.74%, rgba(50, 0, 67, 0.00) 129.5%), rgba(0, 0, 0, 0.03)'
    case 'CCC':
      return 'linear-gradient(180deg, rgba(0, 30, 7, 0.00) 5.53%, rgba(143, 255, 0, 0.50) 91.6%), radial-gradient(233.88% 94.33% at 50.41% 100%, rgba(33, 236, 0, 0.70) 7.29%, rgba(62, 221, 97, 0.26) 71.26%, rgba(0, 130, 21, 0.00) 100%), linear-gradient(0deg, rgba(50, 0, 67, 0.00) 0%, rgba(0, 87, 82, 0.80) 60.49%, rgba(50, 0, 67, 0.00) 128.33%), rgba(0, 0, 0, 0.03)'
    case 'CC+':
      return 'linear-gradient(180deg, rgba(0, 30, 7, 0.00) 56.17%, rgba(143, 255, 0, 0.50) 96.12%), radial-gradient(195.87% 79% at 50.41% 100%, rgba(33, 236, 0, 0.70) 7.29%, rgba(62, 221, 97, 0.26) 66.04%, rgba(0, 130, 21, 0.00) 100%), linear-gradient(0deg, rgba(50, 0, 67, 0.00) 0%, rgba(0, 87, 82, 0.80) 32.79%, rgba(50, 0, 67, 0.00) 134.67%), rgba(0, 0, 0, 0.03)'
    case 'CC':
      return 'linear-gradient(180deg, rgba(0, 30, 7, 0.00) 50%, rgba(143, 255, 0, 0.50) 95.51%), radial-gradient(183.06% 73.83% at 50.41% 107.5%, rgba(33, 236, 0, 0.70) 7.29%, rgba(62, 221, 97, 0.26) 50.14%, rgba(0, 130, 21, 0.00) 100%), linear-gradient(0deg, rgba(50, 0, 67, 0.00) 0%, rgba(0, 87, 82, 0.80) 48.39%, rgba(50, 0, 67, 0.00) 133.5%), rgba(0, 0, 0, 0.03)'
    case 'C+':
      return 'linear-gradient(180deg, rgba(0, 30, 7, 0.00) 56.17%, rgba(143, 255, 0, 0.50) 96.12%), radial-gradient(165.7% 66.83% at 50.41% 123.17%, rgba(33, 236, 0, 0.70) 7.29%, rgba(62, 221, 97, 0.26) 43.88%, rgba(0, 130, 21, 0.00) 100%), linear-gradient(0deg, rgba(50, 0, 67, 0.00) 0%, rgba(0, 87, 82, 0.80) 26.66%, rgba(50, 0, 67, 0.00) 137.17%), rgba(0, 0, 0, 0.03)'
    case 'C':
      return 'linear-gradient(180deg, rgba(0, 30, 7, 0.00) 73.83%, rgba(143, 255, 0, 0.50) 97.68%), radial-gradient(107.85% 43.5% at 50.41% 143.5%, rgba(33, 236, 0, 0.70) 7.29%, rgba(62, 221, 97, 0.26) 54.57%, rgba(0, 130, 21, 0.00) 100%), linear-gradient(0deg, rgba(50, 0, 67, 0.00) -6.17%, rgba(0, 87, 82, 0.80) 13.59%, rgba(50, 0, 67, 0.00) 150.33%), rgba(0, 0, 0, 0.03)'
    case 'DDD':
      return 'linear-gradient(180deg, rgba(119, 132, 143, 0.00) 4.03%, rgba(97, 99, 121, 0.50) 91.47%), radial-gradient(247.93% 100% at 50.41% 100%, rgba(139, 139, 139, 0.70) 7.29%, rgba(126, 126, 126, 0.26) 71.98%, rgba(62, 71, 91, 0.00) 100%), linear-gradient(0deg, rgba(18, 18, 18, 0.00) 0%, rgba(48, 53, 64, 0.80) 84.85%, rgba(34, 34, 34, 0.00) 114.5%)'
    case 'DD+':
      return 'linear-gradient(180deg, rgba(119, 132, 143, 0.00) 4.03%, rgba(97, 99, 121, 0.50) 91.47%), radial-gradient(177.69% 71.67% at 50.41% 100%, rgba(139, 139, 139, 0.70) 7.29%, rgba(126, 126, 126, 0.26) 71.98%, rgba(62, 71, 91, 0.00) 100%), linear-gradient(0deg, rgba(18, 18, 18, 0.00) 0%, rgba(48, 53, 64, 0.80) 84.85%, rgba(34, 34, 34, 0.00) 114.5%)'
    case 'DD':
      return 'linear-gradient(180deg, rgba(119, 132, 143, 0.00) 4.03%, rgba(97, 99, 121, 0.50) 91.47%), radial-gradient(114.88% 46.33% at 50.41% 100%, rgba(139, 139, 139, 0.70) 7.29%, rgba(126, 126, 126, 0.26) 56.93%, rgba(62, 71, 91, 0.00) 100%), linear-gradient(0deg, rgba(18, 18, 18, 0.00) 0%, rgba(48, 53, 64, 0.80) 84.85%, rgba(34, 34, 34, 0.00) 114.5%)'
    case 'D+':
      return 'linear-gradient(180deg, rgba(119, 132, 143, 0.00) 58.83%, rgba(97, 99, 121, 0.50) 114.28%), radial-gradient(105.79% 42.67% at 50.41% 117.17%, rgba(139, 139, 139, 0.70) 7.29%, rgba(126, 126, 126, 0.26) 69.24%, rgba(62, 71, 91, 0.00) 100%), linear-gradient(0deg, rgba(18, 18, 18, 0.00) 0%, rgba(48, 53, 64, 0.80) 84.85%, rgba(34, 34, 34, 0.00) 114.5%)'
    case 'D':
      return 'linear-gradient(180deg, rgba(119, 132, 143, 0.00) 81.67%, rgba(97, 99, 121, 0.50) 115.85%), linear-gradient(0deg, rgba(18, 18, 18, 0.00) -34.5%, rgba(48, 53, 64, 0.80) 16.75%, rgba(34, 34, 34, 0.00) 159.5%)'
    default:
      return 'linear-gradient(180deg, rgba(119, 132, 143, 0.00) 81.67%, rgba(97, 99, 121, 0.50) 115.85%), linear-gradient(0deg, rgba(18, 18, 18, 0.00) -34.5%, rgba(48, 53, 64, 0.80) 16.75%, rgba(34, 34, 34, 0.00) 159.5%)'
  }
}

export const getMainPageRank = (rank: RankTypes | undefined): string => {
  switch (rank) {
    case 'S+':
      return mainPageRankSPlus
    case 'S':
      return mainPageRankS
    case 'AAA':
      return mainPageRankAAA
    case 'AA+':
      return mainPageRankAAPlus
    case 'AA':
      return mainPageRankAA
    case 'A+':
      return mainPageRankAPlus
    case 'A':
      return mainPageRankA
    case 'BBB':
      return mainPageRankBBB
    case 'BB+':
      return mainPageRankBBPlus
    case 'BB':
      return mainPageRankBB
    case 'B+':
      return mainPageRankBPlus
    case 'B':
      return mainPageRankB
    case 'CCC':
      return mainPageRankCCC
    case 'CC+':
      return mainPageRankCCPlus
    case 'CC':
      return mainPageRankCC
    case 'C+':
      return mainPageRankCPlus
    case 'C':
      return mainPageRankC
    case 'DDD':
      return mainPageRankDDD
    case 'DD+':
      return mainPageRankDDPlus
    case 'DD':
      return mainPageRankDD
    case 'D+':
      return mainPageRankDPlus
    case 'D':
      return mainPageRankD
    default:
      return mainPageRankD
  }
}

export const getLobbyRank = (rank: RankTypes | undefined): string => {
  switch (rank) {
    case 'S+':
      return lobbyRankSPlus
    case 'S':
      return lobbyRankS
    case 'AAA':
      return lobbyRankAAA
    case 'AA+':
      return lobbyRankAAPlus
    case 'AA':
      return lobbyRankAA
    case 'A+':
      return lobbyRankAPlus
    case 'A':
      return lobbyRankA
    case 'BBB':
      return lobbyRankBBB
    case 'BB+':
      return lobbyRankBBPlus
    case 'BB':
      return lobbyRankBB
    case 'B+':
      return lobbyRankBPlus
    case 'B':
      return lobbyRankB
    case 'CCC':
      return lobbyRankCCC
    case 'CC+':
      return lobbyRankCCPlus
    case 'CC':
      return lobbyRankCC
    case 'C+':
      return lobbyRankCPlus
    case 'C':
      return lobbyRankC
    case 'DDD':
      return lobbyRankDDD
    case 'DD+':
      return lobbyRankDDPlus
    case 'DD':
      return lobbyRankDD
    case 'D+':
      return lobbyRankDPlus
    case 'D':
      return lobbyRankD
    default:
      return lobbyRankD
  }
}
