import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { EnumGameExperience } from 'api/schemas/supportApi'

import { Header7 } from 'styles/typography'
import InfoTooltipIcon from 'components/Tooltip/Info/InfoTooltipIcon'
import RequiredFieldIndicator from 'components/RequiredFieldIndicator'
import Select from 'components/Select'

interface GameExperienceInputProps {
  value: EnumGameExperience
  onChange: (v: EnumGameExperience) => void
}

const GameExperienceInput = ({ value, onChange }: GameExperienceInputProps) => {
  const { t } = useTranslation()

  const mapGameExperienceToLabel: Record<EnumGameExperience, string> = {
    LESS_THAN_YEAR: t('judge.welcomePage.lessThanYear'),
    MORE_THAN_THREE_YEARS: t('judge.welcomePage.moreThanThreeYears'),
    FROM_ONE_TO_TREE_YEARS: t('judge.welcomePage.fromOneToThreeYears'),
  }

  return (
    <StyledSelect
      multi={false}
      onChange={([{ value }]) =>
        onChange(value as unknown as EnumGameExperience)
      }
      values={[
        {
          id: value,
          value: value,
          label: mapGameExperienceToLabel[value],
        },
      ]}
      label={
        <StyledLabel>
          {t('judge.welcomePage.choseExperience')}
          <RequiredFieldIndicator />
          <InfoTooltipIcon text={t('judge.welcomePage.infoTooltip')} />
        </StyledLabel>
      }
      options={[
        {
          label: t('judge.welcomePage.lessThanYear'),
          value: EnumGameExperience.GameExperienceLessThanYear,
        },
        {
          label: t('judge.welcomePage.fromOneToThreeYears'),
          value: EnumGameExperience.GameExperienceFromOneToTreeYears,
        },
        {
          label: t('judge.welcomePage.moreThanThreeYears'),
          value: EnumGameExperience.GameExperienceMoreThanThreeYears,
        },
      ]}
    />
  )
}

const StyledSelect = styled(Select)`
  height: 32px !important;
  margin-bottom: 30px;
  position: relative;
  z-index: 2;
`

const StyledLabel = styled(Header7)`
  display: flex;
  align-items: center;
  gap: 5px;
  color: white;
  margin-bottom: 2px;
`

export default GameExperienceInput
