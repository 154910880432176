const Support = () => (
  <svg
    width="20"
    fill="none"
    height="20"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="white"
      opacity="0.994"
      clipRule="evenodd"
      fillRule="evenodd"
      d="M9.78761 3.94236C11.6876 3.79618 13.1537 4.51472 14.1858 6.098C14.5005 6.63628 14.6974 7.21576 14.7769 7.83641C14.8 9.13441 14.8 10.4325 14.7769 11.7305C14.4932 13.4742 13.5371 14.6853 11.9085 15.3637C9.96632 15.9749 8.30321 15.5403 6.91923 14.0599C6.36586 13.3815 6.02975 12.6051 5.91095 11.7305C5.88776 10.4325 5.88776 9.13441 5.91095 7.83641C6.19459 6.09271 7.15072 4.88159 8.77933 4.20312C9.11293 4.08789 9.44904 4.00097 9.78761 3.94236Z"
    />
    <path
      fill="white"
      opacity="0.977"
      clipRule="evenodd"
      fillRule="evenodd"
      d="M9.70833 1.01841C12.7604 0.841085 15.1552 1.92786 16.8927 4.27873C17.4486 5.10809 17.8181 6.00748 18.001 6.97692C18.8635 6.88541 19.4968 7.19769 19.901 7.9138C19.9336 7.99954 19.96 8.087 19.9802 8.17612C20.0066 9.40031 20.0066 10.6245 19.9802 11.8487C19.8153 12.4545 19.4129 12.8355 18.7729 12.9916C18.5124 12.9987 18.2551 13.0174 18.001 13.0479C17.4578 15.5851 15.9471 17.3901 13.4687 18.463C12.4428 18.8746 11.374 19.0495 10.2625 18.9876C10.2625 18.7477 10.2625 17.8259 10.2625 17.8259C12.7663 17.7813 14.6729 16.7632 15.9823 14.7717C16.5253 13.852 16.8024 12.8652 16.8135 11.8112C16.8399 10.612 16.8399 9.41279 16.8135 8.21359C16.7132 5.94247 15.6511 4.2124 13.6271 3.02332C11.5423 1.94904 9.45765 1.94904 7.37291 3.02332C5.34874 4.21281 4.2866 5.94292 4.18646 8.21359C4.17326 9.6876 4.16007 11.1616 4.14687 12.6356C4.07431 12.8043 3.94896 12.923 3.77083 12.9916C3.20128 13.0265 2.63392 13.014 2.06875 12.9542C1.51038 12.7628 1.16073 12.3943 1.01979 11.8487C0.993402 10.6245 0.993402 9.40031 1.01979 8.17612C1.16073 7.63045 1.51038 7.26196 2.06875 7.07061C2.37956 7.03152 2.68963 7.00031 2.99896 6.97692C3.54216 4.43972 5.05294 2.63468 7.53125 1.5618C8.23757 1.29163 8.96325 1.1105 9.70833 1.01841Z"
    />
  </svg>
)

export default Support
