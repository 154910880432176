/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface AuthCredentialRequest {
  login?: string
  password?: string
  verificationCode?: number
}

export interface ClaimApproveDto {
  isApproved?: boolean
  messageId?: string
}

export interface ClaimEstimateDto {
  grade?: number
}

export interface ClaimTransferDto {
  userId?: string
}

export interface DtoAttachment {
  attachmentId?: string
  attachmentName?: string
  attachmentUrl?: string
  claimId?: string
}

export interface DtoAvatar {
  id?: string
  imgUrl?: string
}

export interface DtoCSMatchProfileStat {
  ADR?: number
  CSBestWeaponProfileStat?: {
    damage?: number
    headShotCount?: number
    killCount?: number
    matchId?: string
    profileId?: string
    weapon?: {
      gameName?: string
      id?: string
      imgIconUrl?: string
      imgUrl?: string
      name?: string
    }
  }
  EF?: number
  HS?: number
  KAST?: number
  KD?: number
  KDDiff?: number
  assist?: number
  dead?: number
  kill?: number
  matchId?: string
  profileId?: string
  rating?: number
  ratingDiff?: number
  score?: number
}

export interface DtoCSProfileStat {
  ADR?: number
  HS?: number
  KD?: number
  KDDiff?: number
  assist?: number
  confidence?: number
  createdAt?: string
  damage?: number
  dead?: number
  gameFormat?: string
  headShotCount?: number
  id?: string
  isCalibration?: boolean
  kill?: number
  loseStreak?: number
  matchDrawCount?: number
  matchLoseCount?: number
  matchWinCount?: number
  nextRankRating?: number
  prevRankRating?: number
  rank?: string
  rating?: number
  roundCount?: number
  score?: number
  steamId?: string
  updatedAt?: string
  userExternalId?: string
  userId?: string
  userImg?: string
  userName?: string
  winStreak?: number
}

export interface DtoChat {
  claimId?: string
  createdAt?: string
  haveUnreadMessage?: boolean
  id?: string
  isGroup?: boolean
  /** only group */
  lobbyId?: string
  messages?: DtoMessage[]
  participants?: DtoChatParticipant[]
  partyId?: string
}

export interface DtoChatParticipant {
  CSMatchProfileStat?: DtoCSMatchProfileStat
  CSProfileStat?: DtoCSProfileStat
  chatId?: string
  createdAt?: string
  deletedAt?: string
  email?: string
  externalId?: string
  id?: string
  imgUrl?: string
  name?: string
  role?: string
  updatedAt?: string
}

export interface DtoClaim {
  approvedAt?: string
  attachments?: DtoAttachment[]
  author?: DtoUser
  backToWorkAt?: string
  category?: EnumClaimReportCategory
  createStatus?: EnumCreateClaimStatus
  createdAt?: string
  description?: string
  executor?: DtoUser
  externalId?: string
  finishWorkAt?: string
  grade?: number
  id?: string
  reasons?: EnumClaimReason[]
  reportedMatch?: DtoMatch
  reportedUser?: DtoUser
  startWorkAt?: string
  status?: EnumClaimStatus
  timeCodes?: string[][]
  toWorkCount?: number
}

export interface DtoCreateMessage {
  attachments?: DtoAttachment[]
  claimId?: string
  isSystem?: boolean
  status?: string
  text?: string
}

export interface DtoJudge {
  statistic?: DtoJudgeClaimStat
  user?: DtoUser
}

export interface DtoJudgeClaim {
  approvedAt?: string
  attachments?: DtoAttachment[]
  author?: DtoUser
  createStatus?: EnumCreateJudgeClaimStatus
  createdAt?: string
  declinedAt?: string
  declinedAtViewed?: string
  deleteAfter?: string
  description?: string
  executor?: DtoUser
  externalId?: string
  gameExperience?: EnumGameExperience
  id?: string
  judgeExperienceExists?: boolean
  startWorkAt?: string
  status?: EnumJudgeClaimStatus
}

export interface DtoJudgeClaimStat {
  currentMothFinishedClaimCount?: number
  expiredClaimCount?: number
  finishedClaimCount?: number
  prevMothFinishedClaimCount?: number
}

export interface DtoMatch {
  CSMatchResult?: {
    CSMatchRoundResults?: {
      createdAt?: string
      loseTeamColor?: string
      loseTeamDeadCount?: number
      round?: number
      winCondition?: string
      winTeamColor?: string
      winTeamDeadCount?: number
    }[]
    attachmentId?: string
    createdAt?: string
    isDraw?: boolean
    loseTeamColor?: string
    loseTeamScore?: number
    matchId?: string
    winTeamColor?: string
    winTeamScore?: number
  }
  bet?: number
  country?: {
    ImgUrl?: string
    name?: string
  }
  createdAt?: string
  declinedAt?: string
  declinedCode?: string
  declinedReason?: string
  externalId?: string
  finishAt?: string
  gameFormat?: string
  gameMap?: {
    gameName?: string
    id?: string
    imgIconUrl?: string
    imgUrl?: string
    label?: string
    name?: string
  }
  gameMode?: {
    ImgUrl?: string
    id?: string
    name?: string
  }
  gameName?: string
  handleFinishAt?: string
  id?: string
  isDraw?: boolean
  isRating?: boolean
  lobbyId?: string
  loseTeamColor?: string
  loseTeamScore?: number
  participants?: {
    originLobbyId?: string
    slotOrder?: number
    teamColor?: string
    user?: DtoUser
  }[]
  startAt?: string
  status?: string
  statusTimestamp?: string
  url?: string
  winTeamColor?: string
  winTeamScore?: number
}

export interface DtoMessage {
  attachments?: DtoMessageAttachment[]
  author?: DtoUser
  authorId?: string
  createdAt?: string
  id?: string
  isSystem?: boolean
  /** (INIT, FINALLY, TO_DELETE, ESTIMATE) */
  status?: string
  text?: string
  user?: DtoUser
  userId?: string
}

export interface DtoMessageAttachment {
  attachmentId?: string
  attachmentUrl?: string
  createdAt?: string
  id?: string
  messageId?: string
}

export interface DtoUpdateClaim {
  claimId: string
  payload?: DtoUpdateClaimPayload
  status: EnumCreateClaimStatus
}

export interface DtoUpdateClaimPayload {
  category: EnumClaimReportCategory
  description?: string
  reasons: EnumClaimReason[]
  reportedMatchId?: string
  reportedUserId?: string
  timeCodes?: string[][]
}

export interface DtoUpdateJudgeClaim {
  claimId: string
  payload?: DtoUpdateJudgeClaimPayload
  status: EnumCreateJudgeClaimStatus
}

export interface DtoUpdateJudgeClaimPayload {
  description?: string
  gameExperience?: EnumGameExperience
  judgeExperienceExists?: boolean
}

export interface DtoUser {
  CSMatchProfileStat?: DtoCSMatchProfileStat
  CSProfileStat?: DtoCSProfileStat
  createdAt?: string
  email?: string
  externalId?: string
  id?: string
  imgUrl?: string
  name?: string
  role?: string
  updatedAt?: string
}

export enum EnumClaimReason {
  ClaimReasonNetworkDelay = 'NETWORK_DELAY',
  ClaimReasonGameCrushed = 'GAME_CRUSHED',
  ClaimReasonLoweringFPS = 'LOWERING_FPS',
  ClaimReasonCheating = 'CHEATING',
  ClaimReasonGriefing = 'GRIEFING',
  ClaimReasonUnsportsmanlikeBehavior = 'UNSPORTSMANLIKE_BEHAVIOR',
}

export enum EnumClaimReportCategory {
  ClaimReportCategoryServiceReport = 'SERVICE_REPORT',
  ClaimReportCategoryPlayerReport = 'PLAYER_REPORT',
  ClaimReportCategoryMatchReport = 'MATCH_REPORT',
}

export enum EnumClaimStatus {
  SupportClaimStatusPreparing = 'PREPARING',
  SupportClaimStatusNew = 'NEW',
  SupportClaimStatusInProgress = 'IN_PROGRESS',
  SupportClaimStatusFinish = 'FINISH',
  SupportClaimStatusApproved = 'APPROVED',
}

export enum EnumCreateClaimStatus {
  CreateClaimStatusDraw = 'DRAW',
  CreateClaimStatusFileUploading = 'FILE_UPLOADING',
  CreateClaimStatusCompleted = 'COMPLETED',
}

export enum EnumCreateJudgeClaimStatus {
  CreateJudgeClaimStatusDraw = 'DRAW',
  CreateJudgeClaimStatusFileUploading = 'FILE_UPLOADING',
  CreateJudgeClaimStatusCompleted = 'COMPLETED',
}

export enum EnumGameExperience {
  GameExperienceLessThanYear = 'LESS_THAN_YEAR',
  GameExperienceFromOneToTreeYears = 'FROM_ONE_TO_TREE_YEARS',
  GameExperienceMoreThanThreeYears = 'MORE_THAN_THREE_YEARS',
}

export enum EnumJudgeClaimStatus {
  SupportJudgeClaimStatusPreparing = 'PREPARING',
  SupportJudgeClaimStatusNew = 'NEW',
  SupportJudgeClaimStatusInProgress = 'IN_PROGRESS',
  SupportJudgeClaimStatusApproved = 'APPROVED',
  SupportJudgeClaimStatusDeclined = 'DECLINED',
}

export interface InternalControllerHttpV1UserChangePasswordRequest {
  newPassword?: string
  password?: string
}

export interface ResponseResponse {
  data?: any
  meta?: ResponseMeta
  pagination?: ResponsePagination
}

export interface ResponseMeta {
  code?: string
  error?: string
  message?: string
  requestId?: string
  traceId?: string
}

export interface ResponsePagination {
  page?: number
  pages?: number
  perPage?: number
}

export interface UserChangeEmail {
  code?: number
  email?: string
}

export interface UserCreateDto {
  email?: string
  role?: string
}

export interface UserDeleteDto {
  userId?: string
}

export interface UserUpdateDto {
  imgUrl?: string
  name?: string
}

export type GetCodeSendEmailCodeError = ResponseResponse

export type PostCodeVerifyCodeError = ResponseResponse

export type DeleteLogOutError = ResponseResponse

export type PostSignInError = ResponseResponse

export type GetTokenRefreshError = ResponseResponse

export type PostUserPasswordForgetError = ResponseResponse

export type GetChatError = ResponseResponse

export type PostIdMessageError = ResponseResponse

export type GetClaimIdError = ResponseResponse

export type GetClaimError = ResponseResponse

export type PutClaimError = ResponseResponse

export type PostClaimError = ResponseResponse

export type GetClaim2Error = ResponseResponse

export type GetDraftError = ResponseResponse

export type GetMineError = ResponseResponse

export type PutApproveError = ResponseResponse

export type PostAttachmentError = ResponseResponse

export type DeleteAttachmentError = ResponseResponse

export type PutEstimateError = ResponseResponse

export type PutFinishError = ResponseResponse

export type PutTransferError = ResponseResponse

export type PutWorkError = ResponseResponse

export type GetJudgeError = ResponseResponse

export type DeleteJudgeError = ResponseResponse

export type GetClaimFail = ResponseResponse

export type PutClaimFail = ResponseResponse

export type PostClaimFail = ResponseResponse

export type GetClaimIdFail = ResponseResponse

export type GetClaimCurrentError = ResponseResponse

export type PutClaimApproveError = ResponseResponse

export type PostClaimAttachmentError = ResponseResponse

export type DeleteClaimAttachmentError = ResponseResponse

export type PutClaimDeclineError = ResponseResponse

export type PutClaimViewError = ResponseResponse

export type PutClaimWorkError = ResponseResponse

export type GetUserError = ResponseResponse

export type PutUserError = ResponseResponse

export type PostUserError = ResponseResponse

export type DeleteUserError = ResponseResponse

export type PostIdPasswordRestoreError = ResponseResponse

export type GetAvatarsError = ResponseResponse

export type PostEmailChangeError = ResponseResponse

export type PostPasswordChangeError = ResponseResponse

export type GetSearchError = ResponseResponse

export namespace Auth {
  /**
   * @description send email code
   * @tags Support
   * @name GetCodeSendEmailCode
   * @summary send email code
   * @request GET:/auth/code/sendEmailCode
   */
  export namespace GetCodeSendEmailCode {
    export type RequestParams = {}
    export type RequestQuery = {
      /** email */
      email: string
    }
    export type RequestBody = never
    export type RequestHeaders = {
      /** Request id identity */
      'X-Request-Id': string
    }
    export type ResponseBody = ResponseResponse
  }

  /**
   * @description verify code
   * @tags Support
   * @name PostCodeVerifyCode
   * @summary verify code
   * @request POST:/auth/code/verifyCode
   */
  export namespace PostCodeVerifyCode {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = AuthCredentialRequest
    export type RequestHeaders = {
      /** Request id identity */
      'X-Request-Id': string
    }
    export type ResponseBody = ResponseResponse
  }

  /**
   * @description LogOut
   * @tags Support
   * @name DeleteLogOut
   * @summary LogOut
   * @request DELETE:/auth/logOut
   */
  export namespace DeleteLogOut {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {
      /** Request id identity */
      'X-Request-Id': string
    }
    export type ResponseBody = ResponseResponse
  }

  /**
   * @description SignIn
   * @tags Support
   * @name PostSignIn
   * @summary SignIn
   * @request POST:/auth/signIn
   */
  export namespace PostSignIn {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = AuthCredentialRequest
    export type RequestHeaders = {
      /** Request id identity */
      'X-Request-Id': string
    }
    export type ResponseBody = ResponseResponse
  }

  /**
   * @description Refresh Token
   * @tags Support
   * @name GetTokenRefresh
   * @summary Refresh Token
   * @request GET:/auth/token/refresh
   */
  export namespace GetTokenRefresh {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {
      /**
       * Access token
       * @default "Bearer <token>"
       */
      Authorization: string
      /** Request id identity */
      'X-Request-Id': string
    }
    export type ResponseBody = ResponseResponse
  }

  /**
   * @description Restore Password
   * @tags Support
   * @name PostUserPasswordForget
   * @summary Restore Password
   * @request POST:/auth/user/password/forget
   */
  export namespace PostUserPasswordForget {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = AuthCredentialRequest
    export type RequestHeaders = {
      /** Request id identity */
      'X-Request-Id': string
    }
    export type ResponseBody = ResponseResponse
  }
}

export namespace Chat {
  /**
   * @description Get chat by id
   * @tags Support
   * @name GetChat
   * @summary Get chat by id
   * @request GET:/chat/:id
   */
  export namespace GetChat {
    export type RequestParams = {
      /** Chat id */
      id: string
    }
    export type RequestQuery = {
      /** last message dateTime which get another messages */
      fromCreatedAt?: string
    }
    export type RequestBody = never
    export type RequestHeaders = {
      /**
       * Access token
       * @default "Bearer <token>"
       */
      Authorization: string
      /** Request id identity */
      'X-Request-Id': string
    }
    export type ResponseBody = ResponseResponse & {
      data?: DtoChat
    }
  }

  /**
   * @description Send message to chat
   * @tags Support
   * @name PostIdMessage
   * @summary Send message to chat
   * @request POST:/chat/:id/message
   */
  export namespace PostIdMessage {
    export type RequestParams = {
      /** Chat id */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = DtoCreateMessage
    export type RequestHeaders = {
      /**
       * Access token
       * @default "Bearer <token>"
       */
      Authorization: string
      /** Request id identity */
      'X-Request-Id': string
    }
    export type ResponseBody = ResponseResponse
  }

  /**
   * @description Get chat by claimId
   * @tags Support
   * @name GetClaimId
   * @summary Get chat by claimId
   * @request GET:/chat/claim/:id
   */
  export namespace GetClaimId {
    export type RequestParams = {
      /** Claim id */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {
      /**
       * Access token
       * @default "Bearer <token>"
       */
      Authorization: string
      /** Request id identity */
      'X-Request-Id': string
    }
    export type ResponseBody = ResponseResponse & {
      data?: DtoChat
    }
  }
}

export namespace Claim {
  /**
   * @description Get claims by params
   * @tags Support
   * @name GetClaim
   * @summary Get claims by params
   * @request GET:/claim
   */
  export namespace GetClaim {
    export type RequestParams = {}
    export type RequestQuery = {
      /** executor id */
      executorId?: string
      /** category */
      category?: string
      /** searchStr (user externalId or claim externalId) */
      searchStr?: string
      /** status */
      status?: string
    }
    export type RequestBody = never
    export type RequestHeaders = {
      /**
       * Access token
       * @default "Bearer <token>"
       */
      Authorization: string
      /** Request id identity */
      'X-Request-Id': string
    }
    export type ResponseBody = ResponseResponse & {
      data?: DtoClaim[]
    }
  }

  /**
   * @description update claim by user
   * @tags Support
   * @name PutClaim
   * @summary update claim by user
   * @request PUT:/claim
   */
  export namespace PutClaim {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = DtoUpdateClaim
    export type RequestHeaders = {
      /**
       * Access token
       * @default "Bearer <token>"
       */
      Authorization: string
      /** Request id identity */
      'X-Request-Id': string
    }
    export type ResponseBody = ResponseResponse
  }

  /**
   * @description create claim
   * @tags Support
   * @name PostClaim
   * @summary create claim
   * @request POST:/claim
   */
  export namespace PostClaim {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {
      /**
       * Access token
       * @default "Bearer <token>"
       */
      Authorization: string
      /** Request id identity */
      'X-Request-Id': string
    }
    export type ResponseBody = ResponseResponse
  }

  /**
   * @description Get claim by id
   * @tags Support
   * @name GetClaim2
   * @summary Get claim by id
   * @request GET:/claim/:id
   * @originalName getClaim
   * @duplicate
   */
  export namespace GetClaim2 {
    export type RequestParams = {
      /** Claim id */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {
      /**
       * Access token
       * @default "Bearer <token>"
       */
      Authorization: string
      /** Request id identity */
      'X-Request-Id': string
    }
    export type ResponseBody = ResponseResponse & {
      data?: DtoClaim
    }
  }

  /**
   * @description Get claim by id
   * @tags Support
   * @name GetDraft
   * @summary Get claim by id
   * @request GET:/claim/draft
   */
  export namespace GetDraft {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {
      /**
       * Access token
       * @default "Bearer <token>"
       */
      Authorization: string
      /** Request id identity */
      'X-Request-Id': string
    }
    export type ResponseBody = ResponseResponse & {
      data?: DtoClaim
    }
  }

  /**
   * @description Get claims by user who was author it
   * @tags Support
   * @name GetMine
   * @summary Get claims by authorId
   * @request GET:/claim/mine
   */
  export namespace GetMine {
    export type RequestParams = {}
    export type RequestQuery = {
      /** status */
      status?: string
    }
    export type RequestBody = never
    export type RequestHeaders = {
      /**
       * Access token
       * @default "Bearer <token>"
       */
      Authorization: string
      /** Request id identity */
      'X-Request-Id': string
    }
    export type ResponseBody = ResponseResponse & {
      data?: DtoClaim[]
    }
  }

  /**
   * @description Approve claim
   * @tags Support
   * @name PutApprove
   * @summary Approve claim
   * @request PUT:/claim/{id}/approve
   */
  export namespace PutApprove {
    export type RequestParams = {
      /** Dto id */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = ClaimApproveDto
    export type RequestHeaders = {
      /**
       * Access token
       * @default "Bearer <token>"
       */
      Authorization: string
      /** Request id identity */
      'X-Request-Id': string
    }
    export type ResponseBody = ResponseResponse
  }

  /**
   * @description add file to claim
   * @tags Support
   * @name PostAttachment
   * @summary add file to claim
   * @request POST:/claim/{id}/attachment
   */
  export namespace PostAttachment {
    export type RequestParams = {
      /** Claim id */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = {
      /** attachment file */
      file: File
    }
    export type RequestHeaders = {
      /**
       * Access token
       * @default "Bearer <token>"
       */
      Authorization: string
      /** Request id identity */
      'X-Request-Id': string
    }
    export type ResponseBody = ResponseResponse & {
      data?: DtoAttachment
    }
  }

  /**
   * @description delete file from claim
   * @tags Support
   * @name DeleteAttachment
   * @summary delete file from claim
   * @request DELETE:/claim/{id}/attachment/{attachmentId}
   */
  export namespace DeleteAttachment {
    export type RequestParams = {
      /** Claim id */
      id: string
      /** Attachment id */
      attachmentId: string
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {
      /**
       * Access token
       * @default "Bearer <token>"
       */
      Authorization: string
      /** Request id identity */
      'X-Request-Id': string
    }
    export type ResponseBody = ResponseResponse
  }

  /**
   * @description Approve claim
   * @tags Support
   * @name PutEstimate
   * @summary Approve claim
   * @request PUT:/claim/{id}/estimate
   */
  export namespace PutEstimate {
    export type RequestParams = {
      /** Dto id */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = ClaimEstimateDto
    export type RequestHeaders = {
      /**
       * Access token
       * @default "Bearer <token>"
       */
      Authorization: string
      /** Request id identity */
      'X-Request-Id': string
    }
    export type ResponseBody = ResponseResponse
  }

  /**
   * @description Finish claim
   * @tags Support
   * @name PutFinish
   * @summary Finish claim
   * @request PUT:/claim/{id}/finish
   */
  export namespace PutFinish {
    export type RequestParams = {
      /** Dto id */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {
      /**
       * Access token
       * @default "Bearer <token>"
       */
      Authorization: string
      /** Request id identity */
      'X-Request-Id': string
    }
    export type ResponseBody = ResponseResponse
  }

  /**
   * @description Transfer claim to another user
   * @tags Support
   * @name PutTransfer
   * @summary Transfer claim to another user
   * @request PUT:/claim/{id}/transfer
   */
  export namespace PutTransfer {
    export type RequestParams = {
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = ClaimTransferDto
    export type RequestHeaders = {
      /**
       * Access token
       * @default "Bearer <token>"
       */
      Authorization: string
      /** Request id identity */
      'X-Request-Id': string
    }
    export type ResponseBody = ResponseResponse
  }

  /**
   * @description Take claim to work
   * @tags Support
   * @name PutWork
   * @summary Take claim to work
   * @request PUT:/claim/{id}/work
   */
  export namespace PutWork {
    export type RequestParams = {
      /** Dto id */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {
      /**
       * Access token
       * @default "Bearer <token>"
       */
      Authorization: string
      /** Request id identity */
      'X-Request-Id': string
    }
    export type ResponseBody = ResponseResponse
  }
}

export namespace Judge {
  /**
   * @description Get judge list
   * @tags Support
   * @name GetJudge
   * @summary Get judge list
   * @request GET:/judge
   */
  export namespace GetJudge {
    export type RequestParams = {}
    export type RequestQuery = {
      /** searchStr (user externalId or name) */
      searchStr?: string
    }
    export type RequestBody = never
    export type RequestHeaders = {
      /**
       * Access token
       * @default "Bearer <token>"
       */
      Authorization: string
      /** Request id identity */
      'X-Request-Id': string
    }
    export type ResponseBody = ResponseResponse & {
      data?: DtoJudge[]
    }
  }

  /**
   * @description Remove judge
   * @tags Support
   * @name DeleteJudge
   * @summary Remove judge
   * @request DELETE:/judge/:id
   */
  export namespace DeleteJudge {
    export type RequestParams = {
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {
      /**
       * Access token
       * @default "Bearer <token>"
       */
      Authorization: string
      /** Request id identity */
      'X-Request-Id': string
    }
    export type ResponseBody = ResponseResponse & {
      data?: DtoJudge[]
    }
  }

  /**
   * @description Get judge claim list
   * @tags Support
   * @name GetClaim
   * @summary Get judge claim list
   * @request GET:/judge/claim
   */
  export namespace GetClaim {
    export type RequestParams = {}
    export type RequestQuery = {
      /** searchStr (user externalId or claim externalId) */
      searchStr?: string
      /** status */
      status?: 'PREPARING' | 'NEW' | 'IN_PROGRESS' | 'FINISH' | 'APPROVED'
    }
    export type RequestBody = never
    export type RequestHeaders = {
      /**
       * Access token
       * @default "Bearer <token>"
       */
      Authorization: string
      /** Request id identity */
      'X-Request-Id': string
    }
    export type ResponseBody = ResponseResponse & {
      data?: DtoJudgeClaim[]
    }
  }

  /**
   * @description update claim by user
   * @tags Support
   * @name PutClaim
   * @summary update claim by user
   * @request PUT:/judge/claim
   */
  export namespace PutClaim {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = DtoUpdateJudgeClaim
    export type RequestHeaders = {
      /**
       * Access token
       * @default "Bearer <token>"
       */
      Authorization: string
      /** Request id identity */
      'X-Request-Id': string
    }
    export type ResponseBody = ResponseResponse
  }

  /**
   * @description create claim for judge
   * @tags Support
   * @name PostClaim
   * @summary create claim for judge
   * @request POST:/judge/claim
   */
  export namespace PostClaim {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {
      /**
       * Access token
       * @default "Bearer <token>"
       */
      Authorization: string
      /** Request id identity */
      'X-Request-Id': string
    }
    export type ResponseBody = ResponseResponse
  }

  /**
   * @description Get claim by id
   * @tags Support
   * @name GetClaimId
   * @summary Get claim by id
   * @request GET:/judge/claim/:id
   */
  export namespace GetClaimId {
    export type RequestParams = {
      /** Claim id */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {
      /**
       * Access token
       * @default "Bearer <token>"
       */
      Authorization: string
      /** Request id identity */
      'X-Request-Id': string
    }
    export type ResponseBody = ResponseResponse & {
      data?: DtoJudgeClaim
    }
  }

  /**
   * @description Get current judge claim
   * @tags Support
   * @name GetClaimCurrent
   * @summary Get current judge claim
   * @request GET:/judge/claim/current
   */
  export namespace GetClaimCurrent {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {
      /**
       * Access token
       * @default "Bearer <token>"
       */
      Authorization: string
      /** Request id identity */
      'X-Request-Id': string
    }
    export type ResponseBody = ResponseResponse & {
      data?: DtoJudgeClaim
    }
  }

  /**
   * @description Approve judge claim
   * @tags Support
   * @name PutClaimApprove
   * @summary Approve judge claim
   * @request PUT:/judge/claim/{id}/approve
   */
  export namespace PutClaimApprove {
    export type RequestParams = {
      /** Dto id */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {
      /**
       * Access token
       * @default "Bearer <token>"
       */
      Authorization: string
      /** Request id identity */
      'X-Request-Id': string
    }
    export type ResponseBody = ResponseResponse
  }

  /**
   * @description add file to claim
   * @tags Support
   * @name PostClaimAttachment
   * @summary add file to claim
   * @request POST:/judge/claim/{id}/attachment
   */
  export namespace PostClaimAttachment {
    export type RequestParams = {
      /** Claim id */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = {
      /** attachment file */
      file: File
    }
    export type RequestHeaders = {
      /**
       * Access token
       * @default "Bearer <token>"
       */
      Authorization: string
      /** Request id identity */
      'X-Request-Id': string
    }
    export type ResponseBody = ResponseResponse & {
      data?: DtoAttachment
    }
  }

  /**
   * @description delete file from judge claim
   * @tags Support
   * @name DeleteClaimAttachment
   * @summary delete file from judge claim
   * @request DELETE:/judge/claim/{id}/attachment/{attachmentId}
   */
  export namespace DeleteClaimAttachment {
    export type RequestParams = {
      /** Claim id */
      id: string
      /** Attachment id */
      attachmentId: string
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {
      /**
       * Access token
       * @default "Bearer <token>"
       */
      Authorization: string
      /** Request id identity */
      'X-Request-Id': string
    }
    export type ResponseBody = ResponseResponse
  }

  /**
   * @description Decline judge claim
   * @tags Support
   * @name PutClaimDecline
   * @summary Decline judge claim
   * @request PUT:/judge/claim/{id}/decline
   */
  export namespace PutClaimDecline {
    export type RequestParams = {
      /** Dto id */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {
      /**
       * Access token
       * @default "Bearer <token>"
       */
      Authorization: string
      /** Request id identity */
      'X-Request-Id': string
    }
    export type ResponseBody = ResponseResponse
  }

  /**
   * @description View decline judge claim
   * @tags Support
   * @name PutClaimView
   * @summary View decline judge claim
   * @request PUT:/judge/claim/{id}/view
   */
  export namespace PutClaimView {
    export type RequestParams = {
      /** Dto id */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {
      /**
       * Access token
       * @default "Bearer <token>"
       */
      Authorization: string
      /** Request id identity */
      'X-Request-Id': string
    }
    export type ResponseBody = ResponseResponse
  }

  /**
   * @description Take claim to work
   * @tags Support
   * @name PutClaimWork
   * @summary Take claim to work
   * @request PUT:/judge/claim/{id}/work
   */
  export namespace PutClaimWork {
    export type RequestParams = {
      /** Dto id */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {
      /**
       * Access token
       * @default "Bearer <token>"
       */
      Authorization: string
      /** Request id identity */
      'X-Request-Id': string
    }
    export type ResponseBody = ResponseResponse
  }
}

export namespace User {
  /**
   * @description get self info
   * @tags Support
   * @name GetUser
   * @summary get self info
   * @request GET:/user
   */
  export namespace GetUser {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {
      /**
       * Access token
       * @default "Bearer <token>"
       */
      Authorization: string
      /** Request id identity */
      'X-Request-Id': string
    }
    export type ResponseBody = ResponseResponse & {
      data?: DtoUser
    }
  }

  /**
   * @description Update user
   * @tags Support
   * @name PutUser
   * @summary Update user
   * @request PUT:/user
   */
  export namespace PutUser {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = UserUpdateDto
    export type RequestHeaders = {
      /**
       * Access token
       * @default "Bearer <token>"
       */
      Authorization: string
      /** Request id identity */
      'X-Request-Id': string
    }
    export type ResponseBody = ResponseResponse
  }

  /**
   * @description Save user
   * @tags Support
   * @name PostUser
   * @summary Save user
   * @request POST:/user
   */
  export namespace PostUser {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = UserCreateDto
    export type RequestHeaders = {
      /**
       * Access token
       * @default "Bearer <token>"
       */
      Authorization: string
      /** Request id identity */
      'X-Request-Id': string
    }
    export type ResponseBody = ResponseResponse
  }

  /**
   * @description Delete user
   * @tags Support
   * @name DeleteUser
   * @summary Delete user
   * @request DELETE:/user
   */
  export namespace DeleteUser {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = UserDeleteDto
    export type RequestHeaders = {
      /**
       * Access token
       * @default "Bearer <token>"
       */
      Authorization: string
      /** Request id identity */
      'X-Request-Id': string
    }
    export type ResponseBody = ResponseResponse
  }

  /**
   * @description Restore Password for another user by admin
   * @tags Support
   * @name PostIdPasswordRestore
   * @summary Restore Password for another user by admin
   * @request POST:/user/:id/password/restore
   */
  export namespace PostIdPasswordRestore {
    export type RequestParams = {
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {
      /** Request id identity */
      'X-Request-Id': string
      /**
       * Access token
       * @default "Bearer <token>"
       */
      Authorization: string
    }
    export type ResponseBody = ResponseResponse
  }

  /**
   * @description get default avatars
   * @tags Support
   * @name GetAvatars
   * @summary get default avatars
   * @request GET:/user/avatars
   */
  export namespace GetAvatars {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {
      /**
       * Access token
       * @default "Bearer <token>"
       */
      Authorization: string
      /** Request id identity */
      'X-Request-Id': string
    }
    export type ResponseBody = ResponseResponse & {
      data?: DtoAvatar
    }
  }

  /**
   * @description Change email
   * @tags Support
   * @name PostEmailChange
   * @summary Change email
   * @request POST:/user/email/change
   */
  export namespace PostEmailChange {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = UserChangeEmail
    export type RequestHeaders = {
      /** Request id identity */
      'X-Request-Id': string
      /**
       * Access token
       * @default "Bearer <token>"
       */
      Authorization: string
    }
    export type ResponseBody = ResponseResponse
  }

  /**
   * @description Change password
   * @tags Support
   * @name PostPasswordChange
   * @summary Change password
   * @request POST:/user/password/change
   */
  export namespace PostPasswordChange {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = InternalControllerHttpV1UserChangePasswordRequest
    export type RequestHeaders = {
      /**
       * Access token
       * @default "Bearer <token>"
       */
      Authorization: string
      /** Request id identity */
      'X-Request-Id': string
    }
    export type ResponseBody = ResponseResponse
  }

  /**
   * @description Search users
   * @tags Support
   * @name GetSearch
   * @summary Search users
   * @request GET:/user/search
   */
  export namespace GetSearch {
    export type RequestParams = {}
    export type RequestQuery = {
      /** Name for search */
      userSearchName?: string
    }
    export type RequestBody = never
    export type RequestHeaders = {
      /**
       * Access token
       * @default "Bearer <token>"
       */
      Authorization: string
      /** Request id identity */
      'X-Request-Id': string
    }
    export type ResponseBody = ResponseResponse & {
      data?: DtoUser
    }
  }
}

import type { AxiosInstance, AxiosRequestConfig, HeadersDefaults, ResponseType } from 'axios'
import axios from 'axios'

export type QueryParamsType = Record<string | number, any>

export interface FullRequestParams extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean
  /** request path */
  path: string
  /** content type of request body */
  type?: ContentType
  /** query params */
  query?: QueryParamsType
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType
  /** request body */
  body?: unknown
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
  securityWorker?: (
    securityData: SecurityDataType | null
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void
  secure?: boolean
  format?: ResponseType
}

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance
  private securityData: SecurityDataType | null = null
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker']
  private secure?: boolean
  private format?: ResponseType

  constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({
      ...axiosConfig,
      baseURL: axiosConfig.baseURL || '//localhost:8080/f2f/support/api/v1',
    })
    this.secure = secure
    this.format = format
    this.securityWorker = securityWorker
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data
  }

  protected mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method)

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method && this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    }
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === 'object' && formItem !== null) {
      return JSON.stringify(formItem)
    } else {
      return `${formItem}`
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    if (input instanceof FormData) {
      return input
    }
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key]
      const propertyContent: any[] = property instanceof Array ? property : [property]

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem))
      }

      return formData
    }, new FormData())
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<T> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {}
    const requestParams = this.mergeRequestParams(params, secureParams)
    const responseFormat = format || this.format || undefined

    if (type === ContentType.FormData && body && body !== null && typeof body === 'object') {
      body = this.createFormData(body as Record<string, unknown>)
    }

    if (type === ContentType.Text && body && body !== null && typeof body !== 'string') {
      body = JSON.stringify(body)
    }

    return this.instance
      .request({
        ...requestParams,
        headers: {
          ...(requestParams.headers || {}),
          ...(type ? { 'Content-Type': type } : {}),
        },
        params: query,
        responseType: responseFormat,
        data: body,
        url: path,
      })
      .then((response) => response.data)
  }
}

/**
 * @title User api
 * @version 1.0
 * @baseUrl //localhost:8080/f2f/support/api/v1
 * @contact Mikhel Alexander
 *
 * This is support api service.
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  auth = {
    /**
     * @description send email code
     *
     * @tags Support
     * @name GetCodeSendEmailCode
     * @summary send email code
     * @request GET:/auth/code/sendEmailCode
     */
    getCodeSendEmailCode: (
      query: {
        /** email */
        email: string
      },
      params: RequestParams = {}
    ) =>
      this.request<ResponseResponse, GetCodeSendEmailCodeError>({
        path: `/auth/code/sendEmailCode`,
        method: 'GET',
        query: query,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description verify code
     *
     * @tags Support
     * @name PostCodeVerifyCode
     * @summary verify code
     * @request POST:/auth/code/verifyCode
     */
    postCodeVerifyCode: (request: AuthCredentialRequest, params: RequestParams = {}) =>
      this.request<ResponseResponse, PostCodeVerifyCodeError>({
        path: `/auth/code/verifyCode`,
        method: 'POST',
        body: request,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description LogOut
     *
     * @tags Support
     * @name DeleteLogOut
     * @summary LogOut
     * @request DELETE:/auth/logOut
     */
    deleteLogOut: (params: RequestParams = {}) =>
      this.request<ResponseResponse, DeleteLogOutError>({
        path: `/auth/logOut`,
        method: 'DELETE',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description SignIn
     *
     * @tags Support
     * @name PostSignIn
     * @summary SignIn
     * @request POST:/auth/signIn
     */
    postSignIn: (request: AuthCredentialRequest, params: RequestParams = {}) =>
      this.request<ResponseResponse, PostSignInError>({
        path: `/auth/signIn`,
        method: 'POST',
        body: request,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Refresh Token
     *
     * @tags Support
     * @name GetTokenRefresh
     * @summary Refresh Token
     * @request GET:/auth/token/refresh
     */
    getTokenRefresh: (params: RequestParams = {}) =>
      this.request<ResponseResponse, GetTokenRefreshError>({
        path: `/auth/token/refresh`,
        method: 'GET',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Restore Password
     *
     * @tags Support
     * @name PostUserPasswordForget
     * @summary Restore Password
     * @request POST:/auth/user/password/forget
     */
    postUserPasswordForget: (request: AuthCredentialRequest, params: RequestParams = {}) =>
      this.request<ResponseResponse, PostUserPasswordForgetError>({
        path: `/auth/user/password/forget`,
        method: 'POST',
        body: request,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  chat = {
    /**
     * @description Get chat by id
     *
     * @tags Support
     * @name GetChat
     * @summary Get chat by id
     * @request GET:/chat/:id
     */
    getChat: (
      id: string,
      query?: {
        /** last message dateTime which get another messages */
        fromCreatedAt?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<
        ResponseResponse & {
          data?: DtoChat
        },
        GetChatError
      >({
        path: `/chat/${id}`,
        method: 'GET',
        query: query,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Send message to chat
     *
     * @tags Support
     * @name PostIdMessage
     * @summary Send message to chat
     * @request POST:/chat/:id/message
     */
    postIdMessage: (id: string, request: DtoCreateMessage, params: RequestParams = {}) =>
      this.request<ResponseResponse, PostIdMessageError>({
        path: `/chat/${id}/message`,
        method: 'POST',
        body: request,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Get chat by claimId
     *
     * @tags Support
     * @name GetClaimId
     * @summary Get chat by claimId
     * @request GET:/chat/claim/:id
     */
    getClaimId: (id: string, params: RequestParams = {}) =>
      this.request<
        ResponseResponse & {
          data?: DtoChat
        },
        GetClaimIdError
      >({
        path: `/chat/claim/${id}`,
        method: 'GET',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  claim = {
    /**
     * @description Get claims by params
     *
     * @tags Support
     * @name GetClaim
     * @summary Get claims by params
     * @request GET:/claim
     */
    getClaim: (
      query?: {
        /** executor id */
        executorId?: string
        /** category */
        category?: string
        /** searchStr (user externalId or claim externalId) */
        searchStr?: string
        /** status */
        status?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<
        ResponseResponse & {
          data?: DtoClaim[]
        },
        GetClaimError
      >({
        path: `/claim`,
        method: 'GET',
        query: query,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description update claim by user
     *
     * @tags Support
     * @name PutClaim
     * @summary update claim by user
     * @request PUT:/claim
     */
    putClaim: (request: DtoUpdateClaim, params: RequestParams = {}) =>
      this.request<ResponseResponse, PutClaimError>({
        path: `/claim`,
        method: 'PUT',
        body: request,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description create claim
     *
     * @tags Support
     * @name PostClaim
     * @summary create claim
     * @request POST:/claim
     */
    postClaim: (params: RequestParams = {}) =>
      this.request<ResponseResponse, PostClaimError>({
        path: `/claim`,
        method: 'POST',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Get claim by id
     *
     * @tags Support
     * @name GetClaim2
     * @summary Get claim by id
     * @request GET:/claim/:id
     * @originalName getClaim
     * @duplicate
     */
    getClaim2: (id: string, params: RequestParams = {}) =>
      this.request<
        ResponseResponse & {
          data?: DtoClaim
        },
        GetClaim2Error
      >({
        path: `/claim/${id}`,
        method: 'GET',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Get claim by id
     *
     * @tags Support
     * @name GetDraft
     * @summary Get claim by id
     * @request GET:/claim/draft
     */
    getDraft: (params: RequestParams = {}) =>
      this.request<
        ResponseResponse & {
          data?: DtoClaim
        },
        GetDraftError
      >({
        path: `/claim/draft`,
        method: 'GET',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Get claims by user who was author it
     *
     * @tags Support
     * @name GetMine
     * @summary Get claims by authorId
     * @request GET:/claim/mine
     */
    getMine: (
      query?: {
        /** status */
        status?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<
        ResponseResponse & {
          data?: DtoClaim[]
        },
        GetMineError
      >({
        path: `/claim/mine`,
        method: 'GET',
        query: query,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Approve claim
     *
     * @tags Support
     * @name PutApprove
     * @summary Approve claim
     * @request PUT:/claim/{id}/approve
     */
    putApprove: (id: string, request: ClaimApproveDto, params: RequestParams = {}) =>
      this.request<ResponseResponse, PutApproveError>({
        path: `/claim/${id}/approve`,
        method: 'PUT',
        body: request,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description add file to claim
     *
     * @tags Support
     * @name PostAttachment
     * @summary add file to claim
     * @request POST:/claim/{id}/attachment
     */
    postAttachment: (
      id: string,
      data: {
        /** attachment file */
        file: File
      },
      params: RequestParams = {}
    ) =>
      this.request<
        ResponseResponse & {
          data?: DtoAttachment
        },
        PostAttachmentError
      >({
        path: `/claim/${id}/attachment`,
        method: 'POST',
        body: data,
        type: ContentType.FormData,
        format: 'json',
        ...params,
      }),

    /**
     * @description delete file from claim
     *
     * @tags Support
     * @name DeleteAttachment
     * @summary delete file from claim
     * @request DELETE:/claim/{id}/attachment/{attachmentId}
     */
    deleteAttachment: (id: string, attachmentId: string, params: RequestParams = {}) =>
      this.request<ResponseResponse, DeleteAttachmentError>({
        path: `/claim/${id}/attachment/${attachmentId}`,
        method: 'DELETE',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Approve claim
     *
     * @tags Support
     * @name PutEstimate
     * @summary Approve claim
     * @request PUT:/claim/{id}/estimate
     */
    putEstimate: (id: string, request: ClaimEstimateDto, params: RequestParams = {}) =>
      this.request<ResponseResponse, PutEstimateError>({
        path: `/claim/${id}/estimate`,
        method: 'PUT',
        body: request,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Finish claim
     *
     * @tags Support
     * @name PutFinish
     * @summary Finish claim
     * @request PUT:/claim/{id}/finish
     */
    putFinish: (id: string, params: RequestParams = {}) =>
      this.request<ResponseResponse, PutFinishError>({
        path: `/claim/${id}/finish`,
        method: 'PUT',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Transfer claim to another user
     *
     * @tags Support
     * @name PutTransfer
     * @summary Transfer claim to another user
     * @request PUT:/claim/{id}/transfer
     */
    putTransfer: (id: string, request: ClaimTransferDto, params: RequestParams = {}) =>
      this.request<ResponseResponse, PutTransferError>({
        path: `/claim/${id}/transfer`,
        method: 'PUT',
        body: request,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Take claim to work
     *
     * @tags Support
     * @name PutWork
     * @summary Take claim to work
     * @request PUT:/claim/{id}/work
     */
    putWork: (id: string, params: RequestParams = {}) =>
      this.request<ResponseResponse, PutWorkError>({
        path: `/claim/${id}/work`,
        method: 'PUT',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  judge = {
    /**
     * @description Get judge list
     *
     * @tags Support
     * @name GetJudge
     * @summary Get judge list
     * @request GET:/judge
     */
    getJudge: (
      query?: {
        /** searchStr (user externalId or name) */
        searchStr?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<
        ResponseResponse & {
          data?: DtoJudge[]
        },
        GetJudgeError
      >({
        path: `/judge`,
        method: 'GET',
        query: query,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Remove judge
     *
     * @tags Support
     * @name DeleteJudge
     * @summary Remove judge
     * @request DELETE:/judge/:id
     */
    deleteJudge: (id: string, params: RequestParams = {}) =>
      this.request<
        ResponseResponse & {
          data?: DtoJudge[]
        },
        DeleteJudgeError
      >({
        path: `/judge/${id}`,
        method: 'DELETE',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Get judge claim list
     *
     * @tags Support
     * @name GetClaim
     * @summary Get judge claim list
     * @request GET:/judge/claim
     */
    getClaim: (
      query?: {
        /** searchStr (user externalId or claim externalId) */
        searchStr?: string
        /** status */
        status?: 'PREPARING' | 'NEW' | 'IN_PROGRESS' | 'FINISH' | 'APPROVED'
      },
      params: RequestParams = {}
    ) =>
      this.request<
        ResponseResponse & {
          data?: DtoJudgeClaim[]
        },
        GetClaimFail
      >({
        path: `/judge/claim`,
        method: 'GET',
        query: query,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description update claim by user
     *
     * @tags Support
     * @name PutClaim
     * @summary update claim by user
     * @request PUT:/judge/claim
     */
    putClaim: (request: DtoUpdateJudgeClaim, params: RequestParams = {}) =>
      this.request<ResponseResponse, PutClaimFail>({
        path: `/judge/claim`,
        method: 'PUT',
        body: request,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description create claim for judge
     *
     * @tags Support
     * @name PostClaim
     * @summary create claim for judge
     * @request POST:/judge/claim
     */
    postClaim: (params: RequestParams = {}) =>
      this.request<ResponseResponse, PostClaimFail>({
        path: `/judge/claim`,
        method: 'POST',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Get claim by id
     *
     * @tags Support
     * @name GetClaimId
     * @summary Get claim by id
     * @request GET:/judge/claim/:id
     */
    getClaimId: (id: string, params: RequestParams = {}) =>
      this.request<
        ResponseResponse & {
          data?: DtoJudgeClaim
        },
        GetClaimIdFail
      >({
        path: `/judge/claim/${id}`,
        method: 'GET',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Get current judge claim
     *
     * @tags Support
     * @name GetClaimCurrent
     * @summary Get current judge claim
     * @request GET:/judge/claim/current
     */
    getClaimCurrent: (params: RequestParams = {}) =>
      this.request<
        ResponseResponse & {
          data?: DtoJudgeClaim
        },
        GetClaimCurrentError
      >({
        path: `/judge/claim/current`,
        method: 'GET',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Approve judge claim
     *
     * @tags Support
     * @name PutClaimApprove
     * @summary Approve judge claim
     * @request PUT:/judge/claim/{id}/approve
     */
    putClaimApprove: (id: string, params: RequestParams = {}) =>
      this.request<ResponseResponse, PutClaimApproveError>({
        path: `/judge/claim/${id}/approve`,
        method: 'PUT',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description add file to claim
     *
     * @tags Support
     * @name PostClaimAttachment
     * @summary add file to claim
     * @request POST:/judge/claim/{id}/attachment
     */
    postClaimAttachment: (
      id: string,
      data: {
        /** attachment file */
        file: File
      },
      params: RequestParams = {}
    ) =>
      this.request<
        ResponseResponse & {
          data?: DtoAttachment
        },
        PostClaimAttachmentError
      >({
        path: `/judge/claim/${id}/attachment`,
        method: 'POST',
        body: data,
        type: ContentType.FormData,
        format: 'json',
        ...params,
      }),

    /**
     * @description delete file from judge claim
     *
     * @tags Support
     * @name DeleteClaimAttachment
     * @summary delete file from judge claim
     * @request DELETE:/judge/claim/{id}/attachment/{attachmentId}
     */
    deleteClaimAttachment: (id: string, attachmentId: string, params: RequestParams = {}) =>
      this.request<ResponseResponse, DeleteClaimAttachmentError>({
        path: `/judge/claim/${id}/attachment/${attachmentId}`,
        method: 'DELETE',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Decline judge claim
     *
     * @tags Support
     * @name PutClaimDecline
     * @summary Decline judge claim
     * @request PUT:/judge/claim/{id}/decline
     */
    putClaimDecline: (id: string, params: RequestParams = {}) =>
      this.request<ResponseResponse, PutClaimDeclineError>({
        path: `/judge/claim/${id}/decline`,
        method: 'PUT',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description View decline judge claim
     *
     * @tags Support
     * @name PutClaimView
     * @summary View decline judge claim
     * @request PUT:/judge/claim/{id}/view
     */
    putClaimView: (id: string, params: RequestParams = {}) =>
      this.request<ResponseResponse, PutClaimViewError>({
        path: `/judge/claim/${id}/view`,
        method: 'PUT',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Take claim to work
     *
     * @tags Support
     * @name PutClaimWork
     * @summary Take claim to work
     * @request PUT:/judge/claim/{id}/work
     */
    putClaimWork: (id: string, params: RequestParams = {}) =>
      this.request<ResponseResponse, PutClaimWorkError>({
        path: `/judge/claim/${id}/work`,
        method: 'PUT',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  user = {
    /**
     * @description get self info
     *
     * @tags Support
     * @name GetUser
     * @summary get self info
     * @request GET:/user
     */
    getUser: (params: RequestParams = {}) =>
      this.request<
        ResponseResponse & {
          data?: DtoUser
        },
        GetUserError
      >({
        path: `/user`,
        method: 'GET',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Update user
     *
     * @tags Support
     * @name PutUser
     * @summary Update user
     * @request PUT:/user
     */
    putUser: (request: UserUpdateDto, params: RequestParams = {}) =>
      this.request<ResponseResponse, PutUserError>({
        path: `/user`,
        method: 'PUT',
        body: request,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Save user
     *
     * @tags Support
     * @name PostUser
     * @summary Save user
     * @request POST:/user
     */
    postUser: (request: UserCreateDto, params: RequestParams = {}) =>
      this.request<ResponseResponse, PostUserError>({
        path: `/user`,
        method: 'POST',
        body: request,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Delete user
     *
     * @tags Support
     * @name DeleteUser
     * @summary Delete user
     * @request DELETE:/user
     */
    deleteUser: (request: UserDeleteDto, params: RequestParams = {}) =>
      this.request<ResponseResponse, DeleteUserError>({
        path: `/user`,
        method: 'DELETE',
        body: request,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Restore Password for another user by admin
     *
     * @tags Support
     * @name PostIdPasswordRestore
     * @summary Restore Password for another user by admin
     * @request POST:/user/:id/password/restore
     */
    postIdPasswordRestore: (id: string, params: RequestParams = {}) =>
      this.request<ResponseResponse, PostIdPasswordRestoreError>({
        path: `/user/${id}/password/restore`,
        method: 'POST',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description get default avatars
     *
     * @tags Support
     * @name GetAvatars
     * @summary get default avatars
     * @request GET:/user/avatars
     */
    getAvatars: (params: RequestParams = {}) =>
      this.request<
        ResponseResponse & {
          data?: DtoAvatar
        },
        GetAvatarsError
      >({
        path: `/user/avatars`,
        method: 'GET',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Change email
     *
     * @tags Support
     * @name PostEmailChange
     * @summary Change email
     * @request POST:/user/email/change
     */
    postEmailChange: (request: UserChangeEmail, params: RequestParams = {}) =>
      this.request<ResponseResponse, PostEmailChangeError>({
        path: `/user/email/change`,
        method: 'POST',
        body: request,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Change password
     *
     * @tags Support
     * @name PostPasswordChange
     * @summary Change password
     * @request POST:/user/password/change
     */
    postPasswordChange: (request: InternalControllerHttpV1UserChangePasswordRequest, params: RequestParams = {}) =>
      this.request<ResponseResponse, PostPasswordChangeError>({
        path: `/user/password/change`,
        method: 'POST',
        body: request,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Search users
     *
     * @tags Support
     * @name GetSearch
     * @summary Search users
     * @request GET:/user/search
     */
    getSearch: (
      query?: {
        /** Name for search */
        userSearchName?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<
        ResponseResponse & {
          data?: DtoUser
        },
        GetSearchError
      >({
        path: `/user/search`,
        method: 'GET',
        query: query,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
}
