import { EnumClaimReason } from 'api/schemas/supportApi'
import { Header6, Text5 } from 'styles/typography'
import Checkbox from 'components/Checkbox'
import styled from 'styled-components'

const StyledCheckboxWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
const StyledCheckboxTitle = styled(Header6)`
  font-weight: 600;
`
const StyledCheckboxDescription = styled(Text5)`
  opacity: 0.3;
`

interface ChooseCustomCheckbox {
  id: string
  text: string
  label: boolean
  value: EnumClaimReason
  inputLabel?: string
  description?: string
  type: 'checkbox' | 'checkbox-input'
  onChange: (
    name: string,
    value: EnumClaimReason,
    isActive: boolean,
    description?: string
  ) => void
}
const ChooseCustomCheckbox: React.FC<ChooseCustomCheckbox> = ({
  text,
  label,
  value,
  onChange,
  description,
}) => {
  const handleCheckboxChange = (v: boolean) => {
    onChange(text, value, v)
  }

  return (
    <Checkbox isActive={label} onChange={handleCheckboxChange}>
      <StyledCheckboxWrapper>
        <StyledCheckboxTitle>{text}</StyledCheckboxTitle>
        <StyledCheckboxDescription>{description}</StyledCheckboxDescription>
      </StyledCheckboxWrapper>
      {/* {type === 'checkbox-input' && value && (
        <>
          <StyledCheckboxInputLabel>{inputLabel}</StyledCheckboxInputLabel>
          <StyledCheckboxInput>
            <Input value={description} onChange={handleInputChange} />
          </StyledCheckboxInput>
        </>
      )} */}
    </Checkbox>
  )
}

export default ChooseCustomCheckbox
