const BlockIcon = ({ className }: { className?: string }) => (
  <svg
    width="16"
    fill="none"
    height="21"
    viewBox="0 0 16 21"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.2">
      <rect y="7" rx="1" width="16" height="11" fill="white" />
      <rect
        y="1"
        x="4"
        rx="4"
        width="8"
        height="13"
        stroke="white"
        stroke-width="2"
      />
    </g>
  </svg>
)

export default BlockIcon
