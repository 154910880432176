import * as Sentry from '@sentry/react'

const envIsProd = process.env.NODE_ENV === 'production'

enum Severity {
  Fatal = 'fatal',
  Error = 'error',
  Warning = 'warning',
  Log = 'log',
  Info = 'info',
  Debug = 'debug',
}

export function logError(msg: string, extra?: Record<string, unknown>): void {
  if (envIsProd) {
    Sentry.captureMessage(msg, {
      extra,
      level: Severity.Error,
    })
  } else {
    console.error(msg, extra)
  }
}

export function logWarn(msg: string, extra?: Record<string, unknown>): void {
  if (envIsProd) {
    Sentry.captureMessage(msg, {
      extra,
      level: Severity.Warning,
    })
  } else {
    console.warn(msg, extra)
  }
}

export function logException(err: unknown): void {
  if (envIsProd) {
    Sentry.captureException(err)
  } else {
    console.error(err)
  }
}

export function logInfo(msg: string, extra?: Record<string, unknown>): void {
  if (envIsProd) {
    Sentry.captureMessage(msg, {
      extra,
      level: Severity.Info,
    })
  } else {
    console.info(msg, extra)
  }
}
