import { SVGProps } from 'react'

const Close = ({ ...rest }: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <g clipPath="url(#clip0_2565_37656)">
      <path
        fill="white"
        clipRule="evenodd"
        fillRule="evenodd"
        d="M13.9896 11.7068L19.6063 6.09006L17.9099 4.39369L12.2932 10.0104L6.38326 4.10051L4.61113 5.87263L10.5211 11.7826L4.39371 17.9099L6.09007 19.6063L12.2174 13.4789L18.1274 19.3889L19.8995 17.6167L13.9896 11.7068Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_2565_37656">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default Close
