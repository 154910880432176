import { $match } from 'stores/lobbyStore'
import { Header5, Text4 } from 'styles/typography'
import { useStore } from '@nanostores/react'
import { useTranslation } from 'react-i18next'
import WinnerStarIcon from 'components/MatchResults/WinnerStarIcon'
import styled from 'styled-components'

const WinnerTeam = () => {
  const { t } = useTranslation()
  const match = useStore($match)

  if (!match) return null

  const winner = match?.CSMatchResult?.winTeamColor
  const isDraw = match.CSMatchResult?.isDraw
  const winnerText = isDraw ? 'team red team blue' : `team ${winner}`
  const description = isDraw
    ? t('play.statistic.draw')
    : t('play.statistic.win')

  return (
    <StyledWinnerWrapper>
      <WinnerStarIcon />
      <StyledWinner>
        <StyledWinnerText>{winnerText}</StyledWinnerText>
        <StyledMainInfoTitle>{description}</StyledMainInfoTitle>
      </StyledWinner>
    </StyledWinnerWrapper>
  )
}

const StyledMainInfoTitle = styled(Text4)`
  color: rgba(255, 255, 255, 0.3);
  text-align: center;
`

const StyledWinnerWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`

const StyledWinner = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

const StyledWinnerText = styled(Header5)`
  text-transform: uppercase;
  text-align: center;
`

export default WinnerTeam
