import { Id, toast } from 'react-toastify'
import { ModalTypes } from 'types/modals'
import { atom } from 'nanostores'
import { logger } from '@nanostores/logger'
import { persistentAtom } from '@nanostores/persistent'
import { setMatch } from 'stores/lobbyStore'
import { v4 as uuidv4 } from 'uuid'
import buttonClickMenuToggleSound from 'sounds/menu-open-close.wav'
import playSound from 'utils/sound'
import showExitPopupSound from 'sounds/exit-pop-up.wav'
import showReconnection from 'utils/showReconnection'

export type Game = 'cs' | 'dota'

const buttonClickMenuToggle = new Audio(buttonClickMenuToggleSound)
const showExitPopup = new Audio(showExitPopupSound)

export const $currenGame = persistentAtom<Game>('currenGame', 'cs')
export const $isSocketOpen = atom<boolean>(false)
export const $autoStartedMatches = persistentAtom<string[]>(
  'autoStartedMatches',
  [],
  {
    decode: JSON.parse,
    encode: JSON.stringify,
  }
)

export const $isRightBarOpen = persistentAtom<boolean>(
  'isRightBarOpen',
  false,
  {
    decode: JSON.parse,
    encode: JSON.stringify,
  }
)
export const $modal = atom<ModalTypes | null>(null)
export const $modalResultMatchId = atom<string | null>(null)
export const $isOffline = atom<boolean>(false)
export const $isAppLoading = atom<boolean>(false)

export const $isShowExitPopup = atom<boolean>(false)

export const $popups = atom<Record<string, Id>>({})

export const toggleRightBar = async (state?: boolean) => {
  $isRightBarOpen.set(state === undefined ? !$isRightBarOpen.get() : state)
  await playSound(buttonClickMenuToggle)
}

export const addAutoStartedMatch = (id: string) => {
  $autoStartedMatches.set([...$autoStartedMatches.get(), id])
}

export const setGame = (game: Game) => {
  $currenGame.set(game)
}

export const deletePopup = (id: string) => {
  const newPopup = $popups.get()
  delete newPopup[id]
  $popups.set(newPopup)
}

export const setIsOffline = (value: boolean) => {
  $isOffline.set(value)
}

export const addPopup = (id: string, toast: Id) => {
  const newPopup = $popups.get()
  newPopup[id] = toast
  $popups.set(newPopup)
}

export const setIsShowExitPopup = async (value: boolean) => {
  $isShowExitPopup.set(value)
  if (value) await playSound(showExitPopup)
}

export const setModalType = (modalType: ModalTypes | null) => {
  $modal.set(modalType)
}

export const openResultMatchModal = (resultMatchId: string) => {
  $modal.set('matchResults')
  $modalResultMatchId.set(resultMatchId)
}

export const closeResultMatchModal = () => {
  $modalResultMatchId.set(null)
  setMatch(null)
}

$modal.listen((store) => {
  if (!store && $modalResultMatchId.get() !== null) {
    closeResultMatchModal()
  }
})
const idOffline = uuidv4()
let connectionPopup: Id

$isOffline.listen((store) => {
  if (store) {
    connectionPopup = showReconnection({ id: idOffline })
  } else toast.dismiss(connectionPopup)
})

logger(
  {
    socket: $modal,
    $isOffline: $isOffline,
    CurrenGame: $currenGame,
    IsSocketOpen: $isSocketOpen,
  },
  {
    messages: {
      mount: false,
      unmount: false,
    },
  }
)
