import {
  $lastMessageForFastShow,
  setTabActive,
} from 'stores/chatStores/chatStore'
import { $lobby } from 'stores/lobbyStore'
import { $party } from 'stores/partyStore'
import { $userProfile } from 'stores/userStore'
import { DtoChat, DtoMessage } from 'api/schemas/chatApi'
import { atom, computed } from 'nanostores'
import { logger } from '@nanostores/logger'
import api from 'api/api'
import scrollChatToBottom from 'utils/scrollChatToBottom'

export interface GropHostInfo {
  name: string
  imgUrl: string
  hostId: string
}

// STORE

export const $groupsChats = atom<DtoChat[]>([])
export const $activeGroupsChatID = atom<string>('')
export const $isGroupUserListOpen = atom<boolean>(false)

// COMPUTEDS

export const $groupChatsID = computed([$lobby, $party], (lobby, party) => {
  return {
    lobbyId: lobby?.id,
    partyId: party?.id,
  }
})
export const $groupsChatsHaveUnreadMessage = computed(
  [$groupsChats],
  (chats) => {
    return (
      chats.find((item) => item.haveUnreadMessage === true && item.messages) !==
      undefined
    )
  }
)
export const $activeGroupChatType = computed(
  [$activeGroupsChatID, $groupsChats],
  (activeChatID, groupsChats) => {
    const currentGroupChat = groupsChats.find(
      (item) => item.id === activeChatID
    )
    if (
      currentGroupChat?.lobbyId === $groupChatsID.get().lobbyId &&
      $groupChatsID.get().lobbyId
    )
      return 'lobby'
    if (
      currentGroupChat?.partyId === $groupChatsID.get().partyId &&
      $groupChatsID.get().partyId
    )
      return 'party'
  }
)
export const $activeGroupChatData = computed([$groupsChats], (chats) => {
  return chats.find((item) => item.id === $activeGroupsChatID.get()) || {}
})
export const $activeGroupChatMessages = computed(
  [$activeGroupChatData],
  (data) => data?.messages || []
)

export const $groupChatsInfo = computed(
  [$party, $lobby, $groupsChats],
  (party, lobby, groupsChats) => {
    const partyHostId = party?.hostId
    const partyId = party?.id
    const lobbyHostId = lobby?.hostId
    const lobbyId = lobby?.id

    const partyParticipant = party?.participants?.find(
      (participant) => participant?.user?.id === partyHostId
    )

    const lobbyParticipant = lobby?.participants?.find(
      (participant) => participant?.user?.id === lobbyHostId
    )

    const partyChatId = groupsChats.find((item) => item.partyId === partyId)?.id
    const lobbyChatId = groupsChats.find((item) => item.lobbyId === lobbyId)?.id

    const hostInfo = {
      party: partyHostId
        ? {
            id: partyId,
            chatId: partyChatId,
            hostId: partyHostId,
            name: partyParticipant?.user?.name,
            imgUrl: partyParticipant?.user?.imgUrl,
            invitedCount: $groupChatInvitedCount.get().partyInvitedCount,
            participantCount:
              $groupChatParticipantCount.get().partyParticipantCount,
            onlineCount:
              $groupChatParticipantOnlineCount.get()
                .partyParticipantOnlineCount,
            offlineCount:
              $groupChatParticipantOfflineCount.get()
                .partyParticipantOfflineCount,
          }
        : undefined,
      lobby: lobbyHostId
        ? {
            id: lobbyId,
            chatId: lobbyChatId,
            hostId: lobbyHostId,
            isRaiting: lobby.isRating,
            name: lobbyParticipant?.user?.name,
            imgUrl: lobbyParticipant?.user?.imgUrl,
            invitedCount: $groupChatInvitedCount.get().lobbyInvitedCount,
            participantCount:
              $groupChatParticipantCount.get().lobbyParticipantCount || 0,
            onlineCount:
              $groupChatParticipantOnlineCount.get()
                .lobbyParticipantOnlineCount,
            offlineCount:
              $groupChatParticipantOfflineCount.get()
                .lobbyParticipantOfflineCount,
          }
        : undefined,
    }
    return hostInfo
  }
)

export const $groupChatInvitedCount = computed(
  [$party, $lobby],
  (party, lobby) => {
    return {
      partyInvitedCount: party?.invitedParticipants?.length,
      lobbyInvitedCount: lobby?.invitedParticipants?.length,
    }
  }
)
export const $groupChatParticipantCount = computed(
  [$party, $lobby],
  (party, lobby) => {
    return {
      partyParticipantCount: party?.participants?.length,
      lobbyParticipantCount: lobby?.participants?.length,
    }
  }
)
export const $groupChatParticipantOnlineCount = computed(
  [$party, $lobby],
  (party, lobby) => {
    return {
      partyParticipantOnlineCount: party?.participants?.filter(
        (item) => item.user?.online
      )?.length,
      lobbyParticipantOnlineCount: lobby?.participants?.filter(
        (item) => item.user?.online
      )?.length,
    }
  }
)
export const $groupChatParticipantOfflineCount = computed(
  [$groupChatParticipantCount, $groupChatParticipantOnlineCount],
  (all, online) => {
    return {
      partyParticipantOfflineCount:
        all.partyParticipantCount && online.partyParticipantOnlineCount
          ? all.partyParticipantCount - online.partyParticipantOnlineCount
          : 0,
      lobbyParticipantOfflineCount:
        all.lobbyParticipantCount && online.lobbyParticipantOnlineCount
          ? all.lobbyParticipantCount - online.lobbyParticipantOnlineCount
          : 0,
    }
  }
)

// ACTIONS

//export const updateGroupChatMessagesFromSocket
export const updateGroupChatMessagesFromSocket = async (data: DtoChat) => {
  console.log('updateGroupChatMessagesFromSocket')

  const updatedChat = $groupsChats.get().find((chat) => chat?.id === data?.id)
  const updatedChatOpen = data.id === $activeGroupsChatID.get()

  if (!$activeGroupsChatID.get() && data.id) $activeGroupsChatID.set(data.id)

  const lastMessage = data?.messages?.[data?.messages?.length - 1]

  if (lastMessage) {
    $lastMessageForFastShow.set({
      ...lastMessage,
      tab: 'party',
    })
  }

  if (updatedChat) {
    const messages = updatedChat.messages
      ? [...(data.messages as DtoMessage[]), ...updatedChat.messages]
      : [...(data.messages as DtoMessage[])]
    const result = $groupsChats.get().map((chat) => {
      if (chat.id === data.id) {
        return {
          ...chat,
          messages,
          haveUnreadMessage: !updatedChatOpen,
        } as DtoChat
      }
      return chat
    })
    $groupsChats.set(result)
  } else {
    return $groupsChats.set([
      { ...data, haveUnreadMessage: !updatedChatOpen },
      ...$groupsChats.get(),
    ])
  }
  if (updatedChatOpen && data.id) {
    await readMessagesGroupChat(data.id)
    const isUserMessage = data.messages?.find(
      (message) => message.authorId === $userProfile.get()?.id
    )
    if (isUserMessage) scrollChatToBottom()
  }
}

export const getGroupsChats = async () => {
  const { data } = await api.chats.getGroups()
  const chatsData = data as DtoChat[] | null
  $activeGroupsChatID.set(chatsData?.[0]?.id || '')
  return $groupsChats.set(data)
}

export const getGroupsChatMessages = async (fromCreatedAt?: string) => {
  console.log('getGroupsChatMessages')
  const id = $activeGroupsChatID.get()
  const { data } = (await api.chats.getChats(id, {
    fromCreatedAt,
  })) as { data: DtoChat }

  const result = $groupsChats.get().map((chat) => {
    /* подгужаем сообщения которые были раньше */
    const messages: DtoMessage[] | undefined = fromCreatedAt
      ? chat.messages?.length
        ? [...chat.messages.reverse(), ...(data.messages as DtoMessage[])]
        : data.messages
      : data.messages
    return chat.id === id ? { ...chat, messages: messages || [] } : chat
  })
  $groupsChats.set(result)
  await readMessagesGroupChat(id)
}

export const readMessagesGroupChat = async (chatID: string) => {
  console.log('readMessagesGroupChat')
  await api.chats.postRead(chatID)
  const result = $groupsChats
    .get()
    .map((chat) =>
      chat.id === chatID ? { ...chat, haveUnreadMessage: false } : chat
    )
  return $groupsChats.set(result)
}

// SUBSCRIBE
$lobby.listen((lobby) => {
  const groupsChats = $groupsChats.get()
  if (!lobby) {
    $groupsChats.set(groupsChats.filter((chat) => !chat.lobbyId))
  }
})

$party.listen((party) => {
  const groupsChats = $groupsChats.get()

  if (!party) {
    $groupsChats.set(groupsChats.filter((chat) => !chat.partyId))
  }
})

$groupsChats.listen((chat) => {
  if (!chat.length) {
    $activeGroupsChatID.set('')
    setTabActive('chat')
  }
  if (chat.length === 1) $activeGroupsChatID.set(chat?.[0]?.id || '')
})

logger(
  {
    groupsChats: $groupsChats,
    groupChatsID: $groupChatsID,
    groupChatsInfo: $groupChatsInfo,
    activeGroupsChatID: $activeGroupsChatID,
    activeGroupChatType: $activeGroupChatType,
    activeGroupChatData: $activeGroupChatData,
    isGroupUserListOpen: $isGroupUserListOpen,
    groupChatInvitedCount: $groupChatInvitedCount,
    activeGroupChatMessages: $activeGroupChatMessages,
    groupChatParticipantCount: $groupChatParticipantCount,
    groupsChatsHaveUnreadMessage: $groupsChatsHaveUnreadMessage,
    groupChatParticipantOnlineCount: $groupChatParticipantOnlineCount,
    groupChatParticipantOfflineCount: $groupChatParticipantOfflineCount,
  },
  {
    messages: {
      mount: false,
      unmount: false,
    },
  }
)
