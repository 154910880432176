import { $autoStartedMatches } from 'stores/appStore'
import { $balance } from 'stores/walletStore'
import { $lobby, $match, $timer } from 'stores/lobbyStore'
import { NavLink, useNavigate } from 'react-router-dom'
import { isProd } from 'api/api'
import { useEffect } from 'react'
import { useStore } from '@nanostores/react'
import { useTranslation } from 'react-i18next'
import Coin from 'images/Coin'
import Logo from 'images/Logo'
import MenuPoint from 'images/MenuPoint'
import buttonClickSound from 'sounds/simple-buttons.mp3'
import gameIsOnBanner from 'images/gameIsOnBanner.webp'
import preparing from 'images/animations/preparing/preparing.webp'
import preparingFinished from 'images/animations/preparing/preparing_finished.webp'
import routes from 'router/routes'
import styled from 'styled-components'
import useSound from 'hooks/useSound'

const Header: React.FC = () => {
  const balance = useStore($balance)
  const match = useStore($match)
  const lobby = useStore($lobby)
  const autoStartedMatches = useStore($autoStartedMatches)
  const timer = useStore($timer)
  const { t } = useTranslation()

  const navigate = useNavigate()

  const [play] = useSound(buttonClickSound)

  const onClickWithSound = () => {
    play()
  }

  useEffect(() => {
    if (
      lobby?.status === 'MATCH_PREPARED' ||
      lobby?.status === 'READY_TO_MATCH'
    ) {
      navigate(routes.play())
    }
  }, [lobby?.status, navigate])

  return (
    <>
      {(lobby?.status === 'MATCH_PREPARED' ||
        lobby?.status === 'READY_TO_MATCH') &&
        (timer || !autoStartedMatches.includes(match?.id || '')) && (
          <>
            <StyledBunner src={preparing} />
            <StyledAnimatedBunner
              src={preparingFinished}
              state={Math.abs((timer - 20) * 5)}
            />
          </>
        )}
      {lobby?.status === 'MATCH_PREPARED' &&
        autoStartedMatches.includes(match?.id || '') &&
        !timer && <StyledBunner src={gameIsOnBanner} />}

      {lobby?.status !== 'MATCH_PREPARED' &&
        lobby?.status !== 'READY_TO_MATCH' && (
          <StyledWrapper>
            <StyledLogo to={routes.chooseGame()}>
              <Logo />
              {t('mainMenu.title')}
            </StyledLogo>
            <StyledMenu>
              <StyledMenuItem to={routes.app()} onClick={onClickWithSound}>
                <AnimatedText text={t('mainMenu.tabs.home')} />
              </StyledMenuItem>
              <StyledMenuPoint>
                <MenuPoint />
              </StyledMenuPoint>
              <StyledMenuItem to={routes.play()} onClick={onClickWithSound}>
                <AnimatedText text={t('mainMenu.tabs.play')} />
              </StyledMenuItem>
              <StyledMenuPoint>
                <MenuPoint />
              </StyledMenuPoint>
              <StyledMenuItem
                to={routes.statistic()}
                onClick={onClickWithSound}
              >
                <AnimatedText text={t('mainMenu.tabs.statistic')} />
              </StyledMenuItem>
              <StyledMenuPoint>
                <MenuPoint />
              </StyledMenuPoint>
              {!isProd ? (
                <StyledMenuItem
                  to={routes.spectating()}
                  onClick={onClickWithSound}
                >
                  <AnimatedText text={t('mainMenu.tabs.spectating')} />
                </StyledMenuItem>
              ) : null}
            </StyledMenu>
            <StyledBalance>
              <StyledCoin />
              {balance}
            </StyledBalance>
          </StyledWrapper>
        )}
    </>
  )
}

const StyledWrapper = styled.div`
  min-height: 80px;
  height: 80px;
  width: 1646px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 10;
`

const StyledMenuItem = styled(NavLink)<{ onClick?: () => void }>`
  position: relative;
  color: white;
  filter: none;
  text-transform: uppercase;
  &:hover {
    color: #2cff80;
    filter: drop-shadow(0px 1px 6px rgba(44, 255, 128, 0.4));
  }
  &.active {
    color: #2cff80;
    filter: drop-shadow(0px 1px 6px rgba(44, 255, 128, 0.4));
  }

  transition: 0.2s;
`

const StyledLogo = styled(NavLink)`
  font-family: Ruberoid;
  display: flex;
  align-items: center;
  gap: 16px;
`

const StyledBalance = styled.div`
  position: relative;

  width: 60px;
  justify-content: right;
  display: flex;

  align-items: center;
  gap: 8px;
  cursor: pointer;
  z-index: 9;
`

const StyledMenu = styled.div`
  display: flex;
`

const StyledMenuPoint = styled.div`
  display: flex;
  align-items: center;
  padding: 0 16px;
`

const StyledCoin = styled(Coin)`
  fill: #ffcd29;
  min-width: 17px;
`

const StyledBunner = styled.img`
  position: absolute;
  z-index: 1;
  top: -90px;
  width: 1653px;
`

interface StyledAnimatedBunnerProps {
  state: number
}

const StyledAnimatedBunner = styled(StyledBunner)<StyledAnimatedBunnerProps>`
  clip-path: inset(${({ state }) => 67 - (state * 35) / 100}% 0 0);
  transition: 1s;
`

const Bracket = styled.span`
  position: absolute;
  opacity: 0;
  transition: all 0.3s ease;
  filter: drop-shadow(0px 1px 6px rgba(44, 255, 128, 0.4));

  &.left-bracket {
    left: 50%;
    transform: translateX(-50%);
  }

  &.right-bracket {
    right: 50%;
    transform: translateX(50%);
  }

  ${StyledMenuItem}:hover &.left-bracket, ${StyledMenuItem}.active &.left-bracket {
    left: 0;
    opacity: 1;
    transform: translateX(-8px);
  }

  ${StyledMenuItem}:hover &.right-bracket, ${StyledMenuItem}.active &.right-bracket {
    right: 0;
    opacity: 1;
    transform: translateX(8px);
  }
`
interface AnimatedTextProps {
  text: string
}

const AnimatedText = ({ text }: AnimatedTextProps) => (
  <>
    <Bracket className="left-bracket">[</Bracket>
    {text}
    <Bracket className="right-bracket">]</Bracket>
  </>
)

export default Header
