import { $isAppealEstimated } from 'stores/chatStores/appealStore'
import { Text4 } from 'styles/typography'
import { useStore } from '@nanostores/react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const StyledHeadWrapper = styled.div`
  display: flex;
  align-items: center;
`

const StyledText = styled.div`
  display: flex;
  gap: 4px;
  margin: 10px 10px 10px 0;
`
const StyledTextCaption = styled(Text4)`
  color: #2cff80;
`
const StyledTextDescription = styled(Text4)`
  color: white;
  gap: 4px;
  display: flex;
`
const StyledStatus = styled.div`
  color: var(--Yellow, #ffcd29);
`

interface ISupportMessageHead {
  status?: string
  id?: string
}
const SupportMessageHead: React.FC<ISupportMessageHead> = ({ id, status }) => {
  const { t } = useTranslation()
  const isAppealEstimated = useStore($isAppealEstimated)
  // const getFormattedDate = () => {
  //   if (!createdAt) return ''
  //   return (
  //     new Date(createdAt).toLocaleDateString('ru-RU', {
  //       day: '2-digit',
  //       year: 'numeric',
  //       month: '2-digit',
  //     }) +
  //     ' ' +
  //     new Date(createdAt).toLocaleTimeString('ru-RU', {
  //       hour12: false,
  //       hour: '2-digit',
  //       minute: '2-digit',
  //     })
  //   )
  // }

  const getDescription = () => {
    switch (status) {
      case 'NEW':
        return ` ${t('chat.appeal.generated')}`
      case 'IN_PROGRESS':
        return ` ${t('chat.appeal.inProgress')}`
      case 'FINISH':
        return ` ${t('chat.appeal.onYourAppeal')}`
      case 'APPROVE_FAIL':
        return ` ${t('chat.appeal.theDecision')}`
      case 'APPROVE_SUCCESS':
        return !isAppealEstimated ? ` ${t('chat.appeal.solved')}` : ''
      case 'APPROVE_CANCELED':
        return ` ${t('chat.appeal.closed')}`
      case 'ESTIMATE':
      default:
        return ''
    }
  }

  return (
    <StyledHeadWrapper>
      <StyledText>
        <StyledTextCaption>
          {t('chat.appeal.youAppeal')} №{id}.
        </StyledTextCaption>
        <StyledTextDescription>
          {t('chat.appeal.status')}
          {' - '}
          <StyledStatus>{getDescription()}</StyledStatus>
        </StyledTextDescription>
      </StyledText>
    </StyledHeadWrapper>
  )
}

export default SupportMessageHead
