const PaperClip = () => (
  <svg
    width="17"
    height="15"
    viewBox="0 0 17 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.0493 0.277607C11.7936 0.371085 11.4382 0.548197 11.2595 0.671025C10.84 0.959464 4.13097 8.48381 3.89816 8.92701C3.79751 9.11866 3.70719 9.50149 3.68825 9.81717C3.65997 10.2886 3.69355 10.4461 3.91369 10.8755C4.29617 11.6216 4.76079 11.9238 5.58428 11.9624C6.62468 12.0111 6.65206 11.9886 9.93739 8.39687C12.6914 5.38588 12.8358 5.21061 12.8104 4.90975C12.7763 4.50565 12.4287 4.21344 12.0868 4.3014C11.9344 4.3406 11.0077 5.28476 9.44484 6.99304C5.79096 10.9869 6.08302 10.6931 5.76788 10.6931C5.23242 10.6931 4.82858 10.1442 5.00814 9.6602C5.10098 9.40995 11.5597 2.10006 11.9543 1.79874C12.8577 1.10879 14.2743 1.44774 14.8768 2.49808C15.2119 3.08223 15.2191 3.94267 14.8936 4.49636C14.6969 4.8308 8.58894 11.6567 7.55941 12.6926C7.08733 13.1676 6.48929 13.4877 5.80281 13.6331C4.37625 13.9351 2.84474 13.1308 2.17326 11.727C1.95033 11.2609 1.9207 11.0966 1.92034 10.3231C1.91971 8.92425 1.95481 8.87134 5.60645 4.75867C7.31385 2.83574 8.74292 1.17982 8.78216 1.07889C8.87392 0.842892 8.72685 0.410372 8.5155 0.294444C8.04639 0.0371951 8.03992 0.0431757 4.66787 3.85076C1.34501 7.60286 1.00788 8.05111 0.742928 9.07081C0.583384 9.68477 0.586167 10.8839 0.748584 11.5091C1.18188 13.1773 2.45939 14.4504 4.14434 14.8931C4.83091 15.0736 6.00258 15.0198 6.72335 14.7748C7.72335 14.4349 8.12953 14.0495 12.0434 9.727C16.414 4.89999 16.375 4.95602 16.375 3.51659C16.375 2.71209 16.3506 2.58788 16.0832 2.03124C15.7356 1.30771 15.1694 0.741594 14.4604 0.408624C13.7794 0.0888104 12.7224 0.0313986 12.0493 0.277607Z"
      fill="#FFCD29"
    />
  </svg>
)

export default PaperClip
