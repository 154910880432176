import { $isShowExitPopup } from 'stores/appStore'
import { $userProfile } from 'stores/userStore'
import {
  $userStatisticPageInfo,
  $userStatisticPageStat,
} from 'stores/statisticStore'
import { toast } from 'react-toastify'
import api from 'api/api'
import formatNumber from 'utils/formatNumber'

// export const $token = persistentAtom<string>('token', '')
// export const $refreshToken = persistentAtom<string>('refreshToken', '')

// export const setTokens = async ({
//   token,
//   refreshToken,
// }: {
//   token: string
//   refreshToken: string
// }) => {
//   await window.ipcRenderer.invoke('setTokens', {
//     token,
//     refreshToken,
//   })
//   await $token.set(token)
//   await $refreshToken.set(refreshToken)
// }

export const login = async (
  number: string,
  password: string,
  verificationCode?: string
) => {
  await api.signIn.postSignIn({
    password,
    login: formatNumber(number),
    totpCode: verificationCode ? verificationCode : undefined,
  })
}

export const logout = async () => {
  await api.logout.deleteLogout()
  toast.dismiss()
  $isShowExitPopup.set(false)
  $userProfile.set(null)
  $userStatisticPageStat.set(null)
  $userStatisticPageInfo.set(null)
}
