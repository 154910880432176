import { $userProfile } from 'stores/userStore'
import { DtoAttachmentCreate, DtoMessage } from 'api/schemas/chatApi'
import { atom } from 'nanostores'
import { logger } from '@nanostores/logger'
import api from 'api/api'

export type TabActive = 'chat' | 'party' | 'invite' | 'support'

// STORE
export const $chatOpen = atom<boolean>(false)
export const $tabActive = atom<TabActive>('chat')
export const $lastMessageForFastShow = atom<
  (DtoMessage & { tab: TabActive }) | null
>(null)

// SETTERS
export const setChatOpen = (isOpen: boolean) => {
  $chatOpen.set(isOpen)
  $lastMessageForFastShow.set(null)
}
export const setTabActive = (activeTab: TabActive, openChat?: boolean) => {
  !$chatOpen.get() && openChat && $chatOpen.set(true)

  $tabActive.set(activeTab)
}

// ACTIONS
// @description отправить сообщение в чат
export const sendMessage = async (
  chatID: string,
  message: string,
  attachments?: DtoAttachmentCreate[]
) => {
  await api.chats.postMessage(chatID, {
    attachments,
    text: message,
  })
}

$lastMessageForFastShow.listen((lastMessage) => {
  if (lastMessage?.author?.id === $userProfile.get()?.id) {
    $lastMessageForFastShow.set(null)
  }
})

logger(
  {
    chatOpen: $chatOpen,
    tabActive: $tabActive,
  },
  {
    messages: {
      mount: false,
      unmount: false,
    },
  }
)
