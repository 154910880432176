const Party = () => (
  <svg
    width="22"
    fill="none"
    height="17"
    viewBox="0 0 22 17"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1671_52934)">
      <path
        fill="white"
        clipRule="evenodd"
        fillRule="evenodd"
        d="M10.979 6.19364C12.496 6.19364 13.7258 4.96385 13.7258 3.44681C13.7258 1.92978 12.496 0.699982 10.979 0.699982C9.46197 0.699982 8.23217 1.92978 8.23217 3.44681C8.23217 4.96385 9.46197 6.19364 10.979 6.19364ZM14.6476 8.98492C15.6206 9.95794 16.1673 11.2776 16.1673 12.6537H10.9788L5.79037 12.6537C5.79037 11.2776 6.33701 9.95794 7.31004 8.98492C8.28306 8.01189 9.60277 7.46525 10.9788 7.46525C12.3549 7.46525 13.6746 8.01189 14.6476 8.98492ZM19.7934 5.45748C19.7934 6.61757 18.8529 7.558 17.6928 7.558C16.5328 7.558 15.5923 6.61757 15.5923 5.45748C15.5923 4.2974 16.5328 3.35696 17.6928 3.35696C18.8529 3.35696 19.7934 4.2974 19.7934 5.45748ZM21.66 12.6537C21.66 11.6014 21.242 10.5922 20.4979 9.84814C19.7539 9.10407 18.7447 8.68605 17.6924 8.68605C17.1838 8.68605 16.6853 8.78369 16.2219 8.9686C16.9752 10.0405 17.3872 11.3259 17.3872 12.6537H17.6924H21.66ZM4.46524 7.55803C3.30516 7.55803 2.36472 6.61759 2.36472 5.45751C2.36472 4.29742 3.30516 3.35699 4.46524 3.35699C5.62533 3.35699 6.56576 4.29742 6.56576 5.45751C6.56576 6.61759 5.62533 7.55803 4.46524 7.55803ZM1.66014 9.84817C0.916065 10.5922 0.498047 11.6014 0.498047 12.6537H4.46569H4.77124C4.77125 11.3259 5.18326 10.0406 5.93648 8.96875C5.47301 8.78376 4.97439 8.68607 4.46569 8.68607C3.41341 8.68607 2.40422 9.10409 1.66014 9.84817Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_1671_52934">
        <rect
          width="21"
          fill="white"
          height="16.8"
          transform="translate(0.5)"
        />
      </clipPath>
    </defs>
  </svg>
)

export default Party
