import {
  $confirmCount,
  $isConfirmPopupOpen,
  $lobby,
  $timeToConfirmEnd,
} from 'stores/lobbyStore'
import { Outlet } from 'react-router-dom'
import { useCallback, useEffect, useState } from 'react'
import { useStore } from '@nanostores/react'
import GameFindedModal from 'components/Modals/GameFindedModal'
import api from 'api/api'
import styled from 'styled-components'

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: end;
  height: 100%;
  margin: 0 auto;
`

const PlayCSLayout: React.FC = () => {
  const [timer, setTimer] = useState<number>(10)
  const [confirmed, setConfirmed] = useState<boolean>(false)

  const lobby = useStore($lobby)
  const isConfirmPopupOpen = useStore($isConfirmPopupOpen)
  const timeToConfirmEnd = useStore($timeToConfirmEnd)
  const confirmCount = useStore($confirmCount)

  const tick = useCallback((count: number) => {
    if (count > 0) {
      setTimer(count - 1)
      setTimeout(() => tick(count - 1), 1000)
    } else $isConfirmPopupOpen.set(false)
  }, [])

  const handleConfirmSearch = async () => {
    if (timer) {
      await api.search.postConfirm()
      setConfirmed(true)
    }
  }

  useEffect(() => {
    if (isConfirmPopupOpen && timeToConfirmEnd) {
      if (!timeToConfirmEnd) return
      setConfirmed(false)
      setTimer(timeToConfirmEnd)
      tick(timeToConfirmEnd)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConfirmPopupOpen])

  useEffect(() => {
    if (lobby?.status !== 'SEARCHING') {
      $confirmCount.set(null)
      $isConfirmPopupOpen.set(false)
    }
  }, [lobby?.status])

  return (
    <>
      <StyledWrapper>
        <Outlet />
        <GameFindedModal
          timer={timer}
          isConfirmed={confirmed}
          isOpen={isConfirmPopupOpen}
          caption={'Ваша игра найдена'}
          onAccept={handleConfirmSearch}
          confirmCount={confirmCount || 1}
          timeToConfirm={timeToConfirmEnd || 0}
          isFiveToFive={lobby?.gameFormat === 'FIVE_TO_FIVE'}
          acceptTitle={!timer || confirmed ? 'Ожидание игроков' : `Принять`}
          description={
            'Нажмите кнопку ниже, чтобы начать игру, после истечения таймера вы будете удалены из лобби'
          }
        />
      </StyledWrapper>
    </>
  )
}

export default PlayCSLayout
