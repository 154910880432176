import { CSSProperties } from 'react'
import { Tooltip } from 'react-tooltip'
import styled from 'styled-components'

import { Text4 } from 'styles/typography'

export const INFO_TOOLTIP_ID = 'INFO_TOOLTIP_ID'

const InfoTooltipContainer = () => (
  <Tooltip
    id={INFO_TOOLTIP_ID}
    style={styles}
    render={({ content }) => <StyledText>{content}</StyledText>}
  />
)

const styles: CSSProperties = {
  zIndex: 100,
  maxWidth: 400,
  padding: '5px 12px',
  borderRadius: '6px',
  background: '#ffcd29',
}

const StyledText = styled(Text4)`
  color: black;
  font-weight: 500;
  line-height: 125%;
`

export default InfoTooltipContainer
