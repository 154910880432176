import { $friendsStatistic } from 'stores/friendsStore'
import { DtoFriend } from 'api/schemas/friendsApi'
import { GameChoosen } from 'stores/statisticStore'
import { Header2, Header3, Header5, Header6 } from 'styles/typography'
import { useCallback, useState } from 'react'
import { useStore } from '@nanostores/react'
import { useTranslation } from 'react-i18next'
import CSLogo from 'images/personalAccount/CSLogo'
import CopyWrapper from 'components/CopyWrapper'
import DotaLogo from 'images/personalAccount/DotaLogo'
import Input from 'components/Input'
import NickTrimmer from 'components/NickTrimmer'
import Search from 'images/Search'
import WithUserMenu from 'components/ContextMenu/WithUserMenu'
import avatar from 'images/Avatar_test.png'
import dayjs from 'dayjs'
import styled from 'styled-components'

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 64px;
`
const StyledCaption = styled(Header2)`
  line-height: 90%;
  text-transform: uppercase;
`
const StyledInput = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  width: 100%;
  padding: 3px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  margin-top: 32px;
`
const StyledLegend = styled.div`
  display: grid;
  grid-template-columns: 1fr 230px 260px 220px 250px 155px;
  margin: 24px 0;
`
const StyledLegenditem = styled(Header6)`
  color: white;
  text-transform: uppercase;
`

const StyledLegenditemCentred = styled(StyledLegenditem)`
  text-align: center;
`

const StyledUsers = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7px;
`
const StyledUser = styled.div`
  display: grid;
  grid-template-columns: 1fr 230px 260px 220px 250px 155px;
  border: 1px solid rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(7.5px);
  background-color: rgba(255, 255, 255, 0.03);
  height: 65px;
`
const StyledUserInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  padding-left: 16px;
`
interface UserImage {
  $imgUrl?: string
}
const StyledUserImage = styled.div<UserImage>`
  width: 50px;
  height: 50px;
  border-radius: 1px;
  background-image: url(${({ $imgUrl }) => `${$imgUrl ? $imgUrl : avatar}`});
  background-size: cover;
  cursor: pointer;
`
const StyledUserInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`
const StyledUserInfoName = styled(Header5)`
  font-weight: 400;
  line-height: 100%;
  color: white;
  cursor: pointer;
`
const StyledUserInfoId = styled.div`
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 115.5%;
  color: white;
`
const StyledId = styled.div`
  display: inline-block;
  color: #ffcd29;
`
interface StyledValue {
  $color?: 'red' | 'green'
}

const getColor = (color?: 'red' | 'green') => {
  switch (color) {
    case 'green':
      return '#2CFF80'
    case 'red':
      return '#F44'
    default:
      return 'white'
  }
}
const StyledUserPlayValue = styled(Header3)<StyledValue>`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ $color }) => `${getColor($color)}`};
  font-weight: 400;
`

const StyledUserPlayDate = styled(Header5)`
  color: rgba(255, 255, 255, 0.3);

  display: flex;
  justify-content: center;

  align-items: center;

  gap: 24px;
`

const PersonalFriendsList: React.FC = () => {
  const { t } = useTranslation()
  const friendsStatistic = useStore($friendsStatistic)
  const [searchValue, setSearchValue] = useState<string | null>(null)

  const getTotalMatches = useCallback(
    (user: DtoFriend, gameType: GameChoosen) => {
      return (
        user.gameStatistics
          ?.filter((game) => game.gameName === gameType)
          .reduce((sum, current) => {
            return (
              sum +
              (current.matchDrawCount || 0) +
              (current.matchLoseCount || 0) +
              (current.matchWinCount || 0)
            )
          }, 0) || 0
      )
    },
    []
  )

  const getWinRatio = useCallback(
    (user: DtoFriend, gameType: GameChoosen) => {
      const total = getTotalMatches(user, gameType)
      return (
        Math.round(
          ((user.gameStatistics
            ?.filter((game) => game.gameName === gameType)
            .reduce((sum, current) => {
              return sum + (current.matchWinCount || 0)
            }, 0) || 0) /
            total) *
            100
        ) || 0
      )
    },
    [getTotalMatches]
  )

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target

    setSearchValue(value)
  }

  const getLastTimeAndGame = (
    user: DtoFriend
  ): { date: string; game: string } => {
    return (
      user.gameStatistics?.reduce(
        (acc, current) => {
          if (!acc.date && current.lastMatchTime) {
            acc.date = current.lastMatchTime
            acc.game = current.gameName || ''
            return acc
          }

          if (
            current.lastMatchTime &&
            new Date(current.lastMatchTime || '').valueOf() >
              new Date(acc.date || '').valueOf()
          ) {
            acc.date = current.lastMatchTime
            acc.game = current.gameName || ''
          }

          return acc
        },
        { date: '', game: '' } as { date: string; game: string }
      ) || { date: '', game: '' }
    )
  }

  const getGameIcon = (game: string) => {
    switch (game) {
      case 'CS':
        return <CSLogo />
      case 'DOTA2':
        return <DotaLogo />
      default:
        return <></>
    }
  }

  const users = friendsStatistic
    ?.filter((user) => {
      const value = searchValue?.toLowerCase() || ''
      const userID = user.externalId?.toLowerCase()
      const userName = user.name?.toLowerCase()
      if (!value || value.length < 3 || value === userID) return true
      return userName?.includes(value) || userID?.includes(value)
    })
    .map((user) => {
      const profile = {
        isChosen: false,
        id: user?.id || '',
        name: user?.name || '',
        imgUrl: user?.imgUrl || '',
        online: user?.online || false,
        externalId: user?.externalId || '',
        lastGame: getLastTimeAndGame(user).game,
        lastMatchTime: getLastTimeAndGame(user).date,
      }

      const CSWR = getWinRatio(user, 'CS')
      const DOTAWR = getWinRatio(user, 'DOTA2')

      return (
        <StyledUser key={profile.id}>
          <StyledUserInfo>
            <WithUserMenu profile={{ ...profile }}>
              <StyledUserImage $imgUrl={user.imgUrl} />
            </WithUserMenu>
            <StyledUserInfoWrapper>
              <WithUserMenu profile={{ ...profile }}>
                <StyledUserInfoName>
                  <NickTrimmer>{user.name || ''}</NickTrimmer>
                </StyledUserInfoName>
              </WithUserMenu>
              <StyledUserInfoId>
                {t('UserAccountPopup.profileID')}:{' '}
                <CopyWrapper textToCopy={user.externalId || ''}>
                  <StyledId>{user.externalId || ''}</StyledId>
                </CopyWrapper>
              </StyledUserInfoId>
            </StyledUserInfoWrapper>
          </StyledUserInfo>
          <StyledUserPlayValue>
            {getTotalMatches(user, 'DOTA2')}
          </StyledUserPlayValue>
          <StyledUserPlayValue $color={DOTAWR > 49 ? 'green' : 'red'}>
            {getWinRatio(user, 'DOTA2')}%
          </StyledUserPlayValue>
          <StyledUserPlayValue>
            {getTotalMatches(user, 'CS')}
          </StyledUserPlayValue>
          <StyledUserPlayValue $color={CSWR > 49 ? 'green' : 'red'}>
            {getWinRatio(user, 'CS')}%
          </StyledUserPlayValue>
          <StyledUserPlayDate>
            {getGameIcon(profile.lastGame)}
            {profile.lastMatchTime
              ? dayjs(profile.lastMatchTime).format('DD.MM.YY')
              : '-'}
          </StyledUserPlayDate>
        </StyledUser>
      )
    })

  return (
    <StyledWrapper>
      <StyledCaption>{t('UserAccountPopup.friends')}</StyledCaption>
      <StyledInput>
        <Search />
        <Input
          hideBorder
          onChange={handleSearchChange}
          placeholder={t('UserAccountPopup.searchInput')}
        />
      </StyledInput>
      <StyledLegend>
        <StyledLegenditem>{t('UserAccountPopup.name')}</StyledLegenditem>
        <StyledLegenditemCentred>
          {t('UserAccountPopup.totalDota2')}
        </StyledLegenditemCentred>
        <StyledLegenditemCentred>
          {t('UserAccountPopup.winRatioDota2')}
        </StyledLegenditemCentred>
        <StyledLegenditemCentred>
          {t('UserAccountPopup.totalCSGO')}
        </StyledLegenditemCentred>
        <StyledLegenditemCentred>
          {t('UserAccountPopup.winRatioCSGO')}
        </StyledLegenditemCentred>
        <StyledLegenditemCentred>
          {t('UserAccountPopup.lastGame')}
        </StyledLegenditemCentred>
      </StyledLegend>
      <StyledUsers>{users}</StyledUsers>
    </StyledWrapper>
  )
}

export default PersonalFriendsList
