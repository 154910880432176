import {
  $userPopupActiveGameInfo,
  $userPopupInfo,
  $userStatisticPageActiveGameInfo,
  $userStatisticPageInfo,
  GameChoosen,
} from 'stores/statisticStore'
import { DtoCSProfile } from 'api/schemas/statisticApi'
import { Text4 } from 'styles/typography'
import { styled } from 'styled-components'
import { useStore } from '@nanostores/react'
import { useTranslation } from 'react-i18next'
import AvatarWithFlag from 'components/AvatarWithFlag'
import NickTrimmer from 'components/NickTrimmer'
import PersonCardTexture from 'images/PersonCardTexture.svg'

const StyledRoot = styled.div`
  width: 480px;
  height: 300px;
  background-image: url('${PersonCardTexture}');
  border: 1px solid rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(7.5px);
  background-color: rgba(255, 255, 255, 0.05);
`
const StyledTop = styled.div`
  display: flex;
  align-items: center;
  margin-left: 12px;
  padding: 14px 12px 14px 0;
  height: 93px;
  width: fit-content;
  border-right: 1px solid rgba(255, 255, 255, 0.15);
  border-bottom-right-radius: 20px;
  position: relative;
  &:before {
    display: block;
    content: '';
    width: calc(100% - 46px);
    height: 100%;
    position: absolute;
    bottom: 0px;
    right: 0px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
    border-bottom-right-radius: 20px;
    z-index: -1;
  }
`
const StyledAvatar = styled.div`
  width: 65px;
  height: 65px;
  border-radius: 3px;
  overflow: hidden;
`
const StyledName = styled.div`
  font-family: Ruberoid;
  font-size: 26px;
  font-weight: 500;
  margin-left: 25px;
  max-width: 330px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`
const StyledBottom = styled.div`
  display: flex;
  height: 207px;
  position: relative;
`
const StyledVerticalText = styled.div`
  transform: rotate(-90deg);
  font-family: 'Nebula', 'Nebulosa';
  font-size: 16px;
  font-weight: 400;
  color: #2cff80;
  position: absolute;
  top: 43px;
  height: 0px;
  width: 0px;
  height: 210px;
  left: 110px;
`
const StyledStatistic = styled.div`
  padding-top: 24px;
  padding-left: 24px;
  margin-left: 38px;
  width: 100%;
  position: relative;
  &:after {
    display: block;
    content: '';
    width: 20px;
    height: 100%;
    position: absolute;
    bottom: 1px;
    left: 0px;
    border-left: 1px solid rgba(255, 255, 255, 0.15);
    border-top-left-radius: 20px;
    border-top: 1px solid rgba(255, 255, 255, 0.15);
    z-index: -1;
  }
`
const StyledLine = styled.div`
  display: flex;
  align-items: center;
`
const StyledMatchCounts = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  color: white;
  min-width: 80px;
`
const StyledMatchCountsText = styled.div`
  font-family: Ruberoid;
  font-size: 42px;
  font-weight: 450;
  line-height: 100%;
`
const StyledMatchCountsDescr = styled(Text4)`
  font-weight: 500;
  line-height: 100%;
  opacity: 0.3;
`
const StyledBottomLine = styled.div`
  display: flex;
  gap: 30px;
  margin-top: 30px;
`
const StyledBottomLineItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 11px;
  color: white;
  min-width: 65px;

  &:first-child {
    width: 104px;
  }
`
const StyledBottomLineItemCaption = styled.div`
  font-family: Ruberoid;
  font-size: 21px;
  font-weight: 450;
  line-height: 115%;
`
const StyledBottomLineItemDescr = styled(Text4)`
  line-height: 100%;
  opacity: 0.3;
  font-weight: 500;
`
const StyledMatchLight = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 0 auto;
`
const StyledMatchLightItem = styled.div`
  display: flex;
  align-items: center;
  color: white;
`
interface StyledLight {
  color: string
}
const StyledMatchLightItemCaption = styled(Text4)<StyledLight>`
  display: flex;
  align-items: center;
  position: relative;
  line-height: 100%;

  &:before {
    display: block;
    content: '';
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: ${({ color }) => `${color}`};
    margin-right: 10px;
  }
`
const StyledMatchLightItemDescr = styled.div`
  font-family: Ruberoid;
  font-size: 16px;
  font-weight: 700;
  line-height: 100%;
  margin-left: 10px;
`
const StyledWinPercent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 13px 23px;
  border-radius: 3px 0px 0px 3px;
  border-top: 1px solid rgba(255, 255, 255, 0.15);
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  border-left: 1px solid rgba(255, 255, 255, 0.15);
  margin-left: auto;
`
const StyledWinPercentText = styled.div`
  font-family: Ruberoid;
  font-size: 26px;
  font-weight: 700;
  line-height: 115%;
  color: #2cff80;
  text-align: center;
`
const StyledWinPercentDescr = styled(Text4)`
  color: white;
  opacity: 0.3;
`
interface IPersonCard {
  gameChoosen: GameChoosen
  page: 'userProfile' | 'statistic'
}

const PersonCard: React.FC<IPersonCard> = ({ page }) => {
  const userActiveGameInfo =
    page === 'userProfile'
      ? (useStore($userPopupActiveGameInfo) as DtoCSProfile)
      : (useStore($userStatisticPageActiveGameInfo) as DtoCSProfile)
  const userInfo =
    page === 'userProfile'
      ? useStore($userPopupInfo)
      : useStore($userStatisticPageInfo)

  const total = () => {
    return (
      (userActiveGameInfo?.matchWinCount || 0) +
      (userActiveGameInfo?.matchLoseCount || 0) +
      (userActiveGameInfo?.matchDrawCount || 0)
    )
  }

  const winRatio = () => {
    const win = userActiveGameInfo?.matchWinCount || 0
    const lose = userActiveGameInfo?.matchLoseCount || 0
    const draw = userActiveGameInfo?.matchDrawCount || 0
    const result = ((win / (draw + lose + win)) * 100).toFixed(2)
    if (draw + lose === 0 && win === 0) {
      return '—'
    } else if (draw + lose === 0 && win > 0) {
      return '100%'
    }
    return `${result}%`
  }
  const { t } = useTranslation()
  return (
    <StyledRoot>
      <StyledTop>
        <StyledAvatar>
          <AvatarWithFlag width={65} height={65} avatarSrc={userInfo?.imgUrl} />
        </StyledAvatar>
        <StyledName>
          <NickTrimmer>{userInfo?.name}</NickTrimmer>
        </StyledName>
      </StyledTop>
      <StyledBottom>
        <StyledVerticalText>{t('play.statistic.statistic')}</StyledVerticalText>
        <StyledStatistic>
          <StyledLine>
            <StyledMatchCounts>
              <StyledMatchCountsText>{total()}</StyledMatchCountsText>
              <StyledMatchCountsDescr>
                {t('play.statistic.total')}
              </StyledMatchCountsDescr>
            </StyledMatchCounts>
            <StyledMatchLight>
              <StyledMatchLightItem>
                <StyledMatchLightItemCaption color="#04D156">
                  {t('play.statistic.win')}
                </StyledMatchLightItemCaption>
                <StyledMatchLightItemDescr>
                  {userActiveGameInfo?.matchWinCount || '0'}
                </StyledMatchLightItemDescr>
              </StyledMatchLightItem>
              <StyledMatchLightItem>
                <StyledMatchLightItemCaption color="#F2B927">
                  {t('play.statistic.draw')}
                </StyledMatchLightItemCaption>
                <StyledMatchLightItemDescr>
                  {userActiveGameInfo?.matchDrawCount || '0'}
                </StyledMatchLightItemDescr>
              </StyledMatchLightItem>
              <StyledMatchLightItem>
                <StyledMatchLightItemCaption color="#F84848">
                  {t('play.statistic.lose')}
                </StyledMatchLightItemCaption>
                <StyledMatchLightItemDescr>
                  {userActiveGameInfo?.matchLoseCount || '0'}
                </StyledMatchLightItemDescr>
              </StyledMatchLightItem>
            </StyledMatchLight>
            <StyledWinPercent>
              <StyledWinPercentText>{winRatio()}</StyledWinPercentText>
              <StyledWinPercentDescr>
                {t('play.statistic.winRatio')}
              </StyledWinPercentDescr>
            </StyledWinPercent>
          </StyledLine>
          <StyledBottomLine>
            <StyledBottomLineItem>
              <StyledBottomLineItemCaption>
                {Math.round(userActiveGameInfo?.KD || 0)}
              </StyledBottomLineItemCaption>
              <StyledBottomLineItemDescr>
                {t('play.statistic.kd')}
              </StyledBottomLineItemDescr>
            </StyledBottomLineItem>
            <StyledBottomLineItem>
              <StyledBottomLineItemCaption>
                {Math.round(userActiveGameInfo?.score || 0)}
              </StyledBottomLineItemCaption>
              <StyledBottomLineItemDescr>
                {t('play.statistic.score')}
              </StyledBottomLineItemDescr>
            </StyledBottomLineItem>
            <StyledBottomLineItem>
              <StyledBottomLineItemCaption>
                {Math.round(userActiveGameInfo?.HS || 0)}%
              </StyledBottomLineItemCaption>
              <StyledBottomLineItemDescr>
                {t('play.statistic.hs')}
              </StyledBottomLineItemDescr>
            </StyledBottomLineItem>
            <StyledBottomLineItem>
              <StyledBottomLineItemCaption>
                {Math.round(userActiveGameInfo?.ADR || 0)}
              </StyledBottomLineItemCaption>
              <StyledBottomLineItemDescr>
                {t('play.statistic.adr')}
              </StyledBottomLineItemDescr>
            </StyledBottomLineItem>
          </StyledBottomLine>
        </StyledStatistic>
      </StyledBottom>
    </StyledRoot>
  )
}

export default PersonCard
