import {
  DtoNotification,
  EnumNotificationType,
} from 'api/schemas/notificationApi'
import { atom, computed } from 'nanostores'
import { logger } from '@nanostores/logger'
import api from 'api/api'
import notificationListiner from 'sockets/notificationListiner'

export type INotification = Record<string, DtoNotification[]>

export const isInvite = (type: EnumNotificationType): boolean => {
  return (
    type === EnumNotificationType.NotificationPartyInvite ||
    type === EnumNotificationType.NotificationFriendRequest ||
    type === EnumNotificationType.NotificationLobbyInvite
  )
}

// STORE

export const $notifications = atom<DtoNotification[]>([])
export const $activeNotificationAuthorID = atom<string>('')

// COMPUTEDS

export const $chatNotifications = computed(
  [$notifications],
  (notifications) => {
    return notifications
      .filter((notification: DtoNotification) => isInvite(notification.type))
      .reduce((acc: INotification, item: DtoNotification) => {
        const authorId = item.payload.authorId || ''
        if (acc[authorId]) {
          acc[authorId] = [...acc[authorId], item]
        } else {
          acc[authorId] = [item]
        }
        return acc
      }, {})
  }
)

export const $notificationsHaveUnreadMessage = computed(
  [$chatNotifications],
  (notifications) => {
    return !!Object.keys(notifications).length
  }
)
export const $haveNewNotification = computed(
  [$chatNotifications],
  (notifications) => {
    return Object.keys(notifications).length
  }
)
// информация об авторе
export const $activeNotificationAutorInfo = computed(
  [$chatNotifications, $activeNotificationAuthorID],
  (notifications, id) => {
    return notifications[id][0]?.usersById[id]
  }
)

export const $activeNotificationAutorMessages = computed(
  [$chatNotifications, $activeNotificationAuthorID],
  (notifications, id) => {
    console.log(notifications, id)
    return notifications[id]
  }
)

// ACTIONS
// получить уведомления
export const getNotifications = async () => {
  const { data } = await api.notifications.getNotifications()
  $notifications.set(data.notifications)
  $notifications.get().forEach((notification) => {
    if (
      notification.type ===
      EnumNotificationType.NotificationFriendRequestAccepted
    ) {
      notificationListiner({ event: 'CREATED', data: notification })
    }
  })
}

export const addNotification = (notification: DtoNotification) => {
  if (!isInvite(notification.type) || !notification.payload.authorId) return
  return $notifications.set([...$notifications.get(), notification])
}

export const removeNotification = (id: string) => {
  const newNotifications = $notifications
    .get()
    .filter((notification) => notification.id !== id)

  $notifications.set([...newNotifications])
}

logger(
  {
    chatNotifications: $chatNotifications,
    haveNewNotification: $haveNewNotification,
    activeNotificationAuthorID: $activeNotificationAuthorID,
    activeNotificationAutorInfo: $activeNotificationAutorInfo,
    notificationsHaveUnreadMessage: $notificationsHaveUnreadMessage,
    activeNotificationAutorMessages: $activeNotificationAutorMessages,
  },
  {
    messages: {
      mount: false,
      unmount: false,
    },
  }
)
