import {
  Area,
  AreaChart,
  Line,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  YAxis,
} from 'recharts'
import { Header5, Text4, Text6 } from 'styles/typography'
import { styled } from 'styled-components'
import { useTranslation } from 'react-i18next'
import LineBackground from 'images/judge/LineBackground.svg'
import Select from 'components/Select'
import StatisticCalendar from 'components/PersonalStatistic/StatisticCalendar'

const PlayerStatistic: React.FC = () => {
  const { t } = useTranslation()

  const data = [
    {
      uv: 15,
      name: 'Page A',
    },
    {
      uv: 2,
      name: 'Page B',
    },
    {
      uv: 1,
      name: 'Page C',
    },
    {
      uv: -5,
      name: 'Page D',
    },
    {
      uv: -10,
      name: 'Page E',
    },
    {
      uv: 2,
      name: 'Page F',
    },
    {
      uv: 30,
      name: 'Page G',
    },
  ]
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const CustomTooltip = ({ active, payload }: any) => {
    if (active) {
      return (
        <StyledTooltip className="custom-tooltip">
          <StyledTooltipText>{`${payload?.[0]?.value}%`}</StyledTooltipText>
        </StyledTooltip>
      )
    }

    return null
  }
  return (
    <StyledWrapper>
      <StyledTop>
        <StyledTopText>
          <StyledTopTextLeft>
            {t('judge.voteModal.statisticIndicator')}
          </StyledTopTextLeft>
          <StyledTopTextRight>46,8%</StyledTopTextRight>
          <StyledSelect
            values={[]}
            options={[]}
            disableSelect
            onChange={() => ''}
            placeholder={'KD'}
          />
        </StyledTopText>
      </StyledTop>
      <StyledChart>
        <ResponsiveContainer width="100%" height="100%">
          <AreaChart data={data}>
            <defs>
              <linearGradient y2="1" x2="0" y1="0" x1="0" id="colorUv">
                <stop offset="5%" stopOpacity={0.3} stopColor="#2cff80" />
                <stop offset="95%" stopOpacity={0} stopColor="#2cff80" />
              </linearGradient>
            </defs>
            <Tooltip content={<CustomTooltip />} />
            <ReferenceLine y={0} stroke="#2CFF80" strokeDasharray="3 3" />
            <Line dataKey="uv" strokeWidth={1} stroke="#2CFF80" />
            <Area
              dataKey="uv"
              fillOpacity={1}
              stroke="#2CFF80"
              fill="url(#colorUv)"
            />
            <YAxis
              hide
              type="number"
              domain={() => {
                return [-50, 50]
              }}
            />
          </AreaChart>
        </ResponsiveContainer>
      </StyledChart>
      <StyledBottom>
        <StatisticCalendar text={t('judge.voteModal.calendarBegin')} />
        <StatisticCalendar text={t('judge.voteModal.calendarEnd')} />
      </StyledBottom>
    </StyledWrapper>
  )
}

export default PlayerStatistic

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  backdrop-filter: blur(7.5px);
  width: 500px;
  height: 265px;
  padding: 0px 0px 0px 18px;
  position: relative;
  z-index: 1;
  background-image: url(${LineBackground});
  background-repeat: no-repeat;
  background-position-x: 11px;
  background-position-y: 47px;
`
const StyledTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const StyledTopText = styled.div`
  display: flex;
  gap: 9px;
`
const StyledTopTextLeft = styled(Text4)`
  font-weight: 500;
`
const StyledTopTextRight = styled(Header5)`
  font-weight: 500;
  color: #2cff80;
  margin: 0px 10px 0px 0px;
`
const StyledChart = styled.div`
  height: 150px;
  border-radius: 3px;
  border: 1px solid rgba(255, 255, 255, 0.15);
  margin-top: 17px;
`
const StyledBottom = styled.div`
  display: flex;
  align-items: center;
  border-radius: 3px;
  margin-top: 26px;
  backdrop-filter: blur(7.5px);
  background: rgba(255, 255, 255, 0.03);
  border: 1px solid rgba(255, 255, 255, 0.15);
  border-right: none;
`

const StyledTooltip = styled.div`
  padding: 4px;
  border-radius: 0px 2px;
  backdrop-filter: blur(70.5px);
  border: 0.5px solid rgba(255, 255, 255, 0.1);
  background: rgba(255, 255, 255, 0.03);
`
const StyledTooltipText = styled(Text6)`
  color: #2cff80;
`

const StyledSelect = styled(Select)`
  width: 160px !important;
  margin-left: 16px;
  z-index: 10;

  & .react-dropdown-select-input {
    width: 120px !important;
  }

  & .react-dropdown-select-dropdown > div > div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`
