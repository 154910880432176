import { Text6 } from 'styles/typography'
import { useTranslation } from 'react-i18next'
import React, { useEffect } from 'react'
import styled from 'styled-components'

interface CopyWrapperProps {
  textToCopy: string
  children: React.ReactNode
}

const StyledRoot = styled.span`
  position: relative;
  cursor: pointer;
`

interface StyledTooltipProps {
  $isShow: boolean
}

const StyledTooltip = styled(Text6)<StyledTooltipProps>`
  position: absolute;
  bottom: -20px;
  right: 0;
  border-radius: 3px;
  border: 1px solid rgba(255, 255, 255, 0.1);

  background: rgba(29, 30, 34, 0.3);

  padding: 5px 8px;

  line-height: 100%;

  opacity: ${({ $isShow }) => ($isShow ? '1' : '0')};
  display: ${({ $isShow }) => ($isShow ? 'block' : 'none')};

  transition: 0.3s;
`

const CopyWrapper: React.FC<CopyWrapperProps> = ({ children, textToCopy }) => {
  const { t } = useTranslation()
  const [isTooltipShow, setIsTooltipShow] = React.useState<boolean>(false)

  const handleIdCopy =
    (id: string) => async (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation()
      await navigator.clipboard.writeText(id)
      setIsTooltipShow(true)
    }

  useEffect(() => {
    if (isTooltipShow) {
      setTimeout(() => setIsTooltipShow(false), 1000)
    }
  }, [isTooltipShow])

  return (
    <StyledRoot onClick={handleIdCopy(textToCopy)}>
      {children}
      <StyledTooltip $isShow={isTooltipShow}>
        {t('buttons.copied')}
      </StyledTooltip>
    </StyledRoot>
  )
}

export default CopyWrapper
