import { atom } from 'nanostores'
import api from 'api/api'

export const $balance = atom<number>(0)

export const setBalance = (value: number) => {
  $balance.set(value)
}

export const getBalance = async () => {
  const value = await api.wallet.getWallet()

  $balance.set(value.data.balance)
}
