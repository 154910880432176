import {
  $config,
  $isUserReady,
  $lobby,
  $lobbyMemberByPosition,
  $match,
  $teamsPowers,
  Country,
  changeLobbyBet,
  changeLobbyCountry,
  changeLobbyMap,
  changeLobbyReady,
} from 'stores/lobbyStore'
import { $userProfile } from 'stores/userStore'
import { Header5, SideHeader2, SideHeader3 } from 'styles/typography'
import { useStore } from '@nanostores/react'
import { useTranslation } from 'react-i18next'
import BetList from 'components/Game/BetList'
import Button from 'components/Button'
import CSUserCard from 'components/PlayerCardMin/CSUserCard'
import LeaveIcon from 'images/LeaveIcon'
import MapList from 'components/Game/MapList'
import React, { useMemo } from 'react'
import ServerChoiser from 'components/ServerChoiser/ServerChoiser'
import StartMatchButton from 'components/StartMatchButton'
import emptySlot from 'images/emptySlot.png'
import emptySlotHover from 'images/emptySlotHover.png'
import startCSGO from 'utils/startCSGo'
import styled, { css } from 'styled-components'
import useLobbyCS from 'hooks/useLobbyCS'
import useStartCSGame from 'hooks/useStartCSGame'

const StyledRoot = styled.div`
  position: relative;
`

const StyledTeams = styled.div`
  position: relative;

  display: flex;

  justify-content: space-between;
  align-items: center;
  gap: 21px;
  margin-top: 14px;
  margin-bottom: 16px;
`

const StyledTeam = styled.div`
  display: flex;
  gap: 4px;
  justify-content: space-between;
`

const StyledTeamsInfo = styled.div`
  display: flex;
  justify-content: space-between;

  gap: 21px;
  margin-bottom: 22px;
`

const StyledTeamInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 810px;
`

const StyledRedTeamTitle = styled(SideHeader2)`
  color: #ff4444;
  text-transform: uppercase;
  text-shadow: 0px -1px 13px rgba(255, 65, 65, 0.5);
`

const StyledBlueTeamTitle = styled(SideHeader2)`
  text-transform: uppercase;

  color: #0fa9ff;
  text-shadow: 0px -1px 13px rgba(0, 117, 255, 0.5);
`

const StyledPower = styled(SideHeader2)`
  display: flex;
  gap: 12px;
  align-items: center;
`

const StyledPowerTitle = styled(SideHeader3)`
  text-transform: uppercase;
  opacity: 0.5;
`
interface StyledGameSettinsWrapperProps {
  $isHost: boolean
}

const StyledGameSettinsWrapper = styled.div<StyledGameSettinsWrapperProps>`
  position: relative;
  display: flex;
  gap: 21px;
  height: 435px;
  & div {
    ${({ $isHost }) =>
      !$isHost &&
      css`
        cursor: default !important;
      `}
  }
`

const StyledModeName = styled.div`
  position: absolute;
  top: -120px;
  width: 100%;
  text-align: center;
  font-family: Nebula;
  -webkit-text-fill-color: transparent;
  background: linear-gradient(
    90deg,
    rgba(169, 98, 32, 0.2) 0%,
    rgba(140, 140, 140, 0) 50%,
    rgba(117, 123, 135, 0.04) 50%,
    rgba(21, 53, 114, 0.2) 100%
  );
  color: transparent;
  -webkit-background-clip: text !important;
  font-size: 284px;
  font-weight: 400;
  letter-spacing: 48.28px;
  line-height: 284px;
  color: transparent;
  text-shadow:
    0px 2px 0px rgba(255, 255, 255, 0.05),
    0px -2px 0px rgba(0, 0, 0, 0.15);
  white-space: nowrap;
  z-index: -1;
`

const StyledPlayHeader = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  margin-bottom: 14px;
  gap: 17px;
`

interface StyledEmptyCardWrapperProps {
  disabled?: boolean
}

const StyledEmptyCardWrapper = styled.div<StyledEmptyCardWrapperProps>`
  position: relative;
  background-image: url('${emptySlot}');
  backdrop-filter: blur(7.5px);

  width: 158px;
  height: 233px;
  cursor: pointer;
  &:hover {
    background-image: url('${emptySlotHover}');
  }
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.8;
      cursor: default;
      &:hover {
        background-image: url('${emptySlot}');
      }
    `}
`

const StyledEmptySlotTitle = styled(Header5)`
  position: absolute;
  right: 60px;
  top: 111px;
  text-transform: uppercase;
`

const Raiting5GameCSPage: React.FC = () => {
  const { t } = useTranslation()

  const lobby = useStore($lobby)
  const config = useStore($config)
  const currentUser = useStore($userProfile)
  const lobbyMemberByPosition = useStore($lobbyMemberByPosition)
  const isReady = useStore($isUserReady)
  const teamsPowers = useStore($teamsPowers)
  const match = useStore($match)

  const { url } = useStartCSGame({ match, lobby })

  const isHost = currentUser?.id === lobby?.hostId

  const isLobbyBlocked = lobby?.status !== 'NEW'

  const isBackBlocked =
    lobby?.status !== 'NEW' &&
    lobby?.status !== 'READY_TO_SEARCH' &&
    lobby?.status !== 'SEARCHING' &&
    lobby?.status !== 'SEARCHING_STOP'

  const { handleLeaveLobby } = useLobbyCS({
    match,
    lobby,
    config,
    isReady,
  })

  const status = useMemo(() => {
    switch (lobby?.status) {
      case 'NEW':
        return 'preparing'
      case 'MATCH_PREPARED':
        return 'started'
      default:
        return 'starting'
    }
  }, [lobby])

  const teams = useMemo(() => {
    const tempTeams: string[] = []

    return lobby?.participants?.reduce((acc, user) => {
      if (user.originLobbyId && !tempTeams.includes(user.originLobbyId)) {
        tempTeams.push(user.originLobbyId)
      }
      if (
        user.originLobbyId &&
        !acc.includes(user.originLobbyId) &&
        tempTeams.includes(user.originLobbyId)
      ) {
        return [...acc, user.originLobbyId]
      }
      return acc
    }, [] as string[])
  }, [lobby?.participants])

  return (
    <StyledRoot>
      <StyledModeName>5x5</StyledModeName>
      <StyledPlayHeader>
        <Button withCorner disabled={isBackBlocked} onClick={handleLeaveLobby}>
          <LeaveIcon />
          <Header5>{t('play.leave')}</Header5>
        </Button>
      </StyledPlayHeader>
      <StyledTeams>
        <StyledTeam>
          {new Array(5).fill('').map((_, index) => {
            if (lobby && lobbyMemberByPosition?.RED[index + 1]) {
              const user = lobbyMemberByPosition?.RED[index + 1]
              return (
                <CSUserCard
                  key={index}
                  lobby={lobby}
                  disabled={isLobbyBlocked}
                  teamIndex={teams?.indexOf(user?.originLobbyId || '')}
                  player={{
                    team: 'RED',
                    profile: user,
                    position: index,
                    isReady: Boolean(user.isReady),
                  }}
                />
              )
            }
            return (
              <StyledEmptyCardWrapper disabled key={index}>
                <StyledEmptySlotTitle>wait</StyledEmptySlotTitle>
              </StyledEmptyCardWrapper>
            )
          })}
        </StyledTeam>
        <StyledTeam>
          {new Array(5).fill('').map((_, index) => {
            if (lobby && lobbyMemberByPosition?.BLUE[index + 1]) {
              const user = lobbyMemberByPosition?.BLUE[index + 1]
              return (
                <CSUserCard
                  disabled
                  key={index}
                  lobby={lobby}
                  teamIndex={teams?.indexOf(user?.originLobbyId || '')}
                  player={{
                    team: 'BLUE',
                    profile: user,
                    position: index,
                    isReady: Boolean(user.isReady),
                  }}
                />
              )
            }
            return (
              <StyledEmptyCardWrapper disabled key={index}>
                <StyledEmptySlotTitle>wait</StyledEmptySlotTitle>
              </StyledEmptyCardWrapper>
            )
          })}
        </StyledTeam>
      </StyledTeams>
      <StyledTeamsInfo>
        <StyledTeamInfo>
          <StyledRedTeamTitle>
            {t('play.customGame.teams.red')}
          </StyledRedTeamTitle>
          <StyledPower>
            {teamsPowers?.RED}
            <StyledPowerTitle>
              {t('play.customGame.teams.power')}
            </StyledPowerTitle>
          </StyledPower>
        </StyledTeamInfo>
        <StyledTeamInfo>
          <StyledPower>
            {teamsPowers?.BLUE}
            <StyledPowerTitle>
              {t('play.customGame.teams.power')}
            </StyledPowerTitle>
          </StyledPower>
          <StyledBlueTeamTitle>
            {t('play.customGame.teams.blue')}
          </StyledBlueTeamTitle>
        </StyledTeamInfo>
      </StyledTeamsInfo>
      <StyledGameSettinsWrapper $isHost={currentUser?.id === lobby?.hostId}>
        <BetList
          multiple
          status={status}
          bets={config?.bets}
          disabled={isLobbyBlocked}
          currentBets={lobby?.bets || []}
          maxAvailableBet={lobby?.maxAvailableBet}
          onChangeBet={isHost ? changeLobbyBet : undefined}
        />
        {/* <Tooltip id="bet" content="Только хост может менять ставку" /> */}
        <MapList
          multiple
          status={status}
          disabled={isLobbyBlocked}
          mapList={config?.gameMaps}
          currentMaps={lobby?.gameMaps || []}
          onChangeGameMap={isHost ? changeLobbyMap : undefined}
        />

        <ServerChoiser
          disabled={isLobbyBlocked}
          currentServer={lobby?.country}
          servers={config?.countries as Country[]}
          onChangeCurrentServer={isHost ? changeLobbyCountry : undefined}
        />
        <StartMatchButton
          url={url}
          lobby={lobby}
          type="rating"
          startCSGO={startCSGO}
          isReady={Boolean(isReady)}
          onReadyClick={changeLobbyReady}
          isHost={currentUser?.id === lobby?.hostId}
          isHostReady={
            lobby?.participants?.find(
              (participant) => participant.user?.id === lobby?.hostId
            )?.isReady || currentUser?.id === lobby?.hostId
          }
        />
      </StyledGameSettinsWrapper>
    </StyledRoot>
  )
}

export default Raiting5GameCSPage
