const AddFile: React.FC = () => {
  return (
    <svg
      width="16"
      fill="none"
      height="16"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="white"
        fillOpacity="0.15"
        clipRule="evenodd"
        fillRule="evenodd"
        d="M10.0293 2.19351L6.89877 5.41588C6.1008 6.23726 6.1008 7.56899 6.89877 8.39038C7.2152 8.71609 7.60958 8.91264 8.02009 8.98004C8.05673 9.18912 8.03238 9.41173 7.88414 9.6491C7.71106 9.92623 7.47555 10.1978 7.26497 10.4132C6.73462 10.241 6.23555 9.93857 5.81513 9.50581C4.41868 8.06839 4.41868 5.73787 5.81513 4.30044L8.94566 1.07807C10.3421 -0.359356 12.6062 -0.359356 14.0027 1.07807C15.3991 2.51549 15.3991 4.84602 14.0027 6.28344L10.9456 9.43016C10.9872 9.06375 10.9908 8.70708 10.9491 8.37837C10.9158 8.11508 10.8331 7.77921 10.7275 7.42386L12.919 5.168C13.717 4.34662 13.717 3.01489 12.919 2.19351C12.121 1.37212 10.8273 1.37212 10.0293 2.19351ZM4.18059 6.62563C4.18059 6.52816 4.19229 6.41815 4.21244 6.30118C4.20086 6.31278 4.18933 6.32449 4.17787 6.33629L1.04734 9.55866C-0.349113 10.9961 -0.349113 13.3266 1.04734 14.764C2.44379 16.2015 4.70789 16.2015 6.10434 14.764L9.23487 11.5417C10.6313 10.1042 10.6313 7.77371 9.23487 6.33629C8.80324 5.892 8.28872 5.58503 7.74255 5.41539C7.60332 5.56724 7.45054 5.73773 7.28817 5.92453C7.00606 6.24908 6.92139 6.57293 6.93171 6.84844C7.37721 6.89903 7.80964 7.10012 8.15123 7.45173C8.9492 8.27311 8.9492 9.60484 8.15123 10.4262L5.0207 13.6486C4.22273 14.47 2.92896 14.47 2.13098 13.6486C1.33301 12.8272 1.33301 11.4955 2.13098 10.6741L4.39634 8.34228L4.39344 8.33455C4.18059 7.76491 4.18059 7.2829 4.18059 6.62563Z"
      />
    </svg>
  )
}

export default AddFile
