import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Header7 } from 'styles/typography'
import InfoTooltipIcon from 'components/Tooltip/Info/InfoTooltipIcon'
import RequiredFieldIndicator from 'components/RequiredFieldIndicator'
import Select from 'components/Select'

interface JudgeExperienceInputProps {
  value: boolean
  onChange: (v: boolean) => void
}

const JudgeExperienceInput = ({
  value,
  onChange,
}: JudgeExperienceInputProps) => {
  const { t } = useTranslation()

  return (
    <StyledSelect
      onChange={([{ value }]) => onChange(Boolean(value))}
      values={[
        {
          value,
          label: value ? t('judge.welcomePage.yes') : t('judge.welcomePage.no'),
        },
      ]}
      label={
        <StyledLabel>
          {t('judge.welcomePage.experience')}
          <RequiredFieldIndicator />
          <InfoTooltipIcon text={t('judge.welcomePage.infoTooltip')} />
        </StyledLabel>
      }
      options={[
        {
          value: false,
          label: t('judge.welcomePage.no'),
        },
        {
          value: true,
          label: t('judge.welcomePage.yes'),
        },
      ]}
    />
  )
}

const StyledSelect = styled(Select)`
  height: 32px !important;
  margin-bottom: 16px;
  position: relative;
  z-index: 1;
`

const StyledLabel = styled(Header7)`
  margin-bottom: 2px;
  display: flex;
  align-items: center;
  gap: 5px;
  color: white;
`

export default JudgeExperienceInput
