import { useMemo } from 'react'
import { useStore } from '@nanostores/react'

import { $myWorkJudgeStatistic } from 'stores/statisticStore'

const useJudgeWorkStatistic = () => {
  const myWorkJudgeStatistic = useStore($myWorkJudgeStatistic)

  return useMemo(() => {
    const result = {
      todayFinishedCount: 0,
      thisMonthFinishedCount: 0,
      todayFinishedPercentage: 0,
      lastToThisMonthPercentage: '0',
    }

    if (!myWorkJudgeStatistic) return result

    const {
      finishedClaimCount = 0,
      prevMothFinishedClaimCount = 0,
      currentMothFinishedClaimCount = 0,
    } = myWorkJudgeStatistic

    result.todayFinishedCount = finishedClaimCount
    result.thisMonthFinishedCount = currentMothFinishedClaimCount
    result.todayFinishedPercentage = result.todayFinishedCount * 10

    const diff = prevMothFinishedClaimCount
      ? ((currentMothFinishedClaimCount - prevMothFinishedClaimCount) /
          prevMothFinishedClaimCount) *
        100
      : 0

    result.lastToThisMonthPercentage = `${diff < 0 ? '' : '+'}${diff.toFixed()}%`

    return result
  }, [myWorkJudgeStatistic])
}

export default useJudgeWorkStatistic
