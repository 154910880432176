/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface DtoCSBestWeaponProfileStat {
  damage?: number
  headShotCount?: number
  killCount?: number
  matchId?: string
  profileId?: string
  weapon?: DtoWeapon
}

export interface DtoCSMatchProfileStat {
  ADR?: number
  CSBestWeaponProfileStat?: DtoCSBestWeaponProfileStat
  EF?: number
  HS?: number
  KAST?: number
  KD?: number
  KDDiff?: number
  assist?: number
  dead?: number
  kill?: number
  matchId?: string
  profileId?: string
  rating?: number
  ratingDiff?: number
  score?: number
}

export interface DtoCSMatchResult {
  CSMatchRoundResults?: DtoCSMatchRoundResult[]
  attachmentId?: string
  createdAt?: string
  isDraw?: boolean
  loseTeamColor?: string
  loseTeamScore?: number
  matchId?: string
  winTeamColor?: string
  winTeamScore?: number
}

export interface DtoCSMatchRoundResult {
  createdAt?: string
  loseTeamColor?: string
  loseTeamDeadCount?: number
  round?: number
  winCondition?: string
  winTeamColor?: string
  winTeamDeadCount?: number
}

export interface DtoCSProfileStat {
  id?: string
}

export interface DtoCountry {
  ImgUrl?: string
  name?: string
}

export interface DtoDOTA2ProfileStat {
  id?: string
}

export interface DtoDemoUrl {
  url?: string
}

export interface DtoGameMap {
  gameName?: string
  id?: string
  imgIconUrl?: string
  imgUrl?: string
  label?: string
  name?: string
}

export interface DtoGameMode {
  ImgUrl?: string
  id?: string
  name?: string
}

export interface DtoMatch {
  CSMatchResult?: DtoCSMatchResult
  bet?: number
  country?: DtoCountry
  createdAt?: string
  declinedAt?: string
  declinedCode?: string
  declinedReason?: string
  demoStatus?: EnumDemoStatus
  externalId?: string
  finishAt?: string
  gameFormat?: string
  gameMap?: DtoGameMap
  gameMode?: DtoGameMode
  gameName?: string
  handleFinishAt?: string
  id?: string
  isDraw?: boolean
  isRating?: boolean
  lobbyId?: string
  loseTeamColor?: string
  loseTeamScore?: number
  participants?: DtoMatchParticipant[]
  startAt?: string
  status?: string
  statusTimestamp?: string
  url?: string
  winTeamColor?: string
  winTeamScore?: number
}

export interface DtoMatchParticipant {
  originLobbyId?: string
  slotOrder?: number
  teamColor?: string
  user?: DtoUser
}

export interface DtoUser {
  CSMatchProfileStat?: DtoCSMatchProfileStat
  CSProfileStat?: DtoCSProfileStat
  DOTA2ProfileStat?: DtoDOTA2ProfileStat
  country?: string
  id?: string
  imgUrl?: string
  name?: string
  steamId?: string
}

export interface DtoWeapon {
  gameName?: string
  id?: string
  imgIconUrl?: string
  imgUrl?: string
  name?: string
}

export enum EnumDemoStatus {
  InProgressStatusAvailable = 'IN_PROGRESS',
  DemoStatusAvailable = 'AVAILABLE',
  DemoStatusNotAvailable = 'NOT_AVAILABLE',
}

export interface ResponseResponse {
  data?: any
  meta?: ResponseMeta
  pagination?: ResponsePagination
}

export interface ResponseMeta {
  code?: string
  error?: string
  message?: string
  requestId?: string
  traceId?: string
}

export interface ResponsePagination {
  page?: number
  pages?: number
  perPage?: number
}

export type GetMatchError = ResponseResponse

export type GetIdDemoError = ResponseResponse

export type GetLastParticipantsError = ResponseResponse

export namespace Match {
  /**
   * @description Get information about matches
   * @tags Matches
   * @name GetMatch
   * @summary Get finished matches
   * @request GET:/match
   */
  export namespace GetMatch {
    export type RequestParams = {}
    export type RequestQuery = {
      /** get matches wich finished befor this time */
      finishAtFrom?: string
      /** get match by external id */
      externalMatchId?: string
      /** Game format */
      gameFormat?: string
      /** get match by id */
      matchId?: string
    }
    export type RequestBody = never
    export type RequestHeaders = {
      /**
       * Access token
       * @default "Bearer <token>"
       */
      Authorization: string
      /** Request id identity */
      'X-Request-Id': string
    }
    export type ResponseBody = ResponseResponse & {
      data?: DtoMatch[]
    }
  }

  /**
   * @description Get match demo
   * @tags Matches
   * @name GetIdDemo
   * @summary Get match demo
   * @request GET:/match/:id/demo
   */
  export namespace GetIdDemo {
    export type RequestParams = {
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {
      /**
       * Access token
       * @default "Bearer <token>"
       */
      Authorization: string
      /** Request id identity */
      'X-Request-Id': string
    }
    export type ResponseBody = ResponseResponse & {
      data?: DtoDemoUrl
    }
  }

  /**
   * @description Get last match participants
   * @tags Matches
   * @name GetLastParticipants
   * @summary Get last match participants
   * @request GET:/match/last/participants
   */
  export namespace GetLastParticipants {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {
      /**
       * Access token
       * @default "Bearer <token>"
       */
      Authorization: string
      /** Request id identity */
      'X-Request-Id': string
    }
    export type ResponseBody = ResponseResponse & {
      data?: DtoUser[]
    }
  }
}

import type { AxiosInstance, AxiosRequestConfig, HeadersDefaults, ResponseType } from 'axios'
import axios from 'axios'

export type QueryParamsType = Record<string | number, any>

export interface FullRequestParams extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean
  /** request path */
  path: string
  /** content type of request body */
  type?: ContentType
  /** query params */
  query?: QueryParamsType
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType
  /** request body */
  body?: unknown
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
  securityWorker?: (
    securityData: SecurityDataType | null
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void
  secure?: boolean
  format?: ResponseType
}

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance
  private securityData: SecurityDataType | null = null
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker']
  private secure?: boolean
  private format?: ResponseType

  constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({
      ...axiosConfig,
      baseURL: axiosConfig.baseURL || '//localhost:8080/f2f/match/api/v1',
    })
    this.secure = secure
    this.format = format
    this.securityWorker = securityWorker
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data
  }

  protected mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method)

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method && this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    }
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === 'object' && formItem !== null) {
      return JSON.stringify(formItem)
    } else {
      return `${formItem}`
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    if (input instanceof FormData) {
      return input
    }
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key]
      const propertyContent: any[] = property instanceof Array ? property : [property]

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem))
      }

      return formData
    }, new FormData())
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<T> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {}
    const requestParams = this.mergeRequestParams(params, secureParams)
    const responseFormat = format || this.format || undefined

    if (type === ContentType.FormData && body && body !== null && typeof body === 'object') {
      body = this.createFormData(body as Record<string, unknown>)
    }

    if (type === ContentType.Text && body && body !== null && typeof body !== 'string') {
      body = JSON.stringify(body)
    }

    return this.instance
      .request({
        ...requestParams,
        headers: {
          ...(requestParams.headers || {}),
          ...(type ? { 'Content-Type': type } : {}),
        },
        params: query,
        responseType: responseFormat,
        data: body,
        url: path,
      })
      .then((response) => response.data)
  }
}

/**
 * @title Match API
 * @version 1.0
 * @baseUrl //localhost:8080/f2f/match/api/v1
 * @contact Mikhel Alexander
 *
 * This is match api service.
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  match = {
    /**
     * @description Get information about matches
     *
     * @tags Matches
     * @name GetMatch
     * @summary Get finished matches
     * @request GET:/match
     */
    getMatch: (
      query?: {
        /** get matches wich finished befor this time */
        finishAtFrom?: string
        /** get match by external id */
        externalMatchId?: string
        /** Game format */
        gameFormat?: string
        /** get match by id */
        matchId?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<
        ResponseResponse & {
          data?: DtoMatch[]
        },
        GetMatchError
      >({
        path: `/match`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * @description Get match demo
     *
     * @tags Matches
     * @name GetIdDemo
     * @summary Get match demo
     * @request GET:/match/:id/demo
     */
    getIdDemo: (id: string, params: RequestParams = {}) =>
      this.request<
        ResponseResponse & {
          data?: DtoDemoUrl
        },
        GetIdDemoError
      >({
        path: `/match/${id}/demo`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Get last match participants
     *
     * @tags Matches
     * @name GetLastParticipants
     * @summary Get last match participants
     * @request GET:/match/last/participants
     */
    getLastParticipants: (params: RequestParams = {}) =>
      this.request<
        ResponseResponse & {
          data?: DtoUser[]
        },
        GetLastParticipantsError
      >({
        path: `/match/last/participants`,
        method: 'GET',
        format: 'json',
        ...params,
      }),
  }
}
