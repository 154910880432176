import { Header6 } from 'styles/typography'
import ButtonCorner from 'images/ButtonCorner'
import buttonClickSound from 'sounds/simple-buttons.mp3'
import styled, { css } from 'styled-components'
import useSound from 'hooks/useSound'

interface ButtonProps {
  size?: 's' | 'm'
  isLoading?: boolean
  withCorner?: boolean
  withoutBorder?: boolean
  view?: 'primary' | 'secondary'
}

const Button: React.FC<
  ButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>
> = ({
  size,
  view,
  onClick,
  children,
  className,
  withCorner,
  withoutBorder,
  disabled = false,
  ...rest
}) => {
  const [play] = useSound(buttonClickSound)

  const onClickWithSound = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (onClick) {
      onClick(e)
      play()
    }
  }

  return (
    <StyledButton
      $size={size}
      $view={view}
      disabled={disabled}
      className={className}
      $withoutBorder={withoutBorder}
      {...rest}
      onClick={onClickWithSound}
    >
      <>
        <StyledContent disabled={disabled}>{children}</StyledContent>{' '}
        {withCorner && <StyledCorner />}
      </>
    </StyledButton>
  )
}

interface StyledButtonProps {
  $size?: 's' | 'm'
  $withoutBorder?: boolean
  $view?: 'primary' | 'secondary'
}

const getMainColor = (view: 'primary' | 'secondary' = 'primary') => {
  return view === 'secondary' ? 'rgba(255, 68, 68, 1)' : '#ffcd29'
}

const getTextColor = (view: 'primary' | 'secondary' = 'primary') => {
  return view === 'secondary' ? 'rgba(255, 68, 68, 1)' : 'white'
}

const getBackgroundHover = (view: 'primary' | 'secondary' = 'primary') => {
  return view === 'secondary'
    ? 'rgba(255, 68, 68, 0.40)'
    : 'rgba(238, 157, 62, 0.15)'
}

const StyledButton = styled.button<StyledButtonProps>`
  position: relative;
  text-transform: uppercase;

  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;

  gap: 3px;
  padding: 13px 15px;
  height: ${({ $size }) => ($size === 's' ? '42px' : 'auto')};

  opacity: ${({ disabled }) => (disabled ? '0.8' : 1)};

  background: ${({ $withoutBorder }) =>
    $withoutBorder ? 'tranparent' : 'rgba(255, 255, 255, 0.03)'};

  border: ${({ $withoutBorder }) =>
    $withoutBorder ? 'none' : '1px solid rgba(255, 255, 255, 0.15)'};

  fill: ${({ $view }) => getMainColor($view)};
  border-radius: 3px;
  stroke: ${({ $view }) => getMainColor($view)};
  color: ${({ $view }) => getTextColor($view)};

  & > #buttonCorner {
    stroke: ${({ $view }) => getMainColor($view)};
  }

  &:hover {
    background: ${({ $view, $withoutBorder }) =>
      $withoutBorder ? 'tranparent' : getBackgroundHover($view)};
    border: ${({ $withoutBorder }) =>
      $withoutBorder ? 'none' : '1px solid rgba(255, 255, 255, 0.15)'};
    color: ${({ $view, $withoutBorder }) =>
      $withoutBorder ? 'rgba(255, 255, 255, 0.3)' : getTextColor($view)};

    border-radius: 3px;
    fill: ${({ $view }) => getMainColor($view)};

    ${({ $view, disabled, $withoutBorder }) =>
      disabled &&
      css`
        background: ${$withoutBorder
          ? 'tranparent'
          : 'rgba(255, 255, 255, 0.03)'};

        border: ${$withoutBorder
          ? 'none'
          : '1px solid rgba(255, 255, 255, 0.15)'};

        fill: ${getMainColor($view)};
        border-radius: 3px;
        stroke: ${getMainColor($view)};
        color: ${getTextColor($view)};

        & > #buttonCorner {
          stroke: ${getMainColor($view)};
        }
      `};
  }
  &:active {
    transform: scale(1.01);

    background: ${({ $view, $withoutBorder }) =>
      $withoutBorder ? 'transparent' : getMainColor($view)};
    border: ${({ $withoutBorder }) =>
      $withoutBorder ? 'none' : '1px solid rgba(255, 255, 255, 0.2)'};
    border-radius: 3px;
    fill: #2cff80;
    color: ${({ $withoutBorder }) => ($withoutBorder ? '#F8BF2C' : 'black')};
    stroke: black;
    & > #buttonCorner {
      stroke: #2cff80;
    }
    ${({ $view, disabled, $withoutBorder }) =>
      disabled &&
      css`
        background: ${$withoutBorder
          ? 'tranparent'
          : 'rgba(255, 255, 255, 0.03)'};

        border: ${$withoutBorder
          ? 'none'
          : '1px solid rgba(255, 255, 255, 0.15)'};

        fill: ${getMainColor($view)};
        border-radius: 3px;
        stroke: ${getMainColor($view)};
        color: ${getTextColor($view)};

        & > #buttonCorner {
          stroke: ${getMainColor($view)};
        }
      `};
  }

  transition: 0.3s;
`

const StyledCorner = styled(ButtonCorner)`
  position: absolute;
  bottom: 0;
  right: 0;
  fill: inherit;
`

interface StyledContentProps {
  disabled: boolean
}

const StyledContent = styled(Header6)<StyledContentProps>`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 3px;
  ${({ disabled }) => disabled && `opacity: 0.3`}
`

export default Button
