/* eslint-disable @typescript-eslint/no-explicit-any */
import { $party, setParty } from 'stores/partyStore'

const partyListiner = async (data: any) => {
  if (data.entityAction === 'UPDATE') {
    $party.get()?.id === data.data.id && (await setParty(data.data))
  }
  // if (data.entityAction === 'DELETE') {
  //   const profile = $userProfile.get()
  //   if (profile) {
  //     await setUserProfile({ ...profile, partyId: undefined })
  //   }
  //   console.log('Пати удалена')
  // }
}

export default partyListiner
