/* eslint-disable @typescript-eslint/no-explicit-any */
import { addFriend, getFriends } from 'stores/friendsStore'

const friendsListener = async (data: any) => {
  if (data.entityAction === 'CREATE') {
    await addFriend(data.data)
  }
  if (data.event === 'DELETE') {
    await getFriends()
  }
}

export default friendsListener
