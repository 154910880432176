import { DtoTotpDto, DtoUser, DtoUserSetting } from 'api/schemas/userApi'
import { atom } from 'nanostores'
import { changeLanguage } from 'i18next'
import { logError } from 'utils/logger'
import { logger } from '@nanostores/logger'
import { logout } from 'stores/authStore'
import { qrOptions, qrOptionsRegistration } from 'consts/qrCodeGenerator'
import QRCode from 'qrcode'
import api from 'api/api'

export type Profile = DtoUser

export const $userProfile = atom<DtoUser | null>(null)
export const $userAuthenticatorCode = atom<DtoTotpDto | null>(null)
export const $showStandingsPopup = atom<boolean>(false)

export const $isCodeGenerate = atom<boolean>(true)
export const $qrImage = atom<string | null>(null)
export const $showQrCodePopup = atom<boolean>(false)
export const $userConfig = atom<DtoUserSetting | null>(null)

export const toggleQrCodePopup = (isOpen: boolean) => {
  $showQrCodePopup.set(isOpen)
}

export const getUserProfile = async () => {
  try {
    const { data } = (await api.users.getUsers({ withSetting: true })) as {
      data: DtoUser
    }
    data.setting && $userConfig.set(data.setting)

    return $userProfile.set(data)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    if (
      (e?.request?.status && e.request.status === 410) ||
      (e?.response?.status && e.response.status === 410)
    ) {
      await logout()
    }
    throw Error(e as string)
  }
}

export const changeUserConfig = async (
  name: string,
  value: string | number | boolean
) => {
  try {
    const { data } = (await api.users.putSettings({
      ...$userConfig.get(),
      [name]: value,
    })) as {
      data: DtoUser
    }

    if (!data.setting) {
      return
    }

    return $userConfig.set(data.setting)
  } catch (e) {
    console.log(e)
  }
}

// export const getUserConfig = async () => {
//   try {
//     const { data } = (await api.users.getConfig()) as { data: DtoUserConfig }
//     return $userConfig.set(data)
//   } catch (e) {
//     console.log(e)
//   }
// }

export const userTotpGenerate = async (isRegistrationPage?: boolean) => {
  try {
    $isCodeGenerate.set(true)
    const { data } = (await api.users.postTotpGenerate()) as {
      data: DtoTotpDto
    }
    if (data.url) {
      await QRCode.toDataURL(
        data.url,
        isRegistrationPage ? qrOptionsRegistration : qrOptions,
        function (err, url) {
          if (err) return
          $qrImage.set(url)
        }
      )
    }
    $userAuthenticatorCode.set(data)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    logError('Ошибка генерации TOTP кода', e)
    console.log(e)
  } finally {
    $isCodeGenerate.set(false)
  }
}

export const userOtpConfirm = async (
  code: string,
  isRegistrationPage?: boolean
) => {
  try {
    await api.users.postTotpConfirm({ code })
    if (isRegistrationPage) return
    await getUserProfile()
    toggleQrCodePopup(false)
  } catch (e) {
    console.log(e)
  }
}

export const clearUserAuthenticatorCode = () => {
  $qrImage.set(null)
  $userAuthenticatorCode.set(null)
}

export const syncSteam = async () => {
  try {
    if ($userProfile.get()?.steamId) {
      await api.accounts.getSteamSync()
    }
  } catch (e) {
    console.log(e)
  }
}

export const setUserProfile = (profile: DtoUser) => {
  return $userProfile.set({ ...profile })
}

$userConfig.listen(async (store) => {
  if (store) {
    const isCloseTray = store.closeTheTray
    const isLoginOpen = store.startingAtWindowsStartup
    const version = store.applicationVersion
    const lng = store.interfaceLanguage || ''

    await changeLanguage(lng.toLocaleLowerCase())

    window.ipcRenderer.invoke('trayToggle', isCloseTray)
    window.ipcRenderer.invoke('isLoginOpen', isLoginOpen)
    window.ipcRenderer.invoke('changeFlavor', version)
  }
})

logger(
  {
    userConfig: $userConfig,
    userProfile: $userProfile,
    userAuthenticatorCode: $userAuthenticatorCode,
  },
  {
    messages: {
      mount: false,
      unmount: false,
    },
  }
)

export const getCurrentVolume = () => $userConfig.get()?.volume ?? 0
