interface JudgeIconProps {
  fill?: string
}

const JudgeIcon: React.FC<JudgeIconProps> = ({ fill }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill={fill}
    stroke="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.0008 7L13.0007 20H15.5007C15.7769 20 16.0007 20.2239 16.0007 20.5V21.5C16.0007 21.7761 15.7769 22 15.5007 22H12.5007H11.5007H8.50075C8.22461 22 8.00075 21.7761 8.00075 21.5V20.5C8.00075 20.2239 8.22461 20 8.50075 20H11.0007L11.0007 7H6.69918L7.80929 9.14294C7.90329 9.3244 7.77866 9.51129 7.56364 9.51129L6.53194 9.5113C6.3049 9.5113 6.08446 9.38185 5.9852 9.19024L4.99998 7.28839L4.01476 9.19024C3.9155 9.38185 3.69506 9.5113 3.46802 9.5113L2.43632 9.51129C2.2213 9.51129 2.09667 9.3244 2.19068 9.14294L3.30078 7H2.50075C2.2246 7 2.00075 6.77614 2.00075 6.5L2.00075 5.5C2.00075 5.22386 2.22461 5 2.50075 5L11.0007 5V2.5C11.0007 2.22386 11.2246 2 11.5007 2H12.5008C12.7769 2 13.0008 2.22386 13.0008 2.5V5L21.5007 5C21.7769 5 22.0007 5.22386 22.0007 5.5V6.5C22.0007 6.77614 21.7769 7 21.5007 7H20.3994L21.7221 12.5004C21.7758 12.7239 21.6027 13.0008 21.4093 13.0008L20.239 13.0009C20.0875 13.0009 19.9678 12.8926 19.9257 12.7175L18.8418 8.20999L17.7579 12.7175C17.7158 12.8926 17.5961 13.0009 17.4446 13.0009L16.2743 13.0008C16.0809 13.0008 15.9078 12.7239 15.9615 12.5004L17.2842 7H13.0008ZM9.50075 11C9.77689 11 10.0033 11.2244 9.97578 11.4992C9.86116 12.6419 9.35556 13.7163 8.53628 14.5355C7.5986 15.4732 6.32683 16 5.00075 16C3.67467 16 2.4029 15.4732 1.46522 14.5355C0.645937 13.7163 0.140338 12.6419 0.0257139 11.4992C-0.00184632 11.2244 0.224606 11 0.500749 11L5.00075 11H9.50075ZM23.975 14.4992C24.0026 14.2244 23.7761 14 23.5 14H19L14.5 14C14.2239 14 13.9974 14.2244 14.025 14.4992C14.1396 15.6419 14.6452 16.7163 15.4645 17.5355C16.4021 18.4732 17.6739 19 19 19C20.3261 19 21.5979 18.4732 22.5355 17.5355C23.3548 16.7163 23.8604 15.6419 23.975 14.4992Z"
    />
  </svg>
)

export default JudgeIcon
