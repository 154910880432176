const BombWinIcon = ({ className }: { className?: string }) => (
  <svg
    width="24"
    height="24"
    fill="inherit"
    viewBox="0 0 24 24"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M8.60766 4.69587C8.54152 4.72894 8.44966 4.80611 8.40556 4.86858C8.3284 4.97514 8.32472 5.02291 8.3137 5.79823L8.30267 6.61766H7.81763C7.29952 6.61766 7.22971 6.6397 7.13417 6.82711C7.10845 6.87855 7.09375 7.246 7.09375 7.91844V8.93262H8.85753H10.6213V7.94417C10.6213 7.33787 10.6066 6.91529 10.5809 6.84548C10.5111 6.64705 10.4229 6.61766 9.8864 6.61766H9.40871V5.85703C9.40871 5.17356 9.40136 5.0817 9.33522 4.94574C9.20294 4.67382 8.8759 4.56359 8.60766 4.69587Z" />
    <path d="M14.057 4.67682C13.3 4.82013 12.6386 5.52931 12.5247 6.31934L12.4806 6.61698H11.9993C11.4885 6.61698 11.4187 6.63902 11.3231 6.82642C11.2974 6.87787 11.2827 7.249 11.2827 7.92879V8.95031L11.0696 9.07892C10.9557 9.14874 10.6764 9.39493 10.4486 9.61908L10.0371 10.0343H8.56724H7.09375V14.0579V18.0815H8.85753H10.6213V16.4464V14.8149L10.8675 15.0023C11.0035 15.1015 11.1504 15.2044 11.1982 15.2264L11.2827 15.2632V16.6742V18.0815H13.0465H14.8103V16.6742V15.2632L14.8948 15.2264C14.9426 15.2044 15.0896 15.1015 15.2255 15.0023L15.4717 14.8149V16.4464V18.0815H17.2355H18.9993V14.0579V10.0343H17.5258H16.056L15.6444 9.61908C15.4166 9.39493 15.1373 9.14874 15.0234 9.07892L14.8103 8.95031V7.92879C14.8103 7.249 14.7956 6.87787 14.7699 6.82642C14.6743 6.63902 14.6045 6.61698 14.0791 6.61698H13.5867L13.6124 6.46632C13.6418 6.27524 13.7116 6.13929 13.8586 5.99598C14.0974 5.75713 14.1084 5.75346 15.1447 5.75346H16.0854L16.2654 5.8637C16.4749 5.9923 16.6439 6.24585 16.6733 6.46632L16.6953 6.61698H16.2103C15.6701 6.61698 15.5819 6.64637 15.5121 6.8448C15.4864 6.91461 15.4717 7.33719 15.4717 7.94349V8.93194H17.2355H18.9993V7.91776C18.9993 7.24532 18.9846 6.87787 18.9588 6.82642C18.8633 6.63902 18.7935 6.61698 18.2754 6.61698H17.7867V6.49572C17.7867 6.43325 17.7609 6.27524 17.7315 6.14664C17.5588 5.42275 16.9158 4.80175 16.1919 4.67314C15.9273 4.62537 14.3142 4.62537 14.057 4.67682ZM13.7667 9.69624C14.2371 9.84322 14.7184 10.1703 15.0124 10.5414C15.6481 11.3388 15.7363 12.4632 15.2402 13.3524C14.9499 13.8668 14.4061 14.3188 13.8439 14.5062C13.5756 14.5944 13.4875 14.6054 13.0465 14.6054C12.6056 14.6054 12.5174 14.5944 12.2491 14.5062C11.4812 14.249 10.8638 13.6023 10.6287 12.8086C10.5184 12.4374 10.5074 11.8642 10.5993 11.5041C10.8602 10.5157 11.6428 9.8028 12.6791 9.6154C12.8775 9.57866 13.5462 9.6301 13.7667 9.69624Z" />
    <path d="M12.7896 10.3618C12.7198 10.3986 12.6243 10.4941 12.5765 10.5713C12.4957 10.7109 12.4957 10.7366 12.503 11.6589C12.514 12.5445 12.5177 12.607 12.5912 12.7025C12.7676 12.9414 12.8153 12.9561 13.4437 12.9561C13.9581 12.9561 14.0353 12.9487 14.1492 12.8826C14.4101 12.7282 14.4983 12.3792 14.3403 12.1183C14.2337 11.9529 14.061 11.8721 13.8038 11.8721H13.598V11.2915C13.598 10.7624 13.5907 10.7036 13.5135 10.5713C13.418 10.4059 13.2306 10.292 13.0468 10.292C12.977 10.292 12.8594 10.3214 12.7896 10.3618Z" />
    <path d="M7.09375 20.18C7.09375 20.8378 7.10845 21.2015 7.13417 21.253C7.22971 21.4367 7.3032 21.4624 7.78456 21.4624C8.1116 21.4624 8.24021 21.4735 8.24756 21.5065C8.2549 21.5323 8.27695 21.6535 8.30267 21.7748C8.40556 22.3407 8.8906 22.9616 9.42341 23.2189C9.84231 23.421 10.0812 23.454 11.0439 23.4393C12.014 23.421 12.1977 23.3805 12.6056 23.1086C13.142 22.7522 13.4801 22.2267 13.5793 21.5984L13.6014 21.4624H14.0864C14.6045 21.4624 14.6743 21.4404 14.7699 21.253C14.7956 21.2015 14.8103 20.8378 14.8103 20.18V19.1842H13.0465H11.2827V20.18C11.2827 20.8378 11.2974 21.2015 11.3231 21.253C11.4187 21.4367 11.4885 21.4624 11.9919 21.4624C12.4476 21.4624 12.4586 21.4661 12.4586 21.5433C12.4586 21.8005 12.1058 22.1937 11.7861 22.2892C11.5657 22.3553 10.32 22.3664 10.0995 22.3076C9.77616 22.2157 9.44546 21.8813 9.38666 21.58L9.36462 21.4624H9.86435C10.4229 21.4624 10.5111 21.433 10.5809 21.2346C10.6066 21.1648 10.6213 20.7496 10.6213 20.1543V19.1842H8.85753H7.09375V20.18Z" />
    <path d="M15.4727 20.1538C15.4727 20.749 15.4874 21.1643 15.5131 21.2341C15.5939 21.4656 15.5719 21.4619 17.2438 21.4619C18.6254 21.4619 18.7687 21.4546 18.8385 21.3958C18.9929 21.2745 19.0002 21.212 19.0002 20.1795V19.1837H17.2364H15.4727V20.1538Z" />
    <path
      strokeLinecap="round"
      strokeWidth="0.909446"
      d="M6.87402 5.62988L5 5.62988"
    />
    <path
      strokeLinecap="round"
      strokeWidth="0.909446"
      d="M7.08267 3.56815L5.49609 2.5708"
    />
    <path
      strokeLinecap="round"
      strokeWidth="0.909446"
      d="M8.85594 2.87384L8.83008 1"
    />
    <path
      strokeLinecap="round"
      strokeWidth="0.909446"
      d="M10.5943 3.56562L12.0215 2.35107"
    />
  </svg>
)

export default BombWinIcon
