const FriendsIcon = ({ className }: { className?: string }) => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_354_23195)">
      <g clipPath="url(#clip1_354_23195)">
        <path
          clipRule="evenodd"
          fillRule="evenodd"
          d="M12.5 7.65383C12.5 9.94793 10.6403 11.8077 8.34621 11.8077C6.05212 11.8077 4.19238 9.94793 4.19238 7.65383C4.19238 5.35973 6.05212 3.5 8.34621 3.5C10.6403 3.5 12.5 5.35973 12.5 7.65383ZM16.1922 21.5768C16.1922 19.4959 15.3656 17.5002 13.8942 16.0287C12.4227 14.5573 10.427 13.7307 8.34612 13.7307C6.2652 13.7307 4.26951 14.5573 2.79808 16.0287C1.32664 17.5002 0.5 19.4959 0.5 21.5768L8.34612 21.5768H16.1922ZM18.4999 13.8708C20.2542 13.8708 21.6763 12.4487 21.6763 10.6944C21.6763 8.94007 20.2542 7.51792 18.4999 7.51792C16.7456 7.51792 15.3234 8.94007 15.3234 10.6944C15.3234 12.4487 16.7456 13.8708 18.4999 13.8708ZM22.7424 17.3341C23.8676 18.4593 24.4998 19.9854 24.4998 21.5767H18.4998H18.0384C18.0384 19.5688 17.4153 17.625 16.2761 16.004C16.9769 15.7244 17.7307 15.5768 18.4998 15.5768C20.0911 15.5768 21.6172 16.2089 22.7424 17.3341Z"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_354_23195">
        <rect width="24" height="24" transform="translate(0.5 0.5)" />
      </clipPath>
      <clipPath id="clip1_354_23195">
        <rect width="24" height="24" transform="translate(0.5 0.5)" />
      </clipPath>
    </defs>
  </svg>
)

export default FriendsIcon
