import { Header2 } from 'styles/typography'
import { useTranslation } from 'react-i18next'

import JudgeVoteTexture from 'images/judge/JudgeVoteTexture.svg'

import RemainingTimer from 'components/Judge/JudgeVoteTimer'
import VoteStatus from 'components/Judge/Ticket/VoteStatus'
import styled from 'styled-components'

const VoteProcess = () => {
  const { t } = useTranslation()

  return (
    <>
      <StyledStatContainer>
        <StyledResultContainer>
          <RemainingTimer
            progress={25}
            size={120}
            descfont={16}
            timerfont={26}
            lineheight={21}
            left={48}
            top={50}
          />
          <StyledStatTitle>{t('judge.voteModal.decision')}</StyledStatTitle>
        </StyledResultContainer>
        <JudgeStatus>
          <VoteStatus status={'voted'} owner={false} />
          <VoteStatus status={'voted'} owner={false} />
          <VoteStatus status={'process'} owner={true} />
        </JudgeStatus>
      </StyledStatContainer>
    </>
  )
}
export default VoteProcess

const StyledStatContainer = styled.div`
  padding: 34px 113px 34px 34px;
  display: flex;
  width: 100%;
  align-items: center;
  height: 177px;
  border-radius: 100px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  justify-content: space-between;
  background: url(${JudgeVoteTexture});
  background-repeat: no-repeat;
  background-size: contain;
  margin: 0px 0px 24px 0px;

  /* BlurEffect */
  backdrop-filter: blur(7.5px);
`
const StyledResultContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
const StyledStatTitle = styled(Header2)`
  margin: 0px 0px 0px 68px;
  text-transform: uppercase;
  line-height: 29px;
  padding: 0px 0px 0px 23px;
`
const JudgeStatus = styled.div`
  display: flex;
  flex-direction: row;
`
