import { useEffect, useState } from 'react'
import { useStore } from '@nanostores/react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import {
  $myTicketsStatistic,
  getMyTicketsStatistic,
} from 'stores/statisticStore'
import { Header1, Header2, Text5 } from 'styles/typography'
import JudgeStatPie from 'components/Judge/MyTickets/JudgeStatPie'
import Loading from 'components/Loading'

const UserTicketsStatistic = () => {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(true)
  const myTicketsStatistic = useStore($myTicketsStatistic)

  useEffect(() => {
    const fetchUserStatistic = async () => {
      await getMyTicketsStatistic()
      setIsLoading(false)
    }

    void fetchUserStatistic()
  }, [])

  if (isLoading) {
    return (
      <StyledStatContainer>
        <Loading />
      </StyledStatContainer>
    )
  }

  if (!myTicketsStatistic) {
    return null
  }

  const {
    currentMothClaimCount,
    maxAvailableMonthClaimCount,
    currentAvailableMonthClaimCount,
    currentMothDeclinedClaimCount = 0,
    currentMothFinishedClaimCount = 0,
  } = myTicketsStatistic

  return (
    <StyledStatContainer>
      <StyledStatTitle>{t('judge.myTickets.statistic')}</StyledStatTitle>
      <StyledStatWrapper>
        <StyledLimitWrapper>
          <StyledLimitNumbers>
            {currentAvailableMonthClaimCount}/
            <StyledDisabledColorText>
              {maxAvailableMonthClaimCount}
            </StyledDisabledColorText>
          </StyledLimitNumbers>
          <StyledLimitText>
            <StyledLimitLeftText>
              {t('judge.myTickets.claimsLeft')}
            </StyledLimitLeftText>
            <StyledDisabledColorText>
              {t('judge.myTickets.claimsMonthlyLimit1')}{' '}
              {maxAvailableMonthClaimCount}{' '}
              {t('judge.myTickets.claimsMonthlyLimit2')}
            </StyledDisabledColorText>
          </StyledLimitText>
        </StyledLimitWrapper>
        <StyledSeparator />
        <JudgeStatPie
          finished={currentMothFinishedClaimCount}
          declined={currentMothDeclinedClaimCount}
        />
        <StyledAllStatWrapper>
          <StyledAllStat>
            <StyledAllStatNumber>{currentMothClaimCount}</StyledAllStatNumber>
            <StyledAllStatText>
              {t('judge.myTickets.claimsTotal')}
            </StyledAllStatText>
          </StyledAllStat>
          <StyledPieMap>
            <StyledPieMapItem>
              <StyledGreenCircle />
              {t('common.approved')}
            </StyledPieMapItem>
            <StyledPieMapItem>
              <StyledOrangeCircle />
              {t('common.declined')}
            </StyledPieMapItem>
          </StyledPieMap>
        </StyledAllStatWrapper>
      </StyledStatWrapper>
    </StyledStatContainer>
  )
}

const StyledGreenCircle = styled.div`
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: rgba(44, 255, 128, 1);
`

const StyledOrangeCircle = styled.div`
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: #ffcd29;
`

const StyledPieMapItem = styled(Text5)`
  display: flex;
  gap: 8px;
  align-items: center;
`

const StyledPieMap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8;
`

const StyledAllStat = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4;
`

const StyledAllStatNumber = styled(Header2)`
  font-weight: 400;
`
const StyledAllStatText = styled(Text5)``

const StyledAllStatWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 170px;
  justify-content: space-between;
`

const StyledSeparator = styled.div`
  margin: 0 48px;
  width: 4px;
  height: 91px;
  border-radius: 2px;
  background-color: rgba(255, 255, 255, 0.03);
`

const StyledLimitLeftText = styled.div`
  flex: none;
`

const StyledDisabledColorText = styled.span`
  color: var(--Button-Disabled, rgba(255, 255, 255, 0.15));
`

const StyledLimitNumbers = styled(Header1)`
  font-weight: 400;
`

const StyledLimitText = styled(Text5)`
  display: flex;
  gap: 10px;

  &:last-child {
    text-align: right;
  }
`

const StyledLimitWrapper = styled.div`
  display: flex;
  flex-direction: column;

  width: 320px;
`

const StyledStatWrapper = styled.div`
  display: flex;
  align-items: center;
`

const StyledStatTitle = styled(Header2)`
  text-transform: uppercase;
`

const StyledStatContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 32px 80px;
  justify-content: space-between;
  align-items: center;
  gap: 19px;
  height: 232px;
  border-radius: 100px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  background: var(--Button-BaseButtonColor, rgba(255, 255, 255, 0.03));

  /* BlurEffect */
  backdrop-filter: blur(7.5px);
`

export default UserTicketsStatistic
