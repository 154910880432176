import { Header3, Text4 } from 'styles/typography'
import Button from 'components/Button'
import StyledModal from 'styled-react-modal'
import back from 'images/LobbyPopupBack.svg'
import line from 'images/LobbyPopupLine.svg'
import styled from 'styled-components'

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 11;
`
const StyledBack = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0px;
  left: 0px;
  opacity: 0.78;
  background: black;
  width: 100%;
  height: 100%;
`
const StyledDashes = styled.div`
  width: 848px;
  height: 375px;
  background-image: url(${back});
  opacity: 0.3;
`
const StyledLineLeft = styled.div`
  width: 330px;
  height: 19px;
  background: url(${line});
  position: relative;
`
const StyledLineRight = styled(StyledLineLeft)`
  transform: scale(-1, 1);
`
const StyledPopup = styled.div`
  display: flex;
  flex-direction: column;
  width: 700px;
  height: 230px;
  margin: 0 20px;
  position: relative;
  border-radius: 3px;
  border: 1px solid rgba(255, 68, 68, 0.3);
  background: rgba(71, 36, 36, 0.3);
  backdrop-filter: blur(20px);
  border-top: 4px solid #ffcd29;
  padding: 0 20px 14px 20px;
`
const StyledPopupContent = styled.div`
  display: flex;
  gap: 48px;
  align-items: center;
  margin-top: 52px;
`
const StyledPopupTextLeft = styled(Header3)`
  line-height: 115%;
  text-transform: uppercase;
  color: #ffcd29;
  width: 300px;
`
const StyledPopupTextRight = styled(Text4)`
  color: white;
  font-weight: 500;
  line-height: normal;
  width: 280px;
`
const StyledPopupButtons = styled.div`
  display: flex;
  gap: 8px;
  margin-top: auto;
`
const StyledButton = styled(Button)`
  justify-content: center;
  width: 100%;
`
interface ModalProps {
  caption: string
  isOpen: boolean
  description: string
  acceptTitle?: string
  onAccept: () => void
  declineTitle?: string
  onDecline?: () => void
}

const ConfirmActionPopup: React.FC<ModalProps> = ({
  isOpen,
  caption,
  onAccept,
  onDecline,
  description,
  acceptTitle,
  declineTitle,
}) => {
  return (
    <StyledModal isOpen={isOpen}>
      <StyledWrapper>
        <StyledBack>
          <StyledDashes />
        </StyledBack>
        <StyledLineLeft />
        <StyledPopup>
          <StyledPopupContent>
            <StyledPopupTextLeft>{caption}</StyledPopupTextLeft>
            <StyledPopupTextRight>{description}</StyledPopupTextRight>
          </StyledPopupContent>
          <StyledPopupButtons>
            {declineTitle && (
              <StyledButton size="s" onClick={onDecline}>
                {declineTitle}
              </StyledButton>
            )}
            {acceptTitle && (
              <StyledButton size="s" onClick={onAccept}>
                {acceptTitle}
              </StyledButton>
            )}
          </StyledPopupButtons>
        </StyledPopup>
        <StyledLineRight />
      </StyledWrapper>
    </StyledModal>
  )
}

export default ConfirmActionPopup
