const Coin = ({ className }: { className?: string }) => (
  <svg
    width="17"
    height="21"
    viewBox="0 0 17 21"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      fillRule="evenodd"
      d="M9.44983 0.767309C8.86207 0.410897 8.13793 0.410897 7.55017 0.76731L0.949834 4.7697C0.362075 5.12611 0 5.78479 0 6.49761V14.5024C0 15.2152 0.362075 15.8739 0.949835 16.2303L7.55017 20.2327C8.13793 20.5891 8.86208 20.5891 9.44983 20.2327L16.0502 16.2303C16.6379 15.8739 17 15.2152 17 14.5024V6.49761C17 5.78479 16.6379 5.12611 16.0502 4.7697L9.44983 0.767309ZM1.11882 14.7232C0.916167 14.5104 0.874893 14.1811 1.01819 13.9204L7.10271 2.85163C7.21582 2.64585 7.42487 2.51909 7.6511 2.51909H9.14895C9.37518 2.51909 9.58423 2.64585 9.69734 2.85163L15.7603 13.8812C15.9036 14.1419 15.8623 14.4712 15.6597 14.684L14.4464 15.9583C14.3276 16.0831 14.1665 16.1531 13.9986 16.1531H2.74252C2.57458 16.1531 2.41351 16.0831 2.29476 15.9583L1.11882 14.7232Z"
    />
  </svg>
)

export default Coin
