/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface DtoCSMapProfileStat {
  gameCount?: number
  gameMap?: DtoGameMap
}

export interface DtoCSProfile {
  ADR?: number
  CSMapProfileStats?: DtoCSMapProfileStat[]
  CSWeaponProfileStats?: DtoCSWeaponProfileStat[]
  HS?: number
  KD?: number
  KDDiff?: number
  assist?: number
  calibrationRequired?: boolean
  confidence?: number
  createdAt?: string
  damage?: number
  dead?: number
  gameFormat?: string
  headShotCount?: number
  id?: string
  kill?: number
  lastGameTime?: string
  loseStreak?: number
  matchDrawCount?: number
  matchLoseCount?: number
  matchWinCount?: number
  nextRankRating?: number
  prevRankRating?: number
  rank?: string
  rating?: number
  roundCount?: number
  score?: number
  spentTimeInGameMin?: number
  steamId?: string
  steamId3?: string
  updatedAt?: string
  userExternalId?: string
  userId?: string
  userImg?: string
  userName?: string
  winStreak?: number
}

export interface DtoCSTotalStat {
  CSProfiles?: DtoCSProfile[]
  gamesInTotal?: number
  spentTimeInGamesMin?: number
}

export interface DtoCSWeaponProfileStat {
  KD?: number
  deadCount?: number
  killCount?: number
  weapon?: DtoWeapon
}

export interface DtoDOTA2HeroProfileStat {
  drawCount?: number
  heroImg?: string
  heroName?: string
  loseCount?: number
  roles?: DtoDOTA2Role[]
  winCount?: number
}

export interface DtoDOTA2Profile {
  DH?: number
  DMG?: number
  DOTA2HeroProfileStats?: DtoDOTA2HeroProfileStat[]
  LH?: number
  NET?: number
  assist?: number
  calibrationRequired?: boolean
  createdAt?: string
  dead?: number
  gameFormat?: string
  id?: string
  kill?: number
  lastGameTime?: string
  matchDrawCount?: number
  matchLoseCount?: number
  matchWinCount?: number
  nextRankRating?: number
  prevRankRating?: number
  rank?: string
  rating?: number
  score?: number
  spentTimeInGameMin?: number
  steamId?: string
  steamId3?: string
  updatedAt?: string
  userExternalId?: string
  userId?: string
  userImg?: string
  userName?: string
}

export interface DtoDOTA2Role {
  drawCount?: number
  loseCount?: number
  roleName?: string
  winCount?: number
}

export interface DtoDOTA2TotalStat {
  DOTA2Profiles?: DtoDOTA2Profile[]
  gamesInTotal?: number
  spentTimeInGamesMin?: number
}

export interface DtoGameMap {
  gameName?: string
  id?: string
  imgIconUrl?: string
  imgUrl?: string
  label?: string
  name?: string
}

export interface DtoOnlineStat {
  createdAt?: string
  onlineCount?: number
}

export interface DtoStanding {
  gameFormat?: string
  gameName?: string
  position?: number
  rank?: string
  rating?: number
  userId?: string
  userImg?: string
  userName?: string
}

export interface DtoTotalGameStat {
  gameFormat?: string
  gameName?: string
  id?: string
  onlineLobbyCount?: number
  onlineMatchCount?: number
  totalMatches?: number
  totalReward?: number
  updatedAt?: string
}

export interface DtoTotalStat {
  currentOnlineCount?: number
  onlineStats?: DtoOnlineStat[]
  totalGameStat?: DtoTotalGameStat[]
  tournamentTables?: DtoTournamentTable[]
}

export interface DtoTournamentTable {
  gameFormat?: string
  gameName?: string
  standings?: DtoStanding[]
}

export interface DtoUserTotalStat {
  CSTotalStat?: DtoCSTotalStat
  DOTA2TotalStat?: DtoDOTA2TotalStat
  gamesCount?: number
  mostPlayedGameName?: string
  spentTimeInGamesMin?: number
}

export interface DtoWeapon {
  gameName?: string
  id?: string
  imgIconUrl?: string
  imgUrl?: string
  name?: string
}

export interface ResponseResponse {
  data?: any
  meta?: ResponseMeta
  pagination?: ResponsePagination
}

export interface ResponseMeta {
  code?: number
  error?: string
  message?: string
  requestId?: string
  traceId?: string
}

export interface ResponsePagination {
  page?: number
  pages?: number
  perPage?: number
}

export type GetProfilesIdError = ResponseResponse

export type GetProfilesIdFail = ResponseResponse

export type GetUserError = ResponseResponse

export type GetTotalError = ResponseResponse

export type GetUserIdError = ResponseResponse

export namespace Cs {
  /**
   * @description get cs user profiles
   * @tags Statistic
   * @name GetProfilesId
   * @summary get cs user profiles
   * @request GET:/cs/profiles/:id
   */
  export namespace GetProfilesId {
    export type RequestParams = {
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {
      /**
       * Access token
       * @default "Bearer <token>"
       */
      Authorization: string
      /** Request id identity */
      'X-Request-Id': string
    }
    export type ResponseBody = ResponseResponse & {
      data?: DtoCSProfile[]
    }
  }
}

export namespace Dota2 {
  /**
   * @description get dota2 user profiles
   * @tags Statistic
   * @name GetProfilesId
   * @summary get dota2 user profiles
   * @request GET:/dota2/profiles/:id
   */
  export namespace GetProfilesId {
    export type RequestParams = {
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {
      /**
       * Access token
       * @default "Bearer <token>"
       */
      Authorization: string
      /** Request id identity */
      'X-Request-Id': string
    }
    export type ResponseBody = ResponseResponse & {
      data?: DtoDOTA2Profile[]
    }
  }
}

export namespace Standing {
  /**
   * @description get users standings
   * @tags Statistic
   * @name GetUser
   * @summary get users standings
   * @request GET:/standing/user
   */
  export namespace GetUser {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {
      /**
       * Access token
       * @default "Bearer <token>"
       */
      Authorization: string
      /** Request id identity */
      'X-Request-Id': string
    }
    export type ResponseBody = ResponseResponse & {
      data?: DtoStanding[]
    }
  }
}

export namespace Total {
  /**
   * @description get total f2f stat
   * @tags Statistic
   * @name GetTotal
   * @summary get total f2f stat
   * @request GET:/total
   */
  export namespace GetTotal {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {
      /**
       * Access token
       * @default "Bearer <token>"
       */
      Authorization: string
      /** Request id identity */
      'X-Request-Id': string
    }
    export type ResponseBody = ResponseResponse & {
      data?: DtoTotalStat
    }
  }

  /**
   * @description get user total statistic
   * @tags Statistic
   * @name GetUserId
   * @summary get user total statistic
   * @request GET:/total/user/:id
   */
  export namespace GetUserId {
    export type RequestParams = {
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {
      /**
       * Access token
       * @default "Bearer <token>"
       */
      Authorization: string
      /** Request id identity */
      'X-Request-Id': string
    }
    export type ResponseBody = ResponseResponse & {
      data?: DtoUserTotalStat
    }
  }
}

import type { AxiosInstance, AxiosRequestConfig, HeadersDefaults, ResponseType } from 'axios'
import axios from 'axios'

export type QueryParamsType = Record<string | number, any>

export interface FullRequestParams extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean
  /** request path */
  path: string
  /** content type of request body */
  type?: ContentType
  /** query params */
  query?: QueryParamsType
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType
  /** request body */
  body?: unknown
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
  securityWorker?: (
    securityData: SecurityDataType | null
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void
  secure?: boolean
  format?: ResponseType
}

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance
  private securityData: SecurityDataType | null = null
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker']
  private secure?: boolean
  private format?: ResponseType

  constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({
      ...axiosConfig,
      baseURL: axiosConfig.baseURL || '//localhost:8080/f2f/statistic/api/v1',
    })
    this.secure = secure
    this.format = format
    this.securityWorker = securityWorker
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data
  }

  protected mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method)

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method && this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    }
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === 'object' && formItem !== null) {
      return JSON.stringify(formItem)
    } else {
      return `${formItem}`
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    if (input instanceof FormData) {
      return input
    }
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key]
      const propertyContent: any[] = property instanceof Array ? property : [property]

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem))
      }

      return formData
    }, new FormData())
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<T> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {}
    const requestParams = this.mergeRequestParams(params, secureParams)
    const responseFormat = format || this.format || undefined

    if (type === ContentType.FormData && body && body !== null && typeof body === 'object') {
      body = this.createFormData(body as Record<string, unknown>)
    }

    if (type === ContentType.Text && body && body !== null && typeof body !== 'string') {
      body = JSON.stringify(body)
    }

    return this.instance
      .request({
        ...requestParams,
        headers: {
          ...(requestParams.headers || {}),
          ...(type ? { 'Content-Type': type } : {}),
        },
        params: query,
        responseType: responseFormat,
        data: body,
        url: path,
      })
      .then((response) => response.data)
  }
}

/**
 * @title Statistic API
 * @version 1.0
 * @baseUrl //localhost:8080/f2f/statistic/api/v1
 * @contact Mikhel Alexander
 *
 * This is statistic api service.
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  cs = {
    /**
     * @description get cs user profiles
     *
     * @tags Statistic
     * @name GetProfilesId
     * @summary get cs user profiles
     * @request GET:/cs/profiles/:id
     */
    getProfilesId: (id: string, params: RequestParams = {}) =>
      this.request<
        ResponseResponse & {
          data?: DtoCSProfile[]
        },
        GetProfilesIdError
      >({
        path: `/cs/profiles/${id}`,
        method: 'GET',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  dota2 = {
    /**
     * @description get dota2 user profiles
     *
     * @tags Statistic
     * @name GetProfilesId
     * @summary get dota2 user profiles
     * @request GET:/dota2/profiles/:id
     */
    getProfilesId: (id: string, params: RequestParams = {}) =>
      this.request<
        ResponseResponse & {
          data?: DtoDOTA2Profile[]
        },
        GetProfilesIdFail
      >({
        path: `/dota2/profiles/${id}`,
        method: 'GET',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  standing = {
    /**
     * @description get users standings
     *
     * @tags Statistic
     * @name GetUser
     * @summary get users standings
     * @request GET:/standing/user
     */
    getUser: (params: RequestParams = {}) =>
      this.request<
        ResponseResponse & {
          data?: DtoStanding[]
        },
        GetUserError
      >({
        path: `/standing/user`,
        method: 'GET',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  total = {
    /**
     * @description get total f2f stat
     *
     * @tags Statistic
     * @name GetTotal
     * @summary get total f2f stat
     * @request GET:/total
     */
    getTotal: (params: RequestParams = {}) =>
      this.request<
        ResponseResponse & {
          data?: DtoTotalStat
        },
        GetTotalError
      >({
        path: `/total`,
        method: 'GET',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description get user total statistic
     *
     * @tags Statistic
     * @name GetUserId
     * @summary get user total statistic
     * @request GET:/total/user/:id
     */
    getUserId: (id: string, params: RequestParams = {}) =>
      this.request<
        ResponseResponse & {
          data?: DtoUserTotalStat
        },
        GetUserIdError
      >({
        path: `/total/user/${id}`,
        method: 'GET',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
}
