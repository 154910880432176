const ReadyButtonIcon = ({ className }: { className?: string }) => (
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.3">
      <rect y="0.5" x="0.25" width="1.64706" height="1.66326" />
      <rect x="0.25" y="4.65814" width="1.64706" height="1.66326" />
      <rect x="0.25" y="8.81628" width="1.64706" height="1.66326" />
      <rect x="0.25" y="12.9747" width="1.64706" height="1.66326" />
      <rect y="0.5" x="4.36719" width="1.64706" height="1.66326" />
      <rect x="4.36719" y="4.65814" width="1.64706" height="1.66326" />
      <rect x="4.36719" y="8.81628" width="1.64706" height="1.66326" />
      <rect x="4.36719" y="12.9747" width="1.64706" height="1.66326" />
      <rect y="0.5" x="8.48535" width="1.64706" height="1.66326" />
      <rect x="8.48535" y="4.65814" width="1.64706" height="1.66326" />
      <rect x="8.48535" y="8.81628" width="1.64706" height="1.66326" />
      <rect x="8.48535" y="12.9747" width="1.64706" height="1.66326" />
      <rect y="0.5" x="12.6025" width="1.64706" height="1.66326" />
      <rect x="12.6025" y="4.65826" width="1.64706" height="1.66326" />
      <rect x="12.6025" y="8.81647" width="1.64706" height="1.66326" />
      <rect x="12.6025" y="12.9747" width="1.64706" height="1.66326" />
    </g>
  </svg>
)

export default ReadyButtonIcon
