const routes = {
  app: () => '/',
  home: () => '/home',
  play: () => '/play',
  login: () => '/login',
  play1x1: () => '/play/1x1',
  statistic: () => '/statistic',
  spectating: () => '/spectating',
  chooseGame: () => '/choose-game',
  playRating: () => '/play/rating',
  playCustom: () => '/play/custom',
  registration: () => '/registration',
  statistic1x1: () => '/statistic/1x1',
  resetPassword: () => '/reset-password',
  statisticRating: () => '/statistic/rating',
  statisticCustom: () => '/statistic/custom',
  spectatingJudge: () => '/spectating/judge',
  spectatingMyTickets: () => '/spectating/my-tickets',
  results: (id?: string) => `/play/${id ? id : ':matchId'}`,
  results1x1: (id?: string) => `/play/results1x1/${id ? id : ':matchId'}`,
  resultsHistory: (id?: string) => `/play/${id ? id : ':matchId'}/history`,
  matchInfo: (id?: string) => `/statistic/results/${id ? id : ':matchId'}`,
  resultsScoreboard: (id?: string) =>
    `/play/${id ? id : ':matchId'}/scoreboard`,
  matchInfo1x1: (id?: string) =>
    `/statistic/results1x1/${id ? id : ':matchId'}`,
  matchInfoHistory: (id?: string) =>
    `/statistic/results/${id ? id : ':matchId'}/history`,
  matchInfoScoreboard: (id?: string) =>
    `/statistic/results/${id ? id : ':matchId'}/scoreboard`,
}

export default routes
