import { Header3, Text4 } from 'styles/typography'
import { styled } from 'styled-components'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Button from 'components/Button'
import Modal from 'styled-react-modal'
import bg from 'images/update-modal-bg.webp'

const StyledModal = Modal.styled`
  width: 611px;
  height: 520px;
  display: flex;
  align-items: end;
  flex-direction: column;
  gap: 36px;
  padding-right: 48px;
  padding-left: 96px;
  padding-top: 78px;
  background-image: url('${bg}'); 
  background-size: contain;
  background-color: black;
`

const StyledTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 330px;
  justify-content: space-between;
`

const StyledTitle = styled(Header3)`
  font-weight: normal;
`

const StyledText = styled(Text4)``

const StyledCreds = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`

const StyledButton = styled(Button)``

const UpdateModal = () => {
  const { t } = useTranslation()

  const [isOpen, setIsOpen] = useState(false)

  const toggleModal = () => {
    setIsOpen(!isOpen)
  }

  const handleInstallUpdate = () => {
    window.ipcRenderer.invoke('update')
  }

  useEffect(() => {
    const timer = setInterval(async () => {
      const result = await window.ipcRenderer.invoke('isHavingUpdate')
      if (result) {
        toggleModal()
      }
    }, 45000)

    return () => clearInterval(timer)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <StyledModal isOpen={isOpen}>
      <StyledTextWrapper>
        <StyledTitle>{t('modals.update.title')}</StyledTitle>
        <StyledText>{t('modals.update.mainText')}</StyledText>
        <StyledText>{t('modals.update.thanks')}</StyledText>
        <StyledCreds>
          <StyledText>{t('modals.update.wish')}</StyledText>

          <StyledText>{t('modals.update.sender')}</StyledText>
        </StyledCreds>
      </StyledTextWrapper>
      <StyledButton onClick={handleInstallUpdate}>
        {t('buttons.update')}
      </StyledButton>
    </StyledModal>
  )
}

export default UpdateModal
