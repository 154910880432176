import { SpinnerCircularFixed } from 'spinners-react'
import { styled } from 'styled-components'

const StyledRoot = styled.div`
  width: 100%;
  height: 100%;

  display: flex;

  align-items: center;
  justify-content: center;
`

const Loading = () => {
  return (
    <StyledRoot>
      <SpinnerCircularFixed
        size="48"
        thickness={250}
        color="rgba(44, 255, 128, 1)"
        secondaryColor="rgba(255, 255, 255, 0.1)"
      />
    </StyledRoot>
  )
}

export default Loading
