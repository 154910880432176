import { ReactNode } from 'react'
import ResultsMatchHistory from 'components/MatchResults/MatchResultsHistory'
import ResultsMatchScoreboard from 'components/MatchResults/MatchResultsScoreboard'
import WinnerLeftPart from 'components/MatchResults/WinnerLeftPart'
import styled from 'styled-components'

interface Props {
  tab: 'history' | 'scoreboard'
}

const MatchResultCustomCS = ({ tab }: Props) => {
  return (
    <StyledResultScoreboardWrapper>
      <WinnerLeftPart />
      {tabToContent[tab]}
    </StyledResultScoreboardWrapper>
  )
}

const tabToContent: Record<Props['tab'], ReactNode> = {
  history: <ResultsMatchHistory />,
  scoreboard: <ResultsMatchScoreboard />,
}

const StyledResultScoreboardWrapper = styled.div`
  display: flex;
`

export default MatchResultCustomCS
