import { $match } from 'stores/lobbyStore'
import {
  AppealStageTypes,
  handleCreateNewAppeal,
} from 'stores/chatStores/appealStore'
import { DtoMatchParticipant } from 'api/schemas/matchApi'
import { EnumClaimReportCategory } from 'api/schemas/supportApi'
import { Header5, Header6, Text4, Text5 } from 'styles/typography'
import { css, styled } from 'styled-components'
import { setTabActive } from 'stores/chatStores/chatStore'
import { useMemo } from 'react'
import { useStore } from '@nanostores/react'
import { useTranslation } from 'react-i18next'
import AvatarWithRang from 'components/AvatarWithRang'
import Coin from 'images/Coin'
import MVPlostIcon from 'images/MVPlost.svg'
import MVPwonIcon from 'images/MVPwon.svg'
import NickTrimmer from 'components/NickTrimmer'
import TeamIcons from 'images/userCard/TeamIcons'
import WithTooltip from 'components/Tooltip/TableHeader/WithTooltip'
import WithUserMenu from 'components/ContextMenu/WithUserMenu'
import round from 'utils/round'

const ResultsMatchScoreboard = () => {
  const { t } = useTranslation()
  const match = useStore($match)

  const winner = match?.CSMatchResult?.winTeamColor

  if (!match?.participants || match?.participants?.length === 0) {
    return null
  }

  const redMVP = getMVP(
    match.participants.filter((participant) => participant.teamColor === 'RED')
  )

  const blueMVP = getMVP(
    match.participants.filter((participant) => participant.teamColor === 'BLUE')
  )

  const handlePlayerReport =
    (userId: string) => async (e: React.MouseEvent<HTMLElement>) => {
      e.preventDefault()
      e.stopPropagation()
      setTabActive('support', true)
      await handleCreateNewAppeal({
        game: 'CS 2',
        reportedUserId: userId,
        reportedMatchId: match.id,
        nextStep: AppealStageTypes.ADD_FILES,
        prevStep: AppealStageTypes.CHOOSE_PLAYER,
        currentStep: AppealStageTypes.CHOOSE_PLAYER_REASON,
        category: EnumClaimReportCategory.ClaimReportCategoryPlayerReport,
      })
    }

  const teams = useMemo(() => {
    const tempTeams: string[] = []

    return match?.participants?.reduce((acc, user) => {
      if (user.originLobbyId && !tempTeams.includes(user.originLobbyId)) {
        tempTeams.push(user.originLobbyId)
      }
      if (
        user.originLobbyId &&
        !acc.includes(user.originLobbyId) &&
        tempTeams.includes(user.originLobbyId)
      ) {
        return [...acc, user.originLobbyId]
      }
      return acc
    }, [] as string[])
  }, [match?.participants])

  return (
    <StyledRoot>
      <StyledRightPart>
        <StyledTableHead>
          <StyledTableHeadMainPart>
            <div></div>
            <WithTooltip
              title={t('results.tooltips.kill.title')}
              text={t('results.tooltips.kill.description')}
            >
              <StyledTableHeadItem>K</StyledTableHeadItem>
            </WithTooltip>
            <WithTooltip
              title={t('results.tooltips.death.title')}
              text={t('results.tooltips.death.description')}
            >
              <StyledTableHeadItem>D</StyledTableHeadItem>
            </WithTooltip>
            <WithTooltip
              title={t('results.tooltips.assist.title')}
              text={t('results.tooltips.assist.description')}
            >
              <StyledTableHeadItem>A</StyledTableHeadItem>
            </WithTooltip>
            <WithTooltip
              title={t('results.tooltips.diff.title')}
              text={t('results.tooltips.diff.description')}
            >
              <StyledTableHeadItem>+/-</StyledTableHeadItem>
            </WithTooltip>
            <WithTooltip
              title={t('results.tooltips.kd.title')}
              text={t('results.tooltips.kd.description')}
            >
              <StyledTableHeadItem>K/D</StyledTableHeadItem>
            </WithTooltip>
            <WithTooltip
              title={t('results.tooltips.adr.title')}
              text={t('results.tooltips.adr.description')}
            >
              <StyledTableHeadItem>ADR</StyledTableHeadItem>
            </WithTooltip>
            <WithTooltip
              title={t('results.tooltips.hs.title')}
              text={t('results.tooltips.hs.description')}
            >
              <StyledTableHeadItem>HS%</StyledTableHeadItem>
            </WithTooltip>
            <WithTooltip
              title={t('results.tooltips.kast.title')}
              text={t('results.tooltips.kast.description')}
            >
              <StyledTableHeadItem>KAST</StyledTableHeadItem>
            </WithTooltip>
          </StyledTableHeadMainPart>
          <WithTooltip
            title={t('results.tooltips.ef.title')}
            text={t('results.tooltips.ef.description')}
          >
            <StyledTableHeadItem>EF</StyledTableHeadItem>
          </WithTooltip>
          <StyledTableHeadItem>SCORE</StyledTableHeadItem>
          <StyledTableHeadItem>RATING</StyledTableHeadItem>
          <StyledTableHeadItem>REWARD</StyledTableHeadItem>
        </StyledTableHead>
        <StyledTableParts>
          <StyledTablePart>
            <StyledTableBody>
              {match?.participants
                ?.filter((item) => item.teamColor === 'RED')
                .reduce(
                  (acc, item) =>
                    item.user?.id === redMVP.user?.id
                      ? [item, ...acc]
                      : [...acc, item],
                  [] as DtoMatchParticipant[]
                )
                .map(({ user, originLobbyId }, index) => {
                  if (!user) return

                  const { CSMatchProfileStat, ...item } = user
                  const isWin = winner === 'RED'
                  const isMVP = redMVP.user?.id === item.id
                  const isWinMVP = isWin && isMVP

                  return (
                    <StyledTableRow key={index}>
                      {isMVP && <StyledMVPLabel $isWin={winner === 'RED'} />}
                      <StyledTableRowMainItem isWinMVP={isWinMVP}>
                        <StyledPlayerInfo>
                          <WithUserMenu profile={item}>
                            <StyledMVPPlayer>
                              <AvatarWithRang avatar={item.imgUrl} />
                              <StyledMVPPlayerInfo>
                                <StyledNickname>{item.name}</StyledNickname>
                                <StyledPlayerStat>
                                  <TeamIcons
                                    group={
                                      teams?.indexOf(originLobbyId || '') ||
                                      null
                                    }
                                  />
                                  <StyledLike>{t('results.like')}</StyledLike>
                                  <StyledReport
                                    onClick={handlePlayerReport(item?.id || '')}
                                  >
                                    {t('results.report')}
                                  </StyledReport>
                                </StyledPlayerStat>
                              </StyledMVPPlayerInfo>
                            </StyledMVPPlayer>
                          </WithUserMenu>
                        </StyledPlayerInfo>
                        <div>{CSMatchProfileStat?.kill}</div>
                        <div>{CSMatchProfileStat?.dead}</div>
                        <div>{CSMatchProfileStat?.assist}</div>
                        <StyledChangedRaiting
                          $isNegative={Number(CSMatchProfileStat?.KDDiff) < 0}
                        >
                          {round(Number(CSMatchProfileStat?.KDDiff))}
                        </StyledChangedRaiting>
                        <div>{round(CSMatchProfileStat?.KD, 2)}</div>
                        <div>{round(CSMatchProfileStat?.ADR)}</div>
                        <div>{round(CSMatchProfileStat?.HS)}</div>
                        <div>{round(CSMatchProfileStat?.KAST)}</div>
                      </StyledTableRowMainItem>
                      <StyledTableItem isWinMVP={isWinMVP}>
                        {round(CSMatchProfileStat?.EF)}
                      </StyledTableItem>
                      <StyledTableItem isWinMVP={isWinMVP}>
                        {round(CSMatchProfileStat?.score)}
                      </StyledTableItem>
                      <StyledTableItem isWinMVP={isWinMVP}>
                        {Number(round(CSMatchProfileStat?.ratingDiff)) < 0
                          ? '-'
                          : '+'}
                        {Math.abs(
                          Number(round(CSMatchProfileStat?.ratingDiff))
                        )}
                      </StyledTableItem>
                      <StyledTableItem isWinMVP={isWinMVP}>
                        <StyledCoin />{' '}
                        {winner === 'RED' ? `+${match.bet}` : `-${match.bet}`}
                      </StyledTableItem>
                    </StyledTableRow>
                  )
                })}
            </StyledTableBody>
          </StyledTablePart>
          <StyledTablePart>
            <StyledTableBody>
              {match?.participants
                ?.filter((item) => item.teamColor === 'BLUE')
                .reduce(
                  (acc, item) =>
                    item.user?.id === blueMVP.user?.id
                      ? [item, ...acc]
                      : [...acc, item],
                  [] as DtoMatchParticipant[]
                )
                .map(({ user, originLobbyId }, index) => {
                  if (!user) return

                  const { CSMatchProfileStat, ...item } = user
                  const isMVP = blueMVP.user?.id === item.id
                  const isWin = winner === 'BLUE'
                  const isWinMVP = isMVP && isWin

                  return (
                    <StyledTableRow key={index}>
                      {isMVP && <StyledMVPLabel $isWin={isWin} />}
                      <StyledTableRowMainItem isWinMVP={isWinMVP}>
                        <StyledPlayerInfo>
                          <WithUserMenu profile={item}>
                            <StyledMVPPlayer>
                              <AvatarWithRang avatar={item.imgUrl} />
                              <StyledMVPPlayerInfo>
                                <StyledNickname>
                                  <NickTrimmer>{item.name}</NickTrimmer>
                                </StyledNickname>
                                <StyledPlayerStat>
                                  <TeamIcons
                                    group={
                                      teams?.indexOf(originLobbyId || '') ||
                                      null
                                    }
                                  />
                                  <StyledLike>{t('results.like')}</StyledLike>

                                  <StyledReport
                                    onClick={handlePlayerReport(item?.id || '')}
                                  >
                                    {t('results.report')}
                                  </StyledReport>
                                </StyledPlayerStat>
                              </StyledMVPPlayerInfo>
                            </StyledMVPPlayer>
                          </WithUserMenu>
                        </StyledPlayerInfo>
                        <div>{CSMatchProfileStat?.kill}</div>
                        <div>{CSMatchProfileStat?.dead}</div>
                        <div>{CSMatchProfileStat?.assist}</div>
                        <StyledChangedRaiting
                          $isNegative={Number(CSMatchProfileStat?.KDDiff) < 0}
                        >
                          {round(Number(CSMatchProfileStat?.KDDiff))}
                        </StyledChangedRaiting>
                        <div>{round(CSMatchProfileStat?.KD, 2)}</div>
                        <div>{round(CSMatchProfileStat?.ADR)}</div>
                        <div>{round(CSMatchProfileStat?.HS)}</div>
                        <div>{round(CSMatchProfileStat?.KAST)}</div>
                      </StyledTableRowMainItem>
                      <StyledTableItem isWinMVP={isWinMVP}>
                        {round(CSMatchProfileStat?.EF)}
                      </StyledTableItem>
                      <StyledTableItem isWinMVP={isWinMVP}>
                        {round(CSMatchProfileStat?.score)}
                      </StyledTableItem>
                      <StyledTableItem isWinMVP={isWinMVP}>
                        {Number(round(CSMatchProfileStat?.ratingDiff)) < 0
                          ? '-'
                          : '+'}
                        {Math.abs(
                          Number(round(CSMatchProfileStat?.ratingDiff))
                        )}
                      </StyledTableItem>
                      <StyledTableItem isWinMVP={isWinMVP}>
                        <StyledCoin />{' '}
                        {winner === 'BLUE' ? `+${match.bet}` : `-${match.bet}`}
                      </StyledTableItem>
                    </StyledTableRow>
                  )
                })}
            </StyledTableBody>
          </StyledTablePart>
        </StyledTableParts>
      </StyledRightPart>
    </StyledRoot>
  )
}

const StyledRoot = styled.div`
  height: 100%;
  display: flex;
  height: 100%;
  gap: 40px;
`

const StyledPlayerInfo = styled.div`
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  gap: 11px;
`

const StyledMVPPlayer = styled.div`
  display: flex;
  gap: 16px;
`

const StyledMVPPlayerInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const StyledNickname = styled(Header6)`
  text-align: left;
`

const StyledPlayerStat = styled.div`
  display: flex;
  gap: 16px;
`

const StyledRightPart = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: 21px;
`

const StyledTableParts = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: 32px;
`

interface WonMVPProps {
  isWinMVP: boolean
}

const StyledTableItem = styled(Header5)<WonMVPProps>`
  border: 1px solid rgba(255, 255, 255, 0.15);
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 62px;
  font-weight: 400;

  ${({ isWinMVP }) =>
    isWinMVP &&
    css`
      background: var(
        --GradientParty-Off,
        linear-gradient(
          0deg,
          rgba(44, 255, 128, 0.5) -2.76%,
          rgba(44, 255, 128, 0.14) 1.1%,
          rgba(44, 255, 128, 0) 50.25%
        ),
        rgba(27, 53, 36, 0.3)
      );
    `}
`

const StyledTableHead = styled.div`
  display: grid;
  grid-template-columns: 940px 77px repeat(3, 120px);
  align-items: center;
  gap: 8px;
`

const StyledTableHeadMainPart = styled.div`
  display: grid;
  grid-template-columns: 270px repeat(8, 70px);
  padding: 0 16px;
  gap: 11px;
`

const StyledTableRow = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 940px 77px repeat(3, 120px);
  gap: 8px;
`

const StyledTableRowMainItem = styled(StyledTableItem)`
  display: grid;
  grid-template-columns: 280px repeat(8, 70px);
  padding: 0 16px;
  gap: 11px;
  text-align: center;
`

const StyledTablePart = styled.div`
  display: flex;
  height: 326px;
`

const StyledTableHeadItem = styled(Text4)`
  color: rgba(255, 255, 255, 0.5);
  padding: 16px 0;
  text-align: center;
`

const StyledTableBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`

const StyledLike = styled(Text5)`
  color: #2cff80;
  cursor: pointer;
  position: relative;
  &:after {
    content: '';
    width: 100%;
    height: 0.5px;
    background-color: #2cff80;
    position: absolute;
    bottom: -1px;
    left: 0;
  }
  transition: 0.2s;
  &:hover {
    color: white;
    &:after {
      background-color: white;
    }
  }
`

const StyledReport = styled(Text5)`
  color: #f44;
  cursor: pointer;
  position: relative;
  &:after {
    content: '';
    width: 100%;
    height: 0.5px;
    background-color: #f44;
    position: absolute;
    bottom: -1px;
    left: 0;
  }
  transition: 0.2s;
  &:hover {
    color: white;
    &:after {
      background-color: white;
    }
  }
`

const StyledCoin = styled(Coin)`
  fill: #ffcd29;
  width: 17px;
  margin-right: 7.5px;
`

interface StyledTeamNameProps {
  $isWin: boolean
}

const StyledMVPLabel = styled.div<StyledTeamNameProps>`
  position: absolute;
  top: -1px;
  left: -43px;
  width: 49px;
  height: 62px;
  background-image: url(${({ $isWin }) => ($isWin ? MVPwonIcon : MVPlostIcon)});
`

interface StyledChangedRaiting {
  $isNegative?: boolean
}

const StyledChangedRaiting = styled.div<StyledChangedRaiting>`
  color: ${({ $isNegative }) =>
    $isNegative ? 'rgba(255, 68, 68, 1)' : 'rgba(255, 205, 41, 1)'};
`

const getMVP = (users: DtoMatchParticipant[]) => {
  return users.reduce((acc, participant) => {
    if (
      (acc.user?.CSMatchProfileStat?.kill || 0) <
      (participant.user?.CSMatchProfileStat?.kill || 0)
    ) {
      acc = participant
    }
    return acc
  }, users[0])
}

export default ResultsMatchScoreboard
