import { Header2 } from 'styles/typography'
import {
  getTotalStat,
  getUserPosition,
  setMainPageGameFormat,
} from 'stores/statisticStore'
import { logError } from 'utils/logger'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import NewsBlock from 'components/Home/NewsBlock'
import Standings from 'components/Home/Standings'
import StatisticsFTF from 'components/Home/StatisticsFTF'
import styled from 'styled-components'

const StyledRoot = styled.div`
  height: 900px;
  width: 1646px;

  display: grid;
  gap: 20px;
  grid-template-columns: 436px 1fr 450px;
`

const StyledSecondTitle = styled(Header2)`
  text-transform: uppercase;
  padding: 7px 0;

  display: flex;
  align-items: flex-end;
  justify-content: center;
`

const StyledStatisticWrapper = styled.div`
  display: flex;
  flex-direction: column;

  gap: 32px;
`

const StyledTopWrapper = styled.div`
  display: flex;
  flex-direction: column;

  gap: 32px;
`

const HomeCSPage = () => {
  const { t } = useTranslation()

  useEffect(() => {
    const getData = async () => {
      try {
        await getUserPosition()
        await getTotalStat()
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (e: any) {
        logError('Ошибка получения статистики на главной', e)
      }
    }

    setMainPageGameFormat('FIVE_TO_FIVE')

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    getData()
  }, [])

  return (
    <StyledRoot>
      <StyledStatisticWrapper>
        <StyledSecondTitle>{t('home.StatisticsFtf')}</StyledSecondTitle>
        <StatisticsFTF />
      </StyledStatisticWrapper>
      <NewsBlock />
      <StyledTopWrapper>
        <StyledSecondTitle>{t('home.standings')}</StyledSecondTitle>
        <Standings />
      </StyledTopWrapper>
    </StyledRoot>
  )
}

export default HomeCSPage
