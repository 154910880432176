import TicketDate from 'components/Judge/Ticket/TicketDate'
import TicketInfo from 'components/Judge/Ticket/TicketInfo'
import TicketStatistic from 'components/Judge/Ticket/TicketStatistic'
import VoteProcess from 'components/Judge/Ticket/VoteProcess'
import styled from 'styled-components'

const JudgeVoteTicket = () => {
  return (
    <>
      <VoteProcess />
      <StyledMainContent>
        <TicketDate />
        <StyledData>
          <TicketInfo />
          <TicketStatistic />
        </StyledData>
      </StyledMainContent>
    </>
  )
}
export default JudgeVoteTicket

const StyledMainContent = styled.div`
  display: flex;
  width: 100%;
  height: 546px;
  margin: 0px 0px 40px 0px;
`

const StyledData = styled.div`
  margin: 0px 0px 0px 17px;
  display: flex;
  flex-direction: column;
  width: 100%;
`
