import { Header1, Header6, SideHeader1 } from 'styles/typography'
import { styled } from 'styled-components'
import { useTranslation } from 'react-i18next'
import JudgeStarIcon from 'images/judge/JudgeStarIcon'

const TeamLeft = () => {
  const { t } = useTranslation()

  const mockMatch = {
    CSMatchResult: {
      winTeamScore: 16,
      loseTeamScore: 10,
      winTeamColor: 'RED',
    },
  }

  const match = mockMatch

  const winner = match.CSMatchResult?.winTeamColor
  const isRedWin = winner === 'RED'
  const isBlueWin = winner === 'BLUE'
  const isDraw = false

  return (
    <StyledLeftPart>
      <StyledRedTeamInfo $isWin={isRedWin}>
        <StyledTeamName $isWin={isRedWin}>TEAM RED</StyledTeamName>
        {isRedWin && <JudgeStarIcon />}
        <StyledScore $isWin={isRedWin}>
          {isRedWin
            ? match.CSMatchResult?.winTeamScore
            : match.CSMatchResult?.loseTeamScore}
        </StyledScore>
      </StyledRedTeamInfo>
      {isDraw && <StyledDrawLabel>{t('play.statistic.draw')}</StyledDrawLabel>}
      <StyledBlueTeamInfo $isWin={isBlueWin}>
        <StyledScore $isWin={isBlueWin}>
          {isBlueWin
            ? match.CSMatchResult?.winTeamScore
            : match.CSMatchResult?.loseTeamScore}
        </StyledScore>
        {isBlueWin && <JudgeStarIcon />}
        <StyledTeamName $isWin={isBlueWin}>TEAM BLUE</StyledTeamName>
      </StyledBlueTeamInfo>
    </StyledLeftPart>
  )
}

interface WinProps {
  $isWin: boolean
}

const StyledLeftPart = styled.div`
  padding: 67px 0px 14px 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: start;
  height: 100%;
  gap: 22px;
`

const StyledTeamInfo = styled.div<WinProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  height: 334px;
  padding-left: 27px;
  width: 110px;
  flex-shrink: 0;
  gap: 16px;
  &:last-child {
    justify-content: flex-start;
  }
`

const StyledRedTeamInfo = styled(StyledTeamInfo)`
  background:
    linear-gradient(
        to bottom right,
        #ff7a00 0%,
        rgba(255, 0, 0, 0.3) 40%,
        rgba(255, 0, 153, 0) 60%
      )
      bottom left / 17% 50% no-repeat,
    linear-gradient(
        to top right,
        #ff7a00 0%,
        rgba(255, 0, 0, 0.3) 40%,
        rgba(255, 0, 153, 0) 60%
      )
      top left / 17% 50% no-repeat;
`

const StyledBlueTeamInfo = styled(StyledTeamInfo)`
  background:
    linear-gradient(
        to bottom right,
        #00d1ff 0%,
        rgba(2, 72, 253, 0.56) 40%,
        rgba(0, 41, 255, 0) 60%
      )
      bottom left / 17% 50% no-repeat,
    linear-gradient(
        to top right,
        #00d1ff 0%,
        rgba(2, 72, 253, 0.56) 40%,
        rgba(0, 41, 255, 0) 60%
      )
      top left / 17% 50% no-repeat;
`

const StyledDrawLabel = styled(Header6)`
  position: absolute;
  border-radius: 6px 0px;
  background: var(--desktop-green, #2cff80);
  display: flex;
  width: 116px;
  padding: 6px 8px;
  justify-content: center;
  align-items: center;
  left: 10px;
  top: 50;

  font-weight: 400;
  color: var(--desktop-solid-black, #000);
`

interface WinProps {
  $isWin: boolean
}

const StyledScore = styled(Header1)<WinProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ $isWin }) => ($isWin ? '#ffcd29 ' : 'rgba(255, 255, 255, 0.2)')};
  text-align: center;
  flex-direction: column;
  text-transform: uppercase;
  line-height: 38px;
  margin: 20px 0px 0px 0px;
  &:nth-child(1) {
    margin: 0 auto;
    line-height: 100%;
  }
`

interface StyledTeamNameProps {
  $isWin: boolean
}

const StyledTeamName = styled(SideHeader1)<StyledTeamNameProps>`
  display: flex;
  writing-mode: vertical-rl;
  color: ${({ $isWin }) => ($isWin ? 'white' : 'rgba(255, 255, 255, 0.3)')};
  transform: rotate(180deg);
  justify-content: center;
  line-height: 90%;
`

export default TeamLeft
