const ChatIcon = () => (
  <svg
    width="20"
    fill="none"
    height="20"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="white"
      clipRule="evenodd"
      fillRule="evenodd"
      d="M0.833333 1.66666C0.373096 1.66666 0 2.03976 0 2.5V12.7794C0 13.2396 0.373096 13.6127 0.833333 13.6127H1.66656L1.66656 16.5888C1.66656 16.96 2.11537 17.1459 2.37786 16.8834L5.64855 13.6127H15.0947C15.5549 13.6127 15.928 13.2396 15.928 12.7794V2.5C15.928 2.03976 15.5549 1.66666 15.0947 1.66666H0.833333Z"
    />
    <path
      fill="white"
      clipRule="evenodd"
      fillRule="evenodd"
      d="M6.66797 15.6036H17.0878C17.548 15.6036 17.9211 15.2305 17.9211 14.7703V6.31227H18.5722C19.0324 6.31227 19.4055 6.68537 19.4055 7.1456V15.9005C19.4055 16.3608 19.0324 16.7339 18.5722 16.7339H17.5012V18.1606C17.5012 18.5319 17.0524 18.7178 16.7899 18.4553L15.0685 16.7339H7.5013C7.04107 16.7339 6.66797 16.3608 6.66797 15.9005V15.6036Z"
    />
  </svg>
)

export default ChatIcon
