import CheckboxEmpty from 'images/CheckboxEmpty'
import CheckboxFilled from 'images/CheckboxFilled'
import styled from 'styled-components'

interface StyledRootProps {
  $disabled?: boolean
}

const StyledRoot = styled.div<StyledRootProps>`
  display: flex;

  align-items: center;
  cursor: pointer;

  gap: 16px;

  opacity: ${({ $disabled }) => ($disabled ? '0.7' : '1')};
`

const StyledCheckbox = styled.div`
  transform: scale(1.3);
  margin-left: 2px;
`

interface CheckboxProps {
  isActive: boolean
  disabled?: boolean
  children: React.ReactNode
  onChange: (value: boolean) => void
}

const Checkbox: React.FC<CheckboxProps> = ({
  isActive,
  children,
  disabled,
  onChange,
}) => {
  const handleChange = (value: boolean) => () => {
    !disabled && onChange(value)
  }
  return (
    <StyledRoot $disabled={disabled} onClick={handleChange(!isActive)}>
      <StyledCheckbox>
        {isActive ? <CheckboxFilled /> : <CheckboxEmpty />}
      </StyledCheckbox>
      {children}
    </StyledRoot>
  )
}

export default Checkbox
