// eslint-disable-next-line @typescript-eslint/no-explicit-any
const CustomizedDot: React.FC = (props: any) => {
  const { cx, cy } = props

  return (
    <svg
      y={cy - 5}
      width="10"
      x={cx - 5}
      height="10"
      fill="none"
      viewBox="0 0 11 10"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="1" y="0.5" rx="4.5" width="9" height="9" stroke="#FFCD29" />
      <rect y="2" rx="3" x="2.5" width="6" height="6" fill="#FFCD29" />
    </svg>
  )
}

export default CustomizedDot
