// import { $token, getTokensFromStorage } from 'stores/authStore'
import { Navigate, useLocation } from 'react-router-dom'
// import { useEffect, useState } from 'react'
import { useStore } from '@nanostores/react'
// import Loading from 'components/Loading'
import routes from 'router/routes'

import { $userProfile } from 'stores/userStore'

const RequireAuth = ({ children }: { children: JSX.Element }) => {
  // const [isLoading, setIsLoading] = useState(true)
  const user = useStore($userProfile)
  const location = useLocation()

  // useEffect(() => {
  //   async function fetchData() {
  //     setIsLoading(true)
  //     await getTokensFromStorage()
  //     setIsLoading(false)
  //   }
  //   // eslint-disable-next-line @typescript-eslint/no-floating-promises
  //   fetchData()
  // }, [token])

  // if (isLoading) {
  //   return <Loading />
  // }

  if (!user) {
    return <Navigate replace to={routes.login()} state={{ from: location }} />
  }

  return children
}

export default RequireAuth
