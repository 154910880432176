const UserIcon = ({ color }: { color?: string; className?: string }) => (
  <svg
    width="12"
    fill="none"
    height="14"
    viewBox="0 0 12 14"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill={color ? color : '#2CFF80'}
      d="M6.42949 6.75707C8.05817 6.75707 9.37847 5.43677 9.37847 3.80809C9.37847 2.17941 8.05817 0.8591 6.42949 0.8591C4.8008 0.8591 3.4805 2.17941 3.4805 3.80809C3.4805 5.43677 4.8008 6.75707 6.42949 6.75707ZM10.3685 9.7537C11.4131 10.7983 12 12.2152 12 13.6925H6.42968L0.859375 13.6925C0.859375 12.2152 1.44625 10.7983 2.49088 9.7537C3.53552 8.70906 4.95235 8.12219 6.42968 8.12219C7.90702 8.12219 9.32385 8.70906 10.3685 9.7537Z"
    />
  </svg>
)

export default UserIcon
