const ButtonCorner = ({ className }: { className?: string }) => (
  <svg
    height="9"
    width="10"
    fill="none"
    id="buttonCorner"
    viewBox="0 0 10 9"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M9.99971 0L0.46582 9L6.99971 9C8.65657 9 9.99971 7.65685 9.99971 6L9.99971 0Z" />
  </svg>
)

export default ButtonCorner
