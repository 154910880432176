import { DtoBet } from 'api/schemas/dictionaryApi'
import { Header3, Header5 } from 'styles/typography'
import { t } from 'i18next'
import BlockIcon from 'images/BlockIcon'
import CheckboxEmpty from 'images/CheckboxEmpty'
import CheckboxFilled from 'images/CheckboxFilled'
import Coin from 'images/Coin'
import React from 'react'
import styled, { css } from 'styled-components'
// import СalibrationCard from 'components/Game/СalibrationCard'

type SettingsStatus = 'starting' | 'started' | 'preparing'

interface BetListProps {
  bets?: DtoBet[]
  disabled?: boolean
  multiple?: boolean
  currentBets: number[]
  status?: SettingsStatus
  maxAvailableBet?: number
  onChangeBet?: (values: number[]) => void
}

const BetList: React.FC<BetListProps> = ({
  multiple,
  disabled,
  bets = [],
  onChangeBet,
  currentBets,
  maxAvailableBet = 0,
  status = 'preparing',
}) => {
  if (!bets.length) return null

  const availableBets = bets
    .map(({ value = 0 }) => value)
    .filter((value) => value <= maxAvailableBet)

  const isAllSelected = currentBets.length === availableBets.length

  const handleChangeAll = (checked: boolean) => {
    if (disabled || !onChangeBet) return

    onChangeBet(checked ? availableBets : [])
  }

  const handleChangeBet = (value: number) => {
    if (maxAvailableBet < value || disabled || !onChangeBet) return

    const isActive = currentBets.includes(value)

    if (multiple) {
      const newValues = isActive
        ? currentBets.filter((v) => v !== value)
        : [...currentBets, value]

      return onChangeBet(newValues)
    }

    onChangeBet(isActive ? [] : [value])
  }

  return (
    <StyledBetWrapper data-tooltip-id="bet">
      <StyledHeader>
        <StyledLine />
        {multiple && (
          <>
            <StyledText>{t('play.all')}</StyledText>

            {isAllSelected ? (
              <StyledAllCheckboxFilled
                onClick={() => handleChangeAll(false)}
                color={
                  status === 'preparing' ? '#ffcd29' : 'rgba(44, 255, 128, 1)'
                }
              />
            ) : (
              <StyledAllCheckboxEmpty onClick={() => handleChangeAll(true)} />
            )}
          </>
        )}
      </StyledHeader>
      {/* <СalibrationCard allMathes={5} currentMatches={2} /> */}

      <StyledBetContainer>
        {bets.map(
          ({ value }, index) =>
            value !== undefined && (
              <StyledBetItem
                key={index}
                $status={status}
                $unavalible={maxAvailableBet < value}
                onClick={() => handleChangeBet(value)}
                // disabled={status !== 'preparing' || disabled}
                $isActive={Boolean(currentBets.includes(value))}
              >
                <StyledLeft>
                  <StyledCoin />
                  <div>{value}</div>
                </StyledLeft>

                <BetIcon
                  status={status}
                  disabled={maxAvailableBet < value}
                  isActive={currentBets.includes(value)}
                />
              </StyledBetItem>
            )
        )}
      </StyledBetContainer>
    </StyledBetWrapper>
  )
}

interface BetIconProps {
  disabled: boolean
  isActive: boolean
  status: SettingsStatus
}

const BetIcon = ({ status, disabled, isActive }: BetIconProps) => {
  if (status === 'started') return null

  if (disabled) {
    return <BlockIcon />
  }

  if (!isActive) {
    return <CheckboxEmpty />
  }

  return (
    <CheckboxFilled
      color={status === 'starting' ? 'rgba(44, 255, 128, 1)' : '#ffcd29'}
    />
  )
}

const getColor = (status: SettingsStatus) => {
  switch (status) {
    case 'preparing':
      return {
        border: '#ffcd29',
        hover: 'rgba(238, 157, 62, 0.1)',
        background: 'rgba(238, 157, 62, 0.15)',
      }
    case 'starting':
      return {
        border: '#2CFF80',
        hover: 'rgba(56, 204, 115, 0.30)',
        background: 'rgba(56, 204, 115, 0.10)',
      }
    case 'started':
      return {
        hover: 'rgba(44, 255, 128, 1)',
        border: 'rgba(44, 255, 128, 1)',
        background: 'rgba(44, 255, 128, 1)',
      }
  }
}

interface StyledBetWrapperProps {
  disabled?: boolean
}

const StyledBetWrapper = styled.div<StyledBetWrapperProps>`
  width: 268px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.8;
      cursor: default;
    `};
`

const StyledBetContainer = styled.div`
  background: rgba(5, 2, 2, 0.03);

  border: 1px solid rgba(255, 255, 255, 0.15);
  border-radius: 6px;
  overflow: hidden;
`

interface StyledBetItemProps {
  disabled?: boolean
  $isActive: boolean
  $unavalible?: boolean
  $status: SettingsStatus
}

const StyledBetItem = styled(Header3)<StyledBetItemProps>`
  display: flex;
  padding: 6px 12px;
  font-weight: 400;
  align-items: center;
  justify-content: space-between;
  border: 1px solid rgba(255, 255, 255, 0.1);

  height: 44px;

  transition: 0.2s;

  ${({ $status, $isActive, $unavalible }) => css`
    background-color: ${$isActive
      ? getColor($status).background
      : 'transparent'};

    color: ${$status === 'started' && $isActive ? 'black' : 'white'};
    fill: ${$status === 'started' && $isActive ? 'black' : '#ffcd29'};

    &:hover {
      background-color: ${$unavalible
        ? 'transparent'
        : $isActive
          ? getColor($status).hover
          : 'rgba(238, 157, 62, 0.15)'};
    }
    cursor: ${!$unavalible && 'pointer'};

    color: ${$unavalible && 'rgba(255, 255, 255, 0.15);'};
    fill: ${$unavalible && 'rgba(255, 255, 255, 0.15);'};
  `}

  &:last-child {
    border-radius: 0 0 6px 6px;
  }
  &:first-child {
    border-radius: 6px 6px 0 0;
  }

  ${({ $status, disabled, $isActive }) =>
    disabled &&
    css`
      cursor: default;
      &:hover {
        background-color: ${$isActive
          ? getColor($status).background
          : 'transparent'};
      }
    `}

  ${({ $status, $isActive }) =>
    $isActive &&
    css`
      border: 1px solid ${getColor($status).border} !important;
    `}
`

const StyledCoin = styled(Coin)`
  width: 17px;
  height: 17px;
`

const StyledLeft = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 15px;
`

const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  height: 24px;
  width: 100%;
`

const StyledLine = styled.div`
  width: 100%;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  margin-bottom: -6px;
`

const StyledText = styled(Header5)`
  color: rgba(255, 255, 255, 0.3);
  text-transform: uppercase;
`

const StyledAllCheckboxFilled = styled(CheckboxFilled)`
  cursor: pointer;
  min-width: 16px;
`

const StyledAllCheckboxEmpty = styled(CheckboxEmpty)`
  cursor: pointer;
  min-width: 16px;
`

export default BetList
