const LeaveIcon = ({ className }: { className?: string }) => (
  <svg
    width="24"
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      strokeWidth="2"
      stroke="inherit"
      strokeLinejoin="round"
      d="M12.9157 3H4C3.44772 3 3 3.44772 3 4V19.5C3 20.0523 3.44772 20.5 4 20.5H12.9157"
    />
    <path
      strokeWidth="2"
      stroke="inherit"
      strokeLinejoin="round"
      d="M9.35547 11.9535H20.1334"
    />
    <path
      strokeWidth="2"
      stroke="inherit"
      strokeLinejoin="round"
      d="M15.8223 7.47681L20.5646 11.9536L15.8223 16.4303"
    />
  </svg>
)

export default LeaveIcon
