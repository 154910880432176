import { Outlet } from 'react-router-dom'
import dota2BG from 'images/Background Dota2.webp'
import styled from 'styled-components'

const StyledWrapper = styled.div`
  position: absolute;
  z-index: 0;
  left: 0;
  bottom: 0;
  height: 100vh;
  width: 100vw;
  background-image: url('${dota2BG}');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`

const StyledContainer = styled.div`
  padding-top: 80px;
  padding-right: 96px;
  height: 100%;
`

const DotaLayout: React.FC = () => {
  return (
    <>
      <StyledWrapper>
        <StyledContainer>
          <Outlet />
        </StyledContainer>
      </StyledWrapper>
    </>
  )
}

export default DotaLayout
