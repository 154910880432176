import { styled } from 'styled-components'
import { useStore } from '@nanostores/react'
import Counter from 'components/Counter'

import { $activeNotificationAuthorID } from 'stores/chatStores/notificationsStore'
import { $tabActive } from 'stores/chatStores/chatStore'
import { getPartnerChat } from 'stores/chatStores/singleChat'
import WithUserMenu from 'components/ContextMenu/WithUserMenu'

interface StyledCarProps {
  nick: string
  avatar: string
  personId: string
  isActive: boolean
  haveUnreadMessage?: boolean
}
interface StyledCardWrapperProps {
  $isActive: boolean
}

const StyledItem = styled.div`
  position: relative;
`
const StyledCardWrapper = styled.div<StyledCardWrapperProps>`
  display: flex;
  align-items: center;
  border-radius: 3px;
  border: ${({ $isActive }) =>
    $isActive ? '1px solid #FFCD29' : '1px solid rgba(255, 255, 255, 0.15)'};
  background-color: ${({ $isActive }) => ($isActive ? '#FFCD29' : 'unset')};
  padding: 5px;
  cursor: pointer;
`
const StyledImage = styled.img`
  width: 30px;
  height: 30px;
`

const StyledText = styled.div`
  font-family: Ruberoid;
  font-size: 12px;
  font-weight: 600;
  margin-left: 7px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const StyledCounterWrapper = styled.div`
  margin-left: auto;
  width: fit-content;
`
const StyledRoot = styled.div``
const ChatPersonCard: React.FC<StyledCarProps> = ({
  nick,
  avatar,
  personId,
  isActive,
  haveUnreadMessage,
}) => {
  const profile = {
    name: nick,
    id: personId,
    imgUrl: avatar,
    isChosen: false,
    online: isActive,
  }
  const tabActive = useStore($tabActive)
  const openChat = async (personId: string) => {
    tabActive !== 'invite'
      ? await getPartnerChat(personId)
      : $activeNotificationAuthorID.set(personId)
  }
  return (
    <StyledRoot>
      <WithUserMenu disableLClick profile={{ ...profile }}>
        <StyledItem onClick={() => openChat(personId)}>
          <StyledCardWrapper $isActive={isActive}>
            <StyledImage src={avatar} />
            <StyledText>{nick}</StyledText>
            {haveUnreadMessage && (
              <StyledCounterWrapper>
                <Counter value={haveUnreadMessage} />
              </StyledCounterWrapper>
            )}
          </StyledCardWrapper>
        </StyledItem>
      </WithUserMenu>
    </StyledRoot>
  )
}

export default ChatPersonCard
