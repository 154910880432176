const Search = ({ onClick }: { onClick?: () => void }) => (
  <svg
    width="25"
    height="25"
    fill="none"
    onClick={onClick}
    viewBox="0 0 25 25"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="white"
      d="M22.2777 20.5273L16.191 14.4406C17.1355 13.2195 17.6465 11.7266 17.6465 10.1562C17.6465 8.27656 16.9129 6.51406 15.5863 5.18516C14.2598 3.85625 12.4926 3.125 10.6152 3.125C8.73789 3.125 6.9707 3.85859 5.64414 5.18516C4.31523 6.51172 3.58398 8.27656 3.58398 10.1562C3.58398 12.0336 4.31758 13.8008 5.64414 15.1273C6.9707 16.4562 8.73555 17.1875 10.6152 17.1875C12.1855 17.1875 13.6762 16.6766 14.8973 15.7344L20.984 21.8187C21.0018 21.8366 21.023 21.8508 21.0463 21.8604C21.0697 21.8701 21.0947 21.8751 21.1199 21.8751C21.1452 21.8751 21.1702 21.8701 21.1935 21.8604C21.2168 21.8508 21.238 21.8366 21.2559 21.8187L22.2777 20.7992C22.2956 20.7814 22.3098 20.7602 22.3194 20.7369C22.3291 20.7135 22.3341 20.6885 22.3341 20.6633C22.3341 20.638 22.3291 20.613 22.3194 20.5897C22.3098 20.5664 22.2956 20.5452 22.2777 20.5273ZM14.3277 13.8688C13.334 14.8602 12.0168 15.4062 10.6152 15.4062C9.21367 15.4062 7.89648 14.8602 6.90273 13.8688C5.91133 12.875 5.36523 11.5578 5.36523 10.1562C5.36523 8.75469 5.91133 7.43516 6.90273 6.44375C7.89648 5.45234 9.21367 4.90625 10.6152 4.90625C12.0168 4.90625 13.3363 5.45 14.3277 6.44375C15.3191 7.4375 15.8652 8.75469 15.8652 10.1562C15.8652 11.5578 15.3191 12.8773 14.3277 13.8688Z"
    />
  </svg>
)

export default Search
