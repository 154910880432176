const Invite = () => (
  <svg
    width="20"
    fill="none"
    height="21"
    viewBox="0 0 20 21"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="white"
      clipRule="evenodd"
      fillRule="evenodd"
      d="M0.832031 5.79953V16.8833H19.1654V5.80032L10.5883 14.3773C10.2629 14.7028 9.73527 14.7028 9.40984 14.3773L0.832031 5.79953ZM17.4063 4.96663H2.59185L9.40984 11.7846C9.73527 12.11 10.2629 12.11 10.5883 11.7846L17.4063 4.96663Z"
    />
  </svg>
)

export default Invite
