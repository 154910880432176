import { Header5 } from 'styles/typography'
import styled from 'styled-components'

interface StyledRootProps {
  $disabled?: boolean
}

const StyledRoot = styled.div<StyledRootProps>`
  border-radius: 0px 6px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  opacity: ${({ $disabled }) => ($disabled ? '0.8' : '1')};
  overflow: hidden;
  width: max-content;
`

interface StyledItemProps {
  $padding?: string
  $isActive: boolean
}

const StyledItem = styled(Header5)<StyledItemProps>`
  display: flex;
  align-items: center;

  text-transform: uppercase;

  background: ${({ $isActive }) =>
    $isActive
      ? 'linear-gradient(180deg, rgba(255, 255, 255, 0.20) 0%, rgba(152, 0, 0, 0.10) 55.21%, rgba(152, 0, 0, 0.00) 100%), #FFCD29'
      : 'rgba(255, 255, 255, 0.03)'};
  color: ${({ $isActive }) => ($isActive ? 'black' : 'white')};
  padding: ${({ $padding }) => ($padding ? $padding : '10px 12px')};

  transition: 0.3s;

  &:hover {
    background: ${({ $isActive }) =>
      $isActive
        ? 'linear-gradient(180deg, rgba(255, 255, 255, 0.20) 0%, rgba(152, 0, 0, 0.10) 55.21%, rgba(152, 0, 0, 0.00) 100%), rgba(255, 205, 41, 0.8)'
        : 'rgba(238, 157, 62, 0.15)'};
  }

  cursor: pointer;
`

interface ToggleProps<T> {
  value: T
  items: T[]
  padding?: string
  disabled?: boolean
  onChange: (value: T) => void
}

const Toggle = <T,>({
  items,
  value,
  padding,
  onChange,
  disabled,
}: ToggleProps<T>) => {
  const handleChange = (value: T) => () => {
    !disabled && onChange(value)
  }
  return (
    <StyledRoot $disabled={disabled}>
      {items.map((item) => (
        <StyledItem
          key={`${item}`}
          $padding={padding}
          $isActive={value === item}
          onClick={handleChange(item)}
        >
          {String(item)}
        </StyledItem>
      ))}
    </StyledRoot>
  )
}

export default Toggle
