import { Outlet } from 'react-router-dom'
import authBG from 'images/authBG.webp'
import styled from 'styled-components'

const StyledWrapper = styled.div`
  position: absolute;
  z-index: 0;
  left: 0;
  bottom: 0;
  height: 100vh;
  width: 100vw;
  background-image: url('${authBG}');
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: center;
`

const StyledWindowLine = styled.div`
  width: 100%;
  height: 31px;
  background: rgba(29, 30, 34, 1);
  position: relative;
  z-index: 99999;
  -webkit-app-region: drag;
`

const AuthLayout: React.FC = () => {
  return (
    <>
      <StyledWindowLine />
      <StyledWrapper>
        <Outlet />
      </StyledWrapper>
    </>
  )
}

export default AuthLayout
