import { PlacesType } from 'react-tooltip'

import { INFO_TOOLTIP_ID } from 'components/Tooltip/Info/InfoTooltipContainer'
import Question from 'images/judge/Question'

interface TooltipProps {
  text: string
  place?: PlacesType
}

const InfoTooltipIcon = ({ text, place }: TooltipProps) => (
  <div
    data-tooltip-id={INFO_TOOLTIP_ID}
    data-tooltip-content={text}
    data-tooltip-place={place}
  >
    <Question />
  </div>
)

export default InfoTooltipIcon
