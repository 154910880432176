import { $currenGame } from 'stores/appStore'
import { useStore } from '@nanostores/react'

interface GameResolverProps {
  cs: JSX.Element
  dota: JSX.Element
}

const GameResolver: React.FC<GameResolverProps> = (props) => {
  const currenGame = useStore($currenGame)

  return props[currenGame]
}

export default GameResolver
