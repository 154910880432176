import styled from 'styled-components'

interface ProgressBarProps {
  className?: string
  progress: number
}

const ProgressBar: React.FC<ProgressBarProps> = ({ progress, className }) => {
  return (
    <StyledRoot className={className}>
      <StyledFiller $progress={progress} />
    </StyledRoot>
  )
}

export default ProgressBar

interface StyledFillerProps {
  $progress: ProgressBarProps['progress']
}

const StyledFiller = styled.div<StyledFillerProps>`
  position: absolute;

  width: ${({ $progress }) => `${$progress}%`};

  height: 4px;
  background: #2cff80;

  border-radius: 2px;
`

const StyledRoot = styled.div`
  position: relative;

  width: 100%;
  height: 4px;
  background: rgba(255, 255, 255, 0.15);

  border-radius: 2px;
`
