import { ReactNode, memo, useId } from 'react'
import { Text6 } from 'styles/typography'
import { Tooltip } from 'react-tooltip'
import styled from 'styled-components'

interface WithTooltipProps {
  text: string
  title?: string
  children: ReactNode
}

const WithTooltip = ({ text, title, children }: WithTooltipProps) => {
  const id = useId()

  return (
    <>
      <StyledTooltip
        id={id}
        render={() => (
          <>
            {!!title && <StyledTitle>[{title}]</StyledTitle>}
            <Text6>{text}</Text6>
          </>
        )}
      />
      <a data-tooltip-id={id}>{children}</a>
    </>
  )
}

const StyledTooltip = styled(Tooltip)`
  max-width: 200px !important;

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 8px !important;
  gap: 10px;

  background: rgba(255, 255, 255, 0.03);
  border: 0.5px solid rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(7.5px);
  border-radius: 3px 0px;

  z-index: 9999;
  & .react-tooltip-arrow {
    display: none;
  }
`

const StyledTitle = styled(Text6)`
  color: #2cff80;
`

export default memo(WithTooltip)
