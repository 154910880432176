/* eslint-disable @typescript-eslint/no-explicit-any */
import { setMatch } from 'stores/lobbyStore'
import gameEndedSound from 'sounds/game-end.wav'
import gamePreparedSound from 'sounds/game-preparing.wav'
import playSound from 'utils/sound'

const gamePrepared = new Audio(gamePreparedSound)
const gameEnded = new Audio(gameEndedSound)

const matchListiner = async (data: any) => {
  if (data.entityAction === 'CREATE') {
    setMatch(data.data)
  }
  if (data.entityAction === 'UPDATE') {
    setMatch(data.data)
  }
  if (data.matchCreateStatus === 'MATCH_PREPARED') {
    await playSound(gamePrepared)
  }
  if (data.matchCreateStatus === 'MATCH_PROCESSING_COMPLETED') {
    await playSound(gameEnded)
  }
}

export default matchListiner
