import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { DtoClaimPreview } from 'api/schemas/judgeApi'
import { Header2, Text3 } from 'styles/typography'
import ClaimsTableRow from 'components/ClaimsTable/ClaimsTableRow'

interface ClaimsTableBodyProps {
  data: DtoClaimPreview[]
  onClick: (claim: DtoClaimPreview) => void
}

const ClaimsTableBody = ({ data, onClick }: ClaimsTableBodyProps) => {
  const { t } = useTranslation()

  if (!data.length)
    return (
      <StyledEmptyPlaceholder>
        <StyledPlaceholderTitle>
          {t('common.emptyTable')}
        </StyledPlaceholderTitle>
        <StyledPlaceholderDescription>
          {t('judge.myTickets.emptyDescription')}
        </StyledPlaceholderDescription>
      </StyledEmptyPlaceholder>
    )

  return (
    <StyledTableBody>
      {data.map((claim) => (
        <ClaimsTableRow
          key={claim.id}
          claim={claim}
          onClick={() => onClick(claim)}
        />
      ))}
    </StyledTableBody>
  )
}

const StyledTableBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 32px;
`

const StyledEmptyPlaceholder = styled.div`
  height: 240px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
`

const StyledPlaceholderTitle = styled(Header2)`
  text-transform: uppercase;
`

const StyledPlaceholderDescription = styled(Text3)`
  color: rgba(255, 255, 255, 0.4);
  text-align: center;

  font-style: normal;
  font-weight: 400;
  line-height: 100%;
`

export default ClaimsTableBody
