import { useEffect, useState } from 'react'
import { useStore } from '@nanostores/react'

import { $currentJudgeClaim, getJudgeClaimCurrent } from 'stores/judgeStore'
import {
  EnumCreateJudgeClaimStatus,
  EnumJudgeClaimStatus,
} from 'api/schemas/supportApi'
import JudgeMyWork from 'components/Judge/Work/JudgeMyWork'
import JudgeWelcome from 'components/Judge/Welcome/JudgeWelcome'
import Loading from 'components/Loading'

const JudgeWorkPage = () => {
  const currentJudgeClaim = useStore($currentJudgeClaim)

  const isApproved =
    currentJudgeClaim?.status ===
    EnumJudgeClaimStatus.SupportJudgeClaimStatusApproved

  const needUpdate =
    currentJudgeClaim?.createStatus ===
      EnumCreateJudgeClaimStatus.CreateJudgeClaimStatusCompleted && !isApproved

  const [isLoading, setIsLoading] = useState(needUpdate)

  useEffect(() => {
    const refetchJudgeClaim = async () => {
      try {
        if (needUpdate) {
          await getJudgeClaimCurrent()
        }
      } finally {
        setIsLoading(false)
      }
    }

    void refetchJudgeClaim()
  }, [needUpdate])

  if (isLoading) {
    return <Loading />
  }

  if (isApproved) {
    return <JudgeMyWork />
  }

  return <JudgeWelcome claim={currentJudgeClaim} />
}

export default JudgeWorkPage
