import {
  $currentOnline,
  $onlineChangePercent,
  $totalMatches,
  $totalReward,
} from 'stores/statisticStore'
import { Header2, Header5, Header6, Header9 } from 'styles/typography'
import { useStore } from '@nanostores/react'
import { useTranslation } from 'react-i18next'
import Coin from 'images/Coin'
import OnlineChart from 'components/Home/OnlineChart'
import styled from 'styled-components'

const StyledWrapper = styled.div`
  border-radius: 6px;
  border: 1px solid rgba(255, 255, 255, 0.15);
  background: radial-gradient(
    121.38% 134.39% at 90.55% 100%,
    rgba(41, 101, 255, 0.2) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  background-color: rgba(255, 255, 255, 0.03);
  bacgrkound-color: rgba(255, 255, 255, 0.03);
  backdrop-filter: blur(7.5px);
  height: 457px;
  padding: 32px 16px;
`
const StyledTopText = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`
const StyledOnlineWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`
const StyledCaption = styled(Header5)`
  color: white;
  line-height: 100%;
  text-transform: uppercase;
`
const StyledDescription = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
`
const StyledDescriptionText = styled(Header9)`
  line-height: 115.5%;
  opacity: 0.5;
  text-transform: uppercase;
`
interface isDecrease {
  $isDecrease: boolean
}

const StyledPercent = styled(Header6)<isDecrease>`
  line-height: 16px;
  color: ${({ $isDecrease }) => ($isDecrease ? '#F44' : '#2cff80')};
  text-transform: uppercase;
`
const StyledAllOnline = styled(Header2)`
  color: white;
  line-height: 90%;
`
const StyledCoin = styled(Coin)`
  fill: #ffcd29;
  width: 28px;
  height: 33px;
`
const StyledTotalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-top: 60px;
`
const StyledTotalItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const StyledTotalItemLeft = styled(Header5)`
  color: white;
  line-height: 100%;
  text-transform: uppercase;
`
const StyledTotalItemRight = styled(Header2)`
  display: flex;
  align-items: center;
  gap: 7px;
  color: white;
  line-height: 90%;
  text-transform: uppercase;
`

const HomeSchedule: React.FC = () => {
  const { t } = useTranslation()
  const currentOnline = useStore($currentOnline)
  const totalMatches = useStore($totalMatches)
  const totalReward = useStore($totalReward)
  const onlineChangePercent = useStore($onlineChangePercent)

  return (
    <StyledWrapper>
      <StyledTopText>
        <StyledOnlineWrapper>
          <StyledCaption>{t('home.currentOnline')}</StyledCaption>
          <StyledDescription>
            <StyledDescriptionText>{t('home.lastTime')}</StyledDescriptionText>
            <StyledPercent $isDecrease={onlineChangePercent < 0}>{`${
              onlineChangePercent >= 0 ? '+' : '-'
            } ${Math.abs(onlineChangePercent)}%`}</StyledPercent>
          </StyledDescription>
        </StyledOnlineWrapper>
        <StyledAllOnline>{currentOnline}</StyledAllOnline>
      </StyledTopText>
      <OnlineChart />
      <StyledTotalWrapper>
        <StyledTotalItem>
          <StyledTotalItemLeft>{t('home.totalMatches')}</StyledTotalItemLeft>
          <StyledTotalItemRight>{totalMatches}</StyledTotalItemRight>
        </StyledTotalItem>
        <StyledTotalItem>
          <StyledTotalItemLeft>{t('home.totalReward')}</StyledTotalItemLeft>
          <StyledTotalItemRight>
            <StyledCoin />
            {totalReward}
          </StyledTotalItemRight>
        </StyledTotalItem>
      </StyledTotalWrapper>
    </StyledWrapper>
  )
}

export default HomeSchedule
