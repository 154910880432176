const RedStatusLine = ({ className }: { className?: string }) => (
  <svg
    width="2"
    fill="none"
    height="47"
    viewBox="0 0 2 47"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="#FF452C"
      d="M0 0L1.42072 1.43388C1.79181 1.80841 2 2.31432 2 2.84156V43.3955C2 43.7905 1.88304 44.1767 1.66388 44.5053L0 47L0 0Z"
    />
    <path
      fill-opacity="0.4"
      fill="url(#paint0_radial_7430_145553)"
      d="M0 0L1.42072 1.43388C1.79181 1.80841 2 2.31432 2 2.84156V43.3955C2 43.7905 1.88304 44.1767 1.66388 44.5053L0 47L0 0Z"
    />
    <path
      stroke-width="0.5"
      stroke-opacity="0.7"
      stroke="url(#paint1_linear_7430_145553)"
      d="M0.25 46.1746V0.607511L1.24313 1.60984C1.56783 1.93755 1.75 2.38023 1.75 2.84156V43.3955C1.75 43.7411 1.64766 44.079 1.45589 44.3665L0.25 46.1746Z"
    />
    <defs>
      <radialGradient
        r="1"
        cy="0"
        cx="0"
        gradientUnits="userSpaceOnUse"
        id="paint0_radial_7430_145553"
        gradientTransform="translate(-2.5 54.7971) rotate(-50.183) scale(35.9185 35.3323)"
      >
        <stop stop-color="#FFD600" />
        <stop offset="1" stop-opacity="0" stop-color="#FFC555" />
      </radialGradient>
      <linearGradient
        x2="1"
        y1="0"
        x1="1"
        y2="47"
        gradientUnits="userSpaceOnUse"
        id="paint1_linear_7430_145553"
      >
        <stop stop-opacity="0.7" />
        <stop offset="0.465" stop-opacity="0" stop-color="#666666" />
        <stop offset="1" stop-opacity="0.24" />
      </linearGradient>
    </defs>
  </svg>
)

export default RedStatusLine
