import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import styled from 'styled-components'

import { DtoJudgeClaim, EnumJudgeClaimStatus } from 'api/schemas/supportApi'
import { Header6 } from 'styles/typography'
import { markDeclinedJudgeClaimAsViewed } from 'stores/judgeStore'
import JudgeApplicationRejected from 'components/Judge/JudgeApplicationRejected'
import Modal from 'components/Modal'

interface JudgeApplicationWizardProps {
  claim: DtoJudgeClaim
}

const JudgeApplicationWizard = ({ claim }: JudgeApplicationWizardProps) => {
  const { t } = useTranslation()

  const isDeclined =
    claim.status! == EnumJudgeClaimStatus.SupportJudgeClaimStatusDeclined
  const [isModalOpen, setIsModalOpen] = useState(
    isDeclined && !claim.declinedAtViewed
  )

  const declinedAt = useMemo(
    () => dayjs(claim.declinedAt).format('DD/MM/YYYY'),
    [claim.declinedAt]
  )
  const reapplyAt = useMemo(
    () => dayjs(claim.deleteAfter).format('DD/MM/YYYY'),
    [claim.deleteAfter]
  )
  const currentStage = mapStatusToStage[claim.status!]

  const handleCloseModal = () => {
    setIsModalOpen(false)

    // фиксируем факт ознакомления с отклоненной заявкой
    // и больше не открываем модалку при открытии страницы
    if (!claim.declinedAtViewed) {
      void markDeclinedJudgeClaimAsViewed(claim.id!)
    }
  }

  const handleStageClick = (stageId: number) => {
    if (stageId === 3 && isDeclined) {
      setIsModalOpen(true)
    }
  }

  const stages = [
    {
      id: 1,
      title: t('judge.welcomePage.judgeClaimSentTitle'),
    },
    {
      id: 2,
      title: t('judge.welcomePage.judgeClaimInProgressTitle'),
    },
    {
      id: 3,
      title: t('judge.welcomePage.judgeClaimReadyTitle'),
    },
  ]

  return (
    <StyledWrapper>
      <StyledStagesWrapper>
        {stages.map(({ id, title }) => {
          const isPast = id <= currentStage
          const isActive = id === currentStage

          return (
            <StyledStageContainer key={id}>
              <StyledStageButton onClick={() => handleStageClick(id)}>
                <ProgressIndicator isActive={isActive} />
                <StyledTitle isPast={isPast}>{title}</StyledTitle>
              </StyledStageButton>

              <StyledProgressTrack isActive={isActive} />
            </StyledStageContainer>
          )
        })}
      </StyledStagesWrapper>

      <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
        <JudgeApplicationRejected
          declinedAt={declinedAt}
          reapplyAt={reapplyAt}
        />
      </Modal>
    </StyledWrapper>
  )
}

const mapStatusToStage: Record<EnumJudgeClaimStatus, number> = {
  NEW: 1,
  APPROVED: 3,
  DECLINED: 3,
  PREPARING: 1,
  IN_PROGRESS: 2,
}

const StyledWrapper = styled.div`
  position: relative;
  margin: 16px 0;
  width: 1630px;
  height: 58px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 8px;
  align-self: stretch;
`

const StyledStagesWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  gap: 8px;
`

const StyledStageContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const StyledStageButton = styled.div`
  display: flex;
  padding: 6px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  align-self: stretch;

  border-radius: 3px 3px 0px 0px;
  border: 1px solid rgba(255, 255, 255, 0.15);
  background: rgba(255, 255, 255, 0.03);
`

interface ProgressIndicatorProps {
  isActive: boolean
}

const ProgressIndicator = styled.div<ProgressIndicatorProps>`
  width: 6px;
  height: 6px;
  background: ${({ isActive }) =>
    isActive ? '#2cff80' : 'rgba(255, 255, 255, 0.15)'};
  border-radius: 50%;
`

interface StyledTitleProps {
  isPast: boolean
}

const StyledTitle = styled(Header6)<StyledTitleProps>`
  color: ${({ isPast }) => (isPast ? '#fff' : 'rgba(255, 255, 255, 0.15)')};
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  text-transform: uppercase;
`

interface StyledProgressTrackProps {
  isActive: boolean
}

const StyledProgressTrack = styled.div<StyledProgressTrackProps>`
  width: 100%;
  height: 3px;
  background: ${({ isActive }) =>
    isActive ? '#2cff80' : 'rgba(255, 255, 255, 0.15)'};
  border-radius: 1px;
`

export default JudgeApplicationWizard
