/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface DtoAuthTokensResponse {
  accessToken?: string
  refreshToken?: string
}

export interface DtoCodeVerificationRequest {
  login?: string
  verificationCode?: number
}

export interface DtoPasswordForgetRequest {
  login?: string
  password?: string
  verificationCode?: number
}

export interface DtoSignUpRequest {
  login?: string
  password?: string
  verificationCode?: number
}

export interface DtoSingInRequest {
  login?: string
  password?: string
  totpCode?: string
}

export interface DtoTotpGenerateRequest {
  userId?: string
}

export interface DtoTotpGenerateResponse {
  codes?: string[]
  url?: string
}

export interface DtoTotpRecoveryRequest {
  code?: string
  login?: string
  password?: string
}

export interface DtoTotpRemoveRequest {
  userId?: string
}

export interface DtoTotpValidateRequest {
  code?: string
  userId?: string
}

export interface ResponseResponse {
  data?: any
  meta?: ResponseMeta
  pagination?: ResponsePagination
}

export interface ResponseMeta {
  code?: string
  error?: string
  message?: string
  requestId?: string
  traceId?: string
}

export interface ResponsePagination {
  page?: number
  pages?: number
  perPage?: number
}

export type GetRefreshError = ResponseResponse

export type GetVerifyError = ResponseResponse

export type GetSendEmailCodeError = ResponseResponse

export type GetSendPhoneCodeError = ResponseResponse

export type PostVerifyCodeError = ResponseResponse

export type DeleteLogoutError = ResponseResponse

export type PostSignInError = ResponseResponse

export type PostSignUpError = ResponseResponse

export type PostGenerateError = ResponseResponse

export type PostRecoveryError = ResponseResponse

export type PostRemoveError = ResponseResponse

export type PostValidateError = ResponseResponse

export type PostPasswordForgetError = ResponseResponse

export namespace AccessToken {
  /**
   * @description Refresh access token by refresh token
   * @tags Auth
   * @name GetRefresh
   * @summary Refresh access token
   * @request GET:/accessToken/refresh
   */
  export namespace GetRefresh {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {
      /**
       * Access token
       * @default "Bearer <token>"
       */
      Authorization: string
      /**
       * Refresh token
       * @default "Bearer <token>"
       */
      'X-Refresh-Token': string
      /** Request id identity */
      'X-Request-Id': string
    }
    export type ResponseBody = ResponseResponse
  }

  /**
   * @description Verify access token
   * @tags Auth
   * @name GetVerify
   * @summary Verify access token
   * @request GET:/accessToken/verify
   */
  export namespace GetVerify {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {
      /**
       * Access token
       * @default "Bearer <token>"
       */
      Authorization: string
      /** Request id identity */
      'X-Request-Id': string
    }
    export type ResponseBody = ResponseResponse
  }
}

export namespace Code {
  /**
   * @description Send code on email
   * @tags Auth
   * @name GetSendEmailCode
   * @summary Send email code
   * @request GET:/code/sendEmailCode
   */
  export namespace GetSendEmailCode {
    export type RequestParams = {}
    export type RequestQuery = {
      /** email */
      email: string
    }
    export type RequestBody = never
    export type RequestHeaders = {
      /** Request id identity */
      'X-Request-Id': string
    }
    export type ResponseBody = ResponseResponse
  }

  /**
   * @description Send phone number code
   * @tags Auth
   * @name GetSendPhoneCode
   * @summary Send phone number code
   * @request GET:/code/sendPhoneCode
   */
  export namespace GetSendPhoneCode {
    export type RequestParams = {}
    export type RequestQuery = {
      /** user phone number */
      phoneNumber: string
    }
    export type RequestBody = never
    export type RequestHeaders = {
      /** Request id identity */
      'X-Request-Id': string
    }
    export type ResponseBody = ResponseResponse
  }

  /**
   * @description Verify Hash from email or sms
   * @tags Auth
   * @name PostVerifyCode
   * @summary Verify Hash
   * @request POST:/code/verifyCode
   */
  export namespace PostVerifyCode {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = DtoCodeVerificationRequest
    export type RequestHeaders = {
      /** Request id identity */
      'X-Request-Id': string
    }
    export type ResponseBody = ResponseResponse
  }
}

export namespace Logout {
  /**
   * @description User Registration
   * @tags Auth
   * @name DeleteLogout
   * @summary Registration
   * @request DELETE:/logout
   */
  export namespace DeleteLogout {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {
      /** Request id identity */
      'X-Request-Id': string
    }
    export type ResponseBody = ResponseResponse
  }
}

export namespace SignIn {
  /**
   * @description User Authorization
   * @tags Auth
   * @name PostSignIn
   * @summary signIn
   * @request POST:/signIn
   */
  export namespace PostSignIn {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = DtoSingInRequest
    export type RequestHeaders = {
      /** Request id identity */
      'X-Request-Id': string
    }
    export type ResponseBody = ResponseResponse & {
      data?: DtoAuthTokensResponse
    }
  }
}

export namespace SignUp {
  /**
   * @description User Registration
   * @tags Auth
   * @name PostSignUp
   * @summary Registration
   * @request POST:/signUp
   */
  export namespace PostSignUp {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = DtoSignUpRequest
    export type RequestHeaders = {
      /** Request id identity */
      'X-Request-Id': string
    }
    export type ResponseBody = ResponseResponse
  }
}

export namespace Totp {
  /**
   * @description User 2FA
   * @tags Totp
   * @name PostGenerate
   * @summary Totp
   * @request POST:/totp/generate
   */
  export namespace PostGenerate {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = DtoTotpGenerateRequest
    export type RequestHeaders = {}
    export type ResponseBody = ResponseResponse & {
      data?: DtoTotpGenerateResponse
    }
  }

  /**
   * @description User 2FA
   * @tags Totp
   * @name PostRecovery
   * @summary Totp
   * @request POST:/totp/recovery
   */
  export namespace PostRecovery {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = DtoTotpRecoveryRequest
    export type RequestHeaders = {}
    export type ResponseBody = ResponseResponse & {
      data?: DtoAuthTokensResponse
    }
  }

  /**
   * @description User 2FA
   * @tags Totp
   * @name PostRemove
   * @summary Totp
   * @request POST:/totp/remove
   */
  export namespace PostRemove {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = DtoTotpRemoveRequest
    export type RequestHeaders = {}
    export type ResponseBody = void
  }

  /**
   * @description User 2FA
   * @tags Totp
   * @name PostValidate
   * @summary Totp
   * @request POST:/totp/validate
   */
  export namespace PostValidate {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = DtoTotpValidateRequest
    export type RequestHeaders = {}
    export type ResponseBody = void
  }
}

export namespace User {
  /**
   * @description Restore User Password
   * @tags Auth
   * @name PostPasswordForget
   * @summary Password Restore
   * @request POST:/user/password/forget
   */
  export namespace PostPasswordForget {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = DtoPasswordForgetRequest
    export type RequestHeaders = {
      /** Request id identity */
      'X-Request-Id': string
    }
    export type ResponseBody = ResponseResponse
  }
}

import type { AxiosInstance, AxiosRequestConfig, HeadersDefaults, ResponseType } from 'axios'
import axios from 'axios'

export type QueryParamsType = Record<string | number, any>

export interface FullRequestParams extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean
  /** request path */
  path: string
  /** content type of request body */
  type?: ContentType
  /** query params */
  query?: QueryParamsType
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType
  /** request body */
  body?: unknown
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
  securityWorker?: (
    securityData: SecurityDataType | null
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void
  secure?: boolean
  format?: ResponseType
}

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance
  private securityData: SecurityDataType | null = null
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker']
  private secure?: boolean
  private format?: ResponseType

  constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || '//localhost:8080/f2f/auth/api/v1' })
    this.secure = secure
    this.format = format
    this.securityWorker = securityWorker
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data
  }

  protected mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method)

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method && this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    }
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === 'object' && formItem !== null) {
      return JSON.stringify(formItem)
    } else {
      return `${formItem}`
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    if (input instanceof FormData) {
      return input
    }
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key]
      const propertyContent: any[] = property instanceof Array ? property : [property]

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem))
      }

      return formData
    }, new FormData())
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<T> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {}
    const requestParams = this.mergeRequestParams(params, secureParams)
    const responseFormat = format || this.format || undefined

    if (type === ContentType.FormData && body && body !== null && typeof body === 'object') {
      body = this.createFormData(body as Record<string, unknown>)
    }

    if (type === ContentType.Text && body && body !== null && typeof body !== 'string') {
      body = JSON.stringify(body)
    }

    return this.instance
      .request({
        ...requestParams,
        headers: {
          ...(requestParams.headers || {}),
          ...(type ? { 'Content-Type': type } : {}),
        },
        params: query,
        responseType: responseFormat,
        data: body,
        url: path,
      })
      .then((response) => response.data)
  }
}

/**
 * @title Authorization API
 * @version 1.0
 * @baseUrl //localhost:8080/f2f/auth/api/v1
 * @contact Mikhel Alexander
 *
 * This is authorization api service.
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  accessToken = {
    /**
     * @description Refresh access token by refresh token
     *
     * @tags Auth
     * @name GetRefresh
     * @summary Refresh access token
     * @request GET:/accessToken/refresh
     */
    getRefresh: (params: RequestParams = {}) =>
      this.request<ResponseResponse, GetRefreshError>({
        path: `/accessToken/refresh`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Verify access token
     *
     * @tags Auth
     * @name GetVerify
     * @summary Verify access token
     * @request GET:/accessToken/verify
     */
    getVerify: (params: RequestParams = {}) =>
      this.request<ResponseResponse, GetVerifyError>({
        path: `/accessToken/verify`,
        method: 'GET',
        format: 'json',
        ...params,
      }),
  }
  code = {
    /**
     * @description Send code on email
     *
     * @tags Auth
     * @name GetSendEmailCode
     * @summary Send email code
     * @request GET:/code/sendEmailCode
     */
    getSendEmailCode: (
      query: {
        /** email */
        email: string
      },
      params: RequestParams = {}
    ) =>
      this.request<ResponseResponse, GetSendEmailCodeError>({
        path: `/code/sendEmailCode`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * @description Send phone number code
     *
     * @tags Auth
     * @name GetSendPhoneCode
     * @summary Send phone number code
     * @request GET:/code/sendPhoneCode
     */
    getSendPhoneCode: (
      query: {
        /** user phone number */
        phoneNumber: string
      },
      params: RequestParams = {}
    ) =>
      this.request<ResponseResponse, GetSendPhoneCodeError>({
        path: `/code/sendPhoneCode`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * @description Verify Hash from email or sms
     *
     * @tags Auth
     * @name PostVerifyCode
     * @summary Verify Hash
     * @request POST:/code/verifyCode
     */
    postVerifyCode: (request: DtoCodeVerificationRequest, params: RequestParams = {}) =>
      this.request<ResponseResponse, PostVerifyCodeError>({
        path: `/code/verifyCode`,
        method: 'POST',
        body: request,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  logout = {
    /**
     * @description User Registration
     *
     * @tags Auth
     * @name DeleteLogout
     * @summary Registration
     * @request DELETE:/logout
     */
    deleteLogout: (params: RequestParams = {}) =>
      this.request<ResponseResponse, DeleteLogoutError>({
        path: `/logout`,
        method: 'DELETE',
        format: 'json',
        ...params,
      }),
  }
  signIn = {
    /**
     * @description User Authorization
     *
     * @tags Auth
     * @name PostSignIn
     * @summary signIn
     * @request POST:/signIn
     */
    postSignIn: (request: DtoSingInRequest, params: RequestParams = {}) =>
      this.request<
        ResponseResponse & {
          data?: DtoAuthTokensResponse
        },
        PostSignInError
      >({
        path: `/signIn`,
        method: 'POST',
        body: request,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  signUp = {
    /**
     * @description User Registration
     *
     * @tags Auth
     * @name PostSignUp
     * @summary Registration
     * @request POST:/signUp
     */
    postSignUp: (request: DtoSignUpRequest, params: RequestParams = {}) =>
      this.request<ResponseResponse, PostSignUpError>({
        path: `/signUp`,
        method: 'POST',
        body: request,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  totp = {
    /**
     * @description User 2FA
     *
     * @tags Totp
     * @name PostGenerate
     * @summary Totp
     * @request POST:/totp/generate
     */
    postGenerate: (request: DtoTotpGenerateRequest, params: RequestParams = {}) =>
      this.request<
        ResponseResponse & {
          data?: DtoTotpGenerateResponse
        },
        PostGenerateError
      >({
        path: `/totp/generate`,
        method: 'POST',
        body: request,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description User 2FA
     *
     * @tags Totp
     * @name PostRecovery
     * @summary Totp
     * @request POST:/totp/recovery
     */
    postRecovery: (request: DtoTotpRecoveryRequest, params: RequestParams = {}) =>
      this.request<
        ResponseResponse & {
          data?: DtoAuthTokensResponse
        },
        PostRecoveryError
      >({
        path: `/totp/recovery`,
        method: 'POST',
        body: request,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description User 2FA
     *
     * @tags Totp
     * @name PostRemove
     * @summary Totp
     * @request POST:/totp/remove
     */
    postRemove: (request: DtoTotpRemoveRequest, params: RequestParams = {}) =>
      this.request<void, PostRemoveError>({
        path: `/totp/remove`,
        method: 'POST',
        body: request,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description User 2FA
     *
     * @tags Totp
     * @name PostValidate
     * @summary Totp
     * @request POST:/totp/validate
     */
    postValidate: (request: DtoTotpValidateRequest, params: RequestParams = {}) =>
      this.request<void, PostValidateError>({
        path: `/totp/validate`,
        method: 'POST',
        body: request,
        type: ContentType.Json,
        ...params,
      }),
  }
  user = {
    /**
     * @description Restore User Password
     *
     * @tags Auth
     * @name PostPasswordForget
     * @summary Password Restore
     * @request POST:/user/password/forget
     */
    postPasswordForget: (request: DtoPasswordForgetRequest, params: RequestParams = {}) =>
      this.request<ResponseResponse, PostPasswordForgetError>({
        path: `/user/password/forget`,
        method: 'POST',
        body: request,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
}
