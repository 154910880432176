const MenuPoint = () => (
  <svg
    width="4"
    height="4"
    fill="none"
    viewBox="0 0 4 4"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="white"
      opacity="0.5"
      clipRule="evenodd"
      fillRule="evenodd"
      d="M2 1C1.44772 1 1 1.44772 1 2C1 2.55228 1.44772 3 2 3C2.55228 3 3 2.55228 3 2C3 1.44772 2.55228 1 2 1ZM0 2C0 0.895431 0.895431 0 2 0C3.10457 0 4 0.895431 4 2C4 3.10457 3.10457 4 2 4C0.895431 4 0 3.10457 0 2Z"
    />
  </svg>
)

export default MenuPoint
