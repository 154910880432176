import {
  $userPopupActiveGameInfo,
  $userPopupActiveGameStats,
  $userPopupInfo,
} from 'stores/statisticStore'
import { $userProfile } from 'stores/userStore'
import { Header2, Text3, Text4, Text5 } from 'styles/typography'
import { setModalType } from 'stores/appStore'
import { useNavigate } from 'react-router-dom'
import { useStore } from '@nanostores/react'
import { useTranslation } from 'react-i18next'
import Button from 'components/Button'
import CSLogo from 'images/CSStatisticLogo.svg'
import Texture from 'images/CSStatisticTexture.svg'
import routes from 'router/routes'
import styled from 'styled-components'

const StyledRoot = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 490px;
  height: 100%;
  border-radius: 6px;
  border: 1px solid rgba(255, 255, 255, 0.15);
  background-color: rgba(255, 255, 255, 0.05);
  background-image: url(${Texture});
`
const StyledGameHead = styled.div`
  display: flex;
  align-items: flex-end;
  padding: 22px 32px;
  border-right: 1px solid rgba(255, 255, 255, 0.15);
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  border-bottom-right-radius: 20px;
  gap: 7px;
`
const StyledGameLogo = styled.div`
  width: 81px;
  height: 34px;
  background-image: url(${CSLogo});
`
const StyledGameText = styled(Text4)`
  font-weight: 500;
  color: white;
  line-height: 100%;
  opacity: 0.3;
`
const StyledMiddle = styled.div`
  display: flex;
  align-items: flex-end;
  width: 100%;
  padding-left: 32px;
  margin-top: -35px;
`
const StyledInfoItem = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 20px 24px 0;
`
const StyledInfoItemCount = styled(Header2)`
  font-weight: 400;
  color: white;
  line-height: 90%;
`
const StyledInfoItemText = styled(Text4)`
  font-weight: 500;
  color: white;
  line-height: 125%;
  margin-top: 2px;
  opacity: 0.3;
`
const StyledRightBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px 20px;
  margin-left: auto;
  border: 1px solid rgba(255, 255, 255, 0.15);
  border-right: 0px;
  width: 123px;
  border-radius: 3px 0px 0px 3px;
`
const StyledRightBlockItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`
const StyledRightBlockItemCount = styled(Text3)`
  color: white;
  line-height: 100%;
`
const StyledRightBlockItemText = styled(Text5)`
  line-height: 115.5%;
  color: white;
  opacity: 0.3;
`
const StyledButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0 24px;
  margin: auto 0 25px 0;

  & > a {
    width: 100%;
  }
`
const StyledButton = styled(Button)`
  display: flex;
  justify-content: center;
  height: 32px;
  width: 100%;
`

const CSStatistic: React.FC = () => {
  const { t } = useTranslation()
  const userActiveGameInfo = useStore($userPopupActiveGameInfo)
  const userPopupActiveGameStats = useStore($userPopupActiveGameStats)
  const currentUser = useStore($userProfile)
  const userInfo = useStore($userPopupInfo)
  const navigate = useNavigate()

  const total = () => {
    return (
      (userActiveGameInfo?.matchWinCount || 0) +
      (userActiveGameInfo?.matchLoseCount || 0) +
      (userActiveGameInfo?.matchDrawCount || 0)
    )
  }

  const handleClick = () => {
    navigate(routes.statistic())
    setModalType(null)
  }

  return (
    <StyledRoot>
      <StyledGameHead>
        <StyledGameLogo />
        <StyledGameText>{t('UserAccountPopup.statistic')}</StyledGameText>
      </StyledGameHead>
      <StyledMiddle>
        <StyledInfoItem>
          <StyledInfoItemCount>{total()}</StyledInfoItemCount>
          <StyledInfoItemText>
            {t('UserAccountPopup.matchesInMode')}
          </StyledInfoItemText>
        </StyledInfoItem>
        <StyledInfoItem>
          <StyledInfoItemCount>
            {Math.round((userActiveGameInfo?.spentTimeInGameMin || 0) / 60)}
          </StyledInfoItemCount>
          <StyledInfoItemText>
            {t('UserAccountPopup.hoursInMode')}
          </StyledInfoItemText>
        </StyledInfoItem>
        <StyledRightBlock>
          <StyledRightBlockItem>
            <StyledRightBlockItemCount>
              {userPopupActiveGameStats?.gamesInTotal || 0}
            </StyledRightBlockItemCount>
            <StyledRightBlockItemText>
              {t('UserAccountPopup.gamesTotal')}
            </StyledRightBlockItemText>
          </StyledRightBlockItem>
          <StyledRightBlockItem>
            <StyledRightBlockItemCount>
              {Math.round(
                (userPopupActiveGameStats?.spentTimeInGamesMin || 0) / 60
              )}
            </StyledRightBlockItemCount>
            <StyledRightBlockItemText>
              {t('UserAccountPopup.hoursTotal')}
            </StyledRightBlockItemText>
          </StyledRightBlockItem>
        </StyledRightBlock>
      </StyledMiddle>
      {currentUser?.externalId === userInfo?.externalId && (
        <StyledButtonWrapper>
          {/* <NavLink to={routes.statisticCustom()}> */}
          <StyledButton onClick={handleClick}>
            {t('UserAccountPopup.watchHistory')}
          </StyledButton>
          {/* </NavLink> */}
        </StyledButtonWrapper>
      )}
    </StyledRoot>
  )
}

export default CSStatistic
