const PartyMembersIcon = () => (
  <svg
    width="31"
    fill="none"
    height="18"
    viewBox="0 0 31 18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="#2CFF80"
      clipRule="evenodd"
      fillRule="evenodd"
      d="M14.9699 7.84809C17.1371 7.84809 18.8939 6.09123 18.8939 3.92404C18.8939 1.75685 17.1371 0 14.9699 0C12.8027 0 11.0458 1.75685 11.0458 3.92404C11.0458 6.09123 12.8027 7.84809 14.9699 7.84809ZM20.2109 11.8356C21.601 13.2257 22.3819 15.111 22.3819 17.0768H14.9698H7.55773C7.55773 15.111 8.33864 13.2257 9.72868 11.8356C11.1187 10.4456 13.004 9.6647 14.9698 9.6647C16.9356 9.6647 18.8209 10.4456 20.2109 11.8356ZM27.5624 6.79637C27.5624 8.45364 26.2189 9.79711 24.5617 9.79711C22.9044 9.79711 21.5609 8.45364 21.5609 6.79637C21.5609 5.13911 22.9044 3.79563 24.5617 3.79563C26.2189 3.79563 27.5624 5.13911 27.5624 6.79637ZM30.2298 17.0767C30.2298 15.5734 29.6326 14.1317 28.5696 13.0688C27.5067 12.0058 26.065 11.4086 24.5617 11.4086C23.8351 11.4086 23.1229 11.5481 22.4609 11.8123C23.537 13.3436 24.1256 15.1798 24.1256 17.0767H24.5617H30.2298ZM5.66812 9.79711C4.01086 9.79711 2.66738 8.45364 2.66738 6.79637C2.66738 5.13911 4.01086 3.79563 5.66812 3.79563C7.32538 3.79563 8.66886 5.13911 8.66886 6.79637C8.66886 8.45364 7.32538 9.79711 5.66812 9.79711ZM1.66014 13.0688C0.59717 14.1317 0 15.5734 0 17.0767H5.66807H6.10418C6.10419 15.1798 6.69281 13.3436 7.76889 11.8123C7.10688 11.5481 6.39467 11.4086 5.66807 11.4086C4.1648 11.4086 2.72311 12.0058 1.66014 13.0688Z"
    />
  </svg>
)

export default PartyMembersIcon
