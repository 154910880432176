import { $createdAppeal } from 'stores/chatStores/appealStore'
import { Header6 } from 'styles/typography'
import { useStore } from '@nanostores/react'
import TimeCode from 'components/TimeCode'
import styled from 'styled-components'

interface DescriptionBlockProps {
  label?: string
}

const TimeCodeBlock: React.FC<DescriptionBlockProps> = ({ label }) => {
  const createdAppeal = useStore($createdAppeal)

  const handleChangeDescription = (value: string[]) => {
    $createdAppeal.set({
      ...createdAppeal,
      timeCodes: [value],
    })
  }

  return (
    <StyledFilesWrapper>
      {label ? <StyledLabel>{label}</StyledLabel> : null}
      <TimeCode
        values={createdAppeal.timeCodes?.[0]}
        onChange={handleChangeDescription}
      />
    </StyledFilesWrapper>
  )
}

export default TimeCodeBlock

const StyledLabel = styled(Header6)``

const StyledFilesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`
