import { Header2, Text4, Text5 } from 'styles/typography'
import { useTranslation } from 'react-i18next'
import Background from 'images/judge/JudgeApplicationRejectedBackground.svg'
import styled from 'styled-components'

interface JudgeApplicationRejectedProps {
  declinedAt: string
  reapplyAt: string
}

const JudgeApplicationRejected = ({
  reapplyAt,
  declinedAt,
}: JudgeApplicationRejectedProps) => {
  const { t } = useTranslation()

  return (
    <StyledWrapper>
      <StyledInnerContainer>
        <StyledTitle>
          {t('judge.welcomePage.applicationRejectedTitle')}
        </StyledTitle>

        <StyledDescription>
          {t('judge.welcomePage.applicationRejectedDescription')} {reapplyAt}
        </StyledDescription>

        <StyledTimestamp>{declinedAt}</StyledTimestamp>
      </StyledInnerContainer>
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  display: flex;
  width: 535px;
  padding: 24px;
  flex-direction: column;
  align-items: flex-end;
  gap: 16px;

  border-radius: 6px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  background: linear-gradient(
      180deg,
      rgba(114, 132, 180, 0.1) 0%,
      rgba(59, 79, 131, 0) 100%
    ),
    rgba(29, 30, 34, 0.5);
  backdrop-filter: blur(35px);
`

const StyledInnerContainer = styled.div`
  width: 489px;
  height: 212px;
  padding: 24px 102px 16px 16px;
  background: url(${Background});
`

const StyledTitle = styled(Header2)`
  margin-bottom: 12px;

  color: #f44;
  line-height: 99%;
  font-style: normal;
  font-weight: 400;
  text-transform: uppercase;
`

const StyledDescription = styled(Text4)`
  margin-bottom: 25px;

  color: #fff;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
`

const StyledTimestamp = styled(Text5)`
  color: rgba(255, 255, 255, 0.15);
  font-style: normal;
  font-weight: 500;
  line-height: 115.5%;
`

export default JudgeApplicationRejected
