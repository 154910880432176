import { useEffect, useState } from 'react'
import { useStore } from '@nanostores/react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import {
  $currentRewardInfo,
  $myFinishedClaims,
  getRewardInfo,
} from 'stores/judgeStore'
import { Header2, Header3, Text4 } from 'styles/typography'
import Button from 'components/Button'
import Coin from 'images/Coin'
import GetRewardIcon from 'images/judge/GetRewardIcon'
import Loading from 'components/Loading'
import ProgressBar from 'components/ProgressBar'
import api from 'api/api'
import rewardBg from 'images/judge/reward-bg.webp'

const RewardInfo = () => {
  const { t } = useTranslation()
  const currentRewardInfo = useStore($currentRewardInfo)
  const myFinishedClaims = useStore($myFinishedClaims)

  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const fetchRewardInfo = async () => {
      await getRewardInfo()
      setIsLoading(false)
    }

    void fetchRewardInfo()
  }, [])

  if (isLoading || !currentRewardInfo) {
    return (
      <StyledRewardBlock>
        <Loading />
      </StyledRewardBlock>
    )
  }

  const handleGetReward = async () => {
    await api.judgeAPI.reward.postClaim()
  }

  const {
    feeRate = 0,
    countForReward = 0,
    rewardSum: balance = 0,
  } = currentRewardInfo

  const dailyLimit = feeRate * countForReward
  const currentReward = feeRate * myFinishedClaims.length
  const progress = (currentReward * 100) / dailyLimit

  return (
    <StyledRewardBlock>
      <StyledTitlesWrapper>
        <StyledTitles>
          <StyledTitle>{t('judge.judgePage.rewardTitle')}</StyledTitle>
          <StyledDescription>
            {t('judge.judgePage.rewardDescription')}
          </StyledDescription>
        </StyledTitles>
        <StyledTotalReward>
          <Coin />
          {balance.toFixed(2)}
        </StyledTotalReward>
      </StyledTitlesWrapper>
      <StyledDayRewardWrapper>
        <StyledDailyRewardContainer>
          <StyledDailyRewardNumber>
            <Text4>{t('judge.judgePage.todayReward')}</Text4>
            <StyledDailyRewardNumberLine>
              <Coin />
              {currentReward.toFixed(2)} /
              <StyledDisabledText>{dailyLimit.toFixed(2)}</StyledDisabledText>
            </StyledDailyRewardNumberLine>
          </StyledDailyRewardNumber>
          <StyledButton disabled={progress !== 100} onClick={handleGetReward}>
            <GetRewardIcon />
          </StyledButton>
        </StyledDailyRewardContainer>
        <ProgressBar progress={progress} />
      </StyledDayRewardWrapper>
    </StyledRewardBlock>
  )
}

const StyledButton = styled(Button)`
  border-radius: 6px !important;

  & svg path {
    fill: white;
    fill-opacity: 1;
    stroke: none;
  }

  &:enabled {
    &:hover {
      & svg path {
        fill: #ffcd29;
      }
    }

    &:active {
      & svg path {
        fill: black;
      }
    }
  }

  &:disabled {
    border: 1px solid rgba(255, 255, 255, 0.3);
    background: rgba(255, 255, 255, 0.15) !important;
  }
`

const StyledRewardBlock = styled.div`
  width: 445px;
  height: 100%;
  padding: 24px;
  padding-top: 16px;
  background-image: url('${rewardBg}');
  background-repeat: none;
  background-size: cover;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const StyledDailyRewardNumberLine = styled(Header3)`
  font-weight: 400;
  display: flex;
  gap: 4px;

  align-items: center;

  & svg {
    margin-right: 4px;
    fill: rgba(255, 205, 41, 1);
  }
`

const StyledDailyRewardNumber = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const StyledDailyRewardContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: end;
`

const StyledDayRewardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const StyledTotalReward = styled(Header2)`
  display: flex;
  gap: 10px;
  align-items: center;
  font-weight: 400;
  & svg {
    width: 40px;
    height: 40px;
    fill: rgba(255, 205, 41, 1);
  }
`

const StyledTitlesWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

const StyledTitles = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`

const StyledTitle = styled(Header3)`
  text-transform: uppercase;
`

const StyledDisabledText = styled.div`
  color: var(--Button-Disabled, rgba(255, 255, 255, 0.15));
`

const StyledDescription = styled(Text4)`
  color: var(--Button-Disabled, rgba(255, 255, 255, 0.15));
`

export default RewardInfo
