/* eslint-disable @typescript-eslint/no-explicit-any */
import { updateFriend } from 'stores/friendsStore'

const friendListener = async (data: any) => {
  if (data.entityAction === 'UPDATE') {
    await updateFriend(data.data)
  }
}

export default friendListener
