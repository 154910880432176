/* eslint-disable @typescript-eslint/no-explicit-any */

import { setBalance } from 'stores/walletStore'

const walletListiner = async (data: any) => {
  if (data.entityAction === 'CHANGE_WALLET_BALANCE') {
    await setBalance(data.data.balance)
  }
}

export default walletListiner
