import {
  $autoStartedMatches,
  addAutoStartedMatch,
  openResultMatchModal,
} from 'stores/appStore'
import { DtoLobby } from 'api/schemas/lobbyApi'
import { DtoMatch } from 'api/schemas/matchApi'
import { useEffect, useState } from 'react'
import { useStore } from '@nanostores/react'
import startCSGO from 'utils/startCSGo'

const useStartCSGame = ({
  match,
  lobby,
}: {
  lobby: DtoLobby | null
  match: DtoMatch | null
}) => {
  const [url, setUrl] = useState<string | null>(null)
  const autoStartedMatches = useStore($autoStartedMatches)

  useEffect(() => {
    if (match && match.status === 'MATCH_STARTED' && !url) {
      if (!autoStartedMatches.includes(match.id || '')) {
        startCSGO(match?.url || '')
        addAutoStartedMatch(match.id || '')
        setUrl(match?.url || '')
      } else {
        setUrl(match?.url || '')
      }
    }

    if (lobby?.status === 'NEW') {
      setUrl(null)
    }
  }, [autoStartedMatches, match, url, lobby])

  useEffect(() => {
    if (
      match &&
      (match.status === 'MATCH_FINISHED' ||
        match.status === 'MATCH_PROCESSING_COMPLETED') &&
      match.id === lobby?.lastFinishedMatchId
    ) {
      setUrl(null)
      openResultMatchModal(match.id || '')
    }
  }, [match, lobby])

  return { url }
}

export default useStartCSGame
