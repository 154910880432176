import { t } from 'i18next'

interface Texts {
  id: string
  title: string
  message: string
}

const getNotificationTexts = (message: string): Texts | null => {
  switch (message) {
    case 'friend request already send':
      return {
        id: message,
        title: t('errors.deniedRequestTitle'),
        message: t('errors.secondFriendRequestText'),
      }
    case 'friend request already accepted':
      return {
        id: message,
        title: t('errors.secondAcceptFriendRequestTitle'),
        message: t('errors.secondAcceptFriendRequestText'),
      }
    case 'game already start':
      return {
        id: message,
        title: t('errors.gameStartedTitle'),
        message: t('errors.gameStartedText'),
      }
    case 'user already invited':
      return {
        id: message,
        title: t('errors.alreadyInvitedTitle'),
        message: t('errors.alreadyInvitedText'),
      }
    case 'user already in party':
      return {
        id: message,
        title: t('errors.alreadyInPartyTitle'),
        message: t('errors.alreadyInPartyText'),
      }
    case 'user already in lobby':
      return {
        id: message,
        title: t('errors.alreadyInLobbyTitle'),
        message: t('errors.alreadyInLobbyText'),
      }
    case 'slot already busy':
      return {
        id: message,
        title: t('errors.slotAlreadyBusyTitle'),
        message: t('errors.slotAlreadyBusyText'),
      }
    case 'lobby already full':
      return {
        id: message,
        title: t('errors.lobbyAlreadyFullTitle'),
        message: t('errors.lobbyAlreadyFullText'),
      }
    case 'party already full':
      return {
        id: message,
        title: t('errors.partyAlreadyFullTitle'),
        message: t('errors.partyAlreadyFullText'),
      }
    default:
      return null
  }
}

export default getNotificationTexts
