import { Text5 } from 'styles/typography'
import { isProd } from 'api/api'
import { styled } from 'styled-components'
import { version } from '../../package.json'

const StyledRoot = styled(Text5)`
  position: absolute;
  left: 10px;
  bottom: 10px;
  color: rgba(255, 255, 255, 0.3);
  display: flex;
  flex-direction: column;
  gap: 10px;
  z-index: 9999;
`

const AppInfo = () => {
  return (
    <StyledRoot>
      <div>
        ver. {version} {isProd ? '' : 'test'}
      </div>
    </StyledRoot>
  )
}

export default AppInfo
