import { $createdAppeal, IReasonCheckbox } from 'stores/chatStores/appealStore'
import { EnumClaimReason } from 'api/schemas/supportApi'
import { useStore } from '@nanostores/react'
import ChooseCustomCheckbox from 'components/Chat/Support/supportComponents/ChooseCustomCheckbox'
import styled from 'styled-components'

const ChekboxesBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 19px;
`
interface ChooseCustomCheckboxProps {
  name: string
  value: EnumClaimReason
  inputLabel?: string
  description?: string
  type: 'checkbox-input' | 'checkbox'
}
interface ChooseChekboxesProps {
  onChange: () => void
  items: ChooseCustomCheckboxProps[]
}
const ChooseChekboxes: React.FC<ChooseChekboxesProps> = ({
  items,
  onChange,
}) => {
  const createdAppeal = useStore($createdAppeal)

  const handleCheckboxChange = (
    name: string,
    value: EnumClaimReason,
    isActive: boolean,
    description?: string
  ) => {
    const slicedDescription = description
      ? description.slice(0, 300)
      : undefined

    const reason = createdAppeal.reason?.length
      ? [
          ...createdAppeal.reason.filter((item) => item.name !== name),
          { name, value, isActive },
        ]
      : ([{ name, value, isActive }] as IReasonCheckbox[])

    $createdAppeal.set({
      ...createdAppeal,
      reason,
      description: slicedDescription,
    })
    onChange()
  }

  return (
    <ChekboxesBlock>
      {items.map((item) => {
        return (
          <ChooseCustomCheckbox
            id={item.name}
            key={item.name}
            text={item.name}
            type={item.type}
            inputLabel={item.inputLabel}
            onChange={handleCheckboxChange}
            description={item?.description || ''}
            value={item.value}
            label={
              !!createdAppeal.reason?.find(
                (reason) => reason.name === item.name
              )?.isActive
            }
          />
        )
      })}
    </ChekboxesBlock>
  )
}

export default ChooseChekboxes
