import { $lastMessageForFastShow, TabActive } from 'stores/chatStores/chatStore'
import { Text4 } from 'styles/typography'
import { useStore } from '@nanostores/react'
import { useTranslation } from 'react-i18next'
import MessageItem, { chatActionType } from 'components/Chat/MessageItem'
import styled from 'styled-components'

interface FastMessagesBlock {
  openChat: (chat: TabActive, id?: string) => () => void
}

const FastMessagesBlock: React.FC<FastMessagesBlock> = ({ openChat }) => {
  const { t } = useTranslation()
  const message = useStore($lastMessageForFastShow)

  if (!message) {
    return null
  }

  const getChatType = () => {
    if (message.tab === 'support') {
      return t('chat.appeal.support')
    }
    return t(`chat.${message.tab}`)
  }

  return (
    <StyledRoot
      key={message.id}
      onClick={openChat(
        message.tab,
        message.tab === 'chat' ? message.author?.id : ''
      )}
    >
      <MessageItem
        key={message.id}
        message={message.text}
        subject={message.user}
        nick={message.author?.name || ''}
        personId={message.author?.id || ''}
        avatar={message.author?.imgUrl || ''}
        actionType={message.status as chatActionType}
        type={message.isSystem ? 'action' : 'message'}
        date={message.createdAt ? message.createdAt : null}
      />
      <StyledTypeOfChat>
        <StyledChatCircle />
        {getChatType()}
      </StyledTypeOfChat>
    </StyledRoot>
  )
}

const StyledChatCircle = styled.div`
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background: #f2b927;
`

const StyledTypeOfChat = styled(Text4)`
  display: flex;
  align-items: center;

  gap: 12px;
`

const StyledRoot = styled.div`
  @keyframes rollout {
    0% {
      transform: translateY(80px);
    }
    100% {
      transform: none;
    }
  }

  animation: rollout 0.3s;

  width: 100%;
  padding: 5px 16px 5px 6px;

  margin-left: 24px;
  border-radius: 3.339px;
  background: rgba(0, 0, 0, 0.11);

  margin-right: 17px;
  margin-bottom: 5px;

  display: flex;

  justify-content: space-between;
  & > div {
    align-items: center;
  }

  & p {
    width: 600px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  & p > span {
    display: none;
  }
`

export default FastMessagesBlock
