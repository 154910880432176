import {
  $CSActiveTable,
  $DotaActiveTable,
  $mainPageStatisticGameFormat,
  $userPosition,
  RankTypes,
  setMainPageGameFormat,
} from 'stores/statisticStore'
import { $userProfile } from 'stores/userStore'
import { Header5 } from 'styles/typography'
import { useEffect, useRef, useState } from 'react'
import { useStore } from '@nanostores/react'
import { useTranslation } from 'react-i18next'
import StandingPerson from 'components/Home/StandingPerson'
import Toggle from 'components/Toggle'
import styled from 'styled-components'

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: auto;
  border-radius: 6px;
  border: 1px solid rgba(255, 255, 255, 0.15);
  background: rgba(37, 37, 37, 0.8);
  backdrop-filter: blur(7.5px);
  padding: 64px 24px 24px 24px;
`
const StyledLists = styled.div`
  display: flex;
  gap: 8px;
`
const StyledListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 12px 0;
  width: 220px;
  border-radius: 6px;
  border: 1px solid rgba(255, 255, 255, 0.15);
`
const StyledListCaption = styled(Header5)`
  color: white;
  opacity: 0.5;
  line-height: 100%;
  text-transform: uppercase;
  padding: 0 12px;
`
const StyledPlayersList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 480px;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 0 12px;

  &::-webkit-scrollbar {
    width: 2px;
  }

  &::-webkit-scrollbar-track {
    margin: 4px 0 4px;
    border-radius: 1px;
    opacity: 0.1;
    background: rgba(217, 217, 217, 0.1);
  }

  &::-webkit-scrollbar-thumb {
    background-color: white;
    border-radius: 1px;
  }
`
const StyledToTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: rgba(255, 255, 255, 0.03);
  font-family: Ruberoid;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  text-transform: uppercase;
  margin: 0 12px;
  height: 32px;
  cursor: pointer;
`

const PopupPlayers: React.FC = () => {
  const CSActiveTable = useStore($CSActiveTable)
  const DotaActiveTable = useStore($DotaActiveTable)
  const personal = useStore($userProfile)
  const { t } = useTranslation()
  const gameFormat = useStore($mainPageStatisticGameFormat)
  const userPosition = useStore($userPosition)

  type RatingTypes = 'RATING 5x5' | 'RATING 1x1'
  const RATING: RatingTypes[] = [t('home.rating5x5'), t('home.rating1x1')]

  const playersListCsRef = useRef<HTMLDivElement | null>(null)
  const playersListDotaRef = useRef<HTMLDivElement | null>(null)

  const scrollToTop = (game: 'dota' | 'cs') => {
    if (game === 'cs' && playersListCsRef.current)
      playersListCsRef.current.scrollTo({ top: 0, behavior: 'smooth' })
    if (game === 'dota' && playersListDotaRef.current)
      playersListDotaRef.current.scrollTo({ top: 0, behavior: 'smooth' })
  }

  const [rating, setRating] = useState<RatingTypes>(
    t('home.rating1x1') as RatingTypes
  )

  const handleRatingChange = (rating: RatingTypes) => {
    rating.toUpperCase() === 'RATING 1X1'
      ? setMainPageGameFormat('ONE_TO_ONE')
      : setMainPageGameFormat('FIVE_TO_FIVE')
    setRating(rating)
  }

  const activeTable = (game: string) => {
    const users =
      game === 'CS' ? CSActiveTable?.standings : DotaActiveTable?.standings
    if (!users?.length) return []
    return users
  }

  const getUsersStanding = (game: string) => {
    const standing = activeTable(game).map((user) => {
      return (
        <StandingPerson
          key={user.userId}
          imgUrl={user.userImg}
          id={user.userId || ''}
          score={user.rating || ''}
          nick={user.userName || ''}
          position={user.position || ''}
          isYou={personal?.id === user.userId}
          rank={(user.rank || 'D') as RankTypes}
          isTop={!!user?.position && user.position <= 3}
        />
      )
    })
    const listHasPerson = !!activeTable(game).find(
      (user) => user.userId === personal?.id
    )
    if (!listHasPerson && userPosition) {
      const myPosition = userPosition.find(
        (userGame) =>
          userGame.gameFormat === gameFormat && userGame.gameName === game
      )
      standing.push(
        <StandingPerson
          isYou={true}
          isTop={false}
          id={myPosition?.userId || ''}
          key={myPosition?.userId || ''}
          score={myPosition?.rating || ''}
          nick={myPosition?.userName || ''}
          imgUrl={myPosition?.userImg || ''}
          position={myPosition?.position || ''}
          rank={(myPosition?.rank || 'D') as RankTypes}
        />
      )
    }

    return standing
  }

  useEffect(() => {
    if (gameFormat === 'FIVE_TO_FIVE')
      setRating(t('home.rating5x5') as RatingTypes)
    else setRating(t('home.rating1x1') as RatingTypes)
  }, [gameFormat, t])

  return (
    <StyledWrapper>
      <StyledLists>
        <StyledListWrapper>
          <StyledListCaption>CS 2</StyledListCaption>
          <StyledPlayersList ref={playersListCsRef}>
            {getUsersStanding('CS')}
          </StyledPlayersList>
          <StyledToTop onClick={() => scrollToTop('cs')}>
            {t('home.liftUp')}
          </StyledToTop>
        </StyledListWrapper>
        <StyledListWrapper>
          <StyledListCaption>Dota 2</StyledListCaption>
          <StyledPlayersList ref={playersListDotaRef}>
            {getUsersStanding('DOTA2')}
          </StyledPlayersList>
          <StyledToTop onClick={() => scrollToTop('dota')}>
            {t('home.liftUp')}
          </StyledToTop>
        </StyledListWrapper>
      </StyledLists>
      <Toggle
        value={rating}
        items={RATING}
        padding="2.5px 61px"
        onChange={handleRatingChange}
      />
    </StyledWrapper>
  )
}

export default PopupPlayers
