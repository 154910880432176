import { $lobby, sendInvitesToParty } from 'stores/lobbyStore'
import {
  $party,
  dismissParty,
  leaveParty,
  rejectAllPartyInvite,
  rejectPartyInvite,
} from 'stores/partyStore'
import { $userProfile } from 'stores/userStore'
import { Header5 } from 'styles/typography'
import { Tooltip } from 'react-tooltip'
import { useDrop } from 'react-dnd'
import { useState } from 'react'
import { useStore } from '@nanostores/react'
import { useTranslation } from 'react-i18next'
import Counter from 'components/Counter'
import DeclineIcon from 'images/DeclineIcon'
import LeaveIcon from 'images/LeaveIcon'
import LeavePartyIcon from 'images/LeavePartyIcon'
import ManyInvitesIcon from 'images/ManyInvitesIcon'
import SmallButton from 'components/SmallButton'
import UserProfile from 'components/FriendsBar/UserProfile'
import styled from 'styled-components'

const StyledPartyNumber = styled(Header5)`
  position: relative;
  cursor: pointer;
`

const StyledLeaveIcon = styled.div`
  cursor: pointer;
  & svg {
    stroke: white;
  }
`

const StyledPartyWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
`

interface StyledDropButtonProps {
  $isOpen: boolean
}

const StyledDropButton = styled.div<StyledDropButtonProps>`
  color: #2cff80;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Ruberoid';
  font-size: ${(props) => (props.$isOpen ? '10px' : '9px')};
  height: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  border: 1px solid rgba(44, 255, 128, 1);

  background: rgba(0, 0, 0, 0.15);

  width: ${(props) => (props.$isOpen ? '215px' : '67px')};
  padding: 0;

  border-radius: 2px;
`

const StyledPartyControlsWrapper = styled.div`
  display: flex;

  gap: 10px;
`

interface StyledPartyInfoWrapperProps {
  $isOpen: boolean
}

const StyledPartyInfoWrapper = styled.div<StyledPartyInfoWrapperProps>`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: ${({ $isOpen }) => ($isOpen ? 'space-between' : 'center')};
  width: ${({ $isOpen }) => ($isOpen ? '220px' : '69px')};
  transition: 0.2s;
  padding-left: ${({ $isOpen }) => ($isOpen ? '1px' : '0')};
`

const StyledDismissParty = styled.div`
  cursor: pointer;
`

const StlyedTooltip = styled(Tooltip)`
  width: 362px !important;
  padding: 16px 28px !important;
  border-radius: 3px !important;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: rgba(29, 30, 34, 0.3) !important;
  backdrop-filter: blur(10px);
  z-index: 9999;
  & .react-tooltip-arrow {
    display: none;
  }
`

const StyledTooltipButtons = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

const StyledInvitedMembers = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-bottom: 24px;
`

const StyledInvitedMember = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

const StyledDeclineIcon = styled(DeclineIcon)`
  fill: white;
  cursor: pointer;
`

interface PartyProps {
  isOpen: boolean
  isHost: boolean
}

const Party: React.FC<PartyProps> = ({ isOpen, isHost }) => {
  const { t } = useTranslation()

  const [isTooltipOpen, setIsTooltipOpen] = useState(false)

  const party = useStore($party)
  const lobby = useStore($lobby)
  const currentUser = useStore($userProfile)

  const [, drop] = useDrop(() => ({
    accept: 'Friend',
    drop: () => ({ name: 'Dustbin' }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }))

  const handleLeaveParty = async () => {
    await leaveParty()
  }

  const handleSendInvites = async () => {
    await sendInvitesToParty()
  }

  const handleDismissParty = async () => {
    await dismissParty()
  }
  const handleDeclineInvite = (id: string) => async () => {
    await rejectPartyInvite(id)
  }

  const handleOpenTooltip = () => {
    if (party?.invitedParticipants?.length) {
      setIsTooltipOpen(true)
    }
  }

  const handleDeclineAllInvite = async () => {
    const ids =
      party?.invitedParticipants?.map((item) => item.user?.id || '') || []
    console.log(ids)
    await rejectAllPartyInvite(ids)
  }

  return (
    <>
      <StyledPartyWrapper>
        <StyledDropButton ref={drop} $isOpen={isOpen}>
          {isOpen
            ? t('friends.party.dndField')
            : t('friends.party.dndFieldMin')}
        </StyledDropButton>
      </StyledPartyWrapper>
      {party && (
        <StyledPartyInfoWrapper $isOpen={isOpen}>
          <StyledPartyNumber
            onClick={handleOpenTooltip}
            data-tooltip-id={
              party.invitedParticipants?.length
                ? 'party-invites-tooltip'
                : undefined
            }
          >
            {party.participants?.length} / 5
            {Boolean(party.invitedParticipants?.length) && (
              <Counter
                color="gold"
                position="top-left"
                value={`+${party.invitedParticipants?.length || 0}`}
              />
            )}
          </StyledPartyNumber>
          <StlyedTooltip
            clickable
            isOpen={isTooltipOpen}
            id="party-invites-tooltip"
            render={() => (
              <div onMouseLeave={() => setIsTooltipOpen(false)}>
                <StyledInvitedMembers>
                  {party.invitedParticipants?.map(({ user, invitedBy }) =>
                    user ? (
                      <StyledInvitedMember key={user.id}>
                        <UserProfile
                          key={user.id}
                          profile={user}
                          isFull={isOpen}
                          nickName={user.name}
                          status={user.online === undefined}
                        />
                        {(currentUser?.id === party.hostId ||
                          currentUser?.id === invitedBy?.id) && (
                          <div onClick={handleDeclineInvite(user?.id || '')}>
                            <StyledDeclineIcon />
                          </div>
                        )}
                      </StyledInvitedMember>
                    ) : null
                  )}
                </StyledInvitedMembers>

                <StyledTooltipButtons>
                  <SmallButton onClick={handleDeclineAllInvite}>
                    {t('buttons.declineAll')}
                  </SmallButton>
                  <SmallButton onClick={() => setIsTooltipOpen(false)}>
                    {t('buttons.close')}
                  </SmallButton>
                </StyledTooltipButtons>
              </div>
            )}
          />
          {isOpen && (
            <StyledPartyControlsWrapper>
              {lobby && (
                <StyledLeaveIcon onClick={handleSendInvites}>
                  <ManyInvitesIcon />
                </StyledLeaveIcon>
              )}
              {isHost && (
                <StyledDismissParty onClick={handleDismissParty}>
                  <LeavePartyIcon />
                </StyledDismissParty>
              )}

              <StyledLeaveIcon onClick={handleLeaveParty}>
                <LeaveIcon />
              </StyledLeaveIcon>
            </StyledPartyControlsWrapper>
          )}
        </StyledPartyInfoWrapper>
      )}
    </>
  )
}

export default Party
