import React from 'react'
import styled from 'styled-components'

import { Text5 } from 'styles/typography'
import activeBg from 'images/judge/daily-task-active.webp'
import blockBg from 'images/judge/daily-task-block.webp'
import completedBg from 'images/judge/daily-task-completed.webp'

interface DailyTaskItemProps {
  id: number
  todayFinishedCount: number
  claimId?: string
}

const DailyTaskItem: React.FC<DailyTaskItemProps> = ({
  id,
  claimId,
  todayFinishedCount,
}) => {
  const status = getStatus(id, todayFinishedCount)
  const percent = getPercents(id, todayFinishedCount, claimId)
  const showProgress = status !== 'block'
  const isEven = id % 2 === 0

  return (
    <StyledRoot $isEven={isEven}>
      {showProgress && <StyledText $status={status}>ID {claimId}</StyledText>}

      <StyledIcon $status={status}>{showProgress ? id : null}</StyledIcon>

      {showProgress && <StyledText $status={status}>{percent}</StyledText>}
    </StyledRoot>
  )
}

type TaskStatus = 'block' | 'active' | 'completed'

const getPercents = (
  id: number,
  todayFinishedCount: number,
  claimId?: string
) => {
  if (id <= todayFinishedCount) return '100%'

  return claimId ? '50%' : '0%'
}

const getStatus = (id: number, todayFinishedCount: number): TaskStatus => {
  if (id <= todayFinishedCount) return 'completed'
  if (id === todayFinishedCount + 1) return 'active'
  return 'block'
}

const getBg = (status: TaskStatus) => {
  switch (status) {
    case 'active':
      return activeBg
    case 'block':
      return blockBg
    case 'completed':
    default:
      return completedBg
  }
}

interface StyledStatusProps {
  $status: TaskStatus
}

const StyledIcon = styled.div<StyledStatusProps>`
  display: flex;
  justify-content: center;
  align-items: center;

  background-image: url('${({ $status }) => getBg($status)}');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  width: 100%;
  height: ${({ $status }) => ($status === 'block' ? '70px' : '94px')};

  color: ${({ $status }) => {
    switch ($status) {
      case 'active':
        return 'black'
      case 'block':
      case 'completed':
      default:
        return 'white'
    }
  }};

  text-align: center;
  font-family: Ruberoid;
  font-size: 19.291px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
`

const StyledText = styled(Text5)<StyledStatusProps>`
  color: ${({ $status }) => {
    switch ($status) {
      case 'active':
        return '#2CFF80'
      case 'block':
      case 'completed':
      default:
        return 'rgba(255, 255, 255, 0.15)'
    }
  }};
  font-weight: 500;
  text-align: center;
`

interface StyledRootProps {
  $isEven: boolean
}

const StyledRoot = styled.div<StyledRootProps>`
  height: 128px;
  width: 74px;

  display: flex;
  justify-content: center;
  flex-direction: ${({ $isEven }) => ($isEven ? 'column-reverse' : 'column')};
  gap: 3px;
`

export default DailyTaskItem
