const HostIcon = ({ className }: { className?: string }) => (
  <svg
    width="17"
    fill="none"
    height="10"
    viewBox="0 0 17 10"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="#2CFF80"
      d="M0 0V10H17V0L12.75 4.39741L8.5 0L4.25 4.39741L0 0Z"
    />
  </svg>
)

export default HostIcon
