import { Header6 } from 'styles/typography'
import { SpinnerCircularFixed } from 'spinners-react'
import buttonClickSound from 'sounds/simple-buttons.mp3'
import styled, { css } from 'styled-components'
import useSound from 'hooks/useSound'

interface StyledButtonProps {
  $isActive?: boolean
  $colors: ButtonColors
  $withoutBorder?: boolean
}

const StyledButton = styled.button<StyledButtonProps>`
  text-transform: uppercase;

  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;

  padding: ${({ $withoutBorder }) => ($withoutBorder ? '7px 0px' : '7px 10px')};

  opacity: ${({ disabled }) => (disabled ? '0.8' : 1)};

  background: ${({ $colors }) => $colors.background};

  border: ${({ $colors }) => $colors.border};

  border-radius: 3px;
  color: ${({ $colors }) => $colors.color};

  &:hover {
    background: ${({ $colors }) => $colors.backgroundHover};
    border: ${({ $colors }) => $colors.borderHover};

    color: ${({ $colors }) => $colors.colorHover};

    border-radius: 3px;
  }
  &:active {
    background: ${({ $colors }) => $colors.backgroundActive};
    border: ${({ $colors }) => $colors.borderActive};
    border-radius: 3px;
    color: ${({ $colors }) => $colors.colorHover};
  }
  ${({ $colors, $isActive }) =>
    $isActive &&
    css`
      background: ${$colors.backgroundActive};
      border: ${$colors.borderActive};
      border-radius: 3px;
      color: ${$colors.colorHover};
    `}
`

const StyledContent = styled(Header6)`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 3px;
`

interface ButtonColors {
  color: string
  border: string
  colorHover: string
  background: string
  colorActive: string
  borderHover: string
  borderActive: string
  backgroundHover: string
  backgroundActive: string
}

const getButtonColors = (
  view: 'primary' | 'secondary',
  disabled?: boolean,
  withoutBorder?: boolean
): ButtonColors => {
  let result = {
    color: 'white',
    colorHover: 'white',
    colorActive: 'black',
    backgroundActive: '#2CFF80',
    background: 'rgba(255, 255, 255, 0.03)',
    backgroundHover: 'rgba(44, 255, 128, 0.15)',
    border: '1px solid rgba(255, 255, 255, 0.10)',
    borderHover: '1px solid rgba(255, 255, 255, 0.10)',
    borderActive: '1px solid rgba(255, 255, 255, 0.10)',
  }

  if (withoutBorder) {
    result = {
      border: 'none',
      color: 'white',
      borderHover: 'none',
      borderActive: 'none',
      background: 'transparent',
      backgroundHover: 'transparent',
      backgroundActive: 'transparent',
      colorActive: 'rgba(44, 255, 128, 1)',
      colorHover: 'rgba(44, 255, 128, 0.57)',
    }
  }

  if (view === 'secondary') {
    result = {
      color: withoutBorder ? 'white' : 'rgba(255, 68, 68, 1)',
      colorActive: withoutBorder ? 'rgba(255, 68, 68, 1)' : 'black',
      border: withoutBorder ? 'none' : '1px solid rgba(255, 255, 255, 0.10)',
      background: withoutBorder ? 'transparent' : 'rgba(255, 255, 255, 0.03)',
      backgroundActive: withoutBorder ? 'transparent' : 'rgba(255, 68, 68, 1)',
      backgroundHover: withoutBorder
        ? 'transparent'
        : 'rgba(255, 68, 68, 0.40)',
      borderHover: withoutBorder
        ? 'none'
        : '1px solid rgba(255, 255, 255, 0.10)',
      borderActive: withoutBorder
        ? 'none'
        : '1px solid rgba(255, 255, 255, 0.10)',
      colorHover: withoutBorder
        ? 'rgba(255, 68, 68, 0.57)'
        : 'rgba(255, 68, 68, 1)',
    }
  }

  if (disabled) {
    result.backgroundHover = result.background
    result.backgroundActive = result.background
    result.colorHover = result.color
    result.colorActive = result.color
  }

  return result
}

interface ButtonProps {
  isActive?: boolean
  isLoading?: boolean
  withoutBorder?: boolean
  view?: 'primary' | 'secondary'
}

const SmallButton: React.FC<
  ButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>
> = ({
  onClick,
  children,
  disabled,
  isActive,
  isLoading,
  withoutBorder,
  view = 'primary',
  ...rest
}) => {
  const [play] = useSound(buttonClickSound)

  const onClickWithSound = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (onClick) {
      onClick(e)
    }
    play()
  }

  const colors = getButtonColors(view, disabled, withoutBorder)
  return (
    <StyledButton
      $colors={colors}
      disabled={disabled}
      $isActive={isActive}
      onClick={onClickWithSound}
      $withoutBorder={withoutBorder}
      {...rest}
    >
      <>
        <StyledContent>
          {isLoading ? (
            <SpinnerCircularFixed
              size="16"
              thickness={240}
              color="rgba(44, 255, 128, 1)"
              secondaryColor="rgba(255, 255, 255, 0.1)"
            />
          ) : (
            children
          )}
        </StyledContent>
      </>
    </StyledButton>
  )
}

export default SmallButton
