import { Header3 } from 'styles/typography'
import { NavLink } from 'react-router-dom'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import ChooseGameShevron from 'images/ChooseGameShevron'
import routes from 'router/routes'
import styled, { css } from 'styled-components'

import { Game, setGame } from 'stores/appStore'
import chooseGameCSGO from 'images/chooseGameCSGO.webp'
// import chooseGameDOTA2 from 'images/chooseGameDOTA2.webp'

const StyledRoot = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
`

const StyledText = styled(Header3)`
  text-transform: uppercase;
`

const StyledCardsWrapper = styled.div`
  display: flex;
  height: 850px;
  align-items: center;
  gap: 17px;
`

const StyledCard = styled.img`
  height: 100%;
`

interface StyledCardWrapper {
  $hoverStatus: 'current' | 'another' | 'none'
}

const StyledCardWrapper = styled(NavLink)<StyledCardWrapper>`
  ${({ $hoverStatus }) => {
    switch ($hoverStatus) {
      case 'current':
        return css`
          /* width: 462px;
          height: 720px; */
          width: 288px;
          height: 650px;
          transform: scale(1.05);
          opacity: 1;
        `
      case 'another':
        return css`
          /* width: 370px;
          height: 620px; */
          width: 288px;
          height: 650px;
          transform: scale(0.95);
          opacity: 0.6;
        `
      default:
        return css`
          width: 288px;
          height: 650px;
          opacity: 1;
        `
    }
  }}

  cursor: pointer;

  position: relative;

  transition: 0.2s;
`

interface StyledChooseGameShevronProps {
  $isHover: boolean
}

const StyledChooseGameShevron = styled(
  ChooseGameShevron
)<StyledChooseGameShevronProps>`
  ${({ $isHover }) => css`
    opacity: ${$isHover ? '1' : '0'};
  `}
  position: relative;

  margin: 0 auto;
  top: -20px;

  transition: 0.2s;
`

const ChooseGamePage = () => {
  const { t } = useTranslation()

  const [currentHover, setCurrentHover] = useState<Game | null>()

  const handleGameClick = (game: Game) => () => {
    setGame(game)
  }

  return (
    <StyledRoot>
      <StyledCardsWrapper>
        <StyledCardWrapper
          to={routes.play()}
          onClick={handleGameClick('cs')}
          onMouseLeave={() => setCurrentHover(null)}
          onMouseEnter={() => setCurrentHover('cs')}
          $hoverStatus={
            !currentHover
              ? 'none'
              : currentHover === 'cs'
                ? 'current'
                : 'another'
          }
        >
          <StyledChooseGameShevron $isHover={currentHover === 'cs'} />
          <StyledCard src={chooseGameCSGO} />
        </StyledCardWrapper>
        {/* // Доту пока не сделали */}
        {/* <StyledCardWrapper
          to={routes.play()}
          // onClick={handleGameClick('dota')}
          // onMouseLeave={() => setCurrentHover(null)}
          // onMouseEnter={() => setCurrentHover('dota')}
          $hoverStatus={
            !currentHover
              ? 'none'
              : currentHover === 'dota'
              ? 'current'
              : 'another'
          }
        >
          <StyledChooseGameShevron $isHover={currentHover === 'dota'} />
          <StyledCard src={chooseGameDOTA2} />
        </StyledCardWrapper> */}
      </StyledCardsWrapper>
      <StyledText>{t('chooseGame')}</StyledText>
    </StyledRoot>
  )
}

export default ChooseGamePage
