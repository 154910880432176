const SmallHostIcon = () => (
  <svg
    height="7"
    width="11"
    fill="none"
    viewBox="0 0 11 7"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="#2CFF80"
      d="M0.000976562 0V6.54932H10.801V0L8.10098 2.88L5.40098 0L2.70098 2.88L0.000976562 0Z"
    />
  </svg>
)

export default SmallHostIcon
