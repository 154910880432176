import { useEffect, useState } from 'react'
import { useStore } from '@nanostores/react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { $myTickets, getMyTickets } from 'stores/judgeStore'
import { Header3 } from 'styles/typography'
import ClaimsTable from 'components/ClaimsTable/ClaimsTable'
import InfoTooltipIcon from 'components/Tooltip/Info/InfoTooltipIcon'
import UserTicketsStatistic from 'components/Judge/MyTickets/UserTicketsStatistic'

const JudgeMyTicketsPage: React.FC = () => {
  const { t } = useTranslation()
  const myTickets = useStore($myTickets)

  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const fetchClaims = async () => {
      await getMyTickets()
      setIsLoading(false)
    }

    void fetchClaims()
  }, [])

  return (
    <>
      <StyledCaptionWrapper>
        <StyledCaption>
          <StyledText>{t('judge.judgePage.reportInfoAbout')}</StyledText>
          <InfoTooltipIcon
            text={t('judge.judgePage.reportInfoAboutTooltip')}
            place="left-end"
          />
        </StyledCaption>
      </StyledCaptionWrapper>
      <StyledRoot>
        <UserTicketsStatistic />
        <StyledTableWrapper>
          <StyledTableTitle>
            <StyledText>{t('judge.judgePage.applicationTable')}</StyledText>
            <InfoTooltipIcon text={t('judge.judgePage.reportTooltip')} />
          </StyledTableTitle>
          <ClaimsTable claims={myTickets} isLoading={isLoading} />
        </StyledTableWrapper>
      </StyledRoot>
    </>
  )
}

export default JudgeMyTicketsPage

const StyledCaptionWrapper = styled.div`
  position: relative;
  top: -96px;
  display: flex;
  justify-content: flex-end;
`
const StyledCaption = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 14px;
`
const StyledText = styled(Header3)`
  text-transform: uppercase;
  line-height: 140%;
`

const StyledRoot = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 6px;
  margin-top: -36px;
  height: 100%;
  margin-bottom: 85px;
  gap: 48px;

  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 2px;
  }

  &::-webkit-scrollbar-track {
    margin: 4px 0 4px;
    border-radius: 1px;
    opacity: 0.1;
    background: rgba(217, 217, 217, 0.1);
  }

  &::-webkit-scrollbar-thumb {
    background-color: white;
    border-radius: 1px;
  }
`

const StyledTableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  gap: 24px;
`

const StyledTableTitle = styled.div`
  display: flex;
  gap: 14px;
`
