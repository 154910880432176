import { DtoMatch } from 'api/schemas/matchApi'
import dayjs from 'dayjs'

export const getMatchTime = (time: string) => {
  if (!time) return
  const date = new Date(+time)
  const isTooday = new Date().toDateString() === new Date(date).toDateString()
  if (isTooday) return `Today ${dayjs(time).format('HH:mm')}`
  return `${dayjs(time).format('DD.MM HH:mm')}`
}

export const getMatchText = (match: DtoMatch, hideID?: boolean) => {
  if (!match?.externalId || !match?.createdAt) return ''
  const { createdAt, externalId } = match
  return `${hideID ? '' : 'ID '} ${externalId} ${getMatchTime(createdAt)}`
}
