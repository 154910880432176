import { getColorByStatus } from 'components/FriendsBar/UserProfile'
import HostIcon from 'images/HostIcon'
import avatarImg from 'images/Avatar_test.png'
import rangC from 'components/FriendsBar/images/Rang C.png'
import rangS from 'components/FriendsBar/images/Rang S.png'
import styled, { css } from 'styled-components'

const StyledContainer = styled.div`
  display: flex;
  position: relative;
  cursor: pointer;
`
interface StyledImageProps {
  size: '50' | '64' | '30' | '24'
}

const StyledImage = styled.img<StyledImageProps>`
  ${({ size }) => css`
    min-width: ${size}px;
    height: ${size}px;
  `}
  background-size: cover;

  border-radius: 1px;
`

const StyledRang = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`

const getRangImage = (rangType: string) => {
  switch (rangType) {
    case 'S':
      return rangS
    case 'C':
      return rangC
  }
}

const getAvatarSizeStyle = (size: string) => {
  switch (size) {
    case 'xxs':
      return '24'
    case 'xs':
      return '30'
    case 's':
      return '50'
    case 'm':
      return '64'
    default:
      return '50'
  }
}

interface StyledStatusIconProps {
  $status?: string
}

const StyledStatusIcon = styled.div<StyledStatusIconProps>`
  width: 2px;

  margin-right: 2px;

  border-radius: 1px;

  background-color: ${({ $status }) => {
    return getColorByStatus($status || '')
  }};
`

const StyledHostIcon = styled(HostIcon)`
  position: absolute;
  bottom: 6px;
  left: 10px;
`

interface AvatarWithRangProps {
  status?: string
  avatar?: string
  isFull?: boolean
  isHost?: boolean | null
  rang?: 'a' | 'b' | 'c' | 'd'
  size?: 'm' | 's' | 'xs' | 'xxs'
}

const AvatarWithRang: React.FC<AvatarWithRangProps> = ({
  rang,
  status,
  avatar,
  isHost,
  size = 's',
}) => {
  return (
    <StyledContainer>
      {status && <StyledStatusIcon $status={status} />}

      <StyledImage src={avatar || avatarImg} size={getAvatarSizeStyle(size)} />
      {rang && (
        <StyledRang>
          <img alt="" src={getRangImage('S')} />
        </StyledRang>
      )}
      {isHost && <StyledHostIcon />}
    </StyledContainer>
  )
}

export default AvatarWithRang
