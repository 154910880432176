import {
  $isAppealEstimated,
  setEstimateAppeal,
} from 'stores/chatStores/appealStore'
import { Text5 } from 'styles/typography'
import { useState } from 'react'
import { useStore } from '@nanostores/react'
import { useTranslation } from 'react-i18next'
import ActiveStar from 'images/chat/ActiveStar.svg'
import Star from 'images/chat/Star.svg'
import styled from 'styled-components'

const StyledStarsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
`
const StyledStarsText = styled(Text5)``
const StyledStarsItems = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row-reverse;
  width: 184px;
`
interface Label {
  htmlFor: string
}
const StyledLabel = styled.label<Label>`
  width: 40px;
  height: 24px;
  background-image: url(${Star});
  transition: 0.3s;
  cursor: pointer;
  padding-right: 16px;
  background-repeat: no-repeat;
  background-position-x: left;
`
const StyledInput = styled.input`
  opacity: 0;

  &:checked ~ label,
  &:checked ~ label ~ label {
    background-image: url(${ActiveStar});
  }

  &:not(:checked) ~ label:hover,
  &:not(:checked) ~ label:hover ~ label {
    background-image: url(${ActiveStar});
  }
`

const SupportMessageStars: React.FC = () => {
  const { t } = useTranslation()
  const [rating, setRating] = useState<number | null>(null)
  const isAppealEstimated = useStore($isAppealEstimated)
  const handleRatingChange = (selectedRating: number) => async () => {
    setRating(selectedRating)
    await setEstimateAppeal(selectedRating)
  }

  return (
    <StyledStarsWrapper>
      <StyledStarsText>
        {isAppealEstimated
          ? `${t('chat.appeal.deleteSoon')}`
          : `${t('chat.appeal.evaluate')}`}
      </StyledStarsText>
      {!isAppealEstimated && (
        <StyledStarsItems>
          <StyledInput
            type="radio"
            name="rating"
            id="rating-5"
            defaultChecked={rating === 5}
            onChange={handleRatingChange(5)}
          />
          <StyledLabel htmlFor="rating-5" />
          <StyledInput
            type="radio"
            name="rating"
            id="rating-4"
            defaultChecked={rating === 4}
            onChange={handleRatingChange(4)}
          />
          <StyledLabel htmlFor="rating-4" />
          <StyledInput
            type="radio"
            name="rating"
            id="rating-3"
            defaultChecked={rating === 3}
            onChange={handleRatingChange(3)}
          />
          <StyledLabel htmlFor="rating-3" />
          <StyledInput
            type="radio"
            name="rating"
            id="rating-2"
            defaultChecked={rating === 2}
            onChange={handleRatingChange(2)}
          />
          <StyledLabel htmlFor="rating-2" />
          <StyledInput
            type="radio"
            name="rating"
            id="rating-1"
            defaultChecked={rating === 1}
            onChange={handleRatingChange(1)}
          />
          <StyledLabel htmlFor="rating-1" />
        </StyledStarsItems>
      )}
    </StyledStarsWrapper>
  )
}

export default SupportMessageStars
