import { Header4, Header5, SideHeader4 } from 'styles/typography'
import { useTranslation } from 'react-i18next'
import Coin from 'images/Coin'
import MatchRectangle from 'images/judge/MatchRectangle.svg'
import SkullMVPLogo from 'images/judge/SkullMVPLogo.svg'
import TeamLeft from 'components/Judge/Match/TeamLeft'
import WithTooltip from 'components/Tooltip/TableHeader/WithTooltip'
import styled from 'styled-components'

const JudgeVoteMatch = () => {
  const { t } = useTranslation()

  return (
    <>
      <StyledContainer>
        <TeamLeft />
        <StyledMatchContainer>
          <StyledMatchWrapper>
            <StyledMainWrapper>
              <MatchDescriptionWrapper>
                <MatchDescriptionLeftWrapper>
                  <GridDescription>
                    <WithTooltip
                      title={t('results.tooltips.kill.title')}
                      text={t('results.tooltips.kill.description')}
                    >
                      <StyledGrayDescription>K</StyledGrayDescription>
                    </WithTooltip>
                  </GridDescription>
                  <GridDescription>
                    <WithTooltip
                      title={t('results.tooltips.death.title')}
                      text={t('results.tooltips.death.description')}
                    >
                      <StyledGrayDescription>D</StyledGrayDescription>
                    </WithTooltip>
                  </GridDescription>
                  <GridDescription>
                    <WithTooltip
                      title={t('results.tooltips.assist.title')}
                      text={t('results.tooltips.assist.description')}
                    >
                      <StyledGrayDescription>A</StyledGrayDescription>
                    </WithTooltip>
                  </GridDescription>
                  <GridDescription>
                    <WithTooltip
                      title={t('results.tooltips.diff.title')}
                      text={t('results.tooltips.diff.description')}
                    >
                      <StyledGrayDescription>+/-</StyledGrayDescription>
                    </WithTooltip>
                  </GridDescription>
                  <GridDescription>
                    <WithTooltip
                      title={t('results.tooltips.kd.title')}
                      text={t('results.tooltips.kd.description')}
                    >
                      <StyledGrayDescription>K/D</StyledGrayDescription>
                    </WithTooltip>
                  </GridDescription>
                  <GridDescription>
                    <WithTooltip
                      title={t('results.tooltips.adr.title')}
                      text={t('results.tooltips.adr.description')}
                    >
                      <StyledGrayDescription>ADR</StyledGrayDescription>
                    </WithTooltip>
                  </GridDescription>
                  <GridDescription>
                    <WithTooltip
                      title={t('results.tooltips.hs.title')}
                      text={t('results.tooltips.hs.description')}
                    >
                      <StyledGrayDescription>HS%</StyledGrayDescription>
                    </WithTooltip>
                  </GridDescription>

                  <GridDescription>
                    <WithTooltip
                      title={t('results.tooltips.kast.title')}
                      text={t('results.tooltips.kast.description')}
                    >
                      <StyledGrayDescription>KAST</StyledGrayDescription>
                    </WithTooltip>
                  </GridDescription>
                </MatchDescriptionLeftWrapper>
                <MatchDescriptionRightWrapper>
                  <GridRightDescription>
                    <WithTooltip
                      title={t('results.tooltips.ef.title')}
                      text={t('results.tooltips.ef.description')}
                    >
                      <StyledGrayDescription>EF</StyledGrayDescription>
                    </WithTooltip>
                  </GridRightDescription>
                  <GridRightDescription>
                    <StyledGrayDescription>Score</StyledGrayDescription>
                  </GridRightDescription>
                  <GridRightDescription>
                    <StyledGrayDescription>Rating</StyledGrayDescription>
                  </GridRightDescription>
                  <GridRightDescription>
                    <StyledGrayDescription>Reward</StyledGrayDescription>
                  </GridRightDescription>
                </MatchDescriptionRightWrapper>
              </MatchDescriptionWrapper>

              <PlayerInfoWrapper>
                <PlayerLeftWrapper>
                  <PlayerMVP>
                    <PlayerMVPLogo />
                    <PlayerMVPDescription>
                      <span>CHEAT</span>
                    </PlayerMVPDescription>
                  </PlayerMVP>

                  <PlayerGridContainer>
                    <PlayerInfo>
                      <PlayerAvatar />
                      <PlayerNickname>
                        <StyledGreenDescription>DJ_HEAD</StyledGreenDescription>
                      </PlayerNickname>
                    </PlayerInfo>
                    <PlayerStatistic>
                      <GridDescription>
                        <StyledWhiteDescription>12</StyledWhiteDescription>
                      </GridDescription>
                      <GridDescription>
                        <StyledWhiteDescription>16</StyledWhiteDescription>
                      </GridDescription>
                      <GridDescription>
                        <StyledWhiteDescription>0</StyledWhiteDescription>
                      </GridDescription>
                      <GridDescription>
                        <StyledRedDescription>8</StyledRedDescription>
                      </GridDescription>
                      <GridDescription>
                        <StyledWhiteDescription>1.70</StyledWhiteDescription>
                      </GridDescription>
                      <GridDescription>
                        <StyledWhiteDescription>55.5</StyledWhiteDescription>
                      </GridDescription>
                      <GridDescription>
                        <StyledWhiteDescription>23%</StyledWhiteDescription>
                      </GridDescription>
                      <GridDescription>
                        <StyledWhiteDescription>73.1%</StyledWhiteDescription>
                      </GridDescription>
                    </PlayerStatistic>
                  </PlayerGridContainer>
                </PlayerLeftWrapper>
                <PlayerRightWrapper>
                  <GridRightPlayerStat>
                    <StyledWhiteDescription>12</StyledWhiteDescription>
                  </GridRightPlayerStat>
                  <GridRightPlayerStat>
                    <StyledWhiteDescription>5</StyledWhiteDescription>
                  </GridRightPlayerStat>
                  <GridRightPlayerStat>
                    <StyledWhiteDescription>-2</StyledWhiteDescription>
                  </GridRightPlayerStat>
                  <GridRightPlayerStat>
                    <StyledReward>
                      <RewardIcon />3
                    </StyledReward>
                  </GridRightPlayerStat>
                </PlayerRightWrapper>
              </PlayerInfoWrapper>

              <PlayerInfoWrapper>
                <PlayerLeftWrapper>
                  <PlayerGridContainer>
                    <PlayerInfo>
                      <PlayerAvatar />
                      <PlayerNickname>DJ_HEAD</PlayerNickname>
                    </PlayerInfo>
                    <PlayerStatistic>
                      <GridDescription>
                        <StyledWhiteDescription>12</StyledWhiteDescription>
                      </GridDescription>
                      <GridDescription>
                        <StyledWhiteDescription>16</StyledWhiteDescription>
                      </GridDescription>
                      <GridDescription>
                        <StyledWhiteDescription>0</StyledWhiteDescription>
                      </GridDescription>
                      <GridDescription>
                        <StyledRedDescription>8</StyledRedDescription>
                      </GridDescription>
                      <GridDescription>
                        <StyledWhiteDescription>1.70</StyledWhiteDescription>
                      </GridDescription>
                      <GridDescription>
                        <StyledWhiteDescription>55.5</StyledWhiteDescription>
                      </GridDescription>
                      <GridDescription>
                        <StyledWhiteDescription>23%</StyledWhiteDescription>
                      </GridDescription>
                      <GridDescription>
                        <StyledWhiteDescription>73.1%</StyledWhiteDescription>
                      </GridDescription>
                    </PlayerStatistic>
                  </PlayerGridContainer>
                </PlayerLeftWrapper>
                <PlayerRightWrapper>
                  <GridRightPlayerStat>
                    <StyledWhiteDescription>12</StyledWhiteDescription>
                  </GridRightPlayerStat>
                  <GridRightPlayerStat>
                    <StyledWhiteDescription>5</StyledWhiteDescription>
                  </GridRightPlayerStat>
                  <GridRightPlayerStat>
                    <StyledWhiteDescription>-2</StyledWhiteDescription>
                  </GridRightPlayerStat>
                  <GridRightPlayerStat>
                    <StyledReward>
                      <RewardIcon />3
                    </StyledReward>
                  </GridRightPlayerStat>
                </PlayerRightWrapper>
              </PlayerInfoWrapper>

              <PlayerInfoWrapper>
                <PlayerLeftWrapper>
                  <PlayerGridContainer>
                    <PlayerInfo>
                      <PlayerAvatar />
                      <PlayerNickname>DJ_HEAD</PlayerNickname>
                    </PlayerInfo>
                    <PlayerStatistic>
                      <GridDescription>
                        <StyledWhiteDescription>12</StyledWhiteDescription>
                      </GridDescription>
                      <GridDescription>
                        <StyledWhiteDescription>16</StyledWhiteDescription>
                      </GridDescription>
                      <GridDescription>
                        <StyledWhiteDescription>0</StyledWhiteDescription>
                      </GridDescription>
                      <GridDescription>
                        <StyledRedDescription>8</StyledRedDescription>
                      </GridDescription>
                      <GridDescription>
                        <StyledWhiteDescription>1.70</StyledWhiteDescription>
                      </GridDescription>
                      <GridDescription>
                        <StyledWhiteDescription>55.5</StyledWhiteDescription>
                      </GridDescription>
                      <GridDescription>
                        <StyledWhiteDescription>23%</StyledWhiteDescription>
                      </GridDescription>
                      <GridDescription>
                        <StyledWhiteDescription>73.1%</StyledWhiteDescription>
                      </GridDescription>
                    </PlayerStatistic>
                  </PlayerGridContainer>
                </PlayerLeftWrapper>
                <PlayerRightWrapper>
                  <GridRightPlayerStat>
                    <StyledWhiteDescription>12</StyledWhiteDescription>
                  </GridRightPlayerStat>
                  <GridRightPlayerStat>
                    <StyledWhiteDescription>5</StyledWhiteDescription>
                  </GridRightPlayerStat>
                  <GridRightPlayerStat>
                    <StyledWhiteDescription>-2</StyledWhiteDescription>
                  </GridRightPlayerStat>
                  <GridRightPlayerStat>
                    <StyledReward>
                      <RewardIcon />3
                    </StyledReward>
                  </GridRightPlayerStat>
                </PlayerRightWrapper>
              </PlayerInfoWrapper>

              <PlayerInfoWrapper>
                <PlayerLeftWrapper>
                  <PlayerGridContainer>
                    <PlayerInfo>
                      <PlayerAvatar />
                      <PlayerNickname>DJ_HEAD</PlayerNickname>
                    </PlayerInfo>
                    <PlayerStatistic>
                      <GridDescription>
                        <StyledWhiteDescription>12</StyledWhiteDescription>
                      </GridDescription>
                      <GridDescription>
                        <StyledWhiteDescription>16</StyledWhiteDescription>
                      </GridDescription>
                      <GridDescription>
                        <StyledWhiteDescription>0</StyledWhiteDescription>
                      </GridDescription>
                      <GridDescription>
                        <StyledRedDescription>8</StyledRedDescription>
                      </GridDescription>
                      <GridDescription>
                        <StyledWhiteDescription>1.70</StyledWhiteDescription>
                      </GridDescription>
                      <GridDescription>
                        <StyledWhiteDescription>55.5</StyledWhiteDescription>
                      </GridDescription>
                      <GridDescription>
                        <StyledWhiteDescription>23%</StyledWhiteDescription>
                      </GridDescription>
                      <GridDescription>
                        <StyledWhiteDescription>73.1%</StyledWhiteDescription>
                      </GridDescription>
                    </PlayerStatistic>
                  </PlayerGridContainer>
                </PlayerLeftWrapper>
                <PlayerRightWrapper>
                  <GridRightPlayerStat>
                    <StyledWhiteDescription>12</StyledWhiteDescription>
                  </GridRightPlayerStat>
                  <GridRightPlayerStat>
                    <StyledWhiteDescription>5</StyledWhiteDescription>
                  </GridRightPlayerStat>
                  <GridRightPlayerStat>
                    <StyledWhiteDescription>-2</StyledWhiteDescription>
                  </GridRightPlayerStat>
                  <GridRightPlayerStat>
                    <StyledReward>
                      <RewardIcon />3
                    </StyledReward>
                  </GridRightPlayerStat>
                </PlayerRightWrapper>
              </PlayerInfoWrapper>

              <PlayerInfoWrapper>
                <PlayerLeftWrapper>
                  <PlayerGridContainer>
                    <PlayerInfo>
                      <PlayerAvatar />
                      <PlayerNickname>DJ_HEAD</PlayerNickname>
                    </PlayerInfo>
                    <PlayerStatistic>
                      <GridDescription>
                        <StyledWhiteDescription>12</StyledWhiteDescription>
                      </GridDescription>
                      <GridDescription>
                        <StyledWhiteDescription>16</StyledWhiteDescription>
                      </GridDescription>
                      <GridDescription>
                        <StyledWhiteDescription>0</StyledWhiteDescription>
                      </GridDescription>
                      <GridDescription>
                        <StyledRedDescription>8</StyledRedDescription>
                      </GridDescription>
                      <GridDescription>
                        <StyledWhiteDescription>1.70</StyledWhiteDescription>
                      </GridDescription>
                      <GridDescription>
                        <StyledWhiteDescription>55.5</StyledWhiteDescription>
                      </GridDescription>
                      <GridDescription>
                        <StyledWhiteDescription>23%</StyledWhiteDescription>
                      </GridDescription>
                      <GridDescription>
                        <StyledWhiteDescription>73.1%</StyledWhiteDescription>
                      </GridDescription>
                    </PlayerStatistic>
                  </PlayerGridContainer>
                </PlayerLeftWrapper>
                <PlayerRightWrapper>
                  <GridRightPlayerStat>
                    <StyledWhiteDescription>12</StyledWhiteDescription>
                  </GridRightPlayerStat>
                  <GridRightPlayerStat>
                    <StyledWhiteDescription>5</StyledWhiteDescription>
                  </GridRightPlayerStat>
                  <GridRightPlayerStat>
                    <StyledWhiteDescription>-2</StyledWhiteDescription>
                  </GridRightPlayerStat>
                  <GridRightPlayerStat>
                    <StyledReward>
                      <RewardIcon />3
                    </StyledReward>
                  </GridRightPlayerStat>
                </PlayerRightWrapper>
              </PlayerInfoWrapper>
            </StyledMainWrapper>
          </StyledMatchWrapper>
          <StyledMatchWrapper>
            <StyledMainWrapper>
              <PlayerInfoWrapper>
                <PlayerLeftWrapper>
                  <PlayerGridContainer>
                    <PlayerInfo>
                      <PlayerAvatar />
                      <PlayerNickname>DJ_HEAD</PlayerNickname>
                    </PlayerInfo>
                    <PlayerStatistic>
                      <GridDescription>
                        <StyledWhiteDescription>12</StyledWhiteDescription>
                      </GridDescription>
                      <GridDescription>
                        <StyledWhiteDescription>16</StyledWhiteDescription>
                      </GridDescription>
                      <GridDescription>
                        <StyledWhiteDescription>0</StyledWhiteDescription>
                      </GridDescription>
                      <GridDescription>
                        <StyledRedDescription>8</StyledRedDescription>
                      </GridDescription>
                      <GridDescription>
                        <StyledWhiteDescription>1.70</StyledWhiteDescription>
                      </GridDescription>
                      <GridDescription>
                        <StyledWhiteDescription>55.5</StyledWhiteDescription>
                      </GridDescription>
                      <GridDescription>
                        <StyledWhiteDescription>23%</StyledWhiteDescription>
                      </GridDescription>
                      <GridDescription>
                        <StyledWhiteDescription>73.1%</StyledWhiteDescription>
                      </GridDescription>
                    </PlayerStatistic>
                  </PlayerGridContainer>
                </PlayerLeftWrapper>
                <PlayerRightWrapper>
                  <GridRightPlayerStat>
                    <StyledWhiteDescription>12</StyledWhiteDescription>
                  </GridRightPlayerStat>
                  <GridRightPlayerStat>
                    <StyledWhiteDescription>5</StyledWhiteDescription>
                  </GridRightPlayerStat>
                  <GridRightPlayerStat>
                    <StyledWhiteDescription>-2</StyledWhiteDescription>
                  </GridRightPlayerStat>
                  <GridRightPlayerStat>
                    <StyledReward>
                      <RewardIcon />3
                    </StyledReward>
                  </GridRightPlayerStat>
                </PlayerRightWrapper>
              </PlayerInfoWrapper>

              <PlayerInfoWrapper>
                <PlayerLeftWrapper>
                  <PlayerGridContainer>
                    <PlayerInfo>
                      <PlayerAvatar />
                      <PlayerNickname>DJ_HEAD</PlayerNickname>
                    </PlayerInfo>
                    <PlayerStatistic>
                      <GridDescription>
                        <StyledWhiteDescription>12</StyledWhiteDescription>
                      </GridDescription>
                      <GridDescription>
                        <StyledWhiteDescription>16</StyledWhiteDescription>
                      </GridDescription>
                      <GridDescription>
                        <StyledWhiteDescription>0</StyledWhiteDescription>
                      </GridDescription>
                      <GridDescription>
                        <StyledRedDescription>8</StyledRedDescription>
                      </GridDescription>
                      <GridDescription>
                        <StyledWhiteDescription>1.70</StyledWhiteDescription>
                      </GridDescription>
                      <GridDescription>
                        <StyledWhiteDescription>55.5</StyledWhiteDescription>
                      </GridDescription>
                      <GridDescription>
                        <StyledWhiteDescription>23%</StyledWhiteDescription>
                      </GridDescription>
                      <GridDescription>
                        <StyledWhiteDescription>73.1%</StyledWhiteDescription>
                      </GridDescription>
                    </PlayerStatistic>
                  </PlayerGridContainer>
                </PlayerLeftWrapper>
                <PlayerRightWrapper>
                  <GridRightPlayerStat>
                    <StyledWhiteDescription>12</StyledWhiteDescription>
                  </GridRightPlayerStat>
                  <GridRightPlayerStat>
                    <StyledWhiteDescription>5</StyledWhiteDescription>
                  </GridRightPlayerStat>
                  <GridRightPlayerStat>
                    <StyledWhiteDescription>-2</StyledWhiteDescription>
                  </GridRightPlayerStat>
                  <GridRightPlayerStat>
                    <StyledReward>
                      <RewardIcon />3
                    </StyledReward>
                  </GridRightPlayerStat>
                </PlayerRightWrapper>
              </PlayerInfoWrapper>

              <PlayerInfoWrapper>
                <PlayerLeftWrapper>
                  <PlayerGridContainer>
                    <PlayerInfo>
                      <PlayerAvatar />
                      <PlayerNickname>DJ_HEAD</PlayerNickname>
                    </PlayerInfo>
                    <PlayerStatistic>
                      <GridDescription>
                        <StyledWhiteDescription>12</StyledWhiteDescription>
                      </GridDescription>
                      <GridDescription>
                        <StyledWhiteDescription>16</StyledWhiteDescription>
                      </GridDescription>
                      <GridDescription>
                        <StyledWhiteDescription>0</StyledWhiteDescription>
                      </GridDescription>
                      <GridDescription>
                        <StyledRedDescription>8</StyledRedDescription>
                      </GridDescription>
                      <GridDescription>
                        <StyledWhiteDescription>1.70</StyledWhiteDescription>
                      </GridDescription>
                      <GridDescription>
                        <StyledWhiteDescription>55.5</StyledWhiteDescription>
                      </GridDescription>
                      <GridDescription>
                        <StyledWhiteDescription>23%</StyledWhiteDescription>
                      </GridDescription>
                      <GridDescription>
                        <StyledWhiteDescription>73.1%</StyledWhiteDescription>
                      </GridDescription>
                    </PlayerStatistic>
                  </PlayerGridContainer>
                </PlayerLeftWrapper>
                <PlayerRightWrapper>
                  <GridRightPlayerStat>
                    <StyledWhiteDescription>12</StyledWhiteDescription>
                  </GridRightPlayerStat>
                  <GridRightPlayerStat>
                    <StyledWhiteDescription>5</StyledWhiteDescription>
                  </GridRightPlayerStat>
                  <GridRightPlayerStat>
                    <StyledWhiteDescription>-2</StyledWhiteDescription>
                  </GridRightPlayerStat>
                  <GridRightPlayerStat>
                    <StyledReward>
                      <RewardIcon />3
                    </StyledReward>
                  </GridRightPlayerStat>
                </PlayerRightWrapper>
              </PlayerInfoWrapper>

              <PlayerInfoWrapper>
                <PlayerLeftWrapper>
                  <PlayerGridContainer>
                    <PlayerInfo>
                      <PlayerAvatar />
                      <PlayerNickname>DJ_HEAD</PlayerNickname>
                    </PlayerInfo>
                    <PlayerStatistic>
                      <GridDescription>
                        <StyledWhiteDescription>12</StyledWhiteDescription>
                      </GridDescription>
                      <GridDescription>
                        <StyledWhiteDescription>16</StyledWhiteDescription>
                      </GridDescription>
                      <GridDescription>
                        <StyledWhiteDescription>0</StyledWhiteDescription>
                      </GridDescription>
                      <GridDescription>
                        <StyledRedDescription>8</StyledRedDescription>
                      </GridDescription>
                      <GridDescription>
                        <StyledWhiteDescription>1.70</StyledWhiteDescription>
                      </GridDescription>
                      <GridDescription>
                        <StyledWhiteDescription>55.5</StyledWhiteDescription>
                      </GridDescription>
                      <GridDescription>
                        <StyledWhiteDescription>23%</StyledWhiteDescription>
                      </GridDescription>
                      <GridDescription>
                        <StyledWhiteDescription>73.1%</StyledWhiteDescription>
                      </GridDescription>
                    </PlayerStatistic>
                  </PlayerGridContainer>
                </PlayerLeftWrapper>
                <PlayerRightWrapper>
                  <GridRightPlayerStat>
                    <StyledWhiteDescription>12</StyledWhiteDescription>
                  </GridRightPlayerStat>
                  <GridRightPlayerStat>
                    <StyledWhiteDescription>5</StyledWhiteDescription>
                  </GridRightPlayerStat>
                  <GridRightPlayerStat>
                    <StyledWhiteDescription>-2</StyledWhiteDescription>
                  </GridRightPlayerStat>
                  <GridRightPlayerStat>
                    <StyledReward>
                      <RewardIcon />3
                    </StyledReward>
                  </GridRightPlayerStat>
                </PlayerRightWrapper>
              </PlayerInfoWrapper>

              <PlayerInfoWrapper>
                <PlayerLeftWrapper>
                  <PlayerGridContainer>
                    <PlayerInfo>
                      <PlayerAvatar />
                      <PlayerNickname>DJ_HEAD</PlayerNickname>
                    </PlayerInfo>
                    <PlayerStatistic>
                      <GridDescription>
                        <StyledWhiteDescription>12</StyledWhiteDescription>
                      </GridDescription>
                      <GridDescription>
                        <StyledWhiteDescription>16</StyledWhiteDescription>
                      </GridDescription>
                      <GridDescription>
                        <StyledWhiteDescription>0</StyledWhiteDescription>
                      </GridDescription>
                      <GridDescription>
                        <StyledRedDescription>8</StyledRedDescription>
                      </GridDescription>
                      <GridDescription>
                        <StyledWhiteDescription>1.70</StyledWhiteDescription>
                      </GridDescription>
                      <GridDescription>
                        <StyledWhiteDescription>55.5</StyledWhiteDescription>
                      </GridDescription>
                      <GridDescription>
                        <StyledWhiteDescription>23%</StyledWhiteDescription>
                      </GridDescription>
                      <GridDescription>
                        <StyledWhiteDescription>73.1%</StyledWhiteDescription>
                      </GridDescription>
                    </PlayerStatistic>
                  </PlayerGridContainer>
                </PlayerLeftWrapper>
                <PlayerRightWrapper>
                  <GridRightPlayerStat>
                    <StyledWhiteDescription>12</StyledWhiteDescription>
                  </GridRightPlayerStat>
                  <GridRightPlayerStat>
                    <StyledWhiteDescription>5</StyledWhiteDescription>
                  </GridRightPlayerStat>
                  <GridRightPlayerStat>
                    <StyledWhiteDescription>-2</StyledWhiteDescription>
                  </GridRightPlayerStat>
                  <GridRightPlayerStat>
                    <StyledReward>
                      <RewardIcon />3
                    </StyledReward>
                  </GridRightPlayerStat>
                </PlayerRightWrapper>
              </PlayerInfoWrapper>
            </StyledMainWrapper>
          </StyledMatchWrapper>
        </StyledMatchContainer>
      </StyledContainer>
    </>
  )
}
export default JudgeVoteMatch

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px 40px 0px 0px;
`
const StyledMatchContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledMatchWrapper = styled.div`
  display: flex;
  margin: 0px 0px 31px 0px;
  height: 403px;
  &:nth-child(2) {
    margin: 0px 0px 16px 0px;
    height: 337px;
  }
`
const StyledMainWrapper = styled.div`
  width: 100%;
  height: 403px;
`
const MatchDescriptionWrapper = styled.div`
  height: 42px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 0px 25px 25px 0px;
`

const MatchDescriptionLeftWrapper = styled.div`
  padding: 4px 57px 0px 0px;
  height: 100%;
  display: flex;
  gap: 11px;
  flex-direction: row;
  align-items: center;
`

const StyledGrayDescription = styled(Header5)`
  text-transform: uppercase;
  opacity: 0.3;
`
const StyledWhiteDescription = styled(Header5)`
  text-transform: uppercase;
`
const StyledRedDescription = styled(Header5)`
  color: #ff4444;
`
const StyledGreenDescription = styled.div`
  color: #2cff80;
`
const GridDescription = styled.div`
  display: flex;
  justify-content: center;
  width: 70px;
  height: 36px;
  align-items: flex-end;
`
const GridRightDescription = styled.div`
  display: flex;
  width: 121px;
  justify-content: center;

  &:first-child {
    width: 77px;
  }
`
const MatchDescriptionRightWrapper = styled.div`
  height: 100%;
  width: 474px;
  border: 1px;
  display: flex;
  gap: 9px;
  flex-direction: row;
  align-items: flex-end;
`
const PlayerInfoWrapper = styled.div`
  height: 62px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 10px;
  margin: 0px 0px 6px 70px;
  position: relative;
`
const PlayerMVP = styled.div`
  width: 49px;
  height: 62px;
  position: absolute;
  left: -42px;
  background: url(${MatchRectangle});
  display: flex;
`
const PlayerMVPDescription = styled(SideHeader4)`
  width: 50%;
  height: 100%;
  display: flex;
  span {
    font-size: 12px;
    margin: 0px 0px 0px 6px;
    display: inline-block;
    transform: rotate(-90deg);
  }
`
const PlayerMVPLogo = styled.div`
  width: 50%;
  height: 100%;
  background: url(${SkullMVPLogo});
  background-repeat: no-repeat;
  background-position-y: 9px;
  background-position-x: 5px;
`
const PlayerLeftWrapper = styled.div`
  height: 100%;wid
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`
const PlayerGridContainer = styled.div`
  padding: 3px 46px 0px 9px;
  display: flex;
  width: 932px;
  height: 100%;
  border: 1px solid #ffffff26;
  border-radius: 3px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`
const PlayerInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
const PlayerStatistic = styled.div`
  display: flex;
  padding: 0px 0px 13px 0px;
  gap: 11px;
`

const PlayerAvatar = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 3px;
  border: 1px solid white;
`
const PlayerNickname = styled(Header5)`
  padding: 0px 0px 0px 15px;
  font-size: 14px;
`

const PlayerRightWrapper = styled.div`
  height: 100%;
  width: 474px;
  display: flex;
  gap: 8px;
  flex-direction: row;
  align-items: flex-end;
`
const StyledReward = styled(Header4)`
  padding: 2px 3px 0px 0px;
  display: flex;
  font-weight: 400;
  align-items: center;
`
const RewardIcon = styled(Coin)`
  fill: #ffcd29;
  width: 17px;
  margin: 0px 8px 3px 0px;
`
const GridRightPlayerStat = styled.div`
  border: 1px solid #ffffff26;
  border-radius: 3px;
  display: flex;
  width: 121px;
  height: 100%;
  justify-content: center;
  align-items: center;
  &:first-child {
    width: 77px;
  }
`
