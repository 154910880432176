import {
  deleteChat,
  updateChatMessagesFromSocket,
} from 'stores/chatStores/singleChat'
import { updateAppealMessagesFromSocket } from 'stores/chatStores/appealStore'
import { updateGroupChatMessagesFromSocket } from 'stores/chatStores/groupChats'
import newMessageSound from 'sounds/new-message.wav'
import playSound from 'utils/sound'

const newMessage = new Audio(newMessageSound)

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const chatListener = async (data: any) => {
  if (data.entityAction === 'UPDATE' || data.entityAction === 'CREATE') {
    if (data.data.isGroup) {
      await updateGroupChatMessagesFromSocket(data.data)
    } else if (data.data.claimId) {
      updateAppealMessagesFromSocket(data.data)
    } else {
      await updateChatMessagesFromSocket(data.data)
    }
    if (data.data.haveUnreadMessage) await playSound(newMessage)
  }
  if (data.entityAction === 'DELETE') {
    deleteChat(data.data.id)
  }
  console.log(data)
}

export default chatListener
