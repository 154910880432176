import { DtoUser } from 'api/schemas/matchApi'
import {
  Header2,
  Header3,
  Header4,
  Header5,
  SideHeader4,
  Text4,
  Text5,
  Text6,
} from 'styles/typography'
import { styled } from 'styled-components'
import { useTranslation } from 'react-i18next'
import AvatarWithFlag from 'components/AvatarWithFlag'
import NickTrimmer from 'components/NickTrimmer'
import PowerCSIcon from 'images/PowerCSIcon'
import WithUserMenu from 'components/ContextMenu/WithUserMenu'
import card_bg from 'images/1x1CSResultCard.png'
import round from 'utils/round'

const StyledRoot = styled.div`
  width: 770px;
  height: 234px;
  background-image: url('${card_bg}');
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 0px 8px;
  position: relative;
  padding: 20px;
  padding-left: 30px;
  row-gap: 18px;
  display: flex;
  flex-direction: column;
  background-size: cover;
`

const StyledEmptyWrapper = styled(Header5)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.3);
`

const StyledProfileInfo = styled.div`
  display: flex;
  gap: 32px;
`

const StyledMainInfoWrapper = styled.div`
  display: flex;
  justify-content: start;
  gap: 9px;
`

const StyledWinRateWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  bottom: 12px;
  right: 12px;
  align-items: center;
  width: 75px;
`

const StyledPower = styled(Header5)`
  display: flex;
  align-items: center;
  gap: 4px;
  font-weight: 600;
`

const StyledMainInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const StyledNickname = styled(Header3)`
  font-weight: 400;
  line-height: 100%;
`

const StyledMatchesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 17px;
`

const StyledShadowText = styled(SideHeader4)`
  color: rgba(255, 255, 255, 0.2);
  text-transform: uppercase;
  left: 12px;
`

const StyledMatchCount = styled(Header2)`
  font-weight: 400;
  line-height: 75%;
`

const StyledStats = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: auto auto auto auto auto auto auto auto;
  height: 65px;
  column-gap: 40px;
`

const StyledMatchStatTitle = styled(SideHeader4)`
  display: flex;
  align-items: end;
  color: rgba(255, 255, 255, 0.2);
  text-transform: uppercase;
  margin-top: 0px;
  grid-column-start: 1;
  grid-column-end: 9;
`

const StyledStatCount = styled(Header4)`
  font-weight: 500;
`
interface StyledChangedRaiting {
  $isNegative?: boolean
}
const StyledDiff = styled(StyledStatCount)<StyledChangedRaiting>`
  color: ${({ $isNegative }) =>
    $isNegative ? 'rgba(255, 68, 68, 1)' : 'rgba(255, 205, 41, 1)'};
`

const StyledStatText = styled(Text5)`
  color: rgba(255, 255, 255, 0.3);
  font-weight: 500;
`

const StyledWinRateCount = styled(Header3)`
  color: #2cff80;
  line-height: 115%; /* 29.9px */
`

const StyledWinRateText = styled(Text4)`
  color: rgba(255, 255, 255, 0.3);
`

const StyledRateWinDiff = styled(Text6)`
  color: rgba(255, 255, 255, 0.3);
  margin-bottom: -2px;
`

interface PlayerCardCSProps {
  score?: number
  player?: DtoUser
}

const PlayerCardResultCS1x1: React.FC<PlayerCardCSProps> = ({
  score,
  player,
}) => {
  const { t } = useTranslation()
  return (
    <>
      {player ? (
        <WithUserMenu profile={player}>
          <StyledRoot>
            <StyledMainInfoWrapper>
              <AvatarWithFlag avatarSrc={player.imgUrl} />
              <StyledMainInfo>
                <StyledPower>
                  <PowerCSIcon />
                  <div>{round(player.CSMatchProfileStat?.rating)}</div>
                </StyledPower>
                <StyledNickname>
                  <NickTrimmer>{player.name}</NickTrimmer>
                </StyledNickname>
              </StyledMainInfo>
            </StyledMainInfoWrapper>
            <StyledProfileInfo>
              <StyledMatchesWrapper>
                <StyledShadowText>{t('results.score')}</StyledShadowText>
                <StyledMatchCount>{score}</StyledMatchCount>
              </StyledMatchesWrapper>

              <StyledStats>
                <StyledMatchStatTitle>
                  {t('play.userCard.matchstat')}
                </StyledMatchStatTitle>
                <StyledStatCount>
                  {player.CSMatchProfileStat?.kill}
                </StyledStatCount>
                <StyledStatCount>
                  {player.CSMatchProfileStat?.dead}
                </StyledStatCount>
                <StyledDiff
                  $isNegative={Number(player.CSMatchProfileStat?.KDDiff) < 0}
                >
                  {round(Number(player.CSMatchProfileStat?.KDDiff))}
                </StyledDiff>
                <StyledStatCount>
                  {round(player.CSMatchProfileStat?.KD, 2)}
                </StyledStatCount>
                <StyledStatCount>
                  {round(player.CSMatchProfileStat?.ADR)}
                </StyledStatCount>
                <StyledStatCount>
                  {round(player.CSMatchProfileStat?.HS)}
                </StyledStatCount>
                <StyledStatCount>
                  {round(player.CSMatchProfileStat?.KAST)}
                </StyledStatCount>
                <StyledStatCount>
                  {round(player.CSMatchProfileStat?.EF)}
                </StyledStatCount>

                <StyledStatText>K</StyledStatText>
                <StyledStatText>D</StyledStatText>
                <StyledStatText>+/-</StyledStatText>
                <StyledStatText>K/D</StyledStatText>
                <StyledStatText>ADR</StyledStatText>
                <StyledStatText>HS%</StyledStatText>
                <StyledStatText>KAST</StyledStatText>
                <StyledStatText>EF</StyledStatText>
              </StyledStats>
            </StyledProfileInfo>
            <StyledWinRateWrapper>
              <StyledRateWinDiff>
                {Number(round(player.CSMatchProfileStat?.rating)) -
                  Number(round(player.CSMatchProfileStat?.ratingDiff))}

                {Number(round(player.CSMatchProfileStat?.ratingDiff)) < 0
                  ? ' - '
                  : ' + '}
                {Math.abs(Number(round(player.CSMatchProfileStat?.ratingDiff)))}
              </StyledRateWinDiff>
              <StyledWinRateCount>
                {round(player.CSMatchProfileStat?.rating)}
              </StyledWinRateCount>
              <StyledWinRateText>{t('results.rating')}</StyledWinRateText>
            </StyledWinRateWrapper>
          </StyledRoot>
        </WithUserMenu>
      ) : (
        <StyledRoot>
          <StyledEmptyWrapper>
            {t('play.userCard.emptyOpponent')}
          </StyledEmptyWrapper>
        </StyledRoot>
      )}
    </>
  )
}

export default PlayerCardResultCS1x1
