import { styled } from 'styled-components'
import dayjs from 'dayjs'

interface PersonMessageProps {
  message?: string
  date: string | null
}

const StyledMessageContainer = styled.div`
  border-radius: 6px 6px 6px 1px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 10px;
  width: fit-content;
`

const StyledMessageWrapper = styled.div`
  width: 100%;
`

const StyledMessage = styled.p`
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 500;
  color: white;
  word-break: break-word;
  user-select: text;
`

const StyledDate = styled.span`
  font-family: Montserrat;
  font-size: 10px;
  font-weight: 500;
  opacity: 0.5;
  color: white;
  float: right;
  margin-left: 10px;
  padding-top: 3px;
`

const PersonMessage: React.FC<PersonMessageProps> = ({ date, message }) => {
  return (
    <StyledMessageWrapper>
      <StyledMessageContainer>
        <StyledMessage>
          {message}
          {date && (
            <StyledDate>{dayjs(date).format('DD.MM.YYYY HH:mm')}</StyledDate>
          )}
        </StyledMessage>
      </StyledMessageContainer>
    </StyledMessageWrapper>
  )
}

export default PersonMessage
