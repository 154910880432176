import {
  $config,
  $lobby,
  $lobbyMemberByPosition,
  $match,
  $teamsPowers,
  Country,
  changeLobbyBet,
  changeLobbyCountry,
  changeTeam,
  getConfig,
  getMatch,
  leaveLobby,
  leaveTeam,
} from 'stores/lobbyStore'
import { $userProfile } from 'stores/userStore'
import {
  Header5,
  Header7,
  SideHeader1,
  SideHeader2,
  SideHeader3,
} from 'styles/typography'
// import { Tooltip } from 'react-tooltip'
// import { exec } from 'child_process'
import { useNavigate } from 'react-router'
import { useStore } from '@nanostores/react'
import { useTranslation } from 'react-i18next'
import AvatarWithRang from 'components/AvatarWithRang'
import BetList from 'components/Game/BetList'
import Button from 'components/Button'
import DotaUserCard from 'components/PlayerCardMin/DotaUserCard'
import LeaveIcon from 'images/LeaveIcon'
import ModeList from 'components/Game/ModeList'
import NickTrimmer from 'components/NickTrimmer'
import PartyMembersIcon from 'images/PartyMembersIcon'
import React, { useEffect, useState } from 'react'
import ServerChoiser from 'components/ServerChoiser/ServerChoiser'
import WithUserMenu from 'components/ContextMenu/WithUserMenu'
import emptySlot from 'images/emptySlot.png'
import emptySlotHover from 'images/emptySlotHover.png'
import routes from 'router/routes'
import styled, { css } from 'styled-components'

const StyledLobbyContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
`
const StyledRoot = styled.div`
  position: relative;
`

const StyledWaitListTitle = styled(Header5)`
  opacity: 0.3;
  text-transform: uppercase;
  padding-right: 16px;

  border-color: rgba(255, 255, 255, 0.25);
  border-right-width: 1px;
`

const StyledLeft = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 16px;
`

interface StyledEmptyCardWrapperProps {
  disabled?: boolean
}

const StyledEmptyCardWrapper = styled.div<StyledEmptyCardWrapperProps>`
  position: relative;
  background-image: url('${emptySlot}');
  width: 158px;
  height: 233px;
  cursor: pointer;
  &:hover {
    background-image: url('${emptySlotHover}');
  }
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.8;
      cursor: default;
      &:hover {
        background-image: url('${emptySlot}');
      }
    `}
`

const StyledEmptySlotTitle = styled(Header5)`
  position: absolute;
  right: 60px;
  top: 112px;
  text-transform: uppercase;
`

const StyledTeams = styled.div`
  position: relative;

  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-top: 24px;
  margin-bottom: 20px;
`

const StyledTeam = styled.div`
  display: flex;
  gap: 5px;
  justify-content: space-between;
`
const StyledVS = styled(SideHeader3)`
  text-transform: uppercase;
`
const StyledTeamsTitle = styled(SideHeader1)`
  position: absolute;
  transform: rotate(-90deg);
  color: rgba(255, 255, 255, 0.2);
  left: -78px;
  top: 26px;
`

const StyledTeamsInfo = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 72px;
  margin-bottom: 22px;
`

const StyledTeamInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 810px;
`

const StyledRedTeamTitle = styled(SideHeader2)`
  color: #ff4444;
  text-transform: uppercase;
  text-shadow: 0px -1px 13px rgba(255, 65, 65, 0.5);
`

const StyledBlueTeamTitle = styled(SideHeader2)`
  text-transform: uppercase;

  color: #0fa9ff;
  text-shadow: 0px -1px 13px rgba(0, 117, 255, 0.5);
`

const StyledPower = styled(SideHeader2)`
  display: flex;
  gap: 12px;
  align-items: center;
`

const StyledPowerTitle = styled(SideHeader3)`
  text-transform: uppercase;
  opacity: 0.5;
`
interface StyledGameSettinsWrapperProps {
  $isHost: boolean
}

const StyledGameSettinsWrapper = styled.div<StyledGameSettinsWrapperProps>`
  position: relative;
  display: flex;
  gap: 21px;
  height: 435px;
  & div {
    ${({ $isHost }) =>
      !$isHost &&
      css`
        cursor: default !important;
      `}
  }
`

const StyledServerTitle = styled(SideHeader1)`
  position: absolute;
  display: flex;
  gap: 6px;
  writing-mode: vertical-rl;
  color: rgba(255, 255, 255, 0.3);
  transform: rotate(180deg);
  justify-content: center;
  text-transform: uppercase;
  left: -10px;
`

const StyledServerWrapper = styled.div`
  position: relative;
  padding-top: 20px;
  padding-left: 34px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  gap: 17.5px;
`

const StyledWaitLisItem = styled.div`
  display: flex;
  align-items: center;
  gap: 7px;
  margin-right: 5px;
  width: 127px;
  max-width: 127px;
`

const StyledWaitName = styled(Header7)`
  max-width: 130px;
`

const StyledWaitList = styled.div`
  width: 1200px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 0 12px;

  background: rgba(255, 255, 255, 0.03);
  border: 1px solid rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(7.5px);

  border-radius: 3px;
`

const StyledPartyMemberLength = styled(Header7)``

const StyledReconnectButton = styled(Button)`
  position: absolute;
  bottom: 40px;
`

const startCSGO = (url: string) => {
  // const launchCommand = `path/to/csgo.exe -lobby ${lobbyParam}`
  // const urlParsed = url.replace('\n', '').replace('"', '').split(':')
  // const str = `steam://rungameid/730//+connect ${url.url.host}:${url.url.port}/+password ${url.url.password}`
  // const str = `steam://run/730/+password ${url.url.password}/+connect ${url.url.host}:${url.url.port}/`
  const str = `steam://rungameid/730//+connect ${url}`
  // const str = `steam://run/730//+connect ${url}`670312/+connect 92.63.104.73:37975/
  console.log(str)
  // const launchCommand = `start "" "${str}"`
  // exec(launchCommand, (error) => {
  //   if (error) {
  //     console.error(`Error launching CS:GO: ${error.message}`)
  //   }
  // })
}

const CustomGameCSPage: React.FC = () => {
  const { t } = useTranslation()

  const lobby = useStore($lobby)
  const config = useStore($config)
  const currentUser = useStore($userProfile)
  const lobbyMemberByPosition = useStore($lobbyMemberByPosition)
  // const isUserReady = useStore($isUserReady)
  const teamsPowers = useStore($teamsPowers)
  const match = useStore($match)

  const [url, setUrl] = useState<string | null>(null)

  const navigate = useNavigate()

  const isHost = currentUser?.id === lobby?.hostId

  const isLobbyBlocked = lobby?.status !== 'NEW'

  const isReady = lobby?.participants?.find(
    (participant) => participant.user?.id === currentUser?.id
  )?.isReady

  const handlePositionClick =
    (position: number, team: 'BLUE' | 'RED') => async () => {
      if (!isReady) {
        await changeTeam(team, position)
      }
    }

  const handleChangeBet = async (bet: number[]) => {
    if (lobby?.status === 'NEW') {
      await changeLobbyBet(bet)
    }
  }

  const handleLeaveLobby = async () => {
    if (lobby?.status === 'NEW') {
      await leaveLobby()
    }
  }

  useEffect(() => {
    if (match && match.status === 'MATCH_STARTED' && !url) {
      setTimeout(() => {
        startCSGO(match?.url || '')
        setUrl(match?.url || '')
      }, 15000)
    }
  }, [match, url])

  useEffect(() => {
    if (!lobby) {
      navigate(routes.play())
    }
  }, [lobby, navigate])

  useEffect(() => {
    if (
      match &&
      (match.status === 'MATCH_FINISHED' ||
        match.status === 'MATCH_PROCESSING_COMPLETED') &&
      match.id === lobby?.lastFinishedMatchId
    ) {
      setUrl(null)
      navigate(routes.results(match?.id || ''))
    }
  }, [match, lobby, navigate])

  useEffect(() => {
    if (!config) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      getConfig()
    }
  }, [config, lobby])

  useEffect(() => {
    if (lobby?.activeMatchId && !match) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      getMatch(lobby?.activeMatchId)
    }
  }, [match, lobby])

  if (
    match &&
    (match.status === 'MATCH_FINISHED' ||
      match.status === 'MATCH_PROCESSING_COMPLETED') &&
    match.id === lobby?.lastFinishedMatchId
  ) {
    return null
  }

  return (
    <StyledRoot>
      <StyledLobbyContainer>
        <StyledLeft>
          <StyledWaitList>
            <StyledWaitListTitle>
              {t('play.customGame.lobby')}
            </StyledWaitListTitle>
            {lobbyMemberByPosition?.wait.map(({ user }) =>
              user ? (
                <WithUserMenu profile={user}>
                  <StyledWaitLisItem key={user.id}>
                    {/* <StyledWaitAvatar src={user.imgUrl ||} /> */}
                    <AvatarWithRang size="xs" avatar={user.imgUrl} />
                    <StyledWaitName>
                      <NickTrimmer>{user.name}</NickTrimmer>
                    </StyledWaitName>
                  </StyledWaitLisItem>
                </WithUserMenu>
              ) : null
            )}
          </StyledWaitList>

          <PartyMembersIcon />
          <StyledPartyMemberLength>
            {lobby?.participants?.length || 0} / 10
          </StyledPartyMemberLength>
        </StyledLeft>
        <Button withCorner onClick={leaveTeam} disabled={isLobbyBlocked}>
          <LeaveIcon />
          <Header5>{t('play.goToWait')}</Header5>
        </Button>
        <Button withCorner disabled={isLobbyBlocked} onClick={handleLeaveLobby}>
          <LeaveIcon />
          <Header5>{t('play.leave')}</Header5>
        </Button>
      </StyledLobbyContainer>
      <StyledTeams>
        <StyledTeamsTitle>{t('play.customGame.team')}</StyledTeamsTitle>
        <StyledTeam>
          {new Array(5).fill('').map((_, index) => {
            if (lobby && lobbyMemberByPosition?.RED[index + 1]) {
              const user = lobbyMemberByPosition?.RED[index + 1]
              return (
                <DotaUserCard
                  key={index}
                  lobby={lobby}
                  disabled={isLobbyBlocked}
                  player={{
                    team: 'RED',
                    profile: user,
                    position: index,
                    isReady: Boolean(user.isReady),
                  }}
                />
              )
            }
            return (
              <StyledEmptyCardWrapper
                key={index}
                disabled={isLobbyBlocked}
                onClick={
                  !isLobbyBlocked
                    ? handlePositionClick(index + 1, 'RED')
                    : undefined
                }
              >
                <StyledEmptySlotTitle>
                  {t('play.customGame.join')}
                </StyledEmptySlotTitle>
              </StyledEmptyCardWrapper>
            )
          })}
        </StyledTeam>
        <StyledVS>{t('play.customGame.vs')}</StyledVS>
        <StyledTeam>
          {new Array(5).fill('').map((_, index) => {
            if (lobby && lobbyMemberByPosition?.BLUE[index + 1]) {
              const user = lobbyMemberByPosition?.BLUE[index + 1]
              return (
                <DotaUserCard
                  key={index}
                  lobby={lobby}
                  disabled={isLobbyBlocked}
                  player={{
                    team: 'BLUE',
                    profile: user,
                    position: index,
                    isReady: Boolean(user.isReady),
                  }}
                />
              )
            }
            return (
              <StyledEmptyCardWrapper
                key={index}
                disabled={isLobbyBlocked}
                onClick={
                  !isLobbyBlocked
                    ? handlePositionClick(index + 1, 'BLUE')
                    : undefined
                }
              >
                <StyledEmptySlotTitle>
                  {t('play.customGame.join')}
                </StyledEmptySlotTitle>
              </StyledEmptyCardWrapper>
            )
          })}
        </StyledTeam>
      </StyledTeams>
      <StyledTeamsInfo>
        <StyledTeamInfo>
          <StyledRedTeamTitle>
            {t('play.customGame.teams.red')}
          </StyledRedTeamTitle>
          <StyledPower>
            {teamsPowers?.RED}
            <StyledPowerTitle>
              {t('play.customGame.teams.power')}
            </StyledPowerTitle>
          </StyledPower>
        </StyledTeamInfo>
        <StyledTeamInfo>
          <StyledBlueTeamTitle>
            {t('play.customGame.teams.blue')}
          </StyledBlueTeamTitle>
          <StyledPower>
            {teamsPowers?.BLUE}
            <StyledPowerTitle>
              {t('play.customGame.teams.power')}
            </StyledPowerTitle>
          </StyledPower>
        </StyledTeamInfo>
      </StyledTeamsInfo>
      <StyledGameSettinsWrapper $isHost={currentUser?.id === lobby?.hostId}>
        <BetList
          bets={config?.bets}
          disabled={isLobbyBlocked}
          currentBets={lobby?.bets || []}
          maxAvailableBet={lobby?.maxAvailableBet}
          onChangeBet={isHost ? handleChangeBet : undefined}
        />
        <ModeList />
        <StyledServerWrapper>
          <StyledServerTitle>{t('play.customGame.server')}</StyledServerTitle>

          <ServerChoiser
            disabled={isLobbyBlocked}
            currentServer={lobby?.country}
            servers={config?.countries as Country[]}
            onChangeCurrentServer={isHost ? changeLobbyCountry : undefined}
          />
          {/* <StartMatchButton
            type="rating"
            lobby={lobby}
            isReady={isUserReady}
            onReadyClick={changeLobbyReady}
            isHostReady={
              lobby?.participants.find(
                (participant) => participant.id === lobby?.hostId
              )?.isReady || currentUser?.id === lobby?.hostId
            }
          /> */}
          {lobby?.status === 'MATCH_PREPARED' && url && (
            <StyledReconnectButton onClick={() => startCSGO(url)}>
              Reconnect
            </StyledReconnectButton>
          )}
        </StyledServerWrapper>
      </StyledGameSettinsWrapper>
    </StyledRoot>
  )
}

export default CustomGameCSPage
