import { $isAppLoading } from 'stores/appStore'
import { $userStatisticPageGameFormat, getMatches } from 'stores/statisticStore'

const statisticLoader = async () => {
  try {
    $isAppLoading.set(true)
    await getMatches($userStatisticPageGameFormat.get())
  } catch (e) {
    console.log(e)
  }
  $isAppLoading.set(false)

  return true
}

export default statisticLoader
