import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import ConfirmActionPopup from 'components/Modals/ConfirmActionPopup'
import routes from 'router/routes'

const ErrorPage = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const goToHome = () => {
    navigate(routes.app())
  }

  const handleRestart = () => {
    // TODO: Добавить перезагрузку для веба
    window.ipcRenderer.invoke('restart')
  }

  return (
    <ConfirmActionPopup
      isOpen
      onDecline={goToHome}
      onAccept={handleRestart}
      caption={t('errors.mainErrirTitle')}
      acceptTitle={t('errors.mainAcceptTitle')}
      declineTitle={t('errors.mainDeclineTitle')}
      description={t('errors.mainErrorDescription')}
    />
  )
}

export default ErrorPage
