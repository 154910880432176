import { $isGroupUserListOpen } from 'stores/chatStores/groupChats'
import { DtoLobbyParticipant } from 'api/schemas/lobbyApi'
import { DtoPartyParticipant } from 'api/schemas/friendsApi'
import { Header7 } from 'styles/typography'
import { styled } from 'styled-components'
import { useStore } from '@nanostores/react'
import Close from 'images/Close'
import NickTrimmer from 'components/NickTrimmer'
import WithUserMenu from 'components/ContextMenu/WithUserMenu'

const StyledWrapper = styled.div`
  display: flex;
  height: 78px;
  width: 100%;
  border-radius: 3px;
  border: 1px solid rgba(255, 255, 255, 0.15);
  background: #1d1e22;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  position: absolute;
  padding: 10px;
`
const StyledClose = styled.div`
  width: 24px;
  height: 24px;
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
`
const StyledUsersWrapper = styled.div`
  display: flex;
  column-gap: 32px;
  flex-wrap: wrap;
  padding-right: 100px;
`
interface IsOnline {
  $isOnline: boolean
}
const StyledUserItem = styled.div<IsOnline>`
  display: flex;
  align-items: center;
  gap: 13px;
  margin-bottom: 16px;
  opacity: ${({ $isOnline }) => ($isOnline ? '1' : '0.5')};
`
const StyledUserAvatar = styled.img`
  display: flex;
  width: 21px;
  height: 21px;
  object-fit: contain;
`
const StyledName = styled(Header7)`
  color: white;
`

interface UserListProps {
  users?: DtoPartyParticipant[] | DtoLobbyParticipant[]
}
const ChatGroupUserList: React.FC<UserListProps> = ({
  users,
}: {
  users?: DtoPartyParticipant[] | DtoLobbyParticipant[]
}) => {
  const isGroupUserListOpen = useStore($isGroupUserListOpen)
  const handleUserListToggle = () => {
    $isGroupUserListOpen.set(!isGroupUserListOpen)
  }

  return (
    <StyledWrapper>
      <StyledUsersWrapper>
        {users?.map(({ user }) => {
          return user ? (
            <WithUserMenu
              key={user.id}
              profile={{
                id: user.id,
                isChosen: false,
                name: user.name,
                online: user.online,
                imgUrl: user.imgUrl,
                country: user.country,
              }}
            >
              <StyledUserItem key={user.id} $isOnline={!!user.online}>
                <StyledUserAvatar src={user.imgUrl} />
                <StyledName>
                  <NickTrimmer>{user.name}</NickTrimmer>
                </StyledName>
              </StyledUserItem>
            </WithUserMenu>
          ) : null
        })}
      </StyledUsersWrapper>
      <StyledClose onClick={handleUserListToggle}>
        <Close />
      </StyledClose>
    </StyledWrapper>
  )
}

export default ChatGroupUserList
