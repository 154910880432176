const OpenButtonBGDots = ({ className }: { className?: string }) => (
  <svg
    width="14"
    fill="none"
    height="42"
    viewBox="0 0 14 42"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.1">
      <rect fill="white" x="0.712891" width="1.52847" height="1.64702" />
      <rect y="16" fill="white" x="0.712891" width="1.52847" height="1.64702" />
      <rect y="32" fill="white" x="0.712891" width="1.52847" height="1.64702" />
      <rect
        y="4.11755"
        fill="white"
        x="0.712891"
        width="1.52847"
        height="1.64702"
      />
      <rect
        y="20.1176"
        fill="white"
        x="0.712891"
        width="1.52847"
        height="1.64702"
      />
      <rect
        y="36.1176"
        fill="white"
        x="0.712891"
        width="1.52847"
        height="1.64702"
      />
      <rect
        y="8.23511"
        fill="white"
        x="0.712891"
        width="1.52847"
        height="1.64702"
      />
      <rect
        y="24.2351"
        fill="white"
        x="0.712891"
        width="1.52847"
        height="1.64702"
      />
      <rect
        y="40.2351"
        fill="white"
        x="0.712891"
        width="1.52847"
        height="1.64702"
      />
      <rect
        y="12.353"
        fill="white"
        x="0.712891"
        width="1.52847"
        height="1.64702"
      />
      <rect
        y="28.353"
        fill="white"
        x="0.712891"
        width="1.52847"
        height="1.64702"
      />
      <rect x="4.5332" fill="white" width="1.52847" height="1.64702" />
      <rect y="16" x="4.5332" fill="white" width="1.52847" height="1.64702" />
      <rect y="32" x="4.5332" fill="white" width="1.52847" height="1.64702" />
      <rect
        x="4.5332"
        y="4.11755"
        fill="white"
        width="1.52847"
        height="1.64702"
      />
      <rect
        x="4.5332"
        y="20.1176"
        fill="white"
        width="1.52847"
        height="1.64702"
      />
      <rect
        x="4.5332"
        y="36.1176"
        fill="white"
        width="1.52847"
        height="1.64702"
      />
      <rect
        x="4.5332"
        y="8.23511"
        fill="white"
        width="1.52847"
        height="1.64702"
      />
      <rect
        x="4.5332"
        y="24.2351"
        fill="white"
        width="1.52847"
        height="1.64702"
      />
      <rect
        x="4.5332"
        y="40.2351"
        fill="white"
        width="1.52847"
        height="1.64702"
      />
      <rect
        y="12.353"
        x="4.5332"
        fill="white"
        width="1.52847"
        height="1.64702"
      />
      <rect
        y="28.353"
        x="4.5332"
        fill="white"
        width="1.52847"
        height="1.64702"
      />
      <rect x="8.35547" fill="white" width="1.52847" height="1.64702" />
      <rect y="16" x="8.35352" fill="white" width="1.52847" height="1.64702" />
      <rect y="32" x="8.35352" fill="white" width="1.52847" height="1.64702" />
      <rect
        y="4.11755"
        x="8.35547"
        fill="white"
        width="1.52847"
        height="1.64702"
      />
      <rect
        y="20.1176"
        x="8.35352"
        fill="white"
        width="1.52847"
        height="1.64702"
      />
      <rect
        y="36.1176"
        x="8.35352"
        fill="white"
        width="1.52847"
        height="1.64702"
      />
      <rect
        y="8.23511"
        x="8.35547"
        fill="white"
        width="1.52847"
        height="1.64702"
      />
      <rect
        y="24.2351"
        x="8.35352"
        fill="white"
        width="1.52847"
        height="1.64702"
      />
      <rect
        y="40.2351"
        x="8.35352"
        fill="white"
        width="1.52847"
        height="1.64702"
      />
      <rect
        y="12.353"
        x="8.35547"
        fill="white"
        width="1.52847"
        height="1.64702"
      />
      <rect
        y="28.353"
        x="8.35352"
        fill="white"
        width="1.52847"
        height="1.64702"
      />
      <rect x="12.1758" fill="white" width="1.52847" height="1.64702" />
      <rect y="16" x="12.1758" fill="white" width="1.52847" height="1.64702" />
      <rect y="32" x="12.1758" fill="white" width="1.52847" height="1.64702" />
      <rect
        y="4.11768"
        x="12.1758"
        fill="white"
        width="1.52847"
        height="1.64702"
      />
      <rect
        y="20.1177"
        x="12.1758"
        fill="white"
        width="1.52847"
        height="1.64702"
      />
      <rect
        y="36.1177"
        x="12.1758"
        fill="white"
        width="1.52847"
        height="1.64702"
      />
      <rect
        y="8.23529"
        x="12.1758"
        fill="white"
        width="1.52847"
        height="1.64702"
      />
      <rect
        y="24.2354"
        x="12.1758"
        fill="white"
        width="1.52847"
        height="1.64702"
      />
      <rect
        y="40.2354"
        x="12.1758"
        fill="white"
        width="1.52847"
        height="1.64702"
      />
      <rect
        y="12.353"
        x="12.1758"
        fill="white"
        width="1.52847"
        height="1.64702"
      />
      <rect
        y="28.353"
        x="12.1758"
        fill="white"
        width="1.52847"
        height="1.64702"
      />
    </g>
  </svg>
)

export default OpenButtonBGDots
