import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { DtoClaimPreview, EnumClaimStatus } from 'api/schemas/judgeApi'
import { setModalType } from 'stores/appStore'
import { setReportPopupId } from 'stores/judgeStore'
import ClaimsTableBody from 'components/ClaimsTable/ClaimsTableBody'
import ClaimsTableHeader from 'components/ClaimsTable/ClaimsTableHeader'
import Input from 'components/Input'
import Loading from 'components/Loading'
import Search from 'images/Search'
import useFilteredClaims from 'components/ClaimsTable/useFilteredClaims'

interface ClaimsTableProps {
  claims: DtoClaimPreview[]
  isLoading: boolean
}

const ClaimsTable = ({ claims, isLoading }: ClaimsTableProps) => {
  const { t } = useTranslation()

  const [searchFilter, setSearchFilter] = useState('')
  const [statusFilter, setStatusFilter] = useState<EnumClaimStatus>()
  const [decisionFilter, setDecisionFilter] = useState<boolean | null>()

  const filteredClaims = useFilteredClaims(
    claims,
    searchFilter,
    statusFilter,
    decisionFilter
  )

  const handleReportClick = ({ id }: DtoClaimPreview) => {
    if (id) {
      setReportPopupId(id)
      setModalType('report')
    }
  }

  return (
    <StyledTable>
      <StyledInput>
        <Search />
        <Input
          hideBorder
          value={searchFilter}
          onChange={(e) => setSearchFilter(e.target.value)}
          placeholder={t('judge.judgePage.judgeInputPlaceholder')}
        />
      </StyledInput>

      <ClaimsTableHeader
        status={statusFilter}
        decision={decisionFilter}
        onChangeStatus={setStatusFilter}
        onChangeDecision={setDecisionFilter}
      />

      {isLoading ? (
        <StyledLoadingWrapper>
          <Loading />
        </StyledLoadingWrapper>
      ) : (
        <ClaimsTableBody data={filteredClaims} onClick={handleReportClick} />
      )}
    </StyledTable>
  )
}

const StyledTable = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 50px;
`

const StyledLoadingWrapper = styled.div`
  height: 200px;
  margin-bottom: 80px;
`

const StyledInput = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  width: 100%;
  padding: 4px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);

  & > div {
    width: 100%;
  }
`

export default ClaimsTable
