const DeclineIcon = ({ className }: { className?: string }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip10_20639_54084123)">
      <path
        clipRule="evenodd"
        fillRule="evenodd"
        d="M13.9896 11.7069L19.6063 6.09018L17.9099 4.39381L12.2932 10.0106L6.38326 4.10063L4.61113 5.87276L10.5211 11.7827L4.39371 17.91L6.09007 19.6064L12.2174 13.4791L18.1274 19.389L19.8995 17.6169L13.9896 11.7069Z"
      />
    </g>
    <defs>
      <clipPath id="clip10_20639_54084123">
        <rect width="24" height="24" />
      </clipPath>
    </defs>
  </svg>
)

export default DeclineIcon
