/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  $TIME_TO_CONFIRM_RESERVE,
  $confirmCount,
  $isConfirmPopupOpen,
  $timeToConfirmEnd,
} from 'stores/lobbyStore'
import { t } from 'i18next'

const matchmakingListiner = (data: any) => {
  if (data.event === 'READINESS_CONFIRMATION') {
    // Время оставшееся для подтверждения = Время окончания принятия (из сокета) - текущее время пользователя - запас времени
    $timeToConfirmEnd.set(
      Math.round(
        (+(data.data.endAt + '000') - Date.now() - $TIME_TO_CONFIRM_RESERVE) /
          1000
      )
    )

    $isConfirmPopupOpen.set(true)
    const NOTIFICATION_TITLE = t('gameFindedPopup.caption')
    const NOTIFICATION_BODY = t('gameFindedPopup.clickToGoToApp')
    if (document.hidden) {
      new window.Notification(NOTIFICATION_TITLE, {
        body: NOTIFICATION_BODY,
      }).onclick = () => {
        console.log(document.hidden)
        window.ipcRenderer.invoke('open')
      }
    }
  }
  if (data.event === 'CONFIRM') {
    $confirmCount.set(data.data.total)
  }
  if (data.event === 'DECLINE') {
    $isConfirmPopupOpen.set(false)
  }
  console.log(data)
}

export default matchmakingListiner
