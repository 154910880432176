const RecentFriendsIcon = ({ className }: { className?: string }) => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1228_13198)">
      <path
        clipRule="evenodd"
        fillRule="evenodd"
        d="M12 20.6946C16.5187 20.6946 20.1818 17.0315 20.1818 12.5128C20.1818 7.99412 16.5187 4.331 12 4.331C7.48131 4.331 3.81818 7.99412 3.81818 12.5128C3.81818 17.0315 7.48131 20.6946 12 20.6946ZM12 22.5128C17.5228 22.5128 22 18.0357 22 12.5128C22 6.98997 17.5228 2.51282 12 2.51282C6.47715 2.51282 2 6.98997 2 12.5128C2 18.0357 6.47715 22.5128 12 22.5128Z"
      />
      <path
        clipRule="evenodd"
        fillRule="evenodd"
        d="M12 7.05827C12.5021 7.05827 12.9091 7.46529 12.9091 7.96736V12.5908L15.8246 15.5064C16.1797 15.8614 16.1797 16.437 15.8246 16.792C15.4696 17.147 14.894 17.147 14.539 16.792L11.0909 13.3439C11.0909 13.3439 11.0909 8.46944 11.0909 7.96736C11.0909 7.46529 11.4979 7.05827 12 7.05827Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_1228_13198">
        <rect width="24" height="24" transform="translate(0 0.512817)" />
      </clipPath>
    </defs>
  </svg>
)

export default RecentFriendsIcon
