import { Text4 } from 'styles/typography'
import styled from 'styled-components'

interface Props {
  title: string
  slideNumber: string
  description: string
}

const InfoCardContent = ({ title, slideNumber, description }: Props) => {
  return (
    <>
      <SlideNumber>{slideNumber}</SlideNumber>
      <TextContainer>
        <Title>{title}</Title>
        <Description>{description}</Description>
      </TextContainer>
    </>
  )
}

const SlideNumber = styled.span`
  color: #000;
  font-family: Ruberoid;
  font-size: 170px;
  font-style: normal;
  font-weight: 400;
  line-height: 80%;
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
`

const Title = styled.div`
  font-family: Ruberoid;
  font-size: 42px;
  font-style: normal;
  font-weight: 400;
  line-height: 80%;
  color: #000;
  text-transform: uppercase;
`

const Description = styled(Text4)`
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
  color: #000;
`

export default InfoCardContent
