import { $friendsStatistic } from 'stores/friendsStore'
import {
  $nickHistory,
  $userPopupInfo,
  $userPopupStat,
} from 'stores/statisticStore'
import { Header2, Header3 } from 'styles/typography'
import { useStore } from '@nanostores/react'
import { useTranslation } from 'react-i18next'
import CopyWrapper from 'components/CopyWrapper'
import NickTrimmer from 'components/NickTrimmer'
import Select from 'components/Select'
import dayjs from 'dayjs'
import styled from 'styled-components'

interface StyledImg {
  $imgUrl?: string
}

const StyledWrapper = styled.div`
  display: flex;
  padding: 32px 24px;
  border-radius: 0px 6px;
  border: 1px solid rgba(255, 255, 255, 0.15);
  background: rgba(255, 255, 255, 0.03);
`
const StyledUserNameWrapper = styled.div`
  display: flex;
  position: relative;
  z-index: 1;
`
const StyledImage = styled.img<StyledImg>`
  min-width: 84px;
  height: 84px;
  background-image: url(${({ $imgUrl }) => ($imgUrl ? $imgUrl : 'white')});
  background-size: cover;
  background-position: center;
  border-radius: 1px;
  border: unset;
`
const StyledUser = styled.div`
  display: flex;
  gap: 32px;
`
const StyledUserName = styled(Header2)`
  line-height: 125%;
  color: white;
  max-width: 500px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-top: -10px;
`
const StyledUserInfoWrapper = styled.div`
  display: flex;
  gap: 40px;
`
const StyledAfterImage = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`
const StyledUserInfo = styled(Header3)`
  color: white;
  line-height: 115.5%;
  font-weight: 400;
`
const StyledUserInfoText = styled.div`
  display: inline-flex;
  color: #ffcd29;
  margin-left: 10px;
`
const StyledSelect = styled(Select)`
  width: 185px !important;
  margin-left: 16px;

  & .react-dropdown-select-input {
    width: 140px !important;
  }

  & .react-dropdown-select-dropdown > div > div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`
const StyledStatsWrapper = styled.div`
  display: flex;
  gap: 24px;
  margin-left: auto;
`
const StyledStat = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 8px 16px;
  border-radius: 0px 6px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: radial-gradient(
      233.39% 158.15% at 64.48% 136.84%,
      rgba(41, 101, 255, 0.1) 0%,
      rgba(0, 0, 0, 0) 100%
    ),
    rgba(255, 255, 255, 0.03);
  height: 84px;
`
const StyledStatValue = styled(Header3)`
  font-weight: 400;
  color: #2cff80;
`
const StyledStatDescr = styled(Header3)`
  font-weight: 400;
  color: white;
  opacity: 0.3;
`

const PeronalTopInfo: React.FC = () => {
  const userPopupStat = useStore($userPopupStat)
  const userInfo = useStore($userPopupInfo)
  const options = useStore($nickHistory)
  const friendsStatistic = useStore($friendsStatistic)
  const { t } = useTranslation()
  return (
    <StyledWrapper>
      <StyledUser>
        <StyledImage $imgUrl={userInfo?.imgUrl} />
        <StyledAfterImage>
          <StyledUserNameWrapper>
            <StyledUserName>
              <NickTrimmer>{userInfo?.name}</NickTrimmer>
            </StyledUserName>
            <StyledSelect
              values={[]}
              disableSelect
              options={options}
              onChange={() => ''}
              placeholder={t('UserAccountPopup.previousName')}
            />
          </StyledUserNameWrapper>
          <StyledUserInfoWrapper>
            <StyledUserInfo>
              {t('UserAccountPopup.profileID')}:
              <CopyWrapper textToCopy={userInfo?.externalId || ''}>
                <StyledUserInfoText>#{userInfo?.externalId}</StyledUserInfoText>
              </CopyWrapper>
            </StyledUserInfo>
            {userInfo?.createdAt && (
              <StyledUserInfo>
                {t('UserAccountPopup.since')}:
                <StyledUserInfoText>
                  {dayjs(userInfo.createdAt).format('DD/MM/YYYY')}
                </StyledUserInfoText>
              </StyledUserInfo>
            )}
          </StyledUserInfoWrapper>
        </StyledAfterImage>
      </StyledUser>
      <StyledStatsWrapper>
        <StyledStat>
          <StyledStatValue>{friendsStatistic?.length || 0}</StyledStatValue>
          <StyledStatDescr>{t('UserAccountPopup.friends')}</StyledStatDescr>
        </StyledStat>
        <StyledStat>
          <StyledStatValue>
            {Math.round((userPopupStat?.spentTimeInGamesMin || 0) / 60)}
          </StyledStatValue>
          <StyledStatDescr>
            {t('UserAccountPopup.hoursOnService')}
          </StyledStatDescr>
        </StyledStat>
      </StyledStatsWrapper>
    </StyledWrapper>
  )
}

export default PeronalTopInfo
