import { Header2, Header3, Text3, Text4, Text5 } from 'styles/typography'
import { useTranslation } from 'react-i18next'

import ArrowIcon from 'images/judge/FilesArrowIcon.svg'
import JudgeFilesTexture from 'images/judge/JudgeFilesTexture.svg'

import Button from 'components/Button'
import CopyWrapper from 'components/CopyWrapper'
import UploadFilesIcon from 'images/judge/UploadFilesIcon.svg'
import styled from 'styled-components'

const TicketInfo = () => {
  const { t } = useTranslation()

  const filescount = 3

  return (
    <>
      <StyledInfo>
        <StyledDescrpitionContainer>
          <StyledTicketInfo>
            <StyledInfoTitle>
              {t('judge.voteModal.information')}
            </StyledInfoTitle>
            <StyledIdContainer>
              <CopyWrapper textToCopy={'123456'}>
                <StyledDescription>
                  <StyledTicket>
                    {t('judge.voteModal.applicationId')}
                  </StyledTicket>
                  <StyledTicketID>123456</StyledTicketID>
                </StyledDescription>
              </CopyWrapper>
            </StyledIdContainer>
          </StyledTicketInfo>
          <StyledDescription>
            <StyledTicketReason>
              <MatchDetails>
                <StyledMatchDetails>
                  <InfoContainer>
                    <SetTicketDescription>Игрок</SetTicketDescription>
                    <TicketDescription>
                      <StyledGrayDescription>
                        {t('judge.voteModal.category')}
                      </StyledGrayDescription>
                    </TicketDescription>
                  </InfoContainer>
                  <InfoContainer>
                    <SetTicketDescription>353AB</SetTicketDescription>
                    <MarginTicketInfo>
                      <StyledGrayDescription>
                        {t('judge.voteModal.matchId')}
                      </StyledGrayDescription>
                    </MarginTicketInfo>
                  </InfoContainer>
                  <InfoContainer>
                    <SetTicketDescription>-</SetTicketDescription>
                    <MarginTicketInfo>
                      <StyledGrayDescription>
                        {t('judge.voteModal.timing')}
                      </StyledGrayDescription>
                    </MarginTicketInfo>
                  </InfoContainer>
                </StyledMatchDetails>
                <InfoContainer>
                  <SetTicketDescription>
                    Неспортивное поведение
                  </SetTicketDescription>
                  <MarginTicketInfo>
                    <StyledGrayDescription>
                      {t('judge.voteModal.reason')}
                    </StyledGrayDescription>
                  </MarginTicketInfo>
                </InfoContainer>
              </MatchDetails>
            </StyledTicketReason>
            <StyledReason>
              <StyledReasonText>
                <StyledGrayDescription>
                  {t('judge.voteModal.descrpition')}
                </StyledGrayDescription>
              </StyledReasonText>
              <StyledReasonContainer>
                <StyledScrollbar>
                  <StyledReasonDescription>
                    Игрок использовал AIM. Посмотрите на его КД! На записи
                    видно, что его курсор донаводится до противника.
                  </StyledReasonDescription>
                </StyledScrollbar>
              </StyledReasonContainer>
            </StyledReason>
          </StyledDescription>
        </StyledDescrpitionContainer>
        <StyledAttachedFiles>
          <AttachedFiles>
            {t('judge.voteModal.attachedFiles')}
            <UploadButton>
              <img src={UploadFilesIcon} />
            </UploadButton>
          </AttachedFiles>
          <FilesContainer>
            <FilesContentContainer>
              <FilesArrowPrev $filescount={filescount} />
              <Files $filescount={filescount}>
                {Array.from({ length: filescount }, (_, index) => (
                  <File key={index} $filescount={filescount}></File>
                ))}
              </Files>
              <FilesArrowNext $filescount={filescount} />
            </FilesContentContainer>
            <ContentCount>
              <Uploadfilescount>{filescount}</Uploadfilescount>
              <StyledGrayDescription>
                {t('judge.voteModal.countFiles')}
              </StyledGrayDescription>
            </ContentCount>
          </FilesContainer>
        </StyledAttachedFiles>
      </StyledInfo>
    </>
  )
}
export default TicketInfo

const StyledInfo = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: 3px;
  border: 1px solid #ffffff26;
  width: 100%;
  height: 263px;
  margin: 0px 0px 18px 0px;
  background: url('${JudgeFilesTexture}'),
    linear-gradient(0deg, rgba(29, 30, 34, 0.5), rgba(29, 30, 34, 0.5)),
    linear-gradient(
      180deg,
      rgba(114, 132, 180, 0.1) 0%,
      rgba(59, 79, 131, 0) 100%
    );
`

const StyledDescrpitionContainer = styled.div`
  display: flex;
  height: 100%;
  width: 56%;
  flex-direction: column;
`
const StyledDescription = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`
const StyledReason = styled.div`
  padding: 5px 24px 26px 24px;
`
const StyledReasonText = styled(Text5)`
  padding: 0px 0px 3px 0px;
  font-weight: 500;
`
const StyledReasonContainer = styled.div`
  padding: 3px;
  border-radius: 3px;
  border: 1px solid #ffffff26;
  height: 130px;
  width: 365px;
`
const StyledReasonDescription = styled(Text5)`
  font-weight: 500;
  padding: 7px 10px 0px 10px;
`
const StyledScrollbar = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% + -3px);
  height: 100%;
  overflow-y: scroll;
  position: relative;

  &::-webkit-scrollbar {
    width: 2px;
  }

  &::-webkit-scrollbar-track {
    margin: 4px 0 4px;
    border-radius: 1px;
    opacity: 0.1;
    background: rgba(217, 217, 217, 0.1);
  }

  &::-webkit-scrollbar-thumb {
    background-color: white;
    border-radius: 1px;
  }
`
const StyledTicketInfo = styled.div`
  display: flex;
  padding: 16px 24px 0px 22px;
  height: 85px;
  width: 100%;
  justify-content: space-between;
`
const StyledTicketReason = styled.div`
  border: 1px solid #ffffff26;
  border-bottom: none;
  border-left: none;
  border-radius: 0px 25px 0px 3px;
  width: 430px;
  height: 178px;
`
const StyledInfoTitle = styled(Header3)`
  font-weight: 400;
`
const StyledIdContainer = styled.div`
  margin: 3px 0px 0px 0px;
  padding: 5px 25px 5px 25px;
  display: flex;
  width: auto;
  align-items: center;
  height: 31px;
  border-radius: 100px;
  border: 1px solid rgba(255, 255, 255, 0.3);
`
const StyledTicket = styled(Text4)`
  border-right: 1px solid #ffffff26;
  font-weight: 500;
  padding: 0px 10px 0px 0px;
`
const StyledTicketID = styled(Text4)`
  font-weight: 500;
  padding: 0px 0px 0px 10px;
`
const MatchDetails = styled.div`
  padding: 12px 0px 0px 23px;
  display: flex;
  flex-direction: column;
`
const StyledMatchDetails = styled.div`
  display: flex;
  margin: 0px 0px 13px 0px;
`
const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0px 6px 0px 0px;
  &:nth-child(2) {
    padding: 5px 0px 0px 0px;
  }
  &:nth-child(3) {
    margin: 0px 0px 0px 8px;
    padding: 5px 0px 0px 0px;
  }
`
const SetTicketDescription = styled(Text3)`
  &:not(nth-child(2)) {
    line-height: 31px;
    margin: 0px 0px 7px 0px;
  }
`
const TicketDescription = styled(Text4)`
  display: flex;
  height: 20px;
  margin: 5px 29px 0px 0px;
  align-items: center;
`
const MarginTicketInfo = styled(Text4)`
  display: flex;
  height: 20px;
  margin: 0px 29px 0px 0px;
  align-items: center;
`
const StyledGrayDescription = styled.div`
  opacity: 0.3;
`
const UploadButton = styled(Button)`
  display: flex;
  border: 1px solid #ffffff26;
  width: 47px;
  height: 47px;
  border-radius: 3px;
  background-color: #2a2d35;
  justify-content: center;
  align-items: center;
`
const Uploadfilescount = styled(Header2)`
  font-weight: 400;
  line-height: 37px;
`
const StyledAttachedFiles = styled.div`
  padding: 14px 13px 22px 0px;
  display: flex;
  border-left: 1px solid #ffffff26;
  height: 100%;
  width: 44%;
  flex-direction: column;
`
const FilesContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  height: 100%;
`
const AttachedFiles = styled(Text3)`
  margin: 0px 0px 3px 22px;
  display: flex;
  justify-content: space-between;
`
const FilesContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 176px;
`
const ContentCount = styled.div`
  line-height: 20px;
  text-wrap: nowrap;
  text-align: right;
  display: flex;
  flex-direction: column;
`

interface FilesProps {
  $filescount: number
}

const Files = styled.div<FilesProps>`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  margin: 0px 0px 0px 22px;

  ${({ $filescount }) =>
    $filescount === 2 &&
    `  
  justify-content: flex-start;  
  margin: 0px 0px 0px 22px;
`}

  ${({ $filescount }) =>
    $filescount === 3 &&
    ` 
  &:not(:first-child) {  
    display: flex;  
    flex-wrap: wrap;  
    flex-direction: column;  
    margin: 0px 0px 0px 22px;
  }
`}

    ${({ $filescount }) =>
    $filescount > 3 &&
    ` 
  &:not(:first-child) {  
    display: flex;  
    flex-wrap: wrap;  
    flex-direction: column;  
    margin: 0px 0px 0px 0px;
  }
`}
`

const File = styled.div<FilesProps>`
  border-radius: 5px;
  border: 1px solid #ffffff4d;
  width: 100%;
  height: 100%;
  transition: 0.3s;

  &:hover {
    border: 2px solid #ffcd29;
  }

  ${({ $filescount }) =>
    $filescount === 2 &&
    `  
  width: 176px;  
  height: 100%;  
  &:not(:first-child) {  
    margin: 0px 0px 0px 22px;  
  }  

`}

  ${({ $filescount }) =>
    $filescount >= 3 &&
    `  
  
  width: 176px;  
  &:not(:first-child) {    
    width: 200px;  
    height: 84px;  
    margin: 0px 5px 0px 24px;
  }  
  &:nth-child(n+4) {  
    display: none;  
  }  
  &:first-child {
    margin: 0px 0px 0px 0px;
  }
`}
`
const FilesArrowNext = styled.div<FilesProps>`
  display: flex;
  width: 24px;
  background-image: url('${ArrowIcon}');
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0.3;
  transition: 0.3s;
  &:hover {
    opacity: 1;
  }
  ${({ $filescount }) =>
    $filescount <= 3 &&
    `  
  display: none;
`}
`
const FilesArrowPrev = styled.div<FilesProps>`
  width: 24px;
  background-image: url('${ArrowIcon}');
  background-repeat: no-repeat;
  background-position: center;
  transform: rotate(180deg);
  opacity: 0.3;
  transition: 0.3s;
  &:hover {
    opacity: 1;
  }
  ${({ $filescount }) =>
    $filescount <= 3 &&
    `  
  display: none;
`}
`
