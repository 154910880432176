import { Header1, Header5, Text3, Text4 } from 'styles/typography'
import { useTranslation } from 'react-i18next'

import JudgeStatisticTexture from 'images/judge/JudgeStatisticTexture.svg'

import Statistic from 'components/Judge/Ticket/PlayerStatistic'
import styled from 'styled-components'

const TicketStatistic = () => {
  const { t } = useTranslation()

  return (
    <>
      <StyledStatistic>
        <StyledPersonInfo>
          <StyledPersonContainer>
            <StyledPersonStatus>
              <SetTicketInfo>
                Обвиняемый
                <MarginTicketInfo>
                  <StyledGrayDescription>
                    {t('judge.voteModal.statistic')}
                  </StyledGrayDescription>
                </MarginTicketInfo>
              </SetTicketInfo>
            </StyledPersonStatus>
            <PlayerInfo>
              <StyledWinrateContainer>
                <StyledPlayerStatistic>
                  {t('judge.voteModal.winrate')}
                </StyledPlayerStatistic>
                <StyledWinrate>100%</StyledWinrate>
              </StyledWinrateContainer>
              <PlayerName>
                Username
                <PlayerAvatar />
              </PlayerName>
            </PlayerInfo>
          </StyledPersonContainer>
          <PlayerStatisticContainer>
            <PlayerStatistic>
              <PlayerStatisticHeader>
                <PlayerInfoContainer>
                  <SetPlayerInfo>2.1 / 3.2</SetPlayerInfo>
                  <MarginTicketInfo>
                    <StyledGrayDescription>KDA</StyledGrayDescription>
                  </MarginTicketInfo>
                </PlayerInfoContainer>
                <PlayerInfoContainer>
                  <SetPlayerInfo>26.3 / 3</SetPlayerInfo>
                  <MarginTicketInfo>
                    <StyledGrayDescription>LH / DH</StyledGrayDescription>
                  </MarginTicketInfo>
                </PlayerInfoContainer>
                <PlayerInfoContainer>
                  <SetPlayerInfo>16.3K</SetPlayerInfo>
                  <MarginTicketInfo>
                    <StyledGrayDescription>DMG</StyledGrayDescription>
                  </MarginTicketInfo>
                </PlayerInfoContainer>
              </PlayerStatisticHeader>
              <PlayerInfoContainer>
                <SetPlayerInfo>15.3k</SetPlayerInfo>
                <MarginTicketInfo>
                  <StyledGrayDescription>NET</StyledGrayDescription>
                </MarginTicketInfo>
              </PlayerInfoContainer>
            </PlayerStatistic>
            <PlayerMatchesStatistic>
              <PlayerMatches>
                <MatchDescriptionContainer>
                  <MatchDescription>
                    <Circle color="#00D656" />
                    {t('judge.voteModal.win')}
                  </MatchDescription>
                  <MatchCount>8</MatchCount>
                </MatchDescriptionContainer>
                <MatchDescriptionContainer>
                  <MatchDescription>
                    <Circle color="#F2B927" />
                    {t('judge.voteModal.draw')}
                  </MatchDescription>
                  <MatchCount>0</MatchCount>
                </MatchDescriptionContainer>
                <MatchDescriptionContainer>
                  <MatchDescription>
                    <Circle color="#F84848" />
                    {t('judge.voteModal.lose')}
                  </MatchDescription>
                  <MatchCount>0</MatchCount>
                </MatchDescriptionContainer>
              </PlayerMatches>
              <PlayerMatchesTotal>
                <PlayerMatchesCount>8</PlayerMatchesCount>
                <StyledGrayDescription>
                  {t('judge.voteModal.matchesCount')}
                </StyledGrayDescription>
              </PlayerMatchesTotal>
            </PlayerMatchesStatistic>
          </PlayerStatisticContainer>
        </StyledPersonInfo>
        <StyledProcent>
          <Statistic />
        </StyledProcent>
      </StyledStatistic>
    </>
  )
}
export default TicketStatistic

const StyledStatistic = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 265px;
`
const StyledProcent = styled.div`
  height: 100%;
  width: 25%;
`

const SetTicketInfo = styled(Text3)`
  &:not(nth-child(2)) {
    line-height: 31px;
    margin: 0px 0px 7px 0px;
  }
`
const MarginTicketInfo = styled(Text4)`
  display: flex;
  height: 20px;
  margin: 0px 29px 0px 0px;
  align-items: center;
`
const StyledGrayDescription = styled.div`
  opacity: 0.3;
`

const StyledPersonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
const StyledPersonStatus = styled.div`
  line-height: 29px;
  padding: 15px 0px 0px 22px;
`
const StyledPersonInfo = styled.div`
  display: flex;
  border: 1px solid #ffffff26;
  border-radius: 3px;
  height: 100%;
  width: 66%;
  flex-direction: column;
  background-image: url('${JudgeStatisticTexture}');
`
const StyledPlayerStatistic = styled(Text4)`
  line-height: 4px;
  opacity: 0.3;
`
const PlayerInfo = styled.div`
  display: flex;
  padding: 20px 0px 0px 0px;
  border-left: 1px solid #ffffff26;
  border-bottom: 1px solid #ffffff26;
  border-radius: 0px 0px 0px 25px;
  height: 92px;
  width: 57.9%;
  flex-direction: row;
  justify-content: space-between;
`
const StyledWinrateContainer = styled.div`
  margin: 0px 0px 0px 69px;
  padding: 11px 25px 11px 25px;
  display: flex;
  align-items: center;
  height: 42px;
  border-radius: 100px;
  border: 1px solid rgba(255, 255, 255, 0.3);

  /* BlurEffect */
  backdrop-filter: blur(7.5px);
`
const StyledWinrate = styled(Text4)`
  margin: 0px 0px 0px 8px;
  color: #2cff80;
`
const PlayerName = styled(Text3)`
  display: flex;
`
const PlayerAvatar = styled.div`
  margin: 0px 22px 0px 19px;
  border: 1px solid gray;
  width: 42px;
  height: 42px;
  border-radius: 3px;
`
const PlayerStatisticContainer = styled.div`
  display: flex;
  justify-content: space-between;
`
const PlayerStatistic = styled.div`
  padding: 36px 0px 0px 22px;
  display: flex;
  flex-direction: column;
`
const PlayerInfoContainer = styled.div`
  line-height: 31px;
  &:last-child {
  }
  &:nth-child(3) {
    margin: 0px 0px 0px -11px;
  }
`
const PlayerStatisticHeader = styled.div`
  display: flex;
  margin: 0px 0px 7px 0px;
`
const SetPlayerInfo = styled(Text3)`
  margin: 0px 35px 0px 0px;
`
const PlayerMatchesStatistic = styled(Text4)`
  padding: 60px 22px 0px 0px;
  display: flex;
`
const PlayerMatches = styled.div`
  margin: 0px 51px 0px 0px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`
const MatchDescriptionContainer = styled.div`
  margin: 0px 0px 8px 0px;
  height: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
`
const MatchDescription = styled(Text4)`
  font-weight: 500;
  display: flex;
  align-items: center;
  margin: 0px 11px 0px 0px;
`
const MatchCount = styled(Header5)``
const Circle = styled.div`
  margin: 0px 12px 0px 0px;
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background-color: ${(props) => props.color};
`
const PlayerMatchesCount = styled(Header1)`
  line-height: 50px;
`
const PlayerMatchesTotal = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-around;
}
`
