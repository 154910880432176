const startCSGO = (url: string) => {
  // const launchCommand = `path/to/csgo.exe -lobby ${lobbyParam}`
  // const urlParsed = url.replace('\n', '').replace('"', '').split(':')
  // const str = `steam://rungameid/730//+connect ${url.url.host}:${url.url.port}/+password ${url.url.password}`
  // const str = `steam://run/730/+password ${url.url.password}/+connect ${url.url.host}:${url.url.port}/`
  // const str = `steam://rungameid/730//+connect ${url}`
  // // const str = `steam://run/730//+connect ${url}`670312/+connect 92.63.104.73:37975/
  // const launchCommand = `start "" "${str}"`
  // exec(launchCommand, (error) => {
  //   if (error) {
  //     console.error(`Error launching CS:GO: ${error.message}`)
  //   }
  // })
  window.open(`steam://rungameid/730//+connect ${url}`)
}

export default startCSGO
