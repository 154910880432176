import { Header1, Header5, Text5 } from 'styles/typography'
import { NavLink, Outlet } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import HostIcon from 'images/HostIcon'
import routes from 'router/routes'
import styled from 'styled-components'

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: end;
  height: 100%;
  padding-left: 80px;
  padding-right: 0px;
`
const StyledTabs = styled(Header1)`
  display: flex;
  gap: 32px;

  text-transform: uppercase;
`

const StyledMenuItem = styled(NavLink)`
  position: relative;
  overflow: hidden;
  height: 78px;
  transition: 0.2s;
  &.active {
    color: #2cff80;
    &::after {
      content: '';
      display: inline-block;
      position: absolute;
      left: 0;
      bottom: 0;
      opacity: 0.5;
      filter: blur(11.5px);
      background: radial-gradient(
        50.69% 62.96% at 52% 100%,
        #2cff80 0%,
        rgba(44, 255, 128, 0) 100%
      );
      width: 100%;
      height: 100%;
    }
  }
`

const StyledPlayHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
`

const StyledHost = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
`

const StyledHostTitle = styled(Text5)`
  display: flex;
  gap: 6px;
  align-items: center;
`

const StyledHostName = styled(Header5)``

const PlayDotaLayout: React.FC = () => {
  const { t } = useTranslation()

  return (
    <>
      <StyledWrapper>
        <StyledPlayHeader>
          <StyledTabs>
            <StyledMenuItem to={routes.playRating()}>
              {t('play.tabs.rating')}
            </StyledMenuItem>
            <StyledMenuItem to={routes.playCustom()}>
              {t('play.tabs.custom')}
            </StyledMenuItem>
          </StyledTabs>
          <StyledHost>
            <StyledHostName>MarkQuincy</StyledHostName>
            <StyledHostTitle>
              {t('play.host')} <HostIcon />
            </StyledHostTitle>
          </StyledHost>
        </StyledPlayHeader>

        <Outlet />
      </StyledWrapper>
    </>
  )
}

export default PlayDotaLayout
