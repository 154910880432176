import { useEffect, useState } from 'react'
import { useStore } from '@nanostores/react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { $currentWorkClaim, $myFinishedClaims } from 'stores/judgeStore'
import {
  Header1,
  Header2,
  Header3,
  Header4,
  Text4,
  Text5,
} from 'styles/typography'
import { getMyWorkJudgeStatistic } from 'stores/statisticStore'
import DailyTaskItem from 'components/Judge/Work/DailyTaskItem'
import Loading from 'components/Loading'
import ProgressBar from 'components/ProgressBar'
import RewardInfo from 'components/Judge/Work/RewardInfo'
import progressBg from 'images/judge/progress-bg.webp'
import statsBg from 'images/judge/stats-bg.webp'
import useJudgeWorkStatistic from 'components/Judge/Work/useStatistic'

const MyWorkRating = () => {
  const { t } = useTranslation()
  const myFinishedClaims = useStore($myFinishedClaims)
  const currentWorkClaim = useStore($currentWorkClaim)

  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const fetchStatistic = async () => {
      await getMyWorkJudgeStatistic()
      setIsLoading(false)
    }

    void fetchStatistic()
  }, [])

  const {
    todayFinishedCount,
    thisMonthFinishedCount,
    todayFinishedPercentage,
    lastToThisMonthPercentage,
  } = useJudgeWorkStatistic()

  return (
    <StyledRoot>
      <StyledProgressBlock>
        {isLoading ? (
          <Loading />
        ) : (
          <>
            <StyledTitlesWrapper>
              <StyledTitles>
                <StyledTitle>{t('judge.judgePage.progressTitle')}</StyledTitle>
                <StyledDescription>
                  {t('judge.judgePage.progressDescription')}
                </StyledDescription>
              </StyledTitles>
              <StyledLeftTitleProgress>
                <StyledSubTitles>
                  <StyledTitle>{todayFinishedCount}/10</StyledTitle>
                  <StyledDescription>
                    {t('judge.judgePage.completed')}
                  </StyledDescription>
                </StyledSubTitles>
                <StyledDivider />
                <StyledStatsTitleNumber>
                  {todayFinishedPercentage}%
                </StyledStatsTitleNumber>
              </StyledLeftTitleProgress>
            </StyledTitlesWrapper>
            <StyledDailyProgressTasks>
              {Array.from({ length: 10 }).map((_, index) => (
                <DailyTaskItem
                  key={index}
                  id={index + 1}
                  claimId={
                    index === todayFinishedCount
                      ? currentWorkClaim?.externalId
                      : myFinishedClaims[index]?.externalId
                  }
                  todayFinishedCount={todayFinishedCount}
                />
              ))}
            </StyledDailyProgressTasks>
            <ProgressBar progress={todayFinishedPercentage} />
          </>
        )}
      </StyledProgressBlock>

      <StyledStatsBlock>
        {isLoading ? (
          <Loading />
        ) : (
          <>
            <StyledTitlesWrapper>
              <StyledTitles>
                <StyledTitle>{t('judge.judgePage.statsTitle')}</StyledTitle>
                <StyledDescription>
                  {t('judge.judgePage.statsDescription')}
                </StyledDescription>
              </StyledTitles>
            </StyledTitlesWrapper>
            <StyledStatsNumbersWrapper>
              <StyledDisabledText>
                {t('judge.judgePage.all')}
              </StyledDisabledText>
              <StyledMonthStatsWrapper>
                <StyledLastMonthStatsNumber>
                  {thisMonthFinishedCount}/
                </StyledLastMonthStatsNumber>
                <StyledLastMonthStatsWrapper>
                  <StyledPercentBlock
                    $negative={lastToThisMonthPercentage.startsWith('-')}
                  >
                    {lastToThisMonthPercentage}
                  </StyledPercentBlock>
                  <StyledDisabledText>
                    <Text5>{t('judge.judgePage.withLastMonth')}</Text5>
                  </StyledDisabledText>
                </StyledLastMonthStatsWrapper>
              </StyledMonthStatsWrapper>
            </StyledStatsNumbersWrapper>
          </>
        )}
      </StyledStatsBlock>

      <RewardInfo />
    </StyledRoot>
  )
}

interface StyledPercentBlockProps {
  $negative: boolean
}

const StyledPercentBlock = styled(Header4)<StyledPercentBlockProps>`
  border-radius: 3px;
  border: 1px solid var(--Button-Disabled, rgba(255, 255, 255, 0.15));
  background: rgba(0, 137, 55, 0.15);

  padding: 3px 8px;

  width: fit-content;

  font-weight: 400;
  color: ${({ $negative }) => ($negative ? '#FF4444' : '#2cff80')};
`

const StyledLastMonthStatsNumber = styled(Header1)`
  font-weight: 400;
  line-height: 100%;
`

const StyledLastMonthStatsWrapper = styled.div`
  display: flex;
  flex-direction: column;

  gap: 8px;
`

const StyledMonthStatsWrapper = styled.div`
  display: flex;
  gap: 8px;
`

const StyledStatsNumbersWrapper = styled.div`
  display: flex;
  flex-direction: column;

  gap: 16px;
`

const StyledDailyProgressTasks = styled.div`
  display: flex;
  gap: 1px;
`

const StyledStatsTitleNumber = styled(Header2)`
  color: #2cff80;
  font-weight: 400;
`

const StyledDivider = styled.div`
  height: 33px;
  width: 3px;
  border-radius: 2px;
  background-color: #2cff80;
`

const StyledLeftTitleProgress = styled.div`
  display: flex;
  gap: 22px;
  align-items: center;
  height: fit-content;
`

const StyledTitlesWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

const StyledTitles = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`

const StyledSubTitles = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledTitle = styled(Header3)`
  text-transform: uppercase;
`

const StyledDisabledText = styled.div`
  color: var(--Button-Disabled, rgba(255, 255, 255, 0.15));
`

const StyledDescription = styled(Text4)`
  color: var(--Button-Disabled, rgba(255, 255, 255, 0.15));
`

const StyledStatsBlock = styled.div`
  width: 359px;
  height: 100%;
  padding: 24px;
  padding-top: 16px;
  background-image: url('${statsBg}');
  background-repeat: none;
  background-size: cover;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const StyledProgressBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: 789px;
  height: 100%;
  padding: 24px;
  padding-top: 16px;
  background-image: url('${progressBg}');
  background-repeat: none;
  background-size: cover;
`

const StyledRoot = styled.div`
  display: flex;
  justify-content: space-between;
  min-height: 294px;
`

export default MyWorkRating
