const QuestionCircle = ({ className }: { className?: string }) => (
  <svg
    width="14"
    height="14"
    fill="none"
    viewBox="0 0 14 14"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle r="6" cy="7" cx="7" stroke="#FF4444" />
    <path
      fill="#FF4444"
      d="M9.43309 5.888C9.43309 6.152 9.35309 6.392 9.19309 6.608C9.04109 6.816 8.84509 6.98 8.60509 7.1L7.81309 7.496C7.60509 7.592 7.50109 7.732 7.50109 7.916V8.804H6.51709V7.868C6.51709 7.716 6.54509 7.576 6.60109 7.448C6.65709 7.312 6.71709 7.2 6.78109 7.112C6.84509 7.024 6.94909 6.932 7.09309 6.836C7.24509 6.74 7.35709 6.672 7.42909 6.632C7.50909 6.584 7.64109 6.516 7.82509 6.428C8.01709 6.34 8.13709 6.284 8.18509 6.26C8.36109 6.164 8.44909 6.028 8.44909 5.852V5.096C8.44909 5.04 8.44109 4.996 8.42509 4.964C8.37709 4.86 8.28109 4.748 8.13709 4.628C8.00109 4.5 7.88109 4.436 7.77709 4.436H6.46909C6.42109 4.436 6.38109 4.444 6.34909 4.46C6.24509 4.516 6.12509 4.612 5.98909 4.748C5.85309 4.876 5.75309 5 5.68909 5.12L5.56909 5.36L4.62109 5.084C4.73309 4.82 4.84109 4.616 4.94509 4.472C5.43309 3.824 5.92909 3.5 6.43309 3.5H7.78909C8.09309 3.5 8.36109 3.584 8.59309 3.752C8.68109 3.816 8.79709 3.92 8.94109 4.064C9.08509 4.208 9.18109 4.316 9.22909 4.388C9.36509 4.596 9.43309 4.824 9.43309 5.072V5.888ZM7.60909 10.76H6.40909V9.608H7.60909V10.76Z"
    />
  </svg>
)

export default QuestionCircle
