const JoinButtonDots: React.FC = () => {
  return (
    <svg
      fill="none"
      height="13"
      width="299"
      viewBox="0 0 299 13"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        y2="0.5"
        y1="0.5"
        x1="0.5"
        x2="298.5"
        stroke="#FFCD29"
        strokeLinecap="square"
        strokeDasharray="0.1 8"
      />
      <line
        y2="6.5"
        y1="6.5"
        x1="0.5"
        x2="298.5"
        stroke="#FFCD29"
        strokeLinecap="square"
        strokeDasharray="0.1 8"
      />
      <line
        x1="0.5"
        y2="12.5"
        y1="12.5"
        x2="298.5"
        stroke="#FFCD29"
        strokeLinecap="square"
        strokeDasharray="0.1 8"
      />
    </svg>
  )
}

export default JoinButtonDots
