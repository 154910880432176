const PartyInviteIcon = ({ className }: { className?: string }) => (
  <svg
    width="66"
    height="32"
    fill="none"
    viewBox="0 0 66 32"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="#FFCD29"
      clipRule="evenodd"
      fillRule="evenodd"
      d="M32.5 10H30.5V13.5L27 13.5V15.5H30.5V19H32.5V15.5H36V13.5L32.5 13.5V10Z"
    />
    <path
      fill="#FFCD29"
      clipRule="evenodd"
      fillRule="evenodd"
      d="M49.6616 8.76921L45.7094 12.8373C44.702 13.8743 44.702 15.5555 45.7094 16.5925C46.1089 17.0037 46.6068 17.2518 47.125 17.3369C47.1713 17.6009 47.1406 17.8819 46.9534 18.1816C46.7349 18.5315 46.4376 18.8743 46.1717 19.1463C45.5022 18.9289 44.8721 18.547 44.3414 18.0007C42.5784 16.186 42.5784 13.2438 44.3414 11.4291L48.2935 7.36102C50.0565 5.54633 52.9148 5.54633 54.6778 7.36102C56.4407 9.1757 56.4407 12.1179 54.6778 13.9326L50.8184 17.9052C50.8708 17.4426 50.8754 16.9923 50.8228 16.5773C50.7807 16.245 50.6763 15.8209 50.543 15.3723L53.3097 12.5244C54.3171 11.4874 54.3171 9.80617 53.3097 8.76921C52.3023 7.73224 50.669 7.73224 49.6616 8.76921ZM42.2778 14.3646C42.2778 14.2415 42.2926 14.1026 42.318 13.955C42.3034 13.9696 42.2889 13.9844 42.2744 13.9993L38.3222 18.0674C36.5593 19.8821 36.5593 22.8243 38.3222 24.639C40.0852 26.4537 42.9435 26.4537 44.7065 24.639L48.6586 20.5709C50.4216 18.7562 50.4216 15.814 48.6586 13.9993C48.1137 13.4384 47.4642 13.0509 46.7746 12.8367C46.5989 13.0284 46.406 13.2436 46.201 13.4795C45.8449 13.8892 45.738 14.2981 45.751 14.6459C46.3134 14.7097 46.8594 14.9636 47.2906 15.4075C48.298 16.4445 48.298 18.1257 47.2906 19.1627L43.3384 23.2308C42.331 24.2678 40.6977 24.2678 39.6903 23.2308C38.6829 22.1938 38.6829 20.5126 39.6903 19.4756L42.5502 16.5318L42.5465 16.522C42.2778 15.8029 42.2778 15.1944 42.2778 14.3646Z"
    />
  </svg>
)

export default PartyInviteIcon
