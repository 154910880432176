import { Text4, Text5 } from 'styles/typography'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

interface VoteProps {
  owner?: boolean
  status?: 'process' | 'voted'
}

const VoteStatus: React.FC<VoteProps> = ({ owner, status }) => {
  const { t } = useTranslation()

  return (
    <JudgeVote $status={status} $owner={owner}>
      <JudgeVoteResult>
        <JudgeVoteCircle $status={status} />
        <JudgeVoteCircle $status={status} />
        <JudgeVoteCircle $status={status} $owner={owner} />
        <JudgeVoteOwner $status={status} $owner={owner}>
          {t('judge.voteModal.you')}
        </JudgeVoteOwner>
      </JudgeVoteResult>
      <JudgeVoteStatus $status={status}>{getText(status)}</JudgeVoteStatus>
    </JudgeVote>
  )
}

interface StyledVoteProps {
  $owner?: boolean
  $status?: 'process' | 'voted'
}

const getText = (status: 'voted' | 'process' = 'voted') => {
  return status === 'process' ? 'В процессе' : 'Голос отдан'
}

const getElemColor = (status: 'voted' | 'process' = 'voted') => {
  return status === 'process' ? '#ffffff' : '#000000'
}

const getBackgroundColor = (status: 'voted' | 'process' = 'voted') => {
  return status === 'process' ? '#1D1E2280' : '#FFCD29'
}

const JudgeVote = styled.div<StyledVoteProps>`
  margin: 0px 0px 0px 16px;
  display: flex;
  width: 113px;
  height: 108px;
  border-radius: 4px;
  border: 1px solid #ffffff26;
  background-color: ${({ $status }) => getBackgroundColor($status)};
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`

const JudgeVoteResult = styled.div`
  padding: 11px;
  width: 100%;
  display: flex;
`
const JudgeVoteCircle = styled.div<StyledVoteProps>`
  margin: 0px 3px 0px 0px;
  width: 9px;
  height: 9px;
  border-radius: 100%;
  border: 1px solid;
  border-color: ${({ $status }) => getElemColor($status)};
  ${({ $owner }) =>
    $owner &&
    `
    &:nth-child(3) {
    display: none;
    }
    `}
`
const JudgeVoteStatus = styled(Text4)<StyledVoteProps>`
  font-size: 14px;
  font-weight: 500;
  color: ${({ $status }) => getElemColor($status)};
  margin: 0px 0px 11px 0px;
`
const JudgeVoteOwner = styled(Text5)<StyledVoteProps>`
  display: none;
  color: ${({ $status }) => getElemColor($status)};
  ${({ $owner }) =>
    $owner &&
    `
    display: flex;
    background: #2cff80;
    width: 32px;
    height: 14px;
    border-radius: 35px;
    justify-content: center;
    margin: -5px 0px 0px 32px;
    `}
`
export default VoteStatus
