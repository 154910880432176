import {
  $isCodeGenerate,
  $qrImage,
  $userAuthenticatorCode,
  clearUserAuthenticatorCode,
  userOtpConfirm,
  userTotpGenerate,
} from 'stores/userStore'
import { Header3, Header6, Text4 } from 'styles/typography'
import { SpinnerCircularFixed } from 'spinners-react'
import { useEffect, useState } from 'react'
import { useStore } from '@nanostores/react'
import { useTranslation } from 'react-i18next'
import BackArrow from 'images/BackArrow.svg'
import CodeInput from 'components/CodeInput/CodeInput'
import styled from 'styled-components'

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 576px;
  min-height: 250px;
  border-radius: 6px;
  border: 1px solid rgba(255, 255, 255, 0.25);
  padding: 64px 24px 24px 24px;
  background: var(
    --dashboard-small,
    linear-gradient(
      180deg,
      rgba(114, 132, 180, 0.1) 0%,
      rgba(59, 79, 131, 0) 100%
    ),
    rgba(29, 30, 34, 0.5)
  );
  backdrop-filter: blur(7.5px);
`
const StyledQrWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 267px;
  height: 267px;
`
interface QrProps {
  $src: string
}
const StyledCaption = styled(Header3)`
  color: #ffcd29;
  text-transform: uppercase;
  line-height: 115%;
`
const StyledQrImage = styled.div<QrProps>`
  width: 100%;
  height: 100%;
  background-image: ${({ $src }) => `url('${$src || null}')`};
  margin-top: 12px;
`
const StyledDescription = styled(Text4)`
  line-height: 125%;
  color: white;
  margin-top: 18px;
  text-align: center;
  opacity: 0.4;
`
const StyledCode = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 34px;
  border: 1px solid rgb(255 205 41 / 40%);
  margin-top: 12px;
  width: 100%;
  gap: 12px;
`
const StyledCodeItem = styled(Text4)`
  color: #ffcd29;
  text-transform: uppercase;
  font-size: 15px;
`
const StyledLoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`
const StyledWriteDescription = styled(Text4)`
  line-height: 125%;
  margin-top: 9px;
`

const StyledCodeInputWrapper = styled.div`
  margin-top: 17px;
`
const StyledStep = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 12px;
  width: 100%;
  height: 42px;
  border-radius: 3px;
  border: 1px solid rgba(255, 255, 255, 0.15);
  background: rgba(255, 255, 255, 0.03);
  margin-top: 46px;
  cursor: pointer;
`
const StyledStepText = styled(Header6)`
  text-transform: uppercase;
`
const StyledStepInfo = styled(Text4)`
  color: #ffcd29;
`
const StyledStepLogo = styled.div`
  width: 24px;
  height: 24px;
  background-image: url(${BackArrow});
`

type Steps = 'readCode' | 'writeCode'

const QrCodePopup: React.FC = () => {
  const { t } = useTranslation()
  const userAuthenticatorCode = useStore($userAuthenticatorCode)
  const isCodeGenerate = useStore($isCodeGenerate)
  const qrImage = useStore($qrImage)
  const [step, setStep] = useState<Steps>('readCode')
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const getData = async () => {
      await userTotpGenerate()
    }
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    getData()

    return () => {
      clearUserAuthenticatorCode()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleChangeCode = async (value: string) => {
    if (value.length === 6) {
      console.log(value)
      setLoading(true)
      await userOtpConfirm(value)
      setLoading(false)
    }
  }

  return (
    <StyledWrapper>
      {step === 'readCode' && (
        <>
          {!isCodeGenerate && !loading ? (
            <>
              <StyledCaption>{t('qrCode.scan')}</StyledCaption>
              <StyledQrWrapper>
                {qrImage && <StyledQrImage $src={qrImage} />}
              </StyledQrWrapper>
              <StyledDescription>{t('qrCode.orEnter')}</StyledDescription>
              <StyledCode>
                {userAuthenticatorCode?.codes
                  ? userAuthenticatorCode.codes.map((item) => (
                      <StyledCodeItem key={item}>{item}</StyledCodeItem>
                    ))
                  : null}
              </StyledCode>
              <StyledStep onClick={() => setStep('writeCode')}>
                <StyledStepText>{t('qrCode.next')}</StyledStepText>
                <StyledStepInfo>1 {t('qrCode.of')} 2</StyledStepInfo>
              </StyledStep>
            </>
          ) : (
            <StyledLoaderWrapper>
              <SpinnerCircularFixed
                size="48"
                thickness={250}
                color="#ffcd29"
                secondaryColor="rgba(255, 255, 255, 0.1)"
              />
            </StyledLoaderWrapper>
          )}
        </>
      )}
      {step === 'writeCode' && (
        <>
          {!loading ? (
            <>
              <StyledCaption>{t('qrCode.enterConfirmationCode')}</StyledCaption>
              <StyledWriteDescription>
                {t('qrCode.enterCode')}
              </StyledWriteDescription>
              <StyledCodeInputWrapper>
                <CodeInput length={6} onChange={handleChangeCode} />
              </StyledCodeInputWrapper>
              <StyledStep onClick={() => setStep('readCode')}>
                <StyledStepText>{t('registation.backButton')}</StyledStepText>
                <StyledStepLogo />
              </StyledStep>
            </>
          ) : (
            <StyledLoaderWrapper>
              <SpinnerCircularFixed
                size="48"
                thickness={250}
                color="#ffcd29"
                secondaryColor="rgba(255, 255, 255, 0.1)"
              />
            </StyledLoaderWrapper>
          )}
        </>
      )}
    </StyledWrapper>
  )
}

export default QrCodePopup
