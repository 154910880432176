const JudgeStarIcon = ({ className }: { className?: string }) => (
  <svg
    width="22"
    height="24"
    viewBox="0 0 22 24"
    className={className}
    fill="inherit"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11 0L13.4 7.84308L21.3923 6L15.8 12L21.3923 18L13.4 16.1569L11 24L8.6 16.1569L0.607696 18L6.2 12L0.607696 6L8.6 7.84308L11 0Z"
      fill="#FFCD29"
    />
  </svg>
)

export default JudgeStarIcon
