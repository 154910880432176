/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface DtoCSProfileStat {
  ADR?: number
  HS?: number
  KD?: number
  KDDiff?: number
  assist?: number
  confidence?: number
  createdAt?: string
  damage?: number
  dead?: number
  gameFormat?: string
  headShotCount?: number
  id?: string
  isCalibration?: boolean
  kill?: number
  lastMatchesResult?: DtoMatchUserResult[]
  loseStreak?: number
  matchDrawCount?: number
  matchLoseCount?: number
  matchWinCount?: number
  nextRankRating?: number
  prevRankRating?: number
  rank?: string
  rating?: number
  roundCount?: number
  score?: number
  steamId?: string
  updatedAt?: string
  userExternalId?: string
  userId?: string
  userImg?: string
  userName?: string
  winStreak?: number
}

export interface DtoCountry {
  id?: string
  imgUrl?: string
  name?: string
}

export interface DtoDOTA2ProfileStat {
  DH?: number
  DMG?: number
  LH?: number
  NET?: number
  assist?: number
  createdAt?: string
  dead?: number
  gameFormat?: string
  id?: string
  isCalibration?: boolean
  kill?: number
  matchDrawCount?: number
  matchLoseCount?: number
  matchWinCount?: number
  nextRankRating?: number
  prevRankRating?: number
  rank?: string
  rating?: number
  score?: number
  steamId?: string
  updatedAt?: string
  userExternalId?: string
  userId?: string
  userImg?: string
  userName?: string
}

export interface DtoGameMap {
  gameName?: string
  id?: string
  imgIconUrl?: string
  imgUrl?: string
  label?: string
  name?: string
}

export interface DtoGameMode {
  gameName?: string
  id?: string
  imgUrl?: string
  name?: string
}

export interface DtoLobby {
  activeMatchId?: string
  activeMatchMakingId?: string
  /** for rating lobby - multiple choose */
  bets?: number[]
  country?: DtoCountry
  createdAt?: string
  createdBy?: string
  gameFormat?: string
  gameMaps?: DtoGameMap[]
  gameModes?: DtoGameMode[]
  gameName?: string
  hostId?: string
  id?: string
  invitedParticipants?: DtoLobbyParticipant[]
  isRating?: boolean
  lastFinishedMatchId?: string
  maxAvailableBet?: number
  maxParticipantSize?: number
  /** есть только у рейтингово лобби которое появилось в результате merge */
  parentLobbyIds?: string[]
  participants?: DtoLobbyParticipant[]
  status?: string
  statusTimestamp?: string
  teamSlotSize?: number
}

export interface DtoLobbyCreate {
  gameFormat?: string
  gameName?: string
}

export interface DtoLobbyParticipant {
  invitedBy?: DtoUserShort
  isReady?: boolean
  originLobbyId?: string
  slotOrder?: number
  status?: string
  teamColor?: string
  user?: DtoUser
}

export interface DtoMatchUserResult {
  externalId?: string
  finishAt?: string
  gameFormat?: string
  gameMap?: DtoGameMap
  gameMode?: DtoGameMode
  gameName?: string
  id?: string
  isDraw?: boolean
  isLose?: boolean
  isWin?: boolean
  startAt?: string
}

export interface DtoUser {
  CSProfileStat?: DtoCSProfileStat
  DOTA2ProfileStat?: DtoDOTA2ProfileStat
  balance?: number
  country?: string
  id?: string
  imgUrl?: string
  name?: string
  online?: boolean
  steamId?: string
}

export interface DtoUserShort {
  country?: string
  id?: string
  imgUrl?: string
  name?: string
}

export interface LobbyChooseBet {
  bets?: number[]
}

export interface LobbyChooseMap {
  mapIds?: string[]
}

export interface LobbyChooseMode {
  modeIds?: string[]
}

export interface LobbyCloseInvite {
  invitedParticipantIds?: string[]
}

export interface LobbyCreateInvite {
  participantId?: string
}

export interface LobbyJoinTeam {
  slotOrder?: number
  teamColour?: string
}

export interface ResponseResponse {
  data?: any
  meta?: ResponseMeta
  pagination?: ResponsePagination
}

export interface ResponseMeta {
  code?: string
  error?: string
  message?: string
  requestId?: string
  traceId?: string
}

export interface ResponsePagination {
  page?: number
  pages?: number
  perPage?: number
}

export namespace Lobby {
  /**
   * @description Create custom lobby
   * @tags Lobby
   * @name PostLobby
   * @summary Create custom lobby
   * @request POST:/lobby
   */
  export namespace PostLobby {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = DtoLobbyCreate
    export type RequestHeaders = {
      /**
       * Access token
       * @default "Bearer <token>"
       */
      Authorization: string
      /** Request id identity */
      'X-Request-Id': string
    }
    export type ResponseBody = ResponseResponse & {
      data?: DtoLobby
    }
  }

  /**
   * @description Get current lobby
   * @tags Lobby
   * @name GetCurrent
   * @summary Get current lobby
   * @request GET:/lobby/current
   */
  export namespace GetCurrent {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {
      /**
       * Access token
       * @default "Bearer <token>"
       */
      Authorization: string
      /** Request id identity */
      'X-Request-Id': string
    }
    export type ResponseBody = ResponseResponse & {
      data?: DtoLobby
    }
  }

  /**
   * @description Get lobby by id
   * @tags Lobby
   * @name GetLobby
   * @summary Get lobby by id
   * @request GET:/lobby/{id}
   */
  export namespace GetLobby {
    export type RequestParams = {
      /** Lobby id */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {
      /**
       * Access token
       * @default "Bearer <token>"
       */
      Authorization: string
      /** Request id identity */
      'X-Request-Id': string
    }
    export type ResponseBody = ResponseResponse & {
      data?: DtoLobby
    }
  }

  /**
   * @description Choose bet
   * @tags Lobby
   * @name PostLobby2
   * @summary Choose bet
   * @request POST:/lobby/{id}/bet
   * @originalName postLobby
   * @duplicate
   */
  export namespace PostLobby2 {
    export type RequestParams = {
      /** Lobby id */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = LobbyChooseBet
    export type RequestHeaders = {
      /**
       * Access token
       * @default "Bearer <token>"
       */
      Authorization: string
      /** Request id identity */
      'X-Request-Id': string
    }
    export type ResponseBody = ResponseResponse & {
      data?: DtoLobby
    }
  }

  /**
   * @description Choose country
   * @tags Lobby
   * @name PostCountry
   * @summary Choose country
   * @request POST:/lobby/{id}/country/{country}
   */
  export namespace PostCountry {
    export type RequestParams = {
      /** Lobby id */
      id: string
      /** Country Name */
      country: string
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {
      /**
       * Access token
       * @default "Bearer <token>"
       */
      Authorization: string
      /** Request id identity */
      'X-Request-Id': string
    }
    export type ResponseBody = ResponseResponse & {
      data?: DtoLobby
    }
  }

  /**
   * @description Change host
   * @tags Lobby
   * @name PostHost
   * @summary Change host
   * @request POST:/lobby/{id}/host/{hostId}
   */
  export namespace PostHost {
    export type RequestParams = {
      /** Lobby id */
      id: string
      /** New host id */
      hostId: string
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {
      /**
       * Access token
       * @default "Bearer <token>"
       */
      Authorization: string
      /** Request id identity */
      'X-Request-Id': string
    }
    export type ResponseBody = ResponseResponse & {
      data?: DtoLobby
    }
  }

  /**
   * @description Invite lobby participant
   * @tags Lobby
   * @name PostInvite
   * @summary Invite lobby participant
   * @request POST:/lobby/{id}/invite
   */
  export namespace PostInvite {
    export type RequestParams = {
      /** Lobby id */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = LobbyCreateInvite
    export type RequestHeaders = {
      /**
       * Access token
       * @default "Bearer <token>"
       */
      Authorization: string
      /** Request id identity */
      'X-Request-Id': string
    }
    export type ResponseBody = ResponseResponse & {
      data?: DtoLobby
    }
  }

  /**
   * @description Close lobby participant invite
   * @tags Lobby
   * @name PostInviteClose
   * @summary Close lobby participant invite
   * @request POST:/lobby/{id}/invite/close
   */
  export namespace PostInviteClose {
    export type RequestParams = {
      /** Lobby id */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = LobbyCloseInvite
    export type RequestHeaders = {
      /**
       * Access token
       * @default "Bearer <token>"
       */
      Authorization: string
      /** Request id identity */
      'X-Request-Id': string
    }
    export type ResponseBody = ResponseResponse & {
      data?: DtoLobby
    }
  }

  /**
   * @description Decline lobby invite
   * @tags Lobby
   * @name PostInviteDecline
   * @summary Decline lobby invite
   * @request POST:/lobby/{id}/invite/decline
   */
  export namespace PostInviteDecline {
    export type RequestParams = {
      /** Lobby id */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {
      /**
       * Access token
       * @default "Bearer <token>"
       */
      Authorization: string
      /** Request id identity */
      'X-Request-Id': string
    }
    export type ResponseBody = ResponseResponse & {
      data?: DtoLobby
    }
  }

  /**
   * @description Invite lobby participant from party
   * @tags Lobby
   * @name PostInviteParty
   * @summary Invite lobby participant from party
   * @request POST:/lobby/{id}/invite/party
   */
  export namespace PostInviteParty {
    export type RequestParams = {
      /** Lobby id */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {
      /**
       * Access token
       * @default "Bearer <token>"
       */
      Authorization: string
      /** Request id identity */
      'X-Request-Id': string
    }
    export type ResponseBody = ResponseResponse & {
      data?: DtoLobby
    }
  }

  /**
   * @description Join to lobby
   * @tags Lobby
   * @name PostJoin
   * @summary Join to lobby
   * @request POST:/lobby/{id}/join
   */
  export namespace PostJoin {
    export type RequestParams = {
      /** Lobby id */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {
      /**
       * Access token
       * @default "Bearer <token>"
       */
      Authorization: string
      /** Request id identity */
      'X-Request-Id': string
    }
    export type ResponseBody = ResponseResponse & {
      data?: DtoLobby
    }
  }

  /**
   * @description Kick lobby participant
   * @tags Lobby
   * @name PostKick
   * @summary Kick lobby participant
   * @request POST:/lobby/{id}/kick/{participantId}
   */
  export namespace PostKick {
    export type RequestParams = {
      /** Lobby id */
      id: string
      /** Participant id */
      participantId: string
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {
      /**
       * Access token
       * @default "Bearer <token>"
       */
      Authorization: string
      /** Request id identity */
      'X-Request-Id': string
    }
    export type ResponseBody = ResponseResponse & {
      data?: DtoLobby
    }
  }

  /**
   * @description Leave from lobby
   * @tags Lobby
   * @name PostLeave
   * @summary Leave from lobby
   * @request POST:/lobby/{id}/leave
   */
  export namespace PostLeave {
    export type RequestParams = {
      /** Lobby id */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {
      /**
       * Access token
       * @default "Bearer <token>"
       */
      Authorization: string
      /** Request id identity */
      'X-Request-Id': string
    }
    export type ResponseBody = ResponseResponse & {
      data?: DtoLobby
    }
  }

  /**
   * @description Choose map
   * @tags Lobby
   * @name PostLobby3
   * @summary Choose map
   * @request POST:/lobby/{id}/map
   * @originalName postLobby
   * @duplicate
   */
  export namespace PostLobby3 {
    export type RequestParams = {
      /** Lobby id */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = LobbyChooseMap
    export type RequestHeaders = {
      /**
       * Access token
       * @default "Bearer <token>"
       */
      Authorization: string
      /** Request id identity */
      'X-Request-Id': string
    }
    export type ResponseBody = ResponseResponse & {
      data?: DtoLobby
    }
  }

  /**
   * @description Choose mode
   * @tags Lobby
   * @name PostMode
   * @summary Choose mode
   * @request POST:/lobby/{id}/mode
   */
  export namespace PostMode {
    export type RequestParams = {
      /** Lobby id */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = LobbyChooseMode
    export type RequestHeaders = {
      /**
       * Access token
       * @default "Bearer <token>"
       */
      Authorization: string
      /** Request id identity */
      'X-Request-Id': string
    }
    export type ResponseBody = ResponseResponse & {
      data?: DtoLobby
    }
  }

  /**
   * @description Change ready status
   * @tags Lobby
   * @name PostReady
   * @summary Change ready status
   * @request POST:/lobby/{id}/ready
   */
  export namespace PostReady {
    export type RequestParams = {
      /** Lobby id */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {
      /**
       * Access token
       * @default "Bearer <token>"
       */
      Authorization: string
      /** Request id identity */
      'X-Request-Id': string
    }
    export type ResponseBody = ResponseResponse & {
      data?: DtoLobby
    }
  }

  /**
   * @description Stop lobby search status
   * @tags Lobby
   * @name PostSearchStop
   * @summary Stop lobby search status
   * @request POST:/lobby/{id}/search/stop
   */
  export namespace PostSearchStop {
    export type RequestParams = {
      /** Lobby id */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {
      /**
       * Access token
       * @default "Bearer <token>"
       */
      Authorization: string
      /** Request id identity */
      'X-Request-Id': string
    }
    export type ResponseBody = ResponseResponse & {
      data?: DtoLobby
    }
  }

  /**
   * @description Join to team
   * @tags Lobby
   * @name PostTeam
   * @summary Join to team
   * @request POST:/lobby/{id}/team
   */
  export namespace PostTeam {
    export type RequestParams = {
      /** Lobby id */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = LobbyJoinTeam
    export type RequestHeaders = {
      /**
       * Access token
       * @default "Bearer <token>"
       */
      Authorization: string
      /** Request id identity */
      'X-Request-Id': string
    }
    export type ResponseBody = ResponseResponse & {
      data?: DtoLobby
    }
  }

  /**
   * @description Join to wait list
   * @tags Lobby
   * @name PostWait
   * @summary Join to wait list
   * @request POST:/lobby/{id}/wait
   */
  export namespace PostWait {
    export type RequestParams = {
      /** Lobby id */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {
      /**
       * Access token
       * @default "Bearer <token>"
       */
      Authorization: string
      /** Request id identity */
      'X-Request-Id': string
    }
    export type ResponseBody = ResponseResponse & {
      data?: DtoLobby
    }
  }
}

import type { AxiosInstance, AxiosRequestConfig, HeadersDefaults, ResponseType } from 'axios'
import axios from 'axios'

export type QueryParamsType = Record<string | number, any>

export interface FullRequestParams extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean
  /** request path */
  path: string
  /** content type of request body */
  type?: ContentType
  /** query params */
  query?: QueryParamsType
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType
  /** request body */
  body?: unknown
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
  securityWorker?: (
    securityData: SecurityDataType | null
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void
  secure?: boolean
  format?: ResponseType
}

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance
  private securityData: SecurityDataType | null = null
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker']
  private secure?: boolean
  private format?: ResponseType

  constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({
      ...axiosConfig,
      baseURL: axiosConfig.baseURL || '//localhost:8080/f2f/lobby/api/v1',
    })
    this.secure = secure
    this.format = format
    this.securityWorker = securityWorker
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data
  }

  protected mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method)

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method && this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    }
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === 'object' && formItem !== null) {
      return JSON.stringify(formItem)
    } else {
      return `${formItem}`
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    if (input instanceof FormData) {
      return input
    }
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key]
      const propertyContent: any[] = property instanceof Array ? property : [property]

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem))
      }

      return formData
    }, new FormData())
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<T> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {}
    const requestParams = this.mergeRequestParams(params, secureParams)
    const responseFormat = format || this.format || undefined

    if (type === ContentType.FormData && body && body !== null && typeof body === 'object') {
      body = this.createFormData(body as Record<string, unknown>)
    }

    if (type === ContentType.Text && body && body !== null && typeof body !== 'string') {
      body = JSON.stringify(body)
    }

    return this.instance
      .request({
        ...requestParams,
        headers: {
          ...(requestParams.headers || {}),
          ...(type ? { 'Content-Type': type } : {}),
        },
        params: query,
        responseType: responseFormat,
        data: body,
        url: path,
      })
      .then((response) => response.data)
  }
}

/**
 * @title Lobby API
 * @version 1.0
 * @baseUrl //localhost:8080/f2f/lobby/api/v1
 * @contact Mikhel Alexander
 *
 * This is lobby api service.
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  lobby = {
    /**
     * @description Create custom lobby
     *
     * @tags Lobby
     * @name PostLobby
     * @summary Create custom lobby
     * @request POST:/lobby
     */
    postLobby: (request: DtoLobbyCreate, params: RequestParams = {}) =>
      this.request<
        ResponseResponse & {
          data?: DtoLobby
        },
        any
      >({
        path: `/lobby`,
        method: 'POST',
        body: request,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Get current lobby
     *
     * @tags Lobby
     * @name GetCurrent
     * @summary Get current lobby
     * @request GET:/lobby/current
     */
    getCurrent: (params: RequestParams = {}) =>
      this.request<
        ResponseResponse & {
          data?: DtoLobby
        },
        any
      >({
        path: `/lobby/current`,
        method: 'GET',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Get lobby by id
     *
     * @tags Lobby
     * @name GetLobby
     * @summary Get lobby by id
     * @request GET:/lobby/{id}
     */
    getLobby: (id: string, params: RequestParams = {}) =>
      this.request<
        ResponseResponse & {
          data?: DtoLobby
        },
        any
      >({
        path: `/lobby/${id}`,
        method: 'GET',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Choose bet
     *
     * @tags Lobby
     * @name PostLobby2
     * @summary Choose bet
     * @request POST:/lobby/{id}/bet
     * @originalName postLobby
     * @duplicate
     */
    postLobby2: (id: string, request: LobbyChooseBet, params: RequestParams = {}) =>
      this.request<
        ResponseResponse & {
          data?: DtoLobby
        },
        any
      >({
        path: `/lobby/${id}/bet`,
        method: 'POST',
        body: request,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Choose country
     *
     * @tags Lobby
     * @name PostCountry
     * @summary Choose country
     * @request POST:/lobby/{id}/country/{country}
     */
    postCountry: (id: string, country: string, params: RequestParams = {}) =>
      this.request<
        ResponseResponse & {
          data?: DtoLobby
        },
        any
      >({
        path: `/lobby/${id}/country/${country}`,
        method: 'POST',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Change host
     *
     * @tags Lobby
     * @name PostHost
     * @summary Change host
     * @request POST:/lobby/{id}/host/{hostId}
     */
    postHost: (id: string, hostId: string, params: RequestParams = {}) =>
      this.request<
        ResponseResponse & {
          data?: DtoLobby
        },
        any
      >({
        path: `/lobby/${id}/host/${hostId}`,
        method: 'POST',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Invite lobby participant
     *
     * @tags Lobby
     * @name PostInvite
     * @summary Invite lobby participant
     * @request POST:/lobby/{id}/invite
     */
    postInvite: (id: string, request: LobbyCreateInvite, params: RequestParams = {}) =>
      this.request<
        ResponseResponse & {
          data?: DtoLobby
        },
        any
      >({
        path: `/lobby/${id}/invite`,
        method: 'POST',
        body: request,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Close lobby participant invite
     *
     * @tags Lobby
     * @name PostInviteClose
     * @summary Close lobby participant invite
     * @request POST:/lobby/{id}/invite/close
     */
    postInviteClose: (id: string, request: LobbyCloseInvite, params: RequestParams = {}) =>
      this.request<
        ResponseResponse & {
          data?: DtoLobby
        },
        any
      >({
        path: `/lobby/${id}/invite/close`,
        method: 'POST',
        body: request,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Decline lobby invite
     *
     * @tags Lobby
     * @name PostInviteDecline
     * @summary Decline lobby invite
     * @request POST:/lobby/{id}/invite/decline
     */
    postInviteDecline: (id: string, params: RequestParams = {}) =>
      this.request<
        ResponseResponse & {
          data?: DtoLobby
        },
        any
      >({
        path: `/lobby/${id}/invite/decline`,
        method: 'POST',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Invite lobby participant from party
     *
     * @tags Lobby
     * @name PostInviteParty
     * @summary Invite lobby participant from party
     * @request POST:/lobby/{id}/invite/party
     */
    postInviteParty: (id: string, params: RequestParams = {}) =>
      this.request<
        ResponseResponse & {
          data?: DtoLobby
        },
        any
      >({
        path: `/lobby/${id}/invite/party`,
        method: 'POST',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Join to lobby
     *
     * @tags Lobby
     * @name PostJoin
     * @summary Join to lobby
     * @request POST:/lobby/{id}/join
     */
    postJoin: (id: string, params: RequestParams = {}) =>
      this.request<
        ResponseResponse & {
          data?: DtoLobby
        },
        any
      >({
        path: `/lobby/${id}/join`,
        method: 'POST',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Kick lobby participant
     *
     * @tags Lobby
     * @name PostKick
     * @summary Kick lobby participant
     * @request POST:/lobby/{id}/kick/{participantId}
     */
    postKick: (id: string, participantId: string, params: RequestParams = {}) =>
      this.request<
        ResponseResponse & {
          data?: DtoLobby
        },
        any
      >({
        path: `/lobby/${id}/kick/${participantId}`,
        method: 'POST',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Leave from lobby
     *
     * @tags Lobby
     * @name PostLeave
     * @summary Leave from lobby
     * @request POST:/lobby/{id}/leave
     */
    postLeave: (id: string, params: RequestParams = {}) =>
      this.request<
        ResponseResponse & {
          data?: DtoLobby
        },
        any
      >({
        path: `/lobby/${id}/leave`,
        method: 'POST',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Choose map
     *
     * @tags Lobby
     * @name PostLobby3
     * @summary Choose map
     * @request POST:/lobby/{id}/map
     * @originalName postLobby
     * @duplicate
     */
    postLobby3: (id: string, request: LobbyChooseMap, params: RequestParams = {}) =>
      this.request<
        ResponseResponse & {
          data?: DtoLobby
        },
        any
      >({
        path: `/lobby/${id}/map`,
        method: 'POST',
        body: request,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Choose mode
     *
     * @tags Lobby
     * @name PostMode
     * @summary Choose mode
     * @request POST:/lobby/{id}/mode
     */
    postMode: (id: string, request: LobbyChooseMode, params: RequestParams = {}) =>
      this.request<
        ResponseResponse & {
          data?: DtoLobby
        },
        any
      >({
        path: `/lobby/${id}/mode`,
        method: 'POST',
        body: request,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Change ready status
     *
     * @tags Lobby
     * @name PostReady
     * @summary Change ready status
     * @request POST:/lobby/{id}/ready
     */
    postReady: (id: string, params: RequestParams = {}) =>
      this.request<
        ResponseResponse & {
          data?: DtoLobby
        },
        any
      >({
        path: `/lobby/${id}/ready`,
        method: 'POST',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Stop lobby search status
     *
     * @tags Lobby
     * @name PostSearchStop
     * @summary Stop lobby search status
     * @request POST:/lobby/{id}/search/stop
     */
    postSearchStop: (id: string, params: RequestParams = {}) =>
      this.request<
        ResponseResponse & {
          data?: DtoLobby
        },
        any
      >({
        path: `/lobby/${id}/search/stop`,
        method: 'POST',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Join to team
     *
     * @tags Lobby
     * @name PostTeam
     * @summary Join to team
     * @request POST:/lobby/{id}/team
     */
    postTeam: (id: string, request: LobbyJoinTeam, params: RequestParams = {}) =>
      this.request<
        ResponseResponse & {
          data?: DtoLobby
        },
        any
      >({
        path: `/lobby/${id}/team`,
        method: 'POST',
        body: request,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Join to wait list
     *
     * @tags Lobby
     * @name PostWait
     * @summary Join to wait list
     * @request POST:/lobby/{id}/wait
     */
    postWait: (id: string, params: RequestParams = {}) =>
      this.request<
        ResponseResponse & {
          data?: DtoLobby
        },
        any
      >({
        path: `/lobby/${id}/wait`,
        method: 'POST',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
}
