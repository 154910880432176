const VideoFileIcon = () => (
  <svg
    width="21"
    height="18"
    viewBox="0 0 21 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="1"
      y="1.25"
      width="19"
      height="15.5"
      rx="2"
      stroke="white"
      stroke-width="2"
    />
    <path
      d="M15.2133 8.73165L7.43416 4.84206C7.23469 4.74232 7 4.88737 7 5.11039L7 12.8896C7 13.1126 7.23469 13.2576 7.43416 13.1579L15.2133 9.2683C15.4345 9.15775 15.4345 8.8422 15.2133 8.73165Z"
      fill="white"
    />
  </svg>
)

export default VideoFileIcon
