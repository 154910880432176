import { $userProfile } from 'stores/userStore'
import {
  $userStatisticPageActiveGameInfo,
  $userStatisticPageActiveGameTime,
  $userStatisticPageGame,
  RankTypes,
  getUserStatisticPageInfo,
  getUserStatisticPageStat,
} from 'stores/statisticStore'
import { styled } from 'styled-components'
import { useEffect } from 'react'
import { useStore } from '@nanostores/react'
import BestWeapons from 'components/PersonalStatistic/BestWeapons'
import GameStats from 'components/PersonalAccount/GameStats'
import MapStatistic from 'components/PersonalStatistic/MapStatistic'
import MatchStatistics from 'components/PersonalStatistic/MatchStatistics'
import PersonCard from 'components/PersonalStatistic/PersonCard'
import Rank from 'components/PersonalStatistic/Rank'

const StyledRoot = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: scroll;
  margin-top: 42px;
  margin-bottom: 110px;
  padding-right: 6px;

  &::-webkit-scrollbar {
    width: 2px;
  }

  &::-webkit-scrollbar-track {
    margin: 4px 0 4px;
    border-radius: 1px;
    opacity: 0.1;
    background: rgba(217, 217, 217, 0.1);
  }

  &::-webkit-scrollbar-thumb {
    background-color: white;
    border-radius: 1px;
  }
`
const StyledLine = styled.div`
  display: flex;
  gap: 5px;
`
const CSGOPersonalStatisticPage: React.FC = () => {
  const user = useStore($userProfile)
  const userStatisticPageActiveGameInfo = useStore(
    $userStatisticPageActiveGameInfo
  )
  const userStatisticPageGame = useStore($userStatisticPageGame)
  const userStatisticPageActiveGameTime = useStore(
    $userStatisticPageActiveGameTime
  )
  useEffect(() => {
    const getData = async () => {
      if (!user?.id) return
      await getUserStatisticPageStat(user.id)
      await getUserStatisticPageInfo(user.id)
    }
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    getData()
  }, [user?.id])
  return (
    <StyledRoot>
      <StyledLine>
        <GameStats
          page="statistic"
          gameName={userStatisticPageGame}
          gamesInTotal={userStatisticPageActiveGameTime.gamesInTotal}
          hoursInTotal={userStatisticPageActiveGameTime.spentTimeInGames}
          hoursInMode={Math.round(
            (userStatisticPageActiveGameInfo?.spentTimeInGameMin || 0) / 60
          )}
          matchesInMode={
            (userStatisticPageActiveGameInfo?.matchDrawCount || 0) +
            (userStatisticPageActiveGameInfo?.matchWinCount || 0) +
            (userStatisticPageActiveGameInfo?.matchLoseCount || 0)
          }
        />
        <PersonCard page="statistic" gameChoosen="CS" />
        <BestWeapons page="statistic" gameChoosen="CS" />
        <Rank
          rating={userStatisticPageActiveGameInfo?.rating}
          nextRankRating={userStatisticPageActiveGameInfo?.nextRankRating}
          prevRankRating={userStatisticPageActiveGameInfo?.prevRankRating}
          rank={
            (userStatisticPageActiveGameInfo?.rank as RankTypes) || undefined
          }
        />
        <MapStatistic page="statistic" gameChoosen="CS" />
      </StyledLine>
      <MatchStatistics />
    </StyledRoot>
  )
}

export default CSGOPersonalStatisticPage
