/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface DtoAttachment {
  attachmentId?: string
  attachmentName?: string
  attachmentUrl?: string
  claimId?: string
}

export interface DtoCSMatchProfileStat {
  ADR?: number
  CSBestWeaponProfileStat?: {
    damage?: number
    headShotCount?: number
    killCount?: number
    matchId?: string
    profileId?: string
    weapon?: {
      gameName?: string
      id?: string
      imgIconUrl?: string
      imgUrl?: string
      name?: string
    }
  }
  EF?: number
  HS?: number
  KAST?: number
  KD?: number
  KDDiff?: number
  assist?: number
  dead?: number
  kill?: number
  matchId?: string
  profileId?: string
  rating?: number
  ratingDiff?: number
  score?: number
}

export interface DtoClaim {
  attachments?: DtoAttachment[]
  author?: DtoUser
  category?: EnumClaimReportCategory
  createdAt?: string
  decisionCount?: number
  decisions?: DtoJudgeClaim[]
  description?: string
  expiredAt?: string
  externalId?: string
  finishWorkAt?: string
  id?: string
  isGuiltyVerdict?: boolean
  maxDecisionCount?: number
  reasons?: EnumClaimReason[]
  reportedMatch?: DtoMatch
  reportedUser?: DtoUser
  status?: EnumClaimStatus
  takeToWorkAt?: string
  timeCodes?: string[][]
}

export interface DtoClaimPreview {
  authorId?: string
  category?: EnumClaimReportCategory
  createdAt?: string
  decisionCount?: number
  expiredAt?: string
  externalId?: string
  id?: string
  isGuiltyVerdict?: boolean
  maxDecisionCount?: number
  reasons?: EnumClaimReason[]
  reportedMatch?: DtoMatchPreview
  reportedUser?: DtoUser
  status?: EnumClaimStatus
  takeToWorkAt?: string
}

export interface DtoJudgeClaim {
  attachments?: DtoAttachment[]
  claimId?: string
  description?: string
  expiredAt?: string
  finishWorkAt?: string
  id?: string
  isGuilty?: boolean
  status?: EnumJudgeClaimStatus
  takeToWork?: string
  timeCodes?: string[][]
  userId?: string
}

export interface DtoJudgeClaimStat {
  currentMothFinishedClaimCount?: number
  finishedClaimCount?: number
  prevMothFinishedClaimCount?: number
}

export interface DtoMatch {
  CSMatchResult?: {
    CSMatchRoundResults?: {
      createdAt?: string
      loseTeamColor?: string
      loseTeamDeadCount?: number
      round?: number
      winCondition?: string
      winTeamColor?: string
      winTeamDeadCount?: number
    }[]
    attachmentId?: string
    createdAt?: string
    isDraw?: boolean
    loseTeamColor?: string
    loseTeamScore?: number
    matchId?: string
    winTeamColor?: string
    winTeamScore?: number
  }
  bet?: number
  country?: {
    ImgUrl?: string
    name?: string
  }
  createdAt?: string
  declinedAt?: string
  declinedCode?: string
  declinedReason?: string
  externalId?: string
  finishAt?: string
  gameFormat?: string
  gameMap?: {
    gameName?: string
    id?: string
    imgIconUrl?: string
    imgUrl?: string
    label?: string
    name?: string
  }
  gameMode?: {
    ImgUrl?: string
    id?: string
    name?: string
  }
  gameName?: string
  handleFinishAt?: string
  id?: string
  isDraw?: boolean
  isRating?: boolean
  lobbyId?: string
  loseTeamColor?: string
  loseTeamScore?: number
  participants?: {
    originLobbyId?: string
    slotOrder?: number
    teamColor?: string
    user?: DtoUser
  }[]
  startAt?: string
  status?: string
  statusTimestamp?: string
  url?: string
  winTeamColor?: string
  winTeamScore?: number
}

export interface DtoMatchPreview {
  gameName?: string
  id?: string
}

export interface DtoRewardInfo {
  countForReward?: number
  feeRate?: number
  rewardSum?: number
}

export interface DtoUser {
  CSMatchProfileStat?: DtoCSMatchProfileStat
  createdAt?: string
  externalId?: string
  id?: string
  imgUrl?: string
  name?: string
  updatedAt?: string
}

export interface DtoUserClaimStat {
  currentAvailableMonthClaimCount?: number
  currentMothClaimCount?: number
  currentMothDeclinedClaimCount?: number
  currentMothFinishedClaimCount?: number
  maxAvailableMonthClaimCount?: number
}

export interface DtoVerdict {
  claimId?: string
  description?: string
  isGuilty?: boolean
  timeCodes?: string[][]
}

export enum EnumClaimReason {
  ClaimReasonNetworkDelay = 'NETWORK_DELAY',
  ClaimReasonGameCrushed = 'GAME_CRUSHED',
  ClaimReasonLoweringFPS = 'LOWERING_FPS',
  ClaimReasonCheating = 'CHEATING',
  ClaimReasonGriefing = 'GRIEFING',
  ClaimReasonUnsportsmanlikeBehavior = 'UNSPORTSMANLIKE_BEHAVIOR',
}

export enum EnumClaimReportCategory {
  ClaimReportCategoryServiceReport = 'SERVICE_REPORT',
  ClaimReportCategoryPlayerReport = 'PLAYER_REPORT',
  ClaimReportCategoryMatchReport = 'MATCH_REPORT',
}

export enum EnumClaimStatus {
  ClaimStatusPreparing = 'PREPARING',
  ClaimStatusNew = 'NEW',
  ClaimStatusInProgress = 'IN_PROGRESS',
  ClaimStatusFinish = 'FINISH',
  ClaimStatusApproved = 'APPROVED',
}

export enum EnumJudgeClaimStatus {
  JudgeClaimStatusInProgress = 'IN_PROGRESS',
  JudgeClaimStatusFinished = 'FINISHED',
  JudgeClaimStatusDeclined = 'DECLINED',
}

export interface ResponseResponse {
  data?: any
  meta?: ResponseMeta
  pagination?: ResponsePagination
}

export interface ResponseMeta {
  code?: string
  error?: string
  message?: string
  requestId?: string
  traceId?: string
}

export interface ResponsePagination {
  page?: number
  pages?: number
  perPage?: number
}

export type GetClaimError = ResponseResponse

export type GetCurrentError = ResponseResponse

export type GetDayFinishedError = ResponseResponse

export type GetClaim2Error = ResponseResponse

export type PostTakeToWorkError = ResponseResponse

export type PostAttachmentError = ResponseResponse

export type DeleteAttachmentError = ResponseResponse

export type PutVerdictError = ResponseResponse

export type PostClaimError = ResponseResponse

export type GetInfoError = ResponseResponse

export type GetJudgeError = ResponseResponse

export type GetUserError = ResponseResponse

export namespace Claim {
  /**
   * @description Get claim by id
   * @tags Judge
   * @name GetClaim
   * @summary Get claim by id
   * @request GET:/claim/:id
   */
  export namespace GetClaim {
    export type RequestParams = {
      /** Claim id */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {
      /**
       * Access token
       * @default "Bearer <token>"
       */
      Authorization: string
      /** Request id identity */
      'X-Request-Id': string
    }
    export type ResponseBody = ResponseResponse & {
      data?: DtoClaim
    }
  }

  /**
   * @description Get claim at work
   * @tags Judge
   * @name GetCurrent
   * @summary Get claim at work
   * @request GET:/claim/current
   */
  export namespace GetCurrent {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {
      /**
       * Access token
       * @default "Bearer <token>"
       */
      Authorization: string
      /** Request id identity */
      'X-Request-Id': string
    }
    export type ResponseBody = ResponseResponse & {
      data?: DtoClaimPreview
    }
  }

  /**
   * @description Get today finished claims preview
   * @tags Judge
   * @name GetDayFinished
   * @summary Get today finished claims preview
   * @request GET:/claim/day/finished
   */
  export namespace GetDayFinished {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {
      /**
       * Access token
       * @default "Bearer <token>"
       */
      Authorization: string
      /** Request id identity */
      'X-Request-Id': string
    }
    export type ResponseBody = ResponseResponse & {
      data?: DtoClaimPreview[]
    }
  }

  /**
   * @description Get author claims preview
   * @tags Judge
   * @name GetClaim2
   * @summary Get author claims preview
   * @request GET:/claim/my
   * @originalName getClaim
   * @duplicate
   */
  export namespace GetClaim2 {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {
      /**
       * Access token
       * @default "Bearer <token>"
       */
      Authorization: string
      /** Request id identity */
      'X-Request-Id': string
    }
    export type ResponseBody = ResponseResponse & {
      data?: DtoClaimPreview[]
    }
  }

  /**
   * @description take new claim to work
   * @tags Judge
   * @name PostTakeToWork
   * @summary take new claim to work
   * @request POST:/claim/take-to-work
   */
  export namespace PostTakeToWork {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {
      /**
       * Access token
       * @default "Bearer <token>"
       */
      Authorization: string
      /** Request id identity */
      'X-Request-Id': string
    }
    export type ResponseBody = ResponseResponse & {
      data?: DtoClaim
    }
  }

  /**
   * @description add file to claim
   * @tags Judge
   * @name PostAttachment
   * @summary add file to claim
   * @request POST:/claim/{id}/attachment
   */
  export namespace PostAttachment {
    export type RequestParams = {
      /** Claim id */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = {
      /** attachment file */
      file: File
    }
    export type RequestHeaders = {
      /**
       * Access token
       * @default "Bearer <token>"
       */
      Authorization: string
      /** Request id identity */
      'X-Request-Id': string
    }
    export type ResponseBody = ResponseResponse & {
      data?: DtoAttachment
    }
  }

  /**
   * @description delete file from claim
   * @tags Judge
   * @name DeleteAttachment
   * @summary delete file from claim
   * @request DELETE:/claim/{id}/attachment/{attachmentId}
   */
  export namespace DeleteAttachment {
    export type RequestParams = {
      /** Claim id */
      id: string
      /** Attachment id */
      attachmentId: string
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {
      /**
       * Access token
       * @default "Bearer <token>"
       */
      Authorization: string
      /** Request id identity */
      'X-Request-Id': string
    }
    export type ResponseBody = ResponseResponse
  }

  /**
   * @description Approve claim
   * @tags Judge
   * @name PutVerdict
   * @summary Approve claim
   * @request PUT:/claim/{id}/verdict
   */
  export namespace PutVerdict {
    export type RequestParams = {
      /** Dto id */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = DtoVerdict
    export type RequestHeaders = {
      /**
       * Access token
       * @default "Bearer <token>"
       */
      Authorization: string
      /** Request id identity */
      'X-Request-Id': string
    }
    export type ResponseBody = ResponseResponse
  }
}

export namespace Reward {
  /**
   * @description claim reward
   * @tags Judge
   * @name PostClaim
   * @summary claim reward
   * @request POST:/reward/claim
   */
  export namespace PostClaim {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {
      /**
       * Access token
       * @default "Bearer <token>"
       */
      Authorization: string
      /** Request id identity */
      'X-Request-Id': string
    }
    export type ResponseBody = ResponseResponse
  }

  /**
   * @description Get reward info
   * @tags Judge
   * @name GetInfo
   * @summary Get reward info
   * @request GET:/reward/info
   */
  export namespace GetInfo {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {
      /**
       * Access token
       * @default "Bearer <token>"
       */
      Authorization: string
      /** Request id identity */
      'X-Request-Id': string
    }
    export type ResponseBody = ResponseResponse & {
      data?: DtoRewardInfo
    }
  }
}

export namespace Statistic {
  /**
   * @description Get judge statistic
   * @tags Judge
   * @name GetJudge
   * @summary Get judge statistic
   * @request GET:/statistic/judge
   */
  export namespace GetJudge {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {
      /**
       * Access token
       * @default "Bearer <token>"
       */
      Authorization: string
      /** Request id identity */
      'X-Request-Id': string
    }
    export type ResponseBody = ResponseResponse & {
      data?: DtoJudgeClaimStat
    }
  }

  /**
   * @description Get user claim statistic
   * @tags Judge
   * @name GetUser
   * @summary Get user claim statistic
   * @request GET:/statistic/user
   */
  export namespace GetUser {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {
      /**
       * Access token
       * @default "Bearer <token>"
       */
      Authorization: string
      /** Request id identity */
      'X-Request-Id': string
    }
    export type ResponseBody = ResponseResponse & {
      data?: DtoUserClaimStat
    }
  }
}

import type { AxiosInstance, AxiosRequestConfig, HeadersDefaults, ResponseType } from 'axios'
import axios from 'axios'

export type QueryParamsType = Record<string | number, any>

export interface FullRequestParams extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean
  /** request path */
  path: string
  /** content type of request body */
  type?: ContentType
  /** query params */
  query?: QueryParamsType
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType
  /** request body */
  body?: unknown
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
  securityWorker?: (
    securityData: SecurityDataType | null
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void
  secure?: boolean
  format?: ResponseType
}

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance
  private securityData: SecurityDataType | null = null
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker']
  private secure?: boolean
  private format?: ResponseType

  constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({
      ...axiosConfig,
      baseURL: axiosConfig.baseURL || '//localhost:8080/f2f/judge/api/v1',
    })
    this.secure = secure
    this.format = format
    this.securityWorker = securityWorker
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data
  }

  protected mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method)

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method && this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    }
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === 'object' && formItem !== null) {
      return JSON.stringify(formItem)
    } else {
      return `${formItem}`
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    if (input instanceof FormData) {
      return input
    }
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key]
      const propertyContent: any[] = property instanceof Array ? property : [property]

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem))
      }

      return formData
    }, new FormData())
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<T> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {}
    const requestParams = this.mergeRequestParams(params, secureParams)
    const responseFormat = format || this.format || undefined

    if (type === ContentType.FormData && body && body !== null && typeof body === 'object') {
      body = this.createFormData(body as Record<string, unknown>)
    }

    if (type === ContentType.Text && body && body !== null && typeof body !== 'string') {
      body = JSON.stringify(body)
    }

    return this.instance
      .request({
        ...requestParams,
        headers: {
          ...(requestParams.headers || {}),
          ...(type ? { 'Content-Type': type } : {}),
        },
        params: query,
        responseType: responseFormat,
        data: body,
        url: path,
      })
      .then((response) => response.data)
  }
}

/**
 * @title Judge api
 * @version 1.0
 * @baseUrl //localhost:8080/f2f/judge/api/v1
 * @contact Mikhel Alexander
 *
 * This is judge api service.
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  claim = {
    /**
     * @description Get claim by id
     *
     * @tags Judge
     * @name GetClaim
     * @summary Get claim by id
     * @request GET:/claim/:id
     */
    getClaim: (id: string, params: RequestParams = {}) =>
      this.request<
        ResponseResponse & {
          data?: DtoClaim
        },
        GetClaimError
      >({
        path: `/claim/${id}`,
        method: 'GET',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Get claim at work
     *
     * @tags Judge
     * @name GetCurrent
     * @summary Get claim at work
     * @request GET:/claim/current
     */
    getCurrent: (params: RequestParams = {}) =>
      this.request<
        ResponseResponse & {
          data?: DtoClaimPreview
        },
        GetCurrentError
      >({
        path: `/claim/current`,
        method: 'GET',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Get today finished claims preview
     *
     * @tags Judge
     * @name GetDayFinished
     * @summary Get today finished claims preview
     * @request GET:/claim/day/finished
     */
    getDayFinished: (params: RequestParams = {}) =>
      this.request<
        ResponseResponse & {
          data?: DtoClaimPreview[]
        },
        GetDayFinishedError
      >({
        path: `/claim/day/finished`,
        method: 'GET',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Get author claims preview
     *
     * @tags Judge
     * @name GetClaim2
     * @summary Get author claims preview
     * @request GET:/claim/my
     * @originalName getClaim
     * @duplicate
     */
    getClaim2: (params: RequestParams = {}) =>
      this.request<
        ResponseResponse & {
          data?: DtoClaimPreview[]
        },
        GetClaim2Error
      >({
        path: `/claim/my`,
        method: 'GET',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description take new claim to work
     *
     * @tags Judge
     * @name PostTakeToWork
     * @summary take new claim to work
     * @request POST:/claim/take-to-work
     */
    postTakeToWork: (params: RequestParams = {}) =>
      this.request<
        ResponseResponse & {
          data?: DtoClaim
        },
        PostTakeToWorkError
      >({
        path: `/claim/take-to-work`,
        method: 'POST',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description add file to claim
     *
     * @tags Judge
     * @name PostAttachment
     * @summary add file to claim
     * @request POST:/claim/{id}/attachment
     */
    postAttachment: (
      id: string,
      data: {
        /** attachment file */
        file: File
      },
      params: RequestParams = {}
    ) =>
      this.request<
        ResponseResponse & {
          data?: DtoAttachment
        },
        PostAttachmentError
      >({
        path: `/claim/${id}/attachment`,
        method: 'POST',
        body: data,
        type: ContentType.FormData,
        format: 'json',
        ...params,
      }),

    /**
     * @description delete file from claim
     *
     * @tags Judge
     * @name DeleteAttachment
     * @summary delete file from claim
     * @request DELETE:/claim/{id}/attachment/{attachmentId}
     */
    deleteAttachment: (id: string, attachmentId: string, params: RequestParams = {}) =>
      this.request<ResponseResponse, DeleteAttachmentError>({
        path: `/claim/${id}/attachment/${attachmentId}`,
        method: 'DELETE',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Approve claim
     *
     * @tags Judge
     * @name PutVerdict
     * @summary Approve claim
     * @request PUT:/claim/{id}/verdict
     */
    putVerdict: (id: string, request: DtoVerdict, params: RequestParams = {}) =>
      this.request<ResponseResponse, PutVerdictError>({
        path: `/claim/${id}/verdict`,
        method: 'PUT',
        body: request,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  reward = {
    /**
     * @description claim reward
     *
     * @tags Judge
     * @name PostClaim
     * @summary claim reward
     * @request POST:/reward/claim
     */
    postClaim: (params: RequestParams = {}) =>
      this.request<ResponseResponse, PostClaimError>({
        path: `/reward/claim`,
        method: 'POST',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Get reward info
     *
     * @tags Judge
     * @name GetInfo
     * @summary Get reward info
     * @request GET:/reward/info
     */
    getInfo: (params: RequestParams = {}) =>
      this.request<
        ResponseResponse & {
          data?: DtoRewardInfo
        },
        GetInfoError
      >({
        path: `/reward/info`,
        method: 'GET',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  statistic = {
    /**
     * @description Get judge statistic
     *
     * @tags Judge
     * @name GetJudge
     * @summary Get judge statistic
     * @request GET:/statistic/judge
     */
    getJudge: (params: RequestParams = {}) =>
      this.request<
        ResponseResponse & {
          data?: DtoJudgeClaimStat
        },
        GetJudgeError
      >({
        path: `/statistic/judge`,
        method: 'GET',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Get user claim statistic
     *
     * @tags Judge
     * @name GetUser
     * @summary Get user claim statistic
     * @request GET:/statistic/user
     */
    getUser: (params: RequestParams = {}) =>
      this.request<
        ResponseResponse & {
          data?: DtoUserClaimStat
        },
        GetUserError
      >({
        path: `/statistic/user`,
        method: 'GET',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
}
