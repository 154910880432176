/* eslint-disable @typescript-eslint/no-explicit-any */
import { setMatch } from 'stores/lobbyStore'

const gameListiner = async (data: any) => {
  if (data.entityAction === 'CREATE') {
    await setMatch(data.data)
  }
}

export default gameListiner
