import { useStore } from '@nanostores/react'
import useSoundLib from 'use-sound'

import { $userConfig } from 'stores/userStore'

const useSound = (src: string) => {
  const config = useStore($userConfig)
  const volume = (config?.volume ?? 0) / 100

  return useSoundLib(src, { volume })
}

export default useSound
