import { $chatsHaveUnreadMessage } from 'stores/chatStores/singleChat'
import {
  $groupChatsInfo,
  $groupsChatsHaveUnreadMessage,
} from 'stores/chatStores/groupChats'
import { $haveNewNotification } from 'stores/chatStores/notificationsStore'
import {
  $tabActive,
  TabActive,
  setTabActive,
} from 'stores/chatStores/chatStore'
import { Header9 } from 'styles/typography'
import { useStore } from '@nanostores/react'
import { useTranslation } from 'react-i18next'
import ChatIcon from 'images/chat/ChatIcon'
import Counter from 'components/Counter'
import Invite from 'images/chat/Invite'
import Party from 'images/chat/Party'
import Support from 'images/chat/Support'
import styled from 'styled-components'

const StyledMenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: rgba(74, 77, 85, 0.3);
  min-width: 91px;
`
interface StyledMenuButtonProps {
  $isActive?: boolean
}
const StyledMenuBurger = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
`
const StyledMenuButton = styled(Header9)<StyledMenuButtonProps>`
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  padding: 14px 0;
  position: relative;
  cursor: pointer;
  background: ${({ $isActive }) =>
    $isActive ? 'rgba(44, 255, 128, 0.03)' : 'unset'};
  &:after {
    content: '';
    display: block;
    width: 2px;
    height: 100%;
    position: absolute;
    right: 0;
    background: ${({ $isActive }) => ($isActive ? '#2CFF80' : 'unset')};
  }
`

const StyledIconWrapper = styled.div`
  position: relative;
`
const ChatMenu: React.FC = () => {
  const { t } = useTranslation()
  const tabActive = useStore($tabActive)
  const haveUnreadMessage = useStore($chatsHaveUnreadMessage)
  const groupsChatsHaveUnreadMessage = useStore($groupsChatsHaveUnreadMessage)
  const haveNewNotification = useStore($haveNewNotification)
  const groupChatsInfo = useStore($groupChatsInfo)
  const handlActiveTab = (tab: TabActive) => () => {
    setTabActive(tab)
  }

  return (
    <StyledMenuWrapper>
      <StyledMenuBurger />
      <StyledMenuButton
        $isActive={tabActive === 'chat'}
        onClick={handlActiveTab('chat')}
      >
        <StyledIconWrapper>
          <ChatIcon />
          <Counter
            withoutCount
            position={'top-right'}
            value={haveUnreadMessage}
          />
        </StyledIconWrapper>
        {t('chat.chat')}
      </StyledMenuButton>
      {(groupChatsInfo.party || groupChatsInfo.lobby) && (
        <StyledMenuButton
          $isActive={tabActive === 'party'}
          onClick={handlActiveTab('party')}
        >
          <StyledIconWrapper>
            <Party />
            <Counter
              withoutCount
              position={'top-right'}
              value={groupsChatsHaveUnreadMessage}
            />
          </StyledIconWrapper>
          {t('chat.party')}
        </StyledMenuButton>
      )}
      <StyledMenuButton
        onClick={handlActiveTab('invite')}
        $isActive={tabActive === 'invite'}
      >
        <StyledIconWrapper>
          <Invite />
          <Counter
            withoutCount
            position={'top-right'}
            value={haveNewNotification}
          />
        </StyledIconWrapper>
        {t('chat.invite')}
      </StyledMenuButton>
      <StyledMenuButton
        $isActive={tabActive === 'support'}
        onClick={handlActiveTab('support')}
      >
        <StyledIconWrapper>
          <Support />
        </StyledIconWrapper>
        {t('chat.appeal.support')}
      </StyledMenuButton>
    </StyledMenuWrapper>
  )
}

export default ChatMenu
