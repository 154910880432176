import { atom } from 'nanostores'
import { logger } from '@nanostores/logger'

import { DtoParty } from 'api/schemas/friendsApi'
import api from 'api/api'
import buttonClickAddToPartySound from 'sounds/add-friend-to-party.wav'
import playSound from 'utils/sound'

export interface InvitedBy {
  id: string
  name: string
  imgUrl: string
  partyId: string
  online: boolean
  matchId: string
  lobbyId: string
  country: string
  gameName: string
}

export interface Participants {
  id: string
  name: string
  imgUrl: string
  status: string
  country: string
  lobbyId: string
  matchId: string
  online: boolean
  partyId: string
  gameName: string
  isChosen: boolean
  createdAt: string
  invitedBy: InvitedBy
}

export const $party = atom<DtoParty | null>(null)

const buttonClickAddToParty = new Audio(buttonClickAddToPartySound)

export const getParty = async () => {
  let data: DtoParty | null = null
  try {
    const res = await api.party.getCurrent()
    data = res.data
    return $party.set(data)
  } catch (e) {
    console.log(e)
  }
}

export const setParty = (party: DtoParty | null) => {
  if (party) {
    $party.set({ ...$party.get(), ...party })
  } else {
    $party.set(null)
  }
  return
}

export const createParty = async () => {
  const { data } = await api.party.postParty()
  $party.set(data)
  return data as DtoParty
}

export const dismissParty = async () => {
  const id = $party.get()?.id
  if (id) {
    await api.party.postDismiss(id)
    $party.set(null)
  }
}

export const leaveParty = async () => {
  const id = $party.get()?.id
  if (id) {
    await api.party.postLeave(id)
    $party.set(null)
  }
}

export const inviteToParty = async (id: string) => {
  const pratyId = $party.get()?.id
  if (pratyId) {
    try {
      const { data } = await api.party.postInvite(pratyId || '', {
        participantId: id,
      })
      $party.set(data)
      await playSound(buttonClickAddToParty)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      if (e.response.status === 409) {
        console.log('Второй раз добавить нельзя')
      }
    }

    return
  } else {
    await createParty()

    await inviteToParty(id)
    await playSound(buttonClickAddToParty)
  }
}

export const joinToParty = async (id: string) => {
  const { data } = await api.party.postJoin(id)
  $party.set(data)
}

export const declinePartyInvite = async (id: string) => {
  await api.party.postInviteDecline(id)
}

export const rejectPartyInvite = async (id: string) => {
  await api.party.postInviteClose($party.get()?.id || '', {
    participantIds: [id],
  })
}

export const rejectAllPartyInvite = (ids: string[]) => {
  const partyId = $party.get()?.id || ''
  ids.forEach(async (id) => {
    await api.party.postInviteClose(partyId, {
      participantIds: [id],
    })
  })
}

export const kickFromParty = async (id: string) => {
  await api.party.postKick($party.get()?.id || '', id)
}

export const changePartyHost = async (hostId: string) => {
  const id = $party.get()?.id
  if (id) {
    await api.party.postHost(id, hostId)
  }
}

logger(
  {
    Party: $party,
  },
  {
    messages: {
      mount: false,
      unmount: false,
    },
  }
)
