import { $userProfile } from 'stores/userStore'
import { DtoComposite } from 'api/schemas/dictionaryApi'
import { DtoLobby, DtoLobbyParticipant } from 'api/schemas/lobbyApi'
import { DtoMatch } from 'api/schemas/matchApi'
import { atom, computed } from 'nanostores'
import { logWarn } from 'utils/logger'
import { logger } from '@nanostores/logger'
import { redirect } from 'react-router'
import api from 'api/api'
import buttonClickAddToPartySound from 'sounds/add-friend-to-party.wav'
import buttonClickReadyOffSound from 'sounds/ready-button-off.wav'
import buttonClickReadyOnSound from 'sounds/ready-button-on.wav'
import changeLobbySound from 'sounds/change_lobby.wav'
import playSound from 'utils/sound'
import round from 'utils/round'
import routes from 'router/routes'

const buttonClickReadyOn = new Audio(buttonClickReadyOnSound)
const buttonClickReadyOff = new Audio(buttonClickReadyOffSound)
const changeLobbyAudio = new Audio(changeLobbySound)
const buttonClickAddToParty = new Audio(buttonClickAddToPartySound)

export interface Bet {
  value: number
}

export interface Country {
  name: string
  imgUrl: string
}

export interface GameMap {
  id: string
  name: string
  imgUrl: string
  label?: string
}

export type Participant = DtoLobbyParticipant

export interface Player {
  isReady: boolean
  position: number
  team: 'RED' | 'BLUE'
  profile?: Participant
}

export const $player = atom<Player>({
  team: 'RED',
  position: 0,
  isReady: false,
})

export const $match = atom<DtoMatch | null>(null)
export const $timer = atom<number>(20)
export const $timeToConfirmEnd = atom<number | null>(null)
export const $confirmCount = atom<number | null>(null)
export const $TIME_TO_CONFIRM_RESERVE = 2_000

export const setTimer = (count: number) => {
  $timer.set(count)
}

export const $lobby = atom<DtoLobby | null>(null)
export const $config = atom<DtoComposite | null>(null)

export const $isConfirmPopupOpen = atom<boolean>(false)

export const $lobbyMemberByPosition = computed([$lobby], (lobby) => {
  if (lobby?.participants) {
    return lobby.participants.reduce(
      (acc, participant) => {
        if (participant.slotOrder && participant.teamColor) {
          acc[participant.teamColor as 'RED' | 'BLUE'][participant.slotOrder] =
            participant
        } else {
          acc.wait.push(participant)
        }
        return acc
      },
      { RED: {}, BLUE: {}, wait: [] } as {
        wait: Participant[]
        RED: Record<number, Participant>
        BLUE: Record<number, Participant>
      }
    )
  }
  return null
})

export const $teamsPowers = computed([$lobby], (lobby) => {
  if (lobby) {
    return lobby.participants?.reduce(
      (acc, participant) => {
        if (participant.slotOrder && participant.teamColor) {
          acc[participant.teamColor as 'RED' | 'BLUE'] +=
            Number(round(participant.user?.CSProfileStat?.rating)) || 0
        }
        return acc
      },
      { RED: 0, BLUE: 0 } as {
        RED: number
        BLUE: number
      }
    )
  }
  return null
})

export const $isUserReady = computed(
  [$lobby, $userProfile],
  (lobby, userProfile) => {
    if (lobby && userProfile) {
      return lobby.participants?.filter(
        (participant) => participant.user?.id === userProfile.id
      )?.[0].isReady
    }
    return false
  }
)

export const getConfig = async () => {
  let data: DtoComposite | null = null
  try {
    const res = await api.dictionary.postData({
      withBet: true,
      withCountry: true,
      withGameMap: true,
      withGameMode: true,
    })
    data = res.data
    $config.set(data)
  } catch (e) {
    throw Error(e as string)
  }
}

export const getGame = async (id: string) => {
  let data: DtoLobby | null = null
  try {
    const res = await api.match.getMatch({ matchId: id })
    data = res.data[0]?.url
    return data
  } catch (e) {
    throw Error(e as string)
  }
}

export const setMatch = (match: DtoMatch | null) => {
  $match.set(match)
}

export const setPlayerPosition = (position: number, team: 'RED' | 'BLUE') => {
  $player.set({ ...$player.get(), team, position })
}

export function setPlayerReadyStatus(isReady: boolean) {
  $player.set({ ...$player.get(), isReady })
}

export const setLobby = (party: DtoLobby | null) => {
  if (party) {
    $lobby.set({ ...$lobby.get(), ...party })
  } else {
    $lobby.set(null)
  }
  return
}

export const createLobby = async (lobbyType?: string) => {
  const { data } = await api.lobby.postLobby({
    gameName: 'CS',
    gameFormat: lobbyType,
  })
  $lobby.set(data)
}

export const getLobby = async () => {
  let data: DtoLobby | null = null

  try {
    const res = await api.lobby.getCurrent()
    data = res.data
    $lobby.set(data)
  } catch (e) {
    console.log(e)
  }
}

export const getMatch = async (id: string) => {
  try {
    const res = await api.match.getMatch({ matchId: id })
    const data = res.data[0]
    console.log(data)
    $match.set(data)
  } catch (e) {
    console.log(e)
  }
}

export const leaveLobby = async () => {
  const id = $lobby.get()?.id
  if (id) {
    await api.lobby.postLeave(id)
    $lobby.set(null)
  }
  return
}

export const sendInvitesToParty = async () => {
  const id = $lobby.get()?.id
  if (id) {
    await api.lobby.postInviteParty(id)
  }
}

export const inviteToLobby = async (id: string) => {
  const lobbyId = $lobby.get()?.id
  if (lobbyId) {
    try {
      const { data } = await api.lobby.postInvite(lobbyId || '', {
        participantId: id,
      })
      $lobby.set(data)
      await buttonClickAddToParty.play()
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      if (e.response?.status === 409) {
        console.log('Второй раз добавить нельзя')
      }
    }

    return
  } else {
    await createLobby()

    await inviteToLobby(id)
    await buttonClickAddToParty.play()
  }
}

export const joinToLobby = async (id: string) => {
  try {
    const { data } = await api.lobby.postJoin(id)
    $lobby.set(data)

    redirect(`#${routes.play()}`)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    logWarn(`Сработал дважды join. ${id}. ${JSON.stringify(e)}`)
  }
}

export const declineLobby = async (id: string) => {
  await api.lobby.postInviteDecline(id)
}

export const kickFromLobby = async (id: string) => {
  await api.lobby.postKick($lobby.get()?.id || '', id)
  await playSound(changeLobbyAudio)
}

export const changeLobbyHost = async (hostId: string) => {
  const id = $lobby.get()?.id
  if (id) {
    await api.lobby.postHost(id, hostId)
  }
}

export const changeLobbyMap = async (mapIds: string[] | null) => {
  const id = $lobby.get()?.id
  if (id && mapIds) {
    const { data } = await api.lobby.postLobby3(id, { mapIds })
    $lobby.set(data)
    await playSound(changeLobbyAudio)
  }
}

export const changeLobbyBet = async (bets: number[]) => {
  const id = $lobby.get()?.id
  if (id) {
    const { data } = await api.lobby.postLobby2(id, { bets })
    $lobby.set(data)
    await playSound(changeLobbyAudio)
  }
}

export const changeLobbyCountry = async (country: string) => {
  const id = $lobby.get()?.id
  if (id) {
    const { data } = await api.lobby.postCountry(id, country)
    $lobby.set(data)
    await playSound(changeLobbyAudio)
  }
}

export const changeLobbyReady = async () => {
  const id = $lobby.get()?.id

  $isUserReady.get()
    ? await playSound(buttonClickReadyOff)
    : await playSound(buttonClickReadyOn)

  if (
    $lobby.get()?.gameFormat !== 'CUSTOM' &&
    $lobby.get()?.status !== 'NEW' &&
    id
  ) {
    const { data } = await api.lobby.postSearchStop(id)
    $lobby.set(data)
    return
  }
  if (id) {
    const { data } = await api.lobby.postReady(id)
    $lobby.set(data)
  }
}

export const changeTeam = async (color: string, position: number) => {
  const id = $lobby.get()?.id
  if (id) {
    const { data } = await api.lobby.postTeam(id, {
      teamColour: color,
      slotOrder: position,
    })
    $lobby.set(data)
    await playSound(changeLobbyAudio)
  }
}

export const leaveTeam = async () => {
  const id = $lobby.get()?.id
  if (id) {
    const { data } = await api.lobby.postWait(id)
    $lobby.set(data)
    await playSound(changeLobbyAudio)
  }
}

// export const createLobby = action()

// $userProfile.listen(async (userProfile) => {
//   if (userProfile?.lobbyId && userProfile.lobbyId !== $lobby.get()?.id) {
//     await getLobby(userProfile.lobbyId)
//   }
//   if (!userProfile?.lobbyId) {
//     $lobby.set(null)
//   }
// })

logger(
  {
    Match: $match,
    Lobby: $lobby,
    Player: $player,
  },
  {
    messages: {
      mount: false,
      unmount: false,
    },
  }
)
