const MatchTimoutIcon = ({ className }: { className?: string }) => (
  <svg
    width="24"
    height="24"
    fill="inherit"
    viewBox="0 0 24 24"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M10.0902 1.0181C9.93108 1.0783 9.77198 1.22879 9.69889 1.39219C9.63009 1.54269 9.61719 1.68028 9.61719 2.39407V3.22395H10.0902H10.5632V3.83024V4.43652L10.7352 4.40642C11.2555 4.32043 12.6228 4.32043 13.1431 4.40642L13.3151 4.43652V3.83024V3.22395H13.7924H14.2654L14.2525 2.32527C14.2396 1.33629 14.231 1.31479 13.9429 1.0998C13.8268 1.0138 13.7709 1.0095 11.9907 1.0009C10.9803 0.9966 10.1246 1.0052 10.0902 1.0181Z" />
    <path d="M6.43572 3.12127C6.40133 3.13847 6.03583 3.34057 5.63164 3.57706C4.78026 4.06725 4.70286 4.14465 4.68136 4.53594C4.66846 4.79394 4.66846 4.79394 5.06835 5.49052C5.28765 5.87751 5.48115 6.19141 5.50265 6.19141C5.55424 6.19141 8.02669 4.75524 8.02669 4.72514C8.02669 4.70794 7.85039 4.39405 7.6354 4.02425C7.18391 3.25027 7.06781 3.12987 6.72382 3.11267C6.59912 3.10407 6.47012 3.10837 6.43572 3.12127Z" />
    <path d="M16.8148 3.15997C16.7374 3.19867 16.6385 3.27177 16.5912 3.32337C16.5052 3.41797 15.7656 4.66924 15.7656 4.72084C15.7656 4.75524 18.2338 6.19141 18.2897 6.19141C18.3069 6.19141 18.5047 5.87751 18.724 5.49052L19.1239 4.79394L19.111 4.53164C19.0852 4.14035 18.9992 4.05005 18.122 3.55126C17.305 3.08258 17.0943 3.01808 16.8148 3.15997Z" />
    <path d="M11.0154 5.16365C7.55824 5.52484 4.56981 7.92419 3.46043 11.2351C1.89957 15.8962 4.43651 20.9701 9.11481 22.5396C13.7759 24.1004 18.8498 21.5635 20.4193 16.8852C21.8554 12.5982 19.8388 7.90269 15.7238 5.96343C14.2919 5.28835 12.5891 5.00026 11.0154 5.16365ZM13.0578 6.90081C14.6058 7.14591 15.9345 7.82529 17.0567 8.94327C18.4628 10.3493 19.1723 12.0693 19.1723 14.0602C19.1723 16.051 18.4628 17.771 17.0567 19.177C15.6507 20.5831 13.9307 21.2926 11.9398 21.2926C9.94899 21.2926 8.22903 20.5831 6.82296 19.177C5.41689 17.771 4.70741 16.051 4.70741 14.0602C4.70741 12.0693 5.41689 10.3493 6.82296 8.94327C8.10003 7.6662 9.6523 6.96531 11.5099 6.81911C11.8237 6.79331 12.6665 6.84061 13.0578 6.90081Z" />
    <path d="M11.3816 8.3413C8.81023 8.6036 6.73338 10.53 6.28189 13.0712C6.18729 13.6087 6.18729 14.5117 6.28189 15.0492C6.70328 17.427 8.57373 19.2975 10.9516 19.7188C11.4891 19.8134 12.392 19.8134 12.9295 19.7188C15.3074 19.2975 17.1778 17.427 17.5992 15.0492C17.6938 14.5117 17.6938 13.6087 17.5992 13.0712C17.2337 11.0072 15.7546 9.28298 13.7895 8.62509C13.0284 8.3714 12.1384 8.2682 11.3816 8.3413ZM15.1483 11.291C14.9677 11.807 14.4431 12.7229 13.6132 13.9527C12.9553 14.9374 12.7704 15.1566 12.5382 15.2254C11.9019 15.4189 10.9086 14.559 10.8355 13.7635C10.8011 13.4066 10.8914 13.2475 11.2483 13.011C11.7858 12.6541 13.2735 11.8672 14.0647 11.5146C14.4302 11.3512 15.1741 11.0889 15.1999 11.1104C15.2042 11.119 15.1827 11.2007 15.1483 11.291Z" />
  </svg>
)

export default MatchTimoutIcon
