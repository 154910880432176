import { $timer, setTimer } from 'stores/lobbyStore'
import { DtoLobby } from 'api/schemas/lobbyApi'
import { Header4, Header6, Header7, Header8, Text5 } from 'styles/typography'
import { debounce } from 'lodash'
import { useCallback, useEffect, useMemo } from 'react'
import { useStore } from '@nanostores/react'
import { useTranslation } from 'react-i18next'
import GreenStatusLine from 'images/GreenStatusLine'
import ReadyButtonIcon from 'images/ReadyButtonIcon'
import RedStatusLine from 'images/RedStatusLine'
import buttonClickReconnectSound from 'sounds/reconnect.wav'
import buttonPreparingSound from 'sounds/preparing.wav'
import showNotification from 'utils/showNotification'
import startButtonBG from 'images/StartButtonBG.webp'
import styled from 'styled-components'
import useSound from 'hooks/useSound'

type ButtonStatus = 'preparing' | 'waiting' | 'process' | 'starting'

const getColor = (status: ButtonStatus) => {
  switch (status) {
    case 'preparing':
      return {
        hover: 'rgba(238, 157, 62, 0.15)',
        border: 'rgba(255, 255, 255, 0.15)',
        background: 'rgba(255, 255, 255, 0.03)',
      }
    case 'waiting':
      return {
        border: 'rgba(255, 255, 255, 0.30)',
        hover:
          'linear-gradient(180deg, rgba(255, 255, 255, 0.20) 0%, rgba(152, 0, 0, 0.10) 55.21%, rgba(152, 0, 0, 0.00) 100%), #FFCD29',
        background:
          'linear-gradient(180deg, rgba(255, 255, 255, 0.20) 0%, rgba(152, 0, 0, 0.10) 55.21%, rgba(152, 0, 0, 0.00) 100%), #FFCD29',
      }
    case 'starting':
      return {
        border: 'rgba(255, 255, 255, 0.30)',
        hover:
          ' linear-gradient(180deg, rgba(117, 233, 163, 0.40) 0%, rgba(0, 52, 12, 0.25) 50.59%, rgba(57, 226, 125, 0.22) 100%), #00D656',
        background:
          'linear-gradient(180deg, rgba(117, 233, 163, 0.40) 0%, rgba(0, 52, 12, 0.25) 50.59%, rgba(57, 226, 125, 0.22) 100%), #00D656',
      }
    case 'process':
      return {
        border: '#2CFF80',
        hover: 'rgba(238, 157, 62, 0.15)',
        background: 'rgba(255, 255, 255, 0.03)',
      }
  }
}

interface StyledButtonProps {
  $hasLobby: boolean
  $status: ButtonStatus
}

const StyledButton = styled.button<StyledButtonProps>`
  height: 308px;
  width: 124px;
  display: flex;
  flex-direction: column;
  position: relative;

  background: ${({ $status }) => getColor($status).background};
  background-image: url(${startButtonBG});

  border-radius: 0px 6px;
  border: 1px solid ${({ $status }) => getColor($status).border};
  background-position: center;
  background-size: cover;

  /* BlurEffect */
  backdrop-filter: blur(7.5px);

  opacity: ${({ $hasLobby }) => ($hasLobby ? '1' : '0.7')};
  cursor: ${({ $hasLobby }) => ($hasLobby ? 'pointer !important' : '')};

  &:hover {
    background: ${({ $status }) => getColor($status).hover};
    background-image: url(${startButtonBG});

    border-radius: 0px 6px;
    border: 1px solid ${({ $status }) => getColor($status).border};
    background-position: center;
    background-size: cover;
  }

  transition: 0.3s;
`

const StyledButtonContent = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 13px 12px;
`

interface StyledReadyButtonIcon {
  $isReady: boolean
}

const StyledReadyButtonIcon = styled(ReadyButtonIcon)<StyledReadyButtonIcon>`
  fill: ${({ $isReady }) => ($isReady ? 'black' : 'white')};
`

interface StyledTextProps {
  $isReady: boolean
}

const StyledText = styled(Header4)<StyledTextProps>`
  text-transform: uppercase;
  text-align: left;
  margin-top: 10px;
  line-height: 100%;

  color: ${({ $isReady }) => ($isReady ? 'black' : 'white')};
`

const StyledInfo = styled.div<StyledTextProps>`
  display: flex;
  align-items: center;
  gap: 7px;

  color: ${({ $isReady }) => ($isReady ? 'black' : 'white')};
`

const StyledPlayersCount = styled(Header6)``

const StyledReadyMessage = styled(Text5)<StyledTextProps>`
  color: ${({ $isReady }) => ($isReady ? 'black' : 'white')};
  text-align: left;
`

const StyledReady = styled(Text5)`
  font-weight: 500;
`

const StyledReadyItems = styled.div`
  display: flex;
  margin-top: 8px;
  gap: 3px;
`

interface StyledReadyItem {
  $isReady: boolean
  $isActive: boolean
}

const StyledReadyItem = styled.div<StyledReadyItem>`
  width: 1px;
  height: 5px;

  background-color: ${({ $isReady, $isActive }) =>
    $isActive
      ? '#2CFF80'
      : $isReady
        ? 'rgba(0, 0, 0, 0.2)'
        : 'rgba(255, 255, 255, 0.2)'};
`

const StyledMatchStarting = styled(Header7)`
  text-transform: none;
`

const StyledReconnectButton = styled(Header4)`
  color: #2cff80;

  text-transform: uppercase;

  display: flex;
  writing-mode: vertical-rl;
  align-items: end;
  justify-content: center;
  line-height: 95%;
`

interface StartMatchButtonProps {
  isHost?: boolean
  isReady?: boolean
  url: string | null
  isHostReady?: boolean
  lobby?: DtoLobby | null
  onReadyClick?: () => void
  type?: 'custom' | 'rating'
  startCSGO: (url: string) => void
}

const StyledMatchSearch = styled(Header8)`
  font-weight: bold;
  line-height: 120%;
  color: white;
`

const StyledMatchSearching = styled(StyledMatchSearch)`
  @keyframes l {
    to {
      clip-path: inset(0 -3.3px 0 0);
    }
  }

  display: inline-block;
  clip-path: inset(0 10px 0 0);
  animation: l 1s steps(4) infinite;
`

const StyledRoot = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  height: 24px;
  width: 100%;
`

const StyledLine = styled.div`
  width: 100%;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  margin-bottom: -6px;
`

const StyledStatusRedLine = styled(RedStatusLine)`
  position: absolute;
  left: 0;
  top: 50px;
`
const StyledStatusGreenLine = styled(GreenStatusLine)`
  position: absolute;
  left: 0;
  top: 50px;
`

const StartMatchButton: React.FC<StartMatchButtonProps> = ({
  url,
  lobby,
  isHost,
  startCSGO,
  isHostReady,
  onReadyClick,
  type = 'custom',
  isReady = false,
}) => {
  const timer = useStore($timer)
  const { t } = useTranslation()
  const [reconnectPlay] = useSound(buttonClickReconnectSound)
  const [preparingPlay] = useSound(buttonPreparingSound)

  const hasPlayers = lobby?.participants?.filter((p) => p.teamColor).length

  const handleReadyStatus = debounce(() => {
    if (!hasPlayers) {
      return
    }
    if (lobby?.status === 'MATCH_PREPARED' && url && !timer) {
      reconnectPlay()
      startCSGO(url)
      return
    }
    if (isHostReady && !isOptionChoosen) {
      showNotification({
        type: 'info',
        id: 'isOptionChoosenInfo',
        text: t('play.chooseOptions'),
        title: t('play.customGame.lobby'),
      })
    }
    if (
      (isHostReady || type === 'rating') &&
      isOptionChoosen &&
      (lobby?.status === 'NEW' ||
        lobby?.status === 'READY_TO_SEARCH' ||
        lobby?.status === 'SEARCHING' ||
        lobby?.status === 'SEARCHING_STOP')
    ) {
      onReadyClick && onReadyClick()
    }
  }, 300)

  const isOptionChoosen = Boolean(
    lobby?.country && lobby.bets?.length && lobby.gameMaps?.length
  )

  const countReadyMembers = useMemo(() => {
    return (
      lobby?.participants?.filter((participant) => participant.isReady)
        .length || 0
    )
  }, [lobby?.participants])

  const tick = useCallback((count: number) => {
    if (count !== 0) {
      setTimer(count - 1)

      setTimeout(() => tick(count - 1), 1000)
    }
  }, [])

  useEffect(() => {
    if (lobby?.status === 'READY_TO_MATCH') {
      tick(20)
    }
  }, [lobby?.status, tick])

  useEffect(() => {
    if (lobby?.status === 'MATCH_PREPARED' && timer === 20) {
      setTimer(0)
    }
  }, [lobby?.status, timer])

  const status: ButtonStatus = useMemo(() => {
    if (lobby?.status === 'READY_TO_MATCH') {
      return 'starting'
    }

    if (
      (lobby?.status === 'MATCH_PREPARED' && url) ||
      lobby?.status === 'SEARCHING' ||
      lobby?.status === 'READY_TO_SEARCH' ||
      lobby?.status === 'SEARCHING_STOP'
    ) {
      return 'process'
    }

    if (isReady) {
      return 'waiting'
    }

    return 'preparing'
  }, [lobby, isReady, url])

  useEffect(() => {
    if (lobby?.status === 'SEARCHING') {
      preparingPlay()
    }
  }, [lobby?.status, preparingPlay])

  return (
    <StyledRoot>
      <StyledHeader>
        <StyledLine />
      </StyledHeader>
      <StyledButton
        $status={status}
        $hasLobby={Boolean(lobby)}
        onClick={handleReadyStatus}
      >
        {(isOptionChoosen && isHostReady) || lobby?.status !== 'NEW' ? (
          <StyledStatusGreenLine />
        ) : (
          <StyledStatusRedLine />
        )}
        <StyledButtonContent>
          <div>
            {type === 'custom' && isHostReady && isOptionChoosen && (
              <>
                <StyledInfo $isReady={isReady && !url}>
                  <StyledPlayersCount>
                    {countReadyMembers} / {lobby?.participants?.length}
                  </StyledPlayersCount>
                  <StyledReady>{t('play.customGame.ready')}</StyledReady>
                </StyledInfo>
                <StyledReadyItems>
                  {new Array(lobby?.participants?.length)
                    .fill('')
                    .map((_, index) => (
                      <StyledReadyItem
                        key={index}
                        $isReady={isReady}
                        $isActive={index < countReadyMembers}
                      />
                    ))}
                </StyledReadyItems>
              </>
            )}
            {lobby?.status === 'SEARCH_MATCH' && (
              <StyledInfo $isReady={isReady}>
                <StyledReady>
                  {t('play.ratingGame.serachingMatchDescription')}
                </StyledReady>
              </StyledInfo>
            )}
            {!isHostReady && type !== 'rating' && (
              <StyledReadyMessage $isReady={isReady}>
                {t('play.waitHost')}
              </StyledReadyMessage>
            )}
            {!isOptionChoosen && isHostReady && (
              <StyledReadyMessage $isReady={isReady}>
                {t('play.chooseOptions')}
              </StyledReadyMessage>
            )}
          </div>
          {lobby?.status === 'MATCH_PREPARED' && url && !timer && (
            <StyledReconnectButton>Reconnect</StyledReconnectButton>
          )}
          <div>
            {!(lobby?.status === 'MATCH_PREPARED' && url) && (
              <StyledReadyButtonIcon
                $isReady={status === 'waiting' || status === 'starting'}
              />
            )}
            <StyledText $isReady={isReady}>
              {type === 'custom' &&
                lobby?.status !== 'READY_TO_MATCH' &&
                lobby?.status !== 'MATCH_PREPARED' &&
                t('play.customGame.imready')}
              {type === 'rating' && lobby?.status === 'NEW' && isHost && (
                <StyledMatchSearch>
                  {t('play.ratingGame.findMatch')}
                </StyledMatchSearch>
              )}
              {type === 'rating' &&
                lobby?.status === 'NEW' &&
                !isHost &&
                t('play.customGame.imready')}
              {type === 'rating' &&
                (lobby?.status === 'SEARCHING' ||
                  lobby?.status === 'READY_TO_SEARCH') && (
                  <StyledMatchSearching>
                    {t('play.ratingGame.serachingMatch')}
                  </StyledMatchSearching>
                )}
              {type === 'rating' && lobby?.status === 'SEARCHING_STOP' && (
                <StyledMatchSearching>
                  {t('play.ratingGame.serachingStop')}
                </StyledMatchSearching>
              )}
              {lobby?.status === 'READY_TO_MATCH' && (
                <StyledMatchStarting>{t('play.checking')}</StyledMatchStarting>
              )}
              {lobby?.status === 'MATCH_PREPARED' && (
                <>
                  {timer ? (
                    <StyledMatchStarting>
                      {t('play.matchStart')}
                    </StyledMatchStarting>
                  ) : (
                    <StyledMatchSearch>
                      {t('play.matchStarted')}
                    </StyledMatchSearch>
                  )}

                  {timer ? (
                    <div>
                      {timer} {t('play.sec')}
                    </div>
                  ) : null}
                </>
              )}
            </StyledText>
          </div>
        </StyledButtonContent>
        {/* <StartButton
          hover={hover}
          isReady={isReady}
          isOkStatus={isReady}
          isStarting={lobby?.status === 'READY_TO_MATCH'}
        /> */}
      </StyledButton>
    </StyledRoot>
  )
}

export default StartMatchButton
