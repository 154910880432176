import { $activeChatMessages } from 'stores/chatStores/singleChat'
import { styled } from 'styled-components'
import { useStore } from '@nanostores/react'
import ChatField from 'components/Chat/ChatField'
import ChatLeftUsersPanel from 'components/Chat/ChatLeftUsersPanel'

const StyledInviteWrapper = styled.div`
  display: flex;
  gap: 10px;
  padding-right: 20px;
  height: calc(100% - 50px);
`

const StyledChatWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const StyledChatField = styled.div`
  min-height: 100%;
`
const InviteWrapper: React.FC = () => {
  const activeChatMessages = useStore($activeChatMessages)
  return (
    <StyledInviteWrapper>
      <ChatLeftUsersPanel />
      <StyledChatWrapper>
        <StyledChatField>
          <ChatField messages={activeChatMessages} />
        </StyledChatField>
      </StyledChatWrapper>
    </StyledInviteWrapper>
  )
}

export default InviteWrapper
