import { Text6 } from 'styles/typography'
import styled from 'styled-components'

const StyledTooltip = styled.div`
  padding: 4px;
  border-radius: 0px 2px;
  border: 0.5px solid rgba(255, 255, 255, 0.1);
  background: rgba(255, 255, 255, 0.03);
`
const StyledTooltipText = styled(Text6)`
  color: #2cff80;
`

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const CustomTooltip: React.FC = (props: any) => {
  const { active, payload } = props
  return (
    <>
      {active ? (
        <StyledTooltip className="custom-tooltip">
          <StyledTooltipText>{`${payload?.[0]?.value}`}</StyledTooltipText>
        </StyledTooltip>
      ) : (
        ''
      )}
    </>
  )
}

export default CustomTooltip
