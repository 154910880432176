import { QRCodeToDataURLOptions } from 'qrcode'

export const qrOptions: QRCodeToDataURLOptions = {
  margin: 1,
  width: 267,
  type: 'image/png',
  color: {
    light: '#0000', // Transparent background
    dark: '#FFFFFF', // Blue dots
  },
}

export const qrOptionsRegistration: QRCodeToDataURLOptions = {
  margin: 1,
  width: 163,
  type: 'image/png',
  color: {
    light: '#0000', // Transparent background
    dark: '#FFFFFF', // Blue dots
  },
}
