import { $activeChatID, getChatMessages } from 'stores/chatStores/singleChat'
import {
  $activeGroupsChatID,
  getGroupsChatMessages,
} from 'stores/chatStores/groupChats'
import {
  $activeNotificationAutorMessages,
  INotification,
} from 'stores/chatStores/notificationsStore'
import { $tabActive } from 'stores/chatStores/chatStore'
import { DtoMessage } from 'api/schemas/chatApi'
import { DtoNotification } from 'api/schemas/notificationApi'
import { styled } from 'styled-components'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useStore } from '@nanostores/react'
import ChatNotification from 'components/Chat/ChatNotification'
import MessageItem, { chatActionType } from 'components/Chat/MessageItem'
import debounce from 'utils/debounce'
import scrollChatToBottom from 'utils/scrollChatToBottom'

export interface ChatMessages {
  messages?: DtoMessage[]
  notifications?: INotification
}

const StyledChatWrapper = styled.div`
  border: 1px solid rgba(255, 255, 255, 0.15);
  width: 100%;
  height: 100%;
  padding-right: 5px;
`

const StyledScrollbar = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  gap: 7px;
  padding: 12px;

  &::-webkit-scrollbar {
    width: 2px;
  }

  &::-webkit-scrollbar-track {
    margin: 4px 0 4px;
    border-radius: 1px;
    opacity: 0.1;
    background: rgba(217, 217, 217, 0.1);
  }

  &::-webkit-scrollbar-thumb {
    background-color: white;
    border-radius: 1px;
  }
`

const ChatField: React.FC<ChatMessages> = ({ messages }) => {
  const tabActive = useStore($tabActive)
  const activeNotificationAutorMessages = useStore(
    $activeNotificationAutorMessages
  )

  const activeSingleChatId = useStore($activeChatID)
  const activeGroupsChatID = useStore($activeGroupsChatID)
  const scrollbarRef = useRef<HTMLDivElement | null>(null)
  const [isFetching, setIsFetching] = useState(false)
  const [scrolledChatID, setScrolledChatID] = useState('')

  const debouncedFetch = debounce(async () => {
    if (tabActive === 'chat' && messages?.length) {
      await getChatMessages(activeSingleChatId, messages.reverse()[0].createdAt)
    } else if (tabActive === 'party' && messages?.length) {
      await getGroupsChatMessages(messages.reverse()[0].createdAt)
    }
    setIsFetching(false)
  }, 1000)

  const handleScroll = useCallback(async () => {
    const element = scrollbarRef.current
    if (!element || element.scrollTop !== 0 || isFetching) return

    setIsFetching(true)
    await debouncedFetch()
  }, [debouncedFetch, isFetching])

  useEffect(() => {
    const element = scrollbarRef.current
    if (element) {
      element.addEventListener('wheel', handleScroll)
    }

    return () => {
      if (element) {
        element.removeEventListener('wheel', handleScroll)
      }
    }
  }, [handleScroll])

  useEffect(() => {
    if (
      (tabActive === 'chat' && scrolledChatID === activeSingleChatId) ||
      (tabActive === 'party' && scrolledChatID === activeGroupsChatID)
    )
      return
    if (tabActive === 'chat') setScrolledChatID(activeSingleChatId)
    if (tabActive === 'party') setScrolledChatID(activeGroupsChatID)
    scrollChatToBottom()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages])

  return (
    <StyledChatWrapper>
      <StyledScrollbar id="chatScroll" ref={scrollbarRef}>
        {tabActive !== 'invite' ? (
          messages ? (
            messages
              .map((message) => (
                <MessageItem
                  key={message.id}
                  message={message.text}
                  subject={message.user}
                  nick={message.author?.name || ''}
                  personId={message.author?.id || ''}
                  avatar={message.author?.imgUrl || ''}
                  actionType={message.status as chatActionType}
                  type={message.isSystem ? 'action' : 'message'}
                  date={message.createdAt ? message.createdAt : null}
                />
              ))
              .reverse()
          ) : (
            ''
          )
        ) : activeNotificationAutorMessages ? (
          activeNotificationAutorMessages.map(
            (notification: DtoNotification) => (
              <ChatNotification
                key={notification.id}
                notification={notification}
              />
            )
          )
        ) : (
          <></>
        )}
      </StyledScrollbar>
    </StyledChatWrapper>
  )
}

export default ChatField
