const AddFile = () => (
  <svg
    width="16"
    fill="none"
    height="16"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="unset"
      clipRule="evenodd"
      fillRule="evenodd"
      d="M9.00405 6.64889L9.00405 0H6.99595V6.64889H0V8.74667H6.99595V16H9.00405V8.74667H16V6.64889H9.00405Z"
    />
  </svg>
)

export default AddFile
