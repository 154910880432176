import { FC } from 'react'
import SmallHostIcon from 'images/SmallHostIcon'
import TeamIcons from 'images/userCard/TeamIcons'
import YouIcon from 'images/YouIcon'
import styled from 'styled-components'

const StyledStasuses = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 9px;
`

const StyledHost = styled.div`
  padding-top: 2px;
`

interface PlayerStatusesProps {
  isYou?: boolean
  isHost?: boolean
  teamIndex?: number
}

const PlayerStatuses: FC<PlayerStatusesProps> = ({
  isYou,
  isHost,
  teamIndex,
}) => {
  return (
    <StyledStasuses>
      {isYou && <YouIcon />}
      {isHost && (
        <StyledHost>
          <SmallHostIcon />
        </StyledHost>
      )}
      {teamIndex !== undefined && <TeamIcons group={teamIndex} />}
    </StyledStasuses>
  )
}

export default PlayerStatuses
