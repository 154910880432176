import { addPopup } from 'stores/appStore'
import { toast } from 'react-toastify'
import { v4 as uuidv4 } from 'uuid'
import InfoModal from 'components/InfoModal'
import InviteModal, { SenderInfo } from 'components/Modals/InviteModal'
import notificationSound from 'sounds/notification-main.wav'
import notificationSoundError from 'sounds/error.wav'
import playSound from 'utils/sound'

const mainNotification = new Audio(notificationSound)
const errorNotification = new Audio(notificationSoundError)

interface ShowNotification {
  id?: string
  text: string
  title?: string
  redirect?: string
  info?: SenderInfo
  onAccept?: () => void
  onReject?: () => void
  type?: 'party' | 'friend' | 'lobby' | 'error' | 'info' | 'friendAccepted'
}

const showNotification = ({
  id,
  info,
  text,
  title,
  redirect,
  onReject,
  onAccept,
  type = 'error',
}: ShowNotification) => {
  const toastId = id || uuidv4()
  addPopup(
    toastId,
    toast(
      (props) => {
        switch (type) {
          case 'party':
          case 'friend':
          case 'lobby':
          case 'friendAccepted':
            return (
              <InviteModal
                info={info}
                type={type}
                text={text}
                id={toastId}
                redirect={redirect}
                onAccept={onAccept}
                onReject={onReject}
                {...props}
              />
            )
          case 'error':
          case 'info':
          default:
            return (
              <InfoModal
                text={text}
                type={type}
                id={toastId}
                title={title || ''}
                onAccept={onAccept}
                {...props}
              />
            )
        }
      },
      {
        icon: false,
        draggable: true,
        theme: 'colored',
        closeButton: false,
        // toastId: toastId,
        pauseOnHover: true,
        closeOnClick: false,
        progress: undefined,
        position: 'top-right',
        hideProgressBar: true,
        autoClose: type === 'error' ? 2000 : false,
        onOpen: () =>
          setTimeout(
            () =>
              type === 'error'
                ? playSound(errorNotification)
                : playSound(mainNotification),
            300
          ),
      }
    )
  )
}
export default showNotification
