import { $lobby, createLobby } from 'stores/lobbyStore'
import { $userProfile } from 'stores/userStore'
import { Header3 } from 'styles/typography'
import { styled } from 'styled-components'
import { useLayoutEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { useStore } from '@nanostores/react'
import { useTranslation } from 'react-i18next'
import Button from 'components/Button'
import chooseGameCustom from 'images/chooseGameCustom.webp'
import chooseGameRaiting1x1 from 'images/chooseGameRaiting1x1.webp'
import chooseGameRaiting5x5 from 'images/chooseGameRaiting5x5.webp'
import routes from 'router/routes'
import showNotification from 'utils/showNotification'

interface HoverStatus {
  $isDisabled?: boolean
  $hoverStatus: 'current' | 'another' | 'none'
}

const StyledRoot = styled.div`
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 90px;
`

const StyledWrapper = styled.div`
  height: 100%;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 64px;
`

const StyledItem = styled.div<HoverStatus>`
  position: relative;
  width: 317px;
  height: 662px;
  cursor: pointer;

  opacity: ${({ $isDisabled }) => ($isDisabled ? '0.2' : '1')};

  display: flex;
  align-items: center;
  /* justify-content: ${({ $hoverStatus }) =>
    $hoverStatus === 'current' ? 'space-between' : 'center'}; */
  justify-content: center;
  flex-direction: column;

  transform: scale(
    ${({ $hoverStatus }) => ($hoverStatus === 'current' ? '1.15' : '1')}
  );

  transition: 0.2s;
`

const StyledTitle = styled.div<HoverStatus>`
  position: relative;
  font-family: 'Ruberoid';
  font-size: ${({ $hoverStatus }) =>
    $hoverStatus === 'current' ? '42px' : '26px'};
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  white-space: pre-wrap;
  top: ${({ $hoverStatus }) => ($hoverStatus === 'current' ? '-200px' : '0')};
  line-height: ${({ $hoverStatus }) =>
    $hoverStatus === 'current' ? '90%' : '115%'};
  transition: 0.3s;
`

const StyledDescription = styled.div<HoverStatus>`
  position: relative;
  font-family: 'Ruberoid';
  font-size: 14px;
  padding: 0 10px;
  font-style: normal;
  font-weight: bold;
  text-align: center;
  line-height: 16px; /* 114.286% */
  text-transform: uppercase;
  white-space: pre-wrap;
  opacity: ${({ $hoverStatus }) => ($hoverStatus === 'current' ? '1' : '0')};
  top: 180px;

  transition: 0.2s;
`

const StyledBG = styled.img<HoverStatus>`
  position: absolute;

  width: 100%;
  height: 100%;
  opacity: ${({ $hoverStatus }) =>
    $hoverStatus === 'another'
      ? '0.6'
      : $hoverStatus === 'current'
        ? '1'
        : '0.85'};

  z-index: -1;
  transition: 0.2s;
`

const StyledButton = styled(Button)<HoverStatus>`
  position: relative;
  top: 200px;
  opacity: ${({ $hoverStatus }) => ($hoverStatus === 'current' ? '1' : '0')};
  transition: 0.2s;
`

const StyledBottomText = styled(Header3)`
  text-transform: uppercase;
`

type LobbyType = 'FIVE_TO_FIVE' | 'ONE_TO_ONE' | 'CUSTOM'

const LobbyChoiser = () => {
  const { t } = useTranslation()

  const currentUser = useStore($userProfile)
  const lobby = useStore($lobby)
  const navigate = useNavigate()
  const [currentHover, setCurrentHover] = useState<LobbyType | null>()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  useLayoutEffect(() => {
    if (lobby && lobby.id) {
      switch (lobby.gameFormat) {
        case 'FIVE_TO_FIVE':
          navigate(routes.playRating())
          break
        case 'ONE_TO_ONE':
          navigate(routes.play1x1())
          break
        case 'CUSTOM':
        default:
          navigate(routes.playCustom())
      }
    }
  }, [currentUser, lobby, navigate])

  const handleCreateLobby = async () => {
    if (currentHover && !isLoading) {
      setIsLoading(true)

      try {
        await createLobby(currentHover)
        // switch (currentHover) {
        //   case 'FIVE_TO_FIVE':
        //     navigate(routes.playRating())
        //     break
        //   case 'ONE_TO_ONE':
        //     navigate(routes.play1x1())
        //     break
        //   case 'CUSTOM':
        //   default:
        //     navigate(routes.playCustom())
        // }
      } catch (e) {
        showNotification({
          type: 'info',
          text: t('play.error'),
          id: 'failureLobbyCreate',
          title: t('play.somethingWistLobby'),
        })
      }
      setIsLoading(false)
    }
  }

  const getHoverStatus = (type: LobbyType) => {
    const status: unknown = !currentHover
      ? 'none'
      : currentHover === type
        ? 'current'
        : 'another'

    return status as 'current' | 'another' | 'none'
  }

  // if (lobby) {
  //   navigate(routes.playCustom())
  //   return null
  // }

  return (
    <StyledRoot>
      <StyledWrapper>
        <StyledItem
          onClick={handleCreateLobby}
          onMouseLeave={() => setCurrentHover(null)}
          $hoverStatus={getHoverStatus('FIVE_TO_FIVE')}
          onMouseEnter={() => setCurrentHover('FIVE_TO_FIVE')}
        >
          <StyledBG
            src={chooseGameRaiting5x5}
            $hoverStatus={getHoverStatus('FIVE_TO_FIVE')}
          />
          <StyledTitle $hoverStatus={getHoverStatus('FIVE_TO_FIVE')}>
            {t('lobbyChooser.Rating5Title')}
          </StyledTitle>
          <StyledDescription $hoverStatus={getHoverStatus('FIVE_TO_FIVE')}>
            {t('lobbyChooser.Rating5Description')}
          </StyledDescription>
          <StyledButton
            withCorner
            $hoverStatus={getHoverStatus('FIVE_TO_FIVE')}
          >
            {t('lobbyChooser.create')}
          </StyledButton>
        </StyledItem>
        <StyledItem
          onClick={handleCreateLobby}
          onMouseLeave={() => setCurrentHover(null)}
          $hoverStatus={getHoverStatus('ONE_TO_ONE')}
          onMouseEnter={() => setCurrentHover('ONE_TO_ONE')}
        >
          <StyledBG
            src={chooseGameRaiting1x1}
            $hoverStatus={getHoverStatus('ONE_TO_ONE')}
          />
          <StyledTitle $hoverStatus={getHoverStatus('ONE_TO_ONE')}>
            {t('lobbyChooser.Rating1Title')}
          </StyledTitle>
          <StyledDescription $hoverStatus={getHoverStatus('ONE_TO_ONE')}>
            {t('lobbyChooser.Rating1Description')}
          </StyledDescription>
          <StyledButton withCorner $hoverStatus={getHoverStatus('ONE_TO_ONE')}>
            {t('lobbyChooser.create')}
          </StyledButton>
        </StyledItem>
        <StyledItem
          onClick={handleCreateLobby}
          $hoverStatus={getHoverStatus('CUSTOM')}
          onMouseLeave={() => setCurrentHover(null)}
          onMouseEnter={() => setCurrentHover('CUSTOM')}
        >
          <StyledBG
            src={chooseGameCustom}
            $hoverStatus={getHoverStatus('CUSTOM')}
          />
          <StyledTitle $hoverStatus={getHoverStatus('CUSTOM')}>
            {t('lobbyChooser.customTitle')}
          </StyledTitle>
          <StyledDescription $hoverStatus={getHoverStatus('CUSTOM')}>
            {t('lobbyChooser.customDescription')}
          </StyledDescription>

          <StyledButton withCorner $hoverStatus={getHoverStatus('CUSTOM')}>
            {t('lobbyChooser.create')}
          </StyledButton>
        </StyledItem>
      </StyledWrapper>
      <StyledBottomText>{t('lobbyChooser.selectMode')}</StyledBottomText>
    </StyledRoot>
  )
}

export default LobbyChoiser
