import { $match } from 'stores/lobbyStore'
import { Header5, Text4 } from 'styles/typography'
import { useStore } from '@nanostores/react'
import { useTranslation } from 'react-i18next'
import CopyWrapper from 'components/CopyWrapper'
import dayjs from 'dayjs'
import styled from 'styled-components'

const MatchInfoHeader = () => {
  const { t } = useTranslation()
  const match = useStore($match)

  if (!match) return null

  const duration = (
    dayjs(match.finishAt).diff(dayjs(match.createdAt)) /
    1000 /
    60
  ).toFixed(0)

  return (
    <StyledMainInfo>
      <StyledMapWrapper>
        <StyledMapIcon src={match.gameMap?.imgUrl} />
        <StyledMainInfoItem>
          <StyledMainInfoText>{match.gameMap?.label}</StyledMainInfoText>
          <StyledMainInfoTitle>{t('results.map')}</StyledMainInfoTitle>
        </StyledMainInfoItem>
      </StyledMapWrapper>
      <StyledMainInfoItem>
        <CopyWrapper textToCopy={match.externalId || ''}>
          <StyledMainInfoText>{match.externalId || ''}</StyledMainInfoText>
        </CopyWrapper>
        <StyledMainInfoTitle>ID</StyledMainInfoTitle>
      </StyledMainInfoItem>
      <StyledMainInfoItem>
        <StyledMainInfoText>
          {duration} {t('results.min')}
        </StyledMainInfoText>
        <StyledMainInfoTitle>{t('results.duration')}</StyledMainInfoTitle>
      </StyledMainInfoItem>
      <StyledMainInfoItem>
        <StyledMainInfoText>
          {dayjs(match.createdAt).format('YYYY-MM-DD HH:mm')}
        </StyledMainInfoText>
        <StyledMainInfoTitle>{t('results.date')}</StyledMainInfoTitle>
      </StyledMainInfoItem>
    </StyledMainInfo>
  )
}

const StyledMainInfo = styled.div`
  position: relative;
  display: flex;
  gap: 64px;
`

const StyledMainInfoItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  column-gap: 9px;
`

const StyledMainInfoTitle = styled(Text4)`
  color: rgba(255, 255, 255, 0.3);
  text-align: center;
`

const StyledMainInfoText = styled(Header5)`
  text-align: center;
  text-transform: uppercase;
`

const StyledMapWrapper = styled.div`
  display: flex;
  gap: 32px;
  align-items: center;
`

const StyledMapIcon = styled.img`
  width: 46px;
  height: 46px;
  border-radius: 50%;
`

export default MatchInfoHeader
