import { FriendProfile } from 'stores/friendsStore'
import { Header5, Header6, Text5 } from 'styles/typography'
import { Participants, inviteToParty } from 'stores/partyStore'
import { Profile } from 'stores/userStore'
import { useDrag } from 'react-dnd'
import AvatarWithRang from 'components/AvatarWithRang'
import NickTrimmer from 'components/NickTrimmer'
import PowerCSIcon from 'images/PowerCSIcon'
import WithUserMenu from 'components/ContextMenu/WithUserMenu'
import styled from 'styled-components'

interface StyledWrapperProps {
  $isFull?: boolean
  $isDragging?: boolean
}

const StyledWrapper = styled.div<StyledWrapperProps>`
  display: flex;
  justify-content: ${({ $isFull }) => ($isFull ? 'start' : 'center')};
  width: ${({ $isFull }) => ($isFull ? '200px' : '100%')};
  opacity: ${({ $isDragging }) => ($isDragging ? '0.4' : '1')};
  gap: 10px;
  align-items: center;

  transition: 0.3s;
`

const StyledInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const StyledNickname = styled(Header5)`
  text-overflow: ellipsis;
  width: 130px;
  overflow: hidden;
`

const StyledMMR = styled(Header6)`
  display: flex;
  align-items: center;
  gap: 5px;
`

interface StyledStatusProps {
  $status?: string
}

export const getColorByStatus = (status: string) => {
  switch (status) {
    case 'Offline':
      return '#7f7f7f'
    case 'Online':
    case 'In lobby':
      return '#2cff80'
    default:
      return '#ffcd29'
  }
}

const StyledStatus = styled(Text5)<StyledStatusProps>`
  white-space: nowrap;
  color: ${({ $status }) => {
    return getColorByStatus($status || '')
  }};
`

const getStatus = (profile: FriendProfile | Profile | Participants) => {
  if ((profile as Profile) && profile.matchId && profile.online) {
    return `In match: ${profile.gameName}`
  }
  if ((profile as Profile) && profile.lobbyId && profile.online) {
    return 'In lobby'
  }
  return profile.online ? 'Online' : 'Offline'
}

interface UserProfileIconProps {
  size?: 'm' | 's'
  isFull?: boolean
  status?: boolean
  nickName?: string
  hasMenu?: boolean
  isDraggable?: boolean
  mmr?: number | string
  isHost?: boolean | null
  profile: FriendProfile | Profile | Participants
}

interface DropResult {
  name: string
}

const UserProfile: React.FC<UserProfileIconProps> = ({
  mmr,
  size,
  status,
  isHost,
  isFull,
  profile,
  nickName,
  isDraggable,
}) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'Friend',
    item: { id: profile.id },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId(),
    }),
    end: async (item, monitor) => {
      const dropResult = monitor.getDropResult<DropResult>()
      if (item && dropResult) {
        await inviteToParty(item.id || '')
      }
    },
  }))

  const statusText = getStatus(profile)

  return (
    <StyledWrapper
      $isFull={isFull}
      $isDragging={isDragging}
      ref={isDraggable ? drag : null}
    >
      <WithUserMenu profile={profile}>
        <AvatarWithRang
          size={size}
          isHost={isHost}
          avatar={profile.imgUrl}
          status={status ? statusText : undefined}
        />
      </WithUserMenu>

      {isFull && (
        <StyledInfo>
          {mmr && (
            <StyledMMR>
              <PowerCSIcon /> {mmr}
            </StyledMMR>
          )}
          <StyledNickname title={nickName}>
            <NickTrimmer>{nickName}</NickTrimmer>
          </StyledNickname>
          {status && (
            <StyledStatus $status={statusText}>{statusText}</StyledStatus>
          )}
        </StyledInfo>
      )}
    </StyledWrapper>
  )
}

export default UserProfile
