import {
  $matchesHistory,
  $matchesHistorySearch,
  $userStatisticPageGameFormat,
  getSearcedMatches,
} from 'stores/statisticStore'
import { $userProfile } from 'stores/userStore'
import { Header2 } from 'styles/typography'
import { openResultMatchModal } from 'stores/appStore'
import { styled } from 'styled-components'
import { useState } from 'react'
import { useStore } from '@nanostores/react'
import { useTranslation } from 'react-i18next'
import Avatar from 'images/Avatar_test.png'
import CSGame from 'images/RankGameCS.svg'
import Coin from 'images/Coin'
import CopyWrapper from 'components/CopyWrapper'
import Input from 'components/Input'
import Search from 'images/Search'
import WithUserMenu from 'components/ContextMenu/WithUserMenu'
import dayjs from 'dayjs'

const StyledRoot = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 50px;
`
const StyledInput = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  width: 100%;
  padding: 3px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
`
const StyledGridLegend = styled.div`
  display: grid;
  grid-template-columns: 85px 73px 96px 1fr 80px 1fr 180px;
  margin: 42px 0 28px;
  align-items: center;
`
const StyledLegendText = styled.div`
  font-family: Ruberoid;
  font-size: 14px;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  color: white;
  opacity: 0.3;
`
const StyledLegendTextCenter = styled(StyledLegendText)`
  display: flex;
  justify-content: center;
`
const StyledTeamName = styled.div`
  display: flex;
  font-family: 'Nebula', 'Nebulosa';
  font-size: 20px;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
`
const StyledTeamNameRed = styled(StyledTeamName)`
  justify-content: end;
  color: #f44;
`
const StyledTeamNameBlue = styled(StyledTeamName)`
  justify-content: start;
  color: #0fa9ff;
`
const StyledTeamWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7px;
  padding-bottom: 100px;
`
const StyledMatchItem = styled.div`
  display: grid;
  grid-template-columns: 85px 73px 96px 1fr 80px 1fr 180px;
  align-items: center;
  width: 100%;
  backdrop-filter: blur(7.5px);
  border: 1px solid rgba(255, 255, 255, 0.15);
  height: 65px;
  background-color: rgba(255, 255, 255, 0.03);
  transition: 0.3s ease-in-out;
  overflow: hidden;

  cursor: pointer;

  &:hover {
    background-color: rgba(255, 255, 255, 0.15);
  }
`
const StyledGame = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  background-image: url('${CSGame}');
  background-repeat: no-repeat;
  background-position: center;
`
const StyledId = styled.div`
  display: flex;
  justify-content: center;
  font-family: Ruberoid;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  color: white;
`
const StyledTeam = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  height: 100%;
`
const StyledTeamLeft = styled(StyledTeam)`
  justify-content: flex-end;
`
const StyledTeamRight = styled(StyledTeam)`
  justify-content: flex-start;
`
const StyledResultText = styled.div`
  font-family: Ruberoid;
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
`
const StyledResultTextWin = styled(StyledResultText)`
  color: #2cff80;
`
const StyledResultTextLose = styled(StyledResultText)`
  color: #414141;
`
const StyledTime = styled.div`
  font-family: Ruberoid;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  color: white;
`
interface Winner {
  $winner: boolean
}
const StyledAvatars = styled.div<Winner>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 100%;
  gap: 2px;

  &:after {
    display: ${({ $winner }) => `${$winner ? 'block' : 'none'}`};
    content: '';
    position: absolute;
    bottom: -5px;
    width: 196px;
    height: 100%;
    background: radial-gradient(
      51.1% 73.47% at 50% 119.39%,
      #00ff85 0%,
      #0f6 8.85%,
      rgba(0, 255, 178, 0.34) 49.48%,
      rgba(0, 148, 255, 0) 100%
    );
    z-index: -1;
  }
`

interface ItsYou {
  $text?: string
  $avatar?: string
  $isCurrentUser?: boolean
}
const StyledAvatar = styled.div<ItsYou>`
  display: flex;
  justify-content: center;
  width: 30px;
  height: 30px;
  background-image: url('${({ $avatar }) => ($avatar ? $avatar : Avatar)}');
  background-size: contain;
  cursor: pointer;
  position: relative;

  &:after {
    display: ${({ $isCurrentUser }) => `${$isCurrentUser ? 'flex' : 'none'}`};
    align-items: center;
    justify-content: center;
    content: '${(props) => props.$text}';

    color: #000;
    font-family: Ruberoid;
    font-size: 8px;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    width: 24px;
    height: 9px;
    background-color: #f2b927;
    position: absolute;
    bottom: -9px;
  }

  &:before {
    display: ${({ $isCurrentUser }) => `${$isCurrentUser ? 'block' : 'none'}`};
    content: '';
    background-color: #f2b927;
    width: 5px;
    height: 5px;
    transform: rotate(45deg);
    position: absolute;
    bottom: -2.5px;
  }
`
const StyledCountResult = styled.div<Winner>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 30px;
  border-radius: 3px;
  background-color: ${({ $winner }) => `${$winner ? '#2cff80' : '#414141'}`};
  color: ${({ $winner }) => `${$winner ? 'black' : 'white'}`};
  font-family: Ruberoid;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
`
const StyledReward = styled.div`
  display: flex;
  align-items: center;
  gap: 11px;
  font-family: Ruberoid;
  font-size: 26px;
  font-weight: 400;
  line-height: normal;
  color: white;
`
const StyledCoin = styled(Coin)`
  fill: #ffcd29;
  height: 24px;
  width: 24px;
`
const StyledNoResults = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 54px 0 150px 0;
  gap: 16px;
`
const StyledNoResultsCaption = styled(Header2)`
  color: white;
  text-transform: uppercase;
`
const StyledNoResultsDescription = styled.div`
  font-family: Montserrat;
  font-size: 26px;
  font-weight: 400;
  color: white;
  opacity: 0.4;
`

const getInterval = (start: string, end: string) => {
  const min = (dayjs(end).diff(dayjs(start)) / 1000 / 60).toFixed(0)
  const sec = ((dayjs(end).diff(dayjs(start)) / 1000) % 60).toFixed(0)
  return `${Number(min) < 10 ? 0 : ''}${min}:${Number(sec) < 10 ? 0 : ''}${sec}`
}

const MatchStatistics: React.FC = () => {
  const { t } = useTranslation()

  const [searchValue, setSearchValue] = useState<string | null>(null)

  const userStatisticPageGameFormat = useStore($userStatisticPageGameFormat)

  const matches =
    searchValue?.length === 6
      ? useStore($matchesHistorySearch)
      : useStore($matchesHistory)
  const currentUser = useStore($userProfile)
  const gameFormat = useStore($userStatisticPageGameFormat)

  const handleMatchClick = (id: string) => () => {
    openResultMatchModal(id)
    // navigate(routes.matchInfo(id))
  }

  const handleSearchChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    setSearchValue(value)
    if (value.length === 6)
      await getSearcedMatches(userStatisticPageGameFormat, value)
  }

  return (
    <StyledRoot>
      <StyledInput>
        <Search />
        <Input
          hideBorder
          onChange={handleSearchChange}
          placeholder={t('play.statistic.searchInput')}
        />
      </StyledInput>
      <StyledGridLegend>
        <StyledLegendTextCenter>
          {t('play.statistic.date')}
        </StyledLegendTextCenter>
        <StyledLegendTextCenter>
          {t('play.statistic.game')}
        </StyledLegendTextCenter>
        <StyledLegendTextCenter>
          {t('play.statistic.matchId')}
        </StyledLegendTextCenter>
        <StyledTeamNameRed>{t('play.statistic.red')}</StyledTeamNameRed>
        <div></div>
        <StyledTeamNameBlue>{t('play.statistic.blue')}</StyledTeamNameBlue>
        <StyledLegendText>{t('play.statistic.reward')}</StyledLegendText>
      </StyledGridLegend>
      <StyledTeamWrapper>
        {matches
          ?.filter((match) => match.gameFormat === gameFormat)
          ?.map((match) => {
            const gameWinner = match.CSMatchResult?.isDraw
              ? ''
              : match.CSMatchResult?.winTeamColor

            const redScore =
              gameWinner === 'RED'
                ? match.CSMatchResult?.winTeamScore
                : match.CSMatchResult?.loseTeamScore
            const blueScore =
              gameWinner === 'BLUE'
                ? match.CSMatchResult?.winTeamScore
                : match.CSMatchResult?.loseTeamScore

            return (
              <StyledMatchItem
                key={match.createdAt}
                onClick={handleMatchClick(match.id || '')}
              >
                <StyledLegendTextCenter>
                  {dayjs(match.createdAt).format('DD.MM.YY')}
                </StyledLegendTextCenter>
                <StyledGame></StyledGame>
                <CopyWrapper textToCopy={match.externalId || ''}>
                  <StyledId># {match.externalId || ''}</StyledId>
                </CopyWrapper>
                <StyledTeamLeft>
                  {gameWinner ? (
                    gameWinner === 'RED' ? (
                      <StyledResultTextWin>
                        {t('play.statistic.win')}
                      </StyledResultTextWin>
                    ) : (
                      <StyledResultTextLose>
                        {t('play.statistic.lose')}
                      </StyledResultTextLose>
                    )
                  ) : (
                    <StyledResultTextLose>
                      {t('play.statistic.draw')}
                    </StyledResultTextLose>
                  )}
                  <StyledAvatars $winner={gameWinner === 'RED'}>
                    {match.participants
                      ?.filter((participant) => participant.teamColor === 'RED')
                      .map((participant) => (
                        <WithUserMenu
                          key={participant.user?.id}
                          profile={participant.user}
                        >
                          <StyledAvatar
                            $avatar={participant.user?.imgUrl}
                            $isCurrentUser={
                              participant.user?.id === currentUser?.id
                            }
                            $text={
                              participant.user?.id === currentUser?.id
                                ? t('play.statistic.you')
                                : undefined
                            }
                          />
                        </WithUserMenu>
                      ))}
                  </StyledAvatars>
                  <StyledCountResult $winner={gameWinner === 'RED'}>
                    {redScore}
                  </StyledCountResult>
                </StyledTeamLeft>
                <StyledTime>
                  {getInterval(match?.startAt || '', match?.finishAt || '')}
                </StyledTime>
                <StyledTeamRight>
                  <StyledCountResult $winner={gameWinner === 'BLUE'}>
                    {blueScore}
                  </StyledCountResult>
                  <StyledAvatars $winner={gameWinner === 'BLUE'}>
                    {match.participants
                      ?.filter(
                        (participant) => participant.teamColor === 'BLUE'
                      )
                      .map((participant) => (
                        <WithUserMenu
                          key={participant.user?.id}
                          profile={participant.user}
                        >
                          <StyledAvatar
                            $avatar={participant.user?.imgUrl}
                            $isCurrentUser={
                              participant.user?.id === currentUser?.id
                            }
                            $text={
                              participant.user?.id === currentUser?.id
                                ? t('play.statistic.you')
                                : undefined
                            }
                          />
                        </WithUserMenu>
                      ))}
                  </StyledAvatars>
                  {gameWinner ? (
                    gameWinner === 'BLUE' ? (
                      <StyledResultTextWin>
                        {t('play.statistic.win')}
                      </StyledResultTextWin>
                    ) : (
                      <StyledResultTextLose>
                        {t('play.statistic.lose')}
                      </StyledResultTextLose>
                    )
                  ) : (
                    <StyledResultTextLose>
                      {t('play.statistic.draw')}
                    </StyledResultTextLose>
                  )}
                </StyledTeamRight>
                <StyledReward>
                  <StyledCoin></StyledCoin>
                  {match.bet}
                </StyledReward>
              </StyledMatchItem>
            )
          })}
        {!matches?.length && (
          <StyledNoResults>
            <StyledNoResultsCaption>
              {t('play.statistic.noResults')}
            </StyledNoResultsCaption>
            <StyledNoResultsDescription>
              {t('play.statistic.tryAgain')}
            </StyledNoResultsDescription>
          </StyledNoResults>
        )}
      </StyledTeamWrapper>
    </StyledRoot>
  )
}

export default MatchStatistics
