import { GameChoosen } from 'stores/statisticStore'
import { Header2, Header3, Text3, Text4 } from 'styles/typography'
import { useTranslation } from 'react-i18next'
import CSLogo from 'images/CSLogo.png'
import DotaLogo from 'images/DotaLogo.png'
import GameStatsTexture from 'images/GameStatsTexture.svg'
import styled from 'styled-components'

const StyledWrapper = styled.div`
  border-radius: 6px;
  height: 300px;
  width: 530px;
  background: radial-gradient(
    170.33% 173.33% at -20.57% 215.67%,
    #ffcd29 0%,
    rgba(255, 144, 41, 0.43) 46.35%,
    rgba(36, 0, 0, 0) 100%
  );
`
const StyledBack = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 6.122px;
  border: 0.765px solid rgba(255, 255, 255, 0.15);
  background-color: rgba(255, 255, 255, 0.03);
  background-image: url('${GameStatsTexture}');
  height: 100%;
`
const StyledTopWrapper = styled.div`
  display: flex;
`
const StyledGame = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  padding: 16px 20px;
  border-bottom-right-radius: 23px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  border-right: 1px solid rgba(255, 255, 255, 0.1);
`
const StyledGameImageWrapper = styled.div`
  width: 65px;
  height: 65px;
  border-radius: 3px;
  background: rgba(255, 255, 255, 0.15);
  padding: 4px;
`
interface GameName {
  $gameName: GameChoosen
}

const StyledGameImage = styled.div<GameName>`
  background-image: ${({ $gameName }) =>
    $gameName === 'CS' ? `url(${CSLogo})` : `url(${DotaLogo})`};
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
`

const StyledGameName = styled(Header3)`
  line-height: 115%;
  text-wrap: nowrap;
`
const StyledTopStats = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
`
const StyledTopStatItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  text-align: center;
`
const StyledTopStatItemValue = styled(Text3)`
  color: white;
  line-height: 100%;
`
const StyledTopStatItemDescr = styled(Text4)`
  color: white;
  opacity: 0.3;
  line-height: 125%;
`
const StyledMiddleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 18px;
  margin-top: 30px;
`
const StyledMiddleItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  text-align: center;
`
const StyledMiddleItemValue = styled(Header2)`
  color: #2cff80;
  font-weight: 400;
  line-height: 90%;
`
const StyledMiddleItemDescr = styled(Text3)`
  line-height: 100%;
  color: white;
  opacity: 0.3;
`
interface IGameStats {
  gameName: GameChoosen
  page: 'popup' | 'statistic'
  hoursInMode?: string | number
  gamesInTotal?: string | number
  hoursInTotal?: string | number
  matchesInMode?: string | number
}
const GameStats: React.FC<IGameStats> = ({
  gameName,
  hoursInMode,
  hoursInTotal,
  gamesInTotal,
  matchesInMode,
}) => {
  const { t } = useTranslation()

  return (
    <StyledWrapper>
      <StyledBack>
        <StyledTopWrapper>
          <StyledGame>
            <StyledGameImageWrapper>
              <StyledGameImage $gameName={gameName} />
            </StyledGameImageWrapper>
            <StyledGameName>
              {gameName === 'CS' ? 'CS 2' : 'DOTA 2'}
            </StyledGameName>
          </StyledGame>
          <StyledTopStats>
            <StyledTopStatItem>
              <StyledTopStatItemValue>
                {gamesInTotal || 0}
              </StyledTopStatItemValue>
              <StyledTopStatItemDescr>
                {t('UserAccountPopup.totalGames')}
              </StyledTopStatItemDescr>
            </StyledTopStatItem>
            <StyledTopStatItem>
              <StyledTopStatItemValue>
                {hoursInTotal || 0}
              </StyledTopStatItemValue>
              <StyledTopStatItemDescr>
                {t('UserAccountPopup.totalHours')}
              </StyledTopStatItemDescr>
            </StyledTopStatItem>
          </StyledTopStats>
        </StyledTopWrapper>
        <StyledMiddleWrapper>
          <StyledMiddleItem>
            <StyledMiddleItemValue>{matchesInMode || 0}</StyledMiddleItemValue>
            <StyledMiddleItemDescr>
              {t('UserAccountPopup.matchesInMode')}
            </StyledMiddleItemDescr>
          </StyledMiddleItem>
          <StyledMiddleItem>
            <StyledMiddleItemValue>{hoursInMode || 0}</StyledMiddleItemValue>
            <StyledMiddleItemDescr>
              {t('UserAccountPopup.hoursInMode')}
            </StyledMiddleItemDescr>
          </StyledMiddleItem>
        </StyledMiddleWrapper>
      </StyledBack>
    </StyledWrapper>
  )
}

export default GameStats
