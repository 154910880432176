import styled from 'styled-components'

import { Header8 } from 'styles/typography'

const RequiredFieldIndicator = () => <StyledWrapper>*</StyledWrapper>

const StyledWrapper = styled(Header8)`
  color: #f44;
  font-weight: bold;
`

export default RequiredFieldIndicator
