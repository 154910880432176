const AddFriendIcon = ({ className }: { className?: string }) => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      fillRule="evenodd"
      d="M13.8745 6.44994C13.8745 8.62441 12.1117 10.3872 9.93724 10.3872C7.76276 10.3872 6 8.62441 6 6.44994C6 4.27546 7.76276 2.5127 9.93724 2.5127C12.1117 2.5127 13.8745 4.27546 13.8745 6.44994ZM13.1637 13.1582C11.5774 14.3323 10.549 16.2172 10.549 18.3423C10.549 18.8646 10.6111 19.3725 10.7284 19.8588H9.93701H2.5C2.5 17.8864 3.28354 15.9947 4.67825 14.6C6.07296 13.2053 7.96459 12.4218 9.93701 12.4218C11.0649 12.4218 12.1664 12.678 13.1637 13.1582Z"
    />
    <path
      clipRule="evenodd"
      fillRule="evenodd"
      d="M18.8789 13.5127H16.8789V17.0127L13.3789 17.0127V19.0127H16.8789V22.5127H18.8789V19.0127H22.3789V17.0127L18.8789 17.0127V13.5127Z"
    />
  </svg>
)

export default AddFriendIcon
