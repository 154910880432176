import { $appealMatchesHistory } from 'stores/statisticStore'
import {
  $createdAppeal,
  $createdAppealDraft,
  $getMatchPlayers,
  AppealStage,
  AppealStageTypes,
  deleteFiles,
} from 'stores/chatStores/appealStore'
import {
  EnumClaimReason,
  EnumClaimReportCategory,
  EnumCreateClaimStatus,
} from 'api/schemas/supportApi'
import { Header3, Text4 } from 'styles/typography'
import { useEffect } from 'react'
import { useStore } from '@nanostores/react'
import { useTranslation } from 'react-i18next'
import AddFiles from 'components/Chat/Support/supportComponents/AddFiles'
import BugServiceIcon from 'images/chat/BugServiceIcon'
import ChooseBlock from 'components/Chat/Support/supportComponents/ChooseBlock'
import ChooseButton from 'components/Chat/Support/supportComponents/ChooseButton'
import ChooseChekboxes from 'components/Chat/Support/supportComponents/ChooseChekboxes'
import DescriptionBlock from 'components/Chat/Support/supportComponents/DescriptionBlock'
import GeneratedAppeal from 'components/Chat/Support/supportComponents/GeneratedAppeal'
import Loader from 'images/chat/Loader'
import PlayerReportIcon from 'images/chat/PlayerReportIcon'
import ServerIcon from 'images/chat/ServerIcon'
import StepsButtons from 'components/Chat/Support/supportComponents/StepsButtons'
import TimeCodeBlock from 'components/Chat/Support/supportComponents/TimeCodeBlock'
import styled, { css } from 'styled-components'

const SupportStageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: end;
  position: relative;
  width: 890px;
  height: -webkit-fill-available;
  gap: 13px;
  padding: 13px 13px;
  border-radius: 3px;
  border: 1px solid var(--BaseButtonOutline, rgba(255, 255, 255, 0.15));
`
const StyledTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 22px;
`
const ScrollBlock = styled.div`
  width: 100%;
  height: auto;
  overflow-y: scroll;
  padding-right: 2px;

  &::-webkit-scrollbar {
    width: 2px;
  }

  &::-webkit-scrollbar-track {
    margin: 4px 0 4px;
    border-radius: 1px;
    opacity: 0.1;
    background: rgba(217, 217, 217, 0.1);
  }

  &::-webkit-scrollbar-thumb {
    background-color: white;
    border-radius: 1px;
  }
`
const StyledCaption = styled(Header3)`
  color: #2cff80;
  line-height: 115%;
  text-transform: uppercase;
`
const StyledDescription = styled(Text4)`
  margin-top: 6px;
  line-height: 115.5%;
  color: rgba(255, 255, 255, 0.6);
`
const StyledLoader = styled.div`
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const StyledStepDescription = styled(Text4)`
  display: flex;
  gap: 6px;
`

const StyledStepNumber = styled.div`
  color: var(--Green, #2cff80);
`

type ButtonWrapperPosition = 'grid' | 'row' | 'column'
interface StyledChooseButtonWrapperProps {
  $buttonPosition: ButtonWrapperPosition
}
const getButtonWrapperCSS = ($buttonPosition: ButtonWrapperPosition) => {
  switch ($buttonPosition) {
    case 'grid':
      return 'display: grid; grid-template-columns: 1fr 1fr 1fr; column-gap: 7px; row-gap: 7px;'
    case 'column':
      return 'display: flex; flex-direction: column; gap: 7px;'
    case 'row':
      return 'display: flex; justify-content: space-between; flex-direction: row; gap: 7px;'
    default:
      return 'display: flex; justify-content: space-between; flex-direction: row;'
  }
}
const StyledChooseButtonWrapper = styled.div<StyledChooseButtonWrapperProps>`
  width: 100%;
  ${({ $buttonPosition }) => css`
    ${getButtonWrapperCSS($buttonPosition)}
  `}
`

const SupportStage: React.FC = () => {
  const matchesHistory = useStore($appealMatchesHistory)
  const getMatchPlayers = useStore($getMatchPlayers)
  const createdAppeal = useStore($createdAppeal)
  const createdAppealDraft = useStore($createdAppealDraft)
  const { t } = useTranslation()

  const handleSelectedCategory =
    ({
      category,
      nextStep,
    }: {
      nextStep: AppealStage
      category: EnumClaimReportCategory
    }) =>
    () => {
      $createdAppeal.set({ ...createdAppeal, nextStep, category })
    }

  const handleSelectedGame =
    ({ game, nextStep }: { game: string; nextStep: AppealStage }) =>
    () => {
      $createdAppeal.set({ ...createdAppeal, game, nextStep })
    }

  const handleSelectedMatch =
    ({
      nextStep,
      reportedMatchId,
    }: {
      nextStep: AppealStage
      reportedMatchId: string
    }) =>
    () => {
      $createdAppeal.set({ ...createdAppeal, nextStep, reportedMatchId })
    }

  const handleSelectedUser =
    ({
      nextStep,
      reportedUserId,
    }: {
      nextStep: AppealStage
      reportedUserId: string
    }) =>
    () => {
      $createdAppeal.set({
        ...createdAppeal,
        nextStep,
        reportedUserId,
      })
    }

  const handleCheckboxesChange = () => {
    if (
      $createdAppeal.get().reason?.filter((reason) => reason.isActive)?.length
    ) {
      $createdAppeal.set({
        ...$createdAppeal.get(),
        nextStep: AppealStageTypes.ADD_FILES,
      })
      return
    }
    $createdAppeal.set({ ...$createdAppeal.get(), nextStep: null })
  }

  useEffect(() => {
    if (
      createdAppealDraft?.createStatus ===
        EnumCreateClaimStatus.CreateClaimStatusFileUploading &&
      createdAppeal.currentStep !== AppealStageTypes.APPEAL_GENERATED &&
      createdAppeal.currentStep !== AppealStageTypes.ADD_FILES
    ) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      deleteFiles()
    }
  }, [createdAppeal.currentStep, createdAppealDraft])

  const getContent = () => {
    switch (createdAppeal.currentStep) {
      case AppealStageTypes.CHOOSE_CATEGORY:
        return (
          <>
            <StyledTextWrapper>
              <StyledCaption>{t('chat.appeal.Hi')}</StyledCaption>
              <StyledDescription>
                {t('chat.appeal.followSteps')}
              </StyledDescription>
            </StyledTextWrapper>
            <StyledStepDescription>
              <StyledStepNumber>
                {t('chat.appeal.steps', {
                  start: 1,
                  end:
                    createdAppeal.category !==
                    EnumClaimReportCategory.ClaimReportCategoryServiceReport
                      ? 6
                      : 3,
                })}
              </StyledStepNumber>
              <span>{t('chat.appeal.chooseCategory')}</span>
            </StyledStepDescription>
            <ScrollBlock>
              <ChooseBlock caption={t('chat.appeal.chooseCategory')}>
                <StyledChooseButtonWrapper $buttonPosition="row">
                  <ChooseButton
                    id="service"
                    type="button"
                    text={t('chat.appeal.serviceReport')}
                    icon={<BugServiceIcon />}
                    selected={
                      createdAppeal.category ===
                      EnumClaimReportCategory.ClaimReportCategoryServiceReport
                    }
                    onClick={handleSelectedCategory({
                      nextStep: AppealStageTypes.CHOOSE_SERVICE_REASON,
                      category:
                        EnumClaimReportCategory.ClaimReportCategoryServiceReport,
                    })}
                  />
                  <ChooseButton
                    id="player"
                    type="button"
                    icon={<PlayerReportIcon />}
                    text={t('chat.appeal.playerReport')}
                    selected={
                      createdAppeal.category ===
                      EnumClaimReportCategory.ClaimReportCategoryPlayerReport
                    }
                    onClick={handleSelectedCategory({
                      nextStep: AppealStageTypes.CHOOSE_GAME,
                      category:
                        EnumClaimReportCategory.ClaimReportCategoryPlayerReport,
                    })}
                  />
                  <ChooseButton
                    id="match"
                    type="button"
                    text={t('chat.appeal.matchReport')}
                    icon={<ServerIcon />}
                    selected={
                      createdAppeal.category ===
                      EnumClaimReportCategory.ClaimReportCategoryMatchReport
                    }
                    onClick={handleSelectedCategory({
                      nextStep: AppealStageTypes.CHOOSE_GAME,
                      category:
                        EnumClaimReportCategory.ClaimReportCategoryMatchReport,
                    })}
                  />
                </StyledChooseButtonWrapper>
              </ChooseBlock>
            </ScrollBlock>
          </>
        )
      case AppealStageTypes.CHOOSE_SERVICE_REASON:
        return (
          <>
            <StyledStepDescription>
              <StyledStepNumber>
                {t('chat.appeal.steps', { end: 6, start: 2 })}
              </StyledStepNumber>
              <span>{t('chat.appeal.chooseReasons')}</span>
            </StyledStepDescription>

            <ScrollBlock>
              <ChooseBlock caption={t('chat.appeal.chooseReasons')}>
                <ChooseChekboxes
                  onChange={handleCheckboxesChange}
                  items={[
                    {
                      type: 'checkbox-input',
                      name: `${t('chat.appeal.networkDelay')}`,
                      value: EnumClaimReason.ClaimReasonNetworkDelay,
                    },
                    {
                      type: 'checkbox-input',
                      name: `${t('chat.appeal.gameCrushed')}`,
                      value: EnumClaimReason.ClaimReasonGameCrushed,
                    },
                    {
                      type: 'checkbox-input',
                      name: `${t('chat.appeal.loveringFPS')}`,
                      value: EnumClaimReason.ClaimReasonLoweringFPS,
                      description: `${t('chat.appeal.framesReduced')}`,
                    },
                  ]}
                />
              </ChooseBlock>
            </ScrollBlock>
          </>
        )
      case AppealStageTypes.CHOOSE_GAME:
        return (
          <>
            <StyledStepDescription>
              <StyledStepNumber>
                {t('chat.appeal.steps', { end: 6, start: 2 })}
              </StyledStepNumber>
              <span>{t('chat.appeal.chooseGame')}</span>
            </StyledStepDescription>
            <ScrollBlock>
              <ChooseBlock caption={t('chat.appeal.chooseGame')}>
                <StyledChooseButtonWrapper $buttonPosition="column">
                  <ChooseButton
                    id="cs"
                    text="CS 2"
                    type="button"
                    selected={createdAppeal.game === 'CS 2'}
                    onClick={handleSelectedGame({
                      game: 'CS 2',
                      nextStep: AppealStageTypes.CHOOSE_MATCH,
                    })}
                  />
                  <ChooseButton
                    id="dota"
                    type="button"
                    text="dota 2"
                    selected={createdAppeal.game === 'dota 2'}
                    onClick={handleSelectedGame({
                      game: 'dota 2',
                      nextStep: AppealStageTypes.CHOOSE_MATCH,
                    })}
                  />
                </StyledChooseButtonWrapper>
              </ChooseBlock>
            </ScrollBlock>
          </>
        )
      case AppealStageTypes.CHOOSE_MATCH: {
        const matches = matchesHistory
          ? matchesHistory?.map((item, i) => {
              return (
                <ChooseButton
                  type="match"
                  match={item}
                  id={item.id || ''}
                  key={item.id || i}
                  time={item.createdAt}
                  text={item.externalId || ''}
                  selected={createdAppeal.reportedMatchId === item.id}
                  onClick={handleSelectedMatch({
                    reportedMatchId: item.id || '',
                    nextStep:
                      createdAppeal.category ===
                      EnumClaimReportCategory.ClaimReportCategoryMatchReport
                        ? AppealStageTypes.CHOOSE_MATCH_REASON
                        : AppealStageTypes.CHOOSE_PLAYER,
                  })}
                />
              )
            })
          : ''
        return (
          <>
            <StyledStepDescription>
              <StyledStepNumber>
                {t('chat.appeal.steps', { end: 6, start: 3 })}
              </StyledStepNumber>
              <span>{t('chat.appeal.chooseMatchID')}</span>
            </StyledStepDescription>
            <ScrollBlock>
              <ChooseBlock caption={t('chat.appeal.chooseMatchID')}>
                {matches ? (
                  <StyledChooseButtonWrapper $buttonPosition="column">
                    {matches}
                  </StyledChooseButtonWrapper>
                ) : (
                  <StyledLoader>
                    <Loader />
                  </StyledLoader>
                )}
              </ChooseBlock>
            </ScrollBlock>
          </>
        )
      }

      case AppealStageTypes.CHOOSE_PLAYER: {
        const players = getMatchPlayers
          ? getMatchPlayers.map((player, i) => {
              return (
                <ChooseButton
                  type="person"
                  id={player.user?.id || ''}
                  key={player.user?.id || i}
                  text={player.user?.name || ''}
                  imgUrl={player.user?.imgUrl || ''}
                  selected={createdAppeal.reportedUserId === player.user?.id}
                  onClick={handleSelectedUser({
                    reportedUserId: player.user?.id || '',
                    nextStep: AppealStageTypes.CHOOSE_PLAYER_REASON,
                  })}
                />
              )
            })
          : ''
        return (
          <>
            <StyledStepDescription>
              <StyledStepNumber>
                {t('chat.appeal.steps', { end: 6, start: 4 })}
              </StyledStepNumber>
              <span>{t('chat.appeal.choosePlayer')}</span>
            </StyledStepDescription>
            <ScrollBlock>
              <ChooseBlock>
                {players ? (
                  <StyledChooseButtonWrapper $buttonPosition="grid">
                    {players}
                  </StyledChooseButtonWrapper>
                ) : (
                  <StyledLoader>
                    <Loader />
                  </StyledLoader>
                )}
              </ChooseBlock>
            </ScrollBlock>
          </>
        )
      }

      case AppealStageTypes.CHOOSE_PLAYER_REASON:
        return (
          <>
            <StyledStepDescription>
              <StyledStepNumber>
                {t('chat.appeal.steps', { end: 6, start: 5 })}
              </StyledStepNumber>
              <span>{t('chat.appeal.chooseReasons')}</span>
            </StyledStepDescription>
            <ScrollBlock>
              <ChooseBlock caption={t('chat.appeal.chooseReasons')}>
                <ChooseChekboxes
                  onChange={handleCheckboxesChange}
                  items={[
                    {
                      type: 'checkbox-input',
                      name: `${t('chat.appeal.cheating')}`,
                      value: EnumClaimReason.ClaimReasonCheating,
                      description: `${t('chat.appeal.cheatingDescr')}`,
                    },
                    {
                      type: 'checkbox-input',
                      name: `${t('chat.appeal.griefing')}`,
                      value: EnumClaimReason.ClaimReasonGriefing,
                      description: `${t('chat.appeal.griefingDescr')}`,
                    },
                    {
                      type: 'checkbox-input',
                      name: `${t('chat.appeal.unsportsmanlikeBehavior')}`,
                      value: EnumClaimReason.ClaimReasonUnsportsmanlikeBehavior,
                      description: `${t('chat.appeal.unsportsmanlikeBehaviorDescr')}`,
                    },
                  ]}
                />
              </ChooseBlock>
            </ScrollBlock>
          </>
        )
      case AppealStageTypes.CHOOSE_MATCH_REASON:
        return (
          <>
            <StyledStepDescription>
              <StyledStepNumber>
                {t('chat.appeal.steps', { end: 3, start: 2 })}
              </StyledStepNumber>
              <span>{t('chat.appeal.chooseReasons')}</span>
            </StyledStepDescription>
            <ScrollBlock>
              {/* <ChooseBlocks> */}
              <ChooseBlock caption={t('chat.appeal.chooseReasons')}>
                <ChooseChekboxes
                  onChange={handleCheckboxesChange}
                  items={[
                    {
                      type: 'checkbox-input',
                      name: `${t('chat.appeal.cheating')}`,
                      value: EnumClaimReason.ClaimReasonCheating,
                      inputLabel: `${t('chat.appeal.cheatingDescr')}`,
                    },
                    {
                      name: 'DDoS',
                      type: 'checkbox-input',
                      value: EnumClaimReason.ClaimReasonNetworkDelay,
                    },
                    {
                      type: 'checkbox-input',
                      name: `${t('chat.appeal.griefing')}`,
                      value: EnumClaimReason.ClaimReasonGriefing,
                    },
                  ]}
                />
              </ChooseBlock>
              {/* </ChooseBlocks> */}
            </ScrollBlock>
          </>
        )
      case AppealStageTypes.ADD_FILES:
        return (
          <>
            <StyledStepDescription>
              <StyledStepNumber>
                {' '}
                {t('chat.appeal.steps', {
                  end:
                    createdAppeal.category !==
                    EnumClaimReportCategory.ClaimReportCategoryServiceReport
                      ? 6
                      : 3,
                  start:
                    createdAppeal.category !==
                    EnumClaimReportCategory.ClaimReportCategoryServiceReport
                      ? 6
                      : 3,
                })}
              </StyledStepNumber>
              <span>{t('chat.appeal.moreInfo')}</span>
            </StyledStepDescription>
            <ScrollBlock>
              {/* <ChooseBlocks> */}
              <ChooseBlock caption={t('chat.appeal.addPictures')}>
                {createdAppeal.category !==
                  EnumClaimReportCategory.ClaimReportCategoryServiceReport && (
                  <TimeCodeBlock label={t('chat.appeal.chooseTimeCode')} />
                )}
                <DescriptionBlock label={t('chat.appeal.yourDescr')} />
                <AddFiles />
              </ChooseBlock>
              {/* </ChooseBlocks> */}
            </ScrollBlock>
          </>
        )
      case AppealStageTypes.APPEAL_GENERATED:
        return (
          <>
            <StyledStepDescription>
              <StyledStepNumber>
                {t('chat.appeal.confirmationApplicationPart1')}
              </StyledStepNumber>
              <span>{t('chat.appeal.confirmationApplicationPart2')}</span>
            </StyledStepDescription>
            <ScrollBlock>
              {/* <ChooseBlocks> */}
              <ChooseBlock>
                <GeneratedAppeal />
              </ChooseBlock>
              {/* </ChooseBlocks> */}
            </ScrollBlock>
          </>
        )
      default:
        return null
    }
  }

  return (
    <SupportStageWrapper>
      {/* <SupportBreadcrumbs /> */}
      {getContent()}
      <StepsButtons />
    </SupportStageWrapper>
  )
}

export default SupportStage
