import { DtoMessage } from 'api/schemas/chatApi'
import { Header6, Text5 } from 'styles/typography'
import { setApproveAppeal } from 'stores/chatStores/appealStore'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 19px;
`
const StyledCaption = styled(Text5)`
  color: white;
  margin-bottom: 19px;
`
const StyledButtons = styled.div`
  display: flex;
  gap: 15px;
`
const StyledButtonYes = styled(Header6)`
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  color: white;
  transition: 0.3s;
  border-radius: 3px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: rgba(255, 255, 255, 0.15);
  cursor: pointer;
  height: 32px;
  width: 200px;

  &:hover {
    color: black;
    background: #2cff80;
  }
`
const StyledButtonNo = styled(Header6)`
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  height: 32px;
  width: 85px;
  color: white;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    color: #2cff80;
  }
`

interface ISupportMessage {
  message: DtoMessage
}
const SupportMessageButtons: React.FC<ISupportMessage> = ({ message }) => {
  const { t } = useTranslation()
  const handleReopenAppeal = async () => {
    await setApproveAppeal(false, message.id || '')
  }
  const handleOpenEstimate = async () => {
    await setApproveAppeal(true, message.id || '')
  }

  return (
    <StyledWrapper>
      <StyledCaption>{t('chat.appeal.isHelpful')}</StyledCaption>
      <StyledButtons>
        <StyledButtonNo onClick={handleReopenAppeal}>
          {t('chat.appeal.no')}
        </StyledButtonNo>
        <StyledButtonYes onClick={handleOpenEstimate}>
          {t('chat.appeal.yes')}
        </StyledButtonYes>
      </StyledButtons>
    </StyledWrapper>
  )
}

export default SupportMessageButtons
