import Tabs from 'rc-tabs'
import styled from 'styled-components'

const StyledTabs = styled(Tabs)`
  border-radius: 6px;
  border: 1px solid rgba(255, 255, 255, 0.25);
  padding: 10px;

  & .rc-tabs-nav-list {
    display: flex;
    gap: 16px;
  }

  & .rc-tabs-nav-operations {
    display: none;
  }

  & .rc-tabs-tab {
    font-family: 'Ruberoid';
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    padding: 10px 18px;
    line-height: 100%;
    cursor: pointer;
    transition: 0.2s;
  }

  & .rc-tabs-tab-active {
    transition: 0.2s;
    color: black;
  }
  & .rc-tabs-ink-bar {
    border-radius: 3px;
    background: var(--Desktop-Green, #2cff80);
    /* padding: 10px 18px; */
    height: 36px;
    z-index: -1;
    position: absolute;
    box-sizing: content-box;
    transition: 0.2s;
  }
`

interface TabsSwitcher {
  defaultActiveKey: string
  onChange: (key: string) => void
  items: {
    key: string
    label: React.ReactNode
  }[]
}

const TabsSwitcher: React.FC<TabsSwitcher> = ({
  items,
  onChange,
  defaultActiveKey,
}) => {
  return (
    <StyledTabs
      items={items}
      onChange={onChange}
      defaultActiveKey={defaultActiveKey}
    />
  )
}

export default TabsSwitcher
