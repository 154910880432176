import {
  $friendsListSorted,
  $recnetPlayers,
  $searchedFriendsList,
  getRecnetPlayers,
  inviteFriend,
  setSearchFriendsInput,
} from 'stores/friendsStore'
import { $isRightBarOpen, toggleRightBar } from 'stores/appStore'
import { $party } from 'stores/partyStore'
import { $userProfile } from 'stores/userStore'
import { debounce } from 'lodash'
import { useEffect, useState } from 'react'
import { useStore } from '@nanostores/react'
import { useTranslation } from 'react-i18next'
import AddFriendIcon from 'images/AddFriendIcon'
import FriendsIcon from 'images/FriendsIcon'
import Input from 'components/Input'
import OpenButtonBGDots from 'images/OpenButtonBGDots'
import Party from 'components/FriendsBar/Party'
import RecentFriendsIcon from 'images/RecentFriendsIcon'
import Search from 'images/Search'
import ShevronLeftIcor from 'images/ShevronLeftIcor'
import SmallButton from 'components/SmallButton'
import UserProfile from 'components/FriendsBar/UserProfile'
import styled from 'styled-components'

interface StyledWrapperProps {
  $isOpen: boolean
}

const StyledWrapper = styled.div<StyledWrapperProps>`
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  max-height: 100vh;
  justify-content: start;
  z-index: 10;

  width: ${({ $isOpen }) => ($isOpen ? '240px' : '90px')};
  right: 0;

  transition: 0.3s;
`

const StyledBottomContainer = styled.div<StyledWrapperProps>`
  height: 100%;
  overflow: hidden;
  max-height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;

  width: 100%;
  right: 0;
  border-left: 1px solid rgba(255, 255, 255, 0.25);

  background: ${({ $isOpen }) =>
    $isOpen
      ? 'var(--dashboard-small, linear-gradient(180deg, rgba(114, 132, 180, 0.10) 0%, rgba(59, 79, 131, 0.00) 100%), #1D1E22);'
      : 'linear-gradient(180deg,rgba(114, 132, 180, 0.1) 0%,  rgba(59, 79, 131, 0) 100% ), rgba(29, 30, 34, 0.5);'};

  transition: 0.3s;
`

const StyledScroll = styled.div`
  height: auto;
  overflow-y: scroll;
  overflow-x: hidden;
  width: 100%;
  padding: 0px 2px 5px 0px;
  position: relative;
  z-index: 5;

  &::-webkit-scrollbar {
    width: 2px;
  }

  &::-webkit-scrollbar-track {
    margin: 4px 0 4px;
    border-radius: 1px;
    opacity: 0.1;
    background: rgba(217, 217, 217, 0.1);
  }

  &::-webkit-scrollbar-thumb {
    background-color: white;
    border-radius: 1px;
  }
`

const StyledUserWrapeer = styled.div`
  display: flex;
  flex-direction: column;

  position: relative;

  width: 100%;
  align-items: start;
  padding: 16px 10px;
  gap: 10px;
  border-left: 1px solid rgba(255, 255, 255, 0.25);

  background: radial-gradient(
      circle,
      rgb(255 255 255 / 5%) 0%,
      rgba(44, 255, 128, 0.7960434173669468) 115%
    ),
    rgb(27 53 36);
`

const StyledFriends = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  flex-direction: column;
  gap: 10px;
  margin: 20px 0;
  padding: 0 10px;
  width: 100%;
  height: auto;
`

const StyledSearch = styled.div`
  display: flex;
  align-items: center;

  padding: 20px 9px;
  height: 60px;
  cursor: pointer;
  & input::placeholder {
    font-size: 12px !important;
    font-weight: 500 !important;
  }
`

interface StyledTabsProps {
  $isOpen: boolean
}

interface StyledSmallButtonTextProps {
  $isOpen: boolean
}

const StyledSmallButtonText = styled.div<StyledSmallButtonTextProps>`
  font-size: ${({ $isOpen }) => ($isOpen ? '12px' : '9px')};
`

const StyledSmallButton = styled(SmallButton)<StyledSmallButtonTextProps>`
  width: 100%;
  margin-bottom: 10px;
  justify-content: center;
  width: ${({ $isOpen }) => ($isOpen ? '210px' : '80px')};
`

interface StyledTabWrapperProps {
  $isCurrent: boolean
}

const StyledTabWrapper = styled.div<StyledTabWrapperProps>`
  cursor: pointer;
  fill: ${({ $isCurrent }) => ($isCurrent ? '#2CFF80' : 'white')};
`

interface StyledOpenControlProps {
  $isOpen: boolean
}

const StyledOpenControl = styled.div<StyledOpenControlProps>`
  position: absolute;
  left: -5px;
  bottom: -1px;
  min-width: 32px;
  height: calc(100% + 2px);

  cursor: pointer;
  fill: white;
  border-radius: 1px;

  display: flex;
  align-items: center;
  justify-content: center;

  & svg {
    transform: rotate(${({ $isOpen }) => (!$isOpen ? '180deg' : '0deg')});
  }
`

const StyledTabs = styled.div<StyledTabsProps>`
  position: relative;
  transition: 0.3s;

  display: flex;
  justify-content: ${({ $isOpen }) => ($isOpen ? 'center' : 'center')};
  gap: 50px;

  padding: 15px 15px 15px 30px;
  width: calc(100% + 12px);
  left: -6px;

  &:after {
    content: '';
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    border-left: 1px solid rgba(255, 255, 255, 0.1);
    transition: 0.2s;
    position: absolute;
    background: ${({ $isOpen }) =>
      $isOpen
        ? 'linear-gradient(161deg, rgba(114, 132, 180, 0.10) 12.59%, rgba(59, 79, 131, 0.00) 950.3%), #1D1E22;'
        : 'linear-gradient(180deg, rgba(114, 132, 180, 0.10) 0%, rgba(59, 79, 131, 0.00) 1242.98%), rgba(29, 30, 34, 0.50)'};
  }

  &:before {
    content: '';
    top: 0;
    left: 0;
    transition: 0.2s;
    opacity: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    border-left: 1px solid rgba(255, 255, 255, 0.1);
    position: absolute;
    background: ${({ $isOpen }) =>
      $isOpen
        ? 'linear-gradient(161deg, rgba(114, 132, 180, 0.30) 12.59%, rgba(59, 79, 131, 0.00) 950.3%), #1D1E22;'
        : 'linear-gradient(180deg, rgba(114, 132, 180, 0.30) 0%, rgba(59, 79, 131, 0.00) 1242.98%), rgba(29, 30, 34, 0.50);'};
  }

  &:hover:after {
    opacity: 0;
  }

  &:hover:before {
    opacity: 1;
  }

  background-color: #1d1e22;

  z-index: 11;
`

const StyledFriendWrapper = styled.div`
  position: relative;
  width: 100%;
`

const StyledOpenBG = styled(OpenButtonBGDots)`
  position: absolute;
`

const Bar: React.FC = () => {
  const { t } = useTranslation()

  const currentUser = useStore($userProfile)
  const searchedFriendsList = useStore($searchedFriendsList)
  const friendsList = useStore($friendsListSorted)
  const party = useStore($party)
  const recnetPlayers = useStore($recnetPlayers)
  const isOpen = useStore($isRightBarOpen)
  const [inputValue, setInputValue] = useState('')

  const [sendedInvites, setSendedInvites] = useState<string[]>([])

  const [currentTab, setCurrentTab] = useState<'all' | 'messages' | 'recent'>(
    'all'
  )

  const handleSendInvite = (id: string) => async () => {
    setSendedInvites([...sendedInvites, id])
    await inviteFriend(id || '')
  }

  const handleToggleBar = async () => {
    if (isOpen && currentTab === 'messages') {
      await handleCurrentTab('all')()
    }
    await toggleRightBar()
  }

  const debouncedSearch = debounce(async (value: string) => {
    return await setSearchFriendsInput(value)
  }, 500)

  const handleCurrentTab = (tab: 'all' | 'messages' | 'recent') => async () => {
    if (!isOpen) {
      await toggleRightBar()
    }
    setInputValue('')
    setCurrentTab(tab)
    await debouncedSearch('')
  }

  const handleSearchChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    setInputValue(value)
    await debouncedSearch(value)
  }

  useEffect(() => {
    if (currentTab === 'recent') {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      getRecnetPlayers()
    }
  }, [currentTab])

  if (!currentUser) {
    return null
  }

  return (
    <>
      <StyledWrapper
        $isOpen={isOpen}
        // onMouseEnter={() => $isRightBarOpen.set(true)}
        // onMouseLeave={() => $isRightBarOpen.set(false)}
      >
        <StyledUserWrapeer>
          <UserProfile
            size="m"
            isFull={isOpen}
            hasMenu={false}
            profile={currentUser}
            nickName={currentUser.name}
            isHost={party?.hostId === currentUser.id}
          />
          {party?.participants
            ?.filter((participant) => participant.user?.id !== currentUser.id)
            .map(({ user }) =>
              user ? (
                <UserProfile
                  key={user.id}
                  profile={user}
                  isFull={isOpen}
                  nickName={user.name}
                  isHost={party.hostId === user.id}
                  status={user.online !== undefined}
                />
              ) : null
            )}
          <Party isOpen={isOpen} isHost={party?.hostId === currentUser.id} />
        </StyledUserWrapeer>
        <StyledTabs $isOpen={isOpen}>
          <StyledOpenControl $isOpen={isOpen} onClick={handleToggleBar}>
            <ShevronLeftIcor />
            <StyledOpenBG />
          </StyledOpenControl>
          {(currentTab === 'all' || isOpen) && (
            <StyledTabWrapper
              $isCurrent={currentTab === 'all'}
              onClick={handleCurrentTab('all')}
            >
              <FriendsIcon />
            </StyledTabWrapper>
          )}
          {(currentTab === 'recent' || isOpen) && (
            <StyledTabWrapper
              $isCurrent={currentTab === 'recent'}
              onClick={handleCurrentTab('recent')}
            >
              <RecentFriendsIcon />
            </StyledTabWrapper>
          )}
          {(currentTab === 'messages' || isOpen) && (
            <StyledTabWrapper
              $isCurrent={currentTab === 'messages'}
              onClick={handleCurrentTab('messages')}
            >
              <AddFriendIcon />
            </StyledTabWrapper>
          )}
        </StyledTabs>

        <StyledBottomContainer $isOpen={isOpen}>
          {currentTab !== 'recent' && (
            <StyledSearch>
              {!isOpen && <Search onClick={() => toggleRightBar(true)} />}
              {isOpen && (
                <Input
                  value={inputValue}
                  rightDecorator={<Search />}
                  onChange={handleSearchChange}
                  placeholder={t('friends.searchByNickname')}
                />
              )}
            </StyledSearch>
          )}
          <StyledScroll>
            <StyledFriends>
              {currentTab === 'all' && (
                <>
                  {!searchedFriendsList.length
                    ? friendsList?.map((friend) => (
                        <StyledFriendWrapper key={friend.id}>
                          <UserProfile
                            isDraggable
                            key={friend.id}
                            isFull={isOpen}
                            profile={friend}
                            nickName={friend.name}
                            status={friend.online !== undefined}
                          />
                        </StyledFriendWrapper>
                      ))
                    : searchedFriendsList.map(
                        (friend) =>
                          friendsList.some(
                            (friendsListItem) =>
                              friendsListItem.id === friend.id
                          ) && (
                            <StyledFriendWrapper key={friend.id}>
                              <UserProfile
                                isDraggable
                                key={friend.id}
                                isFull={isOpen}
                                profile={friend}
                                nickName={friend.name}
                                status={friend.online !== undefined}
                              />
                            </StyledFriendWrapper>
                          )
                      )}
                </>
              )}
              {currentTab === 'recent' && (
                <>
                  {recnetPlayers.map((friend) => (
                    <UserProfile
                      isDraggable
                      key={friend.id}
                      isFull={isOpen}
                      profile={friend}
                      nickName={friend.name}
                    />
                  ))}
                </>
              )}
              {currentTab === 'messages' && (
                <>
                  {searchedFriendsList.map(
                    (friend, index) =>
                      friendsList.every(
                        (friendsListItem) => friendsListItem.id !== friend.id
                      ) && (
                        <>
                          <UserProfile
                            isDraggable
                            isFull={isOpen}
                            profile={friend}
                            nickName={friend.name}
                            key={`${friend.id}_${index}`}
                          />
                          <StyledSmallButton
                            $isOpen={isOpen}
                            onClick={handleSendInvite(friend.id || '')}
                            disabled={sendedInvites.includes(friend.id || '')}
                          >
                            <StyledSmallButtonText $isOpen={isOpen}>
                              {sendedInvites.includes(friend.id || '')
                                ? t('friends.addedFriend')
                                : t('friends.addFriend')}
                            </StyledSmallButtonText>
                          </StyledSmallButton>
                        </>
                      )
                  )}
                </>
              )}
            </StyledFriends>
          </StyledScroll>
        </StyledBottomContainer>
      </StyledWrapper>
    </>
  )
}

export default Bar
