import { $modal } from 'stores/appStore'
import { useStore } from '@nanostores/react'

// import JudgeReportModal from 'components/Judge/JudgeReportModal'

import JudgeVoteModal from 'components/Judge/JudgeVoteModal'
import MatchResult1x1CS from 'components/MatchResults/MatchResult1x1CS'
import MatchResults from 'components/MatchResults/MatchResults'
import SettingsPage from 'pages/SettingsPage'
import UserAccount from 'components/PersonalAccount/UserAccount'

const ModalPages = () => {
  const modal = useStore($modal)

  if (!modal) {
    return null
  }

  switch (modal) {
    case 'settings':
      return <SettingsPage />
    case 'personal':
      return <UserAccount />
    case 'report':
      // return <JudgeReportModal />
      return <JudgeVoteModal />
    case 'matchResults':
      return <MatchResults />
    case 'matchResults1x1':
      return <MatchResult1x1CS />
    default:
      return null
  }
}

export default ModalPages
