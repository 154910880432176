const ManyInvitesIcon = () => (
  <svg
    width="42"
    fill="none"
    height="24"
    viewBox="0 0 42 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_341_212302)">
      <path
        fill="white"
        clipRule="evenodd"
        fillRule="evenodd"
        d="M28.1504 10.8936C30.054 10.8936 31.5972 9.35045 31.5972 7.44682C31.5972 5.5432 30.054 4 28.1504 4C26.2468 4 24.7036 5.5432 24.7036 7.44682C24.7036 9.35045 26.2468 10.8936 28.1504 10.8936ZM32.7545 14.3963C33.9755 15.6172 34.6614 17.2732 34.6615 18.9999H36.1931C36.1931 17.3337 35.676 15.7207 34.7308 14.3757C35.3122 14.1437 35.9377 14.0211 36.5759 14.0211C37.8963 14.0211 39.1627 14.5457 40.0964 15.4794C41.0301 16.4131 41.5546 17.6794 41.5546 18.9999H36.5759H34.6615V19H28.1508L21.6401 19C21.6401 19 21.6401 18.9999 21.6401 18.9999H19.9788H15C15 17.6794 15.5245 16.4131 16.4582 15.4794C17.3919 14.5457 18.6583 14.0211 19.9787 14.0211C20.6169 14.0211 21.2424 14.1437 21.8239 14.3757C20.8786 15.7207 20.3616 17.3337 20.3616 18.9999H21.6401C21.6402 17.2732 22.3261 15.6172 23.547 14.3963C24.768 13.1753 26.424 12.4893 28.1508 12.4893C29.8775 12.4893 31.5335 13.1753 32.7545 14.3963ZM39.2116 9.96979C39.2116 11.4255 38.0315 12.6056 36.5758 12.6056C35.1201 12.6056 33.94 11.4255 33.94 9.96979C33.94 8.51408 35.1201 7.33398 36.5758 7.33398C38.0315 7.33398 39.2116 8.51408 39.2116 9.96979ZM19.9788 12.6056C18.5231 12.6056 17.343 11.4255 17.343 9.96979C17.343 8.51408 18.5231 7.33398 19.9788 7.33398C21.4345 7.33398 22.6146 8.51408 22.6146 9.96979C22.6146 11.4255 21.4345 12.6056 19.9788 12.6056Z"
      />
      <path
        fill="white"
        clipRule="evenodd"
        fillRule="evenodd"
        d="M8.17652 11.7267L4.86795 8.60346L5.89763 7.5127L10.5784 11.9313C10.7285 12.073 10.8135 12.2703 10.8135 12.4767C10.8135 12.6831 10.7285 12.8804 10.5784 13.0221L5.89763 17.4407L4.86795 16.3499L8.17643 13.2267H-1V11.7267H8.17652Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_341_212302">
        <rect width="42" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default ManyInvitesIcon
